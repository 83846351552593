











import Vue from 'vue';
import GpgPublicKeys from '@/common/components/settings/GpgPublicKeys.vue';

export default Vue.extend({
  components: {
    GpgPublicKeys,
  },
});
