

















































import { CustomFieldType, CustomFieldFillable } from '@/common/components/support/support.types';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Array as () => CustomFieldFillable[],
      required: true,
    },
    enhanced: {
      type: Array as () => CustomFieldFillable[],
      required: false,
    },
    validateEnhancedFields: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      CustomFieldType,
      enhancedFieldValidationInitiated: false as boolean,
    };
  },
  methods: {
    debouncedField() {
      this.$emit('setEnhancedFieldProgress');
    },
    getClass(fieldVal:any) {
      return (this.enhancedFieldValidationInitiated || this.validateEnhancedFields)
              && fieldVal === ''
        ? 'level-input-error'
        : '';
    },
  },
});
