<template>
  <v-card class="recovery-codes-card">
    <v-card-title>
      <h1>Two-Factor recovery codes</h1>
    </v-card-title>
    <v-card-text>
      <p>
        Because this is the first time you enable two-factor, we generated you 10
        recovery codes. These codes will not be shown again, so record them right now and store
        them in a safe place. These codes can be used to complete a two-factor login if you
        lose your device, and they can be used to disable two-factor authentication as well.
      </p>

      <ul>
        <li v-for="code in recoveryCodes" :key="code">
          {{ code }}
        </li>
      </ul>

      <p>
        Once you have recorded the codes, click the Done button.
      </p>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" @click="$emit('done')">
        <v-icon class="mr-2">mdi-check-circle</v-icon>
        Done
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    recoveryCodes: { type: Array, required: true },
  },
};
</script>

<style scoped>
.recovery-codes-card.v-card {
  border-top: 0.5rem solid var(--primary-color);
  border-left: 0;
  border-right: 0;
}

.recovery-codes-card.v-card .v-card__title {
  padding: 2rem 2.5rem 2rem 2.5rem;
}

.recovery-codes-card.v-card .v-card__title h1 {
  font-size: 1.125rem;
  font-weight: 600;
}

.recovery-codes-card.v-card .v-card__text {
  padding: 0 2.5rem;
  color: #000;
}

.recovery-codes-card.v-card .v-card__actions {
  padding: 1rem 2.5rem 2.5rem 2.5rem;
}

ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.25rem;
  row-gap: 0.75rem;
  margin-block: 2rem;
}

li {
  border-radius: 5px;
  background: #666666;
  color: #fff;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
}
</style>
