
















































































import Vue from 'vue';
import { DateTime } from 'luxon';
import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import Alert from '@/common/components/Alert.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import { AlertType } from '@/common/components/alerts.types';
import { PaymentType } from '@/common/types/payments.types';
import { initRecurringPayment } from '@/common/util/payments.util';
import { Transaction } from '@/common/types/transactions.types';
import { Subscription } from '@/common/types/subscriptions.types';
import RecurringPaymentForm from '../components/payments/RecurringPaymentForm.vue';
import Address from './components/Address.vue';
import TransactionDetailsCard from './components/TransactionDetailsCard.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      transaction: null as Transaction | null,
      validationErrors: initValidationErrors() as ValidationErrors,
      recurrence: initRecurringPayment(),
      isSubmitting: false as boolean,
      addToVault: false as boolean,
      externalId: '' as string,
      showAlert: true as boolean,
      AlertType,
    };
  },
  created() {
    document.title = 'RiseOS-Convert to Subscription';
  },
  mounted() {
    this.loadTransaction();
  },
  computed: {
    hasBillingAddress(): boolean {
      return (
        this.transaction !== null
        && this.transaction.customerDetails !== null
        && this.transaction.customerDetails?.billingAddress !== null
        && this.transaction.customerDetails?.billingAddress !== undefined
      );
    },
    hasShippingAddress(): boolean {
      return (
        this.transaction !== null
        && this.transaction.customerDetails !== null
        && this.transaction.customerDetails?.shippingAddress !== null
        && this.transaction.customerDetails?.shippingAddress !== undefined
      );
    },
    customer(): string {
      if (!this.transaction) {
        return 'Loading...';
      }

      if (this.transaction.customer) {
        return this.transaction.customer;
      }

      return this.transaction.paymentMethod!.type === PaymentType.CREDIT
        ? this.transaction.account
        : (this.$options.filters as any).capitalize(this.transaction.type);
    },
    expirationDate(): string {
      if (this.transaction!.paymentMethod!.type !== PaymentType.CREDIT) {
        return '';
      }

      const { expMonth: month, expYear: year } = this.transaction!.paymentMethod!.creditCard!;
      return `Exp: ${month}/${year}`;
    },
    transactionTypeIcon(): string {
      if (this.transaction!.paymentMethod!.type === PaymentType.CASH) {
        return 'money-bill-alt';
      }
      if (this.transaction!.paymentMethod!.type === PaymentType.ACH) {
        return 'money-check';
      }
      return 'credit-card';
    },
    amountToBill(): number {
      return this.transaction?.amount ?? 0;
    },
    showAddToVault(): boolean {
      const customerDetails = this.transaction?.customerDetails;
      return customerDetails === undefined || customerDetails === null || !customerDetails.inVault;
    },
  },
  methods: {
    loadTransaction() {
      (this.$refs.loader as any).setLoading();
      api
        .get(`/transactions/${this.id}`)
        .then(({ data: { data } }) => {
          this.transaction = data;
          this.recurrence.description = this.transaction!.description;
          (this.$refs.loader as any).setReady();
        })
        .catch(() => {
          this.$toasted.error('Problem loading transaction');
          if (this.$refs.loader) {
            (this.$refs.loader as any).setError();
          }
        });
    },
    resetForms() {
      this.recurrence = initRecurringPayment();
      this.recurrence.description = this.transaction!.description;
      this.validationErrors = initValidationErrors();
    },
    submitActions(isSubmitting: boolean, resetForms: boolean = false) {
      this.isSubmitting = isSubmitting;
      if (!isSubmitting && resetForms) {
        this.resetForms();
      }
    },
    submit() {
      this.submitActions(true);
      const subscription: Subscription = {
        name: this.recurrence.name,
        description: this.recurrence.description,
        recurrenceFrequency: this.recurrence.billingRecurrence.recurrence.recurrenceFrequency,
        amount: this.recurrence.billingRecurrence.amount,
        recurrenceText: this.recurrence.billingRecurrence.recurrence.recurrenceText,
        rruleText: this.recurrence.billingRecurrence.recurrence.rruleText,
        transactionId: this.transaction?.id,
        addToVault: this.addToVault,
        externalId: this.externalId,
        customFields: [],
      };
      api.post('subscriptions', subscription).then(this.showSuccess).catch(this.handleSaveError);
    },
    showSuccess({ data }: { data: JsonApiSingleResponse<Subscription> }) {
      this.submitActions(true, true);
      this.$toasted.success('Subscription created successfully!');
      this.$router.push({ name: 'merchant.subscriptions.view', params: { id: data.data!.id! } });
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      this.$toasted.error(`There was a problem creating the subscription. (${response.status})`);
      this.validationErrors = response.data.errors || {};
    },
  },
  filters: {
    transactionDate(timestamp: Date) {
      return DateTime.fromISO(timestamp.toLocaleString())
        .toLocaleString(DateTime.DATETIME_SHORT)
        .replace(', ', ' at ');
    },
  },
  components: {
    Address,
    TransactionDetailsCard,
    PageHeader,
    PageLoader,
    LoadingModal,
    RecurringPaymentForm,
    Alert,
  },
});
