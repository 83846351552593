












import Vue from 'vue';
import { Month } from '@/common/types/dateTime.types';
import { MonthRecurrenceModel } from '@/common/types/billingRecurrences.types';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => MonthRecurrenceModel,
      required: true,
    },
  },
  data() {
    return {
      Month,
    };
  },
  computed: {
    months(): Month[] {
      return [
        Month.January,
        Month.February,
        Month.March,
        Month.April,
        Month.May,
        Month.June,
        Month.July,
        Month.August,
        Month.September,
        Month.October,
        Month.November,
        Month.December,
      ];
    },
  },
});
