


























































import Vue from 'vue';

import LoadingModal from '@/common/components/LoadingModal.vue';
import { mapGetters } from 'vuex';
import { BasicProcessor, Processor, ProcessorType } from '@/common/types/processors.types';
import { availableProcessorsDisplayTest } from '@/common/util/processors.util';
import { isEnabled } from 'vue-feature-flipping';
import { Feature } from '@/features.types';
import CashNew from './ProcessorSettings/CashNew.vue';
import ElavonNew from './ProcessorSettings/ElavonNew.vue';
import TsysNew from './ProcessorSettings/TsysNew.vue';
import FirstDataNew from './ProcessorSettings/FirstDataNew.vue';
import OmahaNew from './ProcessorSettings/OmahaNew.vue';
import PayaNew from './ProcessorSettings/PayaNew.vue';

export default Vue.extend({
  props: {
    merchantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Feature,
      availableProcessorsDisplayTest,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      selectedProcessor: null as Processor | null,
    };
  },
  methods: {
    handleError() {
      this.$toasted.error('There was a problem loading available processors');
    },
    setAvailableProcessors({ data: { data } }: { data: { data: BasicProcessor[] } }) {
      if (isEnabled(Feature.OMAHA)) {
        this.availableProcessors = data;
      } else {
        this.availableProcessors = data.filter((item) => item.key !== ProcessorType.OMAHA);
      }
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.selectedProcessor = null;
    },
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.reset();
    },
    onCreated() {
      this.$emit('created');
      this.close();
    },
  },
  computed: {
    ...mapGetters(['availableProcessors']),
    isElavon(): boolean {
      return this.selectedProcessor?.key === ProcessorType.ELAVON;
    },
    isTsys(): boolean {
      return this.selectedProcessor?.key === ProcessorType.TSYS && isEnabled(Feature.TSYS);
    },
    isFirstData(): boolean {
      return (
        this.selectedProcessor?.key === ProcessorType.FIRSTDATA && isEnabled(Feature.FIRSTDATA)
      );
    },
    isOmaha(): boolean {
      return (
        this.selectedProcessor?.key === ProcessorType.OMAHA && isEnabled(Feature.OMAHA)
      );
    },
    isPaya(): boolean {
      return this.selectedProcessor?.key === ProcessorType.PAYA;
    },
    isCash(): boolean {
      return this.selectedProcessor?.key === ProcessorType.CASH;
    },
  },
  components: {
    LoadingModal,
    CashNew,
    ElavonNew,
    PayaNew,
    FirstDataNew,
    OmahaNew,
    TsysNew,
  },
});
