




import Vue from 'vue';
import NonCashAdjustmentFee from '../../../common/components/settings/NonCashAdjustmentFee.vue';

export default Vue.extend({
  components: {
    NonCashAdjustmentFee,
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    // update component key to re-render child component
    updateComponentKey() {
      this.componentKey += 1;
    },
  },
});
