import { Feature } from '@/features.types';

export default [
  {
    route: 'iso.dashboard',
    icon: () => import('@/components/icons/i-tachometer.vue'),
    text: 'Dashboard',
  },
  {
    route: 'iso.merchants.index',
    icon: () => import('@/components/icons/i-store.vue'),
    text: 'Merchants',
  },
  {
    route: 'iso.shopping-carts',
    icon: () => import('@/components/icons/i-cart.vue'),
    text: 'Shopping Carts',
    feature: Feature.SHOPPING_CARTS,
  },
  {
    route: 'iso.reports.residual',
    icon: () => import('@/components/icons/i-chart.vue'),
    text: 'Reports',
  },
  {
    href: process.env.VUE_APP_API_DOCS_BASE_URL,
    icon: () => import('@/components/icons/i-api.vue'),
    text: 'API Docs',
  },
];
