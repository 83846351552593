













































































import Vue from 'vue';
import auth from '@/common/auth';
import FormTextarea from '@/common/components/forms/Textarea.vue';
import Modal from '@/common/components/Modal.vue';
import PageHeader from '@/common/components/PageHeader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { ApiToken, ApiTokenScope } from '../support/support.types';

export default Vue.extend({
  mounted() {
    this.checkCanCopyToClipboard();
    this.loadTokens();
  },
  data() {
    return {
      canCopyToClipboard: false,
      tokens: [] as ApiToken[],
      tokenName: '' as string,
      modalTokenName: '' as string,
      modalBearerToken: '' as string,
      showModal: false,
      isSubmitting: false,
    };
  },
  methods: {
    checkCanCopyToClipboard() {
      return (window.navigator as any).permissions
        .query({
          name: 'clipboard-write',
        })
        .then(() => {
          this.canCopyToClipboard = true;
        });
    },
    copyToClipboard(token: string) {
      navigator.clipboard.writeText(token);
      this.$toasted.success('Token copied to clipboard!');
    },
    loadTokens() {
      auth
        .get('/oauth/personal-access-tokens')
        .then(({ data: tokens }) => {
          this.tokens = tokens
            .filter((t: ApiToken) => !t.scopes.includes(ApiTokenScope.HPP_SUBMIT_PAYMENT_SCOPE))
            .map((t: ApiToken) => ({ hidden: true, ...t }));
        })
        .catch(() => {
          const message = this.$errorMessages.get('api_tokens.load');
          this.$toasted.error(`${message}`);
        });
    },
    createToken() {
      if (!this.isValid()) {
        return;
      }
      this.isSubmitting = true;
      auth
        .post('/oauth/personal-access-tokens', {
          name: this.tokenName,
          scopes: ['default'],
        })
        .then(
          ({
            data: {
              accessToken: bearer,
              token: { name },
            },
          }) => {
            this.isSubmitting = false;
            this.closeAddTokenPopup();
            this.revealToken(name, bearer);
            this.loadTokens();
            this.tokenName = '';
            (this.$refs.form as any).reset();
            this.$toasted.success('API token created successfully');
          },
        )
        .catch(() => {
          this.isSubmitting = false;
          const message = this.$errorMessages.get('api_tokens.create');
          this.$toasted.error(`${message}`);
        });
    },
    revokeToken(token: ApiToken) {
      auth
        .delete(`/oauth/personal-access-tokens/${token.id}`)
        .then(() => {
          this.loadTokens();
          this.$toasted.success('API token deleted successfully');
        })
        .catch(() => {
          const message = this.$errorMessages.get('api_tokens.delete');
          this.$toasted.error(`${message}`);
        });
    },
    revealToken(name: string, bearer: string) {
      this.modalTokenName = name;
      this.modalBearerToken = bearer;
      (this.$refs.tokenRevealModal as any).show();
    },
    showAddTokenPopup() {
      this.showModal = true;
    },
    closeAddTokenPopup() {
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    isMobile(): boolean {
      if (this.$vuetify.breakpoint.width === 0) return false;

      return this.$vuetify.breakpoint.width < 600;
    },
  },
  components: {
    FormTextarea,
    Modal,
    PageHeader,
    LoadingModal,
  },
});
