








































import Vue from 'vue';
import api from '@/common/api';
import { toFloat, toInt } from '@/common/mappers/buy-rate-value-mapper';
import PageLoader from '@/common/components/PageLoader.vue';
import IsoContact from '@/common/components/forms/Contact.vue';
import MSaveButton from '@/components/molecules/m-save-button.vue';
import IsoBuyRates from '@/tenants/admin/modules/buy-rates/components/BuyRates.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import IsoDetails from '../components/Details.vue';
import IsoBranding from '../components/Branding.vue';
import { IsoRequest, IsoResponse } from '../types/affiliate';

export default Vue.extend({
  data() {
    return {
      iso: {
        name: '',
        subdomain: '',
        taxId: '',
        routingNumber: '',
        accountNumber: '',
        branding: {
          fullLogo: null,
          lightLogo: null,
          darkLogo: null,
          mainColor: '#114B5F',
          mainTextColor: '#F3E9D2',
          secondColor: '#1A936F',
          secondTextColor: '#C6DABF',
          thirdColor: '#88D498',
          thirdTextColor: '#728270',
          loginFormBackgroundColor: '',
          loginTextColor: '',
          loginBodyBackgroundColor: '',
        },
        contact: {
          firstName: '',
          lastName: '',
          username: '',
          email: '',
          phone: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
          country: 'US',
        },
        buyRates: [],
        category: 'iso',
      } as IsoRequest,
      showErrorMsg: false as boolean,
    };
  },
  created() {
    document.title = 'RiseOs-Create a New Affiliate';
  },
  mounted() {
    api
      .get('default-buy-rates')
      .then(({ data: { data: rates } }) => {
        this.iso.buyRates = toFloat(rates);
        (this.$refs.buyRatesLoader as any).setReady();
      })
      .catch((this.$refs.buyRatesLoader as any).setError);
  },
  methods: {
    updateBrandLogo(logo: string) {
      this.iso.branding.logo = logo;
    },
    updateBrandColor(color: string, value: string) {
      this.iso.branding[color] = value;
    },
    save() {
      if (!this.isValid()) {
        this.showErrorMsg = true;
        return;
      }
      this.showErrorMsg = false;
      api
        .post('isos', { ...this.iso, buyRates: toInt(this.iso.buyRates) })
        .then(this.showSuccess)
        .catch(this.handleSaveError);
    },
    showSuccess({ data }: { data: JsonApiSingleResponse<IsoResponse> }) {
      this.$toasted.success('Affiliate created successfully!');
      this.$router.push({ name: 'admin.isos.edit', params: { id: data.data!.id! } });
    },
    handleSaveError({ response }: { response: any }) {
      const message = this.$errorMessages.get('affiliates.default_save');
      this.$toasted.error(`${message} (${response.status})`);
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    PageLoader,
    IsoDetails,
    IsoContact,
    IsoBranding,
    IsoBuyRates,
    MSaveButton,
  },
});
