




















































































import Vue from 'vue';
import api from '@/common/api';
import { toDisplayText } from '@/common/util/hostedPaymentPages.util';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { Feature } from '@/features.types';
import HostedPaymentPageNew from './HostedPaymentPageNew.vue';
import HostedPaymentPageEdit from './HostedPaymentPageEdit.vue';
import HostedPaymentGetLinks from './HostedPaymentGetLinks.vue';

export default Vue.extend({
  data() {
    return {
      toDisplayText,
      Feature,
      EntityFilterType,
      hppItemToDelete: {} as any,
      hppItemToEdit: {} as any,
      hppPaymentLink: '' as string,
      showDeleteModal: false,
      reLoadData: false,
      config: {
        pageTitle: 'Hosted Payment Pages',
        header: 'Hosted Payment Pages',
        routerBase: 'merchant.hosted-payments',
        apiPath: 'hosted-payments',
        entityName: 'hostedPaymentPage',
        identifierProperty: 'id',
        reportFileName: '',
        filters: [
          {
            name: 'name',
            display: 'Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
        ],
        headers: [
          {
            text: 'ID',
            value: 'id',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Name',
            value: 'name',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Payment Link',
            value: 'url',
            sortable: false,
            align: 'left',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Status',
            value: 'pageStatus',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Date Created',
            value: 'createdAt',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Date Updated',
            value: 'updatedAt',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
        ],
      },
    };
  },
  methods: {
    getHPPLinks(link:string) {
      this.hppPaymentLink = link;
      (this.$refs.getLinkModal as any).show();
    },
    newHPP() {
      (this.$refs.newModal as any).show();
    },
    promptForDelete(hppItem: any) {
      this.showDeleteModal = true;
      this.hppItemToDelete = hppItem;
    },
    deleteHppItem() {
      this.showDeleteModal = false;
      this.reLoadData = false;
      api
        .delete(`/hosted-payments/${this.hppItemToDelete?.id}`)
        .then(() => {
          this.hppItemToDelete = null;
          this.reLoadData = true;
        })
        .catch(({ response }: { response: any }) => {
          const message = this.$errorMessages.get('hosted_payment_pages.delete');
          this.$toasted.error(
            `${message} (${response.status})`,
          );
        })
        .finally(() => {});
    },
    editHppItem(hppItem: any) {
      this.hppItemToEdit = hppItem;
      (this.$refs.editModal as any).show();
    },
    getData() {
      this.reLoadData = true;
    },
  },
  components: {
    HostedPaymentPageNew,
    EntitiesTable,
    HostedPaymentPageEdit,
    HostedPaymentGetLinks,
  },
});
