var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-container',[_c('p-page-title',[_vm._v("Edit Maintenance Message")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('p-card',{staticClass:"maintenance-card",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Please add/edit a mantainance message")])]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","type":"submit","loading":_vm.submitting,"disabled":_vm.loading || _vm.submitting || !_vm.isFormValid}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"color":"gray","disabled":_vm.submitting,"to":{ name: 'settings' }}},[_vm._v(" Cancel ")])]},proxy:true}])},[_c('v-switch',{attrs:{"label":"Enable","disabled":_vm.loading || _vm.submitting,"loading":_vm.loading},model:{value:(_vm.maintenance.enabled),callback:function ($$v) {_vm.$set(_vm.maintenance, "enabled", $$v)},expression:"maintenance.enabled"}}),_c('v-text-field',{attrs:{"label":"Title","rules":[
          function (v) { return !!v || 'Title is required'; },
          function (v) { return v.length <= 191 || 'Title is limited to 191 characters'; } ],"disabled":_vm.loading || _vm.submitting || !_vm.maintenance.enabled,"counter":"191","outlined":"","dense":""},model:{value:(_vm.maintenance.title),callback:function ($$v) {_vm.$set(_vm.maintenance, "title", $$v)},expression:"maintenance.title"}}),_c('v-text-field',{attrs:{"label":"Short Title","rules":[
          function (v) { return !!v || 'Short title is required'; },
          function (v) { return v.length <= 191 || 'Short title is limited to 191 characters'; } ],"disabled":_vm.loading || _vm.submitting || !_vm.maintenance.enabled,"counter":"191","outlined":"","dense":""},model:{value:(_vm.maintenance.shortTitle),callback:function ($$v) {_vm.$set(_vm.maintenance, "shortTitle", $$v)},expression:"maintenance.shortTitle"}}),_c('v-textarea',{attrs:{"label":"Text Message","rules":[
          function (v) { return !!v || 'Text message is required'; },
          function (v) { return v.length <= 1000 || 'Text message is limited to 1000 characters'; }
        ],"disabled":_vm.loading || _vm.submitting || !_vm.maintenance.enabled,"counter":"1000","rows":"5","auto-grow":"","outlined":"","dense":""},model:{value:(_vm.maintenance.text),callback:function ($$v) {_vm.$set(_vm.maintenance, "text", $$v)},expression:"maintenance.text"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }