var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","height":"30vh","headers":_vm.headers,"items":_vm.customFields,"options":_vm.options,"multi-sort":true,"server-items-length":_vm.meta.total,"header-props":{
      sortIcon: 'mdi-arrow-down',
    },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pr-2"},[_vm._v("Custom Fields")]),_c('v-btn',{attrs:{"x-small":"","color":"secondary"},on:{"click":_vm.openNewCustomFieldModal}},[_vm._v("Add")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.name))+" ")]}},{key:"item.required",fn:function(ref){
    var item = ref.item;
return [(item.required)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.inHPP",fn:function(ref){
    var item = ref.item;
return [(item.inHPP)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.inVirtualTerminal",fn:function(ref){
    var item = ref.item;
return [(item.inVirtualTerminal)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.inInvoices",fn:function(ref){
    var item = ref.item;
return [(item.inInvoices)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.type))+" ")]}},{key:"item.options",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getCustomOptionValues(item.options)))+" ")]}},{key:"item.isSearchable",fn:function(ref){
    var item = ref.item;
return [(item.isSearchable)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editCustomField(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Custom Field")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.promptForDelete(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Custom Field")])])]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.showDeleteModal),callback:function ($$v) {_vm.showDeleteModal=$$v},expression:"showDeleteModal"}},[(_vm.customFieldToDelete)?_c('v-card',{staticClass:"p-3",attrs:{"justify":"center"}},[_c('v-card-title',[_vm._v(" Are you sure you want to delete custom field "+_vm._s((" " + (_vm.customFieldToDelete.name)))+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v("No ")]),_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":_vm.deleteCustomField}},[_vm._v("Yes ")])],1)],1):_vm._e()],1),_c('LoadingModal',{attrs:{"loadingText":_vm.loadingText,"showModal":_vm.isSubmitting}}),_c('CustomFieldNew',{ref:"newModal",on:{"created":_vm.getData}}),_c('CustomFieldEdit',{ref:"editModal",attrs:{"customFieldToEdit":_vm.customFieldToEdit},on:{"created":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }