











import Vue from 'vue';

import api from '@/common/api';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { Feature } from '@/features.types';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import {
  HostedPaymentPageResponse,
  HostedPaymentPageUpdate,
} from '@/common/types/hostedPaymentPages.types';
import { createUpdateModel, initHostedPaymentPage } from '@/common/util/hostedPaymentPages.util';
import { InfoField } from '@/common/components/support/support.types';
import HostedPaymentPageEditor from './HostedPaymentPageEditor.vue';

const SAVING_LOADING_TEXT: string = 'Saving...';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      Feature,
      isSubmitting: false,
      loadingText: SAVING_LOADING_TEXT as string,
      hppData: initHostedPaymentPage() as HostedPaymentPageUpdate,
      validationErrors: initValidationErrors() as ValidationErrors,
      infoFields: [] as any[],
      billingFields: [] as any[],
      billingRequiredFields: [] as any[],
      shippingFields: [] as any[],
      shippingRequiredFields: [] as any[],
    };
  },
  mounted() {
    if (!this.id) {
      (this.$refs.loader as any).setReady();
      return;
    }
    api
      .get(`/hosted-payments/${this.id}`)
      .then(({ data: { data } }) => {
        this.infoFields = data.infoFields;
        this.setCustomerInfoFields();

        const hppData = createUpdateModel(data);
        const cols = hppData.config[0].columns;
        Object.keys(cols).forEach((key: any) => {
          const colArr = cols[key];
          if (colArr.name === 'billingAddress' || colArr.name === 'shippingAddress') {
            const fieldCat = colArr.name === 'billingAddress' ? 'Billing' : 'Shipping';
            Object.keys(colArr.fields).forEach((fieldKey: any) => {
              if (colArr.fields[fieldKey].name === 'shippingEnabled' && !this.showShippingLink()) {
                hppData.config[0].columns[key].fields[fieldKey].hidden = true;
              } else if (!['billingHeading', 'shippingHeading'].includes(colArr.fields[fieldKey].name)) {
                if (!this.isVisible(colArr.fields[fieldKey].name, fieldCat)) {
                  hppData.config[0].columns[key].fields[fieldKey].hidden = false;
                } else {
                  hppData.config[0].columns[key].fields[fieldKey].hidden = false;
                }

                if (this.isRequired(colArr.fields[fieldKey].name, fieldCat)) {
                  hppData.config[0].columns[key].fields[fieldKey].required = true;
                } else {
                  hppData.config[0].columns[key].fields[fieldKey].required = false;
                }
              }
            });
          }
        });

        this.hppData = hppData;
        this.$store.commit('setHppTaxRate', this.hppData.taxRate);
        (this.$refs.loader as any).setReady();
      })
      .catch(() => {
        this.$toasted.error('Problem loading hosted payment page');
        (this.$refs.loader as any).setError();
      });
  },
  methods: {
    showShippingLink() {
      return typeof this.infoFields.find((x:InfoField) => x.category === 'Shipping') !== 'undefined';
    },
    getFieldName(name: string, removeName: string) {
      const fieldName = name.replace(removeName, '').replace(/ /g, '');
      return (fieldName.charAt(0).toLowerCase() + fieldName.slice(1));
    },
    isVisible(fieldName: string, fieldType: string) {
      return ((fieldType === 'Billing' && this.billingFields.includes(fieldName))
        || (fieldType === 'Shipping' && this.shippingFields.includes(fieldName)));
    },
    isRequired(fieldName: string, fieldType: string) {
      return ((fieldType === 'Billing' && this.billingRequiredFields.includes(fieldName))
        || (fieldType === 'Shipping' && this.shippingRequiredFields.includes(fieldName)));
    },
    setCustomerInfoFields() {
      Object.keys(this.infoFields).forEach((key: any) => {
        const field = this.infoFields[key] as InfoField;
        const fieldName = this.getFieldName(field.name, field.category);
        if (field.category === 'Billing') {
          this.billingFields.push(fieldName);
          if (field.required) {
            this.billingRequiredFields.push(fieldName);
          }
        } else if (field.category === 'Shipping') {
          this.shippingFields.push(fieldName);
          if (field.required) {
            this.shippingRequiredFields.push(fieldName);
          }
        }
      });
    },
    submitActions(isSubmitting: boolean, loadingText: string = SAVING_LOADING_TEXT) {
      this.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    showSuccess({ data }: { data: JsonApiSingleResponse<HostedPaymentPageResponse> }) {
      this.submitActions(false);
      this.$toasted.success('Hosted payment page saved successfully!');
      this.$router.push({ name: 'merchant.hosted-payments.view', params: { id: data.data!.id! } });
    },
    handleSaveError(response: any) {
      this.submitActions(false);
      const message = this.$errorMessages.get('hosted_payment_pages.save');
      this.$toasted.error(
        `${message} (${response.status})`,
      );
      this.validationErrors = response.data.errors || {};
    },
  },
  components: {
    PageLoader,
    HostedPaymentPageEditor,
    LoadingModal,
  },
});
