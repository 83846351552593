



















































































import Vue from 'vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { MerchantStatus } from './merchants.types';

export default Vue.extend({
  data() {
    return {
      EntityFilterType,
      MerchantStatus,
      title: 'Merchants' as string,
      perPage: 10 as number,
      search: '' as string,
      meta: {} as any,
      config: {
        pageTitle: 'Manage Merchants',
        header: 'Manage Merchants',
        routerBase: 'iso.merchants',
        apiPath: 'merchants',
        entityName: 'merchant',
        identifierProperty: 'company',
        reportFileName: 'merchant_report',
        apiFilterSearchPath: 'mcc-codes',
        filters: [
          {
            name: 'mccCode',
            display: 'MCC Code',
            value: '',
            type: EntityFilterType.TYPE_AHEAD,
          },
          {
            name: 'company',
            display: 'Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'contact',
            display: 'Contact',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'id',
            display: 'ID',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'dateStartCreatedAt',
            display: 'Start Date Added',
            type: EntityFilterType.DATE,
            value: '',
            open: false,
          },
          {
            name: 'dateEndCreatedAt',
            display: 'End Date Added',
            type: EntityFilterType.DATE,
            value: '',
            open: false,
          },
        ],
        headers: [
          {
            text: 'ID',
            value: 'id',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Merchant',
            value: 'company',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Contact',
            value: 'contact',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Created',
            value: 'createdAt',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Last Transaction',
            value: 'lastTransactionAt',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Gateway URL',
            value: 'domain',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Stage',
            value: 'status',
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
        ],
      },
    };
  },
  components: {
    EntitiesTable,
  },
});
