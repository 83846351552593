var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"p-1 rounded-b-0",attrs:{"justify":"center"}},[_c('v-card-title',[_vm._v(" Restrict Access to the Gateway to specific IP Addresses ")]),_c('v-card-text',[_c('v-container',{staticClass:"px-0 py-0",attrs:{"fluid":""}},[_c('div',{staticClass:"tw-inline-block"},[_c('v-switch',{attrs:{"label":("IP/Host Restrictions are " + (_vm.restrictIpSetting ? 'ON' : 'OFF'))},on:{"change":_vm.changeIpRestrictionSetting},model:{value:(_vm.restrictIpSetting),callback:function ($$v) {_vm.restrictIpSetting=$$v},expression:"restrictIpSetting"}})],1)])],1)],1),(_vm.restrictIpSettingLoaded && _vm.restrictIpSetting)?_c('EntitiesTable',{attrs:{"config":_vm.config,"showDownloadCsvButton":false,"reLoadData":_vm.reLoadData},on:{"dataLoaded":_vm.updateReloadData},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pr-2"},[_vm._v("Ip Whitelist")]),_c('v-btn',{attrs:{"x-small":"","color":"secondary"},on:{"click":_vm.openNewIpWhitelistModal}},[_vm._v("Add")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editIpWhitelistSetting(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Ip Whitelist")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteEntity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Ip Whitelist")])])]}}],null,false,1824403689)}):_vm._e(),_c('IpWhitelistNew',{ref:"newModal",on:{"created":_vm.getIpWhitelistData}}),_c('IpWhitelistEdit',{ref:"editModal",attrs:{"ipWhitelistToEdit":_vm.ipWhitelistToEdit},on:{"created":_vm.getIpWhitelistData}}),_c('DeleteModal',{attrs:{"deleteModal":_vm.deleteModal,"entitySelectedForDeletion":_vm.entitySelectedForDeletion,"config":_vm.config,"modalAction":"delete"},on:{"deleteEntity":_vm.deleteEntity,"closeDeleteModal":_vm.closeDeleteModal}}),_c('LoadingModal',{attrs:{"loadingText":"Deleting...","showModal":_vm.isDeleting}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }