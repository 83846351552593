<template>
  <div :class="{ big }"></div>
</template>

<script>
export default {
  props: {
    big: { type: Boolean },
  },
};
</script>

<style scoped>
div {
  --size: 0.5rem;

  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: #fff;
  outline: 1px solid #000;
  animation: growing 2s linear  infinite;
}

div.big {
  --size: 2.5rem;
}

@keyframes growing {
  from {
    transform: scale(0.25);
  }

  to {
    transform: scale(2.5);
    opacity: 0;
  }
}
</style>
