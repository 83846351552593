import { MerchantFeature } from '@/common/types/features.types';
import { SellRate } from '../merchants.types';

export enum RateGroupName {
  GATEWAY = 'Gateway',
  AUTOMATIC_CARD_UPDATER = 'Automatic Card Updater',
  CASH = 'Cash',
  CUSTOMER_VAULT = 'Customer Vault',
  INVOICING = 'Invoicing',
  ENHANCED_DATA_LEVEL_3 = 'Enhanced Data (Level II, III)',
  ELECTRONIC_CHECK = 'Electronic Check',
  SURCHARGING = 'Surcharging',
  SHOPPING_CART = 'Shopping Cart',
  NON_CASH_ADJUSTMENT = 'Cash Discount',
}

export interface RateGroup {
  name: RateGroupName;
  rates: SellRate[]
  feature: MerchantFeature | null;
}
