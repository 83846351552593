<template>
  <div class="customer-vault-dropdowns">
    <v-autocomplete
      v-model="customer"
      :items="customerOptions"
      :search-input.sync="search"
      :loading="loadingCustomers"
      :no-data-text="loadingCustomers ? 'Loading customers...' : 'No Data Available'"
      label="Select Customer from Vault"
      @focus="() => fetchCustomers()"
      dense
      flat
      outlined
      hide-details
      cache-items
      clearable
    />

    <v-select
      v-if="!noPayment && customer"
      v-model="paymentMethod"
      :items="paymentMethodOptions"
      :loading="loadingPaymentMethods"
      :no-data-text="loadingPaymentMethods ? 'Loading payment methods...' : 'No Data Available'"
      label="Select Customer Payment Method"
      dense
      flat
      outlined
      hide-details
    />
  </div>
</template>

<script>
import api from '@/common/api';

export default {
  props: {
    noPayment: { type: Boolean, default: false },
  },
  data() {
    return {
      customer: null,
      customers: [],
      paymentMethod: null,
      paymentMethods: [],
      search: null,
      loadingCustomers: false,
      loadingPaymentMethods: false,
    };
  },
  computed: {
    customerOptions() {
      return this.customers.map((x) => {
        const { company, firstName, lastName } = x.billingAddress;

        return { value: x.id, text: `${firstName} ${lastName} (${company})` };
      });
    },
    paymentMethodOptions() {
      return this.paymentMethods.map((x) => ({ value: x.id, text: `${x.type.toUpperCase()} - ${x.nickname}` }));
    },
  },
  watch: {
    search(value) {
      this.fetchCustomers(value);
    },
    customer(value) {
      const customer = this.customers.find((x) => x.id === value);
      if (!this.noPayment && customer) this.fetchPaymentMethods(value);
      if (!customer) {
        this.paymentMethod = null;
      }

      this.$emit('selectCustomer', customer);
    },
    paymentMethod(value) {
      const paymentMethod = this.paymentMethods.find((x) => x.id === value);

      this.$emit('selectPaymentMethod', paymentMethod);
    },
  },
  methods: {
    fetchCustomers(query) {
      this.loadingCustomers = true;
      const endpoint = `/customers?page=1&perPage=&role=&q=${query || ''}`;

      api.get(endpoint)
        .then(({ data }) => {
          this.customers = data.data;
        })
        .catch(() => {
          this.$toasted.error('Error while fetching the customer from the vault');
        })
        .finally(() => {
          this.loadingCustomers = false;
        });
    },
    fetchPaymentMethods(customerId) {
      this.loadingPaymentMethods = true;
      const endpoint = `/customers/${customerId}/payment-methods`;

      api.get(endpoint)
        .then(({ data }) => {
          this.paymentMethods = data.data;
        })
        .catch(() => {
          this.$toasted.error('Error while fetching the payment methods for the selected customer');
        })
        .finally(() => {
          this.loadingPaymentMethods = false;
        });
    },
  },
};
</script>

<style scoped>
.customer-vault-dropdowns {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-block: 2rem 0.75rem;
}

.customer-vault-dropdowns > * {
  flex: 1 1 20rem;
  max-width: 20rem;
}
</style>
