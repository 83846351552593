<template>
  <div v-if="message" class="maintenance" :class="{ 'dark-mode': darkMode, closed }">
    <div class="maintenance-header">
      <div>
        <i-alert class="maintenance-icon" />
        <strong class="maintenance-title">
          {{ closed ? message.shortTitle : message.title }}
        </strong>
        <button v-if="closed" class="maintenance-read-more" @click="closed = false">
          Read more >
        </button>
      </div>

      <button v-if="!closed" class="maintenance-close" @click="closed = true">
        x
      </button>
    </div>

    <p v-if="!closed">{{ message.text }}</p>
  </div>
</template>

<script>
import api from '@/common/api';
import { mapState } from 'vuex';
import IAlert from '../icons/i-alert.vue';

export default {
  components: { IAlert },
  data() {
    return {
      message: null,
      closed: false,
    };
  },
  computed: {
    ...mapState(['darkMode']),
    id() {
      return `maintenance-${this.message?.id}`;
    },
  },
  watch: {
    closed(value) {
      localStorage.setItem(this.id, value);
    },
  },
  created() {
    api.get('/maintenance').then(({ data }) => {
      if (data.data.enabled !== 1) return;

      this.message = data.data;
      this.closed = localStorage.getItem(this.id) || false;
    });
  },
};
</script>

<style scoped>
.maintenance {
  padding: 1rem 1.5rem;
  box-shadow: 0px 10px 30px rgba(126, 135, 140, 0.1);
  margin-block-end: 1.25rem;
  z-index: 1;
  position: relative;
}

.maintenance::before {
  z-index: -1;
  content: '';
  position: absolute;
  inset: 0;
  background: var(--primary-color);
  filter: brightness(3) saturate(0.5);
}

.maintenance.dark-mode::before {
  filter: brightness(0.7);
}

.maintenance.closed {
  padding: 0.75rem;
  margin-block-start: -12px;
}

.maintenance-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.maintenance-header > div {
  display: flex;
  align-items: center;
}

.maintenance-title {
  font-family: 'Montserrat';
  margin-inline-start: 1rem;
}

.maintenance-read-more {
  color: var(--primary-color);
  font-size: 0.875rem;
  margin-inline-start: 0.4em;
}

.maintenance-close {
  line-height: 0.5;
  font-size: 1.2rem;
  opacity: 0.6;
}

p {
  font-size: 0.75rem;
  line-height: 1.66;
  margin-block: 1em 0;
  opacity: 0.8;
}

.maintenance.closed .maintenance-icon {
  height: 1.125rem;
  width: 1.25rem;
}

.maintenance.closed .maintenance-title {
  font-family: 'Source Sans 3';
  font-size: 0.875rem;
  margin-inline-start: 0.5rem;
}
</style>
