










































import Vue from 'vue';

import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { Plan } from './plans.types';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      plan: null as Plan | null,
      validationErrors: {} as any,
    };
  },
  created() {
    document.title = 'RiseOS-View Plan';
  },
  mounted() {
    api
      .get(`/plans/${this.id}`)
      .then(({ data: { data } }) => {
        this.plan = data;
        (this.$refs.loader as any).setReady();
      })
      .catch(() => {
        this.$toasted.error('Problem loading plan');
        (this.$refs.loader as any).setError();
      });
  },
  methods: {
    deletePlan() {
      if (this.plan) {
        api
          .delete(`/plans/${this.plan.id}`)
          .then(({ data: { data: plan } }) => {
            this.$toasted.success('Success');
            this.plan = plan;
            this.$router.push({ name: 'merchant.plans.index' });
          })
          .catch(({ response }) => {
            this.$toasted.error(response.data.message);
          });
      }
    },
    editPlan() {
      this.$router.push({ name: 'merchant.plans.edit', params: { id: this.id } });
    },
  },
  computed: {
    pageTitle(): string {
      if (!this.plan) {
        return '';
      }
      return this.plan.name;
    },
    status(): string {
      return !this.plan!.deactivatedAt ? 'Active' : 'Not Active';
    },
    recurrenceText(): string {
      return this.plan!.recurrenceText.replace('time', 'installment');
    },
  },
  components: {
    PageHeader,
    PageLoader,
  },
});
