








































import Vue from 'vue';
import AchPaymentMethod from '@/common/components/AchPaymentMethod.vue';
import {
  PaymentMethod, PaymentType,
} from '@/common/types/payments.types';
import CreditCardPaymentMethod from './CreditCardPaymentMethod.vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => PaymentMethod,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    validationPrefix: {
      type: String,
      required: false,
      default: '',
    },
    creditCardValidationPrefix: {
      type: String,
      required: false,
      default: '',
    },
    achValidationPrefix: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      // localValueForTest is to make tests based on computed properties pass
      // without computed is not reactive in tests
      localValueForTest: this.value as PaymentMethod,
      PaymentType,
      isEdit: true as boolean,
    };
  },
  components: {
    AchPaymentMethod,
    CreditCardPaymentMethod,
  },
});
