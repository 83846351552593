























import Vue from 'vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import {
  ResidualFeeType,
  ResidualFeeTypeFilterOption,
  Commissions,
} from '@/common/types/residuals.types';
import AdminReportsPagesTabs from '@/admin/components/AdminReportsPagesTabs.vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import dateFormat from 'dateformat';

export default Vue.extend({
  data() {
    return {
      config: {
        pageTitle: 'Commissions Report',
        apiPath: 'commissions',
        reportFileName: 'commissions_report',
        headers: [
          {
            text: 'Date',
            value: 'createdAt',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Merchant',
            value: 'merchantName',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Affiliate',
            value: 'isoName',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Fee type',
            value: 'rateKey',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Quantity',
            value: 'quantity',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Buy Rate',
            value: 'buyRate',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Sell Rate',
            value: 'sellRate',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Commission',
            value: 'commission',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
        ],
        filters: [
          {
            name: 'merchantName',
            display: 'Merchant Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'isoName',
            display: 'Affiliate Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'startDate',
            display: 'Start Date',
            type: EntityFilterType.DATE,
            value: dateFormat(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              'yyyy-mm-dd',
            ),
            open: false,
          },
          {
            name: 'endDate',
            display: 'End Date',
            type: EntityFilterType.DATE,
            value: dateFormat(new Date(), 'yyyy-mm-dd'),
            open: false,
          },
          {
            name: 'rateKey',
            display: 'Fee type',
            value: '',
            type: EntityFilterType.SELECT,
            items: [
              { display: 'Cash Setup Fee', value: ResidualFeeType.CASH_SERVICE_FEE },
              { display: 'Cash Monthly Fee', value: ResidualFeeType.CASH_MONTHLY_FEE },
              { display: 'Cash Per Transaction Fee', value: ResidualFeeType.CASH_TRANSACTION_FEE },
              { display: 'Gateway Setup Fee', value: ResidualFeeType.GATEWAY_SERVICE_FEE },
              { display: 'Gateway Monthly Fee', value: ResidualFeeType.GATEWAY_MONTHLY_FEE },
              { display: 'Gateway Batch Settlement Fee', value: ResidualFeeType.GATEWAY_BATCH_SETTLEMENT_FEE },
              {
                display: 'Gateway Per Transaction Fee',
                value: ResidualFeeType.GATEWAY_TRANSACTION_FEE,
              },
              {
                display: 'Electronic Check Setup Fee',
                value: ResidualFeeType.ELECTRONIC_CHECK_SETUP_FEE,
              },
              {
                display: 'Electronic Check Monthly Fee',
                value: ResidualFeeType.ELECTRONIC_CHECK_MONTHLY_FEE,
              },
              {
                display: 'Electronic Check Per Transaction Fee',
                value: ResidualFeeType.ELECTRONIC_CHECK_TRANSACTION_FEE,
              },
              {
                display: 'Electronic Check Batch Fee',
                value: ResidualFeeType.ELECTRONIC_CHECK_BATCH_FEE,
              },
              {
                display: 'Customer Vault Service Fee',
                value: ResidualFeeType.CUSTOMER_VAULT_SERVICE_FEE,
              },
              {
                display: 'Customer Vault Monthly Fee',
                value: ResidualFeeType.CUSTOMER_VAULT_MONTHLY_FEE,
              },
              {
                display: 'Customer Vault Transaction Fee',
                value: ResidualFeeType.CUSTOMER_VAULT_TRANSACTION_FEE,
              },
              {
                display: 'Cash Discount Per Transaction Fee',
                value: ResidualFeeType.NONCASH_TRANSACTION_FEE,
              },
              {
                display: 'Cash Discount Monthly Fee',
                value: ResidualFeeType.NONCASH_MONTHLY_FEE,
              },
              {
                display: 'Cash Discount Service Fee',
                value: ResidualFeeType.NONCASH_SERVICE_FEE,
              },
              {
                display: 'Surcharging Monthly Fee',
                value: ResidualFeeType.SURCHARGING_MONTHLY_FEE,
              },
              {
                display: 'Surcharging Service Fee',
                value: ResidualFeeType.SURCHARGING_SERVICE_FEE,
              },
              {
                display: 'Enhanced Level II, III Monthly Fee',
                value: ResidualFeeType.ENHANCED_DATA_MONTHLY_FEE,
              },
              {
                display: 'Enhanced Level II, III Service Fee',
                value: ResidualFeeType.ENHANCED_DATA_SERVICE_FEE,
              },
            ] as ResidualFeeTypeFilterOption[],
          },
        ],
        footer: {
          header: 'Total',
          displayHeaders: ['commission'],
          displayFunction(filters: any, entities: any[], header: string) {
            if (header === 'commission') {
              const formatted = filters.number2Decimals(
                entities.reduce(
                  (sum: number, residual: Commissions) => sum + residual.raw,
                  0,
                ),
              );
              return `$${formatted}`;
            }
            return '';
          },
        },
      },
    };
  },
  components: { EntitiesTable, AdminReportsPagesTabs },
});
