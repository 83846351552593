

































import Vue from 'vue';

import api from '@/common/api';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { cloneDeep } from 'lodash';
import { BillingRecurrenceModel } from '@/common/types/billingRecurrences.types';
import { initBillingRecurrenceModel } from '@/common/util/billingRecurrences.util';
import { Subscription } from '@/common/types/subscriptions.types';
import BillingRecurrence from '../components/BillingRecurrence.vue';

export default Vue.extend({
  props: {
    subscription: {
      type: Object as () => Subscription,
      required: true,
    },
  },
  data() {
    return {
      title: 'Edit Subscription' as string,
      editedSubscription: null as Subscription | null,
      billingRecurrence: initBillingRecurrenceModel() as BillingRecurrenceModel,
      isSubmitting: false,
    };
  },
  mounted() {
    this.editedSubscription = cloneDeep(this.subscription);
  },
  methods: {
    submitActions(isSubmitting: boolean, resetForms: boolean = false) {
      this.isSubmitting = isSubmitting;
      if (!isSubmitting && resetForms) {
        this.resetForms();
      }
    },
    resetForms() {
      this.editedSubscription = cloneDeep(this.subscription);
    },
    saveSubscription() {
      this.submitActions(true);
      const subscription = this.editedSubscription!;
      subscription.amount = this.billingRecurrence.amount;
      subscription.rruleText = this.billingRecurrence.recurrence.rruleText;
      subscription.recurrenceFrequency = this.billingRecurrence.recurrence.recurrenceFrequency;
      subscription.recurrenceText = this.billingRecurrence.recurrence.recurrenceText;
      api
        .put(`subscriptions/${this.subscription.id}`, subscription)
        .then(() => {
          this.submitActions(false, true);
          this.resetForms();
          this.$toasted.success('Subscripotion updated successfully!');
          this.$emit('subscription-updated');
        })
        .catch(({ response }) => {
          this.submitActions(false);
          this.$toasted.error(response.data.message);
        });
    },
  },
  components: {
    BillingRecurrence,
    LoadingModal,
  },
});
