<template>
  <h1 class="page-title" :class="{ 'dark-mode': darkMode }">
    <slot />
  </h1>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['darkMode']),
  },
};
</script>

<style scoped>
.page-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.22;
  color: #424242;
  margin-block-end: 1.25em;
}

.page-title.dark-mode {
  color: #dbdbdb;
}
</style>
