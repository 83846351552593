


















































































































































import Vue from 'vue';

import api from '@/common/api';
import PageLoader from '@/common/components/PageLoader.vue';
import PageHeader from '@/common/components/PageHeader.vue';
import Icon from '@/common/icon';
import { DateTime } from 'luxon';
import { PaymentMethod, PaymentType } from '@/common/types/payments.types';
import { generatePaymentMethodTableData } from '@/common/util/payments.util';
import { Transaction } from '@/common/types/transactions.types';
import { Subscription, SubscriptionStatus } from '@/common/types/subscriptions.types';
import DeleteModal from '@/common/components/modals/DeleteModal.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import AFlashMessage from '@/components/atoms/a-flash-message.vue';
import Address from '../transactions/components/Address.vue';
import SubscriptionTransactionsViewTable from '../components/transactions/SubscriptionTransactionsViewTable.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      SubscriptionStatus,
      generatePaymentMethodTableData,
      title: 'View Subscription' as string,
      subscription: null as Subscription | null,
      transactions: null as Transaction[] | null,
      showCancelSubscriptionModal: false,
      deleteModal: false,
      cancelModal: false,
      entitySelectedForDeletion: {} as any,
      entitySelectedForCancel: {} as any,
    };
  },
  created() {
    document.title = 'RiseOS-View Subscription';
  },
  mounted() {
    this.loadSubscription();
  },
  methods: {
    loadSubscription() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api
        .get(`/subscriptions/${this.id}`)
        .then(this.setSubscription)
        .catch(() => {
          this.$toasted.error('Problem loading subscription');
          (this.$refs.loader as any).setError();
        });
    },
    setSubscription({ data }: { data: JsonApiSingleResponse<Subscription> }) {
      if (data.data === undefined || data.data.id === undefined) {
        return;
      }
      this.subscription = data.data;
      (this.$refs.loader as any).setReady();
    },
    handleModalClick(action: string) {
      if (action === 'delete') {
        this.deleteSubscription();
      } else {
        this.cancelSubscription();
      }
    },
    cancelSubscription() {
      if (this.subscription) {
        api
          .patch(`/subscriptions/${this.subscription.id}/cancel`)
          .then(({ data: { data: subscription } }) => {
            this.$toasted.success('Success');
            this.subscription = subscription;
            this.$router.push({ name: 'merchant.reports.recurring' });
          })
          .catch(({ response }) => {
            this.$toasted.error(response.data.message);
          });
      }
    },
    deleteSubscription() {
      if (this.subscription) {
        api
          .delete(`/subscriptions/${this.subscription.id}`)
          .then(({ data: { data: subscription } }) => {
            this.$toasted.success('Success');
            this.subscription = subscription;
            this.$router.push({ name: 'merchant.reports.recurring' });
          })
          .catch(({ response }) => {
            this.$toasted.error(response.data.message);
          });
      }
    },
    editSubscription() {
      this.$router.push({ name: 'merchant.subscriptions.edit', params: { id: this.id } });
    },
    confirmCancelEntity(entity: any) {
      this.entitySelectedForCancel = entity;
      this.cancelModal = true;
    },
    confirmDeleteEntity(entity: any) {
      this.entitySelectedForDeletion = entity;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    closeCancelModal() {
      this.cancelModal = false;
    },
    enableSubscription() {
      if (this.subscription?.status === SubscriptionStatus.CANCELED) {
        api
          .patch(`/subscriptions/${this.subscription?.id}`)
          .then(({ data: { data: subscription } }) => {
            this.$toasted.success('Success');
            this.subscription = subscription;
            this.$router.push({ name: 'merchant.reports.recurring' });
          })
          .catch(({ response }) => {
            this.$toasted.error(response.data.message);
          });
      }
    },
  },
  computed: {
    pageTitle(): string {
      if (!this.subscription) {
        return '';
      }
      return this.subscription.name;
    },
    mobileView(): boolean {
      return this.$vuetify.breakpoint.name === 'xs'
      || this.$vuetify.breakpoint.name === 'sm';
    },
    desktopAndLargerView(): boolean {
      return this.$vuetify.breakpoint.name === 'xl'
      || this.$vuetify.breakpoint.name === 'lg';
    },
    hasBillingAddress(): boolean {
      return (
        this.subscription !== null
        && this.subscription.customer?.billingAddress !== null
        && this.subscription.customer?.billingAddress !== undefined
      );
    },
    hasShippingAddress(): boolean {
      return (
        this.subscription !== null
        && this.subscription.customer?.shippingAddress !== null
        && this.subscription.customer?.shippingAddress !== undefined
      );
    },
    isCredit(): boolean {
      return this.subscription?.paymentMethod?.type === PaymentType.CREDIT;
    },
    isAch(): boolean {
      return this.subscription?.paymentMethod?.type === PaymentType.ACH;
    },
    paymentMethod(): PaymentMethod | null {
      return this.subscription!.paymentMethod ?? null;
    },
    subscriptionTypeIcon(): string {
      if (this.isCredit) {
        return 'credit-card';
      }
      return 'money-bill-alt';
    },
    expirationDate(): string {
      if (!this.isCredit) {
        return '';
      }

      const { expMonth: month, expYear: year } = this.paymentMethod?.creditCard!;
      return `Exp: ${month}/${year}`;
    },
    paymentsMade(): string {
      // eslint-disable-next-line operator-linebreak
      const installments: string =
        this.subscription!.installments && this.subscription!.installments > 0
          ? `/${this.subscription!.installments}`
          : '';
      const paymentCount: number = this.subscription!.paymentsCount ?? 0;
      return `${paymentCount}${installments}`;
    },
    startDate(): string {
      const { startDate } = this.subscription!;
      if (startDate) {
        const dt = DateTime.fromISO(startDate.toLocaleString());
        const formattedDate = dt.toLocaleString(DateTime.DATE_SHORT).replace(', ', '\n');
        return formattedDate;
      }
      return 'N/A';
    },
    nextRun(): string {
      const { nextRun } = this.subscription!;
      if (nextRun) {
        const dt = DateTime.fromISO(nextRun.toLocaleString());
        const formattedDate = dt.toLocaleString(DateTime.DATE_SHORT).replace(', ', '\n');
        return formattedDate;
      }
      return 'N/A';
    },
    lastRun(): string {
      const { lastRun } = this.subscription!;
      if (lastRun) {
        const dt = DateTime.fromISO(lastRun.toLocaleString());
        const formattedDate = dt.toLocaleString(DateTime.DATE_SHORT).replace(', ', '\n');
        return formattedDate;
      }
      return 'N/A';
    },
    lastStatus(): string {
      const { lastRunStatus } = this.subscription!;
      if (lastRunStatus) {
        return lastRunStatus;
      }
      return 'N/A';
    },
    recurrenceText(): string {
      return this.subscription!.recurrenceText.replace('time', 'installment');
    },
    isCustomerInVault(): boolean {
      const customer = this.subscription?.customer;
      return customer !== undefined && customer !== null && customer.inVault;
    },
    isSubscriptionActive(): boolean {
      return this.subscription?.status !== SubscriptionStatus.CANCELED;
    },
  },
  components: {
    PageLoader,
    Address,
    Icon,
    SubscriptionTransactionsViewTable,
    DeleteModal,
    PageHeader,
    AFlashMessage,
  },
});
