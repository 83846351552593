
























































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { CustomFieldType, InfoField } from '@/common/components/support/support.types';
import Permissions from '@/common/permissions';
import {
  PaymentType,
  PaymentTypeDisplay,
  CreditMethod,
  AchAccountHolderType,
} from '@/common/types/payments.types';
import {
  allowedProcessors as allowedProcessorsForCurrentUser,
  filterProcessorsBasedOnPaymentType,
} from '@/common/util/processors.util';
import { readablePaymentMethod } from '@/common/util/payments.util';
import { Processor, ProcessorType } from '@/common/types/processors.types';
import SingleTransaction from '@/common/components/payments/SingleTransaction.vue';
import { debounce } from 'lodash';
import api from '@/common/api';
import { Feature } from '@/features.types';
import {
  getLevel3FieldProgress,
  getEnhancedFieldProgress,
  getLevelProgress,
} from '@/common/util/level.util';
import CustomerForm from '../customers/CustomerForm.vue';
import { VaultCustomerPaymentConfig, CustomerPaymentAutomation } from './customerPayments.types';
import PaymentCustomFields from './PaymentCustomFields.vue';
import PaymentMethodCard from '../payments/PaymentMethodCard.vue';
import RecurringPaymentForm from '../payments/RecurringPaymentForm.vue';
import SearchPlans from '../SearchPlans.vue';
import { Plan } from '../../plans/plans.types';


export default Vue.extend({
  props: {
    value: {
      type: Object as () => VaultCustomerPaymentConfig,
      required: true,
    },
    loadingText: {
      type: String,
      required: true,
    },
    showCustomFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentProcessor: {
      type: Object as () => Processor,
      required: false,
    },
    automation: {
      type: Number,
      required: false,
      default: CustomerPaymentAutomation.ALLOW_RECURRING,
    },
    showInfoFields: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      AchAccountHolderType,
      PaymentTypeDisplay,
      PaymentType,
      CustomFieldType,
      CreditMethod,
      cardType: PaymentType.CREDIT as string,
      Feature,
      enhancedData: false as boolean,
      progressCount: 0 as number,
      level1progressColor: '' as string,
      level2progressColor: '' as string,
      level3progressColor: '' as string,
      level1Progress: false as boolean,
      level2Progress: false as boolean,
      level3Progress: false as boolean,
      validateEnhancedFields: false as boolean,
      paymentFields: [] as any[],
      paymentRequiredFields: [] as any[],
    };
  },
  watch: {
    showInfoFields() {
      this.setCustomerInfoFields();
    },
  },
  computed: {
    ...mapGetters([
      'currentUserCan',
      'brandName',
      'processors',
      'context',
      'isSurchargingEnabled',
      'isEnhancedDataEnabled',
    ]),
    canAuth(): boolean {
      return this.currentUserCan(Permissions.RUN_CC_AUTH);
    },
    canAuthAndCapture(): boolean {
      return this.currentUserCan(Permissions.RUN_CC_SALE);
    },
    methods(): { value: string; display: string }[] {
      const methods = [];
      if (this.canAuthAndCapture) {
        methods.push({
          value: CreditMethod.AUTH_AND_CAPTURE,
          display: 'Authorize & Capture',
        });
      }
      if (this.canAuth) {
        methods.push({
          value: CreditMethod.AUTH_ONLY,
          display: 'Authorize only',
        });
      }
      return methods;
    },
    allowedProcessors(): Processor[] {
      return allowedProcessorsForCurrentUser(this.value.processors, this.currentUserCan).filter(
        (processor) => processor.id === this.value.paymentMethod.processorId!,
      );
    },
    supportedProcessors(): Processor[] {
      return filterProcessorsBasedOnPaymentType(
        this.value.processors,
        this.value.paymentMethod.type,
      ).filter((processor) => processor.id === this.value.paymentMethod.processorId!);
    },
    allowedAndSupportedProcessors(): Processor[] {
      return filterProcessorsBasedOnPaymentType(
        this.allowedProcessors,
        this.value.paymentMethod.type,
      ).filter((processor) => processor.id === this.value.paymentMethod.processorId!);
    },
    isCreditTransaction(): boolean {
      return this.value.paymentMethod.type === PaymentType.CREDIT;
    },
    isAchTransaction(): boolean {
      return this.value.paymentMethod.type === PaymentType.ACH;
    },
    isBusinessAchTransaction(): boolean {
      return (
        this.isAchTransaction
        && this.value.paymentMethod.ach?.accountHolderType === AchAccountHolderType.BUSINESS
      );
    },
    readablePaymentMethod(): string {
      return readablePaymentMethod(this.value.paymentMethod.type);
    },
    isPaymentTypeAvailable(): boolean {
      return this.allowedAndSupportedProcessors.length > 0;
    },
    isPaymentTypeUnavailableDueToPermissions(): boolean {
      return !this.isPaymentTypeAvailable && this.supportedProcessors.length > 0;
    },
    isCardTypeCredit(): boolean {
      return this.cardType === PaymentType.CREDIT;
    },
    total(): number {
      if (this.isSurchargingEnabled && this.isCreditTransaction && this.isCardTypeCredit) {
        return this.value.transaction.amount + this.surchargeFee;
      }
      return this.value.transaction.amount;
    },
    surchargeFee(): number {
      if (this.isSurchargingEnabled && this.isCreditTransaction && this.isCardTypeCredit) {
        const calculatedSurchargeFee = (this.value.transaction.amount
         * this.value.customer.surchargePercent) / 100;
        return calculatedSurchargeFee;
      }
      return 0;
    },
    surchargePercent(): number {
      return this.cardType !== PaymentType.CREDIT ? 0
        : this.value.customer.surchargePercent;
    },
    isValid(): boolean {
      return (this.$refs.customerForm as any).isValid;
    },
    paymentTypeProcessors() {
      const processors = filterProcessorsBasedOnPaymentType(
        this.value.processors,
        this.value.paymentMethod.type,
      );

      if (processors.length === 1) {
        this.setProcessorId(processors[0].id);
      }
      return processors;
    },
    isRecurring(): boolean {
      return this.value.isRecurring;
    },
    allowRecurring(): boolean {
      return this.automation === CustomerPaymentAutomation.ALLOW_RECURRING;
    },
  },
  mounted() {
    this.$store
      .dispatch('loadMerchantProcessors', this.context)
      .then(() => {
        this.value.processors = this.processors;

        // Set Customer Information Fields for category "Payment"
        this.setCustomerInfoFields();

        (this.$refs.processorLoader as any).setReady();
        this.changeTerminal();
      })
      .catch(() => {
        this.$toasted.error('Failed to load processors!');
        this.$router.push({ name: 'merchant.dashboard' });
      });
    this.checkCardType();
    this.finishLoading();
  },
  methods: {
    setProcessorId(processorId: any) {
      this.value.paymentMethod.processorId = processorId;
    },
    setTotal(amount: number) {
      this.value.transaction.amount = amount;
      this.setlAllLevelProgress();
    },
    checkCardType: debounce(function debounced(this: any) {
      let creditCardNumber = this?.value?.paymentMethod?.creditCard?.cardNumber;
      creditCardNumber = creditCardNumber !== undefined ? creditCardNumber.replaceAll(/\s/g, '') : '';
      if (creditCardNumber === '' || creditCardNumber.length < 6) {
        this.cardType = PaymentType.CREDIT;
        return;
      }
      api
        .post('credit-card-number', { creditCardNumber })
        .then(({ data }) => {
          this.setCardType(data);
        })
        .catch(({ response }) => {
          this.catchCardTypeError(response);
        });
    }, 1000),
    setCardType(data: any) {
      this.cardType = data.data.card_type;
    },
    changeTerminal() {
      const filtered = this.processors.filter(
        (item: any) => item.id === this.value.paymentMethod.processorId,
      );
      this.enhancedData = [
        ProcessorType.ELAVON,
        ProcessorType.TSYS,
        ProcessorType.FIRSTDATA,
      ].includes(
        filtered[0]?.key,
      );
      if (this.enhancedData
          && typeof this.currentProcessor?.id !== 'undefined'
          && this.currentProcessor?.id !== this.value.paymentMethod.processorId
      ) {
        this.setlAllLevelProgress();
      }
      this.$emit('changeTerminal', filtered[0]);
    },
    setLevelProgress() {
      if (this.isEnhancedDataEnabled && this.enhancedData) {
        this.validateEnhancedFields = true;

        const levelProgressData = getLevelProgress(
          this.level1Progress,
          this.level2Progress,
          this.level3Progress,
        );
        this.progressCount = levelProgressData.progressCount;
        this.level1progressColor = levelProgressData.level1progressColor;
        this.level2progressColor = levelProgressData.level2progressColor;
        this.level3progressColor = levelProgressData.level3progressColor;

        this.$emit('setTransactionProgress', this.progressCount);
      }
    },
    setlAllLevelProgress() {
      if (this.isEnhancedDataEnabled) {
        this.setlLevel1Progress();
        this.setEnhancedFieldProgress();
        this.setLevel3FieldProgress();

        this.setLevelProgress();
      }
    },
    setlLevel1Progress() {
      const { amount } = this.value.transaction;
      this.level1Progress = !((amount === 0 || Number.isNaN(Number(amount))));
    },
    setEnhancedProgress() {
      this.setlLevel1Progress();
      this.setLevel3FieldProgress();
      this.setLevelProgress();
    },
    setLevel3Progress() {
      this.setlLevel1Progress();
      this.setEnhancedFieldProgress();
      this.setLevelProgress();
    },
    setEnhancedFieldProgress: debounce(function debounced(this: any) {
      if (this.isEnhancedDataEnabled && this.enhancedData) {
        const level2Data = getEnhancedFieldProgress(
          this.value.enhancedFields,
          this.value?.defaultLevel3Setting,
        );
        this.level2Progress = level2Data.validEnhancedFields;
        this.value.enhancedFields = level2Data.enhancedFieldVals;
        this.setEnhancedProgress();
      }
    }, 1000),
    setLevel3FieldProgress: debounce(function debounced(this: any) {
      if (this.isEnhancedDataEnabled
            && this.enhancedData
            && typeof this.value?.defaultLevel3Setting !== 'undefined'
      ) {
        this.level3Progress = getLevel3FieldProgress(this.value?.defaultLevel3Setting);
        this.setLevel3Progress();
      }
    }, 1000),
    setSingle() {
      this.value.isRecurring = false;
    },
    setRecurring() {
      this.value.isRecurring = true;
      if (this.value.paymentMethod.type === PaymentType.CASH) {
        this.value.paymentMethod.type = PaymentType.CREDIT;
      }
    },
    updateSelectedPlan(selectedPlan: Plan) {
      this.value.recurrence.plan = selectedPlan;
      this.value.recurrence.name = this.value.recurrence.plan?.name ?? '';
      this.value.recurrence.description = this.value.recurrence.plan?.description ?? '';
      this.value.recurrence.billingRecurrence.recurrence.rruleText = this.value.recurrence.plan?.rruleText ?? '';
    },
    setCustomerInfoFields() {
      Object.keys(this.value.infoFields).forEach((key: any) => {
        const infoField = this.value.infoFields[key] as InfoField;
        if (infoField.category === 'Payment') {
          this.paymentFields.push(infoField.name);
          if (infoField.required) {
            this.paymentRequiredFields.push(infoField.name);
          }
        }
      });
    },
    isVisible(fieldName: string) {
      return this.paymentFields.includes(fieldName);
    },
    isRequired(fieldName: string) {
      return this.paymentRequiredFields.includes(fieldName);
    },
    finishLoading() {
      if (Object.keys(this.value.infoFields).length && this.value.infoFields[0].name !== '') {
        this.setCustomerInfoFields();
      }
    },
  },
  components: {
    CustomerForm,
    LoadingModal,
    SingleTransaction,
    PaymentCustomFields,
    PageLoader,
    PaymentMethodCard,
    RecurringPaymentForm,
    SearchPlans,
  },
});
