












import Vue from 'vue';
import { HostedPaymentPageUpdate } from '@/common/types/hostedPaymentPages.types';
import DollarAmountInput from '../payments/DollarAmountInput.vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => HostedPaymentPageUpdate,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      fixedAmount: {
        amount: 0 as any,
      },
    };
  },
  mounted() {
    this.value.isFixedAmount = true;
    this.fixedAmount.amount = (this.value.amount / 100).toFixed(2);
  },
  watch: {
    fixedAmount: {
      handler() {
        this.emitUpdateEvent();
      },
      deep: true,
    },
  },
  methods: {
    emitUpdateEvent() {
      this.$emit('setFixedAmount', this.fixedAmount.amount * 100);
    },
  },
  components: {
    DollarAmountInput,
  },
});
