




import Vue from 'vue';
import IpBlockers from '@/common/components/support/IpBlockers.vue';

export default Vue.extend({
  components: {
    IpBlockers,
  },
});
