import Permissions from '../permissions';
import { AchAccountHolderType, PaymentType } from '../types/payments.types';
import { Processor, ProcessorType } from '../types/processors.types';

export function initProcessor(key: string | null = null): Processor {
  return {
    id: Math.random().toString(36),
    key,
    name: '',
    config: {
    },
  };
}

export function initPayaProcessor(type: AchAccountHolderType): Processor {
  return {
    id: Math.random().toString(36),
    key: ProcessorType.PAYA,
    name: '',
    config: {
      mid: '',
      tid: '',
      bin: '',
      type,
    },
  };
}

export function allowedProcessors(
  processors: Processor[],
  currentUserCan: (perm: Permissions) => boolean,
): Processor[] {
  const paymentTypePermissionsMap = {
    [PaymentType.CREDIT]: [Permissions.RUN_CC_SALE, Permissions.RUN_CC_AUTH],
    [PaymentType.ACH]: [Permissions.RUN_ACH_SALE],
    [PaymentType.CASH]: [Permissions.RUN_CASH_SALE],
  };

  return processors.filter(
    (processor) => Object.keys(paymentTypePermissionsMap)
      .reduce(
        (can: boolean, paymentType: any) => can || (
          processor.supports === paymentType
          && paymentTypePermissionsMap[paymentType as PaymentType]
            .reduce(
              (canPerm: boolean, perm: Permissions) => can || currentUserCan(perm),
              false,
            )
        ),
        false,
      ),
  );
}

export function filterProcessorsBasedOnPaymentType(
  processors: Processor[],
  type: PaymentType,
): Processor[] {
  return processors.filter((p) => p.supports === type);
}

export function availableProcessorsDisplayTest(display: string): string {
  switch (display) {
    case ProcessorType.PAYA:
      return 'Electronic Check';
    default:
      return display;
  }
}
