




import Vue from 'vue';
import InvoiceSettings from '@/common/components/support/InvoiceSettings.vue';

export default Vue.extend({
  components: {
    InvoiceSettings,
  },
});
