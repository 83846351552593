import axios from 'axios';
import getDomain from './domain';


const headers = {
  common: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

const instance = axios.create({
  // Change to use an external API base provider, in this case FA
  baseURL: getDomain('api'),
  withCredentials: true,
  headers,
});

instance.interceptors.request.use((config) => ({
  ...config,
  headers: {
    common: {
      ...config.headers.common,
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'x-refresh-token': localStorage.getItem('refresh_token'),
    },
  },
}));

export default instance;
