




















































import Vue from 'vue';
import api from '@/common/api';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { InfoField, initInfoField, InfoFieldCategory } from './support.types';

export default Vue.extend({
  props: {
    infoFieldToEdit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newInfoField: initInfoField() as InfoField,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      infoFieldId: '' as string,
      isLoading: false as boolean,
      InfoFieldCategory,
      fieldCategories: Object.values(InfoFieldCategory) as string[],
    };
  },
  watch: {
    infoFieldToEdit() {
      this.infoFieldId = this?.infoFieldToEdit;
    },
    infoFieldId() {
      this.loadInfoFieldData();
    },
  },
  mounted() {
    this.loadInfoFieldData();
  },
  methods: {
    loadInfoFieldData() {
      if (this?.infoFieldId === undefined || this?.infoFieldId === '') return;

      api
        .get(`/info-fields/${this.infoFieldId}`)
        .then(({ data: { data } }) => {
          this.newInfoField = data;
          this.isLoading = false;
          this.showModal = true;
        })
        .catch(() => {
          this.$toasted.error('Problem loading info field');
        });
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }

      this.submitActions(true);

      api
        .put(`/info-fields/${this.infoFieldId}`, {
          name: this.newInfoField.name,
          inVirtualTerminal: this.newInfoField.inVirtualTerminal,
          inHPP: this.newInfoField.inHPP,
          required: this.newInfoField.required,
          category: this.newInfoField.category,
        })
        .then(() => {
          this.close();
          this.newInfoField = initInfoField();
          this.$emit('created');
          (this.$refs.form as any).reset();
        })
        .catch(({ response }: { response: any }) => {
          this.close();
          this.$toasted.error(`There was a problem creating new info field. (${response.status})`);
        });
    },
    show() {
      this.infoFieldId = this?.infoFieldToEdit;
      this.isLoading = true;
      this.showModal = false;
    },
    close() {
      this.submitActions(false);
      this.showModal = false;
      this.infoFieldId = '';
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    LoadingModal,
  },
});
