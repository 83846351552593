<template>
  <li>
    <router-link :to="to" :class="{ 'dark-mode': darkMode }">
      <slot />
    </router-link>
  </li>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    to: { type: Object, required: true },
  },
  computed: {
    ...mapState(['darkMode']),
  },
};
</script>

<style scoped>
li {
  display: flex;
}

a {
  background: #D1D9E2;
  font-size: 0.875rem;
  font-weight: 600;
  color: #666666;
  padding: clamp(0.35em, 2.8vw, 0.7em) clamp(1.25em, 2.8vw, 2.5em);
  border-radius: 0.5rem 0.5rem 0 0;
  line-height: 1.21;
}

a.active {
  background: #fff;
}

a.dark-mode {
  color: #eee;
  background: #3f4448;
}

a.active.dark-mode {
  background: #000;
}
</style>
