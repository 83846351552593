



















import Vue from 'vue';
import Icon from '@/common/icon';

export enum StatusType {
  INCOMPLETE = 'incomplete',
  COMPLETED = 'completed',
  CURRENT = 'current',
}

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: 'incomplete' as StatusType,
    },
  },
  data() {
    return {
      StatusType,
    };
  },
  components: {
    Icon,
  },
});
