











import Vue from 'vue';
import { HostedPaymentPageUpdate } from '@/common/types/hostedPaymentPages.types';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => HostedPaymentPageUpdate,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    validationName: {
      type: String,
      required: false,
      default: 'amount',
    },
    label: {
      type: String,
      required: false,
      default: 'Amount',
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  methods: {
    setAmount(value: number) {
      this.value.amount = value;
    },
  },
});
