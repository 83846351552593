<template>
  <div class="tw-flex tw-flex-row tw-justify-between tw-items-end">
    <v-select
      label="Credit Card Processor"
      v-model="value.creditCardProcessorId"
      class="tw-w-1/2 tw-pr-2"
      name="creditCardProcessorId"
      :items="this.supportedCreditCardProcessors"
      item-value="id"
      item-text="name"
      ref="creditCardProcessorId"
      :rules="[(v) => hasProcessorBeenChosen(v) || 'At least 1 processor is required']"
    />
    <v-select
      v-if="isAchEnabled"
      label="ACH Processor"
      name="achProcessorId"
      class="tw-w-1/2 tw-pl-2"
      v-model="value.achProcessorId"
      :items="supportedAchProcessors"
      item-text="name"
      item-value="id"
      ref="achProcessorId"
      :rules="[(v) => hasProcessorBeenChosen(v) || 'At least 1 processor is required']"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PaymentType, PaymentTypeDisplay } from '@/common/types/payments.types';
import { NO_PROCESSOR } from '@/common/types/processors.types';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      PaymentType,
      PaymentTypeDisplay,
      changedCreditCardProcessor: true,
    };
  },
  mounted() {
    this.$store
      .dispatch('loadMerchantProcessors', this.context)
      .then(() => {})
      .catch(() => {
        const message = this.$errorMessages.get('hosted_payment_pages.processors_load');
        this.$toasted.error(message);
      });
    // used changedCreditCardProcessor below to distinguish between value.creditCardProcessorId being null
    // because user selected NONE vs user first loading the page and it being null by default
    this.changedCreditCardProcessor = false;
  },
  computed: {
    ...mapGetters(['processors', 'context', 'features']),
    isAchEnabled() {
      return this.features?.find((x) => x.name === 'electronic_check').enabled;
    },
    supportedCreditCardProcessors() {
      return this.supportedProcessorsForPaymentType(PaymentType.CREDIT);
    },
    supportedAchProcessors() {
      return this.supportedProcessorsForPaymentType(PaymentType.ACH);
    },
  },
  methods: {
    supportedProcessorsForPaymentType(paymentType) {
      const supported = (this.processors).filter(
        (p) => p.supports === paymentType,
      );
      if (supported.length === 1) {
        this.presetProcessors(supported[0]);
      }
      supported.push(NO_PROCESSOR);
      return supported;
    },
    presetProcessors(supportedProcessor) {
      // if 1 processor exists + you aren't editing (credit card and ach processor ids are both null)
      // + NONE is not selected in the dropdown, preset dropdown with 1st processor
      if (!this.value.creditCardProcessorId && !this.value.achProcessorId && supportedProcessor
        && supportedProcessor.supports === PaymentType.CREDIT && !this.changedCreditCardProcessor) {
        this.changedCreditCardProcessor = true;
        this.value.creditCardProcessorId = supportedProcessor.id;
      } else if (!this.value.achProcessorId && !this.value.creditCardProcessorId && supportedProcessor
        && supportedProcessor.supports === PaymentType.ACH) {
        this.value.achProcessorId = supportedProcessor.id;
      }
    },
    hasProcessorBeenChosen() {
      if (
        (this.value.creditCardProcessorId !== '' || this.value.achProcessorId !== '')
        && (this.value.creditCardProcessorId !== null || this.value.achProcessorId !== null)
        && (this.value.creditCardProcessorId !== null || this.value.achProcessorId !== '')
        && (this.value.creditCardProcessorId !== '' || this.value.achProcessorId !== null)
      ) {
        return true;
      }
      return false;
    },
    validate() {
      return (
        (this.$refs.creditCardProcessorId).validate()
        && (this.$refs.achProcessorId).validate()
      );
    },
  },
};
</script>
