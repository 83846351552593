export enum AffiliateNotificationName {
  MERCHANT_INACTIVITY_REPORT = 'merchant_inactivity_report',
}

export interface AffiliateNotification {
  name: AffiliateNotificationName;
  enabled: boolean;
  timeframe: number;
  customTimeframe: number;
  notificationFrequency: string;
}
