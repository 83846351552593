export function get({ currentUser }) {
  return currentUser;
}

export function displayName({ currentUser }) {
  return currentUser && `${currentUser.firstName}  ${currentUser.lastName}`;
}

export function can({ currentUser, context }) {
  if (!currentUser) {
    return () => false;
  }

  return function hasPermission(permission) {
    return currentUser.permissions.includes(permission)
        || (context === 'merchant' && ['admin', 'iso'].includes(currentUser.role))
        || (context === 'iso' && ['admin'].includes(currentUser.role));
  };
}
