<template>
  <router-link :to="{ name: to }" class="back-link">
      <v-icon>mdi-chevron-left</v-icon>
      <slot />
    </router-link>
</template>

<script>
export default {
  props: {
    to: { type: String, required: true },
  },
};
</script>

<style scoped>
.back-link {
  font-size: 1.375rem;
  font-weight: 600;
  font-family: 'Montserrat';
  margin-block: 1.2em 1em;
  display: block;
  color: unset;
}

.back-link:hover {
  text-decoration: none;
}
</style>
