
import dateFormat from 'dateformat';

export function refundClass(transaction: any) {
  // for recent batch styling
  if (typeof (transaction) === 'number') {
    return transaction.toString().includes('-') ? 'red--text' : '';
  }
  // for recent transaction styling
  return (transaction.refundedAmount && transaction.refundedAmount !== 0) ? 'red--text' : '';
}

export function dateFormattedValue(value: any, format: any) {
  const date = Date.parse(value);
  return dateFormat(date, format, true);
}
