





































































































































import Vue from 'vue';

import api from '@/common/api';
import Icon from '@/common/icon';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { DateTime } from 'luxon';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import { PaymentMethod } from '@/common/types/payments.types';
import { generatePaymentMethodTableData } from '@/common/util/payments.util';
import { Customer } from '@/common/types/customers.types';
import { Subscription } from '@/common/types/subscriptions.types';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import { mapGetters } from 'vuex';
import Address from '../transactions/components/Address.vue';
import TransactionsViewTable from '../components/transactions/TransactionsViewTable.vue';
import PaymentMethodCard from '../components/payments/PaymentMethodCard.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      generatePaymentMethodTableData,
      title: 'View Customer' as string,
      customer: null as Customer | null,
      paymentMethods: null as PaymentMethod[] | null,
    };
  },
  created() {
    document.title = 'RiseOS-View Customer';
  },
  mounted() {
    if (!this.isCustomerVaultEnabled || this.isCustomerVaultEnabled === undefined) {
      this.$store.dispatch('loadFeatures').then(() => {
        if (this.isCustomerVaultEnabled) {
          this.loadCustomer();
        }
      });
    } else if (this.isCustomerVaultEnabled) {
      this.loadCustomer();
    }
  },
  methods: {
    loadCustomer() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api
        .get(`/customers/${this.id}`)
        .then(this.setCustomer)
        .catch(() => {
          this.$toasted.error('Problem loading customer');
          (this.$refs.loader as any).setError();
        });
    },
    loadPaymentMethods() {
      if (this.$refs.paymentMethodsLoader) {
        (this.$refs.paymentMethodsLoader as any).setLoading();
      }
      api
        .get(`/customers/${this.id}/payment-methods`)
        .then(this.setPaymentMethods)
        .catch(() => {
          this.$toasted.error('Problem loading customer payment methods');
          (this.$refs.paymentMethodsLoader as any).setError();
        });
    },
    setCustomer({ data }: { data: JsonApiSingleResponse<Customer> }) {
      if (data.data === undefined || data.data.id === undefined) {
        return;
      }
      this.customer = data.data;
      (this.$refs.loader as any).setReady();
      this.loadPaymentMethods();
    },
    setPaymentMethods({ data }: { data: JsonApiArrayResponse<PaymentMethod> }) {
      this.paymentMethods = data.data ?? [];
      (this.$refs.paymentMethodsLoader as any).setReady();
    },
    displaySubscriptionPayments(subscription: Subscription): string {
      const installments: string = subscription.installments && subscription.installments > 0
        ? `/${subscription.installments}`
        : '';
      const paymentCount: number = subscription.paymentsCount ?? 0;
      return `${paymentCount}${installments}`;
    },
    displayRecurrenceText(subscription: Subscription): string {
      const formatted: string = (this.$options.filters as any).capitalize(
        subscription.recurrenceText,
      );
      return formatted.replace('time', 'installment');
    },
    editCustomer() {
      this.$router.push({ name: 'merchant.customers.edit', params: { id: this.id } });
    },
    removeCustomerFromVault() {
      api
        .put(`customers/${this.customer!.id}`, {
          ...this.customer,
          inVault: false,
        })
        .then(() => {
          this.$router.push({
            name: 'merchant.customers.index',
          });
        })
        .catch(({ response }) => {
          this.$toasted.error(
            `There was a problem remove the customer from vault. (${response.status})`,
          );
        });
    },
    transactionsLoaded() {
      document.title = 'RiseOs-View Customer';
    },
  },
  computed: {
    ...mapGetters(['isCustomerVaultEnabled']),
    pageTitle(): string {
      if (!this.customer || !this.hasBillingAddress) {
        return '';
      }
      const { billingAddress } = this.customer;
      if (billingAddress.company) {
        return billingAddress.company;
      }
      return `${`${billingAddress.firstName || ''} ${billingAddress.lastName || ''}`.trim()}`;
    },
    hasBillingAddress(): boolean {
      return (
        this.customer !== null
        && this.customer.billingAddress !== null
        && this.customer.billingAddress !== undefined
      );
    },
    hasShippingAddress(): boolean {
      return (
        this.customer !== null
        && this.customer.shippingAddress !== null
        && this.customer.shippingAddress !== undefined
      );
    },
    entryTime(): string {
      const { createdAt } = this.customer!;
      const dt = DateTime.fromISO(createdAt!.toLocaleString());
      const formattedDate = dt.toLocaleString(DateTime.DATETIME_FULL).replace(', ', '\n');
      return formattedDate;
    },
    hasPaymentMethods(): boolean {
      return this.paymentMethods !== null && this.paymentMethods.length > 0;
    },
    mobileView(): boolean {
      return this.$vuetify.breakpoint.name === 'sm'
      || this.$vuetify.breakpoint.name === 'xs';
    },
  },
  components: {
    PageHeader,
    PageLoader,
    Address,
    PaymentMethodCard,
    Icon,
    TransactionsViewTable,
    PaidFeatureNotEnabled,
  },
});
