




































import Vue from 'vue';

import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { BillingRecurrenceModel } from '@/common/types/billingRecurrences.types';
import { initBillingRecurrenceModel } from '@/common/util/billingRecurrences.util';
import { Plan } from './plans.types';
import BillingRecurrence from '../components/BillingRecurrence.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: 'Edit Plan' as string,
      plan: null as Plan | null,
      validationErrors: {} as any,
      billingRecurrence: initBillingRecurrenceModel() as BillingRecurrenceModel,
    };
  },
  created() {
    document.title = 'RiseOS-Edit Plan';
  },
  mounted() {
    api
      .get(`/plans/${this.id}`)
      .then(({ data: { data } }) => {
        this.plan = data;
        (this.$refs.loader as any).setReady();
      })
      .catch(() => {
        this.$toasted.error('Problem loading plan');
        (this.$refs.loader as any).setError();
      });
  },
  methods: {
    deletePlan() {
      if (this.plan) {
        api
          .delete(`/plans/${this.plan.id}`)
          .then(({ data: { data: plan } }) => {
            this.$toasted.success('Success');
            this.plan = plan;
            this.$router.push({ name: 'merchant.plans.index' });
          })
          .catch(({ response }) => {
            this.$toasted.error(response.data.message);
          });
      }
    },
    updatePlan() {
      const plan = this.plan!;
      plan.amount = this.billingRecurrence.amount;
      plan.rruleText = this.billingRecurrence.recurrence.rruleText;
      plan.recurrenceFrequency = this.billingRecurrence.recurrence.recurrenceFrequency;
      plan.recurrenceText = this.billingRecurrence.recurrence.recurrenceText;
      api
        .put(`plans/${this.id}`, this.plan)
        .then(({ data: { data } }) => {
          this.$toasted.success('Success');
          this.plan = data.plan;
          this.$router.push({ name: 'merchant.plans.index' });
        })
        .catch(({ response }) => {
          this.$toasted.error(response.data.message);
        });
    },
  },
  components: {
    PageHeader,
    PageLoader,
    BillingRecurrence,
  },
});
