var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EntitiesTable',{key:("users-" + _vm.reloads),attrs:{"config":_vm.config,"showDownloadCsvButton":false,"showAddButton":"","showSearch":"","allowAddFunc":_vm.currentUserCanAddUser},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.currentUserCanEditUser(item),"color":"secondary","dense":"","x-small":"","to":{ name: ((_vm.config.routerBase) + ".edit"), params: { id: item.id } }}},[_vm._v(" Edit ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":!_vm.currentUserCanDeleteUser(item),"color":"secondary","dense":"","x-small":""},on:{"click":function($event){return _vm.promptForDelete(item)}}},[_vm._v(" Delete ")]),_vm._v(" "),(_vm.currentUserCanAddUser)?_c('router-link',{staticClass:"v-btn v-btn--has-bg\n          v-btn--router theme--light v-size--x-small secondary",class:!_vm.showCopyUserBtn ? 'v-btn--disabled' : 'v-btn--is-elevated',attrs:{"to":{
              name: 'admin.users.new',
              params: { userPermissions: item.permissions }
            }}},[_vm._v(" Copy ")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.showDeleteModal),callback:function ($$v) {_vm.showDeleteModal=$$v},expression:"showDeleteModal"}},[(_vm.userToDelete)?_c('v-card',{staticClass:"p-3",attrs:{"justify":"center"}},[_c('v-card-title',[_vm._v(" Are you sure you want to delete this user? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v("No ")]),_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":_vm.deleteUser}},[_vm._v("Yes ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }