<template>
  <div class="aurora-merchants-container" :class="{ 'dark-mode': darkMode }">
    <div class="aurora-header">
      <i-store />
      <h2>Merchant Accounts</h2>

      <v-btn
        color="primary"
        rounded
        :to="{ name: 'admin.merchants.new' }"
      >
        Add Aurora Merchant +
      </v-btn>
    </div>

    <div class="centerer" v-if="loading">
      <a-spinner big />
    </div>
    <EntitiesTable
      v-if="iso"
      :config="config"
      showLoginButton
      showActions
      showEditButton
      :key="`aurora-${search}`"
    >
      <template v-slot:item.affiliate>
        {{ iso.name }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | dateShort }}
      </template>
      <template v-slot:item.lastTransactionAt="{ item }">
        {{ item.lastTransactionAt | dateShort }}
      </template>
      <template v-slot:item.contact="{ item }">
        <a class="link-text"
        :href="`mailto:${item.contact.email}`">{{ item.contact.name }}</a>
      </template>
      <template v-slot:item.domain="{ item }">
        <a class="link-text"
        :href="`${item.domain.url}`">{{ item.domain.subdomain }}</a>
      </template>
      <template v-slot:item.id="{ item }">
        {{ item.id | uuidLast6 }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status === MerchantStatus.PENDING || item.status === MerchantStatus.CREATED">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="pr-4 tw-text-gray-400"
                color="#ffcb3a"
                size="25"
              >
                $plus-circle
              </v-icon>
            </template>
            <span>{{ item.status === MerchantStatus.PENDING ? 'Pending' : 'Created' }}</span>
          </v-tooltip>
        </span>
        <span v-else-if="item.status === MerchantStatus.ACTIVE">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="pr-4 tw-text-gray-400"
                color="#399261"
                size="25"
              >
                $check-circle
              </v-icon>
            </template>
            <span>Active</span>
          </v-tooltip>
        </span>
        <span v-else-if="item.status === MerchantStatus.RESTRICTED">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="pr-4 tw-text-gray-400" color="red" size="25">
                $ban
              </v-icon>
            </template>
            <span>Restricted</span>
          </v-tooltip>
        </span>
        <span v-else-if="item.status === MerchantStatus.CLOSED">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="pr-4 tw-text-gray-400" color="red" size="25">
                $times-circle
              </v-icon>
            </template>
            <span>Closed</span>
          </v-tooltip>
        </span>
      </template>
    </EntitiesTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { MerchantStatus } from '@/iso/views/merchants/merchants.types';
import IStore from '@/components/icons/i-store.vue';
import ASpinner from '@/components/atoms/a-spinner.vue';
import TableHeaders from './common/table-headers';
import TableFilters from './common/table-filters';

export default {
  components: { EntitiesTable, IStore, ASpinner },
  props: {
    iso: { type: Object, required: false },
    loading: { type: Boolean, required: true },
    search: { type: String, required: true },
  },
  data() {
    return {
      MerchantStatus,
      config: {
        filters: TableFilters,
        headers: TableHeaders,
        apiPath: `iso-merchants?iso=${this.iso?.uuid}`,
        routerBase: 'admin.merchants',
        search: this.search,
        createButtonText: 'Add Aurora Merchant +',
      },
    };
  },
  watch: {
    search(value) {
      this.config.search = value;
    },
    iso(value) {
      this.config.apiPath = `iso-merchants?iso=${value.uuid}`;
    },
  },
  computed: {
    ...mapState(['darkMode']),
  },
};
</script>

<style scoped>
.aurora-merchants-container {
  background: var(--card-background, #fff);
  padding: 2rem 1.75rem;
  border-radius: 0.5rem;
  margin-block-end: 0.75rem;
}

.centerer {
  display: grid;
  place-items: center;
  margin-block: 10vh;
}

.aurora-header {
  display: flex;
  align-items: center;
  gap:  0.75rem;
  margin-block-end: 2rem;
  color: #666;
}

.aurora-header >>> svg {
  widows: 18px;
  height: 18px;
}

h2 {
  font-weight: 600;
  color: #666;
  font-size: 1.375rem;
  margin: 0;
}

.dark-mode h2 {
  color: #ccc;
}

.aurora-merchants-container >>> .entities-table-container .row {
  margin: 0;
}

.aurora-merchants-container >>> .entities-table-container .v-data-footer {
  background: var(--card-background, #fff);
}
</style>
