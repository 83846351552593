<template>
  <v-combobox
    v-model="product"
    label="Product"
    item-value="uuid"
    item-text="name"
    attach
    outlined
    dense
    :rules="[(v) => !!v || 'Product is required']"
    required
    :items="products"
    :loading="searching"
    hide-no-data
    hide-selected
    append-icon=""
    :search-input.sync="search"
    hint="Search for existing product or create a new one"
  />
</template>

<script>
import api from '@/common/api';

export default {
  props: {
    value: { type: String, required: true },
  },
  data() {
    return {
      product: this.$props.value || null,
      search: '',
      searching: false,
      products: [],
    };
  },
  watch: {
    search: 'searchProducts',
    product: 'emitSelectedProduct',
  },
  methods: {
    emitSelectedProduct() {
      this.$emit('selected', this.product);
      this.$emit('input', typeof this.product === 'string' ? this.product : this.product?.name);
    },
    async searchProducts(query) {
      if (!query) return;
      this.searching = true;

      try {
        const { data } = await api.get('/merchant/products-autocomplete', { params: { q: query } });
        this.products = data.data;
      } catch (e) {
        this.$toasted.error('There was an error while searching for products');
      } finally {
        this.searching = false;
      }
    },
  },
};
</script>
