import adminRoutes from '@/tenants/admin/routes';
import isoRoutes from '@/tenants/affiliate/routes';
import merchantRoutes from '@/tenants/merchant/routes';
import paymentRoutes from '@/payments/routes';

export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const LOGIN_JWT_PATH = '/login-jwt';
export const OAUTH_PATH = '/oauth-redirect';

const prefixRoutes = ((routes, prefix) => routes.map((route) => {
  const mapped = { ...route, name: `${prefix}.${route.name}` };

  if (route.redirect?.name) {
    mapped.redirect = { name: `${prefix}.${route.redirect.name}` };
  }
  if (route.children) {
    mapped.children = route.children.map((child) => ({ ...child, name: `${prefix}.${child.name}` }));
  }
  return mapped;
}));

export default [
  {
    path: '/',
    name: 'root',
    redirect: { name: 'merchant.dashboard' },
  },
  ...prefixRoutes(merchantRoutes, 'merchant'),
  ...prefixRoutes(isoRoutes, 'iso'),
  ...prefixRoutes(adminRoutes, 'admin'),
  ...paymentRoutes,
  {
    path: LOGIN_PATH,
    name: 'login',
    component: () => import('@/common/views/auth/Login.vue'),
  },
  {
    path: LOGOUT_PATH,
    name: 'logout',
    component: () => import('@/common/views/auth/Logout.vue'),
  },
  {
    path: LOGIN_JWT_PATH,
    name: 'login-jtw',
    component: () => import('@/common/views/auth/LoginJWT.vue'),
  },
  {
    path: OAUTH_PATH,
    name: 'oauth-redirect',
    component: () => import('@/common/views/auth/OAuthRedirect.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/common/components/settings/SettingsPage.vue'),
  },
  {
    path: '/settings/security-keys',
    name: 'settings.security-keys',
    component: () => import('@/common/components/settings/SecurityKeys.vue'),
  },
  {
    path: '/settings/api-tokens',
    name: 'settings.api-tokens',
    component: () => import('@/common/components/settings/ApiTokensPage.vue'),
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/common/components/settings/SupportPage.vue'),
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: () => import('@/common/views/auth/ResetPassword.vue'),
  },
  {
    path: '/password/reset/:token',
    name: 'confirm-password-reset',
    component: () => import('@/common/views/auth/ConfirmResetPassword.vue'),
  },
  {
    path: '*',
    component: () => import('@/common/views/NotFound.vue'),
  },
];
