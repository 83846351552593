


























































import { CustomFieldType, CustomFieldFillable } from '@/common/components/support/support.types';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Array as () => CustomFieldFillable[],
      required: true,
    },
    enhanced: {
      type: Array as () => CustomFieldFillable[],
      required: false,
    },
    outlined: { type: Boolean, default: true },
  },
  data() {
    return {
      CustomFieldType,
    };
  },
  methods: {
    resetValidation() {},
  },
});
