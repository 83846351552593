var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-container',[_c('p-page-title',[_vm._v("Merchant Addresses")]),_c('entities-table',{key:_vm.deletes,attrs:{"config":_vm.config,"showAddButton":""},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address1)+", "+_vm._s(item.address2 ? ((item.address2) + ","): '')+" "+_vm._s(item.city)+", "+_vm._s(item.state)+", "+_vm._s(item.country)+", "+_vm._s(item.zip)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('v-btn',{staticStyle:{"text-decoration":"none"},attrs:{"color":"grey","x-small":"","to":{ name: 'merchant.settings.addresses.edit', params: { id: item.uuid }}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"#fff"},attrs:{"dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-square-edit-outline ")])]}}],null,true)},[_vm._v(" Edit ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"grey","x-small":""},on:{"click":function($event){_vm.addressToDelete = item.uuid; _vm.showDeleteModal = true;}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"#fff"},attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_vm._v(" Delete ")])],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.showDeleteModal),callback:function ($$v) {_vm.showDeleteModal=$$v},expression:"showDeleteModal"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure to delete this address? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"grey","disabled":_vm.deleting},on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"small":"","color":"error","disabled":_vm.deleting,"loading":_vm.deleting},on:{"click":function () { return _vm.deleteAddress(_vm.addressToDelete); }}},[_vm._v(" Yes ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }