<template>
  <li class="product">
    <img class="product__image" :src="product.image" :alt="`Product ${product.name} picture`" width="48" height="48">

    <span class="product__name" title="Name">{{ product.name }}</span>
    <p class="product__description" title="Description">{{ product.description }}</p>

    <div class="product__actions">
      <button @click="decrementQuantity" class="product__button" :disabled="product.quantity === 0" type="button">
        <span> - </span>
      </button>
      <output>{{ product.quantity }}</output>
      <button @click="incrementQuantity" class="product__button" type="button">
        <span> + </span>
      </button>
    </div>

    <span class="product__sku" title="SKU">{{ product.sku }}</span>
    <strong class="product__price">${{ product.quantity ? product.cost * product.quantity : product.cost | dollarsAndCents }}</strong>
  </li>
</template>

<script>
export default {
  props: {
    product: { type: Object, required: true },
  },
  methods: {
    decrementQuantity() {
      if (this.product.quantity === 0) return;

      this.$emit('decrement');
    },
    incrementQuantity() {
      this.$emit('increment');
    },
  },
};
</script>

<style scoped>
.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F7F7F7;
  border-radius: 0.25rem;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  font-size: 0.875rem;
  margin-block: 0.5rem;
}

.product__image {
  border-radius: 50%;
  flex-shrink: 0;
  height: 3rem;
  width: 3rem;
  object-fit: cover;
}

.product__description {
  margin: 0;
}

.product__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.product__button {
  display: grid;
  place-items: center;
  background: #0000008a;
  color: #fff;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  font-family: monospace;
  line-height: 1rem;
}

.product__button:disabled {
  opacity: 0.5;
}
</style>
