import { JsonApiData } from '@/jsonApi.types';

export interface Product extends JsonApiData {
  name: string;
  category: string;
  cost: number;
  sku: string;
  measurementUnit: string;
  image: string;
}

export function initProduct(): Product {
  return {
    name: '',
    category: '',
    cost: 0,
    sku: '',
    measurementUnit: '',
    image: 'https://core-gateway-development-storage-10t3pc2y5zq13.s3.amazonaws.com/Images/upload-image.png',
  };
}

export const measurementUnits = [
  {
    text: 'Board foot',
    value: 'board_foot',
  },
  {
    text: 'Bushel',
    value: 'bushel',
  },
  {
    text: 'Cord',
    value: 'cord',
  },
  {
    text: 'Cubic yard',
    value: 'cubic_yard',
  },
  {
    text: 'Cubit foot',
    value: 'cubit_foot',
  },
  {
    text: 'Day',
    value: 'day',
  },
  {
    text: 'Dozen',
    value: 'dozen',
  },
  {
    text: 'Each',
    value: 'each',
  },
  {
    text: 'Fluid Ounce',
    value: 'fluid_ounce',
  },
  {
    text: 'Foot',
    value: 'foot',
  },
  {
    text: 'Gallon',
    value: 'gallon',
  },
  {
    text: 'Hour',
    value: 'hour',
  },
  {
    text: 'Inch',
    value: 'inch',
  },
  {
    text: 'Item',
    value: 'item',
  },
  {
    text: 'Kilogram',
    value: 'kilogram',
  },
  {
    text: 'Meter',
    value: 'meter',
  },
  {
    text: 'Month',
    value: 'month',
  },
  {
    text: 'Ounce',
    value: 'ounce',
  },
  {
    text: 'Pound',
    value: 'pound',
  },
  {
    text: 'Square foot',
    value: 'square_foot',
  },
  {
    text: 'Ton',
    value: 'ton',
  },
  {
    text: 'Unit',
    value: 'unit',
  },
  {
    text: 'Yard',
    value: 'yard',
  },
  {
    text: 'Meter',
    value: 'meter',
  },
  {
    text: 'Cubic Meter',
    value: 'cubic_meter',
  },
];
