// eslint-disable-next-line import/no-unresolved, import/extensions
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';

export type ValidationType = ValidationProperties<Vue> & ValidationGroups & Validation;

export interface ValidationErrors extends Record<string, string[]> { }

export function initValidationErrors(): ValidationErrors {
  return {};
}
