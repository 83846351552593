import api from '@/common/api';

function setBranding(branding) {
  const rootStyles = document.documentElement.style;
  rootStyles.setProperty('--primary-color', branding.primaryColor);
  rootStyles.setProperty('--primary-text-color', branding.primaryColorText);
  rootStyles.setProperty('--secondary-color', branding.secondaryColor);
  rootStyles.setProperty('--secondary-text-color', branding.secondaryColorText);
  rootStyles.setProperty('--tertiary-color', branding.tertiaryColor);
  rootStyles.setProperty('--tertiary-text-color', branding.tertiaryColorText);
}

export default function fetchBranding(subdomain) {
  return api.get(`brands/${subdomain}`, { skipAuthentication: true }).then(({ data: { data } }) => {
    setBranding(data);

    return data;
  }).catch();
}
