<template>
  <p-card class="user-permissions">
    <template #header>
      <h2>Permissions</h2>
    </template>
      <div v-if="loading" class="centerer">
        <a-spinner big />
      </div>
      <div v-else class="user-permissions__permissions">
        <v-checkbox
          v-for="permission in allPermissions"
          :key="permission.id"
          v-model="userPermissions"
          :value="permission.name"
          :label="permission.long_name"
          hide-details
          dense
        />
      </div>
  </p-card>
</template>

<script>
import api from '@/common/api';
import ASpinner from '@/components/atoms/a-spinner.vue';
import PCard from '@/components/presentations/p-card.vue';
import { mapState } from 'vuex';
import { UserType } from '@/common/components/users/users.types';

export default {
  props: {
    permissions: { type: Array, required: true },
    userType: { type: String, required: true },
  },
  data() {
    return {
      allPermissions: [],
      loading: true,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    userPermissions: {
      get() {
        return this.permissions;
      },
      set(permissions) {
        this.$emit('changed', permissions);
      },
    },
    allowEdit() {
      const isAdmin = this.currentUser.role === UserType.ADMIN;
      const canEdit = this.currentUser.permissions.includes('edit-user-permissions');

      return isAdmin || canEdit;
    },
  },
  created() {
    this.loadPermissions();
  },
  methods: {
    async loadPermissions() {
      this.loading = true;

      try {
        const { data } = await api.get(`roles/${this.userType}`);
        this.allPermissions = data.data;
      } catch (e) {
        this.$toasted.error('There was an error while loading permissions');
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    PCard,
    ASpinner,
  },
};
</script>

<style scoped>
.user-permissions__permissions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  column-gap: 0.5rem;
}

.centerer {
  display: grid;
  place-items: center;
  margin-block: 3rem;
}
</style>
