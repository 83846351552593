





































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { initValidationErrors } from '@/common/validation/validation.types';
import { CustomFieldType, InfoField } from '@/common/components/support/support.types';
import {
  PaymentType,
  PaymentTypeDisplay,
  AchAccountHolderType,
} from '@/common/types/payments.types';
import {
  allowedProcessors as allowedProcessorsForCurrentUser,
  filterProcessorsBasedOnPaymentType,
} from '@/common/util/processors.util';
import {
  initPaymentMethod,
  initRecurringPayment,
  readablePaymentMethod,
} from '@/common/util/payments.util';
import { initCustomer } from '@/common/util/customers.util';
import { Processor, ProcessorType } from '@/common/types/processors.types';
import SingleTransaction from '@/common/components/payments/SingleTransaction.vue';
import { Feature } from '@/features.types';
import { VirtualTerminalMerchant } from '@/iso/views/merchants/merchants.types';
import api from '@/common/api';
import { debounce } from 'lodash';
import {
  getLevel3FieldProgress,
  getlLevel1Progress,
  getEnhancedFieldProgress,
  getLevelProgress,
} from '@/common/util/level.util';
import CustomerForm from '../customers/CustomerForm.vue';
import PaymentMethodForm from '../payments/PaymentMethodForm.vue';
import RecurringPaymentForm from '../payments/RecurringPaymentForm.vue';
import SearchPlans from '../SearchPlans.vue';
import { Plan } from '../../plans/plans.types';
import {
  CustomerPaymentAutomation,
  CustomerPaymentConfigWithCustomFields,
} from './customerPayments.types';
import PaymentCustomFields from './PaymentCustomFields.vue';


export default Vue.extend({
  props: {
    value: {
      type: Object as () => CustomerPaymentConfigWithCustomFields,
      required: true,
    },
    loadingText: {
      type: String,
      required: true,
    },
    automation: {
      type: Number,
      required: false,
      default: CustomerPaymentAutomation.ALLOW_RECURRING,
    },
    showPaymentHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPaymentDetails: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCustomFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCustomerVault: {
      type: Boolean,
      required: false,
      default: false,
    },
    virtualTerminalMerchant: {
      type: Object as () => VirtualTerminalMerchant,
      required: false,
    },
    showPaymentTypeErrorModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetAmount: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentProcessor: {
      type: Object as () => Processor,
      required: false,
    },
    showInfoFields: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      AchAccountHolderType,
      PaymentType,
      PaymentTypeDisplay,
      CustomFieldType,
      Feature,
      cardType: PaymentType.CREDIT as string,
      enhancedData: false as boolean,
      progressCount: 0 as number,
      level1progressColor: '' as string,
      level2progressColor: '' as string,
      level3progressColor: '' as string,
      level1Progress: false as boolean,
      level2Progress: false as boolean,
      level3Progress: false as boolean,
      validateEnhancedFields: false as boolean,
      show: true as boolean,
      paymentFields: [] as any[],
      paymentRequiredFields: [] as any[],
      nonCashAdjustmentFeeDollarAmt: 0 as number,
      nonCashAdjustmentFeeDollarPer: 0 as number,
    };
  },
  watch: {
    showInfoFields() {
      this.setCustomerInfoFields();
    },
    virtualTerminalMerchant() {
      this.setVirtualTerminalMerchant();
    },
  },
  computed: {
    ...mapGetters([
      'currentUserCan',
      'brandName',
      'processors',
      'context',
      'isSurchargingEnabled',
      'isCustomerElectronicCheckEnabled',
      'isEnhancedDataEnabled',
      'isNonCashAdjustmentEnabled',
    ]),
    allowedProcessors(): Processor[] {
      return allowedProcessorsForCurrentUser(this.value.processors, this.currentUserCan);
    },
    supportedProcessors(): Processor[] {
      return filterProcessorsBasedOnPaymentType(
        this.value.processors,
        this.value.paymentMethod.type,
      );
    },
    allowedAndSupportedProcessors(): Processor[] {
      return filterProcessorsBasedOnPaymentType(
        this.allowedProcessors,
        this.value.paymentMethod.type,
      );
    },
    allowRecurring(): boolean {
      return this.automation === CustomerPaymentAutomation.ALLOW_RECURRING;
    },
    isAutomated(): boolean {
      return this.automation === CustomerPaymentAutomation.AUTOMATED_ONLY || this.isRecurring;
    },
    isRecurring(): boolean {
      return this.value.isRecurring;
    },
    isCreditTransaction(): boolean {
      return this.value.paymentMethod.type === PaymentType.CREDIT;
    },
    isCardTypeCredit(): boolean {
      return this.cardType === PaymentType.CREDIT;
    },
    isAchTransaction(): boolean {
      return this.value.paymentMethod.type === PaymentType.ACH;
    },
    isCashTransaction(): boolean {
      return this.value.paymentMethod.type === PaymentType.CASH;
    },
    isBusinessAchTransaction(): boolean {
      return (
        this.isAchTransaction
        && this.value.paymentMethod.ach?.accountHolderType === AchAccountHolderType.BUSINESS
      );
    },
    readablePaymentMethod(): string {
      return readablePaymentMethod(this.value.paymentMethod.type);
    },
    isPaymentTypeAvailable(): boolean {
      return this.allowedAndSupportedProcessors.length > 0;
    },
    isPaymentTypeUnavailableDueToPermissions(): boolean {
      return !this.isPaymentTypeAvailable && this.supportedProcessors.length > 0;
    },
    tabClasses(): any[] {
      return this.automation === CustomerPaymentAutomation.ALLOW_RECURRING
        ? ['tabs-container']
        : [];
    },
    total(): number {
      if (this.isRecurring) {
        return this.value.recurrence.billingRecurrence.amount;
      }
      if (this.isSurchargingEnabled && this.isCreditTransaction && this.isCardTypeCredit) {
        return this.value.transaction.amount + this.surchargeFee;
      }
      if (this.showNonCash) {
        if (this.value.transaction.amount) {
          return this.value.transaction.amount + this.nonCashAdjustmentFeePercentOrDollarFee;
        }
      }
      return this.value.transaction.amount;
    },
    surchargeFee(): number {
      if (this.isSurchargingEnabled && this.isCreditTransaction && this.isCardTypeCredit) {
        const calculatedSurchargeFee = (this.value.transaction.amount
         * this.virtualTerminalMerchant.surchargePercent) / 100;
        return calculatedSurchargeFee;
      }
      return 0;
    },
    isCustomFieldsFormValid(): boolean {
      return this.$refs.customFieldsForm && (this.$refs.customFieldsForm as any).isValid;
    },
    isValid(): boolean {
      const checkCustomFields = this.showCustomFields && this.value.customFields.length > 0;
      return checkCustomFields
        ? (this.$refs.customerForm as any).isValid && this.isCustomFieldsFormValid
        : (this.$refs.customerForm as any).isValid;
    },
    surchargePercent(): number {
      return this.cardType !== PaymentType.CREDIT ? 0
        : this.virtualTerminalMerchant.surchargePercent;
    },
    nonCashAdjustmentFeeName(): string {
      return this.virtualTerminalMerchant.nonCashAdjustmentFee.name;
    },
    nonCashAdjustmentFeePercentOrDollarSign(): string {
      return this.nonCashAdjustmentFeeDollarAmt !== 0 ? '$'
        : '%';
    },
    nonCashAdjustmentFeePercentOrDollarAmount(): any {
      return this.nonCashAdjustmentFeeDollarAmt
         !== 0
        ? (this.nonCashAdjustmentFeeDollarAmt / 100).toFixed(2)
        : this.nonCashAdjustmentFeeDollarPer;
    },
    nonCashAdjustmentFeePercentOrDollarFee(): number {
      if (this.isNonCashAdjustmentEnabled && this.virtualTerminalMerchant.nonCashAdjustmentFee) {
        if (this.nonCashAdjustmentFeeDollarPer !== 0) {
          const calculatedNonCashAdjustmentFeePercent = (this.value.transaction.amount
           * this.nonCashAdjustmentFeeDollarPer!) / 100;
          return calculatedNonCashAdjustmentFeePercent;
        } if (this.nonCashAdjustmentFeeDollarAmt) {
          const calculatedNonCashAdjustmentFeeDollarAmount = this.nonCashAdjustmentFeeDollarAmt;
          return calculatedNonCashAdjustmentFeeDollarAmount;
        }
      }
      return 0;
    },
    showNonCash(): boolean {
      // only show if show non cash if virtual terminal has been selected, affiliate has
      // enabled it for merchant, and non cash settings have been created by merchant
      if (this.virtualTerminalMerchant.nonCashAdjustmentFee
      && this.virtualTerminalMerchant.nonCashAdjustmentFee.inVirtualTerminal
      && this.isNonCashAdjustmentEnabled) {
        return true;
      } return false;
    },
    nonCashName(): string {
      return this.virtualTerminalMerchant.nonCashAdjustmentFee.name;
    },
  },
  mounted() {
    this.$store
      .dispatch('loadMerchantProcessors', this.context)
      .then(() => {
        this.value.customer.shippingAddress.country = '';
        this.value.processors = this.processors;
        const selected: Processor = this.allowedProcessors[0];
        this.value.paymentMethod.processorId = selected.id;
        this.value.paymentMethod.type = selected.supports!;
        (this.$refs.processorLoader as any).setReady();

        // Set Customer Information Fields for category "Payment"
        this.setCustomerInfoFields();
      })
      .catch(() => {
        this.$toasted.error('Failed to load processors!');
        this.$router.push({ name: 'merchant.dashboard' });
      });
    this.finishLoading();
  },
  methods: {
    updateSelectedPlan(selectedPlan: Plan) {
      this.value.recurrence.plan = selectedPlan;
      this.value.recurrence.name = this.value.recurrence.plan?.name ?? '';
      this.value.recurrence.description = this.value.recurrence.plan?.description ?? '';
      this.value.recurrence.billingRecurrence.recurrence.rruleText = this.value.recurrence.plan?.rruleText ?? '';
    },
    setSingle() {
      this.value.isRecurring = false;
    },
    setRecurring() {
      this.value.isRecurring = true;
      if (this.value.paymentMethod.type === PaymentType.CASH) {
        this.value.paymentMethod.type = PaymentType.CREDIT;
      }
    },
    resetForms() {
      this.value.customer = initCustomer();
      this.value.paymentMethod = initPaymentMethod();
      this.value.recurrence = initRecurringPayment();
      this.value.validationErrors = initValidationErrors();
      this.value.paymentMethod.processorId = this.value.processors[0].id;
    },
    setTotal(amount: number) {
      this.value.transaction.amount = amount;
      this.$emit('resetAmountFlag');
      this.setlAllLevelProgress();
    },
    checkCardType: debounce(function debounced(this: any) {
      this.setlAllLevelProgress();

      let creditCardNumber = this?.value?.paymentMethod?.creditCard?.cardNumber;
      creditCardNumber = creditCardNumber !== undefined ? creditCardNumber.replaceAll(/\s/g, '') : '';
      if (creditCardNumber === '' || creditCardNumber.length < 6) {
        this.cardType = PaymentType.CREDIT;
        return;
      }
      api
        .post('credit-card-number', { creditCardNumber })
        .then(({ data }) => {
          this.setCardType(data);
        })
        .catch(({ response }) => {
          this.catchCardTypeError(response);
        });
    }, 1000),
    setCardType(data: any) {
      this.cardType = data.data.card_type;
    },
    catchCardTypeError(response: any) {
      if (response.data.message && response.data.message !== '') {
        this.$toasted.error(response.data.message);
      } else {
        this.$toasted.error('Could not check card type.');
      }
    },
    changeTerminal(processor: any) {
      this.enhancedData = [
        ProcessorType.ELAVON,
        ProcessorType.TSYS,
        ProcessorType.FIRSTDATA,
      ].includes(
        processor.key,
      ) && this.showPaymentDetails;

      if (this.enhancedData
          && typeof this.currentProcessor?.id !== 'undefined'
          && this.currentProcessor?.id !== processor.id
      ) {
        this.setlAllLevelProgress();
      }
      this.setVirtualTerminalMerchant();
      this.$emit('processorChanged', processor);
    },
    setLevelProgress() {
      if (this.isEnhancedDataEnabled && this.enhancedData) {
        this.validateEnhancedFields = true;

        const levelProgressData = getLevelProgress(
          this.level1Progress,
          this.level2Progress,
          this.level3Progress,
        );
        this.progressCount = levelProgressData.progressCount;
        this.level1progressColor = levelProgressData.level1progressColor;
        this.level2progressColor = levelProgressData.level2progressColor;
        this.level3progressColor = levelProgressData.level3progressColor;

        this.$emit('setTransactionProgress', this.progressCount);
      }
    },
    setlAllLevelProgress() {
      if (this.isEnhancedDataEnabled) {
        this.setlLevel1Progress();
        this.setEnhancedFieldProgress();
        this.setLevel3FieldProgress();

        this.setLevelProgress();
      }
    },
    setlLevel1Progress() {
      this.level1Progress = getlLevel1Progress(this?.value);
    },
    setEnhancedProgress() {
      this.setlLevel1Progress();
      this.setLevel3FieldProgress();
      this.setLevelProgress();
    },
    setLevel3Progress() {
      this.setlLevel1Progress();
      this.setEnhancedFieldProgress();
      this.setLevelProgress();
    },
    setEnhancedFieldProgress: debounce(function debounced(this: any) {
      if (this.isEnhancedDataEnabled && this.enhancedData) {
        const level2Data = getEnhancedFieldProgress(
          this.value.enhancedFields,
          this.value?.defaultLevel3Setting,
        );
        this.level2Progress = level2Data.validEnhancedFields;
        this.value.enhancedFields = level2Data.enhancedFieldVals;
        this.setEnhancedProgress();
      }
    }, 1000),
    setLevel3FieldProgress: debounce(function debounced(this: any) {
      if (this.isEnhancedDataEnabled
            && this.enhancedData
            && typeof this.value?.defaultLevel3Setting !== 'undefined'
      ) {
        this.level3Progress = getLevel3FieldProgress(this.value?.defaultLevel3Setting);
        this.setLevel3Progress();
      }
    }, 1000),
    setCustomerInfoFields() {
      Object.keys(this.value.infoFields).forEach((key: any) => {
        const infoField = this.value.infoFields[key] as InfoField;
        if (infoField.category === 'Payment') {
          this.paymentFields.push(infoField.name);
          if (infoField.required) {
            this.paymentRequiredFields.push(infoField.name);
          }
        }
      });
    },
    setVirtualTerminalMerchant() {
      this.nonCashAdjustmentFeeDollarAmt = 0;
      this.nonCashAdjustmentFeeDollarPer = 0;
      if (typeof this.virtualTerminalMerchant.nonCashAdjustmentFee !== 'undefined') {
        if (this.value.paymentMethod.type !== PaymentType.ACH) {
          if (this.virtualTerminalMerchant.nonCashAdjustmentFee.adjustmentPercent) {
            this.nonCashAdjustmentFeeDollarPer = this.virtualTerminalMerchant.nonCashAdjustmentFee.adjustmentPercent;
          }
          if (this.virtualTerminalMerchant.nonCashAdjustmentFee.dollarAmount) {
            this.nonCashAdjustmentFeeDollarAmt = this.virtualTerminalMerchant.nonCashAdjustmentFee.dollarAmount;
          }
        } else {
          if (this.virtualTerminalMerchant.nonCashAdjustmentFee.adjustmentPercentAch) {
            this.nonCashAdjustmentFeeDollarPer = this.virtualTerminalMerchant.nonCashAdjustmentFee.adjustmentPercentAch;
          }
          if (this.virtualTerminalMerchant.nonCashAdjustmentFee.dollarAmountAch) {
            this.nonCashAdjustmentFeeDollarAmt = this.virtualTerminalMerchant.nonCashAdjustmentFee.dollarAmountAch;
          }
        }
      }
    },
    isVisible(fieldName: string) {
      return this.paymentFields.includes(fieldName);
    },
    isRequired(fieldName: string) {
      return this.paymentRequiredFields.includes(fieldName);
    },
    finishLoading() {
      if (Object.keys(this.value.infoFields).length && this.value.infoFields[0].name !== '') {
        this.setCustomerInfoFields();
      }
      if (this.virtualTerminalMerchant.nonCashAdjustmentFee) {
        this.setVirtualTerminalMerchant();
      }
    },
  },
  components: {
    CustomerForm,
    PageLoader,
    PaymentMethodForm,
    LoadingModal,
    RecurringPaymentForm,
    SearchPlans,
    SingleTransaction,
    PaymentCustomFields,
  },
});
