
































import Vue from 'vue';
import { MerchantStatus } from '@/iso/views/merchants/merchants.types';

export default Vue.extend({
  props: {
    currentStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false as boolean,
      merchantStatusModal: {},
      currentMerchantStatus: '',
      status: MerchantStatus.ACTIVE,
      statusValues: [
        {
          status: MerchantStatus.ACTIVE,
          label: 'ACTIVE',
          text:
            'The Merchant can process transactions and perform all Payment Gateway functions normally.',
        },
        {
          status: MerchantStatus.RESTRICTED,
          label: 'RESTRICTED',
          text:
            'The Merchant is able to log in to generate reports and change options, but they cannot process any transactions.',
        },
        {
          status: MerchantStatus.CLOSED,
          label: 'CLOSED',
          text:
            'Not Billable. The Merchant is unable to log in, but their Affiliate-Partner has access for reporting and voids.',
        },
      ],
    };
  },
  methods: {
    setVisibility(show: boolean) {
      this.show = show;
    },
    closeMerchantStatusModal() {
      this.$emit('closeMerchantStatusModal');
    },
    saveMerchantStatus() {
      if (this.currentStatus !== this.status) {
        this.$emit('saveStatus', this.status);
      }
    },
  },
  mounted() {
    this.status = this.currentStatus as MerchantStatus;
    this.currentMerchantStatus = this.status;
  },
});
