var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"report-page-title"},[_vm._v("Reports")]),(_vm.context === 'admin')?_c('AdminReportsPagesTabs'):_c('reports-tabs'),_c('EntitiesTable',{attrs:{"config":_vm.config},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeShort")(item.createdAt))+" ")]}},{key:"item.customerDetails",fn:function(ref){
var item = ref.item;
return [(
          item.customerDetails &&
          item.customerDetails.billingAddress &&
          item.customerDetails.billingAddress.email
        )?_c('a',{attrs:{"href":("mailto:" + (item.customerDetails.billingAddress.email))}},[_vm._v(_vm._s(item.customerDetails.billingAddress.email)+" ")]):_vm._e()]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uuidLast6")(item.id))+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.status))+" ")]}},{key:"item.paymentMethod",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.generatePaymentMethodTableData(_vm.$options.filters, item.paymentMethod))+" ")]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:_vm.refundClass(item)},[_vm._v(" "+_vm._s(_vm.getAmount(item))+" ")])]}},{key:"item.subscriptionId",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.subscriptionId !== undefined && item.subscriptionId !== null && item.subscriptionId !== '' ? 'Yes' : 'No')+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","dense":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.viewTransaction(item)}}},[_vm._v(" View ")])]}}])}),_c('v-navigation-drawer',{attrs:{"app":"","temporary":"","right":"","width":625},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.drawer)?_c('TransactionDetails',{attrs:{"id":_vm.selectedTransactionId,"merchant":_vm.selectedTransactionMerchant}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }