




































































































import Vue from 'vue';
import Permissions from '@/common/permissions';
import { mapGetters } from 'vuex';
import { PayaConfig, Processor } from '@/common/types/processors.types';
import AchPaymentMethod from '@/common/components/AchPaymentMethod.vue';
import {
  CreditMethod,
  PaymentMethod,
  PaymentType,
  PaymentTypeDisplay,
} from '@/common/types/payments.types';
import { VirtualTerminalMerchant } from '@/iso/views/merchants/merchants.types';
import CreditCardPaymentMethod from './CreditCardPaymentMethod.vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => PaymentMethod,
      required: true,
    },
    processors: {
      type: Array,
      required: true,
    },
    isAutomated: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    validationPrefix: {
      type: String,
      required: false,
      default: '',
    },
    creditCardValidationPrefix: {
      type: String,
      required: false,
      default: '',
    },
    achValidationPrefix: {
      type: String,
      required: false,
      default: '',
    },
    isCustomerVault: {
      type: Boolean,
      required: false,
      default: false,
    },
    virtualTerminalMerchant: {
      type: Object as () => VirtualTerminalMerchant,
      required: false,
    },
    showPaymentTypeErrorModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // localValueForTest is to make tests based on computed properties pass
      // without computed is not reactive in tests
      dialog: false, // show dialog variable
      notAllowedPaymentMethod: '',
      localValueForTest: this.value as PaymentMethod,
      PaymentType,
      PaymentTypeDisplay,
      paymentTypes: [
        {
          display: PaymentTypeDisplay.CREDIT,
          value: PaymentType.CREDIT,
        },
        {
          display: PaymentTypeDisplay.ACH,
          value: PaymentType.ACH,
        },
        {
          display: PaymentTypeDisplay.CASH,
          value: PaymentType.CASH,
        },
      ],
    };
  },
  mounted() {
    if (this.currentUser) {
      this.changeDefaultCreditMethodIfCaptureDisallowed();
    }
  },
  watch: {
    currentUser() {
      this.changeDefaultCreditMethodIfCaptureDisallowed();
    },
    showPaymentTypeErrorModal() {
      this.dialog = this.showPaymentTypeErrorModal;
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentUserCan', 'isCustomerElectronicCheckEnabled', 'isCustomerCashEnabled']),
    showCreditPaymentMethod(): boolean {
      return this.hasCreditProcessor && this.value.type === PaymentType.CREDIT;
    },
    hasCreditProcessor(): boolean {
      return (this.processors as Processor[]).some((p) => p.supports === PaymentType.CREDIT);
    },
    showAchPaymentMethod(): boolean {
      return this.isCustomerElectronicCheckEnabled
          && this.hasAchProcessor
          && this.value.type === PaymentType.ACH;
    },
    hasAchProcessor(): boolean {
      return (this.processors as Processor[]).some((p) => p.supports === PaymentType.ACH);
    },
    showCashPaymentMethod(): boolean {
      return this.isCustomerCashEnabled
          && this.hasCashProcessor
          && this.value.type === PaymentType.CASH;
    },
    hasCashProcessor(): boolean {
      return (
        !this.isAutomated
        && (this.processors as Processor[]).some((p) => p.supports === PaymentType.CASH)
      );
    },
    supportedProcessors(): Processor[] {
      let processorsFilteredByType = (this.processors as Processor[]).filter(
        (p) => p.supports === this.value.type,
      );
      if (this.showAchPaymentMethod) {
        processorsFilteredByType = processorsFilteredByType.filter(
          (processor) => (processor.config as PayaConfig).type
            === this.value.ach?.accountHolderType,
        );
      }
      return processorsFilteredByType;
    },
    showSelectProcessorsForm(): boolean {
      return this.supportedProcessors.length > 1;
    },
    processorIdValidationName(): string {
      return `${this.validationPrefix}processorId`;
    },
    paymentTypeValidationName(): string {
      return `${this.validationPrefix}type`;
    },
  },
  methods: {
    isProcessorIdSupported(processorId: string) {
      const supportedIds = this.supportedProcessors.map((processor) => processor.id);
      return supportedIds.includes(processorId);
    },
    setProcessorId() {
      if ((this.value.type === PaymentType.ACH && !this.isCustomerElectronicCheckEnabled)
      || (this.value.type === PaymentType.CASH && !this.isCustomerCashEnabled)) {
        this.value.processorId = undefined;
        this.dialog = true;
        this.notAllowedPaymentMethod = this.value.type === PaymentType.ACH
          ? PaymentTypeDisplay.ACH
          : PaymentTypeDisplay.CASH;
      } else {
        this.value.processorId = this.supportedProcessors.length > 0
          ? this.supportedProcessors[0].id : undefined;
      }
      if (this.value.processorId) {
        this.updateTerminal();
      }
    },
    changeDefaultCreditMethodIfCaptureDisallowed() {
      if (
        this.value.creditCard!.method === CreditMethod.AUTH_AND_CAPTURE
        && !this.currentUserCan(Permissions.RUN_CC_SALE)
      ) {
        this.value.creditCard!.method = CreditMethod.AUTH_ONLY;
      }

      this.setProcessorId();
    },
    checkCardType() {
      this.$emit('created');
    },
    updateTerminal() {
      const { processorId } = this.value;
      const filtered = this.processors.filter((item: any) => item.id === processorId);

      this.$emit('changeTerminal', filtered[0]);
    },
  },
  components: {
    AchPaymentMethod,
    CreditCardPaymentMethod,
  },
});
