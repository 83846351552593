


























































































import { mask } from 'vue-the-mask';
import Vue from 'vue';
import { range } from 'lodash';
import { mapGetters } from 'vuex';
import Permissions from '@/common/permissions';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { CreditCard, CreditMethod } from '@/common/types/payments.types';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => CreditCard,
      required: true,
    },
    isAutomated: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Object as () => ValidationErrors,
      required: false,
      default: () => initValidationErrors(),
    },
    validationPrefix: {
      type: String,
      required: false,
      default: '',
    },
    cardType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      CreditMethod,
    };
  },
  computed: {
    ...mapGetters(['currentUserCan']),
    canAuth(): boolean {
      return this.currentUserCan(Permissions.RUN_CC_AUTH) && !this.isAutomated;
    },
    canAuthAndCapture(): boolean {
      return this.currentUserCan(Permissions.RUN_CC_SALE);
    },
    creditMethods(): CreditMethod[] {
      return [CreditMethod.AUTH_AND_CAPTURE, CreditMethod.AUTH_ONLY];
    },
    methods(): { value: string; display: string }[] {
      const methods = [];
      if (this.canAuthAndCapture) {
        methods.push({
          value: CreditMethod.AUTH_AND_CAPTURE,
          display: 'Authorize & Capture',
        });
      }
      if (this.canAuth) {
        methods.push({
          value: CreditMethod.AUTH_ONLY,
          display: 'Authorize only',
        });
      }
      return methods;
    },
    months(): number[] {
      return range(1, 13);
    },
    years(): number[] {
      return range(new Date().getFullYear(), new Date().getFullYear() + 19);
    },
    methodValidationName(): string {
      return `${this.validationPrefix}method`;
    },
    cardNumberValidationName(): string {
      return `${this.validationPrefix}cardNumber`;
    },
    expMonthValidationName(): string {
      return `${this.validationPrefix}expirationMonth`;
    },
    expYearValidationName(): string {
      return `${this.validationPrefix}expirationYear`;
    },
    cvvValidationName(): string {
      return 'cvv';
    },
  },
  methods: {
    changeDefaultCreditMethodIfCaptureDisallowed() {
      if (
        this.value.method === CreditMethod.AUTH_AND_CAPTURE
        && !this.currentUserCan(Permissions.RUN_CC_SALE)
      ) {
        this.value.method = CreditMethod.AUTH_ONLY;
      }
    },
    checkCardType() {
      this.$emit('created');
    },
  },
  directives: {
    mask: {
      bind(el, binding, vnode, oldVnode) {
        if (binding.value) {
          mask(el, binding, vnode, oldVnode);
        }
      },
    },
  },
});
