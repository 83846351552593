




















import Vue from 'vue';
import api from '@/common/api';

export default Vue.extend({
  data() {
    return {
      SendEmailSetting: false as boolean,
      isSubmitting: false as boolean,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      api
        .get('/email-settings')
        .then(({ data }) => {
          this.SendEmailSetting = !!data.data.emailSetting;
        })
        .catch(() => {
          this.SendEmailSetting = true;
        });
    },
    changeEmailSetting() {
      api
        .put('/email-settings', { emailSetting: this.SendEmailSetting })
        .then(() => {
          this.$toasted.success('Email Setting updated successfully!');
        })
        .catch(({ response }) => {
          this.$toasted.error(response.data.message);
        });
    },
  },
  components: {
  },
});

