



















































































































































































































































import Vue from 'vue';

import LoadingModal from '@/common/components/LoadingModal.vue';
import { PayaProcessorTypeSelectOption, Processor, ProcessorType } from '@/common/types/processors.types';
import { cloneDeep } from 'lodash';
import { AchAccountHolderType, PaymentType } from '@/common/types/payments.types';
import api from '@/common/api';

export default Vue.extend({
  props: {
    merchantId: {
      type: String,
      required: true,
    },
    processor: {
      type: Object as () => Processor,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      isLoading: false as boolean,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      updatedProcessor: null as Processor | null,
      achProcessor: null as Processor | null,
      binRules: [
        (v: string) => !!v || 'Bank Number is required',
        (v: string) => /^[0-9]{6}$/.test(v) || 'Bank Number has to be 6 digits',
      ],
      midRules: [
        (v: string) => !!v || 'Merchant ID is required',
        (v: string) => /^[0-9]{10}$/.test(v) || 'Merchant ID has to be 10 digits',
      ],
      tidRules: [
        (v: string) => !!v || 'Terminal ID is required',
        (v: string) => /^000[0-9]{13}$/.test(v) || 'Terminal ID has to be 3 zeroes followed by 13 digits',
      ],
      uidTsysRules: [
        (v: string) => !!v || 'User ID is required',
        (v: string) => /^[a-zA-Z0-9]{3,128}$/.test(v) || 'User ID has Alphanumeric with Min Length = 3 and Max Length = 128',
      ],
      midTsysRules: [
        (v: string) => !!v || 'Merchant ID is required',
        (v: string) => /^[a-zA-Z0-9]{12,20}$/.test(v) || 'Merchant ID has to be Numeric with Length between 12 and 20',
      ],
      didTsysRules: [
        (v: string) => !!v || 'Device ID is required',
        (v: string) => /^[a-zA-Z0-9]{14,15}$/.test(v) || 'Device ID has to be Numeric with Min Length = 14 and Max Length = 15',
      ],
      tkeyTsysRules: [
        (v: string) => !!v || 'Transaction Key is required to generate a new Transaction Key',
        (v: string) => /^[a-zA-Z0-9]{32}$/.test(v) || 'Transaction Key has to be Alphanumeric and Max Length = 32',
      ],
      pwTsysRules: [
        (v: string) => !!v || 'Tsys Password is required to generate a new Transaction Key',
      ],
      gidRules: [
        (v: string) => !!v || 'GID is required',
        (v: string) => /^[a-zA-Z0-9]{5,13}$/.test(v) || 'GID has to be between 6 to 13 alphanumeric characters',
      ],
      midFirstDataRules: [
        (v: string) => !!v || 'MID is required',
        (v: string) => /^[a-zA-Z0-9]{6,16}$/.test(v) || 'MID has to be between 6 to 16 alphanumeric characters',
      ],
      tidFirstDataRules: [
        (v: string) => !!v || 'TID is required',
        (v: string) => /^[a-zA-Z0-9]{4,8}$/.test(v) || 'TID has to be between 4 to 8 alphanumeric characters',
      ],
      passwordOmahaRules: [
        (v: string) => !!v || 'Password is required',
        (v: string) => /^[0-9]{6}$/.test(v) || 'Password has to be 6 digits',
      ],
      payaTypes: [
        { display: 'Business', value: AchAccountHolderType.BUSINESS },
        { display: 'Personal', value: AchAccountHolderType.PERSONAL },
      ] as PayaProcessorTypeSelectOption[],
    };
  },
  methods: {
    validateTsysCheckbox(v: any) {
      if (this.updatedProcessor?.generateTransactionKey && !v) {
        return 'Tsys Password is required to regenerate a transaction key';
      }
      return true;
    },
    updateCopy() {
      this.updatedProcessor = cloneDeep(this.processor);
    },
    getAchProcessor() {
      if (this.updatedProcessor === null || typeof this.updatedProcessor.key === 'undefined') return;

      if (this.updatedProcessor.key === ProcessorType.PAYA
          && this.updatedProcessor.supports === PaymentType.ACH) {
        this.isLoading = true;
        const params: any = {
          name: this.updatedProcessor.name,
          id: this.updatedProcessor.id,
        };

        api
          .get(`merchants/${this.merchantId}/processor`, { params })
          .then(({ data }) => {
            this.achProcessor = data.data! !== '' ? cloneDeep(data.data!) : null;
            this.showModal = true;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.$toasted.error('Unable to load ACH processor');
          });
      } else this.showModal = true;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.updatedProcessor = null;
      this.achProcessor = null;
    },
    show() {
      this.updateCopy();
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    close() {
      this.showModal = false;
      this.reset();
    },
    editProcessor() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      this.saveProcessor(this.updatedProcessor);
      if (this.achProcessor
          && this.updatedProcessor?.key === ProcessorType.PAYA
          && this.updatedProcessor?.supports === PaymentType.ACH) {
        this.saveProcessor(this.achProcessor);
      }
    },
    saveProcessor(processorData:any) {
      api
        .put(`merchants/${this.merchantId}/processors/${processorData.id}`, {
          key: processorData!.key,
          name: this.updatedProcessor!.name,
          config: processorData!.config,
          generateTransactionKey: this.updatedProcessor!.generateTransactionKey,
        })
        .then(() => {
          this.$toasted.success('Success');
          this.showModal = false;
          this.reset();
          this.$emit('updated');
        })
        .catch(({ response }) => {
          const errorMessages: string[] = [];
          if (typeof response.data.message !== 'undefined') {
            errorMessages.push(response.data.message);
          } else if (response.data?.errors && typeof (response.data.errors === 'object')) {
            Object.keys(response.data.errors)
              .forEach((key) => errorMessages.push(response.data.errors[key]));
          }
          if (errorMessages.length) {
            this.$toasted.error(errorMessages.join(', '));
          } else {
            this.$toasted.error(response.data.message);
          }
        })
        .finally(() => this.submitActions(false));
    },
  },
  mounted() {
    this.updateCopy();
  },
  computed: {
    isElavon(): boolean {
      return this.processor.supports === PaymentType.CREDIT
        && this.processor.key === ProcessorType.ELAVON;
    },
    isTsys(): boolean {
      return this.processor.supports === PaymentType.CREDIT
        && this.processor.key === ProcessorType.TSYS;
    },
    isFirstData(): boolean {
      return this.processor.supports === PaymentType.CREDIT
       && this.processor.key === ProcessorType.FIRSTDATA;
    },
    isOmaha(): boolean {
      return this.processor.supports === PaymentType.CREDIT
       && this.processor.key === ProcessorType.OMAHA;
    },
    isPaya(): boolean {
      return this.processor.supports === PaymentType.ACH
        && this.processor.key === ProcessorType.PAYA;
    },
    isCash(): boolean {
      return this.processor.supports === PaymentType.CASH
        && this.processor.key === ProcessorType.CASH;
    },
  },
  watch: {
    processor: {
      handler() {
        this.updateCopy();
      },
      deep: true,
    },
    updatedProcessor() {
      this.getAchProcessor();
    },
  },
  components: { LoadingModal },
});
