
































import Vue from 'vue';

export default Vue.extend({
  props: {
    iso: {
      type: Object,
      required: false,
    },
    showModal: {
      type: Boolean,
    },
  },
});
