















import Vue from 'vue';
import Icon from '@/common/icon';
import PageHeader from '@/common/components/PageHeader.vue';
import env from '@/environmentSetup';

export default Vue.extend({
  data() {
    return {
      gpgPath: env('VUE_APP_GPG_PUBLIC_KEY_PATH'),
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    PageHeader,
    Icon,
  },
});
