<template>
  <div class="error-message" v-if="error">
    <h1>{{ errorMessage }}</h1>
    <disabled-svg v-if="errorType === 'closed'" />
    <incorrect-login-svg v-if="errorType === 'invalid_subdomain'" />
    <error-svg v-if="errorType === 'error'" />

    <p v-if="errorType === 'closed'">
      Please contact Aurora Technical Support for assistance.
      <br>
      Please
      <a href="mailto:gatewaysupport@risewithaurora.com">"Click Here"</a>
      for Support.
    </p>

    <p v-if="errorType === 'invalid_subdomain'">
      Please contact your site administrator for the correct URL or
      Aurora Technical Support if further assistance is needed.
      <br>
      Please
      <a href="mailto:gatewaysupport@risewithaurora.com">"Click Here"</a>
      for Support.
    </p>

    <p v-if="errorType === 'error'">
      Please
      <a href="mailto:gatewaysupport@risewithaurora.com">"Click Here"</a>
      for Support.
    </p>

    <v-btn
      color="primary"
      to="/logout"
      class="logout-button"
    >
      Go back
    </v-btn>
  </div>
</template>

<script>
import ErrorSvg from './error-svg.vue';
import DisabledSvg from './disabled-svg.vue';
import IncorrectLoginSvg from './incorrect-login-svg.vue';

const ERRORS = {
  'You are currently trying to login to an incorrect URL.': 'invalid_subdomain',
  'Your Merchant account is currently disabled.': 'closed',
};

export default {
  props: {
    error: { type: Object, required: true },
  },
  components: { ErrorSvg, DisabledSvg, IncorrectLoginSvg },
  computed: {
    errorMessage() {
      const errorData = this.error.response.data;

      return errorData.errors
        ? errorData.errors.username[0]
        : errorData.message;
    },
    errorType() {
      return ERRORS[this.errorMessage] || 'error';
    },
  },
};
</script>

<style scoped>
.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.error-message h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #424242;
  text-align: center;
  max-width: 22ch;
}

.error-message p {
  margin-block-start: 2rem;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  max-width: 60ch;
  text-align: center;
}

.error-message p a {
  color: var(--primary-color) !important;
}

.error-message .logout-button {
  color: #fff !important;
  font-weight: 600;
  padding-inline: 3rem;
  margin-block-start: -1rem;
}
</style>
