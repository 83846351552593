const isoSettings = [
  {
    title: 'Settings',
    settings: [
      {
        title: 'Users',
        route: 'iso.users.index',
        icon: '$users',
      },
    ],
  },
];

export default isoSettings;
