import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/settings/custom-fields',
    name: 'settings.custom-fields',
    component: () => import('../../../../merchant/views/settings/CustomFields.vue'),
  },
  {
    path: '/settings/info-fields',
    name: 'settings.info-fields',
    component: () => import('../../../../merchant/views/settings/InfoFields.vue'),
  },
  {
    path: '/settings/receipt-settings',
    name: 'settings.receipt-settings',
    component: () => import('../../../../merchant/views/settings/ReceiptSettings.vue'),
  },
  {
    path: '/settings/processors',
    name: 'settings.processors',
    component: () => import('../../../../merchant/views/settings/Processors.vue'),
  },
  {
    path: '/settings/email-settings',
    name: 'settings.email-settings',
    component: () => import('../../../../merchant/views/settings/EmailSettings.vue'),
  },
  {
    path: '/settings/cash-discount',
    name: 'settings./cash-discount',
    component: () => import('../../../../merchant/views/settings/NonCashAdjustmentFee.vue'),
  },
  {
    path: '/settings/level-3-settings',
    name: 'settings.level-3-settings',
    component: () => import('../../../../merchant/views/settings/Level3Settings.vue'),
  },
  {
    path: '/settings/transaction-ip-blocker',
    name: 'settings.ip-blocker',
    component: () => import('../../../../merchant/views/settings/IpBlockers.vue'),
  },
  {
    path: '/settings/upload-logo',
    name: 'settings.upload-logo',
    component: () => import('../../../../merchant/views/settings/UploadLogo.vue'),
  },
  {
    path: '/settings/avs-settings',
    name: 'settings.avs-settings',
    component: () => import('../../../../merchant/views/settings/AvsSettings.vue'),
  },
  {
    path: '/settings/transaction-limits',
    name: 'settings.transaction-limits',
    component: () => import('../../../../merchant/views/settings/TransactionLimits.vue'),
  },
  {
    path: '/settings/ip-settings',
    name: 'settings.ip-settings',
    component: () => import('../../../../merchant/views/settings/IpSettings.vue'),
  },
  {
    path: '/settings/invoice-settings',
    name: 'settings.invoice-settings',
    component: () => import('../../../../merchant/views/settings/InvoiceSettings.vue'),
  },
  {
    path: '/settings/invoice-settings/reply-to',
    name: 'settings.invoice-settings.reply-to',
    component: () => import('../../../../merchant/views/settings/ReplyToSettings.vue'),
  },
  {
    path: '/settings/tax-rates',
    name: 'settings.tax-rates',
    component: () => import('../../../../merchant/views/settings/tax-rates/TaxRatesSettings.vue'),
  },
  {
    path: '/settings/tax-rates/new',
    name: 'settings.tax-rates.new',
    component: () => import('../../../../merchant/views/settings/tax-rates/TaxRatesForm.vue'),
  },
  {
    path: '/settings/tax-rates/:id/edit',
    name: 'settings.tax-rates.edit',
    component: () => import('../../../../merchant/views/settings/tax-rates/TaxRatesForm.vue'),
    props: true,
  },
  {
    path: '/settings/merchant-addresses',
    name: 'settings.addresses',
    component: () => import('../../../../merchant/views/merchant-addresses/MerchantAddresses.vue'),
  },
  {
    path: '/settings/merchant-addresses/new',
    name: 'settings.addresses.new',
    component: () => import('../../../../merchant/views/merchant-addresses/MerchantAddressForm.vue'),
  },
  {
    path: '/settings/merchant-addresses/:id/edit',
    name: 'settings.addresses.edit',
    component: () => import('../../../../merchant/views/merchant-addresses/MerchantAddressForm.vue'),
    props: true,
  },
];

export default routes;
