import { JsonApiData } from '@/jsonApi.types';

export interface Category extends JsonApiData {
  id: string;
  name: string;
}
export function initCategory(): Category {
  return {
    id: '',
    name: '',
  };
}
