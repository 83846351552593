<template>
  <p-container>
    <entities-table :config="config" showAddButton>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col>
            <v-btn
              color="grey"
              x-small
              :to="{ name: 'admin.faqs.edit', params: { id: item.uuid }}"
              style="text-decoration: none;"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" dark small style="color: #fff;">
                    mdi-square-edit-outline
                  </v-icon>
                </template>
                Edit
              </v-tooltip>
            </v-btn>

            <v-btn
              color="grey"
              x-small
              class="ml-2"
              @click="faqToDelete = item.uuid; showDeleteModal = true;"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small style="color: #fff;">
                    mdi-close-circle
                  </v-icon>
                </template>
                Delete
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </entities-table>

    <v-dialog v-model="showDeleteModal" persistent max-width="500">
      <v-card>
        <v-card-title>
          Are you sure to delete this Frequently Asked Question?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            color="grey"
            @click="showDeleteModal = false"
            :disabled="deleting"
          >
            No
          </v-btn>
          <v-btn
            small
            color="error"
            @click="() => deleteFaq(faqToDelete)"
            :disabled="deleting"
            :loading="deleting"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </p-container>
</template>

<script>
import api from '@/common/api';
import PContainer from '@/components/presentations/p-container.vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';

export default {
  components: {
    PContainer,
    EntitiesTable,
  },
  data() {
    return {
      faqToDelete: null,
      showDeleteModal: false,
      deleting: false,
      deletes: 0,

      config: {
        pageTitle: 'FAQs',
        header: 'Frequently Asked Questions',
        createButtonText: '+ ADD',
        routerBase: 'admin.faqs',
        apiPath: 'faq',
        filters: [],
        headers: [
          {
            text: 'UUID',
            value: 'uuid',
            align: ' d-none',
          },
          {
            text: 'Question',
            value: 'question',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Answer',
            value: 'answer',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Actions',
            value: 'actions',
            align: 'center',
            width: '8rem',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
        ],
      },
    };
  },
  methods: {
    async deleteFaq(id) {
      this.deleting = true;

      try {
        await api.delete(`faq/${id}`);
        this.$toasted.success('FAQ successfully deleted');
        this.showDeleteModal = false;
        this.deletes += 1;
      } catch (e) {
        this.$toasted.error('There was an error while deleting the FAQ');
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>
