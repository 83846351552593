

































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Feature } from '@/features.types';
import adminSettings from '@/admin/settings/admin-settings';
import isoSettings from '@/iso/settings/iso-settings';
import merchantSettings from '@/merchant/settings/merchant-settings';
import { isEnabled } from 'vue-feature-flipping';

export default Vue.extend({
  created() {
    document.title = 'RiseOS-Settings';
  },
  data() {
    return {
      Feature,
    };
  },
  computed: {
    ...mapGetters(['context', 'isEnhancedDataEnabled', 'isNonCashAdjustmentEnabled', 'currentUserCan']),
    settings(): any[] {
      if (this.context === 'admin') {
        return adminSettings;
      }
      if (this.context === 'iso') {
        return isoSettings;
      }
      if (this.context === 'merchant') {
        return merchantSettings;
      }
      return [];
    },
  },
  methods: {
    showLink(child: any): boolean {
      if (this.context === 'merchant' && child.feature === Feature.LEVEL23) {
        return this.isEnhancedDataEnabled;
      }

      if (this.context === 'merchant' && child.feature === Feature.NON_CASH_ADJUSTMENT) {
        return this.isNonCashAdjustmentEnabled;
      }

      const canShow = child.feature === undefined || isEnabled(child.feature);
      if (!canShow) return false;
      if (!child.permission) return true;

      return this.currentUserCan(child.permission);
    },
  },
});
