<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import {
  Chart,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Filler,
  Title,
  Tooltip,
} from 'chart.js';
import { mapState } from 'vuex';
import { dateFormattedValue } from '@/common/util/dashboard.util';

Chart.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Filler,
  Title,
  Tooltip,
);

export default {
  props: {
    previous: { type: Array, required: true },
    current: { type: Array, required: true },
    title: { type: String, required: true },
    dateFormat: { type: String, required: true },
  },
  computed: {
    ...mapState(['branding', 'darkMode']),
    data() {
      const labels = this.previous.map((x) => dateFormattedValue(x.date, this.dateFormat));

      return {
        labels,
        datasets: [
          this.extractDatasetData('Current Period Volume', this.current, 'secondaryColor'),
          this.extractDatasetData('Previous Period Volume', this.previous, 'primaryColor'),
        ],
      };
    },
    options() {
      return {
        plugins: {
          title: {
            display: true,
            text: this.title,
            align: 'start',
            color: this.darkMode ? '#fff' : '#0B1713',
            fullSize: false,
            font: { size: 22, family: "'Montserrat'" },
            padding: { bottom: -30 },
          },
          legend: {
            align: 'end',
            fullSize: false,
            labels: {
              boxWidth: 20,
              boxHeight: 4,
            },
          },
          tooltip: {
            callbacks: {
              title: () => {},
              label: (context) => {
                const originalSet = context.datasetIndex === 0 ? this.current : this.previous;
                const originalItem = originalSet[context.dataIndex];
                const date = dateFormattedValue(originalItem.date, 'shortDate');
                const volume = this.$options.filters.dollarsAndCents(originalItem.volume);

                return `${date}: ${volume} (${originalItem.transactions})`;
              },
            },
          },
        },
      };
    },
  },
  mounted() {
    this.chart = new Chart(this.$refs.canvas, {
      type: 'line',
      data: this.data,
      options: this.options,
    });
  },
  methods: {
    extractDatasetData(label, data, color) {
      return {
        label,
        data: data.map((x) => x.volume / 100),
        backgroundColor: `${this.branding[color]}AA`,
        fill: true,
        tension: 0.5,
      };
    },
  },
};
</script>
