<template>
  <div class="affiliates-container" :class="{ 'dark-mode': darkMode }">
    <div class="affiliates-header">
      <i-user-card />
      <h2>Sub-Affiliate Accounts</h2>
    </div>


    <div class="centerer" v-if="loading">
      <a-spinner big />
    </div>
    <v-expansion-panels v-if="isos && isos.length" flat accordion>
      <v-expansion-panel v-for="(iso, index) in isos" :key="iso.uuid">
        <v-expansion-panel-header>
          <div class="panel-header">
            <i-user-card />
            {{ iso.name }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <entities-table
            :config="isosConfig[index]"
            showLoginButton
            showActions
            showEditButton
            :showDownloadCsvButton="false"
            :key="`${iso.uuid}-${search}`"
          >
            <template v-slot:item.affiliate>
              {{ iso.name }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | dateShort }}
            </template>
            <template v-slot:item.lastTransactionAt="{ item }">
              {{ item.lastTransactionAt | dateShort }}
            </template>
            <template v-slot:item.contact="{ item }">
              <a class="link-text"
              :href="`mailto:${item.contact.email}`">{{ item.contact.name }}</a>
            </template>
            <template v-slot:item.domain="{ item }">
              <a class="link-text"
              :href="`${item.domain.url}`">{{ item.domain.subdomain }}</a>
            </template>
            <template v-slot:item.id="{ item }">
              {{ item.id | uuidLast6 }}
            </template>
            <template v-slot:item.status="{ item }">
              <span v-if="item.status === MerchantStatus.PENDING || item.status === MerchantStatus.CREATED">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="pr-4 tw-text-gray-400"
                      color="#ffcb3a"
                      size="25"
                    >
                      $plus-circle
                    </v-icon>
                  </template>
                  <span>{{ item.status === MerchantStatus.PENDING ? 'Pending' : 'Created' }}</span>
                </v-tooltip>
              </span>
              <span v-else-if="item.status === MerchantStatus.ACTIVE">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="pr-4 tw-text-gray-400"
                      color="#399261"
                      size="25"
                    >
                      $check-circle
                    </v-icon>
                  </template>
                  <span>Active</span>
                </v-tooltip>
              </span>
              <span v-else-if="item.status === MerchantStatus.RESTRICTED">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="pr-4 tw-text-gray-400" color="red" size="25">
                      $ban
                    </v-icon>
                  </template>
                  <span>Restricted</span>
                </v-tooltip>
              </span>
              <span v-else-if="item.status === MerchantStatus.CLOSED">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="pr-4 tw-text-gray-400" color="red" size="25">
                      $times-circle
                    </v-icon>
                  </template>
                  <span>Closed</span>
                </v-tooltip>
              </span>
            </template>
          </entities-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { MerchantStatus } from '@/iso/views/merchants/merchants.types';
import ASpinner from '@/components/atoms/a-spinner.vue';
import IUserCard from './icons/i-user-card.vue';
import TableFilters from './common/table-filters';
import TableHeaders from './common/table-headers';

export default {
  components: { IUserCard, EntitiesTable, ASpinner },
  props: {
    isos: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    search: { type: String, required: true },
  },
  data() {
    return {
      MerchantStatus,
      isosConfig: this.isos.map((x) => ({
        filters: TableFilters,
        headers: TableHeaders,
        apiPath: `iso-merchants?iso=${x.uuid}`,
        routerBase: 'admin.merchants',
        search: this.search,
      })),
    };
  },
  computed: {
    ...mapState(['darkMode']),
  },
  watch: {
    isos(value) {
      this.isosConfig = value.map((x) => ({
        filters: TableFilters,
        headers: TableHeaders,
        apiPath: `iso-merchants?iso=${x.uuid}`,
        routerBase: 'admin.merchants',
        search: this.search,
      }));
    },
    search(value) {
      this.isosConfig.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
        x.search = value;
      });
    },
  },
};
</script>

<style scoped>

.affiliates-container {
  background: var(--card-background, #fff);
  padding: 2rem 1.75rem;
  border-radius: 0.5rem;
}

.centerer {
  display: grid;
  place-items: center;
  margin-block: 10vh;
}

.affiliates-header {
  display: flex;
  align-items: center;
  gap:  0.75rem;
  margin-block-end: 2rem;
}

.affiliates-header >>> svg {
  width: 20px;
  height: 16px;
}

h2 {
  font-weight: 600;
  color: #666;
  font-size: 1.375rem;
  margin: 0;
}

.dark-mode h2 {
  color: #ccc;
}

.panel-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--primary-color);
}

>>> .v-expansion-panels {
  gap: 0.5rem;
}

>>> .v-expansion-panel .v-expansion-panel-header {
  background: var(--background-color);
  border-radius: 5px;
}

>>> .v-expansion-panel-content {
  background: var(--card-background, #fff);
}

>>> .v-expansion-panel.v-item--active .v-expansion-panel-header {
  background: var(--card-background, #fff);
}

>>> .v-expansion-panel.v-item--active .panel-header {
  color: #666;
}

.dark-mode >>> .v-expansion-panel.v-item--active .panel-header {
  color: #ccc;
}

.affiliates-container >>> .entities-table-container .row {
  margin: 0;
}

.affiliates-container >>> .entities-table-container .v-data-footer {
  background: var(--card-background, #fff);
}
</style>
