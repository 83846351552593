var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-md-10"},[_c('div',{staticClass:"mb-10 fixed-header"},[_c('v-card',{staticClass:"text-center",attrs:{"flat":"","tile":""}},[_c('v-tabs',{attrs:{"grow":""}},[_c('v-tab',[_vm._v("Features")]),_c('v-tab-item',{staticClass:"pt-4"},[_c('draggable',{staticClass:"d-flex justify-sm-space-around list-group",attrs:{"list":_vm.formFields,"group":{ name: 'hppColumn', pull: 'clone', put: false },"clone":_vm.cloneElement}},_vm._l((_vm.formFields),function(element){return _c('div',{key:element.id,staticClass:"p-4"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(_vm._s(element.icon))]),_c('p',[_vm._v(_vm._s(element.title))])],1)}),0)],1),(_vm.customFields.length)?_c('v-tab',[_vm._v("Additional Fields")]):_vm._e(),(_vm.customFields.length)?_c('v-tab-item',{staticClass:"pt-4"},[_c('draggable',{staticClass:"d-flex justify-sm-space-around list-group",attrs:{"draggable":".draggable-list-item","list":_vm.customFields,"group":{ name: 'hppColumn', pull: 'clone', put: false },"clone":_vm.cloneElement,"move":_vm.checkMoveFields}},_vm._l((_vm.customFields),function(element){return _c('div',{key:element.id,staticClass:"p-4",class:{ 'draggable-list-item': !_vm.fieldExists(element) }},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(_vm._s(element.icon))]),_c('p',[_vm._v(_vm._s(element.title))])],1)}),0)],1):_vm._e()],1)],1)],1),_c('div',[_c('v-row',{staticClass:"pa-2 pb-6"},[_c('router-link',{attrs:{"to":{ name: 'merchant.hosted-payments' }}},[_c('v-btn',[_vm._v(" Return to HPP Dashboard ")])],1)],1),_c('v-row',{staticClass:"pa-2 pl-5"},[_c('v-row',[_c('v-btn',{staticClass:"py-4 px-2 mr-2",on:{"click":function($event){return _vm.previewResolution('desk')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-laptop ")])]}}])},[_c('span',[_vm._v("Desktop")])])],1),_c('v-btn',{staticClass:"py-4 px-2 mr-2",on:{"click":function($event){return _vm.previewResolution('tab')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-tablet ")])]}}])},[_c('span',[_vm._v("Tablet")])])],1),_c('v-btn',{staticClass:"py-4 px-2",on:{"click":function($event){return _vm.previewResolution('mob')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-cellphone ")])]}}])},[_c('span',[_vm._v("Mobile")])])],1)],1),_c('v-row',{staticClass:"d-flex justify-end"},[(_vm.canActivate)?_c('v-btn',{staticClass:"tw-ml-2 secondary",attrs:{"color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.activateHostedPaymentPage($event)}}},[_vm._v(" Activate ")]):_vm._e(),(_vm.canDeactivate)?_c('v-btn',{staticClass:"tw-ml-2 secondary",attrs:{"color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.deactivateHostedPaymentPage($event)}}},[_vm._v(" Deactivate ")]):_vm._e()],1)],1),_c('v-row',{staticClass:"hpp-editor pa-2 d-flex align-center",staticStyle:{"width":"600px","margin":"auto"}},_vm._l((_vm.hppConfig),function(row){return _c('HppFieldRow',{key:row.key,attrs:{"isEditing":"","columns":row.columns,"fixedAmount":_vm.hppData.amount,"hasCreditCardProcessor":_vm.hasCreditCardProcessor,"hasAchProcessor":_vm.hasAchProcessor,"surchargePercent":_vm.hppData.surchargePercent,"surchargeEnabled":_vm.hppData.surchargeEnabled,"adjustmentPercent":_vm.hppData.adjustmentPercent,"adjustmentDollarAmount":_vm.hppData.adjustmentDollarAmount,"nonCashAdjustmentEnabled":_vm.hppData.nonCashAdjustmentEnabled,"showAdjustment":_vm.hppData.adjustmentInHpp,"adjustmentName":_vm.hppData.adjustmentName,"plan":_vm.hppData.plan,"isRecurring":_vm.hppData.isRecurring,"showShipingFields":_vm.showShipingFields},on:{"removeField":_vm.removeField,"toggleShipping":_vm.toggleShipping}})}),1)],1),_c('div',{staticClass:"float-right"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.savePage(false)}}},[_vm._v(" Save ")])],1),_c('LoadingModal',{attrs:{"loadingText":_vm.loadingText,"showModal":_vm.isSubmitting}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }