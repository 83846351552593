<template>
  <div class="popover-container">
    <component
      :is="tag"
      @click.prevent="showPopover"
      :href="tag === 'a' ? '' : false"
      class="hover-spin-icon"
    >
      <template v-if="text">
        <v-btn x-small color="secondary">{{ text }}</v-btn>
      </template>
      <icon :icon="icon" v-if="icon" />
    </component>
    <popover
      v-show="shown"
      style="top: calc(100% + 0.25rem)"
      :class="left ? 'tw-left-0' : 'tw-right-0'"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </popover>
  </div>
</template>

<script>
import Icon from '@/common/icon';
import Popover from './Popover.vue';

export default {
  props: {
    tag: {
      type: String,
      required: false,
      default: 'button',
    },
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      shown: false,
    };
  },
  methods: {
    showPopover() {
      this.shown = !this.shown;
      if (!this.shown) {
        this.$emit('hidden');
      }
    },
    hide() {
      this.shown = false;
      this.$emit('hidden');
    },
  },
  components: { Icon, Popover },
};
</script>
