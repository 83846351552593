<template>
  <v-form @submit.prevent="submit" v-model="isValidForm">
    <div v-if="!active">
      <p>
        To enable two-factor using SMS, enter a mobile phone
        and click the button to send a one-time use code.
        Once you receive the code, enter it in the form below.
      </p>

      <v-text-field
        v-model="phone"
        :disabled="submitting || sendingCode"
        label="Mobile phone *"
        placeholder="Enter a phone number"
        outlined
        dense
        :rules="[
          (v) => !!v || 'Mobile phone is required'
        ]"
        @update:error="isValidPhone = !$event"
      />

      <v-btn
        color="secondary"
        :disabled="isSendCodeDisabled"
        :loading="sendingCode"
        class="mb-8"
        @click="sendCode"
      >
        Send a one-time code
      </v-btn>

      <div class="two-factor__submit">
        <v-text-field
          v-model="code"
          label="Verification Code *"
          placeholder="Enter the one-time code"
          outlined
          dense
          hide-details
          :rules="[
          (v) => !!v || 'Verification Code is required.'
        ]"
        />

        <v-btn
          color="secondary"
          :disabled="submitting || !isValidForm"
          :loading="submitting"
          type="submit"
        >
          {{ active ? 'Deactivate' : 'Activate' }}
        </v-btn>
      </div>
    </div>

    <support-link v-else />
  </v-form>
</template>

<script>
import SupportLink from './utils/support-link.vue';

export default {
  components: { SupportLink },
  props: {
    submitting: { type: Boolean, required: true },
    sendingCode: { type: Boolean, required: true },
    active: { type: Boolean, required: true },
    methodId: { type: String },
  },
  data() {
    return {
      isValidForm: false,
      isValidPhone: false,
      phone: '',
      code: '',
    };
  },
  computed: {
    isSendCodeDisabled() {
      if (this.active) return false;

      return this.submitting || this.sendingCode || !this.isValidPhone;
    },
  },
  methods: {
    sendCode() {
      const data = this.active
        ? { methodId: this.methodId }
        : { method: 'sms', mobilePhone: this.phone };

      this.$emit('sendCode', data);
    },
    activate() {
      const data = {
        method: 'sms',
        code: this.code,
        mobilePhone: this.phone,
      };

      this.$emit('activate', data);
    },
    deactivate() {
      const data = {
        methodId: this.methodId,
        code: this.code,
      };

      this.$emit('deactivate', data);
    },
    submit() {
      if (this.active) {
        this.deactivate();
      } else {
        this.activate();
      }
    },
  },
};
</script>
