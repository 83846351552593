<template>
  <div>
    <label :for="`colorPicker${uuid}`" :id="`colorPickerLabel${uuid}`">
      <slot />
    </label>

    <input v-model="theValue" :aria-describedby="`colorPickerLabel${uuid}`">
    <input v-model="theValue" type="color" :id="`colorPicker${uuid}`">
  </div>
</template>

<script>

let uuid = 0;

export default {
  props: {
    value: { type: String, required: true },
  },
  computed: {
    theValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>

<style scoped>
div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

label {
  flex: 1;
  line-height: 1.75;
}

input {
  width: 8ch;
}

label,
input {
  font-size: 1rem;
}

input[type="color"] {
  all: unset;
  cursor: pointer;
  border-radius: 100rem;
}

input[type="color"]:focus{
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

input[type="color"]:active {
  outline-offset: 0px;
}

input[type="color"]:hover::-webkit-color-swatch {
  box-shadow: 0 0 4px -1px var(--primary-color);
}

input[type="color"]::-webkit-color-swatch {
  border-radius: 100rem;
  height: 0.75rem;
  width: 2.5rem;
  border: none;
}
</style>
