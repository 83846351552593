








































































import Vue from 'vue';
import api from '@/common/api';
import {
  TransactionLimit, initTransactionLimit,
} from '@/common/components/support/support.types';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';

export default Vue.extend({
  data() {
    return {
      transactionLimitSettings: initTransactionLimit() as TransactionLimit,
      isSubmitting: false as boolean,
      numericRules: [
        (v: string) => /^([0-9.]+)$/.test(v) || 'Numeric value required',
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    dollarFormattedValueOrZero(value:any) {
      return value ? value / 100 : 0;
    },
    init_values() {
      this.transactionLimitSettings.maxTicketAmount = this.dollarFormattedValueOrZero(
        this.transactionLimitSettings.maxTicketAmount,
      );

      this.transactionLimitSettings.maxMonthlyVolume = this.dollarFormattedValueOrZero(
        this.transactionLimitSettings.maxMonthlyVolume,
      );
    },
    getData() {
      api
        .get('/transaction-limits')
        .then(({ data }) => {
          if (typeof data.data !== 'undefined') {
            this.transactionLimitSettings = data.data!;
            this.init_values();
          }

          (this.$refs.loader as any).setReady();
        })
        .catch(() => {
          (this.$refs.loader as any).setReady();
        });
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    saveData() {
      if (!this.isValid()) {
        return;
      }

      this.isSubmitting = true;

      const maxTicketAmount = Math.round(
        this.transactionLimitSettings.maxTicketAmount * 100,
      );
      const maxMonthlyVolume = Math.round(
        this.transactionLimitSettings.maxMonthlyVolume * 100,
      );

      api
        .post('/transaction-limits', {
          ...this.transactionLimitSettings,
          maxTicketAmount,
          maxMonthlyVolume,
        })
        .then(() => {
          this.isSubmitting = false;
          this.$toasted.success('Transaction Limits saved successfully!');
          (this.$refs.form as any).resetValidation();
        })
        .catch(({ response }) => {
          this.isSubmitting = false;
          this.$toasted.error(response.data.message);
        });
    },
  },
  components: {
    PageLoader,
    LoadingModal,
  },
});

