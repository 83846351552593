import { Month } from '@/common/types/dateTime.types';
import { DateTime } from 'luxon';

export function getCurrentMonth(): Month | string {
  const currentDate = new Date();
  currentDate.setDate(1);
  const currentMonth = currentDate.getMonth() + 1;
  return currentMonth > 9 ? currentMonth : `0${currentMonth}`;
}

export function getPreviousMonth(): Month | string {
  const currentDate = new Date();
  currentDate.setDate(1);
  currentDate.setMonth(currentDate.getMonth() - 1);
  const currentMonth = currentDate.getMonth() + 1;
  return currentMonth > 9 ? currentMonth : `0${currentMonth}`;
}

export function getPreviousMonthYear(): string {
  const previousMonth = DateTime.local().minus({ months: 1 });
  return previousMonth.toFormat('MM/yyyy');
}
