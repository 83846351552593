/**
 * @param name
 * @returns String by replacing all occurences of _ (underscore) with SPACE
 */
export function getUnderscoreReplacedWithSpace(name: string) : string {
  return name.replace(/_/g, ' ');
}

/**
 * @param input
 * @returns String with 1st charater of each Capitalized
 * This function 1st converts all characters to lowercase
 * then capitalize the 1st character of each word
 */
export function capitalizeFirstCharaterOfWords(input: string) : string {
  return input.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}
