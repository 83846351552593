<template>
  <p-container>
    <a-maintenance-alert />
    <p-header-1>Dashboard</p-header-1>

    <m-loader-container :loading="loading" :error="error">
      <div v-if='data !== null' class="dashboard-page">
        <h2>Open Batch Information</h2>

        <ul>
          <a-insight v-for="insight in insights" :key="insight.title" :insight="insight" />
        </ul>

        <div class="content">
          <div>
            <activity :activityPeriod="activityPeriod" :data="data" />
            <sales-by-credit-card-type />
          </div>

          <div>
            <m-card-table title="Recent Batches" :data="recentBatches" />
            <m-card-table @click.native.prevent="handleTxnClick" :title="recentTransactionsTitle" :data="recentTransactions" />
          </div>
        </div>
      </div>

      <template #error>
        There was a problem loading the dashboard data. Please refresh the page and try again.
      </template>
    </m-loader-container>
    <RestrictedAccountModal
      v-if='data !== null'
      :showModal='showAccountModal'
      @closeRestrictedAccountModal='closeRestrictedAccountModal'
      :iso='data.iso'
    />
    <v-navigation-drawer app v-model="drawer" temporary right v-bind:width="625">
      <TransactionDetails v-if="drawer" :id="selectedTransactionId" />
    </v-navigation-drawer>
  </p-container>
</template>

<script>
import api from '@/common/api';
import RestrictedAccountModal from '@/common/components/modals/RestrictedAccountModal.vue';
import { MerchantStatus } from '@/iso/views/merchants/merchants.types';
import PContainer from '@/components/presentations/p-container.vue';
import PHeader1 from '@/components/presentations/p-header-1.vue';
import MLoaderContainer from '@/components/molecules/m-loader-container.vue';
import AInsight from '@/components/atoms/a-insight.vue';
import MCardTable from '@/components/molecules/m-card-table.vue';
import { TransactionStatus } from '@/common/types/transactions.types';
import AMaintenanceAlert from '@/components/atoms/a-maintenance-alert.vue';
import SalesByCreditCardType from './merchant-dashboard/sales-by-credit-card-type.vue';
import Activity from './merchant-dashboard/Activity.vue';
import TransactionDetails from './transactions/components/TransactionDetails.vue';

export default {
  title: 'Dashboard',
  components: {
    RestrictedAccountModal,
    PContainer,
    PHeader1,
    MLoaderContainer,
    AInsight,
    MCardTable,
    Activity,
    SalesByCreditCardType,
    TransactionDetails,
    AMaintenanceAlert,
  },
  mounted() {
    this.loadDashboard();
  },
  data() {
    return {
      activityPeriod: 'thisMonth',
      data: null,
      showAccountModal: false,
      loading: true,
      error: false,
      drawer: false,
      selectedTransactionId: null,
    };
  },
  computed: {
    insights() {
      return [
        { icon: 'bill', value: this.data.transactionsToday, title: 'Transactions' },
        { icon: 'wallet', value: this.data.today.authorized, title: 'Authorized' },
        { icon: 'bill', value: this.data.today.captured, title: 'Captured' },
      ];
    },
    recentBatches() {
      return this.data.recentBatches.map((x) => ({
        date: {
          text: this.$options.filters.dateMedium(x.date),
          link: { name: 'merchant.batches.view', params: { id: x.id } },
        },
        transactions: x.transactions,
        volume: `$${this.$options.filters.dollarsAndCents(x.volume)}`,
      }));
    },
    recentTransactions() {
      return this.data.recentTransactions.map((x) => {
        const isTransactionRefunded = x.status === TransactionStatus.REFUNDED;
        const amount = isTransactionRefunded || (x.refundedAmount && x.originalTransactionId)
          ? x.refundedAmount : x.amount;

        return {
          date: this.$options.filters.dateMedium(x.created_at),
          'txn id': {
            text: this.$options.filters.uuidLast6(x.id),
            link: x.id,
          },
          customer: x.customer,
          amount: `$${this.$options.filters.dollarsAndCents(amount)}`,
          status: x.status,
        };
      });
    },
    recentTransactionsTitle() {
      return {
        text: 'Recent Transactions',
        link: { name: 'merchant.reports.transactions.details' },
      };
    },
  },
  methods: {
    handleTxnClick(e) {
      this.selectedTransactionId = e.target.pathname.substring(1);
      this.drawer = !this.drawer;
    },
    loadDashboard() {
      api
        .get('/dashboard')
        .then(({ data }) => {
          this.data = data;

          if (
            !this.data.merchantStatus === undefined
            && this.data.merchantStatus === MerchantStatus.RESTRICTED
          ) {
            this.showAccountModal = true;
          }
        })
        .catch(() => { this.error = true; })
        .finally(() => { this.loading = false; });
    },
    closeRestrictedAccountModal() {
      this.showAccountModal = false;
    },
  },
};
</script>

<style scoped>
>>> .dashboard-page {
  --spacing: min(1.5vmax, 1.25rem);
}

h2 {
  text-align: center;
  margin: 0;
  font-size: clamp(1.125rem, 3.3vw, 1.375rem);
  font-weight: 600;
}

ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: var(--spacing);
  margin-block: var(--spacing);
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing);
}

.content > div {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.content > div:first-child {
  flex: 4;
}

.content > div:last-child {
  flex: 3;
}
</style>
