





























import Vue from 'vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import ProductsPagesTabs from '@/merchant/components/products-pages-tabs.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      showDeleteModal: false,
      reLoadData: false,
      config: {
        pageTitle: 'Products',
        routerBase: 'merchant.products',
        apiPath: 'merchant/products',
        entityName: 'product',
        identifierProperty: 'name',
        reportFileName: '',
        filters: [],
        headers: [
          {
            text: 'ID',
            value: 'id',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Item Name',
            value: 'name',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Cost',
            value: 'cost',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Category',
            value: 'categoryName',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Measurement Unit',
            value: 'measurementUnit',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Sku',
            value: 'sku',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            align: ' d-none',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
        ],

      },
    };
  },
  methods: {
    getCost(item: any) {
      return (this.$options.filters as any).dollarsAndCents(item.cost);
    },
  },
  computed: {
    ...mapGetters(['isInvoicingEnabled']),
  },
  components: {
    EntitiesTable,
    PaidFeatureNotEnabled,
    ProductsPagesTabs,
  },
});
