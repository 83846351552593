<template>
  <v-expansion-panel class="faq-item" :id="faq.id">
    <v-expansion-panel-header class="faq-question">
      <span ref="faq">{{ faq.question }}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="faq-answer">
      <vue-markdown>
        {{ faq.answer }}
      </vue-markdown>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  components: { VueMarkdown },
  props: {
    faq: { type: Object, required: true },
    active: Boolean,
  },
  mounted() {
    if (this.active) {
      this.$refs.faq.scrollIntoView({ behavior: 'smooth' });
    }
  },
};
</script>

<style scoped>
.faq-item {
  border-radius: 0.5rem;
}

.faq-item.v-expansion-panel--active {
  box-shadow: 4px 4px 20px rgba(8, 24, 49, 0.15);
}

.faq-question {
  font-size: 0.875rem;
  font-weight: 600;
}

.faq-question.v-expansion-panel-header--active {
  color: var(--primary-color);
}

.faq-answer {
  font-size: 0.875rem;
  line-height: 1.42;
}

.faq-answer >>> ul {
  list-style: disc;
  padding-left: 18px !important;
}

.faq-answer >>> p {
  margin-block: 1em;
}

.faq-answer >>> p:first-child {
  margin-block-start: 0;
}

.faq-answer >>> p:last-child {
  margin-block-end: 0;
}

</style>
