<template>
  <div class="selector-container">
    <button
      class="selector card"
      :class="{ small }"
      @click="displayOptions = !displayOptions"
    >
      <div class="inner-selector">
        <i-calendar />

        {{ currentOption }}
      </div>

      <i-dropdown-arrow />
    </button>

    <div class="options" v-show="displayOptions">
      <button
        v-for="period in periods"
        :key="period.value"
        @click="selectOption(period.value)"
        :class="{ active: period.value === value }"
      >
        {{ period.text }}
      </button>
    </div>
  </div>
</template>

<script>
import ICalendar from '../icons/i-calendar.vue';
import IDropdownArrow from '../icons/i-dropdown-arrow.vue';

export default {
  components: { ICalendar, IDropdownArrow },
  props: {
    value: { type: String, required: true },
    noAllTime: { type: Boolean },
    small: { type: Boolean },
  },
  data() {
    const periods = [
      { value: 'thisMonth', text: 'Month To Date' },
      { value: 'lastMonth', text: 'Previous Month' },
      { value: 'thisYear', text: 'Year To Date' },
    ];

    if (!this.noAllTime) {
      periods.push({ value: 'allTime', text: 'All Time' });
    }

    return {
      displayOptions: false,
      periods,
    };
  },
  computed: {
    currentOption() {
      return this.periods.find((x) => x.value === this.value).text;
    },
  },
  methods: {
    selectOption(period) {
      this.$emit('input', period);
      this.displayOptions = false;
    },
  },
};
</script>

<style scoped>
.selector-container {
  --items-padding: 0.5em 0.75em;
  position: relative;
  width: min-content;
}

.selector {
  --card-padding: var(--items-padding);

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  min-width: 40ch;
}

.selector.small {
  --card-padding: 0.5em 0.8em;

  min-width: 28ch;
  font-size: 0.875rem;
}

.selector.small svg {
  width: 14px;
  height: 14px;
}

.inner-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1;
}

.options {
  position: absolute;
  top: calc(100% + 0.25rem);
  filter: drop-shadow(1px 2px 4px rgba(10, 10, 10, 0.16));
  border: 1px solid var(--card-border-color);
  border-radius: 0.5rem;
}

.options button {
  position: relative;
  padding: var(--items-padding);
  width: 100%;
  text-align: start;
  background: var(--background-accent-color);
  overflow: hidden;
}

.options button:first-child {
  border-radius: 0.5rem 0.5rem 0 0;
}

.options button:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}

.options button.active {
  background: var(--primary-color);
  color: #fff;
}

.options button:hover::before {
  content: '';
  inset: 0;
  position: absolute;
  background: var(--primary-color);
  opacity: 0.12;
}

.options button.active:hover::before {
  filter: brightness(0.7);
}
</style>
