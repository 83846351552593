<template>
  <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.7963 25.5845V18.4995H23.938H20.948L33.7963 25.5845Z" fill="#99DCE6" />
    <path
      d="M34.3594 25.758H28.8705C27.2022 25.758 25.8155 26.9135 25.4399 28.4663L33.5577 25.4908L20.9405 18.4996L20.926 18.4924L35.2838 24.9924L35.4066 16.0441L33.6083 17.3658L36.8583
      9.08908C37.111 8.44631 36.7933 7.72408 36.1577 7.47131L27.3394 3.99019C26.6966 3.73742 25.9744 4.05519 25.7216 4.69797L24.176 8.43908L24.3638 8.63408L19.3588 6.65519L18.6655
      6.38075L17.3799 5.87519C16.7372 5.62242 16.0149 5.94019 15.7622 6.58297L12.6927 14.0002H5.82438C4.58216 14.0002 3.57104 15.0041 3.57104 16.2535C3.57104 16.4052 3.58549 16.5496
      3.61438 16.6869L3.57104 16.6652V37.328C3.57104 38.5413 4.55327 39.5235 5.7666 39.5235H33.9622V37.4435V33.0524L33.5505 32.8285H34.121L33.9622 37.4363H36.8727V14.8235H34.7494L34.3594
      25.758Z"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M10.2228 11.104H3.25333C2.01111 11.104 1 12.1079 1 13.3573C1 14.5996 2.00389 15.6107 3.25333 15.6107H31.2322V22.6957"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path d="M1 13.7617V34.4245C1 35.6378 1.98222 36.6201 3.19556 36.6201H31.3911V30.1562"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path d="M32.1851 11.9346H34.3012V34.5473H31.3906" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M34.3017 22.8618H26.3067C24.3495 22.8618 22.7678 24.4435 22.7678 26.4007C22.7678 28.3579 24.3495 29.9396 26.3067 29.9396H34.2223"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path
      d="M26.3572 27.7148C27.0831 27.7148 27.6716 27.1263 27.6716 26.4004C27.6716 25.6744 27.0831 25.0859 26.3572 25.0859C25.6312 25.0859 25.0427 25.6744 25.0427 26.4004C25.0427 27.1263 25.6312
      27.7148 26.3572 27.7148Z"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path
      d="M8.28027 15.56L13.1914 3.67942C13.4442 3.03664 14.1664 2.71886 14.8092 2.97164L23.6275 6.45275C24.2703 6.70553 24.5808 7.42775 24.3281 8.07053L21.3742 15.5961"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path d="M14.7723 15.5599L19.0696 4.6616L16.094 3.48438L11.3057 15.3577" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M21.6055 5.53576L23.151 1.79465C23.4038 1.15187 24.126 0.834095 24.7688 1.08687L33.5871 4.56798C34.2299 4.82076 34.5405 5.54298 34.2877 6.18576L30.8138 15.358"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path d="M26.0534 1.59961L23.959 6.77072" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path d="M29.0362 2.77686L23.959 15.7263" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
  </svg>

</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['darkMode']),
  },
};
</script>
