




































































































import Vue from 'vue';
import APhoneNumberForm from '@/components/atoms/a-phone-number-form.vue';
import { InvoiceCustomer } from './invoices.types';

export default Vue.extend({
  components: { APhoneNumberForm },
  props: {
    invoiceCustomer: {
      type: Object as () => InvoiceCustomer,
      required: true,
    },
    countries: { required: true },
    states: { required: true },
  },
});
