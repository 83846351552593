

































import Vue from 'vue';
import { AlertType } from './alerts.types';

export default Vue.extend({
  props: {
    alertType: {
      type: Number,
      required: false,
      default: AlertType.Info,
    },
    message: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      required: false,
    },
    showToggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      AlertType,
    };
  },
  computed: {
    headerText(): any {
      return this.header ?? AlertType[this.alertType];
    },
  },
  methods: {
    toggleVisbility() {
      this.$emit('input', !this.value);
    },
  },
  components: {
  },
});
