

























































import Vue from 'vue';
import api from '@/common/api';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import DeleteModal from '@/common/components/modals/DeleteModal.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import ReceiptSettingNew from './ReceiptSettingNew.vue';
import ReceiptSettingEdit from './ReceiptSettingEdit.vue';
import { ReceiptSetting } from './support.types';

export default Vue.extend({
  data() {
    return {
      receiptSettings: [] as ReceiptSetting[],
      meta: {} as any,
      receiptSettingToEdit: {} as object,
      reLoadData: false,
      deleteModal: false,
      isDeleting: false,
      entitySelectedForDeletion: {} as any,
      config: {
        pageTitle: 'Receipt Settings',
        apiPath: 'receipt-settings',
        header: 'Receipt Settings',
        reportFileName: 'receipt_settings',
        identifierProperty: 'name',
        entityName: 'Receipt Setting',
        headers: [
          {
            text: 'id',
            value: 'uuid',
            sortable: false,
            align: ' d-none',
          },
          {
            text: 'Name',
            value: 'name',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black',
          },
          {
            text: 'Category',
            value: 'category',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black',
          },
          {
            text: 'Value',
            value: 'value',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black',
            align: 'center',
          },
          {
            text: 'Enabled',
            value: 'enabled',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black',
            align: 'center',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-black',
          },
        ],
        filters: [],
      },
    };
  },
  methods: {
    getData() {
      this.reLoadData = true;
      this.receiptSettingToEdit = {};
    },
    openNewReceiptSettingModal() {
      (this.$refs.newModal as any).show();
    },
    getReceiptSettingstData() {
      this.reLoadData = true;
      this.receiptSettingToEdit = {};
    },
    editReceiptSetting(receiptSetting: ReceiptSetting) {
      this.receiptSettingToEdit = receiptSetting;
      (this.$refs.editModal as any).show();
    },
    confirmDeleteEntity(entity: any) {
      this.entitySelectedForDeletion = entity;
      this.deleteModal = true;
    },
    deleteEntity() {
      this.reLoadData = false;
      this.isDeleting = true;
      this.deleteModal = false;
      api
        .delete(`${this.config.apiPath}/${this.entitySelectedForDeletion.uuid}`)
        .then(() => {
          this.getReceiptSettingstData();
        })
        .catch(({ response }) => {
          const message = response?.data?.message || 'Failed to delete. Try again.';
          this.$toasted.error(`${message} (${response.status})`);
        })
        .finally(() => {
          this.deleteModal = false;
          this.isDeleting = false;
        });
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    updateReloadData() {
      this.reLoadData = false;
    },
  },
  components: {
    EntitiesTable,
    ReceiptSettingNew,
    ReceiptSettingEdit,
    DeleteModal,
    LoadingModal,
  },
});

