






































































































































import Vue from 'vue';
import api, { setHeader } from '@/common/api';
import { mapState } from 'vuex';
import { toFloat, toInt } from '@/common/mappers/sell-rate-value-mapper';
import PageLoader from '@/common/components/PageLoader.vue';
import PContainer from '@/components/presentations/p-container.vue';
import MerchantContact from '@/common/components/forms/Contact.vue';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import { DocumentInfo } from '@/common/components/support/support.types';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { Feature } from '@/features.types';
import MerchantStatusModal from '@/merchant/views/components/MerchantStatusModal.vue';
import { Processor, ProcessorType } from '@/common/types/processors.types';
import MerchantBanking from './components/Banking.vue';
import MerchantDetails from './components/Details.vue';
import MerchantProcessors from './components/MerchantProcessors.vue';
import MerchantSellRates from './components/SellRates.vue';
import MerchantSettings from './components/MerchantSettings.vue';
import { initMerchant, Merchant, MerchantStatus } from './merchants.types';
import { RateGroup } from './components/sellRates.types';
import { createRateGroups, fillMerchantWithSellRates } from './components/sellRates.util';
import HardwareBoarding from './components/HardwareBoarding.vue';
import MerchantCardTypes from './components/CardTypes.vue';
import AuthNetEmulatorCard from './components/AuthNetEmulatorCard.vue';
import IDoor from '../../../components/icons/i-door.vue';
import IMerchantEdit from '../../../components/icons/i-merchant-edit.vue';

export const surchargePercentKey = 'surcharge-percent';

export default Vue.extend({
  props: {
    id: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      merchant: initMerchant() as Merchant,
      validationErrors: initValidationErrors() as ValidationErrors,
      signedAgreement: {} as DocumentInfo,
      rateGroups: [] as RateGroup[],
      isSubmitting: false as boolean,
      showErrorMsg: false as boolean,
      showActivationLink: false as boolean,
      surchargeAmount: 0 as number,
      processorCount: 0 as number,
      showMerchantStatusModal: false as boolean,
      processors: [] as Array<Processor>,
      isThereOneEnhancedDataProcessor: false as boolean,
    };
  },
  created() {
    document.title = 'RiseOS-Edit a Merchant';
    if (this.context === 'admin') {
      setHeader('x-iso-proxy', 'aurora');
    }
  },
  mounted() {
    this.loadMerchant();
    this.loadMerchantAgreementDocuments();
  },
  destroyed() {
    if (this.context === 'admin') {
      setHeader('x-iso-proxy', undefined);
    }
  },
  methods: {
    dollarFormattedValueOrZero(value:any) {
      return value ? value / 100 : 0;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    loadMerchant() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api.get(`merchants/${this.id}`).then(this.setMerchant).catch(this.handleLoadingError);
    },
    setMerchant({ data }: { data: JsonApiSingleResponse<Merchant> }) {
      const merchant: Merchant = data.data!;
      this.merchant = { ...merchant, sellRates: toFloat(merchant.sellRates) };
      this.merchant.settings.maxTicketAmount = this.dollarFormattedValueOrZero(this.merchant.settings.maxTicketAmount);
      this.merchant.settings.maxMonthlyVolume = this.dollarFormattedValueOrZero(this.merchant.settings.maxMonthlyVolume);

      this.showActivationLink = this.merchant?.status === MerchantStatus.PENDING
        || this.merchant?.status === MerchantStatus.CREATED;
      this.rateGroups = createRateGroups(this.merchant.sellRates, this.merchant.features);
      this.surchargeAmount = merchant.surchargePercent;
      this.processorCount = merchant?.processorCount;
      (this.$refs.loader as any).setReady();
    },
    handleLoadingError() {
      const message = this.$errorMessages.get('merchants.not_found');
      this.$toasted.error(`${message}`);
      this.$router.replace({ name: 'iso.merchants.index' });
    },
    updateSurchargeAmount(amount: number) {
      this.surchargeAmount = amount;
    },
    save() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      this.handleSurcharge();

      const originalRateGroups = this.rateGroups.map((rateGroup) => ({
        ...rateGroup,
        rates: rateGroup.rates.filter((rate) => rate.key !== surchargePercentKey),
      }));

      const merchant: Merchant = fillMerchantWithSellRates(this.merchant, originalRateGroups);
      merchant.settings.maxTicketAmount = Math.round(merchant.settings.maxTicketAmount * 100);
      merchant.settings.maxMonthlyVolume = Math.round(merchant.settings.maxMonthlyVolume * 100);

      api
        .put(`/merchants/${this.id}`, {
          ...merchant,
          surchargePercent: this.surchargeAmount,
          sellRates: toInt(merchant.sellRates),
        })
        .then(({ data }: { data: JsonApiSingleResponse<Merchant> }) => {
          this.subdomainUpdated();
          this.showSuccess(data);
        })
        .catch(this.handleSaveError);
    },
    handleSurcharge() {
      const surchargingRateGroup = this.rateGroups.find(
        (g) => g.name.toLowerCase() === Feature.SURCHARGING.toLowerCase(),
      )?.rates;
      const surchargePercent = surchargingRateGroup?.find((s) => s.key === surchargePercentKey)!;
      this.surchargeAmount = surchargePercent.value;
    },
    loadMerchantAgreementDocuments(): void {
      const params: any = {
        name: 'Signed Agreement',
      };
      api
        .get(`merchants/${this.id}/documents`, {
          params,
        })
        .then(this.setDocuments);
    },
    setDocuments({ data }: { data: JsonApiArrayResponse<DocumentInfo> }) {
      const documents = data.data;
      if (documents) {
        const signedAgreements = documents.filter(
          (document: DocumentInfo) => document.name === 'Signed Agreement',
        );
        if (signedAgreements.length > 0) {
          const [first] = signedAgreements;
          this.signedAgreement = first;
        }
      }
    },
    showSuccess(data: any) {
      this.processorCount = data.data.processorCount;
      this.submitActions(false);
      this.$toasted.success('Merchant updated successfully!');
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);

      const message = this.$errorMessages.get('merchants.default_save');

      if (response?.data?.errors && response?.data?.errors?.surchargePercent?.length > 0) {
        this.$toasted.error(response.data.errors.surchargePercent[0]);
      } else if (response.status === 422) {
        let msg: any = '';
        if (response?.data?.errors) {
          const errors = response?.data?.errors;
          Object.keys(errors).forEach((key: any, idx: any) => {
            if (idx > 0) msg += '<br />';
            msg += `${errors[key]}`;
          });
        } else if (response?.data?.message) {
          msg += response?.data?.message;
        }

        if (msg) this.$toasted.error(msg);
        else this.$toasted.error(`${message} (${response.status})`);
      } else {
        this.$toasted.error(`${message} (${response.status})`);
      }
      this.validationErrors = response.data.errors || {};
    },
    handleSendAcitvationLinkError({ response }: { response: any }) {
      this.submitActions(false);
      const message = this.$errorMessages.get('merchants.activation_link');
      this.$toasted.error(
        `${message} (${response.status})`,
      );
      this.validationErrors = response.data.errors || {};
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    showActivationLinkSuccess() {
      this.submitActions(false);
      this.$toasted.success('Activation link sent successfully!');
      this.$router.replace({ name: 'iso.merchants.index' });
    },
    sendActivationLink() {
      if (this.processorCount <= 0) return;

      api
        .post(`merchants/${this.id}/activation-email`, {})
        .then(this.showActivationLinkSuccess)
        .catch(this.handleSaveError);
    },
    updateProcessors(processors: Array<Processor>) {
      this.processors = processors;
      this.isThereOneEnhancedDataProcessor = this.handleProcessors();
    },
    toggleStatusModal() {
      this.showMerchantStatusModal = !this.showMerchantStatusModal;
    },
    closeMerchantStatusModal() {
      this.showMerchantStatusModal = false;
    },
    statusUpdateSuccess() {
      this.$toasted.success('Merchant Status Updated!');
      this.toggleStatusModal();
    },
    saveStatus(status: MerchantStatus) {
      this.merchant.status = status;
      api
        .patch(`merchants/${this.id}`, { status })
        .then(this.statusUpdateSuccess)
        .catch(this.handleSaveError);
    },
    handleProcessors(): boolean {
      const filtered = this.processors.filter((item: any) => item.key === ProcessorType.ELAVON);
      return filtered.length > 0;
    },
    subdomainUpdated() {
      const splitUrl = this.merchant.siteUrl.split('.');
      const splitSubDomain = splitUrl[0].split('//');
      splitSubDomain[1] = this.merchant.subdomain;
      const joinparts2 = splitSubDomain.join('//');
      splitUrl[0] = joinparts2;
      const finalDomainUrl = splitUrl.join('.');
      this.merchant.siteUrl = finalDomainUrl;
    },
  },
  computed: {
    ...mapState(['context']),
    isNotIllegalSurchargingState(): boolean {
      return !['CT', 'MA'].includes(this.merchant.state);
    },
    mobileView(): boolean {
      return this.$vuetify.breakpoint.name === 'sm'
      || this.$vuetify.breakpoint.name === 'xs';
    },
  },
  components: {
    MerchantBanking,
    MerchantDetails,
    MerchantContact,
    MerchantProcessors,
    MerchantSellRates,
    MerchantSettings,
    PageLoader,
    LoadingModal,
    MerchantStatusModal,
    MerchantCardTypes,
    AuthNetEmulatorCard,
    IDoor,
    IMerchantEdit,
    PContainer,
    HardwareBoarding,
  },
});
