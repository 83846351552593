
























































































import Vue from 'vue';
import api from '@/common/api';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import CustomFieldNew from './CustomFieldNew.vue';
import CustomFieldEdit from './CustomFieldEdit.vue';
import { CustomField, CustomFieldOption, CustomFieldType } from './support.types';

export default Vue.extend({
  data() {
    return {
      customFields: [] as CustomField[],
      fieldTypes: Object.values(CustomFieldType) as string[],
      meta: {} as any,
      loading: true,
      options: {} as any,
      showDeleteModal: false as boolean,
      customFieldToDelete: null as CustomField | null,
      customFieldToEdit: {} as object,
      isSubmitting: false as boolean,
      loadingText: '' as string,
      headers: [
        {
          text: 'id',
          value: 'id',
          sortable: false,
          align: ' d-none',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Type',
          value: 'type',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Options',
          value: 'options',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Virtual Terminal',
          value: 'inVirtualTerminal',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Hosted Payments Page',
          value: 'inHPP',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Invoices',
          value: 'inInvoices',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Required',
          value: 'required',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Searchable',
          value: 'isSearchable',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
      ],
      errors: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    showDeleteModal() {
      if (!this.showDeleteModal) {
        this.customFieldToDelete = null;
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getCustomOptionValues(options: CustomFieldOption[]) {
      return options.map((option) => option.value).join(', ');
    },
    getData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.options;
      const params: any = {
        page,
        perPage: itemsPerPage === -1 ? this.meta.total : itemsPerPage,
        role: '',
        sortBy,
        sortDesc,
      };
      this.loading = true;
      api
        .get('/custom-fields', { params })
        .then(this.assignCustomFields)

        .catch(() => {
          this.customFields = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    promptForDelete(customField: CustomField) {
      this.showDeleteModal = true;
      this.customFieldToDelete = customField;
    },
    submitActions(isSubmitting: boolean, loadingText: string = 'Deleting...') {
      this.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    deleteCustomField() {
      this.showDeleteModal = false;
      this.submitActions(true);
      api
        .delete(`/custom-fields/${this.customFieldToDelete?.id}`)
        .then(() => {
          this.customFieldToDelete = null;
          this.getData();
        })
        .catch(({ response }: { response: any }) => {
          this.$toasted.error(`There was a problem deleting custom field. (${response.status})`);
        })
        .finally(() => {
          this.submitActions(false);
        });
    },
    assignCustomFields({ data }: { data: JsonApiArrayResponse<CustomField> }) {
      this.meta = data.meta;
      this.customFields = data.data!;
    },
    isOptionsDisable(customFieldType: CustomFieldType): boolean {
      return customFieldType === CustomFieldType.TEXT || customFieldType === CustomFieldType.CHECK;
    },
    openNewCustomFieldModal() {
      (this.$refs.newModal as any).show();
    },
    editCustomField(customField: CustomField) {
      this.customFieldToEdit = customField;
      (this.$refs.editModal as any).show();
    },
  },
  components: {
    LoadingModal,
    CustomFieldNew,
    CustomFieldEdit,
  },
});
