<template>
  <p-container>
    <a-back-link to="settings">Back to Settings</a-back-link>

    <EntitiesTable :config="config" showAddButton :key="deletes">
      <template v-slot:item.name="{ item }">
        {{ item.name }}
        <span v-if="item.isDefault" class="default-tax-rate">
          Default
        </span>
      </template>
      <template v-slot:item.taxRate="{ item }">
        {{ item.taxRate }}%
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col>
            <v-btn
              color="grey"
              x-small
              :to="{ name: 'merchant.settings.tax-rates.edit', params: { id: item.uuid }}"
              style="text-decoration: none;"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" dark small style="color: #fff;">
                    mdi-square-edit-outline
                  </v-icon>
                </template>
                Edit
              </v-tooltip>
            </v-btn>

            <v-btn
              color="grey"
              x-small
              class="ml-2"
              @click="taxRateToDelete = item.uuid; showDeleteModal = true;"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small style="color: #fff;">
                    mdi-close-circle
                  </v-icon>
                </template>
                Delete
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </EntitiesTable>

    <v-dialog v-model="showDeleteModal" persistent max-width="500">
      <v-card>
        <v-card-title>
          Are you sure to delete this taxRate?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            color="grey"
            @click="showDeleteModal = false"
            :disabled="deleting"
          >
            No
          </v-btn>
          <v-btn
            small
            color="error"
            @click="() => deleteTaxRate(taxRateToDelete)"
            :disabled="deleting"
            :loading="deleting"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </p-container>
</template>

<script>
import api from '@/common/api';
import PContainer from '@/components/presentations/p-container.vue';
import ABackLink from '@/components/atoms/a-back-link.vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';

export default {
  components: { PContainer, ABackLink, EntitiesTable },
  data() {
    return {
      taxRateToDelete: null,
      showDeleteModal: false,
      deleting: false,
      deletes: 0,
      config: {
        pageTitle: 'Sales Tax Rates',
        header: 'Tax Rates',
        createButtonText: '+ ADD',
        routerBase: 'merchant.settings.tax-rates',
        apiPath: 'tax-rates',
        filters: [],
        headers: [
          {
            text: 'UUID',
            value: 'uuid',
            align: ' d-none',
          },
          {
            text: 'Default',
            value: 'isDefault',
            align: ' d-none',
          },
          {
            text: 'Name',
            value: 'name',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Tax Rate',
            value: 'taxRate',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Actions',
            value: 'actions',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            align: 'center',
            width: '8rem',
          },
        ],
      },
    };
  },
  methods: {
    async deleteTaxRate(id) {
      this.deleting = true;

      try {
        await api.delete(`tax-rates/${id}`);
        this.$toasted.success('Tax Rate successfully deleted');
        this.showDeleteModal = false;
        this.deletes += 1;
      } catch (e) {
        this.$toasted.error('There was an error while deleting the tax rate');
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>

<style scoped>
.default-tax-rate {
  background: var(--primary-color);
  color: #fff;
  font-size: 0.625rem;
  border-radius: 10rem;
  padding: 0.3em 1.3em;
  margin-inline-start: 0.5em;
}
</style>
