





















































































































































































































































































import Vue from 'vue';
import { range } from 'lodash';
import HppSingleTransaction from '@/common/components/payments/HppSingleTransaction.vue';
import { mask } from 'vue-the-mask';
import { AchAccountHolderType, AchAccountType, PaymentType } from '@/common/types/payments.types';
import { Feature } from '@/features.types';
import { VirtualTerminalMerchant } from '@/iso/views/merchants/merchants.types';
import api from '@/common/api';
import { Plan } from '../../plans/plans.types';


export default Vue.extend({
  name: 'HppCardForm',
  data() {
    return {
      Feature,
      hppMerchant: {} as VirtualTerminalMerchant,
      cardType: PaymentType.CREDIT as string,
      loadingTaxRates: false as boolean,
      taxRates: [] as any[],
      taxRate: null as any,
      form: {
        robot: false,
      },
    };
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixedAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    hasCreditCardProcessor: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasAchProcessor: {
      type: Boolean,
      required: false,
      default: false,
    },
    surchargePercent: {
      type: Number,
      required: false,
    },
    surchargeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    adjustmentDollarAmount: {
      type: Number,
      required: false,
    },
    adjustmentPercent: {
      type: Number,
      required: false,
    },
    nonCashAdjustmentEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    adjustmentName: {
      type: String,
      required: false,
    },
    showAdjustment: {
      type: Boolean,
      required: false,
      default: false,
    },
    plan: {
      type: Object as () => Plan,
      required: false,
    },
    isRecurring: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasProducts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    if (this.isEditing) this.fetchTaxRates();
  },
  mounted() {
    this.verifyCaptcha();
  },
  methods: {
    async fetchTaxRates() {
      this.loadingTaxRates = true;

      try {
        const { data } = await api.get('tax-rates?hpp=true&isDefault=true');
        this.taxRates = data.data.sort((a: any, b: any) => (b.isDefault ? 1 : -1));
        this.taxRates = this.taxRates.map((x) => ({ ...x, name: `${x.name} (${x.taxRate}%)` }));

        if (this.$store.state.hppTaxRate) {
          const taxRateUuid = this.taxRates.find((x) => x.taxRate === this.$store.state.hppTaxRate)?.uuid;
          if (taxRateUuid) {
            this.taxRate = taxRateUuid;
            return;
          }
          this.taxRates.push({
            taxRate: this.$store.state.hppTaxRate,
            name: `${this.$store.state.hppTaxRate}%`,
            uuid: 'default',
          });
          this.taxRate = 'default';
        } else {
          const defaultTaxRate = this.taxRates.find((x) => x.isDefault);
          if (defaultTaxRate) {
            this.taxRate = defaultTaxRate.uuid;
          }
        }
      } catch (e) {
        this.$toasted.error('There was an error while loading the tax rates');
      } finally {
        this.loadingTaxRates = false;
      }
    },
    isCreditMethod() {
      return this.values.type === PaymentType.CREDIT;
    },
    isAchMethod() {
      if (!this.hasCreditCardProcessor && this.hasAchProcessor) {
        this.values.type = PaymentType.ACH;
      }
      return this.values.type === PaymentType.ACH;
    },
    setCredit() {
      this.values.type = PaymentType.CREDIT;
    },
    setACH() {
      this.values.type = PaymentType.ACH;
    },
    setTotal(amount: number) {
      this.values.amount = amount;

      if (this.$store.state.hppTaxRate) {
        const tax = (amount * this.$store.state.hppTaxRate) / 100;
        this.values.amount += tax;
      }
    },
    checkCardType(value: string) {
      let creditCardNumber = value;
      creditCardNumber = creditCardNumber !== undefined ? creditCardNumber.replaceAll(/\s/g, '') : '';
      if (creditCardNumber === '' || creditCardNumber.length < 6) {
        this.cardType = PaymentType.CREDIT;
        return;
      }
      api.post('credit-card-number', { creditCardNumber }).then(({ data }) => {
        this.cardType = data.data.card_type;
      });
    },
    async verifyCaptcha() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('hostedyPaymentSubmit');

      console.log(token);
    },
    isNotEditingTotal(): number {
      if (this.fixedAmount && this.surchargeEnabled) {
        return this.fixedAmount + this.surchargeFee;
      }
      if (this.fixedAmount && !this.surchargeEnabled && !this.displayAdjustment) {
        return this.fixedAmount;
      }
      if (this.surchargeEnabled
       && this.isCreditTransaction && this.isCardTypeCredit && !this.isRecurring) {
        return Number(this.values.amount) + this.surchargeFee;
      }
      if (this.isRecurring) {
        return this.plan.amount;
      }
      if (this.fixedAmount && this.displayAdjustment) {
        return this.fixedAmount + this.nonCashFee;
      }
      if (!this.fixedAmount && this.displayAdjustment) {
        if (this.values.amount) {
          return this.values.amount + this.nonCashFee;
        }
      }
      return this.values.amount;
    },
    isEditingTotal(): number {
      if (this.fixedAmount && this.surchargeEnabled) {
        return this.fixedAmount + this.surchargeFee;
      }
      if (this.fixedAmount && !this.surchargeEnabled && !this.displayAdjustment) {
        return this.fixedAmount;
      }
      if (this.isRecurring) {
        return this.plan.amount;
      }
      if (this.fixedAmount && this.displayAdjustment) {
        return this.fixedAmount + this.nonCashFee;
      }
      return 0;
    },
  },
  computed: {
    months(): number[] {
      return range(1, 13);
    },
    years(): number[] {
      return range(new Date().getFullYear(), new Date().getFullYear() + 19);
    },
    accountTypes(): { text: string; value: AchAccountType }[] {
      return [
        {
          text: (this.$options.filters as any).capitalize(AchAccountType.CHECKING),
          value: AchAccountType.CHECKING,
        },
        {
          text: (this.$options.filters as any).capitalize(AchAccountType.SAVINGS),
          value: AchAccountType.SAVINGS,
        },
      ];
    },
    accountHolderTypes(): { text: string; value: AchAccountHolderType }[] {
      return [
        {
          text: (this.$options.filters as any).capitalize(AchAccountHolderType.BUSINESS),
          value: AchAccountHolderType.BUSINESS,
        },
        {
          text: (this.$options.filters as any).capitalize(AchAccountHolderType.PERSONAL),
          value: AchAccountHolderType.PERSONAL,
        },
      ];
    },
    total(): number {
      if (!this.isEditing) {
        return this.isNotEditingTotal();
      } return this.isEditingTotal();
    },
    surchargeFee(): number {
      if (!this.isEditing) {
        if (this.fixedAmount) {
          const calculatedSurchargeFee = (this.fixedAmount * this.surchargePercent) / 100;
          return calculatedSurchargeFee;
        }
        if (this.surchargeEnabled && this.isCreditTransaction && this.isCardTypeCredit) {
          const calculatedSurchargeFee = (this.values.amount * this.surchargePercent) / 100;
          return calculatedSurchargeFee;
        }
        return 0;
      }
      if (this.fixedAmount) {
        const calculatedSurchargeFee = (this.fixedAmount * this.surchargePercent) / 100;
        return calculatedSurchargeFee;
      }
      return 0;
    },
    nonCashFee(): number {
      if (!this.isEditing) {
        if (this.fixedAmount) {
          if (this.adjustmentPercent) {
            const calculatedNonCashFee = this.fixedAmount * (this.adjustmentPercent / 100);
            return calculatedNonCashFee;
          } if (this.adjustmentDollarAmount) {
            return this.adjustmentDollarAmount;
          }
        }
        if (this.nonCashAdjustmentEnabled && !this.fixedAmount) {
          if (this.adjustmentPercent) {
            const calculatedNonCashFee = this.values.amount * (this.adjustmentPercent / 100);
            return calculatedNonCashFee;
          } if (this.adjustmentDollarAmount) {
            return this.adjustmentDollarAmount;
          }
        }
        return 0;
      }
      if (this.fixedAmount && this.adjustmentPercent) {
        const calculatedNonCashFee = this.fixedAmount * (this.adjustmentPercent / 100);
        return calculatedNonCashFee;
      }
      if (this.adjustmentDollarAmount) {
        return this.adjustmentDollarAmount;
      }
      return 0;
    },
    isCreditTransaction(): boolean {
      return this.values.type === PaymentType.CREDIT;
    },
    isCardTypeCredit(): boolean {
      return this.cardType === PaymentType.CREDIT;
    },
    displayAdjustment(): boolean {
      if (this.showAdjustment && this.nonCashAdjustmentEnabled) {
        return true;
      }
      return false;
    },
  },
  directives: {
    mask: {
      bind(el, binding, vnode, oldVnode) {
        if (binding.value) {
          mask(el, binding, vnode, oldVnode);
        }
      },
    },
  },
  components: {
    HppSingleTransaction,
  },
  watch: {
    taxRate(val) {
      const selectedTaxRate = this.taxRates.find((x) => x.uuid === val);
      this.$store.commit('setHppTaxRate', selectedTaxRate?.taxRate || 0);
    },
  },
});
