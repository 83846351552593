
















import Vue from 'vue';
import { getPreviousMonth } from '@/common/util/dateTime.util';
import {
  BillingItem,
  ResidualFeeType,
  ResidualFeeTypeFilterOption,
} from '@/common/types/residuals.types';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import ReportsPagesTabs from '@/merchant/components/reports-pages-tabs.vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';

export default Vue.extend({
  data() {
    return {
      ResidualFeeType,
      EntityFilterType,
      config: {
        pageTitle: 'Billing Details Report',
        apiPath: 'billing-details',
        reportFileName: 'billing_details_report',
        headers: [
          {
            text: 'Date',
            value: 'date',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Processor name',
            value: 'processorName',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Fee type',
            value: 'rateKey',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Quantity',
            value: 'quantity',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Rate',
            value: 'sellRate',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Total',
            value: 'total',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
        ],
        filters: [
          {
            name: 'processorName',
            display: 'Processor Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'rateKey',
            display: 'Fee type',
            value: '',
            type: EntityFilterType.SELECT,
            items: [
              { display: 'Cash Setup Fee', value: ResidualFeeType.CASH_SERVICE_FEE },
              { display: 'Cash Monthly Fee', value: ResidualFeeType.CASH_MONTHLY_FEE },
              { display: 'Cash Per Transaction Fee', value: ResidualFeeType.CASH_TRANSACTION_FEE },
              { display: 'Gateway Setup Fee', value: ResidualFeeType.GATEWAY_SERVICE_FEE },
              { display: 'Gateway Monthly Fee', value: ResidualFeeType.GATEWAY_MONTHLY_FEE },
              {
                display: 'Gateway Per Transaction Fee',
                value: ResidualFeeType.GATEWAY_TRANSACTION_FEE,
              },
              {
                display: 'Electronic Check Setup Fee',
                value: ResidualFeeType.ELECTRONIC_CHECK_SETUP_FEE,
              },
              {
                display: 'Electronic Check Monthly Fee',
                value: ResidualFeeType.ELECTRONIC_CHECK_MONTHLY_FEE,
              },
              {
                display: 'Electronic Check Per Transaction Fee',
                value: ResidualFeeType.ELECTRONIC_CHECK_TRANSACTION_FEE,
              },
              {
                display: 'Electronic Check Batch Fee',
                value: ResidualFeeType.ELECTRONIC_CHECK_BATCH_FEE,
              },
              {
                display: 'Customer Vault Service Fee',
                value: ResidualFeeType.CUSTOMER_VAULT_SERVICE_FEE,
              },
              {
                display: 'Customer Vault Monthly Fee',
                value: ResidualFeeType.CUSTOMER_VAULT_MONTHLY_FEE,
              },
              {
                display: 'Customer Vault Transaction Fee',
                value: ResidualFeeType.CUSTOMER_VAULT_TRANSACTION_FEE,
              },
            ] as ResidualFeeTypeFilterOption[],
          },
          {
            name: 'startDate',
            display: 'Start Date',
            type: EntityFilterType.DATE_MONTH,
            open: false,
            value: `${new Date().getFullYear()}-${getPreviousMonth()}`,
          },
          {
            name: 'endDate',
            display: 'End Date',
            type: EntityFilterType.DATE_MONTH,
            open: false,
            value: `${new Date().getFullYear()}-${getPreviousMonth()}`,
          },
        ],
        footer: {
          header: 'Total',
          displayHeaders: ['total'],
          displayFunction(filters: any, entities: any[], header: string) {
            if (header === 'total') {
              const formatted = filters.number2Decimals(entities.reduce(
                (sum: number, residual: BillingItem) => sum + residual.raw, 0,
              ));
              return `$${formatted}`;
            }
            return '';
          },
        },
      },
    };
  },
  components: { EntitiesTable, ReportsPagesTabs },
});
