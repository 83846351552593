




























import Vue from 'vue';
import { CustomerAddress } from '@/common/types/customers.types';

export default Vue.extend({
  props: {
    address: Object as () => CustomerAddress,
  },
});
