








































import Vue from 'vue';
import api from '@/common/api';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { IpWhitelist, initIpWhitelist } from './support.types';

export default Vue.extend({
  props: {
    ipWhitelistToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newIpWhitelist: initIpWhitelist() as IpWhitelist,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      validationErrors: initValidationErrors() as ValidationErrors,
    };
  },
  watch: {
    ipWhitelistToEdit() {
      this.newIpWhitelist = this?.ipWhitelistToEdit;
    },
    newIpWhitelist() {
      this.loadIpWhitelistData();
    },
  },
  methods: {
    loadIpWhitelistData() {
      if (this?.newIpWhitelist.name === null
          || this?.newIpWhitelist.name === ''
          || Object.keys(this?.newIpWhitelist).length === 0) return;

      this.showModal = true;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }

      this.submitActions(true);

      api
        .put(`/ip-whitelists/${this.newIpWhitelist.id}`, {
          name: this.newIpWhitelist.name,
          ipAddress: this.newIpWhitelist.ipAddress,
        })
        .then(() => {
          this.newIpWhitelist = initIpWhitelist();
          (this.$refs.form as any).reset();
          this.close();
        })
        .catch(({ response }: { response: any }) => {
          this.submitActions(false);
          if (response.status === 422) {
            const errors = response?.data?.errors;
            let msg: any = '';
            Object.keys(errors).forEach((key: any, idx: any) => {
              if (idx > 0) msg += '<br />';
              msg += `${errors[key]}`;
            });
            this.$toasted.error(msg);
          } else {
            this.$toasted.error(`There was a problem editing Ip in Whitelist. (${response.status})`);
          }

          this.validationErrors = response.data.errors || {};
        });
    },
    show() {
      this.newIpWhitelist = this?.ipWhitelistToEdit;
      this.showModal = false;
    },
    close() {
      this.$emit('created');
      this.submitActions(false);
      this.newIpWhitelist = initIpWhitelist();
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    LoadingModal,
  },
});
