















import Vue from 'vue';

export default Vue.extend({
  props: {
    curtain: {
      type: Boolean,
      default: true,
    },
    closeOnCurtainClick: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      shown: false as boolean,
    };
  },
  computed: {
    hasButtons(): boolean {
      return !!this.$slots.buttons;
    },
  },
  methods: {
    curtainClicked() {
      if (this.closeOnCurtainClick) {
        this.hide();
      }
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
    },
    setVisibility(show: boolean) {
      this.shown = show;
    },
  },
});
