



























import Vue from 'vue';
import PCard from '@/components/presentations/p-card.vue';
import { BuyRate } from '../types/buyRate';

export default Vue.extend({
  components: { PCard },
  props: {
    value: { type: Array as () => BuyRate[], required: true },
    hideTitle: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      groups: [
        'Gateway',
        'Electronic Check',
        'Cash',
        'Customer Vault',
        'Enhanced Data (Level II, III)',
        'Surcharging',
        'Invoicing',
        'Cash Discount',
      ],
    };
  },
  computed: {
    groupedRates() {
      const groupedRates = new Map<string, BuyRate[]>();

      this.value.forEach((rate) => {
        const groupedRate = groupedRates.get(rate.group);

        groupedRates.set(rate.group, groupedRate ? [...groupedRate, rate] : [rate]);
      });

      return groupedRates;
    },
    availableGroupedRates(): Map<string, BuyRate[]> {
      const groups = [...this.groupedRates];

      const availableGroups = groups
        .filter((group) => this.groups.includes(group[0]))
        .sort((a, b) => this.groups.indexOf(a[0]) - this.groups.indexOf(b[0]));
      return new Map(availableGroups);
    },
  },
});
