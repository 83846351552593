




























































import Vue from 'vue';
import { Processor, ProcessorType, OmahaConfig } from '@/common/types/processors.types';
import { initProcessor } from '@/common/util/processors.util';
import LoadingModal from '@/common/components/LoadingModal.vue';
import api from '@/common/api';

export default Vue.extend({
  props: {
    merchantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: true as boolean,
      isSubmitting: false as boolean,
      processor: initProcessor(ProcessorType.OMAHA) as Processor,
      gidRules: [
        (v: string) => !!v || 'GID is required',
        (v: string) => /^[a-zA-Z0-9]{5,13}$/.test(v) || 'GID has to be between 6 to 13 alphanumeric characters',
      ],
      midRules: [
        (v: string) => !!v || 'MID is required',
        (v: string) => /^[a-zA-Z0-9]{6,16}$/.test(v) || 'MID has to be between 6 to 16 alphanumeric characters',
      ],
      tidRules: [
        (v: string) => !!v || 'TID is required',
        (v: string) => /^[a-zA-Z0-9]{4,8}$/.test(v) || 'TID has to be between 4 to 8 alphanumeric characters',
      ],
      passwordRules: [
        (v: string) => !!v || 'Password is required',
        (v: string) => /^[0-9]{6}$/.test(v) || 'Password has to be 6 digits',
      ],
    };
  },
  methods: {
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.processor = initProcessor();
    },
    submit() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      const { tid } = this.processor.config as OmahaConfig;
      api
        .post(`merchants/${this.merchantId}/processors`, {
          key: this.processor.key,
          name: this.processor.name,
          config: {
            ...this.processor.config,
            tid,
          },
        })
        .then(() => {
          this.$toasted.success('Success');
          this.reset();
          this.$emit('created');
        })
        .catch(({ response }) => {
          this.$toasted.error(response.data.message);
        })
        .finally(() => this.submitActions(false));
    },
  },
  components: { LoadingModal },
});
