import { BillingRecurrenceModel, RecurrenceFrequency } from '../types/billingRecurrences.types';
import { Month, WeekDay } from '../types/dateTime.types';

export const DEFAULT_RECURRENCE_NAME: string = '';
export const DEFAULT_RECURRENCE_DESCRIPTION: string = '';
export const DEFAULT_RECURRENCE_RRULE_TEXT: string = '';
export const DEFAULT_RECURRENCE_RECURRENCE_TEXT: string = '';
export const DEFAULT_RECURRENCE_AMOUNT: number = 0;
export const DEFAULT_RECURRENCE_RECURRENCE_FREQUENCY:
  RecurrenceFrequency = RecurrenceFrequency.MONTHLY;
export const DEFAULT_RECURRENCE_INTERVAL: number = 1;
export const DEFAULT_RECURRENCE_MONTH_DAY: number = 1;
export const DEFAULT_RECURRENCE_MONTH: Month = Month.January;
export const DEFAULT_RECURRENCE_WEEK_DAY: WeekDay = WeekDay.Monday;

export function initBillingRecurrenceModel(): BillingRecurrenceModel {
  return {
    recurrence: {
      recurrenceText: DEFAULT_RECURRENCE_RECURRENCE_TEXT,
      rruleText: DEFAULT_RECURRENCE_RRULE_TEXT,
      recurrenceFrequency: DEFAULT_RECURRENCE_RECURRENCE_FREQUENCY,
    },
    amount: DEFAULT_RECURRENCE_AMOUNT,
  };
}
