import { DataTimestamps, JsonApiData } from '@/jsonApi.types';
import { PaymentMethod, PaymentType } from '@/common/types/payments.types';
import { Customer } from '@/common/types/customers.types';
import { NonCashAdjustmentFee } from './nonCashAdjustment.types';

export enum TransactionStatus {
  PENDING = 'pending',
  CAPTURED = 'captured',
  VOIDED = 'voided',
  SETTLED = 'settled',
  AUTHORIZED = 'authorized',
  REFUNDED = 'refunded',
  DECLINED = 'declined',
}

export interface Transaction extends JsonApiData, DataTimestamps {
  customer: string;
  type: PaymentType;
  account: string;
  amount: number;
  createdBy: string;
  description: string;
  status: TransactionStatus;
  refundedAmount?: number;
  refundedAt?: Date;
  customerDetails?: Customer;
  paymentMethod?: PaymentMethod;
  subscriptionId?: string;
  reason?: string;
  responseCode?: string;
  originalTransactionId?: string;
  customFields?: []
  enhancedFields?: []
  adjustmentAmount?: number,
  surchargeAmount?: number;
  transactionSource?: string;
  nonCashAdjustmentFee?: NonCashAdjustmentFee;
  amountPaid?: number;
  dueAmount?: number;
  cardType?: string;
  // eslint-disable-next-line camelcase
  merchant_subdomain?: string;
}
