var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"400","persistent":_vm.isSubmitting},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Change Password ")])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword($event)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" Change password for "),_c('strong',[_vm._v(_vm._s(_vm.user.username))])])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"New Password","type":"password","rules":[
            function (v) { return !!v || 'New password is required'; },
            function (v) { return v && v.length >= 8 || 'New password must be at least 8 characters'; }
          ],"error-messages":_vm.validationErrors.password,"disabled":_vm.isSubmitting,"dense":"","outlined":""},model:{value:(_vm.password.password),callback:function ($$v) {_vm.$set(_vm.password, "password", $$v)},expression:"password.password"}}),_c('v-text-field',{attrs:{"label":"Confirm New Password","type":"password","rules":[
            function (v) { return !!v || 'New password confirmation is required'; },
            function (v) { return v === _vm.password.password || 'Password confirmation must match'; }
          ],"error-messages":_vm.validationErrors.password_confirmation,"disabled":_vm.isSubmitting,"dense":"","outlined":""},model:{value:(_vm.password.password_confirmation),callback:function ($$v) {_vm.$set(_vm.password, "password_confirmation", $$v)},expression:"password.password_confirmation"}}),_c('ul',{staticClass:"password-conditions"},[_c('li',{staticClass:"password-conditions__condition",class:{ ok: _vm.password.password && _vm.password.password.length >= 8 }},[_c('small',[_vm._v("Passwords must be at least 8 characters long")]),(_vm.password.password && _vm.password.password.length >= 8)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-check ")]):_vm._e(),(_vm.password.password_confirmation && _vm.password.password_confirmation.length >= 8)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-check ")]):_vm._e()],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"secondary","disabled":!_vm.isValid || _vm.isSubmitting,"loading":_vm.isSubmitting}},[_vm._v(" Reset Password ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }