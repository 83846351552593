<template>
  <div class="spinner" :class="{ fullscreen }">
    <v-progress-circular
      indeterminate
      color='primary'
      class="mb-0"
      size="100"
      width="10"
    />
  </div>
</template>

<script>

export default {
  props: {
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
