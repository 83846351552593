
























import Vue from 'vue';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import { mapGetters } from 'vuex';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';

export default Vue.extend({
  data() {
    return {
      config: {
        pageTitle: 'Customer Batch Imports',
        header: 'Customer Batch Imports',
        routerBase: 'merchant.customers.batch.uploads',
        apiPath: 'customer-uploads',
        entityName: 'customerBatchUploads',
        identifierProperty: 'id',
        reportFileName: 'customer_batch_uploads_report',
        filters: [],
        headers: [
          {
            text: 'Batch Import ID',
            value: 'id',
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
            sortable: false,
          },
          {
            text: 'Upload Date',
            value: 'createdAt',
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'File Name',
            value: 'fileName',
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Rows Uploaded',
            value: 'validRowsCount',
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Error Count',
            value: 'errorCount',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['isCustomerVaultEnabled']),
  },
  components: {
    EntitiesTable,
    PaidFeatureNotEnabled,
  },
});
