























import Vue from 'vue';
import api, { setHeader } from '@/common/api';
import Modal from '@/common/components/Modal.vue';
import { DocumentName } from '@/common/components/support/support.types';
import getSubdomain from '@/common/context';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import DocumentViewer from '@/common/components/DocumentViewer.vue';

export default Vue.extend({
  data() {
    return {
      docHtml: '' as string,
      merchantId: '' as string,
    };
  },
  created() {
    document.title = 'RiseOS-Sign Propsal';
  },
  mounted() {
    api.get(`merchants/${getSubdomain()}/virtual-terminal`).then(this.setMerchantId);
    (this.$refs.previewProposalModal as any).show();
  },
  methods: {
    confirmProposal() {
      this.uploadFile('/docs/merchantagreement.pdf', DocumentName.AGREEMENT);
    },
    activateMerchant() {
      api
        .post('merchant/activation')
        .then(() => {
          this.$router.push('/');
        })
        .catch(this.showError);
    },
    uploadFile(filepath: string, filename: string) {
      const oReq = new XMLHttpRequest();
      oReq.open('GET', filepath, true);
      oReq.responseType = 'blob';

      oReq.onload = () => {
        const file = oReq.response;
        const upload = new FormData();
        upload.append('name', filename);
        upload.append('document', file);

        api
          .post('/merchant/documents', upload, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.activateMerchant();
          });
      };
      oReq.send();
    },
    showError({ response }: { response: any }) {
      this.$toasted.error(`Problem agreeing to the proposal. (${response.status})`);
    },
    handleLoadingError() {
      this.$toasted.error('Something went wrong. Please try again.');
    },
    closeRatesModal() {
      (this.$refs.documentViewer as any).hide();
    },
    setMerchantId({ data }: { data: JsonApiSingleResponse<any> }) {
      this.merchantId = data.data!.merchantId;
    },
    showPricingSchedule() {
      const params: any = {
        merchantId: this.merchantId,
      };
      setHeader('Accept', 'text/html');
      setHeader('Accept', 'application/json');
      api
        .get('/support-info/fees', {
          params,
        })
        .then((response) => {
          this.docHtml = response.data;
          (this.$refs.documentViewer as any).show();
        });
    },
  },
  components: {
    Modal,
    DocumentViewer,
  },
});
