




















































































































import Vue from 'vue';
import slugify from 'slugify';
import LoadingModal from '@/common/components/LoadingModal.vue';
import api from '@/common/api';
import { BasicProcessor, Processor } from '@/common/types/processors.types';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { PaymentType } from '@/common/types/payments.types';
import { initDefaultHppConfig, HppColumn, HppField } from '@/common/util/hostedPaymentPages.util';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import { CustomField, CustomFieldType, InfoField } from '@/common/components/support/support.types';
import { nanoid } from 'nanoid';
import { HostedPaymentPageTypes } from '@/common/types/hostedPaymentPages.types';
import ProcessorSelector from '../components/hosted-payments/ProcessorSelector.vue';
import HostedPaymentAmount from '../components/hosted-payments/HostedPaymentAmount.vue';
import { Plan } from '../plans/plans.types';

export default Vue.extend({
  props: {},
  data() {
    return {
      PaymentType,
      HostedPaymentPageTypes,
      hppWriteModel: {
        name: '',
        description: '',
        redirect: null,
        achProcessorId: '',
        creditCardProcessorId: '',
        amount: 0,
        isFixedAmount: false,
        config: initDefaultHppConfig(),
        slug: '',
        isRecurring: false,
        hasProducts: false,
        planId: null,
        products: [] as string[],
      },
      isSubmitting: false as boolean,
      showModal: false as boolean,
      availableProcessors: [] as BasicProcessor[],
      availablePlans: [] as Plan[],
      selectedProcessor: null as Processor | null,
      validationErrors: initValidationErrors() as ValidationErrors,
      chosenHppType: '' as string,
      productsSearch: '' as string,
      productsSearching: false as boolean,
      productsSearchList: [],
      productsList: [] as string[],
      hppPaymentTypes: [
        {
          text: HostedPaymentPageTypes.STANDARD,
          value: HostedPaymentPageTypes.STANDARD,
        },
        {
          text: HostedPaymentPageTypes.RECURRING,
          value: HostedPaymentPageTypes.RECURRING,
        },
        {
          text: HostedPaymentPageTypes.FIXED_AMOUNT,
          value: HostedPaymentPageTypes.FIXED_AMOUNT,
        },
        {
          text: HostedPaymentPageTypes.PRODUCTS,
          value: HostedPaymentPageTypes.PRODUCTS,
        },
      ],
      infoFields: [] as any[],
      billingFields: [] as any[],
      billingRequiredFields: [] as any[],
      shippingFields: [] as any[],
      shippingRequiredFields: [] as any[],
    };
  },
  watch: {
    productsSearch: 'searchProducts',
  },
  mounted() {
    this.getCustomerInfoFields();
    this.loadAvailableProcessors();
    this.loadCustomFields();
    this.loadAvailablePlans();
  },
  methods: {
    async searchProducts(query: string) {
      if (!query) return;
      this.productsSearching = true;

      try {
        const { data } = await api.get('/merchant/products', { params: { q: query } });
        this.productsSearchList = data.data.map((x: any) => ({ text: x.name, value: x.id }));
      } catch (e) {
        this.$toasted.error('There was an error while searching for products');
      } finally {
        this.productsSearching = false;
      }
    },
    loadAvailableProcessors() {
      if (this.showModal) {
        this.submitActions(true);
      }
      api
        .get('processors')
        .then(this.setAvailableProcessors)
        .catch(this.handleError)
        .finally(() => this.submitActions(false));
    },
    setFixedAmount(fixedAmount: number) {
      this.hppWriteModel.amount = fixedAmount;
    },
    loadCustomFields() {
      api
        .get('/custom-fields', {
          params: {
            hpp: true,
          },
        })
        .then(this.assignCustomFields);
    },
    loadAvailablePlans() {
      api
        .get('/plans')
        .then(this.setAvailablePlans);
    },
    assignCustomFields({ data }: { data: JsonApiArrayResponse<CustomField> }) {
      if (data.data!.length > 0) {
        const fields: CustomField[] = data.data!;
        const customFieldsColumnFields: HppField[] = fields.map((field: CustomField) => ({
          id: field.id,
          type: this.getCustomFieldType(field.type),
          name: field.name,
          value: this.getCustomFieldType(field.type) === 'VCheckbox' ? false : '',
          display: field.type,
          required: field.required,
          attributes: {
            label: (this.$options.filters as any).capitalize(field.name),
            items: field.options.map((option) => option.value),
          },
          customField: true,
          hidden: false,
          style: '',
        }));

        customFieldsColumnFields.unshift({
          id: nanoid(),
          type: 'HppHeading',
          attributes: {
            label: 'Additional Information',
          },
          name: 'additionalFieldsHeading',
          value: 'Additional Information',
          required: false,
          customField: false,
          hidden: false,
          style: 'grid-column-start: 1; grid-column-end: 3',
        });

        const customFieldsColumn: HppColumn = {
          key: nanoid(),
          type: 'Column',
          name: 'additionalFields',
          fields: customFieldsColumnFields,
        };
        const previousToLast = this.hppWriteModel.config[0].columns.length - 1;
        this.hppWriteModel.config[0].columns.splice(previousToLast, 0, customFieldsColumn);
      }
    },
    getCustomFieldType(customFieldType: CustomFieldType) {
      if (customFieldType === CustomFieldType.TEXT) {
        return 'VTextField';
      }
      if (customFieldType === CustomFieldType.CHECK) {
        return 'VCheckbox';
      }

      return 'VSelect';
    },
    sluggifyForSubdomain(value: string): any {
      this.hppWriteModel.slug = slugify(value, {
        lower: true,
        strict: true,
      });
    },
    handleError() {
      const message = this.$errorMessages.get('hosted_payment_pages.processors_load');
      this.$toasted.error(message);
    },
    setAvailableProcessors({ data: { data } }: { data: { data: BasicProcessor[] } }) {
      this.availableProcessors = data;
    },
    setAvailablePlans({ data: { data } }: { data: { data: any } }) {
      this.availablePlans = data;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.selectedProcessor = null;
    },
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.reset();
    },
    onCreated() {
      this.$emit('created');
      this.close();
    },
    saveHpp() {
      if (!this.isValid) {
        return;
      }
      this.submitActions(true);
      this.setChosenHppTypeWrite();

      const cols = this.hppWriteModel.config[0].columns;
      Object.keys(cols).forEach((key: any) => {
        const colArr = cols[key];
        if (colArr.name === 'billingAddress' || colArr.name === 'shippingAddress') {
          const fieldCat = colArr.name === 'billingAddress' ? 'Billing' : 'Shipping';
          Object.keys(colArr.fields).forEach((fieldKey: any) => {
            if (!['billingHeading', 'shippingHeading', 'shippingEnabled'].includes(colArr.fields[fieldKey].name)) {
              if (!this.isVisible(colArr.fields[fieldKey].name, fieldCat)) {
                this.hppWriteModel.config[0].columns[key].fields[fieldKey].hidden = true;
              } else {
                this.hppWriteModel.config[0].columns[key].fields[fieldKey].hidden = false;
              }

              if (this.isRequired(colArr.fields[fieldKey].name, fieldCat)) {
                this.hppWriteModel.config[0].columns[key].fields[fieldKey].required = true;
              } else {
                this.hppWriteModel.config[0].columns[key].fields[fieldKey].required = false;
              }
            }
          });
        }
      });

      this.hppWriteModel.products = this.productsList;

      api
        .post('/hosted-payments', this.hppWriteModel)
        .then(this.showSuccess)
        .catch(this.handleSaveError);
    },
    showSuccess(response: any) {
      const hppId = response.data.data.id;
      window.location.assign(`/hosted-payments/${hppId}/edit`);
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      if (response.status === 422) {
        const errors = response?.data?.errors;
        let msg: any = '';
        Object.keys(errors).forEach((key: any, idx: any) => {
          if (idx > 0) msg += '<br />';
          msg += `${errors[key]}`;
        });
        this.$toasted.error(msg);
      } else {
        const message = this.$errorMessages.get('hosted_payment_pages.create');
        this.$toasted.error(`${message} (${response.status})`);
      }

      this.validationErrors = response.data.errors || {};
    },
    setChosenHppTypeWrite() {
      if (this.hppWriteModel.planId !== null) {
        this.hppWriteModel.isRecurring = true;
      }

      if (this.chosenHppType === HostedPaymentPageTypes.STANDARD) {
        this.hppWriteModel.isFixedAmount = false;
        this.hppWriteModel.isRecurring = false;
        this.hppWriteModel.hasProducts = false;
      } if (this.chosenHppType === HostedPaymentPageTypes.FIXED_AMOUNT) {
        this.hppWriteModel.isRecurring = false;
        this.hppWriteModel.hasProducts = false;
        this.hppWriteModel.isFixedAmount = true;
      } if (this.chosenHppType === HostedPaymentPageTypes.RECURRING) {
        this.hppWriteModel.isFixedAmount = false;
        this.hppWriteModel.hasProducts = false;
        this.hppWriteModel.isRecurring = true;
      } if (this.chosenHppType === HostedPaymentPageTypes.PRODUCTS) {
        this.hppWriteModel.isFixedAmount = false;
        this.hppWriteModel.isRecurring = false;
        this.hppWriteModel.hasProducts = true;
      }
    },
    getFieldName(name: string, removeName: string) {
      const fieldName = name.replace(removeName, '').replace(/ /g, '');
      return (fieldName.charAt(0).toLowerCase() + fieldName.slice(1));
    },
    isVisible(fieldName: string, fieldType: string) {
      return ((fieldType === 'Billing' && this.billingFields.includes(fieldName))
        || (fieldType === 'Shipping' && this.shippingFields.includes(fieldName)));
    },
    isRequired(fieldName: string, fieldType: string) {
      return ((fieldType === 'Billing' && this.billingRequiredFields.includes(fieldName))
        || (fieldType === 'Shipping' && this.shippingRequiredFields.includes(fieldName)));
    },
    getCustomerInfoFields() {
      api
        .get('/info-fields', {
          params: {
            hpp: true,
          },
        })
        .then(this.assignCustomerInfoFields);
    },
    assignCustomerInfoFields({ data }: { data: JsonApiArrayResponse<InfoField> }) {
      this.infoFields = data.data!;
      Object.keys(this.infoFields).forEach((key: any) => {
        const field = this.infoFields[key] as InfoField;
        const fieldName = this.getFieldName(field.name, field.category);
        if (field.category === 'Billing') {
          this.billingFields.push(fieldName);
          if (field.required) {
            this.billingRequiredFields.push(fieldName);
          }
        } else if (field.category === 'Shipping') {
          this.shippingFields.push(fieldName);
          if (field.required) {
            this.shippingRequiredFields.push(fieldName);
          }
        }
      });
    },
  },
  computed: {
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    hppUrl() {
      return `${window.location.origin}/pay/`;
    },
    planDescription(): string {
      if (this.hppWriteModel.planId) {
        const plan = this.availablePlans.find((p) => p.id === this.hppWriteModel.planId);
        return `$${plan?.amount! / 100} ${plan?.recurrenceText}`;
      }
      return '';
    },
  },
  components: {
    LoadingModal,
    ProcessorSelector,
    HostedPaymentAmount,
  },
});
