









































































import Vue from 'vue';

import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { DateTime } from 'luxon';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import { mapGetters } from 'vuex';
import { CustomerBatchUpload } from './customerBatchUploads.types';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      batchUpload: null as CustomerBatchUpload | null,
    };
  },
  created() {
    document.title = 'RiseOS-View Customer Batch Import';
  },
  mounted() {
    if (this.isCustomerVaultEnabled) {
      this.loadCustomerBatchUpload();
    }
  },
  methods: {
    loadCustomerBatchUpload() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api.get(`/customer-uploads/${this.id}`)
        .then(this.setCustomerBatchUpload)
        .catch(() => {
          this.$toasted.error('Problem loading customer batch import');
          (this.$refs.loader as any).setError();
        });
    },
    setCustomerBatchUpload({ data }: {data: JsonApiSingleResponse<CustomerBatchUpload>}) {
      if (data.data === undefined || data.data.id === undefined) {
        return;
      }
      this.batchUpload = data.data;
      (this.$refs.loader as any).setReady();
    },
  },
  computed: {
    ...mapGetters(['isCustomerVaultEnabled']),
    pageTitle(): string {
      if (!this.batchUpload) {
        return '';
      }
      return this.batchUpload.fileName.trim();
    },
    uploadedAt(): string {
      const { createdAt } = this.batchUpload!;
      const dt = DateTime.fromISO(createdAt!.toLocaleString());
      const formattedDate = dt.toLocaleString(DateTime.DATETIME_FULL).replace(', ', '\n');
      return formattedDate;
    },
    hasErrors(): boolean {
      return this.batchUpload !== null && this.batchUpload.errorCount! > 0;
    },
  },
  components: {
    PageHeader,
    PageLoader,
    PaidFeatureNotEnabled,
  },
});
