




















import Vue from 'vue';

import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import {
  BillingRecurrenceModel,
  RecurrenceFrequency,
} from '@/common/types/billingRecurrences.types';
import { Plan } from './plans.types';
import BillingRecurrence from '../components/BillingRecurrence.vue';

export default Vue.extend({
  data() {
    return {
      billingRecurrence: {
        recurrence: {
          recurrenceText: '',
          rruleText: '',
          recurrenceFrequency: RecurrenceFrequency.MONTHLY,
        },
        amount: 0,
      } as BillingRecurrenceModel,
      planName: '' as string,
      description: '' as string,
      validationErrors: {} as any,
    };
  },
  created() {
    document.title = 'RiseOS-Create Plan';
  },
  methods: {
    save() {
      const plan: Plan = {
        name: this.planName,
        description: this.description,
        amount: this.billingRecurrence.amount,
        recurrenceText: this.billingRecurrence.recurrence.recurrenceText,
        rruleText: this.billingRecurrence.recurrence.rruleText,
        recurrenceFrequency: this.billingRecurrence.recurrence.recurrenceFrequency,
      };
      api.post('plans', plan).then(this.showSuccess).catch(this.handleSaveError);
    },
    showSuccess({ data }: { data: JsonApiSingleResponse<Plan> }) {
      if (data.data === undefined || data.data.id === undefined) {
        return;
      }
      this.$toasted.success('Plan created successfully!');
      this.$router.push({ name: 'merchant.plans.view', params: { id: data.data!.id } });
    },
    handleSaveError({ response }: { response: any }) {
      this.$toasted.error(`There was a problem creating the plan. (${response.status})`);
      this.validationErrors = response.data.errors || {};
    },
  },
  components: {
    PageHeader,
    BillingRecurrence,
  },
});
