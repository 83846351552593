

















import Vue from 'vue';

import { mapGetters } from 'vuex';
import { isEnabled } from 'vue-feature-flipping';
import adminNavItems from '@/tenants/admin/navigation';
import isoNavItems from '@/tenants/affiliate/navigation';
import merchantNavItems from '@/tenants/merchant/navigation';

function filterDisabledNavItems(navItems: any[]): any[] {
  return navItems.filter((item) => item.feature === undefined || isEnabled(item.feature));
}

export default Vue.extend({
  computed: {
    ...mapGetters(['currentUserCan', 'context']),
    navbarItems(): any[] {
      if (this.context === 'admin') {
        return filterDisabledNavItems(adminNavItems);
      }
      if (this.context === 'iso') {
        return filterDisabledNavItems(isoNavItems);
      }
      if (this.context === 'merchant') {
        return filterDisabledNavItems(merchantNavItems);
      }

      return [];
    },
    allowedItems(): any[] {
      return this.navbarItems
        .filter((item: any) => !item.permission || this.currentUserCan(item.permission));
    },
  },
  methods: {
    blur() {
      (document.activeElement as HTMLElement).blur();
    },
  },
});
