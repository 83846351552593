<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3323 15.6416C12.3323 18.1188 10.3245 20.1266 7.8473 20.1266C5.37008 20.1266 3.3623 18.1188 3.3623 15.6416"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M21.3311 15.6417C21.3311 18.119 19.3233 20.1267 16.8461 20.1267C14.3689 20.1267 12.3611 18.119 12.3611 15.6417L13.4589 7.1123"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M30.3369 15.6414C30.3369 18.1186 28.3292 20.1264 25.8519 20.1264C23.3747 20.1264 21.3669 18.1186 21.3669 15.6414V7.15527"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M3.37671 15.7137L5.76727 8.46263C6.04171 7.62486 6.82893 7.06152 7.71004 7.06152H29.2684H35.0173C35.8984 7.06152 36.6784 7.62486 36.96 8.46263L39.3434 15.6993C39.3434 18.1765 37.3284
      20.1337 34.8512 20.1337C32.3739 20.1337 30.3662 18.126 30.3662 15.6487L29.2756 7.08319"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M37.0318 19.5273V36.7957C37.0318 38.5073 35.6452 39.9012 33.9263 39.9012H9.26962C7.55795 39.9012 6.16406 38.5146 6.16406 36.7957V19.9246"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M11.5742 39.9012V26.0345C11.5742 25.0739 12.3542 24.2939 13.3148 24.2939H19.8003C20.7609 24.2939 21.5409 25.0739 21.5409 26.0345V39.9012"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M31.4637 31.8554H26.2276C25.5632 31.8554 25.0215 31.3137 25.0215 30.6493V25.6587C25.0215 24.9943 25.5632 24.4526 26.2276 24.4526H31.4637C32.1282 24.4526 32.6698 24.9943 32.6698
      25.6587V30.6493C32.6698 31.3137 32.1282 31.8554 31.4637 31.8554Z"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M6.57617 7.24227V5.15505C6.57617 4.2306 7.32728 3.47949 8.25173 3.47949H34.5984C35.5228 3.47949 36.274 4.2306 36.274 5.15505V7.59616"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path d="M9.64609 6.26025L4.12109 15.7503C14.1672 15.7503 39.2355 15.8875 39.2355 15.8875" :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path d="M10.6059 21.3906L34.6198 21.2679L31.6226 5.17676L10.967 6.73676L9.0459 20.5529L10.6059 21.3906Z"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M10.0061 12.8682C10.0061 15.3454 7.99835 17.3532 5.52113 17.3532C3.04391 17.3532 1.03613 15.3454 1.03613 12.8682"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M19.0049 12.8683C19.0049 15.3455 16.9971 17.3533 14.5199 17.3533C12.0427 17.3533 10.0349 15.3455 10.0349 12.8683L11.1327 4.33887"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M28.0042 12.8684C28.0042 15.3457 25.9964 17.3534 23.5192 17.3534C21.042 17.3534 19.0342 15.3457 19.0342 12.8684V4.38232"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M1.05078 12.9335L3.44134 5.68236C3.71578 4.84458 4.503 4.28125 5.38411 4.28125H26.9424H32.6913C33.5725 4.28125 34.3525 4.84458 34.6341 5.68236L37.0174 12.919C37.0174 15.3962 35.0025
      17.3535 32.5252 17.3535C30.048 17.3535 28.0402 15.3457 28.0402 12.8685L26.9497 4.30292"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path d="M1 13.1064C11.0461 13.1064 36.9017 13.1064 36.9017 13.1064" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M34.7064 16.7466V34.0149C34.7064 35.7266 33.3197 37.1205 31.6008 37.1205H6.94418C5.23251 37.1205 3.83862 35.7338 3.83862 34.0149V17.151"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M9.24805 37.1204V23.2537C9.24805 22.2932 10.028 21.5132 10.9886 21.5132H17.4742C18.4347 21.5132 19.2147 22.2932 19.2147 23.2537V37.1204"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M29.1309 29.0751H23.8948C23.2304 29.0751 22.6887 28.5335 22.6887 27.869V22.8785C22.6887 22.214 23.2304 21.6724 23.8948 21.6724H29.1309C29.7954 21.6724 30.3371 22.214 30.3371
      22.8785V27.869C30.3371 28.5335 29.8026 29.0751 29.1309 29.0751Z"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path d="M11.4434 26.9951V29.8335" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M4.25 4.46151V2.37429C4.25 1.44984 5.00111 0.69873 5.92556 0.69873H32.2722C33.1967 0.69873 33.9478 1.44984 33.9478 2.37429V4.8154"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
  </svg>

</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['darkMode']),
  },
};
</script>
