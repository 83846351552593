import { PaymentMethod, VaultPaymentMethod } from '@/common/types/payments.types';
import { Customer } from '@/common/types/customers.types';
import { initPaymentMethod } from '@/common/util/payments.util';
import { initCustomer } from '@/common/util/customers.util';
import {
  CustomFieldFillable,
  CustomFieldType,
  CustomField,
  DefaultLevel3Setting,
  InfoFieldFillable,
  InfoFieldCategory,
} from '@/common/components/support/support.types';


export interface VirtualTerminalTransaction
{
  amount: number;
  description: string;
  paymentMethod: PaymentMethod | VaultPaymentMethod;
  customer: Customer;
  customFields: CustomField[];
  enhancedFields?: CustomField[];
  surchargeAmount: number;
  transactionType: string;
  internalNote?: string;
  level3Fields?: DefaultLevel3Setting;
  isLevel3Transaction?: boolean;
}

export function initVirtualTerminalTransaction(): VirtualTerminalTransaction {
  return {
    amount: 0,
    description: '',
    paymentMethod: initPaymentMethod(),
    customer: initCustomer(),
    customFields: [],
    surchargeAmount: 0,
    transactionType: 'moto',
    internalNote: '',
    isLevel3Transaction: false,
  };
}

export function initCustomFields(): CustomFieldFillable[] {
  return [{
    id: Math.random().toString(36),
    name: '',
    type: CustomFieldType.TEXT,
    options: [],
    inVirtualTerminal: false,
    inHPP: false,
    inInvoices: false,
    required: false,
    value: '',
  }];
}


export function initInfoFields(): InfoFieldFillable[] {
  return [{
    id: Math.random().toString(36),
    name: '',
    inVirtualTerminal: false,
    inHPP: false,
    required: false,
    value: '',
    category: InfoFieldCategory.SHIPPING,
  }];
}
