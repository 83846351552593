import { AffiliateNotification, AffiliateNotificationName } from '../types/affiliateNotifications.types';

// eslint-disable-next-line import/prefer-default-export
export function initAffiliateNotifications(): AffiliateNotification[] {
  return [
    {
      name: AffiliateNotificationName.MERCHANT_INACTIVITY_REPORT,
      enabled: false,
      timeframe: 0,
      customTimeframe: 0,
      notificationFrequency: '',
    },
  ];
}
