<template>
  <div>
    <div v-if="showNotActivePage">
       <Alert
        v-model="showAlert"
        :alertType="AlertType.Error"
        header="Access Denied"
        :message="alertMessage"
        :showToggle="false"
      />
    </div>
    <PageLoader ref="loader" v-else>
      <v-container fluid style="max-width: 600px; padding: 0; margin-top: clamp(2rem, 7vh, 4.75rem)">
        <v-row justify="center">
          <v-form
            style="margin: auto; padding: 0"
            ref="form"
            lazy-validation
            @submit.prevent="submitTransaction"
          >
            <HppFieldRow
              v-for="row in hppConfig"
              :columns="row.columns"
              :key="row.key"
              :rowNum="row.key"
              :fixedAmount="paymentPage.amount || 0"
              :hasCreditCardProcessor="hasCreditCardProcessor"
              :hasAchProcessor="hasAchProcessor"
              @toggleShipping="toggleShipping"
              :surchargePercent="paymentPage.surchargePercent"
              :surchargeEnabled="paymentPage.surchargeEnabled"
              :adjustmentPercent="paymentPage.adjustmentPercent"
              :adjustmentDollarAmount="paymentPage.adjustmentDollarAmount"
              :adjustmentName="paymentPage.adjustmentName"
              :showAdjustment="paymentPage.adjustmentInHpp"
              :nonCashAdjustmentEnabled="paymentPage.nonCashAdjustmentEnabled"
              :isRecurring="paymentPage.isRecurring"
              :hasProducts="paymentPage.hasProducts"
              :plan="paymentPage.plan"
              @robotCheck="robotCheck"
              :showShipingFields="showShipingFields"
            >
              <template v-if="paymentPage.hasProducts" #products>
                <v-col cols="12">
                  <h3 class="hpp-heading">Products</h3>
                  <div v-if="loadingProducts" class="centerer">
                    <v-progress-circular indeterminate />
                  </div>

                  <ul>
                    <product-item
                      v-for="product in products"
                      :key="product.id"
                      :product="product"
                      @increment="() => product.quantity += 1"
                      @decrement="() => product.quantity -= 1"
                    />
                  </ul>

                  <div class="products-total">
                    Total: ${{ productsTotal | dollarsAndCents }}
                  </div>
                </v-col>
              </template>

              <template #button>
                <v-row style="width: 100%">
                  <v-btn
                    :disabled="isPreview"
                    style="margin: 1rem 0 1.5rem auto; padding-inline: 2rem"
                    color="primary"
                    type="submit"
                    height="52"
                  >
                    Submit Payment
                    <v-icon right>mdi-credit-card-outline</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </HppFieldRow>
            <PaymentDetailsModal
              ref="paymentDetailsModal"
              :response="txnResponse"
              :subscription="subscription"
              @closed="resetResponse"
            />
          </v-form>
        </v-row>
      </v-container>
      <LoadingModal :loadingText="config.loadingText" :showModal="config.isSubmitting" />
    </PageLoader>
  </div>
</template>

<script>
import api from '@/common/api';
import PageLoader from '@/common/components/PageLoader.vue';
import { mapGetters } from 'vuex';
import HppFieldRow from '@/merchant/views/hosted-payments/design-components/HppRow.vue';
import { Feature } from '@/features.types';
import { reformatCustomFields, initPaymentMethod } from '@/common/util/payments.util';
import { initCustomer } from '@/common/util/customers.util';
import { TransactionStatus } from '@/common/types/transactions.types';
import {
  initVirtualTerminalTransaction,
  initCustomFields,
} from '@/common/types/virtualTerminal.types';
import {
  fillCustomerInHPP,
  fillPaymentMethodInHPP,
  fillTransactionWithCustomer,
  fillTransactionWithPaymentMethod,
  fillCustomFieldsInHpp,
} from '@/common/util/virtualTerminal.util';
import PaymentDetailsModal from '@/common/components/payments/PaymentDetailsModal.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import axios from 'axios';
import {
  toggleShippingInformation,
} from '@/common/util/hostedPaymentPages.util';
import { AlertType } from '@/common/components/alerts.types';
import Alert from '@/common/components/Alert.vue';
import getDomain from '@/common/domain';
import { isActive } from './paymentPages.util';
import ProductItem from './ProductItem.vue';

export default {
  props: {
    id: { type: String, required: true },
    isPreview: { type: Boolean, required: false },
  },
  data() {
    return {
      AlertType,
      Feature,
      TransactionStatus,
      config: {
        loadingText: 'Processing...',
        customer: initCustomer(),
        customFields: initCustomFields(),
        isSubmitting: false,
        paymentMethod: initPaymentMethod(),
        transaction: initVirtualTerminalTransaction(),
      },
      content: '',
      paymentPage: null,
      hppConfig: [],
      txnResponse: null,
      subscription: null,
      reCaptcha: false,
      showNotActivePage: false,
      showAlert: true,
      showShipingFields: false,
      products: [],
      loadingProducts: false,
    };
  },
  created() {
    document.title = `RiseOS-${(this).brandName}`;
  },
  mounted() {
    this.loadPaymentPageInfo();
    document.documentElement.classList.toggle('tw-dark', false);
  },
  methods: {
    loadPaymentPageInfo() {
      api
        .get(`/payment-pages/${this.id}`, this.skipAuthConfig)
        .then(({ data: { data } }) => {
          this.paymentPage = data;

          if (this.paymentPage.products && this.paymentPage.products.length) {
            this.loadProducts();
            this.paymentPage.isFixedAmount = true;
            this.paymentPage.amount = 0; // this value will be updated with the total of the products
          }

          if (data.name) {
            document.title = `RiseOS-${data.name}`;
          }

          this.hppConfig = JSON.parse(data.jsonData);
          const cols = this.hppConfig[0].columns;
          Object.keys(cols).forEach((key) => {
            const colArr = cols[key];
            if (colArr.name === 'additionalFields') {
              if (colArr.fields.length === 1) {
                this.hppConfig[0].columns[key].fields.splice(0, 1);
              }
            }
          });
          this.loadPaymentPageContent();
          this.$store.commit('setHppTaxRate', this.paymentPage.tax_rate);
        })
        .catch(() => {
          (this).$toasted.error('Problem loading payment page');
          if ((this).$refs.loader) {
            ((this).$refs.loader).setError();
          }
        });
    },
    async loadProducts() {
      if (!this.paymentPage || !this.paymentPage.products) return;

      this.loadingProducts = true;
      const productPromises = this.paymentPage.products.map((productId) => api.get(`/merchant/products/${productId}`));

      try {
        this.products = await Promise.all(productPromises);
        this.products = this.products.map((x) => ({ ...x.data.data, quantity: 0 }));
      } catch (e) {
        this.$toasted.error('There was an error while loading the products. Please refresh the page.');
      } finally {
        this.loadingProducts = false;
      }
    },
    loadPaymentPageContent() {
      if (this.isHppActive || this.isPreview) {
        this.showNotActivePage = false;
        ((this).$refs.loader).setReady();
      } else {
        this.showNotActivePage = true;
      }
    },
    submitTransaction() {
      if (!this.isValid) {
        return;
      }
      this.txnResponse = null;
      this.submitActions(true);

      let txn;

      const hostedPaymentPageId = this.paymentPage.id;

      const hppColumns = this.hppConfig.reduce(
        (columns, row) => columns.concat(row.columns),
        [],
      );
      const customerBillingData = hppColumns.find((columns) => columns.name === 'billingAddress');
      const customerShippingData = hppColumns.find((columns) => columns.name === 'shippingAddress');
      if (customerBillingData && customerShippingData) {
        const customer = fillCustomerInHPP(
          customerBillingData,
          customerShippingData,
          this.config.customer,
        );
        txn = fillTransactionWithCustomer(this.config.transaction, customer);
      }

      const paymentMethodData = hppColumns.find((columns) => columns.name === 'paymentMethod');
      const paymentMethod = fillPaymentMethodInHPP(paymentMethodData, this.config.paymentMethod);

      const customFieldsData = hppColumns.find(
        (columns) => columns.name === 'additionalFields',
      );
      if (customFieldsData) {
        const customFields = fillCustomFieldsInHpp(customFieldsData);
        txn.customFields = reformatCustomFields(customFields);
      }

      txn = fillTransactionWithPaymentMethod(
        txn,
        paymentMethod,
        paymentMethod.type,
        hostedPaymentPageId,
      );

      txn.amount = this.paymentPage.isFixedAmount
        ? this.paymentPage.amount
        : (paymentMethodData).fields[0].attributes.values.amount;
      if (!this.paymentPage.isRecurring) {
        axios
          .post(`${getDomain('api')}/transactions`, txn, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${this.paymentPage.token}`,
            },
          })
          .then(({ data }) => {
            this.submitActions(false);
            this.txnResponse = data;
            (this.$refs.paymentDetailsModal).setVisibility(true);
            this.$toasted.success('Transaction processed!');
          })
          .catch((this).handleTransactionError);
      } else {
        const subscription = {
          name: this.paymentPage.plan.name,
          description: this.paymentPage.plan.description,
          amount: this.paymentPage.plan.amount,
          paymentMethod: txn.paymentMethod,
          customer: txn.customer,
          customFields: txn.customFields,
        };
        axios.post(`${getDomain('api')}/subscriptions`, subscription, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${this.paymentPage.token}`,
          },
        }).then(({ data }) => {
          this.submitActions(false);
          this.txnResponse = { data: data.data.transaction };
          this.subscription = data.data;
          (this.$refs.paymentDetailsModal).setVisibility(true);
          this.$toasted.success('Transaction processed!');
        }).catch((this).handleSubscriptionError);
      }
    },
    submitActions(
      isSubmitting,
      loadingText = 'Processing...',
      resetForms = false,
    ) {
      this.config.isSubmitting = isSubmitting;
      this.config.loadingText = loadingText;
      if (!isSubmitting && resetForms) {
        (this).resetForms();
      }
    },
    resetResponse() {
      if (this.txnResponse.data.status === TransactionStatus.CAPTURED) {
        this.redirect();
      }
      this.txnResponse = null;
    },
    toggleShipping(fieldLabel) {
      this.hppConfig = this.hppConfig.map(toggleShippingInformation);
      this.showShipingFields = fieldLabel === 'Use different shipping information';
    },
    handleTransactionError({ response }) {
      this.submitActions(false);
      this.txnResponse = response;
      (this.$refs.paymentDetailsModal).setVisibility(true);
    },
    handleSubscriptionError({ response }) {
      this.submitActions(false);
      this.txnResponse = response;
      this.$refs.paymentDetailsModal.setVisibility(true);
    },
    redirect() {
      if (this.paymentPage.redirect) {
        if (
          this.paymentPage.redirect.includes('https://')
          || this.paymentPage.redirect.includes('http://')
        ) {
          window.location.replace(this.paymentPage.redirect);
        } else {
          window.location.replace(`https://${this.paymentPage.redirect}`);
        }
      } else {
        this.$router.go(0);
      }
    },
    robotCheck(robot) {
      this.reCaptcha = robot;
    },
  },
  computed: {
    ...mapGetters(['brandName']),
    skipAuthConfig() {
      return { skipAuthentication: true };
    },
    isHppActive() {
      return isActive(this.paymentPage);
    },
    isValid() {
      return this.$refs.form.validate();
    },
    hasCreditCardProcessor() {
      return this.paymentPage.hasCreditCardProcessor;
    },
    hasAchProcessor() {
      return this.paymentPage.hasAchProcessor;
    },
    alertMessage() {
      return 'This Payment Page is not active yet.';
    },
    productsTotal() {
      if (!this.products) return 0;

      return this.products.map((x) => x.quantity * x.cost).reduce((acc, val) => acc + val, 0);
    },
  },
  watch: {
    productsTotal(total) {
      this.paymentPage.amount = total;
    },
  },
  components: {
    PageLoader,
    HppFieldRow,
    PaymentDetailsModal,
    LoadingModal,
    Alert,
    ProductItem,
  },
};
</script>

<style scoped>
.hpp-heading {
  font-weight: 600;
  font-size: 1.125rem;
  text-transform: none;
  line-height: 1.22;
  color: #333;
  margin-block: 1rem 1.75rem;
}

.centerer {
  display: grid;
  place-items: center;
  margin-block: 1rem;
}

.products-total {
  background: #F0F0F0;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.22;
  padding: 0.625rem;
  width: fit-content;
  margin-left: auto;
  margin-block-start: 1rem;
}
</style>
