import env from '@/environmentSetup';
import { Feature } from '@/features.types';
import Permissions from './permissions';

export default [
  {
    route: 'merchant.dashboard',
    icon: () => import('@/components/icons/i-tachometer.vue'),
    text: 'Dashboard',
  },
  {
    route: 'merchant.virtual-terminal',
    icon: () => import('@/components/icons/i-virtual-terminal.vue'),
    text: 'Virtual Terminal',
  },
  {
    route: 'merchant.hosted-payments',
    icon: () => import('@/components/icons/i-hosted-payment.vue'),
    text: 'Hosted Payment Page',
    feature: Feature.HPP,
  },
  {
    route: 'merchant.shopping-carts',
    icon: () => import('@/components/icons/i-cart.vue'),
    text: 'Shopping Carts',
    feature: Feature.SHOPPING_CARTS,
  },
  {
    route: 'merchant.plans.index',
    icon: () => import('@/components/icons/i-plans-calendar.vue'),
    text: 'Plans',
  },
  {
    route: 'merchant.customers.index',
    icon: () => import('@/components/icons/i-customer-vault.vue'),
    text: 'Customer Vault',
    children: [
      {
        route: 'merchant.customers.index',
        text: 'Customer Vault',
      },
      {
        route: 'merchant.customers.batch.uploads.index',
        text: 'Batch Imports',
      },
    ],
  },
  {
    icon: () => import('@/components/icons/i-product-manager.vue'),
    text: 'Product Manager',
    route: 'merchant.products.index',
    feature: Feature.INVOICING,
  },
  {
    icon: () => import('@/components/icons/i-invoices.vue'),
    route: 'merchant.invoices.index',
    feature: Feature.INVOICING,
    text: 'Invoices',
    permission: Permissions.MANAGE_INVOICING,
  },
  {
    route: 'merchant.reports.transactions.details',
    icon: () => import('@/components/icons/i-chart.vue'),
    text: 'Reports',
    permission: Permissions.VIEW_MERCHANT_REPORTS,
  },
  {
    href: env('VUE_APP_API_DOCS_BASE_URL'),
    icon: () => import('@/components/icons/i-api.vue'),
    text: 'API Docs',
  },
];
