













import Vue from 'vue';
import { Frequency } from 'rrule';
import { FrequencyModel } from './rrules.types';

interface FrequencyOption {
  frequency: Frequency;
  display: string;
}

export default Vue.extend({
  props: {
    value: {
      type: Object as () => FrequencyModel,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    frequencies(): FrequencyOption[] {
      return [
        { frequency: Frequency.DAILY, display: 'Daily' },
        { frequency: Frequency.WEEKLY, display: 'Weekly' },
        { frequency: Frequency.MONTHLY, display: 'Monthly' },
        { frequency: Frequency.YEARLY, display: 'Yearly' },
      ];
    },
  },
});
