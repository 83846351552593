











































































































































































































import Vue from 'vue';
import slugify from 'slugify';
import api from '@/common/api';
import MccAutocomplete from '@/common/components/forms/MccAutocomplete.vue';
import PCard from '@/components/presentations/p-card.vue';
import { EmailInfo, MerchantSettings } from '../merchants.types';

export default Vue.extend({
  props: {
    settings: {
      type: Object as () => MerchantSettings,
      required: true,
    },
    emailInfo: {
      type: Object as () => EmailInfo,
      required: true,
    },
  },
  data() {
    return {
      timezones: [] as string[],
    };
  },
  created() {
    api.get('timezones').then(({ data: { data: timezones } }) => {
      this.timezones = timezones;
    });
  },
  methods: {
    sluggifyForEmailUsername(value: string) {
      this.emailInfo.username = slugify(value, {
        lower: true,
        strict: true,
      });
    },
  },
  computed: {
    isLargeViewport(): boolean {
      return this.$vuetify.breakpoint.name === 'xl'
      || this.$vuetify.breakpoint.name === 'lg';
    },
    domainEmail() {
      const { hostname } = window.location;
      const firstDot = hostname.indexOf('.');

      return `@${hostname.substring(firstDot + 1)}`;
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'settings.maxTransactionsInTimeframe.period'() {
      this.settings.maxTransactionsInTimeframe.time = 1;
    },
    // eslint-disable-next-line object-shorthand
    'settings.maxTransactionsPerIp.period'() {
      this.settings.maxTransactionsPerIp.time = 1;
    },
  },
  components: {
    MccAutocomplete,
    PCard,
  },
});
