<template>
  <div class="modal-container" :class="{ 'dark-mode': darkMode }">
  <section>
    <header>
      Do you also want to send a SMS message in addition to the email?

      <button @click="$emit('close')" class="close-button">
        x
      </button>
    </header>

    <main>
      <div class="radios-container">
        <div>
          <input
            v-model="method"
            type="radio"
            id="emailRadio"
            name="sms-radio"
            value="email"
          >
          <label for="emailRadio">EMAIL</label>
        </div>
        <div>
          <input
            v-model="method"
            type="radio"
            id="smsRadio"
            name="sms-radio"
            value="sms"
          >
          <label for="smsRadio">SMS</label>
        </div>
        <div>
          <input
            v-model="method"
            type="radio"
            id="bothRadio"
            name="sms-radio"
            value="both"
          >
          <label for="bothRadio">BOTH</label>
        </div>
      </div>

      <v-text-field
        v-if="method === 'email' || method === 'both'"
        v-model="email"
        label="Add Email"
        outlined
        dense
        :rules="[
          (v) => !!v || 'Email is required.',
          (v) =>
            !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) || 'Email must be valid',
        ]"
      />

      <a-phone-number-form
        v-if="method === 'sms' || method === 'both'"
        v-model="phoneNumber"
        label="Add Phone Number"
        outlined
        dense
      />
    </main>

    <footer>
      <a-button @click="confirm" :disabled="!isValid">
        Yes
      </a-button>
      <a-button @click="$emit('close')" color="gray">
        No
      </a-button>
    </footer>
  </section>
  </div>
</template>

<script>
import AButton from '@/components/atoms/a-button.vue';
import APhoneNumberForm from '@/components/atoms/a-phone-number-form.vue';
import { mapState } from 'vuex';

export default {
  components: { AButton, APhoneNumberForm },
  props: {
    contactEmail: { type: String, required: true },
    contactPhoneNumber: { type: String, required: true },
  },
  data() {
    return {
      method: 'email',
      email: this.contactEmail,
      phoneNumber: this.contactPhoneNumber,
    };
  },
  computed: {
    ...mapState(['darkMode']),
    isValid() {
      const emailIsValid = !!this.email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email);
      const phoneNumberIsValid = !!this.phoneNumber;

      if (this.method === 'email') return emailIsValid;
      if (this.method === 'sms') return phoneNumberIsValid;
      return emailIsValid && phoneNumberIsValid;
    },
  },
  methods: {
    confirm() {
      const data = { method: this.method };

      if (this.method === 'email') {
        data.email = this.email;
      } else if (this.method === 'sms') {
        data.phoneNumber = this.phoneNumber;
      } else {
        data.email = this.email;
        data.phoneNumber = this.phoneNumber;
      }

      this.$emit('confirmation', data);
    },
  },
};
</script>

<style scoped>
section {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  z-index: 100;
}

.modal-container::before {
  content: '';
  z-index: 10;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
}

header,
footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

header {
  font-size: 1.125rem;
  font-weight: 700;
  font-family: 'Montserrat';
  line-height: 1.6;
}

main {
  margin-block: 1.75rem 1.25rem;
}

.radios-container {
  display: flex;
  gap: 4rem;
  margin-block-end: 1.75rem;
}

.radios-container > div {
  display: flex;
  align-items: center;
}

input[type="radio"] {
  width: 1.5rem;
  height: 1.5rem;
  accent-color: var(--primary-color);
}

input[type="radio"] + label {
  padding-inline-start: 0.5rem;
}

footer button {
  flex: 1;
}

.dark-mode section {
  background: var(--background-color);
}
</style>
