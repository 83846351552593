import Permissions from '@/common/permissions';

const adminSettings = [
  {
    title: 'Settings',
    settings: [
      {
        title: 'Users',
        route: 'admin.users.index',
        icon: '$users',
      },
      {
        title: 'API Tokens',
        route: 'settings.api-tokens',
        icon: 'mdi-api',
      },
      {
        title: 'Frequently Asked Questions',
        route: 'admin.faqs',
        icon: 'mdi-frequently-asked-questions',
        permission: Permissions.MANAGE_FAQ,
      },
      {
        title: 'Edit Maintenance Message',
        route: 'admin.settings.maintenance',
        icon: '$clock',
        permission: Permissions.EDIT_MAINTENANCE,
      },
    ],
  },
];

export default adminSettings;
