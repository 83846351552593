import { setEnabledFeatures } from 'vue-feature-flipping';
import env from '@/environmentSetup';
import { Feature, FeatureToggle } from './features.types';

function getFeatureToggles(): FeatureToggle[] {
  const toggles: FeatureToggle[] = [];

  const featureList: string = env('VUE_APP_FEATURE_TOGGLES');
  if (!featureList) {
    return toggles;
  }
  const features = featureList.split(',');
  Object.keys(Feature).forEach((key: string) => {
    const feature = Feature[key as keyof typeof Feature];
    const featureEnabled = features.find((f) => f === feature);
    toggles.push({ feature, enabled: featureEnabled !== undefined });
  });

  return toggles;
}

export default function setFeatureToggles() {
  const featureToggles: FeatureToggle[] = getFeatureToggles();
  const enabledFeatures: string[] = featureToggles
    .filter((ft) => ft.enabled)
    .map((ft) => ft.feature);
  setEnabledFeatures(enabledFeatures);
}
