
























import Vue from 'vue';

import api from '@/common/api';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { cloneDeep } from 'lodash';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import {
  InfoField,
} from '@/common/components/support/support.types';
import { Customer } from '@/common/types/customers.types';
import CustomerForm from './CustomerForm.vue';

export default Vue.extend({
  props: {
    customer: {
      type: Object as () => Customer,
      required: true,
    },
  },
  data() {
    return {
      editedCustomer: null as Customer | null,
      validationErrors: initValidationErrors() as ValidationErrors,
      isSubmitting: false,
      infoFields: [] as InfoField[],
    };
  },
  mounted() {
    this.editedCustomer = cloneDeep(this.customer);
    this.getCustomerInfoFields();
  },
  computed: {
    isValid(): boolean {
      return (this.$refs.customerForm as any).isValid;
    },
  },
  methods: {
    submitActions(isSubmitting: boolean, resetForms: boolean = false) {
      this.isSubmitting = isSubmitting;
      if (!isSubmitting && resetForms) {
        this.resetForms();
      }
    },
    resetForms() {
      this.editedCustomer = cloneDeep(this.customer);
    },
    saveCustomer() {
      if (!this.isValid) {
        return;
      }
      this.submitActions(true);
      api
        .put(`customers/${this.editedCustomer!.id}`, this.editedCustomer)
        .then(this.showSuccess)
        .catch(this.handleError);
    },
    showSuccess() {
      this.submitActions(false, true);
      this.resetForms();
      this.$toasted.success('Customer updated successfully!');
      this.$emit('customer-updated');
    },
    handleError({ response }: { response: any }) {
      this.submitActions(false);
      this.$toasted.error(`There was a problem editing the customer. (${response.status})`);
      this.validationErrors = response.data.errors || {};
    },
    getCustomerInfoFields() {
      api
        .get('/info-fields', {
          params: {
            virtualTerminal: true,
          },
        })
        .then(this.assignCustomerInfoFields);
    },
    assignCustomerInfoFields({ data }: { data: JsonApiArrayResponse<InfoField> }) {
      this.infoFields = data.data!.map((infoField) => ({
        ...infoField,
        value: '',
      }));
    },
  },
  components: {
    CustomerForm,
    LoadingModal,
  },
});
