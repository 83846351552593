<template>
  <div class="card">
    <h3>
      <router-link v-if="typeof title === 'object'" :to="title.link">
        {{ title.text }}
      </router-link>

      <template v-else>
        {{ title }}
      </template>
    </h3>

    <a-table :data="data" />
  </div>
</template>

<script>
import ATable from '../atoms/a-table.vue';

// TODO: Dont forget to add the limit by 10 and see all button
export default {
  components: { ATable },
  props: {
    title: { type: [String, Object], required: true },
    data: { type: Array, required: true },
  },
  computed: {
    headers() {
      if (!this.data || this.data.length === 0) return [];

      return Object.keys(this.data[0]);
    },
  },
};
</script>

<style scoped>
h3 {
 font-size: 1.125rem;
 font-weight: 700;
 margin-block-end: 1.2em;
 text-transform: none;
}
</style>
