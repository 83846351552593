
























import Vue from 'vue';
import PCard from '@/components/presentations/p-card.vue';

export default Vue.extend({
  components: { PCard },
  data() {
    return {
      rates: [4, 8, 3, 3, 3, 2, 3, 3],
    };
  },
});
