<template>
  <div class="d-flex justify-space-between">
    <v-icon v-if="isEditing" small class="mr-2" @click="fieldEditing = !fieldEditing">
      {{ fieldEditing ? 'mdi-content-save' : 'mdi-pencil' }}
    </v-icon>
    <h3
      style="width: 100%; word-wrap: break-word"
      class="mb-0 hpp-heading"
      ref="editable"
      :contenteditable="fieldEditing"
      v-on="listeners"
    />
    <slot name="append-outer"></slot>
  </div>
</template>

<script>
export default {
  name: 'HppHeading',
  props: {
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      fieldEditing: false,
    };
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.innerText);
    },
  },
  computed: {
    listeners() {
      return { ...this.$listeners, input: this.onInput };
    },
  },
  mounted() {
    this.$refs.editable.innerText = this.value;
  },
};
</script>

<style scoped>
.hpp-heading {
  font-weight: 600;
  font-size: 1.125rem;
  text-transform: none;
  line-height: 1.22;
  color: #333;
}
</style>
