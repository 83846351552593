import { DataTimestamps, JsonApiData } from '@/jsonApi.types';
import { RecurrenceFrequency } from '@/common/types/billingRecurrences.types';
import { PaymentMethod } from '@/common/types/payments.types';
import { Customer } from '@/common/types/customers.types';
import {
  CustomField,
  DefaultLevel3Setting,
} from '@/common/components/support/support.types';
import { Transaction } from './transactions.types';

export enum LastRunStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  DECLINED = 'declined'
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  DELINQUENT = 'delinquent'
}

export interface Subscription extends JsonApiData, DataTimestamps {
  customerId?: string;
  planId?: string;
  paymentMethodId?: string;
  name: string;
  description: string;
  recurrenceFrequency: RecurrenceFrequency;
  amount: number;
  recurrenceText: string;
  rruleText: string;
  nextRun?: Date;
  lastRun?: Date;
  lastRunStatus?: LastRunStatus;
  startDate?: Date;
  paymentMethod?: PaymentMethod;
  customer?: Customer;
  paymentsCount?: number;
  status?: SubscriptionStatus;
  transaction?: Transaction;
  installments?: number;
  transactionId?: string;
  addToVault?: boolean;
  externalId?: string;
  customFields: any[];
  enhancedFields?: CustomField[];
  level3Fields?: DefaultLevel3Setting;
  isLevel3Transaction?: boolean;
}
