
























































import Vue from 'vue';

import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { PaymentMethod } from '@/common/types/payments.types';
import { BillingRecurrenceModel } from '@/common/types/billingRecurrences.types';
import { initBillingRecurrenceModel } from '@/common/util/billingRecurrences.util';
import { Customer } from '@/common/types/customers.types';
import { isCustomerAddressEmpty } from '@/common/util/customers.util';
import { Subscription, SubscriptionStatus } from '@/common/types/subscriptions.types';
import EditCustomerCard from '../components/customers/EditCustomerCard.vue';
import PaymentMethodCard from '../components/payments/PaymentMethodCard.vue';
import EditSubscriptionCard from './EditSubscriptionCard.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      SubscriptionStatus,
      subscription: null as Subscription | null,
      customer: null as Customer | null,
      paymentMethod: null as PaymentMethod | null,
      billingRecurrence: initBillingRecurrenceModel() as BillingRecurrenceModel,
    };
  },
  created() {
    document.title = 'RiseOS-Edit Subscription';
  },
  mounted() {
    this.loadSubscription();
  },
  methods: {
    loadSubscription() {
      api
        .get(`/subscriptions/${this.id}`)
        .then(({ data: { data } }) => {
          this.subscription = data;
          (this.$refs.loader as any).setReady();
          this.loadPaymentMethod();
          this.loadCustomer();
        })
        .catch(() => {
          this.$toasted.error('Problem loading subscription');
          (this.$refs.loader as any).setError();
        });
    },
    loadCustomer() {
      if (this.$refs.customerLoader) {
        (this.$refs.customerLoader as any).setLoading();
      }
      api
        .get(
          `/subscriptions/${this.subscription!.id!}/customers/${this.subscription!.customer!.id}`,
        )
        .then(({ data: { data } }) => {
          this.customer = data;
          this.customer!.separateShipping = !isCustomerAddressEmpty(
            this.customer!.shippingAddress,
            false,
          );
          (this.$refs.customerLoader as any).setReady();
        })
        .catch(() => {
          this.$toasted.error('Problem loading customer');
          (this.$refs.customerLoader as any).setError();
        });
    },
    loadPaymentMethod() {
      if (this.$refs.paymentMethodLoader) {
        (this.$refs.paymentMethodLoader as any).setLoading();
      }
      api
        .get(
          `/customers/${this.subscription!.customer!.id}/payment-methods/${
            this.subscription!.paymentMethod!.id
          }`,
        )
        .then(({ data: { data } }) => {
          this.paymentMethod = data;
          (this.$refs.paymentMethodLoader as any).setReady();
        })
        .catch(() => {
          this.$toasted.error('Problem loading payment methods');
          (this.$refs.paymentMethodLoader as any).setError();
        });
    },
    cancelSubscription() {
      if (this.subscription) {
        api
          .delete(`/subscriptions/${this.subscription.id}`)
          .then(({ data: { data: subscription } }) => {
            this.$toasted.success('Success');
            this.subscription = subscription;
            this.$router.push({ name: 'merchant.reports.recurring' });
          })
          .catch(({ response }) => {
            this.$toasted.error(response.data.message);
          });
      }
    },
    onUpdated() {
      this.$router.push({
        name: 'merchant.subscriptions.view',
        params: { id: this.subscription!.id! },
      });
    },
    enableSubscription() {
      if (this.subscription?.status === SubscriptionStatus.CANCELED) {
        api
          .patch(`/subscriptions/${this.subscription?.id}`)
          .then(({ data: { data: subscription } }) => {
            this.$toasted.success('Success');
            this.subscription = subscription;
            this.$router.push({ name: 'merchant.reports.recurring' });
          })
          .catch(({ response }) => {
            this.$toasted.error(response.data.message);
          });
      }
    },
  },
  computed: {
    isSubscriptionActive(): boolean {
      return this.subscription?.status !== SubscriptionStatus.CANCELED;
    },
    mobileView(): boolean {
      return this.$vuetify.breakpoint.name === 'sm'
      || this.$vuetify.breakpoint.name === 'xs';
    },
  },
  components: {
    PageHeader,
    PageLoader,
    EditCustomerCard,
    EditSubscriptionCard,
    PaymentMethodCard,
  },
});
