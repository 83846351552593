


















































































































































































































import Vue from 'vue';
import { AlertType } from '@/common/components/alerts.types';
import Alert from '@/common/components/Alert.vue';
import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import { mapGetters } from 'vuex';
import { Feature } from '@/features.types';
import { ShoppingCart, initShoppingCart } from '@/common/components/support/support.types';
import getSubdomain from '@/common/context';
import { mask } from 'vue-the-mask';
import LoadingModal from '@/common/components/LoadingModal.vue';


export default Vue.extend({
  data() {
    return {
      AlertType,
      Feature,
      showAlert: true as boolean,
      loadingText: 'Creating Shopping Cart...' as string,
      newShoppingCart: initShoppingCart() as ShoppingCart,
      shouldDisable: true as boolean,
      showSuccess: false as boolean,
      isSubmitting: false as boolean,
    };
  },
  created() {
    document.title = 'RiseOS-Create Shopping Cart';
  },
  mounted() {
    this.initValues();
  },
  computed: {
    ...mapGetters(['branding']),
    userRole() {
      return [
        { value: 'persona_1', display: 'FULL-TIME OWNER of an established business (&lt;100% revenue online)' },
        { value: 'persona_2', display: 'FULL-TIME OWNER of an established business (100% revenue online)' },
        { value: 'persona_3', display: 'PART-TIME OWNER of an established business' },
        { value: 'persona_4', display: 'MANAGER OR STAFF MEMBER of an established business' },
        { value: 'persona_7', display: 'VOLUNTEER OR STAFF at a nonprofit organization' },
        { value: 'persona_8', display: 'ENTREPRENEUR starting a new business from scratch' },
        { value: 'persona_9', display: 'Other (Please input below)' },
      ];
    },
    industry() {
      return [
        { value: '32', display: 'Auto / Industrial' },
        { value: '49', display: 'Crafts / Gifts' },
        { value: '24', display: 'Electronics' },
        { value: '25', display: 'Entertainment' },
        { value: '28', display: 'Fashion / Apparel' },
        { value: '30', display: 'Food / Drink' },
        { value: '5', display: 'Health / Beauty' },
        { value: '33', display: 'Home decor / Garden' },
        { value: '35', display: 'Jewelry' },
        { value: '7', display: 'Other' },
        { value: '47', display: 'Sports / Outdoors' },
        { value: '27', display: 'Toys / Kids / Baby' },
      ];
    },
    businessType() {
      return [
        { value: '1', display: 'I sell directly to consumers (B2C).' },
        { value: '2', display: 'I sell to other businesses (B2B).' },
        { value: '3', display: 'I sell to both consumers and businesses.' },
      ];
    },
    alertMessage(): string {
      return `Your online enterprise is now being created! In addition
      to the setup tour in your dashboard, you will receive informative
      emails to assist you during your Free Trial.  If you need assistance,
      please contact ${this.branding.name} at ${this.branding.isoPhone}`;
    },
  },
  methods: {
    initValues() {
      this.newShoppingCart.affiliateLogo = this.branding.logo;
      this.newShoppingCart.affiliateId = this.branding.affiliateId;
      this.newShoppingCart.merchantId = this.branding.merchantId;
      this.newShoppingCart.gateway = '';
      this.newShoppingCart.storeName = getSubdomain();
      this.newShoppingCart.name = `${this.branding.first_name} ${this.branding.last_name}`;
      this.newShoppingCart.email = this.branding.email;
      this.newShoppingCart.phone = this.branding.phone;
      this.newShoppingCart.dbaName = this.branding.company;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    resetForm() {
      this.newShoppingCart = initShoppingCart();
      this.isSubmitting = false;
    },
    save() {
      this.showSuccess = false;
      if (!this.isValid()) {
        return;
      }

      this.isSubmitting = true;

      const nameArr = this.newShoppingCart.name.split(' ');
      let firstName:string = '';
      let lastName:string = '';

      if (this.newShoppingCart.name.split(' ').length > 0) {
        firstName = typeof nameArr[1] !== 'undefined' ? nameArr[0] : '';
        lastName = typeof nameArr[1] !== 'undefined' ? nameArr[1] : '';
      }

      const today = new Date();
      let dd:any = today.getDate();
      let mm:any = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      dd = (dd < 10) ? `0${dd}` : dd;
      mm = (mm < 10) ? `0${mm}` : mm;
      const currentDate = `${yyyy}-${mm}-${dd}`;

      api
        .post('https://app.corecommerce.com/coregateway-trial/', {
          first_name: firstName,
          last_name: lastName,
          dba_name: this.newShoppingCart.dbaName,
          email: this.newShoppingCart.email,
          phone: this.newShoppingCart.phone,
          store_name: this.newShoppingCart.storeName,
          store_category: this.newShoppingCart.storeCategory,
          myterms: currentDate,
          mypassword: this.newShoppingCart.mypassword,
          affiliate_uuiid: this.newShoppingCart.affiliateId,
          affiliate_logo: this.newShoppingCart.affiliateLogo,
          merchant_id: this.newShoppingCart.merchantId,
          gateway_of_choice: this.newShoppingCart.gateway,
        })
        .then(() => {
          this.isSubmitting = false;
          this.showSuccess = true;
          this.$toasted.success('Shopping Cart information saved successfully');
          (this.$refs.form as any).resetValidation();
          this.resetForm();
        })
        .catch(({ response }: { response: any }) => {
          this.isSubmitting = false;
          this.showSuccess = false;
          this.$toasted.error(`There was a problem creating new Shopping Cart. (${response.status})`);
        });
    },
  },
  directives: {
    mask: {
      bind(el, binding, vnode, oldVnode) {
        if (binding.value) {
          mask(el, binding, vnode, oldVnode);
        }
      },
    },
  },
  components: {
    PageHeader,
    Alert,
    LoadingModal,
  },
});
