


















































import Vue from 'vue';
import api from '@/common/api';
import { generatePaymentMethodTableData } from '@/common/util/payments.util';
import { Transaction, TransactionStatus } from '@/common/types/transactions.types';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { PaymentType } from '@/common/types/payments.types';
import {
  PaymentTypeFilterOption,
  StatusFilterOption,
} from '@/common/types/transactionFilters.types';
import dateFormat from 'dateformat';
import { CustomField } from '@/common/components/support/support.types';
import ReportsPagesTabs from '@/merchant/components/reports-pages-tabs.vue';
import TransactionDetails from '../transactions/components/TransactionDetails.vue';

export default Vue.extend({
  data() {
    return {
      selectedTransactionId: '' as string,
      drawer: false,
      generatePaymentMethodTableData,
      EntityFilterType,
      PaymentType,
      TransactionStatus,
      customFieldsLoaded: false as boolean,
      customFields: [] as CustomField[],
      config: {
        pageTitle: 'Transaction Detail Report',
        routerBase: 'merchant.transactions',
        apiPath: 'transactions',
        entityName: 'transaction',
        identifierProperty: 'id',
        reportFileName: 'transaction_details_report',
        filters: [
          {
            name: 'paymentType',
            display: 'Payment Type',
            value: '',
            type: EntityFilterType.SELECT,
            items: [
              { display: 'All', value: '' },
              { display: 'Credit / Debit Card', value: PaymentType.CREDIT },
              { display: 'Electronic Check', value: PaymentType.ACH },
              { display: 'Cash', value: PaymentType.CASH },
            ] as PaymentTypeFilterOption[],
          },
          {
            name: 'status',
            display: 'Status',
            value: '',
            type: EntityFilterType.SELECT,
            items: [
              { display: 'All', value: '' },
              { display: 'Pending', value: TransactionStatus.PENDING },
              { display: 'Captured', value: TransactionStatus.CAPTURED },
              { display: 'Voided', value: TransactionStatus.VOIDED },
              { display: 'Settled', value: TransactionStatus.SETTLED },
              { display: 'Authorized', value: TransactionStatus.AUTHORIZED },
              { display: 'Refunded', value: TransactionStatus.REFUNDED },
              { display: 'Declined', value: TransactionStatus.DECLINED },
            ] as StatusFilterOption[],
          },
          {
            name: 'dateStartCreatedAt',
            display: 'Start Date',
            type: EntityFilterType.DATE,
            value: dateFormat(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              'yyyy-mm-dd',
            ),
            open: false,
          },
          {
            name: 'dateEndCreatedAt',
            display: 'End Date',
            type: EntityFilterType.DATE,
            value: dateFormat(new Date(), 'yyyy-mm-dd'),
            open: false,
          },
          {
            name: 'company',
            display: 'Company',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'zip',
            display: 'Zip',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'firstName',
            display: 'First Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'lastName',
            display: 'Last Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'ccLastFour',
            display: 'CC Last 4',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'minAmount',
            display: '$ Minimum Amount',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'maxAmount',
            display: '$ Maximum Amount',
            value: '',
            type: EntityFilterType.TEXT,
          },
        ],
        headers: [
          {
            text: 'Date',
            value: 'createdAt',
            class: 'entity-table-header subtitle-1 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'ID',
            value: 'id',
            class: 'entity-table-header subtitle-1 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Status',
            value: 'status',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Payment method',
            value: 'paymentMethod',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Customer',
            value: 'customer',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Subscription',
            value: 'subscriptionId',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Amount',
            value: 'amount',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Re-Amount',
            value: 'refundedAmount',
            sortable: false,
            align: ' d-none',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
        ],
        footer: {
          header: 'Totals',
          displayHeaders: ['amount'],
          displayFunction(filters: any, entities: any[], header: string) {
            if (header === 'amount') {
              let totalAmount = 0;
              entities.forEach((item) => {
                if (item.refundedAmount !== 'undefined' && typeof item.refundedAmount !== 'undefined') {
                  totalAmount -= item.refundedAmount;
                } else if (item.status !== 'declined' && item.status !== 'voided') {
                  totalAmount += item.amount;
                }
              });

              const formatted = filters.dollarsAndCents(
                totalAmount,
              );
              return `$${formatted}`;
            }
            return '';
          },
        },
      },
    };
  },
  mounted() {
    this.getSearchableCustomFields();
  },
  methods: {
    viewTransaction(transaction: Transaction) {
      this.selectedTransactionId = transaction.id!;
      this.drawer = !this.drawer;
    },
    getAmount(transaction: Transaction) {
      if (transaction.refundedAmount && transaction.refundedAmount !== 0) {
        return `($${(this.$options.filters as any).dollarsAndCents(transaction.refundedAmount)})`;
      }

      return `$${(this.$options.filters as any).dollarsAndCents(transaction.amount)}`;
    },
    refundClass(transaction: Transaction) {
      return (transaction.refundedAmount && transaction.refundedAmount !== 0) ? 'red--text' : '';
    },
    getSearchableCustomFields() {
      api
        .get('/custom-fields', {
          params: {
            searchable: true,
          },
        })
        .then(({ data }) => {
          this.customFieldsLoaded = true;
          this.customFields = data.data!;

          Object.keys(this.customFields).forEach((key: any) => {
            const cField = this.customFields[key] as CustomField;
            const field = {
              name: `customFields|${key}`,
              display: cField.name,
              value: '',
              type: EntityFilterType.TEXT,
            };
            this.config.filters.push(field);
          });
        })
        .catch(() => {
          this.$toasted.error('Failed to load custom fields data. Try again later!');
        });
    },
  },
  watch: {
    drawer() {
      if (!this.drawer) {
        this.selectedTransactionId = '';
      }
    },
  },
  components: { EntitiesTable, TransactionDetails, ReportsPagesTabs },
});
