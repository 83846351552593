<template>
  <button @click="darkMode = !darkMode" :class="{ dark: darkMode }">
    Dark mode
  </button>
</template>

<script>
const DARK_MODE_KEY = 'darkMode';

export default {
  data() {
    return {
      darkMode: false,
    };
  },
  created() {
    let darkMode = localStorage.getItem(DARK_MODE_KEY);

    if (darkMode === null) {
      darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
      darkMode = darkMode === 'true';
    }

    this.darkMode = darkMode;
  },
  watch: {
    darkMode(value) {
      localStorage.setItem(DARK_MODE_KEY, value);
      this.$store.commit('setDarkMode', value);
      document.documentElement.classList.toggle('tw-dark', value);
    },
  },
};
</script>

<style scoped>
button {
  color: #fff;
  font-weight: 600;
  position: relative;
  padding-left: 2.5rem;
}

button::before,
button::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

button::before {
  width: 2rem;
  height: 1rem;
  border-radius: 10rem;
  border: 1px solid #fff;
  left: 0;
}

button::after {
  width: 0.7rem;
  height: 0.7rem;
  background: #fff;
  border-radius: 50%;
  left: 0.2rem;
  transition: transform 200ms ease-in-out;
}

button.dark::after {
  transform: translate(0.9rem, -50%);
}
</style>
