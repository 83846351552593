

































import Vue from 'vue';
import { initValidationErrors } from '@/common/validation/validation.types';
import { RecurringPayment } from '@/common/types/payments.types';
import {
  DEFAULT_RECURRENCE_AMOUNT,
  DEFAULT_RECURRENCE_RRULE_TEXT,
} from '@/common/util/billingRecurrences.util';
import BillingRecurrence from '../BillingRecurrence.vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => RecurringPayment,
      required: true,
    },
    validationPrefix: {
      type: String,
      required: false,
      default: '',
    },
    errors: {
      type: Object,
      required: false,
      default: () => initValidationErrors(),
    },
    showStartDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    amountToBill: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      rruleText: DEFAULT_RECURRENCE_RRULE_TEXT as string | null | undefined,
      defaultInitialAmount: DEFAULT_RECURRENCE_AMOUNT as number | null | undefined,
    };
  },
  watch: {
    'value.plan': {
      handler() {
        this.rruleText = this.value.plan?.rruleText;
        if (typeof this.value.plan?.amount !== 'undefined') {
          this.defaultInitialAmount = this.value.plan?.amount / 100;
        }
      },
      deep: true,
    },
  },
  computed: {
    defaultAmount(): number {
      return this.amountToBill ?? this.defaultInitialAmount;
    },
  },
  components: {
    BillingRecurrence,
  },
});
