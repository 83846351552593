import { Customer, CustomerAddress } from '@/common/types/customers.types';

export function isCustomerAddressEmpty(
  address: CustomerAddress | null,
  checkFax: boolean = true,
): boolean {
  if (address === undefined || address === null) {
    return true;
  }

  const isFaxEmpty: boolean = checkFax ? address.fax === '' : true;

  return address.company === ''
    && address.firstName === ''
    && address.lastName === ''
    && address.email === ''
    && address.address1 === ''
    && address.address2 === ''
    && address.city === ''
    && address.state === ''
    && address.zip === ''
    && address.country === ''
    && address.phone === ''
    && isFaxEmpty;
}

export function initCustomerAddress(withCountry: boolean = true): CustomerAddress {
  return {
    company: '',
    firstName: '',
    lastName: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: withCountry ? 'US' : '',
    phone: '',
    fax: '',
  };
}

export function initCustomer(inVault: boolean = false): Customer {
  return {
    billingAddress: initCustomerAddress(),
    shippingAddress: initCustomerAddress(false),
    inVault,
    separateShipping: false,
    surchargePercent: 0,
  };
}
