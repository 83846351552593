<template>
  <div class="centerer">
    <h1>Logging out...</h1>
    <a-spinner big />
  </div>
</template>

<script>
import env from '@/environmentSetup';
import auth from '@/common/auth';
import ASpinner from '@/components/atoms/a-spinner.vue';

export default {
  components: { ASpinner },
  computed: {
    fusionAuthLogoutUrl() {
      const domain = env('VUE_APP_FUSION_AUTH_URL');
      const clientId = env('VUE_APP_FUSION_AUTH_CLIENT_ID');
      const tenantId = env('VUE_APP_FUSION_AUTH_TENANT_ID');
      const redirect = `${window.location.origin}/login`;
      const encodedRedirect = encodeURIComponent(redirect);

      return `${domain}/oauth2/logout?client_id=${clientId}&tenantId=${tenantId}&post_logout_redirect_uri=${encodedRedirect}`;
    },
  },
  async created() {
    await this.logout();
    window.location = this.fusionAuthLogoutUrl;
  },
  methods: {
    async logout() {
      try {
        await auth.post('logout');
        await this.$store.dispatch('clearAuthPromise');
      } catch (e) {
        this.$toasted.error('There has been a problem while logging out');
      }
    },
  },
};
</script>

<style scoped>
.centerer {
  display: grid;
  place-items: center;
  margin-block: 10vh;
}

h1 {
  margin-block-end: 2rem;
}
</style>
