enum Permissions {
  RUN_CC_SALE = 'run-credit-card-sale',
  RUN_CC_AUTH = 'run-credit-card-auth',
  RUN_CC_VOID = 'run-credit-card-refund-void',
  RUN_ACH_SALE = 'run-ach-sale',
  RUN_ACH_VOID = 'run-ach-refund-void',
  RUN_CASH_SALE = 'run-cash-sale',
  RUN_CASH_VOID = 'run-cash-refund-void',

  VIEW_MERCHANT_REPORTS = 'view-merchant-reports',
  VIEW_COMMISSION_REPORTS = 'view-commission-reports',

  ADD_USER = 'add-user',
  EDIT_USER = 'edit-user',
  DELETE_USER = 'delete-user',

  RECEIVE_RECEIPTS = 'receive-receipts',
  RECEIVE_DECLINED_RECEIPTS = 'receive-declined-receipts',
  RECEIVE_SETTLEMENT_REPORT = 'receive-settlement-report',

  MANAGE_FAQ = 'manage-faq',
  EDIT_MAINTENANCE = 'edit-maintenance-message',
}

export default Permissions;
