import { ValidationErrors } from '@/common/validation/validation.types';
import { Processor } from '@/common/types/processors.types';
import { PaymentMethod, RecurringPayment } from '@/common/types/payments.types';
import { Customer } from '@/common/types/customers.types';
import { CustomFieldFillable, DefaultLevel3Setting, InfoFieldFillable } from '@/common/components/support/support.types';
import { VirtualTerminalTransaction } from '@/common/types/virtualTerminal.types';


export enum CustomerPaymentAutomation {
  ALLOW_RECURRING,
  AUTOMATED_ONLY,
}

export interface CustomerPaymentConfig {
  processors: Processor[];
  transaction: VirtualTerminalTransaction;
  customer: Customer;
  paymentMethod: PaymentMethod;
  recurrence: RecurringPayment;
  showAddToVault: boolean;
  isRecurring: boolean;
  validationErrors: ValidationErrors;
  isSubmitting: boolean;
  paymentValidationPrefix?: string;
  creditCardValidationPrefix?: string;
  achValidationPrefix?: string;
  customerBillingValidationPrefix?: string;
  customerShippingValidationPrefix?: string;
  infoFields: InfoFieldFillable[];
}

export interface CustomerPaymentConfigWithCustomFields extends CustomerPaymentConfig {
  customFields: CustomFieldFillable[];
  enhancedFields: CustomFieldFillable[];
  defaultLevel3Setting?: DefaultLevel3Setting;
  infoFields: InfoFieldFillable[];
}

export interface VaultCustomerPaymentConfig {
  transaction: VirtualTerminalTransaction;
  customer: Customer;
  paymentMethod: PaymentMethod;
  validationErrors: ValidationErrors;
  isSubmitting: boolean;
  customFields: CustomFieldFillable[];
  processors: Processor[];
  enhancedFields: CustomFieldFillable[];
  defaultLevel3Setting?: DefaultLevel3Setting;
  isRecurring: boolean;
  recurrence: RecurringPayment;
  infoFields: InfoFieldFillable[];
}
