


























































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { PaymentType } from '@/common/types/payments.types';
import { Processor } from '@/common/types/processors.types';
import ProcessorEdit from './ProcessorsEdit.vue';

export default Vue.extend({
  data() {
    return {
      processorToEdit: {} as object,
      loading: true,
      hideFooter: true as boolean,
      merchantProcessors: [],
      headers: [
        {
          text: 'id',
          value: 'id',
          sortable: false,
          align: ' d-none',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Type',
          value: 'supports',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Processor',
          value: 'key',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Settlement Time',
          value: 'settlementTimeUtc',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['processors', 'context']),
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$store
        .dispatch('loadMerchantProcessors', this.context)
        .then(() => {
          this.merchantProcessors = this.processors.filter(
            (item:Processor) => item.key !== PaymentType.CASH,
          );
        })
        .catch(() => {
          this.merchantProcessors = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editProcessor(processor: Processor) {
      this.processorToEdit = processor;
      (this.$refs.editModal as any).show();
    },
    formatAMPM(time:any) {
      const tArray = time.split(':');
      let hours:number = tArray[0];
      const minutes:number = tArray[1];
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours %= 12;
      hours = hours || 12; // the hour '0' should be '12'
      return `${hours}:${minutes} ${ampm}`;
    },
  },
  components: {
    ProcessorEdit,
  },
});

