


















































































































import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import api, { setHeader } from '@/common/api';
import Icon from '@/common/icon';
import DocumentViewer from '@/common/components/DocumentViewer.vue';
import { initValidationErrors } from '@/common/validation/validation.types';
import TextInput from '@/common/components/forms/TextInput.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { toFloat, toInt } from '@/common/mappers/buy-rate-value-mapper';
import IsoContact from '@/common/components/forms/Contact.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import IsoStatusModal from '@/iso/views/components/IsoStatusModal.vue';
import IsoBuyRates from '@/tenants/admin/modules/buy-rates/components/BuyRates.vue';
import { IsoStatus, IsoResponse } from '../types/affiliate';
import IsoDetails from '../components/Details.vue';
import IsoBranding from '../components/Branding.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      previewProposalModal: false as boolean,
      loaded: false as boolean,
      iso: {} as IsoResponse,
      validationErrors: initValidationErrors(),
      memberName: '' as string,
      memberTitle: '' as string,
      memberPhone: '' as string,
      docHtml: '' as string,
      showErrorMsg: false as boolean,
      showIsoStatusModal: false as boolean,
      rules: {
        required: (value: string) => !!value || 'Required.',
      },
    };
  },
  created() {
    document.title = 'RiseOS-Edit Affiliate';
  },
  mounted() {
    this.loadIso();
  },
  computed: {
    ...mapGetters(['currentUserDisplayName']),
    mobileAlignHeader(): boolean {
      return this.$vuetify.breakpoint.name === 'sm'
      || this.$vuetify.breakpoint.name === 'xs';
    },
  },
  methods: {
    loadIso() {
      api.get(`isos/${this.id}`).then(this.setIso).catch(this.handleLoadingError);
    },
    setIso({ data }: { data: JsonApiSingleResponse<IsoResponse> }) {
      const iso: IsoResponse = data.data!;
      this.iso = { ...iso, buyRates: toFloat(iso.buyRates) };
      this.loaded = true;
      (this.$refs.loader as any).setReady();
    },
    handleLoadingError() {
      this.$toasted.error(`Affiliate not found: ${this.id}`);
      this.$router.replace({ name: 'admin.isos.index' });
    },
    updateBrandLogo(logo: string) {
      this.iso.branding.logo = logo;
    },
    updateBrandColor(color: string, value: string) {
      this.iso.branding[color] = value;
    },
    save() {
      if (!this.isValid()) {
        this.showErrorMsg = true;
        return;
      }
      this.showErrorMsg = false;
      api
        .put(`isos/${this.id}`, {
          ...this.iso,
          buyRates: toInt(this.iso.buyRates),
        })
        .then(this.showSuccess)
        .catch(this.handleSaveError);
    },
    previewProposal() {
      this.memberName = this.currentUserDisplayName;
      this.previewProposalModal = true;
    },
    showPreviewProposal() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const params: any = {
        isoId: this.id,
        name: this.memberName,
        title: this.memberTitle,
        phone: this.memberPhone,
      };
      setHeader('Accept', 'text/html');
      api
        .get('/proposals/latest', {
          params,
        })
        .then((response) => {
          this.docHtml = response.data;
          this.previewProposalModal = false;
          (this.$refs.documentViewer as any).show();
        })
        .catch((this.$refs.loader as any).setError);
      setHeader('Accept', 'application/json');
    },
    sendProposal() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      api
        .post(`/isos/${this.iso.id}/proposals`, {
          name: this.memberName,
          title: this.memberTitle,
          phone: this.memberPhone,
        })
        .then(() => {
          (this.$refs.documentViewer as any).hide();
          this.$toasted.success('Proposal was sent successfully');
        })
        .catch((this.$refs.loader as any).setError);
    },
    showSuccess() {
      this.updatedomain();
      this.$toasted.success('Changes saved successfully!');
    },
    handleSaveError({ response }: { response: any }) {
      if (response.status === 422) {
        let msg: any = '';
        if (response?.data?.errors) {
          const errors = response?.data?.errors;
          Object.keys(errors).forEach((key: any, idx: any) => {
            if (idx > 0) msg += '<br />';
            msg += `${errors[key]}`;
          });
        } else if (response?.data?.message) {
          msg += response?.data?.message;
        }

        if (msg) this.$toasted.error(msg);
        else this.$toasted.error(this.$errorMessages.get('affiliates.default_save'));
      } else {
        this.$toasted.error(this.$errorMessages.get('affiliates.default_save'));
      }
      this.validationErrors = response.data.errors || {};
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    toggleStatusModal() {
      this.showIsoStatusModal = !this.showIsoStatusModal;
    },
    closeIsoStatusModal() {
      this.showIsoStatusModal = false;
    },
    saveIsoStatus(status: IsoStatus) {
      this.iso.status = status;
      api
        .patch(`isos/${this.id}/update-status`, { status })
        .then(this.statusUpdateSuccess)
        .catch(this.handleSaveError);
    },
    statusUpdateSuccess() {
      this.$toasted.success('Iso Status Updated!');
      this.toggleStatusModal();
    },
    updatedomain() {
      const splitUrl = this.iso.siteUrl.split('.');
      const splitSubDomain = splitUrl[0].split('//');
      splitSubDomain[1] = this.iso.subdomain;
      const joinparts2 = splitSubDomain.join('//');
      splitUrl[0] = joinparts2;
      const finalDomainUrl = splitUrl.join('.');
      this.iso.siteUrl = finalDomainUrl;
    },
  },
  validations: {
    memberName: {
      required,
    },
    memberTitle: {
      required,
    },
    memberPhone: {
      required,
    },
  },
  components: {
    Icon,
    PageLoader,
    IsoDetails,
    IsoContact,
    IsoBranding,
    IsoBuyRates,
    DocumentViewer,
    TextInput,
    IsoStatusModal,
  },
});
