import { SettingsRow } from '@/common/types/settings.types';

const invoiceSettings: SettingsRow[] = [
  {
    columns: [
      {
        title: '',
        settings: [
          {
            title: 'Logo',
            route: 'merchant.settings.upload-logo',
            icon: 'mdi-image-plus',
          },
          {
            title: 'Reminders',
            route: 'merchant.settings.upload-logo',
            icon: 'mdi-reminder',
          },
          {
            title: 'Reply to Email',
            route: 'merchant.settings.upload-logo',
            icon: 'mdi-email',
          },
        ],
      },
    ],
  },
];

export default invoiceSettings;
