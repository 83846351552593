var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-justify-between tw-items-center tw-h-6"},[_c('p',{class:!_vm.tabletScreenSizeAndBelow ? 'card-title mt-5' : 'mobile-card-title'},[_vm._v(" "+_vm._s(_vm.editedRateGroupName)+" ")]),(_vm.value.feature !== null)?_c('v-checkbox',{attrs:{"label":"Enabled","name":_vm.getCheckBoxName},on:{"change":_vm.handleCheckBoxClick},model:{value:(_vm.value.feature.enabled),callback:function ($$v) {_vm.$set(_vm.value.feature, "enabled", $$v)},expression:"value.feature.enabled"}}):_vm._e()],1),(_vm.value.rates.length > 0)?_c('table',{class:!_vm.tabletScreenSizeAndBelow ? 'mt-5' : ''},[_vm._m(0),_c('tbody',_vm._l((_vm.value.rates),function(rate,k){return _c('tr',{key:k},[(rate.buyRate)?_c('td',[_vm._v(_vm._s(rate.name))]):_vm._e(),(rate.buyRate)?_c('td',{staticClass:"tw-text-right"},[(rate.key !== 'electronic_check_discount_rate' &&
          rate.key !== 'surcharge-percent')?_c('span',[_vm._v("$")]):_vm._e(),_vm._v(" "+_vm._s(rate.buyRate)+" "),(rate.key === 'electronic_check_discount_rate' ||
          rate.key === 'surcharge-percent')?_c('span',[_vm._v("%")]):_vm._e()]):_vm._e(),(rate.buyRate)?_c('td',{staticClass:"input-wrapper tw-w-32"},[_c('v-text-field',{attrs:{"type":"number","min":rate.buyRate,"step":"0.01","prefix":_vm.getPrefix(rate),"suffix":_vm.getSuffix(rate),"rules":[
              (_vm.value.feature && _vm.value.feature.enabled) ?
              (
                function (v) { return (v && parseFloat(v) >= parseFloat(rate.buyRate.toString())) ||
                _vm.failedValidation(rate) || _vm.hasSellRateBeenEnabled(); }
              ) : true
            ]},model:{value:(rate.value),callback:function ($$v) {_vm.$set(rate, "value", $$v)},expression:"rate.value"}})],1):_vm._e()])}),0)]):_vm._e(),_c('Modal',{ref:"toggleFeatureModal",attrs:{"closeOnCurtainClick":false,"showCloseButton":false},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.updateEnabled(false)}}},[_vm._v("No")]),_c('v-btn',{staticClass:"tw-text-red",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.updateEnabled(true)}}},[_vm._v("Yes")])]},proxy:true}])},[[_c('div',{staticClass:"tw-text-center"},[_c('Icon',{attrs:{"icon":"exclamation-circle","size":"5x"}}),(_vm.isShoppingCart(_vm.editedRateGroupName))?_c('h2',{staticClass:"tw-mt-3"},[_vm._v(" Are you sure you want to activate the RiseOS "+_vm._s(_vm.editedRateGroupName)+" for this merchant? ")]):_c('h2',{staticClass:"tw-mt-3"},[_vm._v(" Enable "),_c('strong',[_vm._v(_vm._s(_vm.editedRateGroupName))]),_vm._v("? ")]),(_vm.disableOtherFeatureText)?_c('p',[_c('span',[_vm._v(" Enabling "),_c('strong',[_vm._v(_vm._s(_vm.editedRateGroupName))]),_vm._v(" will disable "+_vm._s(_vm.disabledFeature)+". ")])]):_vm._e(),(!_vm.isShoppingCart(_vm.editedRateGroupName))?_c('p',{staticClass:"tw-font-light"},[_vm._v(" The merchant will be charged. ")]):_vm._e()],1)]],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"main-tr"},[_c('th',[_vm._v("Fee")]),_c('th',{staticClass:"tw-text-right"},[_vm._v("Buy Rate")]),_c('th',{staticClass:"tw-text-right"},[_vm._v("Sell Rate")])])])}]

export { render, staticRenderFns }