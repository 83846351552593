<template>
  <ul>
    <li v-for="item in items" :key="item.name">
      <button v-if="item.clickId" @click="$emit('openModal', item.clickId)" type="button">
        <span class="items__item-name">{{ item.name }}</span>
        <span class="items__item-value">${{ item.value | dollarsAndCents }}</span>
      </button>
      <div v-else>
        <span class="items__item-name">{{ item.name }}</span>
        <span class="items__item-value">${{ item.value | dollarsAndCents }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    subtotal: { required: true },
    cashDiscountName: { type: String },
    cashDiscountPercent: { type: Number },
    cashDiscount: { type: Number },
    discountAmount: { required: true },
    discountPercent: { required: true },
    shippingDollarAmount: { required: true },
    taxAmount: { required: true },
    taxPercent: { required: true },
    initialDiscountState: { type: Boolean, required: true },
    initialTaxState: { type: Boolean, required: true },
    initialShippingState: { type: Boolean, required: true },
    total: { required: true },
  },
  computed: {
    items() {
      const items = [
        { name: 'Subtotal', value: this.subtotal },
        {
          name: this.initialDiscountState ? 'Create Discount' : `Discount (${this.discountPercent}%)`,
          value: this.discountAmount * -1,
          clickId: 'Discount',
        },
        {
          name: this.initialTaxState ? 'Create Tax' : `Tax (${this.taxPercent}%)`,
          value: this.taxAmount,
          clickId: 'Tax',
        },
        {
          name: this.initialShippingState ? 'Create Shipping' : 'Shipping',
          value: this.shippingDollarAmount,
          clickId: 'Shipping',
        },
        { name: 'Total', value: this.total },
      ];

      if (this.cashDiscountName) {
        items.splice(1, 0, {
          name: this.cashDiscountPercent ? `${this.cashDiscountName} (${this.cashDiscountPercent}%)` : this.cashDiscountName,
          value: this.cashDiscount,
        });
      }

      return items;
    },
  },
};
</script>

<style scoped>
ul {
  padding: 0.5rem 3rem 1.75rem !important;
  max-width: 25rem;
  margin-inline-start: auto;
}

li {
  font-size: 0.75rem;
}

li:last-child {
  font-weight: 600;
  text-transform: uppercase;
}

li > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.01em 0.33em;
}

li:nth-child(odd) > * {
  position: relative;
  z-index: 1;
}

li:nth-child(odd) > *::before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  background: var(--bg-color, var(--primary-color));
  opacity: 0.12;
}

button {
  width: 100%;
  color: blue;
}

button:hover {
  text-decoration: underline;
}
</style>
