import { CustomFieldFillable, CustomFieldType } from '../components/support/support.types';
import {
  Ach,
  AchAccountHolderType,
  AchAccountType,
  CardBrand,
  CreditCard,
  CreditMethod,
  PaymentMethod,
  PaymentType,
  PaymentTypeDisplay,
  RecurringPayment,
} from '../types/payments.types';
import { initBillingRecurrenceModel } from './billingRecurrences.util';

export function readablePaymentMethod(type: PaymentType | null | undefined): string {
  if (!type) {
    return '';
  }

  switch (type) {
    case PaymentType.ACH:
      return PaymentTypeDisplay.ACH;
    case PaymentType.CASH:
      return PaymentTypeDisplay.CASH;
    case PaymentType.CREDIT:
      return PaymentTypeDisplay.CREDIT;
    default:
      return '';
  }
}

export function defaultCardBrands(): CardBrand[] {
  return [
    { name: 'Visa', regex: /^4/, icon: ['fab', 'cc-visa'] },
    { name: 'American Express', regex: /^(34|37)/, icon: ['fab', 'cc-amex'] },
    { name: 'Mastercard', regex: /^5[1-5]/, icon: ['fab', 'cc-mastercard'] },
    { name: 'Discover', regex: /^6011/, icon: ['fab', 'cc-discover'] },
  ];
}

export function initCreditCard(method: CreditMethod = CreditMethod.AUTH_AND_CAPTURE): CreditCard {
  return {
    cardNumber: '',
    expMonth: 1,
    expYear: new Date().getFullYear(),
    cvv: '',
    method,
  };
}

export function initAch(): Ach {
  return {
    routingNumber: '',
    accountNumber: '',
    accountHolderType: AchAccountHolderType.PERSONAL,
    accountType: AchAccountType.CHECKING,
    confirmed: false,
  };
}

export function initPaymentMethod(
  type: PaymentType = PaymentType.CREDIT,
  creditCardMethod: CreditMethod = CreditMethod.AUTH_AND_CAPTURE,
): PaymentMethod {
  return {
    type,
    creditCard: initCreditCard(creditCardMethod),
    ach: initAch(),
    nickname: '',
  };
}

export function initRecurringPayment(): RecurringPayment {
  return {
    name: '',
    description: '',
    plan: null,
    billingRecurrence: initBillingRecurrenceModel(),
  };
}

export function generatePaymentMethodTableData(
  filters: any,
  paymentMethod?: PaymentMethod,
): string {
  if (!paymentMethod) {
    return '';
  }
  let paymentType: string = filters.capitalize(paymentMethod.type);
  let formattedMethod: string = '';
  switch (paymentMethod.type) {
    case PaymentType.CREDIT: {
      const creditCard: CreditCard = paymentMethod.creditCard!;
      formattedMethod = `\n${creditCard.cardNumber}`;
      break;
    }
    case PaymentType.ACH: {
      paymentType = filters.allCaps(paymentType);
      const achAccount: Ach = paymentMethod.ach!;
      formattedMethod = `\n${achAccount.accountNumber}`;
      break;
    }
    case PaymentType.CASH:
    default:
      break;
  }
  return `${paymentType}${formattedMethod}`;
}

export function reformatCustomFields(customFields: CustomFieldFillable[]): any[] {
  const processedCustomFields: any[] = [];
  const filledCustomFields = customFields.filter((field) => field.value);
  filledCustomFields.forEach((customField) => {
    if (customField.type === CustomFieldType.TEXT) {
      processedCustomFields.push(customField);
    } else if (customField.type === CustomFieldType.CHECK) {
      if (typeof customField.value === 'boolean') {
        processedCustomFields.push({
          ...customField,
          value: customField.value ? 'yes' : 'no',
        });
      }
    } else if (customField.type === CustomFieldType.RADIO) {
      processedCustomFields.push(customField);
    } else if (customField.type === CustomFieldType.SELECT) {
      if (Array.isArray(customField.value)) {
        processedCustomFields.push({
          ...customField,
          value: (customField.value as string[])
            .map((optionField) => (optionField).replace(';', ''))
            .join(';'),
        });
      }
    }
  });
  return processedCustomFields;
}
