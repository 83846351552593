var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-container',[_c('a-back-link',{attrs:{"to":"merchant.settings.tax-rates"}},[_vm._v(" Back to Tax Rates ")]),_c('p-card',{staticStyle:{"max-width":"54rem"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v(" "+_vm._s(_vm.editMode ? 'Tax Rate Edit Page' : 'Tax Rate Creation Page')+" ")])]},proxy:true}])},[_c('v-form',{staticStyle:{"max-width":"32rem"},on:{"submit":function($event){$event.preventDefault();return _vm.saveTaxRate($event)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-text-field',{attrs:{"label":"Tax Name","rules":[
          function (v) { return !!v || 'Name is required'; },
          function (v) { return v.length <= 30 || 'Name can only go up to 30 characters'; }
        ],"disabled":_vm.loading || _vm.saving,"loading":_vm.loading,"outlined":"","dense":""},model:{value:(_vm.taxRateForm.name),callback:function ($$v) {_vm.$set(_vm.taxRateForm, "name", $$v)},expression:"taxRateForm.name"}}),_c('v-text-field',{directives:[{name:"blur-decimals",rawName:"v-blur-decimals",value:(3),expression:"3"}],staticStyle:{"max-width":"17rem"},attrs:{"label":"Tax Rate %","rules":[
          function (v) { return !!v || 'Tax Rate is required'; },
          function (v) { return v >= 0 || 'Tax Rate cannot be negative'; },
          function (v) { return v <= 100 || 'Tax Rate cannot be greater than 100'; }
        ],"disabled":_vm.loading || _vm.saving,"loading":_vm.loading,"type":"number","step":"0.001","min":"0","max":"99.999","suffix":"%","outlined":"","dense":""},model:{value:(_vm.taxRateForm.taxRate),callback:function ($$v) {_vm.$set(_vm.taxRateForm, "taxRate", _vm._n($$v))},expression:"taxRateForm.taxRate"}}),_c('v-checkbox',{attrs:{"label":"Use as Default","disabled":_vm.loading || _vm.saving,"hide-details":""},model:{value:(_vm.taxRateForm.isDefault),callback:function ($$v) {_vm.$set(_vm.taxRateForm, "isDefault", $$v)},expression:"taxRateForm.isDefault"}}),_c('v-checkbox',{attrs:{"label":"Include in Hosted Payment Pages","disabled":_vm.loading || _vm.saving,"hide-details":""},model:{value:(_vm.taxRateForm.includeInHpp),callback:function ($$v) {_vm.$set(_vm.taxRateForm, "includeInHpp", $$v)},expression:"taxRateForm.includeInHpp"}}),_c('v-checkbox',{attrs:{"label":"Include in Invoices","disabled":_vm.loading || _vm.saving,"hide-details":""},model:{value:(_vm.taxRateForm.includeInInvoices),callback:function ($$v) {_vm.$set(_vm.taxRateForm, "includeInInvoices", $$v)},expression:"taxRateForm.includeInInvoices"}}),_c('v-btn',{staticClass:"mt-5",attrs:{"type":"submit","color":"primary","disabled":!_vm.validForm || _vm.saving || _vm.loading,"loading":_vm.saving}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save-outline")]),_vm._v(" Save ")],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to override your current default Tax Rate? ")]),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.showConfirmDialog = false; _vm.saving = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.finishSaveTaxRate}},[_vm._v(" Yes ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }