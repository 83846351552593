var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"report-page-title"},[_vm._v("Reports")]),_c('reports-tabs'),_c('EntitiesTable',{attrs:{"config":_vm.config},scopedSlots:_vm._u([{key:"item.rateKey",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (_vm.$options.filters.capitalize(item.rateKey.replaceAll('_', ' ')))))+" ")]}},{key:"item.merchantName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("first15Characters")(item.merchantName))+" ")]}},{key:"item.sellRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sellRate)+" ")]}},{key:"item.buyRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.buyRate)+" ")]}},{key:"item.residual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.residual)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }