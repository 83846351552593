




import Vue from 'vue';
import Level3Settings from '@/common/components/support/Level3Settings.vue';

export default Vue.extend({
  components: {
    Level3Settings,
  },
});
