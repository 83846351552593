<template>
  <div>
    <div class="faq-header">
      <h1>Frequently asked Questions</h1>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        :loading="loading"
      />
    </div>

    <v-expansion-panels
      v-if="faqs && faqs.length"
      v-model="open"
      flat
      accordion
      class="faqs-list"
    >
      <faq-item
        v-for="faq in filteredFaqs"
        :key="faq.id"
        :faq="faq"
        :active="faq.id === $route.hash.substring(1)"
      />
    </v-expansion-panels>
    <div v-else class="centerer">
      <ASpinner big />
    </div>

    <div class="faq-footer">
      <div>
        <label for="rowsPerPage">Rows per page: </label>
        <v-select
          v-model="rowsPerPage"
          id="rowsPerPage"
          dense
          hide-details
          :items="[
            { text: '3', value: 3 },
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: 'All', value: -1 },
          ]"
        />
      </div>

      <div>
        {{ meta.from || 1 }}
        -
        {{ meta.to || 1 }}
        of
        {{ meta.total || 1 }}

        <button @click="previousPage">
          <v-icon>mdi-arrow-left</v-icon>
        </button>
        <button @click="nextPage">
          <v-icon>mdi-arrow-right</v-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/common/api';
import ASpinner from '@/components/atoms/a-spinner.vue';
import FaqItem from './components/faq-item.vue';

export default {
  components: { FaqItem, ASpinner },
  data() {
    return {
      faqs: [],
      loading: true,
      search: '',
      open: undefined,
      page: 1,
      rowsPerPage: 10,
      meta: {},
    };
  },
  computed: {
    filteredFaqs() {
      const filteredFaqs = this.faqs.filter((x) => {
        if (!this.search) return true;

        const question = x.question.toLowerCase();
        const answer = x.answer.toLowerCase();
        const search = this.search.toLowerCase();

        return question.includes(search) || answer.includes(search);
      });

      if (this.rowsPerPage === -1) return filteredFaqs;

      const start = (this.page - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return filteredFaqs.slice(start, end);
    },
  },
  watch: {
    open: 'updateRoute',
    search: 'updateRoute',
    page: 'updateRoute',
    rowsPerPage() {
      this.page = 1;
      this.updateRoute();
    },
  },
  async created() {
    await this.fetchFAQs();

    this.open = this.faqs.findIndex((x) => x.id === this.$route.hash.substring(1));
    this.search = this.$route.query.q || '';
    this.page = this.$route.query.page || 1;
    this.rowsPerPage = this.$route.query.rows || 10;
  },
  methods: {
    async fetchFAQs() {
      this.loading = true;

      try {
        const params = {
          page: this.page,
          perPage: this.rowsPerPage,
          q: this.search,
        };

        const { data } = await api('faq', { params });
        this.faqs = data.data;
        this.meta = data.meta;
      } catch (e) {
        this.$toasted.error('There was a problema while loading the FAQs');
      } finally {
        this.loading = false;
      }
    },
    updateRoute() {
      const hash = this.open !== undefined && this.open !== -1 ? this.faqs[this.open].question : '';
      const query = {};
      if (this.search) {
        query.q = this.search;
      }
      if (this.page !== 1) {
        query.page = this.page;
      }
      if (this.rowsPerPage !== 10) {
        query.rows = this.rowsPerPage;
      }

      const routeHash = this.$route.hash ? this.$route.hash.substring(1) : '';
      if (routeHash === hash && JSON.stringify(this.$route.query) === JSON.stringify(query)) return;

      this.$router.replace({ hash, query });

      if (JSON.stringify(this.$route.query) === JSON.stringify(query)) return;
      this.fetchFAQs();
    },
    previousPage() {
      this.page = this.page - 1 <= 1 ? 1 : this.page - 1;
    },
    nextPage() {
      const maxPage = this.meta.last_page || 1;
      this.page = this.page + 1 >= maxPage ? maxPage : this.page + 1;
    },
  },
};
</script>

<style scoped>
.faq-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-block: 1rem 2.5rem;
}

h1 {
  margin: 0;
  font-size: 1.375rem;
  font-weight: 600;
}

.centerer {
  display: grid;
  place-items: center;
  margin-block: 5rem;
}

.faqs-list {
  gap: 1.25rem;
}

.faq-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 2.5rem;
}

.faq-footer > div {
  display: flex;
  align-items: center;
}

.faq-footer > div:first-child {
  gap: 3vw;
}

.faq-footer > div > button {
  background: #E5E8EA;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-inline-start: 0.5rem;
}
</style>
