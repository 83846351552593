<template>
  <v-select
    name="country"
    label="Country"
    v-model="contact.country"
    :items="countries"
    outlined
    item-text="name"
    item-value="alpha2Code"
    dense
    :rules="[isoEditOrMerchant ? (v) => !!v || 'Country is required.' : true]"
    :prefix="isoEditOrMerchant ? '*' : ''"
  />
</template>

<script>
import api from '@/common/api';

export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    isoEditOrMerchant: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      countries: [],
    };
  },
  computed: {
    inputId() {
      return this.id || this.name;
    },
  },
  mounted() {
    api.get('countries').then(({ data: { data: countries } }) => {
      this.countries = countries.filter((country) => ['USA', 'CAN', 'PRI'].includes(country.alpha3Code));
    });
  },
};
</script>
