<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import { Chart, PieController, ArcElement } from 'chart.js';

Chart.register(PieController, ArcElement);

export default {
  props: {
    sales: { type: Object, required: true },
  },
  computed: {
    data() {
      return {
        labels: ['Visa', 'American Express', 'MasterCard', 'Discover', 'ACH', 'Other'],
        datasets: [{
          data: Object.values(this.sales),
          backgroundColor: ['#194070', '#D81F1F', '#D88449', '#7F4A81', '#00C1DF', '#D0D878'],
          borderWidth: 0,
        }],
      };
    },
    options() {
      return {
        plugins: {
          legend: {
            position: 'right',
          },
          tooltip: {
            callbacks: {
              title: () => {},
              label: (ctx) => {
                const money = this.$options.filters.dollarsAndCents(ctx.raw);

                return `${ctx.label}: $${money}`;
              },
            },
          },
        },
      };
    },
  },
  mounted() {
    this.cart = new Chart(this.$refs.canvas, {
      type: 'pie',
      data: this.data,
      options: this.options,
    });
  },
};
</script>
