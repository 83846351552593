

















































import Vue from 'vue';
import { mapState } from 'vuex';
import api from '@/common/api';
import PContainer from '@/components/presentations/p-container.vue';
import PPageTitle from '@/components/presentations/p-page-title.vue';
import Permissions from '@/common/permissions';
import AffiliateNotifications from '@/iso/views/affiliate-notifications/AffiliateNotifications.vue';
import { AffiliateNotification } from '@/common/types/affiliateNotifications.types';
import { initAffiliateNotifications } from '@/common/util/affiliateNotifications.util';
import UserDetailsForm from './UserDetailsForm.vue';
import UserPermissionsForm from './UserPermissionsForm.vue';
import { initUser, User, UserType } from './users.types';
import MerchantLocationPermissions from './MerchantLocationPermissions.vue';
import TwoFactorEnableCard from '../auth/two-factor-enable-card.vue';

export default Vue.extend({
  props: {
    id: { type: String, required: true },
    userType: { type: String, required: true },
  },
  data() {
    return {
      UserType,
      user: initUser() as User,
      validationErrors: {} as any,
      userPermissions: [] as Permissions[],
      loading: true as boolean,
    };
  },
  computed: {
    ...mapState(['currentUser']),
  },
  created() {
    this.loadUser();
  },
  methods: {
    async loadUser() {
      this.loading = true;

      try {
        const { data } = await api.get<{ data: User }>(`users/${this.id}`);
        this.user = data.data;

        if (typeof this.user?.notifications !== 'undefined' && !this.user?.notifications.length) {
          this.user.notifications = initAffiliateNotifications() as AffiliateNotification[];
        }
      } catch (e) {
        this.$toasted.error(`User not found: ${this.id}`);
        this.$router.replace({ name: `${this.userType}.users.index` });
      } finally {
        this.loading = false;
      }
    },
    setPermissions(updatedPermissions: Permissions[]) {
      this.user.permissions = updatedPermissions;
    },
    save() {
      api.put(`users/${this.id}`, this.user).then(this.showSuccess).catch(this.handleSaveError);
    },
    showSuccess() {
      this.$toasted.success('Changes saved successfully!');
    },
    handleSaveError({ response }: { response: any }) {
      const message = this.$errorMessages.get('users.save');
      this.$toasted.error(`${message} (${response.status})`);
      this.validationErrors = response.data.errors || {};
    },
  },
  components: {
    PContainer,
    PPageTitle,
    UserDetailsForm,
    UserPermissionsForm,
    AffiliateNotifications,
    MerchantLocationPermissions,
    TwoFactorEnableCard,
  },
});
