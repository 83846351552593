import { Feature } from '@/features.types';

const merchantSettings = [
  {
    title: 'General',
    settings: [
      {
        title: 'Users',
        route: 'merchant.users.index',
        icon: '$users',
      },
      {
        title: 'Custom Fields',
        route: 'merchant.settings.custom-fields',
        icon: '$window-maximize',
      },
      {
        title: 'Cash Discount',
        route: 'merchant.settings./cash-discount',
        icon: 'mdi-credit-card-outline',
        feature: Feature.NON_CASH_ADJUSTMENT,
      },
      {
        title: 'Level III settings',
        route: 'merchant.settings.level-3-settings',
        icon: '$window-maximize',
        feature: Feature.LEVEL23,
      },
      {
        title: 'Customer Information Fields',
        route: 'merchant.settings.info-fields',
        icon: '$window-maximize',
        feature: Feature.INFO_FIELDS,
      },
      {
        title: 'Receipt Settings',
        route: 'merchant.settings.receipt-settings',
        icon: 'mdi-receipt',
      },
      {
        title: 'Email Settings',
        route: 'merchant.settings.email-settings',
        icon: 'mdi-receipt',
      },
      {
        title: 'Invoice Settings',
        route: 'merchant.settings.invoice-settings',
        icon: 'mdi-receipt',
      },
      {
        title: 'Processor Settlement Time',
        route: 'merchant.settings.processors',
        icon: '$clock',
      },
      {
        title: 'Sales Tax Rates',
        route: 'merchant.settings.tax-rates',
        icon: 'mdi-cash-multiple',
      },
      {
        title: 'Merchant Addresses',
        route: 'merchant.settings.addresses',
        icon: 'mdi-map-marker-outline',
      },
    ],
  },
  {
    title: 'Security',
    settings: [
      {
        title: 'Security Keys',
        route: 'settings.security-keys',
        icon: '$key',
      },
      {
        title: 'API Tokens',
        route: 'settings.api-tokens',
        icon: 'mdi-api',
      },
      {
        title: 'AVS Settings',
        route: 'merchant.settings.avs-settings',
        icon: 'mdi-credit-card-outline',
      },
      {
        title: 'IP Whitelist',
        route: 'merchant.settings.ip-settings',
        icon: 'mdi-credit-card-outline',
      },
      {
        title: 'Transaction IP Blocker',
        route: 'merchant.settings.ip-blocker',
        icon: '$window-maximize',
      },
      {
        title: 'Transaction Limits',
        route: 'merchant.settings.transaction-limits',
        icon: '$window-maximize',
      },
    ],
  },
];

export default merchantSettings;
