<template>
  <div>
    <div class="merchants-header">
      <h1>Merchants Lists</h1>

      <div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          placeholder="Search"
          single-line
          hide-details
          class="searcher"
        />
      </div>
    </div>

    <aurora-merchants :iso="auroraIso" :loading="loading" :search="search" />
    <affiliates-merchants :isos="otherIsos" :loading="loading" :search="search" />
  </div>
</template>

<script>
import api from '@/common/api';
import AuroraMerchants from './AuroraMerchants.vue';
import AffiliatesMerchants from './AffiliatesMerchants.vue';

const AURORA_AFFILIATE = 'Aurora Payments LLC';

export default {
  components: { AuroraMerchants, AffiliatesMerchants },
  data() {
    return {
      search: '',
      isos: [],
      loading: true,
    };
  },
  computed: {
    auroraIso() {
      return this.isos.find((x) => x.name === AURORA_AFFILIATE);
    },
    otherIsos() {
      return this.isos.filter((x) => x.name !== AURORA_AFFILIATE);
    },
  },
  created() {
    document.title = 'RiseOS-Merchants';

    api.get('/isos-names')
      .then(({ data }) => {
        this.isos = data;
      })
      .catch(() => {
        this.$toasted.error('There was an error while loading the sub-affiliates list');
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.merchants-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 1.5rem;
}

.merchants-header > div {
  display: flex;
  align-items: center;
  gap: 2rem;
}

h1 {
  font-size: 1.375rem;
  font-weight: 600;
  margin: 0;
}

.merchants-header .searcher {
  padding-top: 0;
  margin-top: 0;
  width: 20rem;
}
</style>
