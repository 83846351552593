var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card bolded-labels"},[_c('h3',{staticClass:"card-title"},[_vm._v("Affiliate Details")]),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"businessName","label":"Business Name","outlined":"","dense":"","rules":[function (v) { return !!v || 'Business name is required.'; }],"prefix":"*"},model:{value:(_vm.iso.name),callback:function ($$v) {_vm.$set(_vm.iso, "name", $$v)},expression:"iso.name"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"subdomain","label":"Subdomain","outlined":"","dense":"","rules":[
          function (v) { return !!v || 'Subdomain is required.'; },
          function (v) { return v.length <= 63 || 'Subdomain cannot have more than 63 characters'; },
          function (v) { return /^[a-z]/.test(v) || 'Subdomain must start with a letter.'; },
          function (v) { return /^[a-z][a-z0-9]{0,62}/.test(v) || 'Subdomain must only use letters and numbers.'; } ],"prefix":"*","suffix":_vm.domainSuffix},model:{value:(_vm.iso.subdomain),callback:function ($$v) {_vm.$set(_vm.iso, "subdomain", $$v)},expression:"iso.subdomain"}})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"taxId","label":"Tax ID","rules":[
          _vm.edit && _vm.fromPage === 'isoedit' ? function (v) { return !!v || 'Tax ID is required.'; } : true,
          function (v) { return (v !== '' && v.length === 10) || 'Tax ID must be 10 characters.'; },
          function (v) { return (v !== '' && /\d{2}-\d{7}/.test(v)) || 'Tax ID format is not correct.'; }
        ],"prefix":_vm.edit && _vm.fromPage === 'isoedit' ? '*' : '',"outlined":"","dense":""},model:{value:(_vm.isoTaxId),callback:function ($$v) {_vm.isoTaxId=$$v},expression:"isoTaxId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"name":"category","items":_vm.affiliateCategories,"item-text":"display","item-value":"value","outlined":"","dense":"","label":"Affiliate Category"},model:{value:(_vm.iso.category),callback:function ($$v) {_vm.$set(_vm.iso, "category", $$v)},expression:"iso.category"}})],1)],1),_c('AchPaymentMethod',{attrs:{"name":"banking-details","suffix":"edit","isoEditOrMerchant":_vm.edit,"getPage":_vm.fromPage},model:{value:(_vm.iso),callback:function ($$v) {_vm.iso=$$v},expression:"iso"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }