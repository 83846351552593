<template>
  <p-container>
    <p-header-1>Dashboard</p-header-1>

    <m-loader-container :loading="loading" :error="error">
      <!-- Why is there an error if I don't use the v-if below -->
      <div v-if="data.hasOwnProperty('affiliates')" class="dashboard-content">
        <div class="dashboard-subheader">
          <h2>Activity</h2>

          <a-period-selector v-model="activityPeriod" />
        </div>

        <ul class="insights">
          <a-insight v-for="insight of insights" :key="insight.title" :insight="insight" />
        </ul>

        <m-card-table title="Top Merchants By Residual" :data="merchantsByResidual" />
        <m-card-table title="Top Merchants By Volume" :data="merchantsByVolume" />

        <statuses-grid
          title="Merchants"
          :active="periodData.merchants.active"
          :restricted="periodData.merchants.restricted"
          :pending="periodData.merchants.pending"
          :closed="periodData.merchants.closed"
        />
        <statuses-grid
          title="Affiliates"
          :active="periodData.affiliates.active"
          :restricted="periodData.affiliates.restricted"
          :pending="periodData.affiliates.pending"
          :closed="periodData.affiliates.closed"
        />
      </div>

      <template #error>
        There was a problem loading the API data. Please refresh the page and try again.
      </template>
    </m-loader-container>
  </p-container>
</template>

<script>
import api from '@/common/api';
import AInsight from '@/components/atoms/a-insight.vue';
import MCardTable from '@/components/molecules/m-card-table.vue';
import APeriodSelector from '@/components/atoms/a-period-selector.vue';
import PContainer from '@/components/presentations/p-container.vue';
import PHeader1 from '@/components/presentations/p-header-1.vue';
import MLoaderContainer from '@/components/molecules/m-loader-container.vue';
import StatusesGrid from '../components/dashboard/statuses-grid.vue';

export default {
  components: {
    PContainer,
    PHeader1,
    AInsight,
    APeriodSelector,
    MCardTable,
    MLoaderContainer,
    StatusesGrid,
  },
  data() {
    return {
      activityPeriod: 'thisMonth',
      data: {},
      loading: true,
      error: false,
    };
  },
  mounted() {
    api.get('/dashboard')
      .then(({ data }) => { this.data = data; })
      .catch(() => { this.error = true; })
      .finally(() => { this.loading = false; });
  },
  computed: {
    periodData() {
      return this.data[this.activityPeriod];
    },
    insights() {
      return [
        { icon: 'bill', title: 'Transaction Volume', value: this.periodData?.volume },
        { icon: 'wallet', title: '#Total Transactions', value: this.periodData?.transactions },
        { icon: 'bill', title: 'Residual', value: this.periodData?.commission },
        { icon: 'wallet', title: 'Total Active Affiliates', value: this.periodData?.activatedAffiliates },
        { icon: 'store', title: 'New Affiliates', value: this.periodData?.newActiveAffiliate },
        { icon: 'basket', title: 'Total Shopping Carts', value: this.periodData?.totalShoppingCarts },
      ];
    },
    merchantsByResidual() {
      return this.periodData.Top10MerchantsRevenue.map((x) => ({
        name: x.merchant_name,
        revenue: `$${this.$options.filters.dollarsAndCents(x.residual)}`,
      }));
    },
    merchantsByVolume() {
      return this.periodData.top10MerchantsVolume.map((x) => ({
        name: x.merchant,
        revenue: `$${this.$options.filters.dollarsAndCents(x.volume)}`,
      }));
    },
  },
};
</script>

<style scoped>
>>> .dashboard-content {
  --card-background: #fff;
  --card-text-color: #505050;
  --card-border-color: #d1d9e2;

  display: grid;
  gap: min(1.5vmax, 1.25rem);
}

.dashboard-subheader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.dashboard-subheader h2 {
  font-size: 1.625rem;
  font-weight: 500;
  margin: 0;
}

.insights {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: min(1.5vmax, 1.25rem);
}

.dashboard-subheader >>> .v-input__control > div {
  background: var(--card-background) !important;
  border: 1px solid var(--card-border-color) !important;
  border-radius: 0.5rem;
}

.dashboard-subheader >>> .v-input__control > div::before {
  content: none;
}

@media only screen and (min-width: 769px) {
  .dashboard-content {
    grid-template-columns: repeat(2, 1fr);
  }

  .insights,
  .dashboard-subheader {
    grid-column: span 2;
  }
}
</style>
