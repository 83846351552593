

















import Vue from 'vue';

export default Vue.extend({
  props: {
    subHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    tag: {
      type: String,
      required: false,
      default: 'h1',
    },
  },
  computed: {
    headerClass(): string {
      return this.subHeader ? 'sub-header' : 'page-header';
    },
  },
});
