
































import Vue from 'vue';
import { IsoStatus } from '@/tenants/admin/modules/affiliates/types/affiliate';

export default Vue.extend({
  props: {
    currentStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false as boolean,
      isoStatusModal: {},
      currentIsoStatus: '',
      status: IsoStatus.ACTIVATED,
      statusValues: [
        {
          status: IsoStatus.ACTIVATED,
          label: 'ACTIVATED',
          text:
            'The Affiliate can create merchants, receive commissions and perform all Payment Gateway functions normally.',
        },
        {
          status: IsoStatus.HOLD,
          label: 'ON HOLD',
          text:
            'The Affiliate will not be paid any commissions.',
        },
      ],
    };
  },
  methods: {
    setVisibility(show: boolean) {
      this.show = show;
    },
    closeIsoStatusModal() {
      this.$emit('closeIsoStatusModal');
    },
    saveIsoStatus() {
      if (this.currentStatus !== this.status) {
        this.$emit('saveIsoStatus', this.status);
      }
    },
  },
  mounted() {
    this.status = this.currentStatus as IsoStatus;
    this.currentIsoStatus = this.status;
  },
});
