const TRANSACTION_REPORT_TABLE_HEADERS = [
  {
    text: 'Date',
    value: 'createdAt',
    class: 'entity-table-header subtitle-1 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'ID',
    value: 'id',
    class: 'entity-table-header subtitle-1 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'Merchant',
    value: 'merchant_name',
    class: 'entity-table-header subtitle-1 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'Status',
    value: 'status',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'Payment method',
    value: 'paymentMethod',
    sortable: false,
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'Customer',
    value: 'customer',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'Subscription',
    value: 'subscriptionId',
    sortable: false,
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'Amount',
    value: 'amount',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'Re-Amount',
    value: 'refundedAmount',
    sortable: false,
    align: ' d-none',
  },
  {
    text: 'Merchant Subdomain',
    value: 'merchant_subdomain',
    sortable: false,
    align: ' d-none',
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
    align: 'center',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '8rem',
  },
];

export default TRANSACTION_REPORT_TABLE_HEADERS;
