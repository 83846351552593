<template>
  <p-card>
    <template #header>
      <h2>Locations</h2>

      <v-switch
        v-model="allLocations"
        label="Check all"
        :loading="loading"
        :disabled="loading"
        hide-details
        class="mt-0"
      />
    </template>


    <div class="location-checkboxes">
      <v-checkbox
        v-for="location in locations"
        :key="location.id"
        v-model="theValue"
        :value="location.id"
        :label="location.name"
        :disabled="loading"
        hide-details
      />
    </div>
  </p-card>
</template>

<script>
import api from '@/common/api';
import PCard from '@/components/presentations/p-card.vue';

export default {
  components: { PCard },
  props: {
    value: { type: Array, required: true },
  },
  data() {
    return {
      loading: true,
      locations: [],
      allLocations: false,
    };
  },
  computed: {
    theValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    allLocations(value) {
      if (value) {
        this.$emit('input', this.locations.map((x) => x.id));
      } else {
        this.$emit('input', []);
      }
    },
    value(value) {
      if (value.length === this.locations.length) {
        this.allLocations = true;
      } else if (value.length === 0) {
        this.allLocations = false;
      }
    },
  },
  created() {
    this.fetchAllLocations();
  },
  methods: {
    async fetchAllLocations() {
      try {
        const { data } = await api.get('/merchant/locations');
        this.locations = data.data;
      } catch (e) {
        this.$toasted.error('There was an error while loading the locations');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
