






















import Vue from 'vue';
import api, { setHeader } from '@/common/api';
import { IsoStatus } from '@/tenants/admin/modules/affiliates/types/affiliate';
import Spinner from '@/common/components/LoadingSpinner.vue';
import DocumentViewer from '@/common/components/DocumentViewer.vue';
import { DocumentName } from '@/common/components/support/support.types';
import StatusBar from './onboarding/StatusBar.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      IsoStatus,
      docHtml: '' as string,
      loading: true as boolean,
    };
  },
  created() {
    document.title = 'RiseOS-Sign Proposal';
  },
  mounted() {
    this.loadProposal();
  },
  methods: {
    loadProposal() {
      setHeader('Accept', 'text/html');
      api
        .get(`/proposals/${this.id}`)
        .then((response) => {
          this.docHtml = response.data;
          this.loading = false;
        })
        .catch(this.handleLoadingError);
      setHeader('Accept', 'application/json');
    },
    confirmProposal() {
      api
        .patch(`/proposals/${this.id}`)
        .then(() => {
          this.uploadFile('/docs/agreement.pdf', DocumentName.AGREEMENT);
          this.uploadFile('/docs/achauthorization.pdf', DocumentName.ACH);
          this.$router.push('/onboarding/setup');
        })
        .catch(this.showError);
    },
    uploadFile(filepath: string, filename: string) {
      const oReq = new XMLHttpRequest();
      oReq.open('GET', filepath, true);
      oReq.responseType = 'blob';

      oReq.onload = () => {
        const file = oReq.response;
        const upload = new FormData();
        upload.append('name', filename);
        upload.append('document', file);

        api.post('documents', upload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      };
      oReq.send();
    },
    showError({ response }: { response: any }) {
      const message = this.$errorMessages.get('affiliates.agreeing_proposal');
      this.$toasted.error(`${message} (${response.status})`);
    },
    handleLoadingError() {
      const message = this.$errorMessages.get('affiliates.proposal_load');
      this.$toasted.error(`${message}`);
    },
  },
  components: {
    DocumentViewer,
    Spinner,
    StatusBar,
  },
});
