













import Vue from 'vue';
import { Month } from '@/common/types/dateTime.types';
import { MonthRecurrenceModel } from '@/common/types/billingRecurrences.types';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => MonthRecurrenceModel,
      required: true,
    },
  },
  data() {
    return {
      Month,
    };
  },
  computed: {
    months(): any[] {
      return [
        {
          value: Month.January,
          display: Month[Month.January],
        },
        {
          value: Month.February,
          display: Month[Month.February],
        },
        {
          value: Month.March,
          display: Month[Month.March],
        },
        {
          value: Month.April,
          display: Month[Month.April],
        },
        {
          value: Month.May,
          display: Month[Month.May],
        },
        {
          value: Month.June,
          display: Month[Month.June],
        },
        {
          value: Month.July,
          display: Month[Month.July],
        },
        {
          value: Month.August,
          display: Month[Month.August],
        },
        {
          value: Month.September,
          display: Month[Month.September],
        },
        {
          value: Month.October,
          display: Month[Month.October],
        },
        {
          value: Month.November,
          display: Month[Month.November],
        },
        {
          value: Month.December,
          display: Month[Month.December],
        },
      ];
    },
  },
});
