




import Vue from 'vue';
import IpSettings from '@/common/components/support/IpSettings.vue';

export default Vue.extend({
  components: {
    IpSettings,
  },
});
