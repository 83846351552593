<template>
  <p-container>
    <a-maintenance-alert />
    <p-header-1>Dashboard</p-header-1>

    <m-loader-container :loading="loading" :error="error">
      <div v-if="data !== null" class="dashboard-page-content">
        <a-period-selector class="iso-period-selector" v-model="activityPeriod" no-all-time/>

        <ul>
          <a-insight v-for="insight of insights" :key="insight.title" :insight="insight" />
        </ul>

        <div class="content">
          <div>
            <div class="card">
              <a-volume-line-chart
                :previous="periodData.previous"
                :current="periodData.current"
                :title="activityPeriod === 'thisYear' ? 'Monthly Sales' : 'Daily Sales'"
                :dateFormat="dateFormat"
              />
            </div>

            <statuses-grid
              title="Merchants"
              :active="periodData.merchants.active"
              :restricted="periodData.merchants.restricted"
              :pending="periodData.merchants.pending"
              :closed="periodData.merchants.closed"
            />
          </div>

          <div>
            <m-card-table
              title="Top Merchants by Residual"
              :data="merchantsByResidual"
            />
            <m-card-table
              title="Top Merchants by Volume"
              :data="merchantsByVolume"
            />
          </div>
        </div>
      </div>

      <template #error>
        There was a problem loading the dashboard data. Please refresh the page and try again.
      </template>
    </m-loader-container>
  </p-container>
</template>

<script>
import api from '@/common/api';
import Permissions from '@/common/permissions';
import { mapGetters } from 'vuex';
import PContainer from '@/components/presentations/p-container.vue';
import PHeader1 from '@/components/presentations/p-header-1.vue';
import MLoaderContainer from '@/components/molecules/m-loader-container.vue';
import MCardTable from '@/components/molecules/m-card-table.vue';
import AVolumeLineChart from '@/components/atoms/a-volume-line-chart.vue';
import AInsight from '@/components/atoms/a-insight.vue';
import APeriodSelector from '@/components/atoms/a-period-selector.vue';
import StatusesGrid from '@/admin/components/dashboard/statuses-grid.vue';
import AMaintenanceAlert from '@/components/atoms/a-maintenance-alert.vue';

export default {
  title: 'Dashboard',
  components: {
    PContainer,
    PHeader1,
    MLoaderContainer,
    MCardTable,
    AVolumeLineChart,
    AInsight,
    APeriodSelector,
    StatusesGrid,
    AMaintenanceAlert,
  },
  data() {
    return {
      activityPeriod: 'thisMonth',
      data: null,
      loading: true,
      error: false,
    };
  },
  computed: {
    ...mapGetters(['currentUserCan']),
    dateFormat() {
      return this.activityPeriod === 'thisYear' ? 'mmm' : 'dS';
    },
    periodData() {
      return this.data[this.activityPeriod];
    },
    currentUserCanViewCommissionReport() {
      return this.currentUserCan(Permissions.VIEW_COMMISSION_REPORTS);
    },
    insights() {
      const insights = [
        { icon: 'wallet', value: this.periodData.transactions, title: 'Transactions' },
        { icon: 'bill', value: this.periodData.volume, title: 'Volume' },
        { icon: 'basket', value: this.periodData.totalShoppingCarts, title: 'Total Shopping Carts' },
      ];

      if (this.currentUserCanViewCommissionReport) {
        const insight = { icon: 'bill', value: this.periodData.residual, title: 'Residual' };
        insights.splice(2, 0, insight);
      }

      return insights;
    },
    merchantsByResidual() {
      return this.periodData.residualTop10.map((x) => ({
        merchant: x.merchant,
        residual: `$${this.$options.filters.dollarsAndCents(x.residual)}`,
      }));
    },
    merchantsByVolume() {
      return this.periodData.volumeTop10.map((x) => ({
        merchant: x.merchant,
        volume: `$${this.$options.filters.dollarsAndCents(x.volume)}`,
      }));
    },
  },
  mounted() {
    api
      .get('/dashboard')
      .then(({ data }) => { this.data = data; })
      .catch(() => { this.error = true; })
      .finally(() => { this.loading = false; });
  },
};
</script>

<style scoped>
>>> .dashboard-page-content {
  --spacing: min(1.5vmax, 1.25rem);
}

ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: var(--spacing);
  margin-block: var(--spacing);
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing);
}

.content > div {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.content > div:first-child {
  flex: 4;
}

.content > div:last-child {
  flex: 3;
}

.iso-period-selector {
  margin-inline: auto;
}
</style>
