import Vue, { VueConstructor } from 'vue';

class ErrorMessagesImpl {
  data: any;

  constructor(data: any) {
    this.data = data;
  }

  get(key: string): string {
    const reduce = (k: string, opt: any) => k.split('.').reduce((o: any, current: string) => {
      if (o) {
        return o[current];
      }

      return current;
    }, opt);

    let message : string = reduce(key, this.data);

    const REF_REGEX : RegExp = /^\{(.+)\}$/g;
    const matches = REF_REGEX.exec(message);
    if (matches) {
      message = reduce(matches[1], this.data);
    }

    return message;
  }
}

// eslint-disable-line no-param-reassign
export default function ErrorMessages(app: VueConstructor<Vue>, options?: any) { // eslint-disable-line no-param-reassign
  app.prototype.$errorMessages = new ErrorMessagesImpl(options!.data);// eslint-disable-line no-param-reassign
}
