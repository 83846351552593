import { CustomFieldType, CustomFieldFillable } from '../components/support/support.types';

import {
  CreditMethod, PaymentMethod, PaymentType, VaultPaymentMethod,
} from '../types/payments.types';
import { Customer } from '../types/customers.types';

import { VirtualTerminalTransaction } from '../types/virtualTerminal.types';
import { HppColumn } from './hostedPaymentPages.util';

export function fillTransactionWithCustomer(
  transaction: VirtualTerminalTransaction,
  customer: Customer,
): VirtualTerminalTransaction {
  const txn = { ...transaction };
  txn.customer.billingAddress.company = customer.billingAddress.company;
  txn.customer.billingAddress.firstName = customer.billingAddress.firstName;
  txn.customer.billingAddress.lastName = customer.billingAddress.lastName;
  txn.customer.billingAddress.email = customer.billingAddress.email;
  txn.customer.billingAddress.address1 = customer.billingAddress.address1;
  txn.customer.billingAddress.address2 = customer.billingAddress.address2;
  txn.customer.billingAddress.city = customer.billingAddress.city;
  txn.customer.billingAddress.state = customer.billingAddress.state;
  txn.customer.billingAddress.zip = customer.billingAddress.zip;
  txn.customer.billingAddress.country = customer.billingAddress.country;
  txn.customer.billingAddress.phone = customer.billingAddress.phone;
  txn.customer.billingAddress.fax = customer.billingAddress.fax;

  txn.customer.shippingAddress.company = customer.shippingAddress.company;
  txn.customer.shippingAddress.firstName = customer.shippingAddress.firstName;
  txn.customer.shippingAddress.lastName = customer.shippingAddress.lastName;
  txn.customer.shippingAddress.email = customer.shippingAddress.email;
  txn.customer.shippingAddress.address1 = customer.shippingAddress.address1;
  txn.customer.shippingAddress.address2 = customer.shippingAddress.address2;
  txn.customer.shippingAddress.city = customer.shippingAddress.city;
  txn.customer.shippingAddress.state = customer.shippingAddress.state;
  txn.customer.shippingAddress.zip = customer.shippingAddress.zip;
  txn.customer.shippingAddress.country = customer.shippingAddress.country;
  txn.customer.shippingAddress.phone = customer.shippingAddress.phone;
  txn.customer.shippingAddress.fax = '';
  txn.customer.inVault = customer.inVault;
  return txn;
}

function getFieldValueByName(
  hppData: HppColumn,
  fieldName: string,
): any {
  const filteredList = hppData.fields.filter((field: any) => field.name === fieldName);
  if (filteredList.length > 0) {
    return filteredList[0].value || '';
  }
  return '';
}

export function fillCustomerInHPP(
  billingInfo: HppColumn,
  shippingInfo: HppColumn,
  existingCustomer: Customer,
): Customer {
  const customer = existingCustomer;

  customer.billingAddress.company = getFieldValueByName(billingInfo, 'company');
  customer.billingAddress.firstName = getFieldValueByName(billingInfo, 'firstName');
  customer.billingAddress.lastName = getFieldValueByName(billingInfo, 'lastName');
  customer.billingAddress.email = getFieldValueByName(billingInfo, 'email');
  customer.billingAddress.address1 = getFieldValueByName(billingInfo, 'address1');
  customer.billingAddress.address2 = getFieldValueByName(billingInfo, 'address2');
  customer.billingAddress.city = getFieldValueByName(billingInfo, 'city');
  customer.billingAddress.state = getFieldValueByName(billingInfo, 'state');
  customer.billingAddress.zip = getFieldValueByName(billingInfo, 'zip');
  customer.billingAddress.country = getFieldValueByName(billingInfo, 'country');
  customer.billingAddress.phone = getFieldValueByName(billingInfo, 'phone');
  customer.billingAddress.fax = getFieldValueByName(billingInfo, 'fax');

  customer.shippingAddress.company = getFieldValueByName(shippingInfo, 'company');
  customer.shippingAddress.firstName = getFieldValueByName(shippingInfo, 'firstName');
  customer.shippingAddress.lastName = getFieldValueByName(shippingInfo, 'lastName');
  customer.shippingAddress.email = getFieldValueByName(shippingInfo, 'email');
  customer.shippingAddress.address1 = getFieldValueByName(shippingInfo, 'address1');
  customer.shippingAddress.address2 = getFieldValueByName(shippingInfo, 'address2');
  customer.shippingAddress.city = getFieldValueByName(shippingInfo, 'city');
  customer.shippingAddress.state = getFieldValueByName(shippingInfo, 'state');
  customer.shippingAddress.zip = getFieldValueByName(shippingInfo, 'zip');
  customer.shippingAddress.country = getFieldValueByName(shippingInfo, 'country');
  customer.shippingAddress.phone = getFieldValueByName(shippingInfo, 'phone');
  customer.shippingAddress.fax = getFieldValueByName(shippingInfo, 'fax');

  return customer;
}

export function fillPaymentMethodInHPP(
  hppData: any,
  existingPaymentMethod: PaymentMethod,
): PaymentMethod {
  const paymentMethod = existingPaymentMethod;

  const paymentMethodValues = hppData.fields[0].attributes.values;
  paymentMethod.type = paymentMethodValues.type;

  if (paymentMethod.type === PaymentType.CREDIT) {
    paymentMethod.creditCard!.method = CreditMethod.AUTH_AND_CAPTURE;
    paymentMethod.creditCard!.cardNumber = paymentMethodValues.creditCard!.cardNumber;
    paymentMethod.creditCard!.expMonth = paymentMethodValues.creditCard!.expMonth;
    paymentMethod.creditCard!.expYear = paymentMethodValues.creditCard!.expYear;
    paymentMethod.creditCard!.cvv = paymentMethodValues.creditCard!.cvv ?? '';
  } else if (paymentMethod.type === PaymentType.ACH) {
    paymentMethod.ach!.routingNumber = paymentMethodValues.ach!.routingNumber;
    paymentMethod.ach!.accountNumber = paymentMethodValues.ach!.accountNumber;
    paymentMethod.ach!.accountHolderType = paymentMethodValues.ach!.accountHolderType;
    paymentMethod.ach!.accountType = paymentMethodValues.ach!.accountType;
  }
  return paymentMethod;
}

function getCustomFieldType(field: any): CustomFieldType {
  if (field.type === 'VTextField') {
    return CustomFieldType.TEXT;
  }
  if (field.type === 'VCheckbox') {
    return CustomFieldType.CHECK;
  }
  if (field.display === CustomFieldType.RADIO) {
    return CustomFieldType.RADIO;
  }
  return CustomFieldType.SELECT;
}

export function fillCustomFieldsInHpp(
  hppData: HppColumn,
): CustomFieldFillable[] {
  return hppData.fields.map((field) => ({
    id: field.id,
    name: field.name,
    type: getCustomFieldType(field),
    display: field.type,
    options: [],
    inVirtualTerminal: false,
    inHPP: true,
    inInvoices: false,
    required: field.required,
    value: field.value,
  }));
}

export function fillTransactionWithPaymentMethod(
  transaction: VirtualTerminalTransaction,
  paymentMethod: PaymentMethod,
  paymentType: PaymentType,
  hostedPaymentPageId: string = '',
): VirtualTerminalTransaction {
  const txn = { ...transaction };
  const txnPaymentMethod: PaymentMethod = txn.paymentMethod as PaymentMethod;
  txnPaymentMethod.type = paymentMethod.type;
  if (hostedPaymentPageId) {
    txnPaymentMethod.hostedPaymentPageId = hostedPaymentPageId;
  } else {
    txnPaymentMethod.processorId = paymentMethod.processorId ?? '';
  }
  if (paymentType === PaymentType.CREDIT) {
    txnPaymentMethod.creditCard!.method = paymentMethod.creditCard!.method;
    txnPaymentMethod.creditCard!.cardNumber = paymentMethod.creditCard!.cardNumber;
    txnPaymentMethod.creditCard!.expMonth = paymentMethod.creditCard!.expMonth;
    txnPaymentMethod.creditCard!.expYear = paymentMethod.creditCard!.expYear;
    txnPaymentMethod.creditCard!.cvv = paymentMethod.creditCard!.cvv ?? '';
  } else if (paymentType === PaymentType.ACH) {
    txnPaymentMethod.ach!.routingNumber = paymentMethod.ach!.routingNumber;
    txnPaymentMethod.ach!.accountNumber = paymentMethod.ach!.accountNumber;
    txnPaymentMethod.ach!.accountHolderType = paymentMethod.ach!.accountHolderType;
    txnPaymentMethod.ach!.accountType = paymentMethod.ach!.accountType;
    txnPaymentMethod.ach!.confirmed = paymentMethod.ach!.confirmed;
  }
  return txn;
}

export function fillTransactionWithVaultPaymentMethod(
  transaction: VirtualTerminalTransaction,
  paymentMethod: PaymentMethod,
): VirtualTerminalTransaction {
  const txn = { ...transaction };
  const vaultPaymentMethod: VaultPaymentMethod = {
    id: paymentMethod.id!,
    customerId: paymentMethod.customerId!,
    isCof: '1',
    processorId: paymentMethod.processorId!,
    type: paymentMethod.type,
  };
  if (paymentMethod.type === PaymentType.CREDIT) {
    vaultPaymentMethod.creditCard = {
      method: paymentMethod.creditCard!.method!,
    };
  }
  txn.paymentMethod = vaultPaymentMethod;
  return txn;
}
