




































import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      name: '',
    };
  },
  props: {
    addModal: {
      type: Boolean,
    },
  },
  methods: {
    save() {
      if (!this.isValid()) {
        return;
      }
      this.$emit('saveCategory', this.name);
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
});
