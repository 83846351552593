


























import Vue from 'vue';
import { generatePaymentMethodTableData } from '@/common/util/payments.util';
import { Transaction, TransactionStatus } from '@/common/types/transactions.types';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { PaymentType } from '@/common/types/payments.types';
import TransactionDetails from '../../transactions/components/TransactionDetails.vue';

export default Vue.extend({
  props: {
    subscriptionId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedTransactionId: '' as string,
      drawer: false,
      generatePaymentMethodTableData,
      EntityFilterType,
      PaymentType,
      TransactionStatus,
      config: {
        pageTitle: 'View Subscription',
        routerBase: 'merchant.transactions',
        apiPath: `subscriptions/${this.subscriptionId}/transactions`,
        entityName: 'transactions',
        identifierProperty: 'id',
        header: '',
        reportFileName: 'transaction_details_report',
        filters: [],
        headers: [
          {
            text: 'Amount',
            value: 'refundedAmount',
            align: ' d-none',
            sortable: false,
          },
          {
            text: 'Date',
            value: 'createdAt',
            class: 'entity-table-header subtitle-1 font-weight-black',
            sortable: false,
          },
          {
            text: 'ID',
            value: 'id',
            class: 'entity-table-header subtitle-1 font-weight-bold',
            sortable: false,
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            class: 'entity-table-header subtitle-1 font-weight-bold',
          },
          {
            text: 'Payment method',
            value: 'paymentMethod',
            sortable: false,
            class: 'entity-table-header subtitle-1 font-weight-bold',
          },
          {
            text: 'Amount',
            value: 'amount',
            class: 'entity-table-header subtitle-1 font-weight-bold',
            sortable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-1 font-weight-bold',
          },
        ],
      },
    };
  },
  methods: {
    viewTransaction(transaction: Transaction) {
      this.selectedTransactionId = transaction.id!;
      this.drawer = !this.drawer;
    },
    getAmount(transaction: Transaction) {
      if (transaction.refundedAmount && transaction.refundedAmount !== 0) {
        return (this.$options.filters as any).dollarsAndCents(transaction.refundedAmount);
      }

      return (this.$options.filters as any).dollarsAndCents(transaction.amount);
    },
  },
  watch: {
    drawer() {
      if (!this.drawer) {
        this.selectedTransactionId = '';
      }
    },
  },
  components: { EntitiesTable, TransactionDetails },
});
