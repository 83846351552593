





















































import api from '@/common/api';
import dateFormat from 'dateformat';
import Vue from 'vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import ReportsPagesTabs from '@/merchant/components/reports-pages-tabs.vue';
import { mapState } from 'vuex';
import { Batch } from '../components/batches/batches.types';

export default Vue.extend({
  components: {
    EntitiesTable,
    ReportsPagesTabs,
  },
  data() {
    return {
      openBatchLoaded: false as boolean,
      openBatchObj: {} as object,
      EntityFilterType,
      config: {
        pageTitle: 'Batch Settlements Report',
        apiPath: 'batches',
        routerBase: 'merchant.batches',
        entityName: 'batch',
        identifierProperty: 'id',
        reportFileName: 'batch_settlements_report',
        filters: [
          {
            name: 'dateStartCreatedAt',
            display: 'Start Date',
            type: EntityFilterType.DATE,
            value: dateFormat(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              'yyyy-mm-dd',
            ),
            open: false,
          },
          {
            name: 'dateEndCreatedAt',
            display: 'End Date',
            type: EntityFilterType.DATE,
            value: dateFormat(new Date(), 'yyyy-mm-dd'),
            open: false,
          },
          {
            name: 'batchNumber',
            display: 'Batch Number',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'processorId',
            display: 'Location',
            value: '',
            type: EntityFilterType.SELECT,
            items: [],
          },
        ],
        headers: [
          { text: 'ID', value: 'id', align: ' d-none' },
          {
            text: 'Settlement Date',
            value: 'settledAt',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '15rem',
          },
          {
            text: 'Batch Number',
            value: 'batchNumber',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Transaction Count',
            value: 'transactionCount',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '12rem',
          },
          {
            text: 'Volume',
            value: 'volume',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Refunds',
            value: 'refundAmount',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Net Amount',
            value: 'netAmount',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Processor Name',
            value: 'processorName',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '15rem',
          },
        ],
        footer: {
          header: 'Totals',
          displayHeaders: ['transactionCount', 'volume', 'refundAmount', 'netAmount'],
          displayFunction(filters: any, entities: any[], header: string) {
            if (header === 'transactionCount') {
              return entities.reduce((sum: number, item: Batch) => sum + item.transactionCount, 0);
            }
            if (header === 'volume') {
              const formatted = filters.dollarsAndCents(
                entities.reduce((sum: number, item: Batch) => sum + item.volume, 0),
              );
              return `$${formatted}`;
            }
            if (header === 'refundAmount') {
              const formatted = filters.dollarsAndCents(
                entities.reduce((sum: number, item: Batch) => sum + item.refundAmount, 0),
              );
              return `$${formatted}`;
            }
            if (header === 'netAmount') {
              const formatted = filters.dollarsAndCents(
                entities.reduce((sum: number, item: Batch) => sum + item.netAmount, 0),
              );
              return `$${formatted}`;
            }
            return '';
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['processors', 'context']),
  },
  watch: {
    processors: {
      immediate: true,
      handler(value) {
        const locations = value.map((x: any) => ({ display: x.name, value: x.id })) || [];
        this.config.filters[3].items = locations;
      },
    },
  },
  mounted() {
    this.getOpenBatch();
    this.$store.dispatch('loadMerchantProcessors', this.context);
  },
  methods: {
    getOpenBatch() {
      api
        .get('/batches/open-batch')
        .then(({ data }) => {
          this.openBatchLoaded = true;
          this.openBatchObj = data.data!;
        })
        .catch(() => {
          this.$toasted.error('Failed to load open batch data. Try again later!');
        });
    },
    getAmount(amount: number) {
      if (amount < 0) {
        const absAmount = Math.abs(amount);
        return `($${(this.$options.filters as any).dollarsAndCents(absAmount)})`;
      }

      return `$${(this.$options.filters as any).dollarsAndCents(amount)}`;
    },
    netAmountClass(amount: number) {
      return (amount < 0) ? 'red--text' : '';
    },
  },
});
