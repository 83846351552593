<template>
  <div :id="id" class="d-flex">
    <v-icon v-if="isEditing" small class="mr-2" @click="fieldEditing = !fieldEditing">
      {{ fieldEditing ? 'mdi-content-save' : 'mdi-pencil' }}
    </v-icon>
    <p
      style="width: 100%; word-wrap: break-word"
      class="mb-0"
      ref="editable"
      :contenteditable="fieldEditing"
      v-on="listeners"
    />
    <slot name="append-outer"></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fieldEditing: false,
    };
  },
  computed: {
    listeners() {
      return { ...this.$listeners, input: this.onInput };
    },
  },
  mounted() {
    this.$refs.editable.innerText = this.value;
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.innerText);
    },
  },
};
</script>
