// You can specify a custom display function
// If you require use of Vue Filters then be sure to pass this.$options.filters
// when calling the function
export interface EntityDisplayFunc {
  (filters: any, entity: any): string;
}

export interface EntityHighlightRowOptions {
  highlighted: boolean;
  colorClasses: string;
}

export interface EntityHighlightRowFunc {
  (entity: any): EntityHighlightRowOptions;
}

export interface EntityIcon {
  icon: string;
  title: string;
  size: string;
  class: string;
}

export interface EntityIconFunc {
  (entity: any): EntityIcon;
}

export interface EntityLink {
  href: string;
  display: string;
}

export interface EntityActionLink extends EntityLink {
  class: string;
}

export interface EntityLinkFunc {
  (entity: any): EntityLink;
}

export interface EntityActionLinkFunc {
  (entity: any): EntityActionLink[];
}

export interface EntityColumnDisplay {
  header: string;
  // The display precedence is as follows:
  //   - useMailToLink
  //   - iconFunction
  //   - linkFunction
  //   - displayFunction
  //   - property
  useMailToLink?: boolean;
  iconFunction?: EntityIconFunc;
  linkFunction?: EntityLinkFunc;
  displayFunction?: EntityDisplayFunc;
  property?: string;
}
export interface BasicEntityFilter {
  name: string;
  value: string | number| Date;
}
export interface EntityFilter extends BasicEntityFilter {
  display: string;
  component?: any;
  compositeValues?: BasicEntityFilter[];
}

export interface TableFooter {
  header: string;
  displayFunction: EntityDisplayFunc;
}

export interface EntitiesIndexConfig {
  pageTitle: string;
  header: string;
  routerBase: string;
  apiPath: string;
  entityName: string;
  identifierProperty: string;
  reportFileName: string;
  columns: EntityColumnDisplay[];
  filters: EntityFilter[];
  actionLinkFunction?: EntityActionLinkFunc;
  footer?: TableFooter;
  highlightRowFunction?: EntityHighlightRowFunc;
}

export enum EntityFilterType {
  DATE = 'date',
  DATE_MONTH = 'dateMonth',
  TEXT = 'text',
  SELECT = 'select',
  TYPE_AHEAD = 'typeAhead'
}
