
























































import Vue from 'vue';
import { Processor, ProcessorType, ElavonConfig } from '@/common/types/processors.types';
import { initProcessor } from '@/common/util/processors.util';
import LoadingModal from '@/common/components/LoadingModal.vue';
import api from '@/common/api';

export default Vue.extend({
  props: {
    merchantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: true as boolean,
      isSubmitting: false as boolean,
      processor: initProcessor(ProcessorType.ELAVON) as Processor,
      binRules: [
        (v: string) => !!v || 'Bank Number is required',
        (v: string) => /^[0-9]{6}$/.test(v) || 'Bank Number has to be 6 digits',
      ],
      midRules: [
        (v: string) => !!v || 'Merchant ID is required',
        (v: string) => /^[0-9]{10}$/.test(v) || 'Merchant ID has to be 10 digits',
      ],
      tidRules: [
        (v: string) => !!v || 'Terminal ID is required',
        (v: string) => /^[0-9]{3}$/.test(v) || 'Terminal ID has to end with 3 digits',
      ],
    };
  },
  methods: {
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.processor = initProcessor();
    },
    submit() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      const tid = this.tidPrefix + (this.processor.config as ElavonConfig).tid;
      api
        .post(`merchants/${this.merchantId}/processors`, {
          key: this.processor.key,
          name: this.processor.name,
          config: {
            ...this.processor.config,
            tid,
          },
        })
        .then(() => {
          this.$toasted.success('Success');
          this.reset();
          this.$emit('created');
        })
        .catch(({ response }) => {
          this.$toasted.error(response.data.message);
        })
        .finally(() => this.submitActions(false));
    },
  },
  computed: {
    tidPrefix(): string {
      return `000${(this.processor.config as ElavonConfig).mid ?? ''}`;
    },
  },
  components: { LoadingModal },
});
