







































































import { mapGetters } from 'vuex';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import api from '@/common/api';
import PageLoader from '@/common/components/PageLoader.vue';
import PageHeader from '@/common/components/PageHeader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import Vue from 'vue';
import getSubdomain from '@/common/context';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import InvoiceFeeModal from '@/common/components/modals/InvoiceFeeModal.vue';
import { CustomFieldFillable } from '@/common/components/support/support.types';
import LineItem from '../line-items/LineItem.vue';
import { initLineItem, LineItemInterface, LineItemsInterface } from '../line-items/lineItems.types';
import InvoiceMerchantContact from './InvoiceMerchantContact.vue';
import InvoiceCustomerForm from './InvoiceCustomerForm.vue';
import InvoiceInformation from './InvoiceInformation.vue';
import InvoiceCustomFields from './InvoiceCustomFields.vue';
import InvoiceTotals from './InvoiceTotals.vue';
import {
  FeeType, InvoiceCustomer, MerchantInfo, InvoiceInformationInterface, initInvoiceInformation,
  SaveOption, formatDate, SaveText,
} from './invoices.types';
import AddLineItemButton from './AddLineItemButton.vue';
import SmsModalConfirmation from '../../components/sms-modal-confirmation.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false as boolean,
      showSmsModal: false,
      lineItem: initLineItem() as LineItemInterface,
      lineItems: [initLineItem() as LineItemInterface] as LineItemsInterface[],
      subtotal: 0 as number,
      merchantInfo: {} as MerchantInfo,
      customFields: [] as CustomFieldFillable[],
      invoiceCustomer: {} as InvoiceCustomer,
      addModal: false,
      discountPercent: 0 as number,
      taxPercent: 0 as number,
      shippingDollarAmount: 0 as number,
      invoiceInformation: initInvoiceInformation() as InvoiceInformationInterface,
      modalType: '' as string,
      editModal: false,
      showErrorMsg: false,
      saveOption: '',
      countries: [],
      states: [],
      loadingText: '' as string,
    };
  },
  mounted() {
    api
      .get(`merchants/${getSubdomain()}/virtual-terminal`).then(this.setMerchant)
      .catch(() => {
        if (this.$refs.loader) {
          (this.$refs.loader as any).setError();
        }
      });
    api
      .get(`merchant/invoice/${this.id}`).then(this.setInvoice)
      .catch(() => {
        if (this.$refs.loader) {
          (this.$refs.loader as any).setError();
        }
      });
    api.get('countries').then(({ data: { data: countries } }) => {
      this.countries = countries;
    });
    api.get('states').then(({ data: { data: states } }) => {
      this.states = states;
    });
  },
  methods: {
    addBlankLineItem() {
      this.lineItems.push(initLineItem());
    },
    addFilledLineItem(lineItem: LineItemInterface, id: number) {
      this.lineItems.splice(id, 1, lineItem);
      this.calculateSubtotal();
    },
    setInvoice({ data }: { data: JsonApiSingleResponse<any> }) {
      this.invoiceCustomer = { ...data.data.invoiceCustomer };
      const modifiedLineItems = data.data.lineItems.map((lineItem: LineItemInterface) => ({
        ...lineItem,
        rate: (lineItem.rate / 100).toFixed(2),
        lineTotal: lineItem.lineTotal / 100,
      }));
      this.lineItems = modifiedLineItems;
      this.invoiceInformation = { ...data.data.invoiceInformation };
      this.discountPercent = data.data.invoiceInformation.discount;
      this.taxPercent = data.data.invoiceInformation.tax;
      this.shippingDollarAmount = data.data.invoiceInformation.shipping;
      this.customFields = data.data.customFields;
    },
    setMerchant({ data }: { data: JsonApiSingleResponse<MerchantInfo> }) {
      this.merchantInfo = { ...data.data! };
      (this.$refs.loader as any).setReady();
    },
    save(sendImmediately = false) {
      this.saveOption = '';
      if (!this.isValid()) return;

      this.showSmsModal = sendImmediately;

      if (!this.showSmsModal) {
        this.sendEditRequest(sendImmediately);
      }
    },
    sendEditRequest(sendImmediately: boolean, message?: any) {
      this.loadingText = sendImmediately ? SaveText.UPDATEANDSEND : SaveText.UPDATE;
      this.isSubmitting = true;

      const modifiedLineItems = this.lineItems.map((lineItem) => ({
        ...lineItem,
        rate: lineItem.rate * 100,
      }));

      if (this.merchantInfo.nonCashAdjustmentFee?.inInvoice) {
        this.invoiceInformation.cashDiscountName = this.merchantInfo.nonCashAdjustmentFee.name;
        this.invoiceInformation.cashDiscount = this.merchantInfo.nonCashAdjustmentFee.adjustmentPercent
          ? this.merchantInfo.nonCashAdjustmentFee.adjustmentPercent
          : (this.merchantInfo.nonCashAdjustmentFee.dollarAmount / this.subtotal) * 100;
        this.invoiceInformation.cashDiscount = this.invoiceInformation.cashDiscount.toString();
      }

      api
        .put(`/merchant/invoice/${this.id}`, {
          customer: { ...this.invoiceCustomer },
          lineItems: { ...modifiedLineItems },
          invoiceInformation: { ...this.invoiceInformation },
          customFields: this.customFields,
          sendImmediately,
          message,
        })
        .then(this.showSuccess)
        .catch(this.handleSaveError);

      this.showSmsModal = false;
    },
    showSuccess() {
      this.submitActions(false);
      if (this.loadingText === SaveText.UPDATE) {
        this.$toasted.success('Invoice updated successfully!');
      } else {
        this.$toasted.success('Invoice updated and sent successfully!');
      }
      this.$router.replace({ name: 'merchant.invoices.index' });
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      if (response.status === 422) {
        const errors = response?.data?.errors;
        let msg: any = '';
        Object.keys(errors).forEach((key: any, idx: any) => {
          if (idx > 0) msg += '<br />';
          msg += `${errors[key]}`;
        });
        this.$toasted.error(msg);
      } else {
        this.$toasted.error(`There was a problem creating the merchant. (${response.status})`);
      }
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    deleteLineItem(id: number) {
      this.lineItems.splice(id, 1);
      this.calculateSubtotal();
    },
    calculateSubtotal() {
      const inital = 0;
      const subtotal = this.lineItems.reduce(
        (previousValue:number, item:LineItemInterface) => previousValue + item.lineTotal,
        inital,
      );
      this.subtotal = subtotal;
    },
    saveValue(value: number, modalType: string) {
      this.addModal = false;
      switch (modalType) {
        case FeeType.DISCOUNT:
          this.discountPercent = value;
          this.invoiceInformation.discount = this.discountPercent;
          this.initialDiscountState = false;
          break;
        case FeeType.TAX:
          this.taxPercent = value;
          this.invoiceInformation.tax = this.taxPercent;
          this.initialTaxState = false;
          break;
        default:
          this.shippingDollarAmount = value * 100;
          this.invoiceInformation.shipping = this.shippingDollarAmount;
          this.initialShippingState = false;
          break;
      }
    },
    openModal(modalType: string) {
      this.addModal = true;
      switch (modalType) {
        case FeeType.DISCOUNT:
          this.modalType = modalType;
          if (this.discountPercent > 0) {
            this.editModal = true;
          } else {
            this.editModal = false;
          }
          break;
        case FeeType.TAX:
          this.modalType = modalType;
          if (this.taxPercent > 0) {
            this.editModal = true;
          } else {
            this.editModal = false;
          }
          break;
        default:
          this.modalType = modalType;
          if (this.shippingDollarAmount > 0) {
            this.editModal = true;
          } else {
            this.editModal = false;
          }
          this.addModal = true;
          break;
      }
    },
    formatDate,
  },
  watch: {
    saveOption() {
      if (this.saveOption === SaveOption.UPDATE) {
        this.save();
      } else if (this.saveOption === SaveOption.UPDATEANDSEND) {
        this.save(true);
      }
    },
  },
  computed: {
    ...mapGetters(['isInvoicingEnabled']),
    cashDiscount(): number {
      const thereIsNoCashDiscount = !this.merchantInfo.nonCashAdjustmentFee;
      const cashDiscountNotApplies = !this.merchantInfo.nonCashAdjustmentFee?.inInvoice;
      if (thereIsNoCashDiscount || cashDiscountNotApplies) return 0;

      if (this.merchantInfo.nonCashAdjustmentFee!.adjustmentPercent) {
        return (this.subtotal * this.merchantInfo.nonCashAdjustmentFee!.adjustmentPercent) / 100;
      }

      return this.merchantInfo.nonCashAdjustmentFee!.dollarAmount;
    },
    discountAmount(): number {
      return (this.subtotal * this.discountPercent) / 100;
    },
    taxAmount(): number {
      return ((this.subtotal - this.discountAmount) * this.taxPercent) / 100;
    },
    total(): number {
      return (this.subtotal + this.cashDiscount - this.discountAmount) + this.taxAmount + this.shippingDollarAmount;
    },
    addOrEditModal(): string {
      return !this.editModal ? 'Add' : 'Edit';
    },
    initialDiscountState: {
      get(): boolean {
        return !(this.discountPercent > 0);
      },
      set() {
      },
    },
    initialTaxState: {
      get(): boolean {
        return !(this.taxPercent > 0);
      },
      set() {
      },
    },
    initialShippingState: {
      get(): boolean {
        return !(this.shippingDollarAmount > 0);
      },
      set() {
      },
    },
    saveOptions() {
      return [
        { value: SaveOption.UPDATE, display: 'Update' },
        { value: SaveOption.UPDATEANDSEND, display: 'Update and Send' },
      ];
    },
  },
  components: {
    PageHeader,
    PageLoader,
    LineItem,
    InvoiceMerchantContact,
    InvoiceCustomerForm,
    InvoiceFeeModal,
    InvoiceInformation,
    InvoiceTotals,
    AddLineItemButton,
    LoadingModal,
    PaidFeatureNotEnabled,
    SmsModalConfirmation,
    InvoiceCustomFields,
  },
});

