
















import Vue from 'vue';
import AchPaymentMethod from '@/common/components/AchPaymentMethod.vue';
import PCard from '@/components/presentations/p-card.vue';

export default Vue.extend({
  props: {
    banking: {
      type: Object,
      required: true,
    },
    isoEditOrMerchant: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { AchPaymentMethod, PCard },
});
