<template>
  <v-text-field
    class="text-right pa-0 ma-0"
    name="amount"
    label="Amount"
    :rules="[(v) => v > 0 || 'Amount has to be greater than 0']"
    :value="amountValue"
    @input="debouncedAmount"
    :disabled="disabled"
    prefix="$"
    :prepend-icon="!fixedAmount && !planAmount && !hasProducts ? 'mdi-asterisk' : ''"
  />
</template>

<script>
import { debounce } from 'lodash';

export default {
  data() {
    return {
      initialValue: true,
    };
  },
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    validationName: {
      type: String,
      required: false,
      default: 'amount',
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixedAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    planAmount: {
      type: Number,
      required: false,
    },
    hasProducts: { type: Boolean, default: false },
  },
  methods: {
    debouncedAmount: debounce(function debounced(value) {
      const amount = parseFloat(value) || 0;
      this.$emit('setTotal', Math.round(amount * 100));
    }, 100),
  },
  updated() {
    // needed a way to format 'value' only when the page first loads with the inital value,
    // and not format it when you enter/edit the value. there is probably a better way
    this.initialValue = false;
  },
  computed: {
    amountValue() {
      if (this.hasProducts) return (this.fixedAmount).toFixed(2);

      if (this.fixedAmount !== 0 && this.fixedAmount !== undefined) {
        return (this.fixedAmount).toFixed(2);
      }
      if (this.planAmount !== 0 && this.planAmount !== undefined) {
        return this.planAmount;
      }
      if (this.value) {
        return this.initialValue ? (this.value / 100).toFixed(2) : this.value / 100;
      } return '';
    },
  },
};
</script>

<style scoped>
  ::v-deep .v-text-field__prefix {
      color: black !important;
    }
</style>
