












































































































import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import CustomerVaultDropdown from '@/iso/views/merchants/components/CustomerVaultDropdown.vue';
import { mapGetters } from 'vuex';
import api from '@/common/api';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import Vue from 'vue';
import getSubdomain from '@/common/context';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import InvoiceFeeModal from '@/common/components/modals/InvoiceFeeModal.vue';
import { CustomField, CustomFieldFillable } from '@/common/components/support/support.types';
import LineItem from '../line-items/LineItem.vue';
import { initLineItem, LineItemInterface, LineItemsInterface } from '../line-items/lineItems.types';
import InvoiceMerchantContact from './InvoiceMerchantContact.vue';
import InvoiceCustomerForm from './InvoiceCustomerForm.vue';
import InvoiceInformation from './InvoiceInformation.vue';
import InvoiceTotals from './InvoiceTotals.vue';
import InvoiceCustomFields from './InvoiceCustomFields.vue';
import {
  FeeType, InvoiceCustomer, MerchantInfo, InvoiceInformationInterface, initInvoiceInformation,
  SaveOption, SaveText,
} from './invoices.types';
import AddLineItemButton from './AddLineItemButton.vue';
import SmsModalConfirmation from '../../components/sms-modal-confirmation.vue';

export default Vue.extend({
  data() {
    return {
      isSubmitting: false as boolean,
      showSmsModal: false,
      lineItem: initLineItem() as LineItemInterface,
      lineItems: [initLineItem() as LineItemInterface] as LineItemsInterface[],
      subtotal: 0 as number,
      merchantInfo: {} as MerchantInfo,
      invoiceCustomer: {} as InvoiceCustomer,
      initialDiscountState: true,
      initialTaxState: true,
      initialShippingState: true,
      addModal: false,
      discountPercent: 0 as number,
      taxPercent: 0 as number,
      shippingDollarAmount: 0 as number,
      invoiceInformation: initInvoiceInformation() as InvoiceInformationInterface,
      modalType: '' as string,
      editModal: false,
      showErrorMsg: false,
      saveOption: '',
      countries: [],
      states: [],
      loadingText: '' as string,
      invoiceCustomerUpdates: 0,
      customFields: [] as CustomFieldFillable[],
      addresses: [] as any[],
      merchantAddress: '' as string,
    };
  },
  mounted() {
    api
      .get(`merchants/${getSubdomain()}/virtual-terminal`).then(this.setMerchant)
      .catch(() => {
        if (this.$refs.loader) {
          (this.$refs.loader as any).handleSaveError();
        }
      });
    api
      .get('countries').then(({ data: { data: countries } }) => {
        this.countries = countries;
      });
    api
      .get('states').then(({ data: { data: states } }) => {
        this.states = states;
      });
    api.get('address').then(({ data: { data: addresses } }) => {
      this.addresses = addresses;
    });
    api
      .get('/custom-fields', { params: { invoice: true } })
      .then(({ data }: { data: JsonApiArrayResponse<CustomField>}) => {
        this.customFields = data.data!.map((customField) => ({
          ...customField,
          value: '',
        }));
      });
  },
  methods: {
    onSelectCustomerFromVault(customer: any) {
      if (customer === undefined) {
        this.invoiceCustomer = {} as InvoiceCustomer;
        this.invoiceCustomerUpdates += 1;
        return;
      }

      this.invoiceCustomer.company = customer.billingAddress.company;
      this.invoiceCustomer.firstName = customer.billingAddress.firstName;
      this.invoiceCustomer.lastName = customer.billingAddress.lastName;
      this.invoiceCustomer.email = customer.billingAddress.email;
      this.invoiceCustomer.address1 = customer.billingAddress.address1;
      this.invoiceCustomer.address2 = customer.billingAddress.address2;
      this.invoiceCustomer.city = customer.billingAddress.city;
      this.invoiceCustomer.state = customer.billingAddress.state;
      this.invoiceCustomer.zip = customer.billingAddress.zip;
      this.invoiceCustomer.phoneNumber = customer.billingAddress.phone;
      this.invoiceCustomer.country = customer.billingAddress.country;

      this.invoiceCustomerUpdates += 1;
    },
    addBlankLineItem() {
      this.lineItems.push(initLineItem());
    },
    addFilledLineItem(lineItem: LineItemInterface, id: number) {
      this.lineItems.splice(id, 1, lineItem);
      this.calculateSubtotal();
    },
    setMerchant({ data }: { data: JsonApiSingleResponse<MerchantInfo> }) {
      this.merchantInfo = { ...data.data! };
      (this.$refs.loader as any).setReady();
    },
    save(sendImmediately = false) {
      this.saveOption = '';
      if (!this.isValid()) return;

      this.showSmsModal = sendImmediately;

      if (!this.showSmsModal) {
        this.sendCreateRequest(sendImmediately);
      }
    },
    sendCreateRequest(sendImmediately: boolean, message?: any) {
      this.loadingText = sendImmediately ? SaveText.SAVEANDSEND : SaveText.SAVE;
      this.isSubmitting = true;

      const modifiedLineItems = this.lineItems.map((lineItem) => ({
        ...lineItem,
        rate: lineItem.rate * 100,
      }));

      if (this.merchantInfo.nonCashAdjustmentFee?.inInvoice) {
        this.invoiceInformation.cashDiscountName = this.merchantInfo.nonCashAdjustmentFee.name;
        this.invoiceInformation.cashDiscount = this.merchantInfo.nonCashAdjustmentFee.adjustmentPercent
          ? this.merchantInfo.nonCashAdjustmentFee.adjustmentPercent
          : (this.merchantInfo.nonCashAdjustmentFee.dollarAmount / this.subtotal) * 100;
        this.invoiceInformation.cashDiscount = this.invoiceInformation.cashDiscount.toString();
      }

      api
        .post('/merchant/invoice/create', {
          customer: { ...this.invoiceCustomer },
          lineItems: { ...modifiedLineItems },
          invoiceInformation: { ...this.invoiceInformation },
          customFields: this.customFields,
          sendImmediately,
          message,
          ...(this.merchantAddress ? {
            merchant_address: {
              uuid: this.merchantAddress,
              address1: this.selectedMerchantAddress.address1,
              address2: this.selectedMerchantAddress.address2,
              city: this.selectedMerchantAddress.city,
              state: this.selectedMerchantAddress.state,
              zip: this.selectedMerchantAddress.zip,
            },
          } : {}),
        })
        .then(this.showSuccess)
        .catch(this.handleSaveError);

      this.showSmsModal = false;
    },
    showSuccess() {
      this.submitActions(false);
      if (this.loadingText === SaveText.SAVE) {
        this.$toasted.success('Invoice created successfully!');
      } else {
        this.$toasted.success('Invoice created and sent successfully!');
      }
      this.$router.replace({ name: 'merchant.invoices.index' });
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      if (response.status === 422) {
        const errors = response?.data?.errors;
        let msg: any = '';
        Object.keys(errors).forEach((key: any, idx: any) => {
          if (idx > 0) msg += '<br />';
          msg += `${errors[key]}`;
        });
        this.$toasted.error(msg);
      } else {
        this.$toasted.error(`There was a problem creating the invoice. (${response.status})`);
      }
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    deleteLineItem(id: number) {
      this.lineItems.splice(id, 1);
      this.calculateSubtotal();
    },
    calculateSubtotal() {
      const inital = 0;
      const subtotal = this.lineItems.reduce(
        (previousValue:number, item:LineItemInterface) => previousValue + item.lineTotal,
        inital,
      );
      this.subtotal = subtotal;
    },
    saveValue(value: number, modalType: string) {
      this.addModal = false;
      switch (modalType) {
        case FeeType.DISCOUNT:
          this.discountPercent = value;
          this.invoiceInformation.discount = this.discountPercent;
          this.initialDiscountState = false;
          break;
        case FeeType.TAX:
          this.taxPercent = value;
          this.invoiceInformation.tax = this.taxPercent;
          this.initialTaxState = false;
          break;
        default:
          this.shippingDollarAmount = value * 100;
          this.invoiceInformation.shipping = this.shippingDollarAmount;
          this.initialShippingState = false;
          break;
      }
    },
    openModal(modalType: string) {
      this.addModal = true;
      switch (modalType) {
        case FeeType.DISCOUNT:
          this.modalType = modalType;
          if (this.discountPercent > 0) {
            this.editModal = true;
          } else {
            this.editModal = false;
          }
          break;
        case FeeType.TAX:
          this.modalType = modalType;
          if (this.taxPercent > 0) {
            this.editModal = true;
          } else {
            this.editModal = false;
          }
          break;
        default:
          this.modalType = modalType;
          if (this.shippingDollarAmount > 0) {
            this.editModal = true;
          } else {
            this.editModal = false;
          }
          this.addModal = true;
          break;
      }
    },
  },
  watch: {
    saveOption() {
      if (this.saveOption === SaveOption.SAVE) {
        this.save();
      } else if (this.saveOption === SaveOption.SAVEANDSEND) {
        this.save(true);
      }
    },
  },
  computed: {
    ...mapGetters(['isInvoicingEnabled']),
    selectedMerchantAddress(): any {
      if (!this.merchantAddress) return null;

      return this.addresses.find((x: any) => x.uuid === this.merchantAddress);
    },
    cashDiscount(): number {
      const thereIsNoCashDiscount = !this.merchantInfo.nonCashAdjustmentFee;
      const cashDiscountNotApplies = !this.merchantInfo.nonCashAdjustmentFee?.inInvoice;
      if (thereIsNoCashDiscount || cashDiscountNotApplies) return 0;

      if (this.merchantInfo.nonCashAdjustmentFee!.adjustmentPercent) {
        return (this.subtotal * this.merchantInfo.nonCashAdjustmentFee!.adjustmentPercent) / 100;
      }

      return this.merchantInfo.nonCashAdjustmentFee!.dollarAmount;
    },
    discountAmount(): number {
      return (this.subtotal * this.discountPercent) / 100;
    },
    taxAmount(): number {
      return ((this.subtotal - this.discountAmount) * this.taxPercent) / 100;
    },
    total(): number {
      return (this.subtotal + this.cashDiscount - this.discountAmount) + this.taxAmount + this.shippingDollarAmount;
    },
    addOrEditModal(): string {
      return !this.editModal ? 'Add' : 'Edit';
    },
    saveOptions() {
      return [
        { value: SaveOption.SAVE, display: 'Save' },
        { value: SaveOption.SAVEANDSEND, display: 'Save and Send' },
      ];
    },
  },
  components: {
    PageLoader,
    LineItem,
    InvoiceMerchantContact,
    InvoiceCustomerForm,
    InvoiceFeeModal,
    InvoiceInformation,
    InvoiceTotals,
    AddLineItemButton,
    LoadingModal,
    PaidFeatureNotEnabled,
    SmsModalConfirmation,
    CustomerVaultDropdown,
    InvoiceCustomFields,
  },
});

