





















































import Vue from 'vue';
import api from '@/common/api';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { Feature } from '@/features.types';
import InfoFieldEdit from './InfoFieldEdit.vue';
import { InfoField } from './support.types';

export default Vue.extend({
  data() {
    return {
      Feature,
      infoFields: [] as InfoField[],
      meta: {} as any,
      loading: true,
      options: {} as any,
      infoFieldToEdit: '' as string,
      isSubmitting: false as boolean,
      loadingText: '' as string,
      footerItemsPerPage: [-1, 20, 50, 100],
      headers: [
        {
          text: 'id',
          value: 'id',
          sortable: false,
          align: ' d-none',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Virtual Terminal',
          value: 'inVirtualTerminal',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Hosted Payments Page',
          value: 'inHPP',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Required',
          value: 'required',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
      ],
      errors: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.options;

      const metaTotal = this.meta.total > 0 ? this.meta.total : '';
      const params: any = {
        page,
        perPage: itemsPerPage === -1 ? metaTotal : itemsPerPage,
        role: '',
        sortBy,
        sortDesc,
      };
      this.loading = true;
      api
        .get('/info-fields', { params })
        .then(this.assignInfoFields)

        .catch(() => {
          this.infoFields = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    assignInfoFields({ data }: { data: JsonApiArrayResponse<InfoField> }) {
      this.meta = data.meta;
      this.infoFields = data.data!;
    },
    submitActions(isSubmitting: boolean, loadingText: string = 'Deleting...') {
      this.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    editInfoField(infoField: InfoField) {
      this.infoFieldToEdit = infoField?.id;
      (this.$refs.editModal as any).show();
    },
  },
  components: {
    LoadingModal,
    InfoFieldEdit,
  },
});

