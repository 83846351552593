import Permissions from '@/common/permissions';
import { AffiliateNotification } from '@/common/types/affiliateNotifications.types';
import { JsonApiData } from '@/jsonApi.types';

export enum UserType {
  ADMIN = 'admin',
  ISO = 'iso',
  MERCHANT = 'merchant',
}

export interface User extends JsonApiData {
  username: string;
  password?: string;
  firstName: string;
  lastName: string;
  email: string;
  permissions: Permissions[];
  notifications?: AffiliateNotification[];
  locations?: string[];
}

export function initUser(withPassword: boolean = false) {
  const noPassword: User = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    permissions: [],
    notifications: [],
    locations: [],
  };
  return withPassword ? { ...noPassword, password: '' } : noPassword;
}
