



































































































import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import { mapGetters } from 'vuex';
import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import LogoPicker from '@/components/atoms/a-logo-picker.vue';
import Vue from 'vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import { Product, initProduct, measurementUnits } from './products.types';

export default Vue.extend({
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  mounted() {
    this.loadCategories();
    this.loadProduct();
  },
  data() {
    return {
      product: initProduct() as Product,
      measurementUnits,
      isSubmitting: false as boolean,
      categories: null,
    };
  },
  computed: {
    ...mapGetters(['isInvoicingEnabled']),
    logo: {
      get(): string {
        return this.product.image;
      },
      set(value: any) {
        this.product.image = value;
      },
    },
  },
  methods: {
    save() {
      if (!this.isValid()) {
        return;
      }
      this.product.cost = this.product.cost || 0;
      const cost = Math.round(this.product.cost * 100);
      this.submitActions(true);
      api
        .put(`merchant/products/${this.id}`, {
          ...this.product, cost,
        })
        .then(this.showSuccess)
        .catch(this.handleSaveError);
    },
    loadCategories() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api
        .get('merchant/category').then(({ data }) => {
          this.categories = data.data;
          (this.$refs.loader as any).setReady();
        })
        .catch(() => {
          if (this.$refs.loader) {
            (this.$refs.loader as any).setError();
          }
        });
    },
    loadProduct() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api
        .get(`merchant/products/${this.id}`).then(this.setProduct);
    },
    setProduct({ data }: { data: JsonApiSingleResponse<Product> }) {
      this.product = { ...data.data! };
      this.product.cost = (this.$options.filters as any).dollarsAndCentsNoCommas(this.product.cost);
      (this.$refs.loader as any).setReady();
    },
    showSuccess() {
      this.submitActions(false);
      this.$toasted.success('Product updated successfully!');
      this.$router.replace({ name: 'merchant.products.index' });
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      this.$toasted.error(`There was a problem editing the product. (${response.status})`);
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    PageHeader,
    LogoPicker,
    PageLoader,
    LoadingModal,
    PaidFeatureNotEnabled,
  },
});

