






import env from '@/environmentSetup';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    loginUrl() {
      const fusionAuthDomain = env('VUE_APP_FUSION_AUTH_URL');
      const fusionAuthClientId = env('VUE_APP_FUSION_AUTH_CLIENT_ID');
      const oauthRedirect = `${window.location.origin}/oauth-redirect`;
      const redirect = encodeURIComponent(oauthRedirect);

      return `${fusionAuthDomain}/oauth2/authorize?client_id=${fusionAuthClientId}&response_type=code&redirect_uri=${redirect}&scope=offline_access`;
    },
  },
  created() {
    document.title = 'RiseOS-Login';
    window.location.href = this.loginUrl;
  },
});
