<template>
  <div class="auth-layout">
    <header class="auth-layout__header">
      <img
        v-if="!hideImage"
        :src="$store.state.branding ? $store.state.branding.fullLogo : ''"
        :alt="$store.state.brandName + ' logo'"
        class="auth-layout__logo"
        width="215"
        height="75"
      >
    </header>

    <main class="auth-layout__main">
      <slot />
    </main>


    <footer class="auth-layout__footer">
      <img
        src="/images/login-hero.png"
        class="auth-layout__footer-image"
        width="436"
        height="439"
        alt=""
      />
      <div class="auth-layout__footer-bg"></div>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    hideImage: { type: Boolean },
  },
};
</script>

<style scoped>
.auth-layout {
  padding-block-start: 10vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  isolation: isolate;
}

.auth-layout__header {
  margin-block-end: 2rem;
  display: grid;
  place-items: center;
}

.auth-layout__logo {
  object-fit: contain;
}

.auth-layout__main {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.auth-layout__main > * {
  flex: 1;
}

.auth-layout__footer {
  margin-block-start: auto;
  padding-block-start: 10rem;
  width: 100%;
  position: relative;
  z-index: -1;
}

.auth-layout__footer-image {
  position: absolute;
  bottom: 1rem;
  object-fit: contain;
}

.auth-layout__footer-bg {
  height: clamp(2rem, 7vh, 4.75rem);
  background: var(--primary-color);
}
</style>
