




import Vue from 'vue';
import EmailSettings from '../../../common/components/support/EmailSettings.vue';

export default Vue.extend({
  components: {
    EmailSettings,
  },
});
