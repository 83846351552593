










































































import Vue from 'vue';
import api from '@/common/api';

import LoadingModal from '@/common/components/LoadingModal.vue';
import { CustomField, CustomFieldType, initCustomField } from './support.types';

export default Vue.extend({
  data() {
    return {
      CustomFieldType,
      newCustomField: initCustomField() as CustomField,
      newOptions: '' as string,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      fieldTypes: Object.values(CustomFieldType) as string[],
    };
  },
  methods: {
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }
      this.close();
      api
        .post('/custom-fields', {
          name: this.newCustomField.name,
          type: this.newCustomField.type,
          inVirtualTerminal: this.newCustomField.inVirtualTerminal,
          inHPP: this.newCustomField.inHPP,
          inInvoices: this.newCustomField.inInvoices,
          required: this.newCustomField.required,
          options:
            this.newOptions !== ''
              ? this.newOptions.split('\n').filter((option) => option !== '')
              : [],
          isSearchable: this.newCustomField.isSearchable,
        })
        .then(() => {
          this.newCustomField = initCustomField();
          this.newOptions = '';
          this.$emit('created');
          (this.$refs.form as any).resetValidation();
        })
        .catch(({ response }: { response: any }) => {
          this.$toasted.error(
            `There was a problem creating new custom field. (${response.status})`,
          );
        });
    },
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    LoadingModal,
  },
});
