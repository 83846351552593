<template>
  <v-data-table
    show-select
    :headers="headers"
    :items="lineItems"
    item-key="name"
    class="mb-5"
  />
</template>

<script>
export default {
  name: 'HppListItems',
  data() {
    return {
      headers: [
        {
          text: 'Product',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Price', value: 'price' },
      ],
      lineItems: [
        {
          name: 'Frozen Yogurt',
          price: 1.59,
        },
        {
          name: 'Ice cream sandwich',
          price: 2.37,
        },
        {
          name: 'Eclair',
          price: 2.62,
        },
      ],
    };
  },
};
</script>
