














import { JsonApiSingleResponse } from '@/jsonApi.types';
import Vue from 'vue';

export default Vue.extend({
  props: {
    response: {
      type: Object as () => JsonApiSingleResponse<any>,
      required: false,
      default: null,
    },
  },
  computed: {
    isValidationError(): boolean {
      return this.response.status! === 422;
    },
    title(): string {
      return this.response.data.message ?? this.response.message;
    },
    reason(): string {
      return this.response.data.message;
    },
    errorList(): string[] {
      if (!this.isValidationError) {
        return [];
      }
      const errorMessages: string[] = [];
      Object.keys(this.response.data.errors)
        .forEach((key) => errorMessages.push(...this.response.data.errors[key]));
      return errorMessages;
    },
  },
});
