import * as Sentry from '@sentry/browser';
import env from '@/environmentSetup';
import { Vue as VueIntegration } from '@sentry/integrations';
import Vue from 'vue';
import Toasted from 'vue-toasted';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import router from '@/common/router';
import store from '@/common/store';
import titleMixin from '@/common/mixins/titles';
import IdleVue from 'idle-vue';
import { isEnabled } from 'vue-feature-flipping';
import { Feature } from '@/features.types';
import ErrorPrompts from '@/assets/prompts/errors.json';
import ErrorMessages from '@/error_messages';
import DOMPurify from 'dompurify';
import App from './App.vue';

import '@/assets/css/app.css';
import setVueFilters from './filters';
import setVuePlugins from './plugins';
import setFeatureToggles from './features';
import vuetify from './plugins/vuetify';
import BlurDecimals from './directives/blur-decimals';

if (window.trustedTypes && window.trustedTypes.createPolicy) { // Feature testing
  window.trustedTypes.createPolicy('default', {
    createHTML: (str : string) => DOMPurify.sanitize(str),
    createScript: (str : string) => str,
    createScriptURL: (str : string) => str,
  });
}

Sentry.init({
  dsn: env('VUE_APP_SENTRY_DSN'),
  integrations: [new VueIntegration({ Vue, logErrors: true })],
});

Vue.config.productionTip = false;
Vue.use(ErrorMessages, { data: ErrorPrompts });
Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000,
  keepOnHover: true,
  className: 'toast',
  router,
});
Vue.use(VueReCaptcha, { siteKey: env('VUE_APP_RECAPTCHA_KEY') });
setVuePlugins(Vue);
Vue.mixin(titleMixin);
Vue.directive('blur-decimals', BlurDecimals);
setVueFilters(Vue);

setFeatureToggles();

const eventsHub = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

function invokeEventsHub() {
  return eventsHub;
}

if (isEnabled(Feature.AUTO_LOGOUT)) {
  const TIMEOUT_MINUTES = 14;
  const idleTime = TIMEOUT_MINUTES * 60 * 1000;

  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime,
    startAtIdle: false,
  });
} else {
  invokeEventsHub();
}
