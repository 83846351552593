

















































































import Vue from 'vue';
import {
  RRule, Frequency, Weekday, Options, rrulestr,
} from 'rrule';
import { DateTime } from 'luxon';
import { Month, WeekDay } from '@/common/types/dateTime.types';
import { initValidationErrors } from '@/common/validation/validation.types';
import {
  BillingAmountModel,
  BillingRecurrenceModel,
  MonthRecurrenceModel,
  RecurrenceDurationModel,
  RecurrenceFrequency,
  WeekDayModel,
} from '@/common/types/billingRecurrences.types';
import {
  DEFAULT_RECURRENCE_AMOUNT,
  DEFAULT_RECURRENCE_INTERVAL,
  DEFAULT_RECURRENCE_MONTH,
  DEFAULT_RECURRENCE_MONTH_DAY,
  DEFAULT_RECURRENCE_WEEK_DAY,
} from '@/common/util/billingRecurrences.util';
import RecurrenceDurationInput from './Recurrences/RecurrenceDurationInput.vue';
import RecurrenceFrequencyInput from './Recurrences/RecurrenceFrequencyInput.vue';
import RecurrenceWeekDayInput from './Recurrences/RecurrenceWeekDayInput.vue';
import RecurrenceDayOfMonthInput from './Recurrences/RecurrenceDayOfMonthInput.vue';
import RecurrenceMonthInput from './Recurrences/RecurrenceMonthInput.vue';
import BillingRecurrenceAmountInput from './Recurrences/BillingRecurrenceAmountInput.vue';
import {
  createWeekdayMap,
  DEFAULT_RRULE_FREQUENCY,
  FrequencyModel,
} from './Recurrences/rrules.types';

interface RuleOptions {
  frequency: FrequencyModel;
  weekDay: WeekDayModel;
  duration: RecurrenceDurationModel;
  monthData: MonthRecurrenceModel;
}

export default Vue.extend({
  props: {
    value: {
      type: Object as () => BillingRecurrenceModel,
      required: true,
    },
    ruleText: {
      type: String,
      required: false,
    },
    amountToBill: {
      type: Number,
      required: false,
      default: DEFAULT_RECURRENCE_AMOUNT,
    },
    showStartDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Object,
      required: false,
      default: () => initValidationErrors(),
    },
  },
  data() {
    return {
      showDatePicker: false as boolean,
      startDate: {
        value: null as string | null,
      },
      options: {
        frequency: {
          frequency: DEFAULT_RRULE_FREQUENCY,
        },
        weekDay: {
          day: DEFAULT_RECURRENCE_WEEK_DAY,
        },
        duration: {
          interval: DEFAULT_RECURRENCE_INTERVAL,
          count: null,
        },
        monthData: {
          day: DEFAULT_RECURRENCE_MONTH_DAY,
          month: DEFAULT_RECURRENCE_MONTH,
        },
      } as RuleOptions,
      billingAmount: {
        amount: 0,
      } as BillingAmountModel,
      weekDayMap: createWeekdayMap() as Map<WeekDay, Weekday>,
      Frequency,
      Month,
      WeekDay,
    };
  },
  mounted() {
    this.billingAmount.amount = (this.amountToBill / 100);
    this.loadFromRuleText(this.ruleText);
  },
  watch: {
    options: {
      handler() {
        this.emitUpdateEvent();
      },
      deep: true,
    },
    startDate: {
      handler() {
        this.emitUpdateEvent();
      },
      deep: true,
    },
    billingAmount: {
      handler() {
        this.emitUpdateEvent();
      },
      deep: true,
    },
    amountToBill() {
      this.billingAmount.amount = (this.amountToBill / 100);
    },
    ruleText: {
      handler() {
        this.loadFromRuleText(this.ruleText);
      },
    },
  },
  computed: {
    computedMonthDay(): number | null {
      if (
        this.options.frequency.frequency === Frequency.MONTHLY
        || this.options.frequency.frequency === Frequency.YEARLY
      ) {
        return this.options.monthData.day;
      }
      return null;
    },
    computedWeekDay(): Weekday[] | null {
      if (
        this.options.frequency.frequency === Frequency.WEEKLY
        && this.options.weekDay.day != null
      ) {
        const day: Weekday = this.weekDayMap.get(+this.options.weekDay.day)!;
        return [day];
      }
      return null;
    },
    computedMonth(): number | null {
      if (this.options.frequency.frequency === Frequency.YEARLY) {
        return this.options.monthData.month;
      }
      return null;
    },
    recurrenceText(): string {
      return this.rrule.toText().replace('time', 'installment');
    },
    recurrenceFrequency(): RecurrenceFrequency {
      switch (this.options.frequency.frequency) {
        case Frequency.SECONDLY:
          return RecurrenceFrequency.SECONDLY;
        case Frequency.MINUTELY:
          return RecurrenceFrequency.MINUTELY;
        case Frequency.HOURLY:
          return RecurrenceFrequency.HOURLY;
        case Frequency.DAILY:
          return RecurrenceFrequency.DAILY;
        case Frequency.WEEKLY:
          return RecurrenceFrequency.WEEKLY;
        case Frequency.MONTHLY:
          return RecurrenceFrequency.MONTHLY;
        default:
          return RecurrenceFrequency.YEARLY;
      }
    },
    rrule(): RRule {
      const dtstart: Date | null = this.startDate.value
        ? DateTime.fromFormat(this.startDate.value, 'yyyy-MM-dd').toUTC().toJSDate()
        : null;
      return new RRule({
        freq: this.options.frequency.frequency || DEFAULT_RRULE_FREQUENCY,
        interval: this.options.duration.interval || DEFAULT_RECURRENCE_INTERVAL,
        byweekday: this.computedWeekDay,
        bymonth: this.computedMonth,
        bymonthday: this.computedMonthDay,
        count: this.options.duration.count,
        dtstart,
      });
    },
  },
  methods: {
    loadFromRuleText(ruleText: string) {
      const rule: RRule | null = ruleText && ruleText !== '' ? rrulestr(this.ruleText) : null;
      const ruleOptions: Options | null = rule ? rule.options : null;
      if (ruleOptions) {
        this.options.duration.count = ruleOptions.count;
        // since corresponding properties in ruleOptions can be null
        // we need to handle this by making sure defaults get used instead.
        // This is done to prevent passing null to a child component
        this.options.frequency.frequency = ruleOptions.freq != null
          ? ruleOptions.freq : DEFAULT_RRULE_FREQUENCY;
        this.options.duration.interval = ruleOptions.interval
          ? ruleOptions.interval
          : DEFAULT_RECURRENCE_INTERVAL;
        this.options.monthData.day = ruleOptions.bymonthday
          ? (ruleOptions.bymonthday as number[])?.[0]
          : DEFAULT_RECURRENCE_MONTH_DAY;
        this.options.monthData.month = ruleOptions.bymonth
          ? (ruleOptions.bymonth as number[])?.[0]
          : DEFAULT_RECURRENCE_MONTH;
        this.options.weekDay.day = ruleOptions.byweekday
          ? (ruleOptions.byweekday as number[])[0]
          : DEFAULT_RECURRENCE_WEEK_DAY;
      }
      this.emitUpdateEvent();
    },
    emitUpdateEvent() {
      const rule = this.rrule;
      const event: BillingRecurrenceModel = {
        amount: this.billingAmount.amount * 100,
        recurrence: {
          recurrenceText: rule.toText(),
          rruleText: rule.toString(),
          recurrenceFrequency: this.recurrenceFrequency,
        },
      };
      this.$emit('input', event);
    },
  },
  components: {
    RecurrenceDurationInput,
    RecurrenceFrequencyInput,
    RecurrenceWeekDayInput,
    RecurrenceDayOfMonthInput,
    RecurrenceMonthInput,
    BillingRecurrenceAmountInput,
  },
});
