



























































































































import { CustomerAddress } from '@/common/types/customers.types';
import Vue from 'vue';

export default Vue.extend({
  props: {
    address: {
      type: Object as () => CustomerAddress,
      required: false,
      default: null,
    },
    isBilling: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    title(): string {
      return this.isBilling ? 'Billing Details' : 'Shipping Details';
    },
  },
});
