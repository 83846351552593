


























































import Vue from 'vue';
import { AffiliateNotification } from '@/common/types/affiliateNotifications.types';
import { getUnderscoreReplacedWithSpace, capitalizeFirstCharaterOfWords } from '@/common/util/common.util';

export default Vue.extend({
  props: {
    notifications: {
      type: Array as () => AffiliateNotification[],
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timeframeDays: [
        { text: '7 Days', value: 7 },
        { text: '30 Days', value: 30 },
        { text: '60 Days', value: 60 },
        { text: 'Custom Timeframe', value: -1 },
      ],
      notificationFrequencies: [
        { text: 'Every Day', value: 'day' },
        { text: 'Every Week', value: 'week' },
        { text: 'Every Month', value: 'month' },
      ],
    };
  },
  methods: {
    getLabel(name: string) {
      return capitalizeFirstCharaterOfWords(getUnderscoreReplacedWithSpace(name));
    },
  },
});
