<template>
  <auth-layout>
    <p-card class="password-confirm">
      <template #header>
        <h1>Password Reset Confirmation</h1>
      </template>

      <v-form ref="form" v-model="valid" @submit.prevent="resetPassword">
        <v-text-field
          v-model="user.username"
          label="Username"
          :error-messages="validationErrors.username"
          :rules="[(v) => !!v || 'Username is required']"
          readonly
          outlined
          dense
        />
        <v-text-field
          v-model="user.password"
          label="Password"
          :error-messages="validationErrors.password"
          type="password"
          :rules="[
            (v) => !!v || 'Password is required',
            (v) => v.length >= 8 || 'Password must be at least 8 characters'
          ]"
          outlined
          dense
        />
        <v-text-field
          v-model="user.password_confirmation"
          label="Password Confirmation"
          :error-messages="validationErrors.confirm"
          type="password"
          :rules="[
            (v) => !!v || 'Password confirmation is required',
            (v) => v.length >= 8 || 'Password confirmation must be at least 8 characters',
            (v) => v === user.password || 'Passwords do not match'
          ]"
          outlined
          dense
        />

        <v-btn
          color="primary"
          type="submit"
          :disabled="!valid || submitting"
          :loading="submitting"
        >
          Set Password
        </v-btn>
      </v-form>
    </p-card>
  </auth-layout>
</template>

<script>
import auth from '@/common/auth';
import PCard from '@/components/presentations/p-card.vue';
import AuthLayout from './AuthLayout.vue';

export default {
  title: 'Set new password',
  components: { AuthLayout, PCard },
  data() {
    return {
      user: {
        username: '',
        password: '',
        password_confirmation: '',
        token: this.$route.params.token,
      },
      valid: true,
      submitting: false,
      validationErrors: {},
      redirect: '',
    };
  },
  created() {
    this.user.username = this.$route.query.username;
    this.redirect = this.$route.query.redirect;
  },
  methods: {
    async resetPassword() {
      this.submitting = true;

      try {
        await auth.post('password/reset', this.user);
        this.$toasted.success('Password succesfully reset. You will be redirected in 3 seconds');

        setTimeout(() => { window.location = this.redirect; }, 3000);
      } catch (e) {
        this.$toasted.error('There was an error while resetting the password');
        this.validationErrors = e.response.data.errors;

        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped>
.password-confirm {
  --card-padding: 2rem;
  --card-header-margin-block-end: 2rem;

  border-top: 0.25rem solid var(--primary-color);
  max-width: 30rem;
}
</style>
