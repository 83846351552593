





























































































































































































































































































































































import { mapState } from 'vuex';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import Vue from 'vue';
import api, { setHeader } from '@/common/api';
import debounce from 'lodash/debounce';
import PerfectScrollbar from 'perfect-scrollbar';
import { MccCode } from '@/iso/views/merchants/components/mcc-codes.types';
import { getPreviousMonth } from '@/common/util/dateTime.util';
import { SubscriptionStatus } from '@/common/types/subscriptions.types';
import { BasicEntityFilter, EntityFilterType } from './entities.types';
import DeleteModal from '../modals/DeleteModal.vue';
import AddModal from '../modals/AddModal.vue';
import EditModal from '../modals/EditModal.vue';

import 'perfect-scrollbar/css/perfect-scrollbar.css';

export default Vue.extend({
  props: {
    config: {
      type: Object,
      required: true,
    },
    showDownloadCsvButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showLoginButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showViewButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    openModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCustomButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    customButtonText: {
      type: String,
      required: false,
      default: '',
    },
    allowAddFunc: {
      type: Function,
      required: false,
      default() {
        return true;
      },
    },
    allowEditFunc: {
      type: Function,
      required: false,
      default() {
        return true;
      },
    },
    allowViewFunc: {
      type: Function,
      required: false,
      default() {
        return true;
      },
    },
    allowDeleteFunc: {
      type: Function,
      required: false,
      default() {
        return true;
      },
    },
    reLoadData: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileBreakpoint: {
      type: Number,
      default: 600,
      required: false,
    },
    openBatchObj: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (
      this.config.reportFileName === 'commissions_report'
      || this.config.reportFileName === 'residual_report'
      || this.config.reportFileName === 'affiliates_report'
      || this.config.reportFileName === 'merchant_report'
      || this.config.reportFileName === 'recurring_report'
      || this.config.reportFileName === 'customers_vault_report'
      || this.config.reportFileName === 'batch_settlements_report'
      || this.config.reportFileName === 'billing_details_report'
      || this.config.reportFileName === 'customer_batch_uploads_report'
      || this.config.reportFileName === 'shopping_carts_report'
    ) this.footerItemsPerPage = [-1, 20, 50, 100];
    else if (this.config.reportFileName === 'transaction_details_report') this.footerItemsPerPage = [20, 50, 100];

    if (this.config.pageTitle) {
      document.title = `RiseOS-${this.config.pageTitle}`;
    }
  },
  data() {
    return {
      EntityFilterType,
      filterDialog: false,
      customModal: false,
      deleteModal: false,
      addModal: false,
      editModal: false,
      categoryId: '',
      search: this.config.search || '' as string,
      autoCompleteSearch: '' as string,
      autoCompleteSearchLoading: false as boolean,
      autoCompleteSearchResults: [] as any[],
      entitySelectedForDeletion: {} as any,
      items: [] as any[],
      meta: {} as any,
      loading: true,
      options: {} as any,
      getPreviousMonth,
      footerItemsPerPage: [20, 50, 100, -1],
      loadingdata: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: debounce(function debounced(this: any) {
      if (this.options.itemsPerPage === -1) {
        this.options.itemsPerPage = '';
      }
      this.getData();
    }, 1000),
    'config.search': {
      handler(val) {
        this.search = val;
      },
    },
    autoCompleteSearch() {
      if (this.autoCompleteSearch === '' || !this.autoCompleteSearch) return;
      if (this.autoCompleteSearchLoading) return;

      this.autoCompleteSearchLoading = true;
      api
        .get(this.config.apiFilterSearchPath, {
          params: {
            q: this.autoCompleteSearch,
          },
        })
        .then(({ data: { data: searchResult } }) => {
          this.autoCompleteSearchResults = searchResult.map(
            (result: MccCode) => ({
              text: `${result.mcc} - ${result.combined_description}`,
              value: result.mcc,
            }),
          );
        })
        .finally(() => {
          this.autoCompleteSearchLoading = false;
        });
    },
    reLoadData() {
      if (this?.reLoadData) {
        this.getData();
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    allowEdit(entity: any): boolean {
      return this.allowEditFunc(entity);
    },
    allowView(entity: any): boolean {
      return this.showViewButton && this.allowViewFunc(entity);
    },
    allowDelete(entity: any): boolean {
      return this.allowDeleteFunc(entity);
    },
    getData() {
      if (!this.loadingdata) {
        this.loadingdata = true;
        const {
          page, itemsPerPage, sortBy, sortDesc,
        } = this.options;
        this.loading = true;
        const metaTotal = this.meta.total > 0 ? this.meta.total : '';
        const params: any = {
          page,
          perPage: itemsPerPage === -1 ? metaTotal : itemsPerPage,
          role: '',
          q: this.search,
          sortBy,
          sortDesc,
          ...this.querySearchParams,
        };
        api
          .get(this.config.apiPath, {
            params,
          })
          .then(this.setEntities)
          .catch(({ response }) => {
            this.loadingdata = false;
            const defaultErrorMsg = this.$errorMessages.get('default');
            switch (response.status) {
              case 403: {
                const error = response?.data?.message || defaultErrorMsg;
                this.$toasted.error(error);
                if (this.config.reportFileName === 'customers_vault_report') {
                  window.location.reload();
                }
                break;
              }
              case 422: {
                const errors = response?.data?.errors || defaultErrorMsg;
                let msg: any = '';
                Object.keys(errors).forEach((key: any, idx: any) => {
                  if (idx > 0) msg += '<br />';
                  msg += `${errors[key]}`;
                });
                this.$toasted.error(msg);
                break;
              }
              default:
                this.$toasted.error(defaultErrorMsg);
            }
          });
      }
    },
    setEntities({ data }: { data: JsonApiArrayResponse<any> }) {
      this.loadingdata = false;
      const tableData = data.data!;

      if (
        this.config.reportFileName === 'batch_settlements_report'
        && data.meta.currentPage === 1
        && this.openBatchObj?.transactionCount > 0
      ) {
        tableData.unshift({
          batchNumber: '',
          id: '',
          netAmount: this.openBatchObj?.netAmount,
          processorId: '',
          refundAmount: this.openBatchObj?.refundedAmount,
          settledAt: 'Open Batch (Not Settled)',
          transactionCount: this.openBatchObj?.transactionCount,
          volume: this.openBatchObj?.volume,
        });
      }

      this.items = tableData.map((item) => {
        const result = {} as any;
        if (typeof item.raw !== 'undefined') {
          result.raw = item.raw;
        }
        this.config.headers.forEach((header: any) => {
          result[header.value] = item[header.value];
          if (this.config.reportFileName === 'recurring_report') {
            if (header.value === 'status') {
              if (item[header.value] === SubscriptionStatus.CANCELED) result.color = 'red';
              else if (item[header.value] === SubscriptionStatus.COMPLETED) result.color = 'light-green';
            }
          } else result.color = '';
        });

        return result;
      });
      this.meta = data.meta;
      if (
        this.config.reportFileName === 'batch_settlements_report'
        && data.meta.currentPage === 1
        && this.openBatchObj?.transactionCount > 0
      ) {
        this.meta.total = data.meta.total === 0 ? 1 : data.meta.total;
      }
      this.loading = false;

      if (['ip_whitelists', 'receipt_settings'].includes(this.config.reportFileName)) {
        this.$emit('dataLoaded');
      }
      this.closeFilterDialog();
      this.initCustomScroll();
    },
    downloadCsv() {
      const params: any = {
        role: '',
        ...this.querySearchParams,
      };
      setHeader('Accept', 'text/csv');
      api
        .get(this.config.apiPath, {
          params,
          responseType: 'blob',
        })
        .then((response) => {
          fileDownload(
            response.data,
            `${DateTime.local().toFormat('yyyy_MM_dd')}_${
              this.config.reportFileName
            }.csv`,
          );
        });
      setHeader('Accept', 'application/json');
    },
    applyFilter() {
      this.closeFilterDialog();
      this.options.itemsPerPage = -1;
      this.meta.total = 0;
      this.getData();
    },
    resetFilters() {
      this.config.filters = this.config.filters.map((filter: any) => ({
        ...filter,
        value: filter.type === EntityFilterType.DATE ? [] : '',
      }));
      this.closeFilterDialog();
      this.meta.total = 0;
      this.getData();
    },
    confirmDeleteEntity(entity: any) {
      this.entitySelectedForDeletion = entity;
      this.deleteModal = true;
    },
    deleteEntity() {
      api
        .delete(`${this.config.apiPath}/${this.entitySelectedForDeletion.id}`)
        .then(() => {
          this.items = this.items.filter(
            (item) => item.id !== this.entitySelectedForDeletion.id,
          );
        })
        .catch(({ response }) => {
          const message = this.$errorMessages.get('default_delete');
          this.$toasted.error(`${message} (${response.status})`);
        })
        .finally(() => {
          this.deleteModal = false;
        });
    },
    initCustomScroll() {
      const table = this.$el.querySelector('.v-data-table__wrapper');
      if (!table) {
        return;
      }

      const ps = new PerfectScrollbar(table, {
        suppressScrollY: true,
      });
      ps.update();
    },
    closeFilterDialog() {
      this.filterDialog = false;
    },
    closeCustomModal() {
      this.customModal = false;
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    closeAddModal() {
      this.addModal = false;
    },
    closeEditModal() {
      this.editModal = false;
    },
    editModalFunction(id: any) {
      this.categoryId = id;
      this.editModal = true;
    },
    saveCategory(name: string) {
      api.post(`${this.config.apiPath}/create`, {
        name,
      }).then(this.updateComponentKey);
    },
    updateCategory(category: any) {
      this.closeEditModal();
      api.put(`${this.config.apiPath}/${category.uuid}`, {
        id: category.uuid,
        name: category.name,
      }).then(this.updateComponentKey);
    },
    updateComponentKey() {
      this.$emit('updateComponentKey');
      this.$toasted.success('Category created successfully!');
    },
    isMobile(): boolean {
      if (this.$vuetify.breakpoint.width === 0) return false;

      return this.$vuetify.breakpoint.width < this.mobileBreakpoint;
    },
  },
  computed: {
    ...mapState(['accessToken']),
    querySearchParams(): any {
      let params: any = {};
      this.config.filters
        .filter((filterItem: any) => filterItem.value !== '')
        .forEach((filterItem: any) => {
          const filter: any = {};
          if (filterItem.compositeValues) {
            filterItem.compositeValues.forEach((value: BasicEntityFilter) => {
              filter[value.name] = value.value;
            });
            params = { ...params, ...filter };
          } else if (this.config.reportFileName === 'transaction_details_report' && (filterItem.name).indexOf('customFields') === 0) {
            if (typeof params.customFields !== 'undefined') {
              const oldCustomFields = params.customFields;
              const newCustomField = { [filterItem.display]: filterItem.value };
              params.customFields = {
                ...oldCustomFields,
                ...newCustomField,
              };
            } else {
              filter.customFields = { [filterItem.display]: filterItem.value };
              params = { ...params, ...filter };
            }
          } else {
            filter[filterItem.name] = filterItem.value;
            params = { ...params, ...filter };
          }
        });
      return params;
    },
    allowAdd(): boolean {
      return this.showAddButton && this.allowAddFunc();
    },
    showHeaderTitle(): boolean {
      return !!this.config.header;
    },
    showFilters(): boolean {
      return this.config.filters && this.config.filters.length > 0;
    },
    showToolbar(): boolean {
      return (
        this.showHeaderTitle || this.showFilters || this.showDownloadCsvButton || this.allowAdd
      );
    },
    previousMonth(): any {
      const today = new Date();
      today.setMonth(Number(getPreviousMonth()) - 1);
      return today.toISOString();
    },
  },
  components: {
    DeleteModal,
    AddModal,
    EditModal,
  },
});
