<template>
  <v-tooltip v-if="item.status === IsoStatus.ACTIVATED" bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <i-check-circle />
      </div>
    </template>
    Live
  </v-tooltip>

  <v-tooltip v-else-if="item.status === IsoStatus.HOLD" bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <i-do-not />
      </div>
    </template>
    On Hold - No Commissions Paid
  </v-tooltip>

  <v-tooltip v-else-if="item.status === IsoStatus.QUALIFIED" bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" @click="activateIso">
        <i-rocket />
      </div>
    </template>
    Launch
  </v-tooltip>

  <v-tooltip v-else bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <i-onboarding />
      </div>
    </template>
    Boarding
  </v-tooltip>
</template>

<script>
import api from '@/common/api';
import ICheckCircle from '@/components/icons/i-check-circle.vue';
import IOnboarding from '@/components/icons/i-onboarding.vue';
import IDoNot from '@/components/icons/i-do-not.vue';
import IRocket from '@/components/icons/i-rocket.vue';
import { IsoStatus } from '../types/affiliate';

export default {
  props: {
    item: { type: Object, required: true },
  },
  components: {
    ICheckCircle,
    IOnboarding,
    IDoNot,
    IRocket,
  },
  data() {
    return {
      IsoStatus,
    };
  },
  methods: {
    activateIso() {
      api
        .patch(`isos/${this.item.id}/activate`, { activate: true })
        .then(() => {
          this.item.status = IsoStatus.ACTIVATED;
        })
        .catch(({ response }) => {
          const message = this.$errorMessages.get('affiliates.default_activation');
          this.$toasted.error(`${message} (${response.status})`);
        });
    },
  },
};
</script>

<style scoped>
>>> svg {
  margin: 0 auto;
}
</style>
