enum Permissions {
  ADD_USER = 'add-user',
  EDIT_USER = 'edit-user',
  DELETE_USER = 'delete-user',
  MANAGE_ISOS = 'manage-isos',
  EDIT_BUY_RATES = 'edit-buy-rates',
  EDIT_DEFAULT_BUY_RATES = 'edit-default-buy-rates',
  VIEW_RESIDUAL_REPORT = 'view-residual-report',
  MANAGE_FAQ = 'manage-faq',
  EDIT_MAINTENANCE_MESSAGE = 'edit-maintenance-message',
  EDIT_USER_PERMISSIONS = 'edit-user-permissions',
}

export default Permissions;
