<template>
  <div>
    <vue-tel-input-vuetify
      v-model="theValue"
      v-bind="$attrs"
      :label="label"
      :name="name"
      :rules="[
        fieldRequired ? (v) => !!v || `${label} number is required` : true,
        !valid ? () => `${label} is incorrect` : true,
      ]"
      :valid-characters-only="true"
      @input="onInput"
      :wrapperClasses="wrapperClasses"
    />
  </div>
</template>

<script>
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue';

export default {
  components: {
    VueTelInputVuetify,
  },
  props: {
    name: String,
    value: String,
    label: String,
    fieldRequired: Boolean,
  },
  data() {
    return {
      input: '',
      valid: true,
      wrapperClasses: typeof this.$attrs.outlined === 'string' ? 'outlined' : 'lined',
    };
  },
  methods: {
    onInput(formattedNumber, { number, valid }) {
      this.valid = (number.input === '') || valid;
      this.theValue = number;
    },
  },
  computed: {
    theValue: {
      /**
       * Returns a string in the format '+1(###) ###-####'.
       */
      get() {
        return this.value ?? this.input;
      },
      /**
       * Emits a string in the format '+1##########' where '#' is a digit.
       * No spaces, no parentheses.
       *
       * @param {string} value formatted phone number
       */
      set(val) {
        const regex = /[\s,\-,(,)]/g;
        const phoneForSave = val?.e164 ?? val.input?.replace(regex, '') ?? val.replace(regex, '');
        this.input = val.international ?? phoneForSave;
        this.$emit('input', phoneForSave);
      },
    },
  },
};
</script>

<style lang="scss">
.lined{
  margin-top: 2px;

  .country-code{
    width: 30px;

    .v-select__selections .vti__flag {
      margin-left:0;
    }
  }
}

.outlined{
  .country-code {
    width: 42px;
    margin-right: 5px;
    .v-input__slot{
      padding: 0 10px;
      min-width: 44px;
    }
  }
}
</style>
