



















import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { InputTypes } from '@/common/types/input.types';
import Vue from 'vue';

/**
 * Why not just use v-bind="$attrs" in the component declaration?
 * It would have avoided the verbose declaration of all those props...
 * Same with the events just with v-on="$listeners"
 */
export default Vue.extend({
  props: {
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    errors: {
      type: Object as () => ValidationErrors,
      required: false,
      default: () => initValidationErrors(),
    },
    rules: {
      type: Array,
      required: false,
    },
    validationName: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      required: false,
      default: InputTypes.TEXT,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      InputTypes,
    };
  },
  computed: {
    inputId() {
      return this.id || this.name;
    },
    validationKey(): string {
      return (this.validationName || this.name) as string;
    },
    errorMessages(): string[] {
      if (!this.errors) {
        return [];
      }
      const errors = (this.errors as ValidationErrors)[this.validationKey as string];
      return errors ?? [];
    },
  },
});
