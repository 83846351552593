<template>
  <ul>
    <tab-item :to="{ name: 'merchant.reports.transactions.details' }">
      Transactions Detail Report
    </tab-item>
    <tab-item :to="{ name: 'merchant.reports.batch.settlements' }">
      Batch Settlements Report
    </tab-item>
    <tab-item :to="{ name: 'merchant.reports.recurring' }">
      Subscription Report
    </tab-item>
    <tab-item :to="{ name: 'merchant.reports.billing.details' }">
      Billing Details Report
    </tab-item>
  </ul>
</template>

<script>
import TabItem from './tab-item.vue';

export default {
  components: { TabItem },
};
</script>

<style scoped>
ul {
  display: flex;
  gap: 0.25rem;
}
</style>
