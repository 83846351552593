import { getCurrentMonth } from '@/common/util/dateTime.util';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { ResidualFeeType, ResidualFeeTypeFilterOption } from '@/common/types/residuals.types';

const RESIDUAL_REPORT_TABLE_FILTERS = [
  {
    name: 'merchantName',
    display: 'Merchant Name',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'processorName',
    display: 'Processor Name',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'startDate',
    display: 'Start Date',
    type: EntityFilterType.DATE_MONTH,
    open: false,
    value: `${new Date().getFullYear()}-${getCurrentMonth()}`,
  },
  {
    name: 'endDate',
    display: 'End Date',
    type: EntityFilterType.DATE_MONTH,
    open: false,
    value: `${new Date().getFullYear()}-${getCurrentMonth()}`,
  },
  {
    name: 'rateKey',
    display: 'Fee type',
    value: '',
    type: EntityFilterType.SELECT,
    items: [
      { display: 'Cash Setup Fee', value: ResidualFeeType.CASH_SERVICE_FEE },
      { display: 'Cash Monthly Fee', value: ResidualFeeType.CASH_MONTHLY_FEE },
      { display: 'Cash Per Transaction Fee', value: ResidualFeeType.CASH_TRANSACTION_FEE },
      { display: 'Gateway Setup Fee', value: ResidualFeeType.GATEWAY_SERVICE_FEE },
      { display: 'Gateway Monthly Fee', value: ResidualFeeType.GATEWAY_MONTHLY_FEE },
      {
        display: 'Gateway Per Transaction Fee',
        value: ResidualFeeType.GATEWAY_TRANSACTION_FEE,
      },
      {
        display: 'Electronic Check Setup Fee',
        value: ResidualFeeType.ELECTRONIC_CHECK_SETUP_FEE,
      },
      {
        display: 'Electronic Check Monthly Fee',
        value: ResidualFeeType.ELECTRONIC_CHECK_MONTHLY_FEE,
      },
      {
        display: 'Electronic Check Per Transaction Fee',
        value: ResidualFeeType.ELECTRONIC_CHECK_TRANSACTION_FEE,
      },
      {
        display: 'Electronic Check Batch Fee',
        value: ResidualFeeType.ELECTRONIC_CHECK_BATCH_FEE,
      },
      {
        display: 'Customer Vault Service Fee',
        value: ResidualFeeType.CUSTOMER_VAULT_SERVICE_FEE,
      },
      {
        display: 'Customer Vault Monthly Fee',
        value: ResidualFeeType.CUSTOMER_VAULT_MONTHLY_FEE,
      },
      {
        display: 'Cash Discount Per Transaction Fee',
        value: ResidualFeeType.NONCASH_TRANSACTION_FEE,
      },
      {
        display: 'Cash Discount Monthly Fee',
        value: ResidualFeeType.NONCASH_MONTHLY_FEE,
      },
      {
        display: 'Cash Discount Service Fee',
        value: ResidualFeeType.NONCASH_SERVICE_FEE,
      },
      {
        display: 'Surcharging Monthly Fee',
        value: ResidualFeeType.SURCHARGING_MONTHLY_FEE,
      },
      {
        display: 'Surcharging Service Fee',
        value: ResidualFeeType.SURCHARGING_SERVICE_FEE,
      },
      {
        display: 'Enhanced Level II, III Monthly Fee',
        value: ResidualFeeType.ENHANCED_DATA_MONTHLY_FEE,
      },
      {
        display: 'Enhanced Level II, III Service Fee',
        value: ResidualFeeType.ENHANCED_DATA_SERVICE_FEE,
      },
    ] as ResidualFeeTypeFilterOption[],
  },
];

export default RESIDUAL_REPORT_TABLE_FILTERS;
