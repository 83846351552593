<template>
  <button
    :class="[color, { 'full-width': fullWidth, loading, small }]"
    :disabled="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <a-spinner v-if="loading" />
    <slot />
  </button>
</template>

<script>
import ASpinner from './a-spinner.vue';

export default {
  components: { ASpinner },
  props: {
    fullWidth: { type: Boolean },
    loading: { type: Boolean },
    color: { type: String },
    small: { type: Boolean },
  },
};
</script>

<style scoped>
button {
  position: relative;
  color: var(--button-text-color, #fff);
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7em 2em;
  gap: 0.7em;
  border-radius: 0.5em;
  transition: transform 125ms ease-out;
  z-index: 1;
}

button.small {
  font-size: 0.875rem;
  padding: 0.4em 0.9em;
}

button::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  background: var(--button-color, var(--primary-color));
  z-index: -1;
  transition: filter 80ms ease-out;
}

button.gray {
  --button-color: #ccc;
  --focus-brightness: 1.05;
  --hover-brightness: 1.075;
  --active-brightness: 1.15;
}

button.white {
  --button-color: #fff;
  --button-text-color: currentColor;
  --focus-brightness: 0.95;
  --hover-brightness: 0.9125;
  --active-brightness: 0.875;
}

button.loading {
  cursor: wait;
}

button:disabled {
  opacity: 0.8;
}

button:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

button:focus::before {
  filter: brightness(var(--focus-brightness, 1.1));
}

button:enabled:hover::before {
  filter: brightness(var(--hover-brightness, 1.2));
}

button:enabled:active::before {
  filter: brightness(var(--active-brightness, 1.5));
}

button.full-width {
  width: 100%;
}
</style>
