<template>
  <p-card primary>
    <template #header>
      <h2>Device Manager</h2>

      <a-spinner v-if="loadingStatus" style="margin-left: auto; margin-right: 0.25rem" />
      <v-checkbox
        :value="enabled"
        @change="() => enableDeviceManager(!enabled)"
        :disabled="loadingStatus || savingDevice || deletingDevice"
        color="#fff"
        label="Enable"
        dense
        hide-details
      />
    </template>

    <div v-if="showDevicesList" class="devices-list">
      <ul>
        <li v-for="device in devices" :key="device.id">
          <strong>{{ device.device_name }}</strong>

          <div>
            <v-btn
              :disabled="!enabled || loadingStatus"
              icon
              small
              @click="() => editDevice(device)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              :disabled="!enabled || loadingStatus"
              icon
              small
              @click="() => prepareForDelete(device.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </li>
      </ul>

      <div v-if="devicesMeta.per_page < devicesMeta.total" class="devices-list__pagination">
        <ul>
          <li v-for="(_, index) in Array(devicesMeta.last_page)" :key="index">
            <v-btn
              :disabled="page === index + 1 || !enabled || loadingStatus"
              class="page-button"
              icon
              small
              @click="page = index + 1"
            >
              {{ index + 1}}
            </v-btn>
          </li>
        </ul>

        <div>
          <v-btn
            :disabled="page === 1 || !enabled || loadingStatus"
            @click="page = page - 1"
            small
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            :disabled="page === devicesMeta.last_page || !enabled || loadingStatus"
            @click="page = page + 1"
            small
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div v-if="showDeleteConfirmation" class="delete-confirmation">
      <p>Are you sure you want to delete this device?</p>

      <div>
        <v-btn
          :disabled="deletingDevice"
          @click="showDeleteConfirmation = false"
        >
          <v-icon>mdi-close</v-icon>
          Cancel
        </v-btn>
        <v-btn
          :disabled="deletingDevice"
          color="primary"
          @click="deleteDevice(deviceIdToDelete)"
        >
          <v-icon>mdi-check</v-icon>
          Yes
        </v-btn>
      </div>
    </div>

    <v-form
      v-if="showDeviceForm"
      v-model="validAddDeviceForm"
      class="device-form"
      @submit.prevent="saveDevice"
    >
      <label for="newdevice_name">Device Name (Max. 50 characters)*</label>
      <v-text-field
        v-model="newDevice.device_name"
        autofocus
        id="newdevice_name"
        placeholder="Enter device name"
        maxlength="50"
        counter
        outlined
        dense
        :rules="[
          (v) => !!v || 'Device name is required',
          (v) => v.length <= 50 || 'Device name is too long'
        ]"
      />

      <label for="newDeviceApi">API Key*</label>
      <v-text-field
        v-model="newDevice.api_key"
        id="newDeviceApi"
        placeholder="Enter API key"
        outlined
        dense
        :rules="[
          (v) => !!v || 'API key is required'
        ]"
      />

      <label for="newDeviceRegister">Register ID*</label>
      <v-text-field
        v-model="newDevice.register_id"
        id="newDeviceRegister"
        placeholder="Enter Register ID"
        outlined
        dense
        :rules="[
          (v) => !!v || 'Register ID is required'
        ]"
      />

      <small>* Required fields</small>
    </v-form>

    <div v-if="loadingDevices" class="centerer">
      <a-spinner />
    </div>


    <template v-if="!showDeleteConfirmation" #footer>
      <div v-if="showDeviceForm" class="device-form-buttons">
        <v-btn
          :disabled="savingDevice"
          @click="showDeviceForm = false"
        >
          <v-icon>mdi-close</v-icon>
          Cancel
        </v-btn>
        <v-btn
          :disabled="!validAddDeviceForm || savingDevice"
          color="primary"
          @click="() => deviceIdToUpdate ? updateDevice(deviceIdToUpdate) : saveDevice()"
          :loading="savingDevice"
        >
          <v-icon>mdi-content-save</v-icon>
          Save device
        </v-btn>
      </div>
      <v-btn
        v-else
        :disabled="!enabled || loadingStatus"
        color="primary"
        block
        @click="showDeviceForm = true"
      >
        <v-icon>mdi-plus-circle</v-icon>
        Add Device
      </v-btn>
    </template>
  </p-card>
</template>

<script>
import PCard from '@/components/presentations/p-card.vue';
import ASpinner from '@/components/atoms/a-spinner.vue';
import api from '@/common/api';

export default {
  components: { PCard, ASpinner },
  props: {
    merchantUUID: { type: String, required: true },
  },
  data() {
    return {
      enabled: false,
      devices: [],
      devicesMeta: null,
      page: 1,
      newDevice: {
        device_name: '',
        api_key: '',
        register_id: '',
      },
      deviceIdToDelete: '',
      deviceIdToUpdate: '',

      showDeviceForm: false,
      showDeleteConfirmation: false,
      validAddDeviceForm: false,

      loadingDevices: true,
      loadingStatus: true,
      savingDevice: false,
      deletingDevice: false,
    };
  },
  computed: {
    showDevicesList() {
      return this.devices.length && !this.showDeviceForm && !this.showDeleteConfirmation && !this.loadingDevices;
    },
  },
  watch: {
    page: 'fetchDevices',
  },
  created() {
    this.fetchEnabledStatus();
    this.fetchDevices();
  },
  methods: {
    async fetchDevices() {
      this.loadingDevices = true;
      const endpoint = `/merchants/${this.merchantUUID}/hardware-list?page=${this.page}&perPage=6`;

      try {
        const { data } = await api.get(endpoint);
        this.devices = data.data;
        this.devicesMeta = data.meta;
      } catch (e) {
        this.$toasted.error('There was an error while loading the list of devices');
      } finally {
        this.loadingDevices = false;
      }
    },
    async fetchEnabledStatus() {
      this.loadingStatus = true;
      const endpoint = `/merchants/${this.merchantUUID}/enable-hardware`;

      try {
        const { data } = await api.get(endpoint);
        this.enabled = !!data.enabled;
      } catch (e) {
        if (e.response.status === 404) {
          this.enabled = false;
        } else {
          this.$toasted.error('There was an error while loading the device manager enabled status');
        }
      } finally {
        this.loadingStatus = false;
      }
    },
    async enableDeviceManager(active) {
      this.loadingStatus = true;
      const endpoint = `/merchants/${this.merchantUUID}/enable-hardware`;

      try {
        const { data } = await api.post(endpoint, { active });
        this.enabled = !!data.enabled;

        if (!this.enabled) {
          this.showDeviceForm = false;
        }
      } catch (e) {
        this.$toasted.error('There was an error while toggling the status of the device manager');
      } finally {
        this.loadingStatus = false;
      }
    },
    async saveDevice() {
      this.savingDevice = true;
      const endpoint = `/merchants/${this.merchantUUID}/create-new-hardware`;

      try {
        await api.post(endpoint, this.newDevice);
        this.$toasted.success('Device successfully added');
        this.showDeviceForm = false;
        this.newDevice = { device_name: '', api_key: '', register_id: '' };
        this.fetchDevices();
      } catch (e) {
        if (e.response.status === 409) {
          this.$toasted.error('The Register ID already exists in the database');
        } else {
          this.$toasted.error('There was an error while saving the new device');
        }
      } finally {
        this.savingDevice = false;
      }
    },
    async updateDevice(deviceId) {
      this.savingDevice = true;
      const endpoint = `/merchants/${this.merchantUUID}/hardware/${deviceId}`;

      try {
        await api.put(endpoint, this.newDevice);
        this.$toasted.success('Device successfully updated');
        this.showDeviceForm = false;
        this.newDevice = { device_name: '', api_key: '', register_id: '' };
        this.deviceIdToUpdate = '';
        this.fetchDevices();
      } catch (e) {
        if (e.response.status === 409) {
          this.$toasted.error('The Register ID already exists in the database');
        } else {
          this.$toasted.error('There was an error while updating the device');
        }
      } finally {
        this.savingDevice = false;
      }
    },
    async deleteDevice(deviceId) {
      this.deletingDevice = true;
      const endpoint = `/merchants/${this.merchantUUID}/hardware/${deviceId}`;

      try {
        const { data } = await api.delete(endpoint);
        this.$toasted.success(data.message);
        this.showDeleteConfirmation = false;
        this.deviceIdToDelete = '';
        this.fetchDevices();
      } catch (e) {
        this.$toasted.error('There was an error while deleting the device');
      } finally {
        this.deletingDevice = false;
      }
    },
    editDevice(device) {
      this.newDevice = {
        device_name: device.device_name,
        api_key: device.api_key,
        register_id: device.register_id,
      };
      this.showDeviceForm = true;
      this.deviceIdToUpdate = device.id;
    },
    prepareForDelete(deviceId) {
      this.deviceIdToDelete = deviceId;
      this.showDeleteConfirmation = true;
    },
  },
};
</script>

<style scoped>
.v-input--checkbox {
  margin-top: 0;
  padding-top: 0;
}

.v-input--checkbox >>> .v-label {
  color: #fff;
  font-weight: 600;
}

.device-form-buttons {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.device-form-buttons > * {
  flex: 1;
}

.device-form > label {
  font-weight: 600;
  font-size: 1rem;
  margin-block-end: 0.25em;
}

.device-form >>> .v-text-field--outlined fieldset {
  border: none;
  background: var(--primary-color);
}

.device-form >>> .v-input input {
  color: rgba(255, 255, 255, 0.87);
  caret-color: #fff;
  font-size: 1rem;
  font-weight: 600;
}

.device-form >>> .v-counter {
  color: rgba(255, 255, 255, 0.8);
}

.device-form >>> .v-input input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.devices-list > ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 21.5rem;
}

.devices-list > ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
}

.devices-list > ul li::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--primary-color);
  filter: brightness(0.5) contrast(1.5);
  z-index: -1;
  border-radius: 0.5rem;
}

.devices-list > ul li .v-btn.v-btn--icon {
  color: #fff;
}

.devices-list__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 2rem;
}

.devices-list__pagination ul {
  display: flex;
  align-items: center;
}

.devices-list__pagination > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.devices-list__pagination > div .v-btn {
  height: 2rem;
  width: 2rem;
}

.delete-confirmation {
  margin-block: 5rem;
}

.delete-confirmation p {
  text-align: center;
  font-family: 'Montserrat';
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  max-width: 23ch;
  margin-inline: auto;
  margin-block-end: 2rem;
}

.delete-confirmation > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.delete-confirmation > div > * {
  flex: 1;
}

.v-btn >>> .v-btn__content {
  gap: 0.5rem;
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0;
}

.centerer {
  display: grid;
  place-items: center;
  min-height: 25.5rem;
}

.page-button >>> .v-btn__content {
  font-weight: 500;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.62);
}

.page-button:disabled >>> .v-btn__content {
  color: #fff;
  font-weight: 700;
}
</style>
