import { Feature } from '@/features.types';
import { RouteConfig } from 'vue-router';
import Permissions from './permissions';
import settingsRoutes from './modules/settings/routes';

const HostedPaymentPageEditor = () => import('../../merchant/views/hosted-payments/HostedPaymentPageEditor.vue');
const HostedPayments = () => import('../../merchant/views/hosted-payments/HostedPayments.vue');
const HostedPaymentPagePreview = () => import('../../merchant/views/hosted-payments/HostedPaymentPagePreview.vue');
const PaymentPage = () => import('@/payments/views/payment-pages/PaymentPage.vue');
const HostedPaymentEdit = () => import('../../merchant/views/hosted-payments/HostedPaymentEdit.vue');
const HostedPaymentConnect = () => import('../../merchant/views/hosted-payments/HostedPaymentConnect.vue');

const Plans = () => import('../../merchant/views/plans/Plans.vue');
const PlanNew = () => import('../../merchant/views/plans/PlanNew.vue');
const PlanEdit = () => import('../../merchant/views/plans/PlanEdit.vue');
const PlanView = () => import('../../merchant/views/plans/PlanView.vue');

const SubscriptionNew = () => import('../../merchant/views/subscriptions/SubscriptionNew.vue');
const SubscriptionEdit = () => import('../../merchant/views/subscriptions/SubscriptionEdit.vue');
const SubscriptionView = () => import('../../merchant/views/subscriptions/SubscriptionView.vue');

const Customers = () => import('../../merchant/views/customers/Customers.vue');
const CustomerView = () => import('../../merchant/views/customers/CustomerView.vue');
const CustomerNew = () => import('../../merchant/views/customers/CustomerNew.vue');
const CustomerEdit = () => import('../../merchant/views/customers/CustomerEdit.vue');
const CustomerCharge = () => import('../../merchant/views/customers/payment-methods/CustomerCharge.vue');
const PaymentMethodNew = () => import('../../merchant/views/customers/payment-methods/PaymentMethodNew.vue');

const CustomerBatchUploads = () => import('../../merchant/views/customers/batch-uploads/CustomerBatchUploads.vue');
const CustomerBatchUploadView = () => import('../../merchant/views/customers/batch-uploads/CustomerBatchUploadView.vue');

const Products = () => import('../../merchant/views/products/Products.vue');
const ProductNew = () => import('../../merchant/views/products/ProductNew.vue');
const ProductEdit = () => import('../../merchant/views/products/ProductEdit.vue');

const Categories = () => import('../../merchant/views/categories/Categories.vue');

const Invoices = () => import('../../merchant/views/invoices/Invoices.vue');
const InvoiceNew = () => import('../../merchant/views/invoices/InvoiceNew.vue');
const InvoiceEdit = () => import('../../merchant/views/invoices/InvoiceEdit.vue');

const BatchView = () => import('../../merchant/views/batches/BatchView.vue');

export const HPP_PAYMENT_PREVIEW_ROUTE_NAME = 'merchant.hosted-payments.preview';

export const HPP_PAYMENT_PAGES_NEW_ROUTE_NAME = 'merchant.hosted-payments.new';

const ShoppingCarts = () => import('../../merchant/views/shopping-carts/ShoppingCarts.vue');

const ShoppingCartNew = () => import('../../merchant/views/shopping-carts/ShoppingCartNew.vue');

const FAQs = () => import('../../merchant/views/faqs/Faqs.vue');

const routes: RouteConfig[] = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../../merchant/views/Dashboard.vue'),
  },
  ...settingsRoutes,
  {
    path: '/frequently-asked-questions',
    name: 'faqs',
    component: FAQs,
  },
  {
    path: '/onboarding/proposal',
    name: 'merchant-proposal',
    component: () => import('../../merchant/views/MerchantProposal.vue'),
  },
  {
    path: '/virtual-terminal',
    name: 'virtual-terminal',
    component: () => import('../../merchant/views/virtual-terminal/VirtualTerminal.vue'),
  },
  {
    path: '/transactions/:id',
    name: 'transactions.view',
    component: () => import('../../merchant/views/transactions/TransactionView.vue'),
    props: true,
  },
  {
    path: '/hosted-payments',
    name: 'hosted-payments',
    component: HostedPayments,
    meta: {
      featureFlipping: { key: Feature.HPP },
    },
  },
  {
    path: '/hosted-payments/preview/:id',
    name: 'hosted-payments.preview',
    component: HostedPaymentPagePreview,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
    },
  },
  {
    path: '/hosted-payments/new',
    name: 'hosted-payments.new',
    component: HostedPaymentPageEditor,
    meta: {
      featureFlipping: { key: Feature.HPP },
    },
  },
  {
    path: '/hosted-payments/:id',
    name: 'hosted-payments.view',
    component: PaymentPage,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
    },
  },
  {
    path: '/hosted-payments/:id/edit',
    name: 'hosted-payments.edit',
    component: HostedPaymentEdit,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
    },
  },
  {
    path: '/hosted-payments/:id/connect',
    name: 'hosted-payments.connect',
    component: HostedPaymentConnect,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
    },
  },
  {
    path: '/transactions/:id/subscriptions/new',
    name: 'transactions.subscriptions.new',
    component: () => import('../../merchant/views/transactions/TransactionSubscriptionNew.vue'),
    props: true,
  },
  {
    path: '/customers',
    name: 'customers.index',
    component: Customers,
  },
  {
    path: '/customers/new',
    name: 'customers.new',
    component: CustomerNew,
  },
  {
    path: '/customers/uploads',
    name: 'customers.batch.uploads.index',
    component: CustomerBatchUploads,
  },
  {
    path: '/customers/uploads/:id',
    name: 'customers.batch.uploads.view',
    component: CustomerBatchUploadView,
    props: true,
  },
  {
    path: '/customers/:id/edit',
    name: 'customers.edit',
    component: CustomerEdit,
    props: true,
  },
  {
    path: '/customers/:id',
    name: 'customers.view',
    component: CustomerView,
    props: true,
  },
  {
    path: '/customers/:customerId/payment-methods/new',
    name: 'customers.payment-methods.new',
    component: PaymentMethodNew,
    props: true,
  },
  {
    path: '/customers/:customerId/payment-methods/:paymentMethodId/charge',
    name: 'customers.payment-methods.charge',
    component: CustomerCharge,
    props: true,
  },
  {
    path: '/plans',
    name: 'plans.index',
    component: Plans,
  },
  {
    path: '/plans/new',
    name: 'plans.new',
    component: PlanNew,
  },
  {
    path: '/plans/:id/edit',
    name: 'plans.edit',
    component: PlanEdit,
    props: true,
  },
  {
    path: '/plans/:id',
    name: 'plans.view',
    component: PlanView,
    props: true,
  },
  {
    path: '/subscriptions/new',
    name: 'subscriptions.new',
    component: SubscriptionNew,
  },
  {
    path: '/subscriptions/:id/edit',
    name: 'subscriptions.edit',
    component: SubscriptionEdit,
    props: true,
  },
  {
    path: '/subscriptions/:id',
    name: 'subscriptions.view',
    component: SubscriptionView,
    props: true,
  },
  {
    path: '/batches/:id',
    name: 'batches.view',
    component: BatchView,
    props: true,
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('../../merchant/views/users/MerchantUsers.vue'),
  },
  {
    path: '/users/new',
    name: 'users.new',
    component: () => import('../../merchant/views/users/MerchantUserNew.vue'),
    props: true,
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: () => import('../../merchant/views/users/MerchantUserEdit.vue'),
    props: true,
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: { name: 'reports.transactions.details' },
    meta: {
      permission: Permissions.VIEW_MERCHANT_REPORTS,
    },
  },
  {
    path: '/reports/transaction-details',
    name: 'reports.transactions.details',
    component: () => import('../../merchant/views/reports/TransactionDetailsReport.vue'),
    meta: {
      permission: Permissions.VIEW_MERCHANT_REPORTS,
    },
  },
  {
    path: '/reports/batch-settlements',
    name: 'reports.batch.settlements',
    component: () => import('../../merchant/views/reports/BatchSettlementsReport.vue'),
    meta: {
      permission: Permissions.VIEW_MERCHANT_REPORTS,
    },
  },
  {
    path: '/reports/recurring',
    name: 'reports.recurring',
    component: () => import('../../merchant/views/reports/RecurringReport.vue'),
    meta: {
      permission: Permissions.VIEW_MERCHANT_REPORTS,
    },
  },
  {
    path: '/reports/billing',
    name: 'reports.billing.details',
    component: () => import('../../merchant/views/reports/BillingDetailsReport.vue'),
    meta: {
      permission: Permissions.VIEW_MERCHANT_REPORTS,
    },
  },
  {
    path: '/products',
    name: 'products.index',
    component: Products,
  },
  {
    path: '/products/new',
    name: 'products.new',
    component: ProductNew,
  },
  {
    path: '/products/:id/edit',
    name: 'products.edit',
    component: ProductEdit,
    props: true,
  },
  {
    path: '/categories',
    name: 'categories.index',
    component: Categories,
  },
  {
    path: '/invoices',
    name: 'invoices.index',
    component: Invoices,
    meta: {
      permission: Permissions.MANAGE_INVOICING,
    },
  },
  {
    path: '/invoices/new',
    name: 'invoices.new',
    component: InvoiceNew,
    meta: {
      permission: Permissions.MANAGE_INVOICING,
    },
  },
  {
    path: '/invoices/:id/edit',
    name: 'invoices.edit',
    component: InvoiceEdit,
    props: true,
    meta: {
      permission: Permissions.MANAGE_INVOICING,
    },
  },
  {
    path: '/shopping-carts',
    name: 'shopping-carts',
    component: ShoppingCarts,
    meta: {
      featureFlipping: { key: Feature.SHOPPING_CARTS, defaut: false },
    },
  },
  {
    path: '/shopping-carts/new',
    name: 'shopping-carts.new',
    component: ShoppingCartNew,
    meta: {
      featureFlipping: { key: Feature.SHOPPING_CARTS, defaut: false },
    },
  },
];

export default routes;
