


















import { IsoStatus } from '@/tenants/admin/modules/affiliates/types/affiliate';
import Vue from 'vue';
import StatusCheck, { StatusType } from './StatusCheck.vue';

export default Vue.extend({
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      IsoStatus,
      setPassword: StatusType.COMPLETED,
      signedAgreement: StatusType.INCOMPLETE,
      setupInfo: StatusType.INCOMPLETE,
    };
  },
  mounted() {
    this.setStatus(this.status as IsoStatus);
  },
  watch: {
    status() {
      this.setStatus(this.status as IsoStatus);
    },
  },
  methods: {
    setStatus(status: IsoStatus) {
      if (status === IsoStatus.CREATED) {
        this.signedAgreement = StatusType.CURRENT;
      } else if (status === IsoStatus.SETUP) {
        this.signedAgreement = StatusType.COMPLETED;
        this.setupInfo = StatusType.CURRENT;
      } else if (status === IsoStatus.SIGNED) {
        this.signedAgreement = StatusType.COMPLETED;
        this.setupInfo = StatusType.COMPLETED;
      } else if (status === IsoStatus.QUALIFIED) {
        this.signedAgreement = StatusType.COMPLETED;
        this.setupInfo = StatusType.COMPLETED;
      }
    },
  },
  components: { StatusCheck },
});
