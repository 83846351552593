



















import Vue from 'vue';
import { Feature } from '@/features.types';
import {
  RateGroup, RateGroupName,
} from './sellRates.types';
import { rateGroupings } from './sellRates.util';
import SellRate from './SellRate.vue';

export default Vue.extend({
  props: {
    value: {
      type: Array as () => RateGroup[],
      required: true,
    },
    surchargeAmount: {
      type: Number,
      required: false,
    },
    isNotIllegalSurchargingState: {
      type: Boolean,
      required: false,
    },
    isThereOneEnhancedDataProcessor: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      rateGrouping: rateGroupings() as Array<Array<RateGroupName>>,
      Feature,
      updatedSurchargePercent: 0 as number,
    };
  },
  computed: {
    filteredRateGroupings(): Array<Array<RateGroupName>> {
      let finalList = [] as Array<Array<RateGroupName>>;
      if (this.isNotIllegalSurchargingState) {
        finalList = this.rateGrouping;
      } else {
        finalList = this.rateGrouping.map((group: any) => group
          .filter((rateGroup: any) => rateGroup.toLowerCase()
          !== Feature.SURCHARGING.toLowerCase()));
      }
      return finalList;
    },
    surchargeAndNonCashRateGroups(): RateGroup[] {
      const nonCashAndSurchargingRateGroups = this.value.filter((group:any) => group.name
      === RateGroupName.NON_CASH_ADJUSTMENT || group.name === RateGroupName.SURCHARGING);
      const nonCashAndOrSurchargingEnabledRateGroups = nonCashAndSurchargingRateGroups.filter(
        (rateGroup:any) => rateGroup.feature!.enabled === true,
      );
      return nonCashAndOrSurchargingEnabledRateGroups;
    },
  },
  methods: {
    getRateGrouping(group: string): RateGroup {
      return this.value.find((g) => g.name === group)!;
    },
    addSurchargePercentToRateGrouping() {
      this.value.find((g) => g.name.toLowerCase()
      === Feature.SURCHARGING.toLowerCase())!.rates.push({
        group: '',
        name: 'Surcharging Percent',
        key: 'surcharge-percent',
        value: this.surchargeAmount ?? '0.00',
        buyRate: '0.00',
      });
    },
    disabledFeature(disabledFeature: string) {
      if (disabledFeature === RateGroupName.SURCHARGING) {
        this.value.find((g) => g.name
        === RateGroupName.SURCHARGING)!.feature!.enabled = false;
      } else if (disabledFeature === RateGroupName.NON_CASH_ADJUSTMENT) {
        this.value.find((g) => g.name
        === RateGroupName.NON_CASH_ADJUSTMENT)!.feature!.enabled = false;
      }
    },
    updateSurchargePercentInRateGrouping() {
      const array = this.value.find((g) => g.name.toLowerCase()
      === Feature.SURCHARGING)!.rates;
      const surchargePercent = array.find((s) => s.key === 'surcharge-percent')?.value!;
      this.updatedSurchargePercent = surchargePercent;
    },
    updateSurchargeAmount(amount: number) {
      this.updatedSurchargePercent = amount;
      this.$emit('updateSurchargeAmount', amount);
    },
  },
  watch: {
    updatedSurchargePercent() {
      this.updateSurchargePercentInRateGrouping();
    },
  },
  created() {
    this.addSurchargePercentToRateGrouping();
  },
  components: { SellRate },
});
