<template>
  <div class="card">
    <div class="card-header">
      <h2>Sales by Type</h2>
      <a-period-selector v-model="activityPeriod" no-all-time small />
    </div>

    <m-loader-container :loading="loading" :error="error">
      <credit-cards-pie-chart :sales="salesByCreditCard" />
    </m-loader-container>
  </div>
</template>

<script>
import APeriodSelector from '@/components/atoms/a-period-selector.vue';
import MLoaderContainer from '@/components/molecules/m-loader-container.vue';
import api from '@/common/api';
import CreditCardsPieChart from './credit-cards-pie-chart.vue';

export default {
  components: { MLoaderContainer, APeriodSelector, CreditCardsPieChart },
  data() {
    return {
      activityPeriod: 'thisMonth',
      data: null,
      loading: true,
      error: false,
    };
  },
  computed: {
    salesByCreditCard() {
      if (this.data === null) return {};

      const countWithoutTotal = Object
        .entries(this.data)
        .filter((val) => val[0] !== 'total')
        .reduce((acc, val) => acc + val[1], 0);

      return {
        visa: this.data.visa,
        americanExpress: this.data.americanExpress,
        mastercard: this.data.mastercard,
        discover: this.data.discover,
        ach: this.data.ach,
        other: this.data.total - countWithoutTotal,
      };
    },
  },
  watch: {
    activityPeriod: {
      handler() {
        this.loadCreditCardBrandCounts();
      },
      immediate: true,
    },
  },
  methods: {
    loadCreditCardBrandCounts() {
      this.loading = true;
      const url = '/dashboard/merchant/credit-card-brand-counts';
      const params = { periodData: this.activityPeriod };

      api.get(url, { params })
        .then(({ data }) => { this.data = data.data; })
        .catch(() => { this.error = true; })
        .finally(() => { this.loading = false; });
    },
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  gap: 10%;
}

h2 {
  margin: 0;
}
</style>
