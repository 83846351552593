






































































































































































import Vue from 'vue';
import { PaymentType } from '@/common/types/payments.types';
import { Transaction, TransactionStatus } from '@/common/types/transactions.types';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    transaction: {
      type: Object as () => Transaction,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isSurchargingEnabled']),
    hasBillingAddress(): boolean {
      return (
        this.transaction !== null
        && this.transaction.customerDetails !== null
        && this.transaction.customerDetails?.billingAddress !== null
        && this.transaction.customerDetails?.billingAddress !== undefined
      );
    },
    hasShippingAddress(): boolean {
      return (
        this.transaction !== null
        && this.transaction.customerDetails !== null
        && this.transaction.customerDetails?.shippingAddress !== null
        && this.transaction.customerDetails?.shippingAddress !== undefined
      );
    },
    expirationDate(): string {
      if (this.transaction!.paymentMethod!.type !== PaymentType.CREDIT) {
        return '';
      }

      const { expMonth: month, expYear: year } = this.transaction!.paymentMethod!.creditCard!;
      return `Exp: ${month}/${year}`;
    },
    transactionTypeIcon(): string {
      if (this.transaction!.paymentMethod!.type === PaymentType.CASH) {
        return 'money-bill-alt';
      }
      if (this.transaction!.paymentMethod!.type === PaymentType.ACH) {
        return 'money-check';
      }
      if (this.transaction!.cardType! !== '') {
        return this.transaction!.cardType!;
      }
      return 'credit-card';
    },
    isCustomerInVault(): boolean {
      const customerDetails = this.transaction?.customerDetails;
      return customerDetails !== undefined && customerDetails !== null && customerDetails.inVault;
    },
    isForSubscription(): boolean {
      const subscriptionId = this.transaction?.subscriptionId;
      return subscriptionId !== undefined && subscriptionId !== null && subscriptionId !== '';
    },
    transactionType(): string {
      if (this.transaction!.paymentMethod!.type === PaymentType.CASH) {
        return 'Cash';
      }
      if (this.transaction!.paymentMethod!.type === PaymentType.ACH) {
        return 'ACH';
      }
      return 'Credit';
    },
    statusColor(): string {
      switch (this.transaction!.status) {
        case TransactionStatus.DECLINED:
          return 'tw-text-red-600';
        default:
          return '';
      }
    },
    isDeclined(): boolean {
      return this.transaction!.status === TransactionStatus.DECLINED;
    },
    isSurchargeTransaction(): boolean {
      return this.transaction.surchargeAmount !== 0
      && this.transactionType.toLowerCase() === PaymentType.CREDIT;
    },
    isNonCashAdjustmentTransaction(): boolean {
      if (this.transaction.transactionSource) {
        return true;
      } return false;
    },
  },
});
