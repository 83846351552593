import { RouteConfig } from 'vue-router';
import Permissions from '../../permissions';

const routes: RouteConfig[] = [
  {
    path: '/default-buy-rates',
    name: 'buy-rates',
    component: () => import('./pages/BuyRates.vue'),
    meta: {
      permission: Permissions.EDIT_DEFAULT_BUY_RATES,
    },
  },
];

export default routes;
