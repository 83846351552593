import { RouteConfig } from 'vue-router';
import Permissions from './permissions';
import affiliatesRoutes from './modules/affiliates/routes';
import buyRatesRoutes from './modules/buy-rates/routes';

const routes: RouteConfig[] = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../../admin/views/Dashboard.vue'),
  },
  ...affiliatesRoutes,
  ...buyRatesRoutes,
  {
    path: '/merchants',
    name: 'merchants.index',
    component: () => import('../../admin/views/merchants/MerchantsIndex.vue'),
  },
  {
    path: '/merchants/:id',
    name: 'merchants.edit',
    component: () => import('@/iso/views/merchants/MerchantEdit.vue'),
    props: true,
  },
  {
    name: 'merchants.new',
    path: '/merchants/new',
    component: () => import('@/iso/views/merchants/MerchantNew.vue'),
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('../../admin/views/users/AdminUsers.vue'),
  },
  {
    path: '/users/new',
    name: 'users.new',
    component: () => import('../../admin/views/users/AdminUserNew.vue'),
    props: true,
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    props: true,
    component: () => import('../../admin/views/users/AdminUserEdit.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: { name: 'reports.commission' },
  },
  {
    path: '/reports/commission',
    name: 'reports.commission',
    component: () => import('../../admin/views/reports/CommissionReport.vue'),
  },
  {
    path: '/reports/transaction',
    name: 'reports.transaction',
    component: () => import('../affiliate/modules/reports/pages/transaction-report/TransactionReport.vue'),
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../../admin/views/faqs/FaqsIndex.vue'),
  },
  {
    path: '/faqs/new',
    name: 'faqs.new',
    component: () => import('../../admin/views/faqs/FaqForm.vue'),
    meta: {
      permission: Permissions.MANAGE_FAQ,
    },
  },
  {
    path: '/faqs/:id',
    name: 'faqs.edit',
    component: () => import('../../admin/views/faqs/FaqForm.vue'),
    props: true,
    meta: {
      permission: Permissions.MANAGE_FAQ,
    },
  },
  {
    path: '/settings/maintenance',
    name: 'settings.maintenance',
    component: () => import('../../admin/views/maintenance/MaintenanceEdit.vue'),
    meta: {
      permission: Permissions.EDIT_MAINTENANCE_MESSAGE,
    },
  },
];

export default routes;
