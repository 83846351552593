
































































import Vue from 'vue';
import api, { setHeader } from '@/common/api';
import { toFloat, toInt } from '@/common/mappers/sell-rate-value-mapper';
import PageLoader from '@/common/components/PageLoader.vue';
import PContainer from '@/components/presentations/p-container.vue';
import MerchantContact from '@/common/components/forms/Contact.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import { mapState } from 'vuex';
import MerchantBanking from './components/Banking.vue';
import MerchantCardTypes from './components/CardTypes.vue';
import AuthNetEmulatorCard from './components/AuthNetEmulatorCard.vue';
import MerchantDetails from './components/Details.vue';
import MerchantSellRates from './components/SellRates.vue';
import MerchantSettings from './components/MerchantSettings.vue';
import { initMerchant, Merchant, SellRate } from './merchants.types';
import { RateGroup } from './components/sellRates.types';
import { createRateGroups, fillMerchantWithSellRates } from './components/sellRates.util';
import MerchantProcessors from './components/MerchantProcessors.vue';
import { surchargePercentKey } from './MerchantEdit.vue';

export default Vue.extend({
  data() {
    return {
      title: 'Create a merchant' as string,
      merchant: initMerchant() as Merchant,
      rateGroups: [] as RateGroup[],
      isSubmitting: false as boolean,
      showErrorMsg: false as boolean,
      id: '' as string,
      contactState: '' as string,
    };
  },
  created() {
    if (this.context === 'admin') {
      setHeader('x-iso-proxy', 'aurora');
    }
  },
  mounted() {
    this.loadBuyRates();
  },
  destroyed() {
    if (this.context === 'admin') {
      setHeader('x-iso-proxy', undefined);
    }
  },
  methods: {
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    loadBuyRates() {
      api
        .get('buy-rates')
        .then(this.setSellRates)
        .catch((this.$refs.loader as any).setError);
    },
    setSellRates({ data }: { data: any }) {
      const rates: SellRate[] = data.data;
      this.merchant.sellRates = toFloat(rates);
      this.rateGroups = createRateGroups(this.merchant.sellRates, this.merchant.features);
      (this.$refs.loader as any).setReady();
    },
    save() {
      if (!this.isValid()) {
        this.showErrorMsg = true;
        return;
      }
      this.showErrorMsg = false;
      this.submitActions(true);
      const originalRateGroups = this.rateGroups.map((rateGroup) => ({
        ...rateGroup,
        rates: rateGroup.rates.filter((rate) => rate.key !== surchargePercentKey),
      }));

      const merchant: Merchant = fillMerchantWithSellRates(this.merchant, originalRateGroups);
      merchant.settings.maxTicketAmount = Math.round(merchant.settings.maxTicketAmount * 100);
      merchant.settings.maxMonthlyVolume = Math.round(merchant.settings.maxMonthlyVolume * 100);

      api
        .post('merchants', {
          ...merchant,
          sellRates: toInt(merchant.sellRates),
        })
        .then(({ data }: { data: JsonApiSingleResponse<Merchant> }) => {
          this.showSuccess(data);
        })
        .catch(this.handleSaveError);
    },
    showSuccess(data: any) {
      this.submitActions(false);
      this.$toasted.success('Merchant created successfully!');
      this.$router.replace({ name: `${this.context}.merchants.edit`, params: { id: data.data.id } });
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      if (response.status === 422) {
        const errors = response?.data?.errors;
        let msg: any = '';
        Object.keys(errors).forEach((key: any, idx: any) => {
          if (idx > 0) msg += '<br />';
          msg += `${errors[key]}`;
        });
        this.$toasted.error(msg);
      } else {
        const message = this.$errorMessages.get('merchants.create');
        this.$toasted.error(`${message} (${response.status})`);
      }
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    changeContactState(state: any) {
      this.contactState = state;
    },
  },
  computed: {
    ...mapState(['context']),
    isNotIllegalSurchargingState(): boolean {
      return !['CT', 'MA'].includes(this.contactState);
    },
  },
  components: {
    MerchantBanking,
    MerchantDetails,
    MerchantContact,
    MerchantSellRates,
    MerchantSettings,
    PageLoader,
    LoadingModal,
    MerchantProcessors,
    MerchantCardTypes,
    AuthNetEmulatorCard,
    PContainer,
  },
});
