




import Vue from 'vue';
import AvsSettings from '@/common/components/support/AvsSettings.vue';

export default Vue.extend({
  components: {
    AvsSettings,
  },
});
