var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isCustomerVaultEnabled)?_c('PaidFeatureNotEnabled',{attrs:{"header":_vm.config.header,"feature":"Customer Vault"}}):_c('EntitiesTable',{attrs:{"config":_vm.config,"showDownloadCsvButton":false,"showActions":"","showViewButton":"","showEditButton":"","showSearch":"","showAddButton":"","showCustomButton":"","customButtonText":"Batch Import"},scopedSlots:_vm._u([{key:"item.billingAddress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billingAddress.company || ("" + (((item.billingAddress.firstName || '') + " " + (item.billingAddress.lastName || '')).trim())))+" ")]}},{key:"item.paymentMethodsCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"justify-center align-center d-flex",class:_vm.getPaymentMethodClass(item),staticStyle:{"height":"48px"}},[_vm._v(" "+_vm._s(item.paymentMethodsCount)+" ")])]}},{key:"item.billingAddress.email",fn:function(ref){
var item = ref.item;
return [(item.billingAddress && item.billingAddress.email)?_c('a',{attrs:{"href":("mailto:" + (item.billingAddress.email))}},[_vm._v(" "+_vm._s(item.billingAddress.email)+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeShort")(item.createdAt))+" ")]}},{key:"customModal",fn:function(slotProps){return [_c('CustomerBatchUploadPrompt',{attrs:{"closeFunction":slotProps.closeFunction}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }