











































































































































import Vue, { PropType } from 'vue';
import { mask } from 'vue-the-mask';
import CountriesSelect from '@/common/components/CountriesSelect.vue';
import StatesSelect from '@/common/components/StatesSelect.vue';
import { Contact } from '@/tenants/admin/modules/affiliates/types/affiliate';
import APhoneNumberForm from '@/components/atoms/a-phone-number-form.vue';
import PCard from '@/components/presentations/p-card.vue';

export default Vue.extend({
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    isoEditOrMerchant: {
      type: Boolean,
      required: false,
      default: false,
    },
    showUsername: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    changeState() {
      this.$emit('changeContactState', this.contact.state);
    },
  },
  directives: {
    mask: {
      bind(el, binding, vnode, oldVnode) {
        if (binding.value) {
          mask(el, binding, vnode, oldVnode);
        }
      },
    },
  },
  components: {
    CountriesSelect,
    StatesSelect,
    APhoneNumberForm,
    PCard,
  },
});
