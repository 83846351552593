<template>
  <div
    class="riseos-card"
    :class="{ 'dark-mode': darkMode, 'background-primary': primary }"
  >
    <div v-if="$slots.header" class="riseos-card__header">
      <slot name="header" />
    </div>

    <div class="riseos-card__body">
      <slot />
    </div>

    <div v-if="$slots.footer" class="riseos-card__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    primary: { type: Boolean },
  },
  computed: {
    ...mapState(['darkMode']),
  },
};
</script>

<style scoped>
.riseos-card {
  border-radius: var(--card-border-radius, 0.5rem);
  background: var(--card-background, #FFF);
  padding: var(--card-padding, 2rem 1.25rem 2.5rem 1.25rem);
}

.riseos-card.background-primary {
  --card-header-color: #fff;

  color: #fff;
  position: relative;
  z-index: 1;
}

.riseos-card.background-primary::before {
  content: '';
  position: absolute;
  z-index: -1;
  background: var(--primary-color);
  inset: 0;
  border-radius: var(--card-border-radius, 0.5rem);
  filter: brightness(0.7);
}

.riseos-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: var(--card-header-margin-block-end, 1.25rem);
}

.riseos-card__header h1,
.riseos-card__header h2,
.riseos-card__header h3 {
  font-size: 1.125rem;
  font-weight: var(--card-header-weight, 600);
  line-height: 1.222;
  color: var(--card-header-color, #333);
  margin: 0;
}

.riseos-card__footer {
  margin-block-start: 2rem;
}

.riseos-card.dark-mode {
  --card-background: #0D0D0D;
  --card-header-color: #ccc;
}
</style>
