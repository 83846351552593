enum Permissions {
  MANAGE_INVOICING = 'manage-invoicing',
  MANAGE_DESIGN = 'manage-design',
  ADD_USER = 'add-user',
  EDIT_USER = 'edit-user',
  MANAGE_SETTINGS = 'manage-settings',
  DELETE_USER = 'delete-user',
  RUN_CREDIT_CARD_AUTH = 'run-credit-card-auth',
  RUN_CREDIT_CARD_REFUND_VOID = 'run-credit-card-refund-void',
  RUN_ACH_SALE = 'run-ach-sale',
  RUN_ACH_REFUND_VOID = 'run-ach-refund-void',
  RUN_CASH_SALE = 'run-cash-sale',
  RUN_CASH_REFUND_VOID = 'run-cash-refund-void',
  VIEW_TRANSACTIONS = 'view-transactions',
  VIEW_MERCHANT_REPORTS = 'view-merchant-reports',
  RUN_CREDIT_CARD_SALE = 'run-credit-card-sale',
}

export default Permissions;
