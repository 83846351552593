<template>
  <p-container>
    <h1>Add New User</h1>

    <div class="main-content">
      <UserDetailsForm :user="user" :errors="validationErrors" password />

      <div>
        <UserPermissionsForm
          :permissions="user.permissions"
          :userType="userType"
          @changed="setPermissions"
        />
        <merchant-location-permissions
          v-if="userType === UserType.MERCHANT"
          v-model="user.locations"
        />
      </div>
    </div>

    <v-btn
      color="primary"
      large
      @click="save"
      style="display: block; margin-left: auto;"
      :disabled="saving"
      :loading="saving"
    >
      <v-icon left>mdi-content-save</v-icon>
      Save Changes
    </v-btn>
  </p-container>
</template>

<script>
import api from '@/common/api';
import PContainer from '@/components/presentations/p-container.vue';
import UserDetailsForm from '@/common/components/users/UserDetailsForm.vue';
import UserPermissionsForm from '@/common/components/users/UserPermissionsForm.vue';
import { initUser, UserType } from '@/common/components/users/users.types';
import MerchantLocationPermissions from './MerchantLocationPermissions.vue';

export default {
  props: {
    userType: { type: String, required: true },
    userPermissions: { type: Array, required: false },
  },
  data() {
    return {
      user: initUser(true),
      validationErrors: {},
      saving: false,
      UserType,
    };
  },
  mounted() {
    if (this?.userPermissions) this.setPermissions(this?.userPermissions);
  },
  methods: {
    setPermissions(updatedPermissions) {
      this.user.permissions = updatedPermissions;
    },
    async save() {
      this.saving = true;
      try {
        const response = await api.post('users', this.user);
        this.$toasted.success('User created successfully!');
        this.$router.push({
          name: `${this.userType}.users.edit`,
          params: { id: response.data.data.id },
        });
      } catch (e) {
        const message = this.$errorMessages.get('users.create');
        this.$toasted.error(`${message} (${e.response.status})`);
        this.validationErrors = e.response.data.errors || {};
      } finally {
        this.saving = false;
      }
    },
  },
  components: {
    PContainer,
    UserDetailsForm,
    UserPermissionsForm,
    MerchantLocationPermissions,
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.375rem;
  font-weight: 600;
  margin-block-start: 1rem;
}

.main-content {
  --card-header-weight: 500;

  margin-block: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.main-content > *:first-child {
  flex: 1 1 13rem;
}

.main-content > *:last-child {
  flex: 1 1 28rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
</style>
