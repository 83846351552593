<template>
<div>
  <PaidFeatureNotEnabled
    v-if="!isInvoicingEnabled"
    header="Invoices"
    feature="Invoicing"
  />
  <div v-else>
    <a-back-link to="settings">Back to Settings</a-back-link>

    <v-card class="invoice-settings-card">
      <v-card-title class="settings-title">Invoice Settings</v-card-title>

      <v-list>
        <v-list-item :to="{ name: 'merchant.settings.upload-logo' }" class="settings-item">
          <v-list-item-icon>
            <v-icon size="16">mdi-image-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logo</v-list-item-title>
          <v-list-item-icon>
            <v-icon size="16">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item class="settings-item">
          <v-list-item-icon>
            <v-icon size="16">mdi-alarm</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="reminders-title">
              <span :class="{ 'font-weight-bold': !reminders }">
                Disable reminders
              </span>
              <v-switch
                v-model="reminders"
                :loading="savingReminders"
                :disabled="savingReminders"
                dense
                hide-details
                inset
              />
              <span :class="{ 'font-weight-bold': reminders }">
                Enable reminders
              </span>
            </v-list-item-title>

            <div class="disabled-reminders" v-if="!reminders">
              No reminders will be emailed to the customer, only the initial invoice sent via email/SMS
            </div>
            <div class="active-reminders" v-if="reminders">
              <div class="forms-container">
                <strong>Automatic Reminders:</strong>

                <v-radio-group v-model="activeReminder" dense hide-details>
                  <v-radio value="before" @mousedown="() => toggleRadio('before')">
                    <template #label>
                      Remind customer
                      <v-text-field
                        :value="activeReminder === 'before' ? daysReminder : ''"
                        @input="onDaysReminderInput"
                        type="number"
                        dense
                        hide-details
                        solo
                      />
                      days before due date.
                    </template>
                  </v-radio>
                  <v-radio value="every" @mousedown="() => toggleRadio('every')">
                    <template #label>
                      Remind customer every
                      <v-text-field
                        :value="activeReminder === 'upTo' ? daysReminder : ''"
                        @input="onDaysReminderInput"
                        type="number"
                        dense
                        hide-details
                        solo
                      />
                      days up to due date.
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-checkbox v-model="afterReminder" dense hide-details>
                  <template #label>
                    Remind customer every
                    <v-text-field
                      v-model="afterReminderDays"
                      type="number"
                      dense
                      hide-details
                      solo
                    />
                    days that payment is past due.
                  </template>
                </v-checkbox>
              </div>

              <v-btn
                color="primary"
                :disabled="invalidForm"
                @click="saveReminders"
              >
                SAVE
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'merchant.settings.invoice-settings.reply-to'}" class="settings-item">
          <v-list-item-icon>
            <v-icon size="16">mdi-card-account-details</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Invoice Contact Info</v-list-item-title>
          <v-list-item-icon>
            <v-icon size="16">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ABackLink from '@/components/atoms/a-back-link.vue';
import invoiceSettings from '@/merchant/settings/invoice-settings';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import api from '@/common/api';

export default {
  created() {
    document.title = 'RiseOS-Invoice Settings';
  },
  data() {
    return {
      reminders: false,
      activeReminder: '',
      daysReminder: '',
      afterReminder: false,
      afterReminderDays: '',
      savingReminders: false,
    };
  },
  computed: {
    ...mapGetters(['isInvoicingEnabled', 'branding']),
    settingRows() {
      return invoiceSettings;
    },
    invalidForm() {
      if (this.activeReminder && !this.afterReminder) {
        return this.savingReminders || !this.daysReminder;
      }
      if (this.afterReminder && !this.activeReminder) {
        return this.savingReminders || !this.afterReminderDays;
      }
      if (this.activeReminder && this.afterReminder) {
        return this.savingReminders || !this.daysReminder || !this.afterReminderDays;
      }

      return true;
    },
  },
  watch: {
    reminders(val) {
      if (!val) this.saveReminders();
    },
  },
  methods: {
    onDaysReminderInput(value) {
      if (Number.isInteger(+value)) {
        this.daysReminder = +value;
      }
    },
    toggleRadio(value) {
      if (value === this.activeReminder) {
        setTimeout(() => {
          this.activeReminder = '';
        }, 100);
      }
    },
    async saveReminders() {
      if (this.reminders) {
        if (this.activeReminder) {
          await this.sendBeforeOrEveryReminderRequest();
        }
        if (this.afterReminder) {
          this.sendAfterReminderRequest(!!this.activeReminder);
        }
      } else {
        this.sendDisableRemindersRequest();
      }
    },
    async sendBeforeOrEveryReminderRequest() {
      this.savingReminders = true;
      const data = {
        active: this.reminders,
        merchant_id: this.branding.merchantId,
        send_before: this.activeReminder === 'before',
        send_every: this.activeReminder === 'every',
        send_after: false,
        days_reminder: this.daysReminder,
        reminder_message: '',
      };

      try {
        await api.post('/merchant/reminder/create_multiple', data);
        this.$toasted.success('Before Reminder was successfully enabled.');
      } catch (_) {
        const message = this.$errorMessages.get('settings.reminder');
        this.$toasted.error(`${message}`);
      } finally {
        this.savingReminders = false;
      }
    },
    sendAfterReminderRequest(paired) {
      this.savingReminders = true;
      const data = {
        active: this.reminders,
        merchant_id: this.branding.merchantId,
        send_before: false,
        send_every: false,
        send_after: true,
        days_reminder: this.afterReminderDays,
        reminder_message: '',
      };

      if (paired) {
        data.paired = true;
      }

      api.post('/merchant/reminder/create_multiple', data)
        .then(() => {
          this.$toasted.success('After Reminder was successfully enabled.');
        })
        .catch(() => {
          const message = this.$errorMessages.get('settings.reminder');
          this.$toasted.error(`${message}`);
        })
        .finally(() => {
          this.savingReminders = false;
        });
    },
    sendDisableRemindersRequest() {
      this.savingReminders = true;
      const data = { active: false, merchant_id: this.branding.merchantId };

      api.post('/merchant/reminder/create_multiple', data)
        .then(() => {
          this.$toasted.success('Reminders were successfully disabled.');
        })
        .catch(() => {
          const message = this.$errorMessages.get('settings.reminder');
          this.$toasted.error(`${message}`);
        })
        .finally(() => {
          this.savingReminders = false;
        });
    },
  },
  components: {
    PaidFeatureNotEnabled,
    ABackLink,
  },
};
</script>

<style scoped>
.v-card.invoice-settings-card {
  box-shadow: 0px 10px 30px rgba(126, 135, 140, 0.1);
  max-width: 32rem;
}

.v-card .settings-title {
  font-size: 1.125rem;
  font-weight: 600;
  padding-inline-start: 1.5rem;
}

.v-card .settings-item {
  padding-inline-start: 1.5rem;
}

.v-card .settings-item:hover {
  text-decoration: none;
}

.v-card .settings-item .v-list-item__content {
  overflow: visible;
}

.v-card .settings-item .v-list-item__icon {
  margin-inline-end: 1.25rem;
  min-width: 16px;
}

.reminders-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  overflow: visible;
  margin-block-end: 1rem;
}

.reminders-title span:last-child {
  margin-inline-start: -16px;
}

.reminders-title .v-input {
  margin: 0;
  padding: 0;
}

.disabled-reminders {
  max-width: 40ch;
  font-size: 0.875rem;
}

.active-reminders {
  margin-inline-start: calc(-16px - 1.25rem);
}

.active-reminders .forms-container {
  position: relative;
  padding: 1rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  margin-block-end: 2rem;
}

.active-reminders .forms-container::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.25rem;
  background: var(--primary-color);
  opacity: 0.07;
}

.active-reminders .v-input {
  flex: 0;
}

.active-reminders .v-radio >>> .v-input__control,
.active-reminders .v-input--checkbox >>> .v-label .v-input__control {
  min-height: 30px !important;
  margin-inline: 0.25rem;
}

.active-reminders .v-input--checkbox {
  margin-top: 4px;
}

.active-reminders .v-radio >>> .v-input__control .v-input__slot,
.active-reminders .v-input--checkbox >>> .v-label .v-input__control .v-input__slot {
  padding: 0 0.25rem !important;
  box-shadow: none !important;
  border: 1px solid #cccccc;
}

.active-reminders >>> .v-input__control input {
  width: 2ch;
}

.active-reminders >>> .v-input__control input::-webkit-outer-spin-button,
.active-reminders >>> .v-input__control input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.active-reminders >>> .v-input__control input[type=number] {
  -moz-appearance: textfield;
}
</style>
