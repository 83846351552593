<template>
  <v-dialog v-model="showDialog" max-width="400" :persistent="isSubmitting">
    <template #activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        Change Password
      </v-btn>
    </template>

    <v-form ref="form" v-model="isValid" @submit.prevent="resetPassword">
      <v-card>
        <v-card-title>
          <span>
            Change password for &nbsp;
            <strong>{{ user.username }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="password.password"
            label="New Password"
            type="password"
            :rules="[
              (v) => !!v || 'New password is required',
              (v) => v && v.length >= 8 || 'New password must be at least 8 characters'
            ]"
            :error-messages="validationErrors.password"
            :disabled="isSubmitting"
            dense
            outlined
          />

          <v-text-field
            v-model="password.password_confirmation"
            label="Confirm New Password"
            type="password"
            :rules="[
              (v) => !!v || 'New password confirmation is required',
              (v) => v === password.password || 'Password confirmation must match'
            ]"
            :error-messages="validationErrors.password_confirmation"
            :disabled="isSubmitting"
            dense
            outlined
          />

          <ul class="password-conditions">
            <li
              class="password-conditions__condition"
              :class="{ ok: password.password && password.password.length >= 8 }"
            >
              <small>Passwords must be at least 8 characters long</small>
              <v-icon
                v-if="password.password && password.password.length >= 8"
                x-small
              >
                mdi-check
              </v-icon>
              <v-icon
                v-if="password.password_confirmation && password.password_confirmation.length >= 8"
                x-small
              >
                mdi-check
              </v-icon>
            </li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            type="submit"
            color="secondary"
            :disabled="!isValid || isSubmitting"
            :loading="isSubmitting"
          >
            Reset Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import api from '@/common/api';

export default {
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      password: {
        password: '',
        password_confirmation: '',
      },

      validationErrors: {},
      isValid: true,
      isSubmitting: false,
      showDialog: false,
    };
  },
  methods: {
    async resetPassword() {
      this.isSubmitting = true;

      try {
        await api.patch(`users/${this.user.id}`, this.password);
        this.$toasted.success('Password reset successfully');
        this.$refs.form.reset();
        this.showDialog = false;
      } catch (e) {
        this.$toasted.error('There was a problem while resetting the password.');
        this.validationErrors = e.response.data.error || {};
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style scoped>
.password-conditions {
  list-style: disc;
  padding-inline-start: 1rem !important;
}

.password-conditions__condition.ok,
.password-conditions__condition.ok .v-icon {
  color: #0b540b;
  font-weight: 500;
}
</style>
