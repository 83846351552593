



















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import getSubdomain from '@/common/context';
import { VirtualTerminalMerchant, MerchantStatus } from '@/iso/views/merchants/merchants.types';
import { isEnabled } from 'vue-feature-flipping';
import { Feature } from '@/features.types';
import api from '@/common/api';
import PaymentDetailsModal from '@/common/components/payments/PaymentDetailsModal.vue';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import { initValidationErrors } from '@/common/validation/validation.types';
import {
  reformatCustomFields,
  initPaymentMethod,
  initRecurringPayment,
} from '@/common/util/payments.util';
import { initCustomer } from '@/common/util/customers.util';
import { Transaction } from '@/common/types/transactions.types';
import { Subscription } from '@/common/types/subscriptions.types';
import {
  CustomField,
  CustomFieldFillable,
  CustomFieldType,
  DefaultLevel3Setting,
  initDefaultLevel3Setting,
  InfoField,
} from '@/common/components/support/support.types';
import {
  initVirtualTerminalTransaction,
  initCustomFields,
  initInfoFields,
} from '@/common/types/virtualTerminal.types';
import {
  fillTransactionWithCustomer,
  fillTransactionWithPaymentMethod,
} from '@/common/util/virtualTerminal.util';
import { PaymentType } from '@/common/types/payments.types';

import RestrictedAccountModal from '@/common/components/modals/RestrictedAccountModal.vue';
import { ProcessorType } from '@/common/types/processors.types';
import CustomerVaultDropdown from '@/iso/views/merchants/components/CustomerVaultDropdown.vue';
import CustomerPaymentForm from '../components/customer-payment/CustomerPaymentForm.vue';
import {
  CustomerPaymentAutomation,
  CustomerPaymentConfigWithCustomFields,
} from '../components/customer-payment/customerPayments.types';


export default Vue.extend({
  data() {
    return {
      Feature,
      config: {
        processors: [],
        customFields: initCustomFields(),
        transaction: initVirtualTerminalTransaction(),
        customer: initCustomer(),
        paymentMethod: initPaymentMethod(),
        recurrence: initRecurringPayment(),
        showAddToVault: true,
        isRecurring: false,
        validationErrors: initValidationErrors(),
        isSubmitting: false,
        paymentValidationPrefix: 'paymentMethod.',
        creditCardValidationPrefix: 'paymentMethod.creditCard.',
        achValidationPrefix: 'paymentMethod.ach.',
        customerBillingValidationPrefix: 'customer.billingAddress.',
        customerShippingValidationPrefix: 'customer.shippingAddress.',
        enhancedFields: [] as Array<CustomFieldFillable>,
        defaultLevel3Setting: initDefaultLevel3Setting() as DefaultLevel3Setting,
        infoFields: initInfoFields(),
      } as CustomerPaymentConfigWithCustomFields,
      loadingText: 'Processing...' as string,
      CustomerPaymentAutomation,
      txnResponse: null as JsonApiSingleResponse<any> | null,
      virtualTerminalMerchant: {} as VirtualTerminalMerchant,
      calculatedSurchargeFee: 0 as number,
      showPaymentTypeErrorModal: false as boolean,
      showAccountModal: false as boolean,
      currentProcessor: {} as any,
      resetAmount: false as boolean,
      isLevel3Transaction: false as boolean,
      customerVaultKey: 0,
    };
  },
  created() {
    document.title = 'RiseOS-Virtual Terminal';
  },
  mounted() {
    this.getCustomerInfoFields();
    this.getCustomFields();
    this.loadVirtualTerminalMerchant();
    this.getLevel3Fields();
  },
  computed: {
    ...mapGetters([
      'isCustomerElectronicCheckEnabled',
      'isCustomerCashEnabled',
      'isEnhancedDataEnabled',
    ]),
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    showCustomFields(): boolean {
      return isEnabled(Feature.CUSTOM_FIELDS);
    },
  },
  methods: {
    onSelectCustomerFromVault(customer: any) {
      this.config.showAddToVault = !customer;

      this.config.customer.billingAddress.address1 = customer?.billingAddress?.address1 || '';
      this.config.customer.billingAddress.address2 = customer?.billingAddress?.address2 || '';
      this.config.customer.billingAddress.city = customer?.billingAddress?.city || '';
      this.config.customer.billingAddress.company = customer?.billingAddress?.company || '';
      this.config.customer.billingAddress.country = customer?.billingAddress?.country || '';
      this.config.customer.billingAddress.email = customer?.billingAddress?.email || '';
      this.config.customer.billingAddress.firstName = customer?.billingAddress?.firstName || '';
      this.config.customer.billingAddress.lastName = customer?.billingAddress?.lastName || '';
      this.config.customer.billingAddress.phone = customer?.billingAddress?.phone || '';
      this.config.customer.billingAddress.state = customer?.billingAddress?.state || '';
      this.config.customer.billingAddress.zip = customer?.billingAddress?.zip || '';
    },
    onSelectPaymentMethodFromVault(paymentMethod: any) {
      if (paymentMethod !== undefined) {
        this.config.paymentMethod.processorId = paymentMethod.processorId;
        this.config.paymentMethod.type = paymentMethod.type;
      }

      if (this.config.paymentMethod.creditCard) {
        this.config.paymentMethod.creditCard.cardNumber = paymentMethod?.creditCard?.cardNumber || '';
        this.config.paymentMethod.creditCard.expMonth = paymentMethod?.creditCard?.expMonth || 1;
        this.config.paymentMethod.creditCard.expYear = paymentMethod?.creditCard?.expYear || 2022;
      }
    },
    loadVirtualTerminalMerchant() {
      api.get(`merchants/${getSubdomain()}/virtual-terminal`).then(this.setVirtualTerminalMerchant);
    },
    setVirtualTerminalMerchant({ data }: { data: JsonApiSingleResponse<VirtualTerminalMerchant> }) {
      const virtualTerminalMerchant: VirtualTerminalMerchant = data.data!;
      this.virtualTerminalMerchant = virtualTerminalMerchant;

      if (this.config.paymentMethod.ach) {
        this.config.paymentMethod.ach.confirmed = virtualTerminalMerchant.confirmed || false;
      }
      if (this.virtualTerminalMerchant?.status === MerchantStatus.RESTRICTED) {
        this.showAccountModal = true;
      }
    },
    isAchConfirmed() {
      if (this.config.paymentMethod.ach) {
        return this.config.paymentMethod.type === PaymentType.ACH
          && !this.config.paymentMethod.ach.confirmed;
      }
      return false;
    },
    getCustomerInfoFields() {
      api
        .get('/info-fields', {
          params: {
            virtualTerminal: true,
          },
        })
        .then(this.assignCustomerInfoFields);
    },
    assignCustomerInfoFields({ data }: { data: JsonApiArrayResponse<InfoField> }) {
      this.config.infoFields = data.data!.map((infoField) => ({
        ...infoField,
        value: '',
      }));
    },
    getCustomFields() {
      api
        .get('/custom-fields', {
          params: {
            virtualTerminal: true,
          },
        })
        .then(this.assignCustomFields);
    },
    assignCustomFields({ data }: { data: JsonApiArrayResponse<CustomField> }) {
      this.config.customFields = data.data!.map((customField) => ({
        ...customField,
        value: '',
      }));
    },
    getLevel3Fields() {
      api
        .get('/level-3-settings')
        .then((response:any) => {
          if (typeof response.data.data !== 'undefined') {
            this.config.defaultLevel3Setting = response.data.data!;
            this.initDefaultLevel3Values();
          }
        });
    },
    initDefaultLevel3Values() {
      if (this.isEnhancedDataEnabled && typeof this.config.defaultLevel3Setting !== 'undefined') {
        this.config.defaultLevel3Setting.shippingAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.shippingAmount,
        );
        this.config.defaultLevel3Setting.salesTaxAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.salesTaxAmount,
        );
        this.config.defaultLevel3Setting.salesTaxRate = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.salesTaxRate,
        );
        this.config.defaultLevel3Setting.discountAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.discountAmount,
        );
        this.config.defaultLevel3Setting.nationalTaxAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.nationalTaxAmount,
        );
        this.config.defaultLevel3Setting.nationalTaxRate = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.nationalTaxRate,
        );
        this.config.defaultLevel3Setting.duty = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.duty,
        );
        this.config.defaultLevel3Setting.vatTaxAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.vatTaxAmount,
        );
        this.config.defaultLevel3Setting.productCost = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.productCost,
        );
        this.config.defaultLevel3Setting.productTaxAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.productTaxAmount,
        );
        this.config.defaultLevel3Setting.productTaxRate = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.productTaxRate,
        );
        this.config.defaultLevel3Setting.productDiscountAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.productDiscountAmount,
        );

        this.setEnhancedFields(true);
      }
    },
    dollarFormattedValueOrZero(value:any) {
      return value ? value / 100.0 : 0;
    },
    submitActions(
      isSubmitting: boolean,
      loadingText: string = 'Processing...',
      resetForms: boolean = false,
    ) {
      this.config.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
      if (!isSubmitting && resetForms) {
        this.resetForms();
      }
    },
    submit() {
      if (this.config.isRecurring) {
        this.submitSubscription();
      } else {
        this.submitTransaction();
      }
    },
    submitTransaction() {
      if (!this.isValid) {
        return;
      }
      this.showPaymentTypeErrorModal = false;
      this.txnResponse = null;
      this.submitActions(true);
      let txn = fillTransactionWithCustomer(this.config.transaction, this.config.customer);
      txn = fillTransactionWithPaymentMethod(
        txn,
        this.config.paymentMethod,
        this.config.paymentMethod.type,
        '',
      );
      txn.customFields = reformatCustomFields(this.config.customFields);
      if (this.isEnhancedDataEnabled
          && [
            ProcessorType.ELAVON,
            ProcessorType.TSYS,
            ProcessorType.FIRSTDATA,
          ].includes(
            this.currentProcessor.key,
          )) {
        if (typeof this.config.enhancedFields !== 'undefined') {
          txn.enhancedFields = reformatCustomFields(this.config.enhancedFields);
        }
        txn.enhancedFields = txn.enhancedFields?.map((field: CustomField) => {
          const newField = {
            ...field,
          };
          if (newField.label === 'Sales Tax') {
            newField.value = typeof this.config.defaultLevel3Setting !== 'undefined' ? Math.round(
              this.config.defaultLevel3Setting.salesTaxAmount * 100,
            ) : newField.value * 100;
          }
          return newField;
        });

        txn.level3Fields = this.config.defaultLevel3Setting;
        if (typeof txn.level3Fields !== 'undefined') {
          txn.level3Fields.shippingAmount = Math.round(
            txn.level3Fields.shippingAmount * 100,
          );
          txn.level3Fields.salesTaxAmount = Math.round(
            txn.level3Fields.salesTaxAmount * 100,
          );
          txn.level3Fields.salesTaxRate = Math.round(
            txn.level3Fields.salesTaxRate * 100,
          );
          txn.level3Fields.discountAmount = Math.round(
            txn.level3Fields.discountAmount * 100,
          );
          txn.level3Fields.nationalTaxAmount = Math.round(
            txn.level3Fields.nationalTaxAmount * 100,
          );
          txn.level3Fields.nationalTaxRate = Math.round(
            txn.level3Fields.nationalTaxRate * 100,
          );
          txn.level3Fields.duty = Math.round(
            txn.level3Fields.duty * 100,
          );
          txn.level3Fields.vatTaxAmount = Math.round(
            txn.level3Fields.vatTaxAmount * 100,
          );
          txn.level3Fields.productCost = Math.round(
            txn.level3Fields.productCost * 100,
          );
          txn.level3Fields.productTaxAmount = Math.round(
            txn.level3Fields.productTaxAmount * 100,
          );
          txn.level3Fields.productTaxRate = Math.round(
            txn.level3Fields.productTaxRate * 100,
          );
          txn.level3Fields.productDiscountAmount = Math.round(
            txn.level3Fields.productDiscountAmount * 100,
          );
        }

        txn.isLevel3Transaction = this.isLevel3Transaction;
      }

      const addToVault: boolean = this.config.customer.inVault;

      if (
        (this.config.paymentMethod.type === PaymentType.ACH
          && !this.isCustomerElectronicCheckEnabled)
        || (this.config.paymentMethod.type === PaymentType.CASH
          && !this.isCustomerCashEnabled)
      ) {
        this.submitActions(false);
        this.showPaymentTypeErrorModal = true;
        return;
      }

      api
        .post('transactions', txn)
        .then(({ data }: { data: JsonApiSingleResponse<Transaction> }) => {
          this.addToVault(addToVault, data);
        })
        .catch(this.handleTransactionError);
    },
    addToVault(addToVault: boolean, data: JsonApiSingleResponse<Transaction>) {
      if (addToVault) {
        this.submitActions(true, 'Adding to vault...');
        const txn: Transaction = data.data!;
        api
          .post('customers', {
            transactionId: txn.id,
            externalId: this.config.customer.externalId,
          })
          .then(() => {
            this.showSuccessfulTransaction(data);
          })
          .catch(({ response }) => {
            this.$toasted.error(response.data.message);
            this.showSuccessfulTransaction(data);
          });
      } else {
        this.showSuccessfulTransaction(data);
      }
    },
    submitSubscription() {
      if (!this.isValid) {
        return;
      }
      this.txnResponse = null;
      this.submitActions(true);
      const subscription: Subscription = {
        planId: this.config.recurrence.plan?.id,
        name: this.config.recurrence.name,
        description: this.config.recurrence.description,
        recurrenceFrequency: this.config.recurrence.billingRecurrence.recurrence
          .recurrenceFrequency,
        amount: this.config.recurrence.billingRecurrence.amount,
        recurrenceText: this.config.recurrence.billingRecurrence.recurrence.recurrenceText,
        rruleText: this.config.recurrence.billingRecurrence.recurrence.rruleText,
        paymentMethod: this.config.paymentMethod,
        customer: this.config.customer,
        customFields: reformatCustomFields(this.config.customFields),
      };

      if (this.isEnhancedDataEnabled
          && [
            ProcessorType.ELAVON,
            ProcessorType.TSYS,
            ProcessorType.FIRSTDATA,
          ].includes(
            this.currentProcessor.key,
          )) {
        if (typeof this.config.enhancedFields !== 'undefined') {
          subscription.enhancedFields = reformatCustomFields(this.config.enhancedFields);
        }

        subscription.enhancedFields = subscription.enhancedFields?.map((field: CustomField) => {
          const newField = {
            ...field,
          };
          if (newField.label === 'Sales Tax') {
            newField.value = typeof this.config.defaultLevel3Setting !== 'undefined' ? Math.round(
              this.config.defaultLevel3Setting.salesTaxAmount * 100,
            ) : newField.value * 100;
          }
          return newField;
        });

        subscription.level3Fields = this.config.defaultLevel3Setting;
        if (typeof subscription.level3Fields !== 'undefined') {
          subscription.level3Fields.shippingAmount = Math.round(
            subscription.level3Fields.shippingAmount * 100,
          );
          subscription.level3Fields.salesTaxAmount = Math.round(
            subscription.level3Fields.salesTaxAmount * 100,
          );
          subscription.level3Fields.salesTaxRate = Math.round(
            subscription.level3Fields.salesTaxRate * 100,
          );
          subscription.level3Fields.discountAmount = Math.round(
            subscription.level3Fields.discountAmount * 100,
          );
          subscription.level3Fields.nationalTaxAmount = Math.round(
            subscription.level3Fields.nationalTaxAmount * 100,
          );
          subscription.level3Fields.nationalTaxRate = Math.round(
            subscription.level3Fields.nationalTaxRate * 100,
          );
          subscription.level3Fields.duty = Math.round(
            subscription.level3Fields.duty * 100,
          );
          subscription.level3Fields.vatTaxAmount = Math.round(
            subscription.level3Fields.vatTaxAmount * 100,
          );
          subscription.level3Fields.productCost = Math.round(
            subscription.level3Fields.productCost * 100,
          );
          subscription.level3Fields.productTaxAmount = Math.round(
            subscription.level3Fields.productTaxAmount * 100,
          );
          subscription.level3Fields.productTaxRate = Math.round(
            subscription.level3Fields.productTaxRate * 100,
          );
          subscription.level3Fields.productDiscountAmount = Math.round(
            subscription.level3Fields.productDiscountAmount * 100,
          );
        }

        subscription.isLevel3Transaction = this.isLevel3Transaction;
      }

      api
        .post('subscriptions', subscription)
        .then(this.showSuccessfulSubscription)
        .catch(this.handleSubscriptionError);
    },
    showSuccessfulTransaction(data: JsonApiSingleResponse<Transaction>) {
      this.initDefaultLevel3Values();
      this.submitActions(false, 'Finished', true);
      this.txnResponse = data;
      (this.$refs.paymentDetailsModal as any).setVisibility(true);
    },
    handleTransactionError({ response }: any) {
      this.initDefaultLevel3Values();
      this.submitActions(false);
      this.txnResponse = response;
      (this.$refs.paymentDetailsModal as any).setVisibility(true);
      this.config.validationErrors = response.data.message;
    },
    showSuccessfulSubscription({ data }: { data: JsonApiSingleResponse<Subscription> }) {
      this.initDefaultLevel3Values();
      this.submitActions(false, 'Finished', true);
      this.$toasted.success('Subscription created!', {
        action: {
          text: 'View',
          push: {
            name: 'merchant.subscriptions.view',
            params: { id: data.data!.id },
          },
        },
      });

      this.resetAmountFlag();
    },
    handleSubscriptionError({ response }: any) {
      this.initDefaultLevel3Values();
      this.submitActions(false);
      this.config.validationErrors = response.data.errors;
      this.txnResponse = response;
      (this.$refs.paymentDetailsModal as any).setVisibility(true);
    },
    resetForms() {
      (this.$refs.form as any).resetValidation();
      this.config.customer = initCustomer();
      this.config.paymentMethod = initPaymentMethod();
      this.config.transaction = initVirtualTerminalTransaction();
      this.config.recurrence = initRecurringPayment();
      this.config.validationErrors = initValidationErrors();
      this.config.customFields = this.config.customFields.map((customField) => ({
        ...customField,
        value: '',
      }));
      this.config.enhancedFields = this.config.enhancedFields.map((field) => ({
        ...field,
        value: '',
      }));
      this.txnResponse = null;
      this.resetAmount = true;
      this.customerVaultKey += 1;
      this.getLevel3Fields();
    },
    resetAmountFlag() {
      this.resetAmount = false;
    },
    resetResponse() {
      this.txnResponse = null;
      this.resetAmountFlag();
    },
    closeRestrictedAccountModal() {
      this.showAccountModal = false;
    },
    processorChanged(processor: any) {
      this.currentProcessor = processor;
      this.setEnhancedFields(true);
    },
    setEnhancedFields(force: boolean) {
      if (
        [
          ProcessorType.ELAVON,
          ProcessorType.TSYS,
          ProcessorType.FIRSTDATA,
        ].includes(
          this.currentProcessor.key,
        )
        && (!this.config.enhancedFields.length || force)) {
        if (force) {
          this.config.enhancedFields = [];
        }

        const tax = {
          id: 'enhanced_sales_tax',
          name: '11.Sales_Tax',
          label: 'Sales Tax',
          type: CustomFieldType.TEXT,
          options: [] as any,
          inVirtualTerminal: true,
          inHPP: false,
          inInvoices: false,
          required: false,
          value: '',
          rules: [
            (v: string) => {
              let valid = false;
              if (v.length) {
                if (/^[0-9.]{0,10}$/.test(v)) {
                  valid = true;
                }
              } else {
                valid = true;
              }
              if (!valid) {
                return 'Sales tax must be dollars and cents less than 10 digits';
              }
              return true;
            },
          ],
          change: 'convertDecimalToDollars',
        };
        this.config.enhancedFields.push(tax);
        const customerId = {
          id: 'enhanced_customer_code',
          name: '11.Customer_Code',
          label: 'Customer Code',
          type: CustomFieldType.TEXT,
          options: [] as any,
          inVirtualTerminal: true,
          inHPP: false,
          inInvoices: false,
          required: false,
          value: '',
          rules: [
            (v: string) => {
              let valid = false;
              if (v.length) {
                if (/^[0-9a-zA-Z!"#$%')(*+-./0-9_]{0,17}$/.test(v)) {
                  valid = true;
                }
              } else {
                valid = true;
              }
              if (!valid) {
                return 'Customer code must be alphanumeric no longer than 17 characters';
              }
              return true;
            },
          ],
        };
        this.config.enhancedFields.push(customerId);
        const invoice = {
          id: 'enhanced_invoice',
          name: '12.Invoice_Number',
          label: 'Invoice Number',
          type: CustomFieldType.TEXT,
          options: [] as any,
          inVirtualTerminal: true,
          inHPP: false,
          inInvoices: false,
          required: false,
          value: '',
          rules: [],
        };
        this.config.enhancedFields.push(invoice);

        if (this.currentProcessor.key === ProcessorType.TSYS) {
          const refNumber = {
            id: 'supplierReferenceNumber',
            name: 'supplierReferenceNumber',
            label: 'Supplier Reference Number',
            type: CustomFieldType.TEXT,
            options: [] as any,
            inVirtualTerminal: true,
            inHPP: false,
            inInvoices: false,
            required: false,
            value: '',
            rules: [],
          };
          this.config.enhancedFields.push(refNumber);
        }
      } else if (
        ![
          ProcessorType.ELAVON,
          ProcessorType.TSYS,
          ProcessorType.FIRSTDATA,
        ].includes(
          this.currentProcessor.key,
        )
      ) {
        this.config.enhancedFields = [];
      }
    },
    setTransactionProgress(progressCount: number) {
      this.isLevel3Transaction = !!(this.isEnhancedDataEnabled && progressCount === 100);
    },
  },
  components: {
    CustomerPaymentForm,
    PaymentDetailsModal,
    RestrictedAccountModal,
    CustomerVaultDropdown,
  },
});
