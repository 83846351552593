var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"batch-settle-report"},[_c('h1',{staticClass:"report-page-title"},[_vm._v("Reports")]),_c('reports-pages-tabs'),(_vm.openBatchLoaded)?_c('EntitiesTable',{attrs:{"openBatchObj":_vm.openBatchObj,"config":_vm.config,"showActions":"","showViewButton":""},scopedSlots:_vm._u([{key:"item.settledAt",fn:function(ref){
var item = ref.item;
return [(item.settledAt !== 'Open Batch (Not Settled)')?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeShort")(item.settledAt))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.settledAt)+" ")])]}},{key:"item.batchNumber",fn:function(ref){
var item = ref.item;
return [(item.settledAt === 'Open Batch (Not Settled)')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'merchant.batches.view',
            params: { id: 'open-batch' },
          }}},[_c('v-btn',{attrs:{"x-small":""}},[_vm._v("View")])],1)],1):_c('router-link',{attrs:{"to":{
          name: 'merchant.batches.view',
          params: { id: item.id },
        }}},[_vm._v(" "+_vm._s(item.batchNumber)+" ")])]}},{key:"item.refundAmount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm._f("dollarsAndCents")(item.refundAmount))+" ")]}},{key:"item.volume",fn:function(ref){
        var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm._f("dollarsAndCents")(item.volume))+" ")]}},{key:"item.netAmount",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:_vm.netAmountClass(item.netAmount)},[_vm._v(" "+_vm._s(_vm.getAmount(item.netAmount))+" ")])]}}],null,false,2833710620)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }