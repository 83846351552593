




import Vue from 'vue';

import UserEditForm from '@/common/components/users/UserEditForm.vue';
import { UserType } from '@/common/components/users/users.types';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      UserType,
    };
  },
  created() {
    document.title = 'RiseOS-Edit User';
  },
  components: {
    UserEditForm,
  },
});
