import { JsonApiData } from '@/jsonApi.types';
import { Plan } from '@/merchant/views/plans/plans.types';

export enum PaymentPageStatus {
  NOT_ACTIVE = 'not_active',
  ACTIVE_PENDING = 'active_pending',
  ACTIVE = 'active',
}

export interface PaymentPage extends JsonApiData {
  name: string;
  description?: string | null;
  pageStatus: PaymentPageStatus,
  renderedUrl?: string;
  redirect: string;
  amount: number;
  isFixedAmount: boolean;
  token: string;
  hasAchProcessor: boolean,
  hasCreditCardProcessor: boolean;
  surchargePercent: number;
  surchargeEnabled: boolean;
  adjustmentName?: string;
  adjustmentPercent?: number;
  adjustmentDollarAmount?: number;
  adjustmentInHpp?: boolean;
  nonCashAdjustmentEnabled: boolean;
  isRecurring: boolean;
  plan?: Plan;
  hasProducts: boolean;
  products?: string[];
}
