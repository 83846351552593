


















import Vue from 'vue';
import { Residual } from '@/common/types/residuals.types';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import ReportsTabs from '../../components/reports-tabs.vue';
import RESIDUAL_REPORT_TABLE_HEADERS from './table-headers';
import RESIDUAL_REPORT_TABLE_FILTERS from './table-filters';

export default Vue.extend({
  components: { EntitiesTable, ReportsTabs },
  data() {
    return {
      config: {
        pageTitle: 'Residual Report',
        apiPath: 'residuals',
        reportFileName: 'residual_report',
        headers: RESIDUAL_REPORT_TABLE_HEADERS,
        filters: RESIDUAL_REPORT_TABLE_FILTERS,
        footer: {
          header: 'Total',
          displayHeaders: ['residual'],
          displayFunction(filters: any, entities: any[], header: string) {
            if (header === 'residual') {
              const formatted = filters.number2Decimals(entities.reduce(
                (sum: number, residual: Residual) => sum + residual.raw, 0,
              ));
              return `$${formatted}`;
            }
            return '';
          },
        },
      },
    };
  },
});
