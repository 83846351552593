




































































































































import Vue from 'vue';

import api from '@/common/api';
import Icon from '@/common/icon';
import { cloneDeep } from 'lodash';
import Modal from '@/common/components/Modal.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { PaymentMethod, PaymentType } from '@/common/types/payments.types';
import { initPaymentMethod } from '@/common/util/payments.util';
import EditPaymentMethodForm from './EditPaymentMethodForm.vue';

const SavingLoadingText: string = 'Saving Payment Method...';
const DeletingLoadingText: string = 'Deleting Payment Method...';
const DefaultPaymentLoadingText: string = 'Updating Default Payment Method...';

export default Vue.extend({
  props: {
    paymentMethod: {
      type: Object as () => PaymentMethod,
      required: true,
    },
    showDeleteButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showChargeNowButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      editedPaymentMethod: initPaymentMethod() as PaymentMethod,
      validationErrors: initValidationErrors() as ValidationErrors,
      isSubmitting: false as boolean,
      loadingText: SavingLoadingText as string,
    };
  },
  mounted() {
    this.editedPaymentMethod = cloneDeep(this.paymentMethod);
  },
  methods: {
    submitActions(isSubmitting: boolean, loadingText: string = SavingLoadingText) {
      this.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    confirmDelete() {
      (this.$refs.deletePaymentMethodModal as any).show();
    },
    deletePaymentMethod() {
      if (this.paymentMethod) {
        (this.$refs.deletePaymentMethodModal as any).hide();
        this.submitActions(true, DeletingLoadingText);
        api
          .delete(
            `/customers/${this.paymentMethod.customerId}/payment-methods/${this.paymentMethod.id}`,
          )
          .then(() => {
            this.$toasted.success('Success');
            this.$emit('payment-method-deleted');
          })
          .catch(({ response }) => {
            const message = response?.data?.message || 'Failed to delete payment method. Try again.';
            this.$toasted.error(`${message} (${response.status})`);
          })
          .finally(() => {
            this.submitActions(false);
          });
      }
    },
    showEditModal(showEdit: boolean) {
      (this.$refs.editPaymentMethodModal as any).setVisibility(showEdit);
    },
    editPaymentMethod() {
      if (!this.editedPaymentMethod) {
        return;
      }
      this.showEditModal(false);
      this.submitActions(true);
      api
        .put(
          `/customers/${this.paymentMethod.customerId}/payment-methods/${this.paymentMethod.id}`,
          this.editedPaymentMethod,
        )
        .then(() => {
          this.$toasted.success('Success');
          this.$emit('payment-method-edited');
          this.submitActions(false);
        })
        .catch(({ response }) => {
          this.showEditModal(true);
          this.submitActions(false);
          const message = response?.data?.message || 'Failed to update payment method. Try again.';
          this.validationErrors = response.data.errors || {};
          this.$toasted.error(`${message} (${response.status})`);
        });
    },
    chargeNow() {
      this.$router.push({
        name: 'merchant.customers.payment-methods.charge',
        params: {
          customerId: this.paymentMethod.customerId!,
          paymentMethodId: this.paymentMethod.id!,
        },
      });
    },
    setDefaultPayment() {
      this.submitActions(true, DefaultPaymentLoadingText);
      api
        .patch(
          `/customers/${this.paymentMethod.customerId}/payment-methods/${this.paymentMethod.id}`,
          {
            isDefault: !this.paymentMethod.isDefault,
          },
        )
        .then(() => {
          this.$toasted.success('Success');
          this.submitActions(false);
          this.$emit('payment-method-edited');
        })
        .catch(({ response }) => {
          this.submitActions(false);
          const message = response?.data?.message || 'Failed to update default payment method. Try again.';
          this.validationErrors = response.data.errors || {};
          this.$toasted.error(`${message} (${response.status})`);
        });
    },
  },
  computed: {
    getPaymentMethodHeader(): string {
      return `${this.$options.filters!.allCaps(this.paymentMethod.type)} ${
        this.paymentMethod.nickname
          ? ` - ${this.$options.filters!.capitalize(this.paymentMethod.nickname)}`
          : ''
      }`;
    },
    showActionButtons(): boolean {
      return this.showDeleteButton || this.showEditButton;
    },
    isCredit(): boolean {
      return this.paymentMethod.type === PaymentType.CREDIT;
    },
    isAch(): boolean {
      return this.paymentMethod.type === PaymentType.ACH;
    },
    isCash(): boolean {
      return this.paymentMethod.type === PaymentType.CASH;
    },
    expirationDate(): string {
      if (!this.isCredit) {
        return '';
      }

      const { expMonth: month, expYear: year } = this.paymentMethod?.creditCard!;
      return `Exp: ${month}/${year}`;
    },
    isExpiring(): boolean {
      if (!this.isCredit) {
        return false;
      }
      const now: Date = new Date();
      const { expMonth, expYear } = this.paymentMethod?.creditCard!;
      return expYear === now.getFullYear() && expMonth - 1 === now.getMonth();
    },
    expiringClass(): string {
      if (!this.isCredit) {
        return '';
      }
      let returnClass = '';
      const now: Date = new Date();
      const { expMonth, expYear } = this.paymentMethod?.creditCard!;
      const currMonth = now.getMonth() + 1;
      if (expYear === now.getFullYear() && expMonth === currMonth) {
        returnClass = 'yellow lighten-5';
      } else if ((expYear === now.getFullYear() && expMonth > currMonth)
      || expYear > now.getFullYear()) {
        returnClass = 'green lighten-5';
      } else if ((expYear === now.getFullYear() && expMonth < currMonth)
      || expYear < now.getFullYear()) {
        returnClass = 'red lighten-5';
      }
      return returnClass;
    },
    lastFour(): string {
      switch (this.paymentMethod?.type) {
        case PaymentType.CREDIT: {
          const { length } = this.paymentMethod.creditCard!.cardNumber;
          return this.paymentMethod.creditCard!.cardNumber.substring(length - 4);
        }
        case PaymentType.ACH: {
          const { length } = this.paymentMethod.ach!.accountNumber;
          return this.paymentMethod.ach!.accountNumber.substring(length - 4);
        }
        case PaymentType.CASH:
        default:
          return '';
      }
    },
  },
  components: {
    Icon,
    Modal,
    EditPaymentMethodForm,
    LoadingModal,
  },
});
