












































import Vue from 'vue';
import api from '@/common/api';
import { IsoResponse, IsoStatus } from '@/tenants/admin/modules/affiliates/types/affiliate';
import Spinner from '@/common/components/LoadingSpinner.vue';
import { toFloat } from '@/common/mappers/buy-rate-value-mapper';
import DocumentViewer from '@/common/components/DocumentViewer.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import IsoBranding from '@/tenants/admin/modules/affiliates/components/Branding.vue';
import IsoDetails from '@/tenants/admin/modules/affiliates/components/Details.vue';
import { mapState } from 'vuex';
import StatusBar from './StatusBar.vue';

export default Vue.extend({
  data() {
    return {
      IsoStatus,
      iso: {} as IsoResponse,
      docHtml: '' as string,
      buttonActive: false as boolean,
      loading: true as boolean,
      activating: false,
    };
  },
  computed: {
    ...mapState(['darkMode']),
  },
  created() {
    document.title = 'RiseOS-Setup Info';
  },
  mounted() {
    this.loadIso();
  },
  methods: {
    loadIso() {
      api.get('iso').then(this.setIso).catch(this.handleLoadingError);
    },
    async finishOnboarding() {
      this.activating = true;

      try {
        await api.patch(`isos/${this.iso.id}`, this.iso);
        await api.post('iso/activation');

        this.$router.push('/dashboard');
      } catch (e) {
        this.handleSaveError(e as any);
      } finally {
        this.activating = false;
      }
    },
    setIso({ data }: { data: JsonApiSingleResponse<IsoResponse> }) {
      const iso: IsoResponse = data.data!;
      this.iso = { ...iso, buyRates: toFloat(iso.buyRates) };
      this.loading = false;
    },
    updateBrandLogo(logo: string) {
      this.iso.branding.logo = logo;
    },
    updateBrandColor(color: string, value: string) {
      this.iso.branding[color] = value;
    },
    handleSaveError({ response }: { response: any }) {
      const message = this.$errorMessages.get('affiliates.default_onboarding_edit');
      this.$toasted.error(`${message} (${response.status})`);
    },
    handleLoadingError() {
      const message = this.$errorMessages.get('default');
      this.$toasted.error(message);
    },
  },
  components: {
    DocumentViewer,
    StatusBar,
    IsoBranding,
    IsoDetails,
    Spinner,
  },
});
