<template>
  <div @drop.prevent="handleDrop" @dragover.prevent>
    <input
      :id="`logopicker-${uuid}`"
      type="file"
      accept="image/jpg, image/jpeg, image/gif, image/png"
      @change="readLogo"
    />

    <label :for="`logopicker-${uuid}`">
      <img :src="value" v-if="value" />

      <template v-else>
        <i-upload-arrow />

        <slot />
        <template v-if="!$slots.default">Upload logo</template>
      </template>
    </label>

    <small>Max file size of 500kB</small>
    <small class="error" v-if="error">{{ error }}</small>
  </div>
</template>

<script>
import IUploadArrow from '@/components/icons/i-upload-arrow.vue';

let uuid = 0;

export default {
  components: { IUploadArrow },
  props: {
    value: { required: true },
  },
  data() {
    return {
      error: '',
    };
  },
  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
  methods: {
    readLogo(evt) {
      this.error = '';
      const input = evt.target;
      if (!input.files || !input.files[0]) return;

      if (Math.round(input.files[0].size / 1024) > 500) {
        this.error = 'Maximum image size allowed is 500kb';
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.$emit('input', e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    },
    handleDrop(e) {
      if (e.dataTransfer.items
        && e.dataTransfer.items[0]
        && e.dataTransfer.items[0].kind === 'file'
      ) {
        this.error = '';
        const file = e.dataTransfer.items[0].getAsFile();

        if (Math.round(file.size / 1024) > 500) {
          this.error = 'Maximum image size allowed is 500kb';
          return;
        }

        if (!['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(file.type)) {
          this.error = 'File format not supported';
          return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
          this.$emit('input', event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style scoped>
input {
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
}

input:focus + label {
  outline: 2px solid var(--primary-color);
  outline-offset: 1px;
}

label {
  background: #F1F5F7;
  border: 1px solid #D1D9E2;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  color: #8693A6;
  text-transform: uppercase;
  padding: 3vh 0.5rem;
  cursor: pointer;
}

label:hover {
  border-color: #8f959c;
}

.error {
  color: rgb(116, 21, 21);
}
</style>
