import { EntityFilterType } from '@/common/components/entities/entities.types';

export default [
  {
    name: 'mccCode',
    display: 'MCC Code',
    value: '',
    type: EntityFilterType.TYPE_AHEAD,
  },
  {
    name: 'company',
    display: 'Name',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'contact',
    display: 'Contact',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'id',
    display: 'ID',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'dateStartCreatedAt',
    display: 'Start Date Added',
    type: EntityFilterType.DATE,
    value: '',
    open: false,
  },
  {
    name: 'dateEndCreatedAt',
    display: 'End Date Added',
    type: EntityFilterType.DATE,
    value: '',
    open: false,
  },
];
