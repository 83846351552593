<template>
  <p-container>
    <p-page-title>Merchant Addresses</p-page-title>

    <entities-table :config="config" showAddButton :key="deletes">
      <template v-slot:item.address="{ item }">
        {{ item.address1 }},
        {{ item.address2 ? `${item.address2},`: '' }}
        {{ item.city }},
        {{ item.state }},
        {{ item.country }},
        {{ item.zip }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col>
            <v-btn
              color="grey"
              x-small
              :to="{ name: 'merchant.settings.addresses.edit', params: { id: item.uuid }}"
              style="text-decoration: none;"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" dark small style="color: #fff;">
                    mdi-square-edit-outline
                  </v-icon>
                </template>
                Edit
              </v-tooltip>
            </v-btn>

            <v-btn
              color="grey"
              x-small
              class="ml-2"
              @click="addressToDelete = item.uuid; showDeleteModal = true;"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small style="color: #fff;">
                    mdi-close-circle
                  </v-icon>
                </template>
                Delete
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </entities-table>

    <v-dialog v-model="showDeleteModal" persistent max-width="500">
      <v-card>
        <v-card-title>
          Are you sure to delete this address?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            color="grey"
            @click="showDeleteModal = false"
            :disabled="deleting"
          >
            No
          </v-btn>
          <v-btn
            small
            color="error"
            @click="() => deleteAddress(addressToDelete)"
            :disabled="deleting"
            :loading="deleting"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </p-container>
</template>

<script>
import api from '@/common/api';
import PContainer from '@/components/presentations/p-container.vue';
import PPageTitle from '@/components/presentations/p-page-title.vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';

export default {
  components: {
    PContainer,
    PPageTitle,
    EntitiesTable,
  },
  data() {
    return {
      addressToDelete: null,
      showDeleteModal: false,
      deleting: false,
      deletes: 0,

      config: {
        pageTitle: 'Merchant Addressess',
        header: 'Addressess',
        createButtonText: '+ ADD',
        routerBase: 'merchant.settings.addresses',
        apiPath: 'address',
        filters: [],
        headers: [
          { value: 'uuid', align: ' d-none' },
          { value: 'address1', align: ' d-none' },
          { value: 'address2', align: ' d-none' },
          { value: 'country', align: ' d-none' },
          { value: 'state', align: ' d-none' },
          { value: 'city', align: ' d-none' },
          { value: 'zip', align: ' d-none' },
          {
            text: 'Address',
            value: 'address',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Actions',
            value: 'actions',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            align: 'center',
            width: '8rem',
          },
        ],
      },
    };
  },
  methods: {
    async deleteAddress(id) {
      this.deleting = true;

      try {
        await api.delete(`address/${id}`);
        this.$toasted.success('Merchant address successfully deleted');
        this.showDeleteModal = false;
        this.deletes += 1;
      } catch (e) {
        this.$toasted.error('There was an error while deleting the address');
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>
