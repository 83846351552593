















































































import Vue from 'vue';
import env from '@/environmentSetup';
import axios from 'axios';
import UserResetPasswordForm from '@/common/components/users/UserResetPasswordForm.vue';
import { mapGetters } from 'vuex';
import Permissions from '@/common/permissions';
import PCard from '@/components/presentations/p-card.vue';
import { User } from './users.types';

export default Vue.extend({
  props: {
    user: {
      type: Object as () => User,
      required: true,
    },
    errors: { type: Object },
    password: { type: Boolean, default: false },
  },
  data() {
    return {
      submittingPasswordReset: false,
    };
  },
  computed: {
    ...mapGetters(['isIsoContext', 'currentUser', 'currentUserCan']),
    fusionAuthDomain() {
      return env('VUE_APP_FUSION_AUTH_URL');
    },
    showChangePasswordButton(): boolean {
      return !this.password && this.currentUserCanEditUser();
    },
  },
  methods: {
    setChangePasswordModalVisibility(show: boolean) {
      (this.$refs.changePasswordModal as any).setVisibility(show);
    },
    currentUserCanEditUser() {
      return this.currentUserCan(Permissions.EDIT_USER);
    },
    async sendResetPasswordLink() {
      this.submittingPasswordReset = true;

      try {
        const endpoint = `${this.fusionAuthDomain}/api/user/forgot-password`;
        const data = {
          sendForgotPasswordEmail: false,
          loginId: this.user.email,
          applicationId: env('VUE_APP_FUSION_AUTH_CLIENT_ID'),
        };

        await axios.post(endpoint, data);
        this.$toasted.success('Reset password email successfully sent');
      } catch (e) {
        this.$toasted.error('There was a problem while sending the password reset email');
      } finally {
        this.submittingPasswordReset = false;
      }
    },
  },
  components: {
    UserResetPasswordForm,
    PCard,
  },
});
