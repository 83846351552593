var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EntitiesTable',{attrs:{"config":_vm.config,"showAddButton":_vm.userCanManageAffiliates,"showSearch":""},scopedSlots:_vm._u([{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.contact.email))}},[_vm._v(" "+_vm._s(item.contact.firstName)+" "+_vm._s(item.contact.lastName)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateShort")(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('iso-statuses',{attrs:{"item":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"space-around action-buttons"}},[_c('v-btn',_vm._b({attrs:{"href":item.siteUrl  + "/login-jwt?token=" + _vm.accessToken,"target":"_blank","disabled":item.status !== _vm.IsoStatus.ACTIVATED}},'v-btn',_vm.actionButtonAttrs,false),[_vm._v(" Log in ")]),_c('v-btn',_vm._b({attrs:{"to":{ name: "admin.isos.edit", params: { id: item.id } },"disabled":!_vm.userCanManageAffiliates}},'v-btn',_vm.actionButtonAttrs,false),[_vm._v(" Edit ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }