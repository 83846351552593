import Vue from 'vue';
import VCalendar from 'v-calendar';
import Vuelidate from 'vuelidate';
import FeatureFlipping from 'vue-feature-flipping';

export default function setVuePlugins(vue: typeof Vue) {
  Vue.use(VCalendar, {
    componentPrefix: 'vc',
  });
  vue.use(Vuelidate);
  vue.use(FeatureFlipping);
}
