<template>
  <v-app-bar app clipped-left color="primary" height="60">
    <router-link to="/">
      <img :src="logo" :alt="$store.state.branding.name + ' logo'" class="header-logo">
    </router-link>

    <v-spacer />

    <dark-mode-toggler class="tw-mr-4" />

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <button class="user-dropdown-button" :class="{ dark: darkMode }" v-bind="attrs" v-on="on">
          {{ currentUserDisplayName }}
          <i-dropdown-arrow />
        </button>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in menuItems" :key="index" link :to="{ name: item.to }">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item :to="{ name: 'logout' }">
          <v-list-item-title>Logout</v-list-item-title>
          <v-list-item-icon>
            <v-icon v-text="'$sign-out-alt'" />
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IDropdownArrow from '@/components/icons/i-dropdown-arrow.vue';
import DarkModeToggler from './DarkModeToggler.vue';

export default {
  components: { DarkModeToggler, IDropdownArrow },
  computed: {
    ...mapState(['darkMode']),
    ...mapGetters(['currentUserDisplayName', 'context']),
    menuItems() {
      if (this.context !== 'merchant') {
        return [{ title: 'Settings', icon: '$cog', to: 'settings' }];
      }

      return [
        { title: 'Settings', icon: '$cog', to: 'settings' },
        { title: 'Support', icon: '$life-ring', to: 'support' },
      ];
    },
    logo() {
      return this.$store.state.darkMode
        ? this.$store.state.branding.darkLogo
        : this.$store.state.branding.lightLogo;
    },
  },
};
</script>

<style scoped>
.user-dropdown-button {
  position: relative;
  z-index: 1;
  padding: 0.2em 0.75em;
  min-width: 10.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  color: #fff;
  text-transform: uppercase;
}

.user-dropdown-button.dark {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.user-dropdown-button:not(.dark)::before {
  content: '';
  position: absolute;
  inset: 0;
  background: #000;
  opacity: 0.36;
  z-index: -1;
}

.header-logo {
  height: 1.75rem;
}
</style>
