var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ipblocker"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","height":"30vh","headers":_vm.headers,"items":_vm.blockedIps,"options":_vm.options,"multi-sort":true,"server-items-length":_vm.meta.total,"header-props":{
      sortIcon: 'mdi-arrow-down',
    },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pr-2"},[_vm._v("Transaction IP Blocker")]),_c('v-btn',{attrs:{"x-small":"","color":"secondary"},on:{"click":_vm.openNewBlockIpModal}},[_vm._v("Add")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.blockedIp)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editBlockedIp(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Blocked IP")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.promptForDelete(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Blocked IP")])])]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"650"},model:{value:(_vm.showDeleteModal),callback:function ($$v) {_vm.showDeleteModal=$$v},expression:"showDeleteModal"}},[(_vm.blockedIpToDelete)?_c('v-card',{staticClass:"p-3",attrs:{"justify":"center"}},[_c('v-card-title',[_vm._v(" Are you sure you want to delete IP "+_vm._s((" " + (_vm.blockedIpToDelete.blockedIp)))+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v("No ")]),_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":_vm.deleteBlockedIp}},[_vm._v("Yes ")])],1)],1):_vm._e()],1),_c('LoadingModal',{attrs:{"loadingText":_vm.loadingText,"showModal":_vm.isSubmitting}}),_c('IpBlockerNew',{ref:"newModal",on:{"created":_vm.getData}}),_c('IpBlockerEdit',{ref:"editModal",attrs:{"blockedIpToEdit":_vm.blockedIpToEdit},on:{"created":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }