

































































































import Vue from 'vue';
import { Ach, AchAccountHolderType, AchAccountType } from '@/common/types/payments.types';
import { isCheckDigitRoutingNumberValid } from '@/common/validation/routingNumber.validation';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Ach,
      required: true,
    },
    suffix: {
      type: String,
      required: true,
    },
    isoEditOrMerchant: {
      type: Boolean,
      required: false,
      default: false,
    },
    releasePaddingOffset: {
      type: Boolean,
      required: false,
    },
    getPage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      AchAccountType,
      isCheckDigitRoutingNumberValid,
    };
  },
  computed: {
    accountTypes(): { text: string; value: AchAccountType }[] {
      return [
        {
          text: (this.$options.filters as any).capitalize(AchAccountType.CHECKING),
          value: AchAccountType.CHECKING,
        },
        {
          text: (this.$options.filters as any).capitalize(AchAccountType.SAVINGS),
          value: AchAccountType.SAVINGS,
        },
      ];
    },
    accountHolderTypes(): { text: string; value: AchAccountHolderType }[] {
      return [
        {
          text: (this.$options.filters as any).capitalize(AchAccountHolderType.BUSINESS),
          value: AchAccountHolderType.BUSINESS,
        },
        {
          text: (this.$options.filters as any).capitalize(AchAccountHolderType.PERSONAL),
          value: AchAccountHolderType.PERSONAL,
        },
      ];
    },
    routingNumberName(): string {
      return `routingNumber-${this.suffix}`;
    },
    accountNumberName(): string {
      return `accountNumber-${this.suffix}`;
    },
    accountHolderTypeName(): string {
      return `accountHolderType-${this.suffix}`;
    },
    accountTypeName(): string {
      return `accountType-${this.suffix}`;
    },
  },
});
