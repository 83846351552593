












































import Vue from 'vue';

import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import api from '@/common/api';
import { isEnabled } from 'vue-feature-flipping';
import PageLoader from '@/common/components/PageLoader.vue';
import PaymentDetailsModal from '@/common/components/payments/PaymentDetailsModal.vue';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import { CreditMethod, PaymentMethod, PaymentType } from '@/common/types/payments.types';
import { Customer } from '@/common/types/customers.types';
import { initVirtualTerminalTransaction, initInfoFields } from '@/common/types/virtualTerminal.types';
import {
  initCustomer,
  initCustomerAddress,
  isCustomerAddressEmpty,
} from '@/common/util/customers.util';
import {
  initPaymentMethod,
  reformatCustomFields,
  initRecurringPayment,
} from '@/common/util/payments.util';
import { initValidationErrors } from '@/common/validation/validation.types';
import PageHeader from '@/common/components/PageHeader.vue';
import { Feature } from '@/features.types';
import {
  CustomField, CustomFieldFillable, CustomFieldType,
  DefaultLevel3Setting,
  initDefaultLevel3Setting,
  InfoField,
} from '@/common/components/support/support.types';
import {
  fillTransactionWithCustomer,
  fillTransactionWithVaultPaymentMethod,
} from '@/common/util/virtualTerminal.util';
import { Transaction } from '@/common/types/transactions.types';
import { mapGetters } from 'vuex';
import { ProcessorType } from '@/common/types/processors.types';
import { Subscription } from '@/common/types/subscriptions.types';
import CustomerVaultPaymentForm from '../../components/customer-payment/CustomerVaultPaymentForm.vue';
import {
  VaultCustomerPaymentConfig,
  CustomerPaymentAutomation,
} from '../../components/customer-payment/customerPayments.types';

export default Vue.extend({
  props: {
    customerId: {
      type: String,
      required: true,
    },
    paymentMethodId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      config: {
        customFields: [],
        transaction: initVirtualTerminalTransaction(),
        customer: initCustomer(),
        paymentMethod: initPaymentMethod(),
        validationErrors: initValidationErrors(),
        isSubmitting: false,
        processors: [],
        enhancedFields: [] as Array<CustomFieldFillable>,
        defaultLevel3Setting: initDefaultLevel3Setting() as DefaultLevel3Setting,
        isRecurring: false,
        recurrence: initRecurringPayment(),
        infoFields: initInfoFields(),
      } as VaultCustomerPaymentConfig,
      loadingText: 'Processing...' as string,
      currentProcessor: {} as any,
      isLevel3Transaction: false as boolean,
      CustomerPaymentAutomation,
      txnResponse: null as JsonApiSingleResponse<any> | null,
    };
  },
  created() {
    document.title = 'RiseOS-Charge Customer';
  },
  mounted() {
    if (!this.isCustomerVaultEnabled || this.isCustomerVaultEnabled === undefined) {
      this.$store.dispatch('loadFeatures').then(() => {
        if (this.isCustomerVaultEnabled) {
          this.loadCustomer();
        } else {
          this.$router.push({ name: 'merchant.customers.index' });
        }
      });
    } else if (this.isCustomerVaultEnabled) {
      this.loadCustomer();
    }

    this.getLevel3Fields();
    this.getCustomerInfoFields();
  },
  methods: {
    loadCustomer() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api
        .get(`/customers/${this.customerId}`)
        .then(this.setCustomer)
        .catch(() => {
          this.$toasted.error('Problem loading customer');
          (this.$refs.loader as any).setError();
        });
    },
    loadPaymentMethod() {
      if (this.$refs.paymentMethodLoader) {
        (this.$refs.paymentMethodLoader as any).setLoading();
      }
      api
        .get(`/customers/${this.customerId}/payment-methods/${this.paymentMethodId}`)
        .then(this.setPaymentMethod)
        .catch(() => {
          this.$toasted.error('Problem loading customer payment method');
          (this.$refs.paymentMethodLoader as any).setError();
        });
    },
    setCustomer({ data }: { data: JsonApiSingleResponse<Customer> }) {
      if (data.data === undefined || data.data.id === undefined) {
        return;
      }
      const customer: Customer = data.data;
      this.config.customer.separateShipping = !isCustomerAddressEmpty(data.data.shippingAddress);
      this.config.customer.billingAddress = {
        ...initCustomerAddress(),
        ...customer.billingAddress,
      };
      this.config.customer.shippingAddress = {
        ...initCustomerAddress(),
        ...customer.shippingAddress,
      } ?? initCustomerAddress(false);
      this.config.customer.surchargePercent = customer.surchargePercent;
      this.loadPaymentMethod();
    },
    setPaymentMethod({ data }: { data: JsonApiSingleResponse<PaymentMethod> }) {
      this.config.paymentMethod = data.data!;
      if (this.config.paymentMethod.type === PaymentType.CREDIT) {
        this.config.paymentMethod.creditCard!.method = CreditMethod.AUTH_AND_CAPTURE;
      }
      this.currentProcessor = {
        id: this.config.paymentMethod.processorId,
        key: this.config.paymentMethod.key,
      };
      this.setEnhancedFields(false);
      this.getCustomFields();
    },
    getCustomFields() {
      api
        .get('/custom-fields', {
          params: {
            virtualTerminal: true,
          },
        })
        .then(this.assignCustomFields);
    },
    assignCustomFields({ data }: { data: JsonApiArrayResponse<CustomField> }) {
      this.config.customFields = data.data!.map((customField) => ({
        ...customField,
        value: '',
      }));
      (this.$refs.loader as any).setReady();
    },
    submitActions(isSubmitting: boolean, loadingText: string = 'Processing...') {
      this.config.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    submit() {
      if (this.config.isRecurring) {
        this.submitSubscription();
      } else {
        this.submitTransaction();
      }
    },
    submitTransaction() {
      if (!this.isValid) {
        return;
      }
      this.submitActions(true);
      let txn = fillTransactionWithCustomer(this.config.transaction, this.config.customer);
      txn = fillTransactionWithVaultPaymentMethod(txn, this.config.paymentMethod);
      txn.customFields = reformatCustomFields(this.config.customFields);
      if (this.isEnhancedDataEnabled
          && [
            ProcessorType.ELAVON,
            ProcessorType.TSYS,
            ProcessorType.FIRSTDATA,
          ].includes(
            this.currentProcessor.key,
          )) {
        if (typeof this.config.enhancedFields !== 'undefined') {
          txn.enhancedFields = reformatCustomFields(this.config.enhancedFields);
        }
        txn.enhancedFields = txn.enhancedFields?.map((field: CustomField) => {
          const newField = {
            ...field,
          };
          if (newField.label === 'Sales Tax') {
            newField.value = typeof this.config.defaultLevel3Setting !== 'undefined' ? Math.round(
              this.config.defaultLevel3Setting.salesTaxAmount * 100,
            ) : newField.value * 100;
          }
          return newField;
        });

        txn.level3Fields = this.config.defaultLevel3Setting;
        if (typeof txn.level3Fields !== 'undefined') {
          txn.level3Fields.shippingAmount = Math.round(
            txn.level3Fields.shippingAmount * 100,
          );
          txn.level3Fields.salesTaxAmount = Math.round(
            txn.level3Fields.salesTaxAmount * 100,
          );
          txn.level3Fields.salesTaxRate = Math.round(
            txn.level3Fields.salesTaxRate * 100,
          );
          txn.level3Fields.discountAmount = Math.round(
            txn.level3Fields.discountAmount * 100,
          );
          txn.level3Fields.nationalTaxAmount = Math.round(
            txn.level3Fields.nationalTaxAmount * 100,
          );
          txn.level3Fields.nationalTaxRate = Math.round(
            txn.level3Fields.nationalTaxRate * 100,
          );
          txn.level3Fields.duty = Math.round(
            txn.level3Fields.duty * 100,
          );
          txn.level3Fields.vatTaxAmount = Math.round(
            txn.level3Fields.vatTaxAmount * 100,
          );
          txn.level3Fields.productCost = Math.round(
            txn.level3Fields.productCost * 100,
          );
          txn.level3Fields.productTaxAmount = Math.round(
            txn.level3Fields.productTaxAmount * 100,
          );
          txn.level3Fields.productTaxRate = Math.round(
            txn.level3Fields.productTaxRate * 100,
          );
          txn.level3Fields.productDiscountAmount = Math.round(
            txn.level3Fields.productDiscountAmount * 100,
          );
        }

        txn.isLevel3Transaction = this.isLevel3Transaction;
      }

      api
        .post('transactions', txn)
        .then(this.showSuccessfulTransaction)
        .catch(this.handleTransactionError);
    },
    submitSubscription() {
      if (!this.isValid) {
        return;
      }
      this.submitActions(true);
      const subscription: Subscription = {
        planId: this.config.recurrence.plan?.id,
        name: this.config.recurrence.name,
        description: this.config.recurrence.description,
        recurrenceFrequency: this.config.recurrence.billingRecurrence.recurrence
          .recurrenceFrequency,
        amount: this.config.recurrence.billingRecurrence.amount,
        recurrenceText: this.config.recurrence.billingRecurrence.recurrence.recurrenceText,
        rruleText: this.config.recurrence.billingRecurrence.recurrence.rruleText,
        paymentMethod: this.config.paymentMethod,
        customer: this.config.customer,
        customFields: reformatCustomFields(this.config.customFields),
        customerId: this.config.paymentMethod.customerId,
        paymentMethodId: this.config.paymentMethod.id,
      };

      if (this.isEnhancedDataEnabled
          && [
            ProcessorType.ELAVON,
            ProcessorType.TSYS,
            ProcessorType.FIRSTDATA,
          ].includes(
            this.currentProcessor.key,
          )) {
        if (typeof this.config.enhancedFields !== 'undefined') {
          subscription.enhancedFields = reformatCustomFields(this.config.enhancedFields);
        }

        subscription.enhancedFields = subscription.enhancedFields?.map((field: CustomField) => {
          const newField = {
            ...field,
          };
          if (newField.label === 'Sales Tax') {
            newField.value = typeof this.config.defaultLevel3Setting !== 'undefined' ? Math.round(
              this.config.defaultLevel3Setting.salesTaxAmount * 100,
            ) : newField.value * 100;
          }
          return newField;
        });

        subscription.level3Fields = this.config.defaultLevel3Setting;
        if (typeof subscription.level3Fields !== 'undefined') {
          subscription.level3Fields.shippingAmount = Math.round(
            subscription.level3Fields.shippingAmount * 100,
          );
          subscription.level3Fields.salesTaxAmount = Math.round(
            subscription.level3Fields.salesTaxAmount * 100,
          );
          subscription.level3Fields.discountAmount = Math.round(
            subscription.level3Fields.discountAmount * 100,
          );
          subscription.level3Fields.nationalTaxAmount = Math.round(
            subscription.level3Fields.nationalTaxAmount * 100,
          );
          subscription.level3Fields.duty = Math.round(
            subscription.level3Fields.duty * 100,
          );
          subscription.level3Fields.vatTaxAmount = Math.round(
            subscription.level3Fields.vatTaxAmount * 100,
          );
          subscription.level3Fields.productCost = Math.round(
            subscription.level3Fields.productCost * 100,
          );
          subscription.level3Fields.productTaxAmount = Math.round(
            subscription.level3Fields.productTaxAmount * 100,
          );
          subscription.level3Fields.productDiscountAmount = Math.round(
            subscription.level3Fields.productDiscountAmount * 100,
          );
        }

        subscription.isLevel3Transaction = this.isLevel3Transaction;
      }

      api
        .post('subscriptions', subscription)
        .then(this.showSuccessfulSubscription)
        .catch(this.handleSubscriptionError);
    },
    showSuccessfulTransaction({ data }: { data: JsonApiSingleResponse<Transaction> }) {
      this.initDefaultLevel3Values();
      this.submitActions(false, 'Finished');
      this.$toasted.success('Transaction processed', {
        action: {
          text: 'View',
          push: {
            name: 'merchant.transactions.view',
            params: { id: data.data!.id },
          },
        },
      });
      this.txnResponse = data;
      (this.$refs.paymentDetailsModal as any).setVisibility(true);
    },
    handleTransactionError({ response }: any) {
      this.initDefaultLevel3Values();
      this.submitActions(false);
      this.config.validationErrors = response.data.errors;

      const msg = response.data.message || 'Could not save transaction';
      this.$toasted.error(`${msg} (${response.status})`);
    },
    showSuccessfulSubscription({ data }: { data: JsonApiSingleResponse<Subscription> }) {
      this.initDefaultLevel3Values();
      this.submitActions(false, 'Finished');
      this.$toasted.success('Subscription created!', {
        action: {
          text: 'View',
          push: {
            name: 'merchant.subscriptions.view',
            params: { id: data.data!.id },
          },
        },
      });
    },
    handleSubscriptionError({ response }: any) {
      this.initDefaultLevel3Values();
      this.submitActions(false);
      this.config.validationErrors = response?.data.errors;

      const msg = response?.data.message || 'Could not save subscription';
      this.$toasted.error(`${msg} (${response?.status})`);
    },
    changeTerminal(processor: any) {
      this.config.paymentMethod.processorId = processor.id;
      this.currentProcessor = processor;
      this.setEnhancedFields(true);
    },
    getLevel3Fields() {
      api
        .get('/level-3-settings')
        .then((response:any) => {
          if (typeof response.data.data !== 'undefined') {
            this.config.defaultLevel3Setting = response.data.data!;
            this.initDefaultLevel3Values();
          }
        });
    },
    initDefaultLevel3Values() {
      if (this.isEnhancedDataEnabled && typeof this.config.defaultLevel3Setting !== 'undefined') {
        this.config.defaultLevel3Setting.shippingAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.shippingAmount,
        );
        this.config.defaultLevel3Setting.salesTaxAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.salesTaxAmount,
        );
        this.config.defaultLevel3Setting.salesTaxRate = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.salesTaxRate,
        );
        this.config.defaultLevel3Setting.discountAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.discountAmount,
        );
        this.config.defaultLevel3Setting.nationalTaxAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.nationalTaxAmount,
        );
        this.config.defaultLevel3Setting.nationalTaxRate = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.nationalTaxRate,
        );
        this.config.defaultLevel3Setting.duty = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.duty,
        );
        this.config.defaultLevel3Setting.vatTaxAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.vatTaxAmount,
        );
        this.config.defaultLevel3Setting.productCost = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.productCost,
        );
        this.config.defaultLevel3Setting.productTaxAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.productTaxAmount,
        );
        this.config.defaultLevel3Setting.productTaxRate = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.productTaxRate,
        );
        this.config.defaultLevel3Setting.productDiscountAmount = this.dollarFormattedValueOrZero(
          this.config.defaultLevel3Setting?.productDiscountAmount,
        );

        this.setEnhancedFields(true);
      }
    },
    dollarFormattedValueOrZero(value:any) {
      return value ? value / 100.0 : 0;
    },
    setEnhancedFields(force: boolean) {
      if (
        [
          ProcessorType.ELAVON,
          ProcessorType.TSYS,
          ProcessorType.FIRSTDATA,
        ].includes(
          this.currentProcessor.key,
        )
        && (!this.config.enhancedFields.length || force)) {
        if (force) {
          this.config.enhancedFields = [];
        }
        const tax = {
          id: 'enhanced_sales_tax',
          name: '11.Sales_Tax',
          label: 'Sales Tax',
          type: CustomFieldType.TEXT,
          options: [] as any,
          inVirtualTerminal: true,
          inHPP: false,
          inInvoices: false,
          required: false,
          value: '',
          rules: [
            (v: string) => {
              let valid = false;
              if (v.length) {
                if (/^[0-9.]{0,10}$/.test(v)) {
                  valid = true;
                }
              } else {
                valid = true;
              }
              if (!valid) {
                return 'Sales tax must be dollars and cents less than 10 digits';
              }
              return true;
            },
          ],
          change: 'convertDecimalToDollars',
        };
        this.config.enhancedFields.push(tax);
        const customerId = {
          id: 'enhanced_customer_code',
          name: '11.Customer_Code',
          label: 'Customer Code',
          type: CustomFieldType.TEXT,
          options: [] as any,
          inVirtualTerminal: true,
          inHPP: false,
          inInvoices: false,
          required: false,
          value: '',
          rules: [
            (v: string) => {
              let valid = false;
              if (v.length) {
                if (/^[0-9a-zA-Z!"#$%')(*+-./0-9_]{0,17}$/.test(v)) {
                  valid = true;
                }
              } else {
                valid = true;
              }
              if (!valid) {
                return 'Customer code must be alphanumeric no longer than 17 characters';
              }
              return true;
            },
          ],
        };
        this.config.enhancedFields.push(customerId);
        const invoice = {
          id: 'enhanced_invoice',
          name: '12.Invoice_Number',
          label: 'Invoice Number',
          type: CustomFieldType.TEXT,
          options: [] as any,
          inVirtualTerminal: true,
          inHPP: false,
          inInvoices: false,
          required: false,
          value: '',
          rules: [],
        };
        this.config.enhancedFields.push(invoice);
        if (this.currentProcessor.key === ProcessorType.TSYS) {
          const refNumber = {
            id: 'supplierReferenceNumber',
            name: 'supplierReferenceNumber',
            label: 'Supplier Reference Number',
            type: CustomFieldType.TEXT,
            options: [] as any,
            inVirtualTerminal: true,
            inHPP: false,
            inInvoices: false,
            required: false,
            value: '',
            rules: [],
          };
          this.config.enhancedFields.push(refNumber);
        }
      } else if (
        ![
          ProcessorType.ELAVON,
          ProcessorType.TSYS,
          ProcessorType.FIRSTDATA,
        ].includes(
          this.currentProcessor.key,
        )
      ) {
        this.config.enhancedFields = [];
      }
    },
    setTransactionProgress(progressCount: number) {
      this.isLevel3Transaction = !!(this.isEnhancedDataEnabled && progressCount === 100);
    },
    getCustomerInfoFields() {
      api
        .get('/info-fields', {
          params: {
            virtualTerminal: true,
          },
        })
        .then(this.assignCustomerInfoFields);
    },
    assignCustomerInfoFields({ data }: { data: JsonApiArrayResponse<InfoField> }) {
      this.config.infoFields = data.data!.map((infoField) => ({
        ...infoField,
        value: '',
      }));
    },
  },
  computed: {
    ...mapGetters([
      'isCustomerVaultEnabled',
      'isEnhancedDataEnabled',
    ]),
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    showCustomFields(): boolean {
      return isEnabled(Feature.CUSTOM_FIELDS);
    },
  },
  components: {
    CustomerVaultPaymentForm,
    PageLoader,
    PageHeader,
    PaidFeatureNotEnabled,
    PaymentDetailsModal,
  },
});
