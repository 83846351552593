<template>
  <p-container>
    <p-page-title>Edit Maintenance Message</p-page-title>

    <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
      <p-card class="maintenance-card">
        <template #header>
          <h2>Please add/edit  a mantainance message</h2>
        </template>

        <v-switch
          v-model="maintenance.enabled"
          label="Enable"
          :disabled="loading || submitting"
          :loading="loading"
        />

        <v-text-field
          v-model="maintenance.title"
          label="Title"
          :rules="[
            (v) => !!v || 'Title is required',
            (v) => v.length <= 191 || 'Title is limited to 191 characters',
          ]"
          :disabled="loading || submitting || !maintenance.enabled"
          counter="191"
          outlined
          dense
        />

        <v-text-field
          v-model="maintenance.shortTitle"
          label="Short Title"
          :rules="[
            (v) => !!v || 'Short title is required',
            (v) => v.length <= 191 || 'Short title is limited to 191 characters',
          ]"
          :disabled="loading || submitting || !maintenance.enabled"
          counter="191"
          outlined
          dense
        />

        <v-textarea
          v-model="maintenance.text"
          label="Text Message"
          :rules="[
            (v) => !!v || 'Text message is required',
            (v) => v.length <= 1000 || 'Text message is limited to 1000 characters'
          ]"
          :disabled="loading || submitting || !maintenance.enabled"
          counter="1000"
          rows="5"
          auto-grow
          outlined
          dense
        />

        <template #footer>
          <v-btn
            class="mr-3"
            color="primary"
            type="submit"
            :loading="submitting"
            :disabled="loading || submitting || !isFormValid"
          >
            Save
          </v-btn>

          <v-btn
            color="gray"
            :disabled="submitting"
            :to="{ name: 'settings' }"
          >
            Cancel
          </v-btn>
        </template>
      </p-card>
    </v-form>
  </p-container>
</template>

<script>
import api from '@/common/api';
import PContainer from '@/components/presentations/p-container.vue';
import PPageTitle from '@/components/presentations/p-page-title.vue';
import PCard from '@/components/presentations/p-card.vue';

export default {
  title: 'Edit Maintenance Message',
  components: {
    PContainer,
    PPageTitle,
    PCard,
  },
  data() {
    return {
      loading: true,
      isFormValid: true,
      submitting: false,

      maintenance: {
        title: '',
        shortTitle: '',
        text: '',
        enabled: false,
      },
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;

      try {
        const { data } = await api.get('maintenance');

        this.maintenance = {
          id: data.data.id,
          uuid: data.data.uuid,
          title: data.data.title,
          shortTitle: data.data.shortTitle,
          text: data.data.text,
          enabled: data.data.enabled,
        };
      } catch (e) {
        this.$toasted.error('There was an error while loading the maintenance data');
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      this.submitting = true;

      try {
        await api.put(`maintenance/${this.maintenance.uuid}`, this.maintenance);

        this.$toasted.success('Maintenance message successfully saved');
        await this.$router.push({ name: 'settings' });
      } catch (e) {
        this.$toasted.error('There was an error while saving the maintenance message');
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped>
.maintenance-card {
  max-width: 33rem;
}
</style>
