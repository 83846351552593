var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","height":"30vh","headers":_vm.headers,"items":_vm.merchantProcessors,"multi-sort":true,"server-items-length":_vm.merchantProcessors.length,"header-props":{
    sortIcon: 'mdi-arrow-down',
  },"loading":_vm.loading,"hide-default-footer":_vm.hideFooter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pr-2"},[_vm._v("Settlement Settings")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.name))+" ")]}},{key:"item.supports",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("allCaps")(item.supports))+" ")]}},{key:"item.key",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.key))+" ")]}},{key:"item.settlementTimeUtc",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAMPM(item.settlementTimeUtc))+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editProcessor(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Processor")])])]}}])}),_c('ProcessorEdit',{ref:"editModal",attrs:{"processorToEdit":_vm.processorToEdit},on:{"created":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }