<template>
  <auth-layout v-show="!hide" :hideImage="!!error">
    <div v-if="loading" class="centerer">
      <h1>Logging in...</h1>
      <a-spinner big />
    </div>

    <two-factor-enable-card
      v-if="show2FA"
      :token="accessToken"
      :username="username"
      :userId="userId"
      style="max-width: 30rem;"
      @success="onSave2FASuccess"
      @done="onRecoveryCodesDone"
    />

    <login-errors v-if="error" :error="error" />
  </auth-layout>
</template>

<script>
import axios from 'axios';
import env from '@/environmentSetup';
import TwoFactorEnableCard from '@/common/components/auth/two-factor-enable-card.vue';
import ASpinner from '@/components/atoms/a-spinner.vue';
import { mapState } from 'vuex';
import AuthLayout from './AuthLayout.vue';
import LoginErrors from '../../components/auth/login-errors.vue';

export default {
  components: {
    TwoFactorEnableCard,
    ASpinner,
    LoginErrors,
    AuthLayout,
  },
  data() {
    return {
      show2FA: false,
      recoveryCodes: [],
      accessToken: '',
      userId: '',
      loading: true,
      hide: false,
      error: null,
      username: '',
    };
  },
  async created() {
    const { code, userState } = this.$route.query;

    if (userState !== 'Authenticated' || !code) {
      this.$router.replace('/login');
      return;
    }

    try {
      await this.obtainAccessToken(code);
      await this.check2FA(this.accessToken, this.userId);
    } catch (e) {
      this.$toasted.error('There was an error while signing-in');
      setTimeout(() => { this.$router.replace('/login'); }, 3000);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState(['context', 'branding']),
    fusionAuthDomain() {
      return env('VUE_APP_FUSION_AUTH_URL');
    },
  },
  methods: {
    async finishLogin() {
      this.$store.dispatch('clearAuthPromise');
      this.$store.commit('setAccessToken', this.accessToken);
      this.$store.commit('setRefreshToken', this.refreshToken);

      const authenticated = await this.$store.dispatch('authenticate');

      if (authenticated.status === false) {
        this.error = authenticated.error;
      } else {
        this.hide = true;
        this.$router.push(this.$store.getters.redirect || { name: `${this.context}.dashboard` });
      }
    },
    onSave2FASuccess(recoveryCodes) {
      this.show2FA = false;
      this.recoveryCodes = recoveryCodes;
    },
    onRecoveryCodesDone() {
      this.recoveryCodes = [];
      this.finishLogin();
    },
    async obtainAccessToken(code) {
      const endpoint = `${this.fusionAuthDomain}/oauth2/token`;

      const data = new URLSearchParams();
      data.append('client_id', env('VUE_APP_FUSION_AUTH_CLIENT_ID'));
      data.append('client_secret', env('VUE_APP_FUSION_AUTH_CLIENT_SECRET'));
      data.append('code', code);
      data.append('grant_type', 'authorization_code');
      data.append('redirect_uri', window.location.origin + window.location.pathname);
      data.append('offline_access', true);

      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      const response = await axios.post(endpoint, data, { headers });

      this.userId = response.data.userId;
      this.accessToken = response.data.access_token;
      this.refreshToken = response.data.access_token;
    },
    async check2FA(accessToken, userId) {
      const endpoint = `${this.fusionAuthDomain}/api/user/${userId}`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.username = response.data.user.username;

      if (Object.keys(response.data.user.twoFactor).length === 0) {
        this.show2FA = true;
      } else {
        await this.finishLogin();
      }
    },
  },
};
</script>

<style scoped>
.centerer {
  display: grid;
  place-items: center;
  margin-block-start: 10vh;
}

.centerer h1 {
  margin-block-end: 2rem;
}
</style>
