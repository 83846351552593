<template>
  <div class="card">
    <div class="card-header">
      <h2>Activity</h2>

      <a-period-selector v-model="selectedActivityPeriod" no-all-time small />

      <div>
        <strong>{{ periodData.transactions }}</strong>
        <span>Transactions</span>
      </div>

      <div>
        <strong>{{ dollarFormattedValue(periodData.volume) }}</strong>
        <span>Volume</span>
      </div>
    </div>

    <div class="chart-container">
      <a-volume-line-chart
        :previous="periodData.previous"
        :current="currentPeriodData"
        :title="selectedActivityPeriod === 'thisYear' ? 'Monthly Sales' : 'Daily Sales'"
        :dateFormat="dateFormat"
      />
    </div>

    <button @click="toggleTransactionsTable" :class="{ expanded: showTransactionsTable }">
      <i-dropdown-arrow class="dropdown-arrow" />
      {{ showTransactionsTable ? 'Hide transactions table' : 'Show transactions table' }}
    </button>

    <a-table v-if="showTransactionsTable" :data="transactions" />
  </div>
</template>

<script>
import { dateFormattedValue } from '@/common/util/dashboard.util';
import ATable from '@/components/atoms/a-table.vue';
import APeriodSelector from '@/components/atoms/a-period-selector.vue';
import AVolumeLineChart from '@/components/atoms/a-volume-line-chart.vue';
import IDropdownArrow from '@/components/icons/i-dropdown-arrow.vue';

export default {
  props: {
    activityPeriod: { required: true },
    data: { required: true },
  },
  data() {
    return {
      showTransactionsTable: false,
      selectedActivityPeriod: this.activityPeriod,
    };
  },
  computed: {
    periodData() {
      return this.data[this.selectedActivityPeriod];
    },
    previousPeriodData() {
      return this.periodData.previous;
    },
    currentPeriodData() {
      return this.periodData.current;
    },
    dateFormat() {
      return this.selectedActivityPeriod === 'thisYear' ? 'mmm' : 'dS';
    },
    maxDatesInCurrentOrPrevious() {
      const entries = this.currentPeriodData.length >= this.previousPeriodData.length
        ? this.currentPeriodData
        : this.previousPeriodData;

      return entries.map(({ date }) => date);
    },
    transactions() {
      return this.maxDatesInCurrentOrPrevious.map((date, index) => ({
        date: this.dateFormattedValue(date, this.dateFormat),
        'previous transactions': this.previousPeriodDataAt(index, 'transactions'),
        'previous volume': this.formattedPreviousPeriodDataAt(index, 'volume'),
        'current transactions': this.currentPeriodDataAt(index, 'transactions'),
        'current volume': this.formattedCurrentPeriodDataAt(index, 'volume'),
      }));
    },
  },
  methods: {
    dollarFormattedValue(value) {
      const format = this.$options.filters.dollarsAndCents;
      return value ? `$${format(value)}` : null;
    },
    dateFormattedValue,
    previousPeriodDataAt(idx, key) {
      return this.periodDataAt('previous', idx, key);
    },
    formattedPreviousPeriodDataAt(idx, key) {
      return this.dollarFormattedValue(this.previousPeriodDataAt(idx, key));
    },
    currentPeriodDataAt(idx, key) {
      return this.periodDataAt('current', idx, key);
    },
    formattedCurrentPeriodDataAt(idx, key) {
      return this.dollarFormattedValue(this.currentPeriodDataAt(idx, key));
    },
    periodDataAt(period, idx, key) {
      if (!this.periodData[period][idx]) {
        return null;
      }
      return this.periodData[period][idx][key];
    },
    toggleTransactionsTable() {
      this.showTransactionsTable = !this.showTransactionsTable;
    },
  },
  components: {
    ATable,
    APeriodSelector,
    AVolumeLineChart,
    IDropdownArrow,
  },
};
</script>

<style scoped>
.card {
  --card-padding: 0;
  overflow: hidden;
}

h2 {
  margin: 0;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.card-header > div {
  display: flex;
  flex-direction: column;
}

.card-header > div strong {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.3;
}

.card-header > div span {
  font-size: 0.875rem;
  font-weight: 400;
  color: #666666;
  line-height: 1;
}

.chart-container {
  padding: 0 1rem 1rem 1rem;
}

button {
  background: var(--background-color);
  width: 100%;
  padding: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

button.expanded .dropdown-arrow {
  transform: rotate(180deg);
}
</style>
