























































import Vue from 'vue';

import api from '@/common/api';
import { Feature } from '@/features.types';
import { PaymentPage } from '@/payments/views/payment-pages/paymentPages.types';
import rawDisplay from './design-components/rawDisplay.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Feature,
      paymentPage: null as PaymentPage | null,
      iframeHeight: '500px',
      iframeWidth: '100%',
      listItems: [
        {
          name: 'Apple iPhone 12',
          price: 999.99,
          quantity: 1,
        },
        {
          name: 'Apple iPad Pro',
          price: 1249.49,
          quantity: 2,
        },
        {
          name: 'Apple iPhone 12 Pro',
          price: 1499.99,
          quantity: 1,
        },
      ],
    };
  },
  mounted() {
    (this as any).loadPaymentPageInfo();
  },
  methods: {
    loadPaymentPageInfo() {
      api.get(`/payment-pages/${this.id}`, (this as any).skipAuthConfig)
        .then(({ data: { data } }) => {
          (this as any).paymentPage = data;
        })
        .catch(() => {
          (this as any).$toasted.error('Problem loading payment page');
          if ((this as any).$refs.loader) {
            ((this as any).$refs.loader as any).setError();
          }
        });
    },
    addItem() {
      (this as any).listItems.push({
        name: '',
        price: 0.0,
        quantity: 1,
      });
    },
    removeItem(index: number) {
      (this as any).listItems.splice(index, 1);
    },
  },
  computed: {
    baseURL() {
      return `${window.location.origin}/pay/${this.id}`;
    },
  },
  components: {
    rawDisplay,
  },
});
