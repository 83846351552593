




























































































































import Vue from 'vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import juice from 'juice';
import Icon from '@/common/icon';
import PageHeader from '@/common/components/PageHeader.vue';

export default Vue.extend({
  props: {
    hppPaymentLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      paymentUrl: '' as string,
      showModal: false as boolean,
      isLoading: false as boolean,
      backgroundColor: '#0d1b0e' as string,
      textColor: '#eff2c0' as string,
      buttonText: 'Pay Now' as string,
    };
  },
  watch: {
    hppPaymentLink() {
      this.paymentUrl = this?.hppPaymentLink;
    },
  },
  methods: {
    copyToClipboard(eType: string) {
      if (eType === 'inlineButtonHtml') navigator.clipboard.writeText(this.inlineButtonHtml);
      else if (eType === 'inlineIframeHtml') navigator.clipboard.writeText(this.inlineIframeHtml);
      else if (eType === 'inlineLinkHtml') navigator.clipboard.writeText(this.inlineLinkHtml);
      this.$toasted.success('Copied to clipboard!');
    },
    show() {
      this.paymentUrl = this?.hppPaymentLink;
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.paymentUrl = '';
      this.isLoading = false;
    },
  },
  computed: {
    inlineButtonHtml(): string {
      return juice(this.buttonHtml).trim();
    },
    inlineIframeHtml(): string {
      return juice(this.iframeHtml).trim();
    },
    inlineLinkHtml(): string {
      return juice(this.linkHtml).trim();
    },
    buttonStyle(): any {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
        cursor: 'pointer',
        paddingRight: '0.75rem',
        paddingLeft: '0.75rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        fontSize: '18px',
        height: '40px',
      };
    },
    iframeContainerStyle(): any {
      return {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        paddingTop: '56.25%',
      };
    },
    iframeStyle(): any {
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        border: 'none',
      };
    },
    linkHtml(): string {
      return `${this.paymentUrl}`;
    },
    iframeHtml(): string {
      return `
        <style>
        .hpp-container {
          position: relative;
          width: 100%;
          overflow: hidden;
          padding-top: 56.25%;
        }
        .hpp-responsive-iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          border: none;
        }
        </style>
        <div class="hpp-container"> 
          <iframe class="hpp-responsive-iframe" src="${this.paymentUrl}"></iframe>
        </div>`;
    },
    buttonHtml(): string {
      return (`
        <style>
          .hpp-container {
            position: relative;
            width: 100%;
            overflow: hidden;
            padding-top: 56.25%;
            height: 95%;
          }
      
          .hpp-responsive-iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: none;
          }
      
          .hpp-payment-container {
            text-align: center;
          }
      
          .hpp-payment-button {
            display: inline-block;
            color: ${this.textColor};
            background-color: ${this.backgroundColor};
            cursor: pointer;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            font-size: 18px;
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          }
      
          .modal {
            display: none;
            /* Hidden by default */
            position: fixed;
            /* Stay in place */
            z-index: 1;
            /* Sit on top */
            left: 0;
            top: 0;
            width: 100%;
            /* Full width */
            height: 100%;
            /* Full height */
            overflow: auto;
            /* Enable scroll if needed */
            background-color: rgb(0, 0, 0);
            /* Fallback color */
            background-color: rgba(0, 0, 0, 0.4);
            /* Black w/ opacity */
          }
      
          /* Modal Content/Box */
          .modal-content {
            background-color: #fefefe;
            margin: 15% auto;
            margin-top: 0%;
            /* 15% from the top and centered */
            padding: 20px;
            padding-top: 0px;
            padding-bottom: 0px;
            border: 1px solid #888;
            width: 50%;
            height: 100%;
            /* Could be more or less, depending on screen size */
          }
      
          /* The Close Button */
          .close {
            color: #aaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
          }
      
          .close:hover,
          .close:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
          }
        </style>
        <div class="hpp-payment-container">
          <button class="hpp-payment-button" id="myBtn">${this.buttonText}</button>
        </div>
    
        <!-- The Modal -->
        <div id="myModal" class="modal">
        
          <!-- Modal content -->
          <div class="modal-content">
            <span class="close">&times;</span>
            <div class="hpp-container">
                <iframe class="hpp-responsive-iframe"src="${this.paymentUrl}">
                </iframe>
              </div>
          </div>
        </div>
        <script>
          // Get the modal
          var modal = document.getElementById("myModal");

          // Get the button that opens the modal
          var btn = document.getElementById("myBtn");

          // Get the <span> element that closes the modal
          var span = document.getElementsByClassName("close")[0];

          // When the user clicks on the button, open the modal
          btn.onclick = function () {
              modal.style.display = "block";
          }

          // When the user clicks on <span> (x), close the modal
          span.onclick = function () {
              modal.style.display = "none";
          }
      `);
    },
  },
  components: {
    LoadingModal,
    Icon,
    PageHeader,
  },
});
