<template>
  <div class="input-wrapper" :class="{ 'input-wrapper-inline': inline }">
    <label v-if="hasLabel" :for="name">{{ label }}</label>
    <div class="input-group relative">
      <input
        :type="type"
        :id="inputId"
        :name="name"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @focus="$emit('focus', $event)"
        @keyup="$emit('keyup', $event)"
        :min="min"
        :max="max"
        :step="step"
        :autofocus="autofocus"
        :placeholder="placeholder"
        :pattern="pattern"
        :title="errorMessage"
        :required="required"
        :class="{ 'appearance-none': !step, 'input-padding-right': addPaddingRight }"
        :readonly="readonly"
        v-mask="mask"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :style="darkMode ? { backgroundColor: 'var(--background-color)', color: '#fff'} : { backgroundColor: '#FFFFFF', outline: '0.5px solid black'}"
      />
      <div class="append" :class="{ tight: tightAppend }" v-if="hasInputAppend">
        <slot name="append"></slot>
      </div>
      <div class="input-list" v-if="hasInputList">
        <slot name="inputList"></slot>
      </div>
    </div>
    <ul class="errors" v-if="errors && validationKey in errors">
      <li v-for="(error, i) in errors[validationKey]" :key="i">{{ error }}</li>
    </ul>
    <ul class="errors">
      <slot name="clientErrors"></slot>
    </ul>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapState } from 'vuex';

export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      required: false,
      default: '',
    },
    errors: {
      type: Object,
      required: false,
      default: () => {},
    },
    validationName: {
      required: false,
      default: '',
    },
    password: {
      type: Boolean,
      required: false,
      default: false,
    },
    email: {
      type: Boolean,
      required: false,
      default: false,
    },
    number: {
      type: Object,
      required: false,
      default: () => ({ isDefault: true }),
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    mask: {
      type: [String, Object],
      required: false,
      default: '',
    },
    pattern: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    tightAppend: {
      type: Boolean,
      default: false,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    autocomplete: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    addPaddingRight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(['darkMode']),
    inputId() {
      return this.id || this.name;
    },
    type() {
      if (this.password) {
        return 'password';
      }
      if (this.email) {
        return 'email';
      }
      if (!this.number.isDefault) {
        return 'number';
      }
      return 'text';
    },
    min() {
      return this.getNumberProperty('min');
    },
    max() {
      return this.getNumberProperty('max');
    },
    step() {
      return this.getNumberProperty('step');
    },
    validationKey() {
      return this.validationName || this.name;
    },
    hasInputAppend() {
      return !!this.$slots.append;
    },
    hasInputList() {
      return !!this.$slots.inputList;
    },
    hasLabel() {
      return this.label !== undefined && this.label !== null && this.label !== '';
    },
  },
  methods: {
    getNumberProperty(property) {
      return this.number.isDefault || !Object.prototype.hasOwnProperty.call(this.number, property)
        ? false
        : this.number[property];
    },
  },
  directives: {
    mask: {
      bind(el, binding, vnode, oldVnode) {
        if (binding.value) {
          mask(el, binding, vnode, oldVnode);
        }
      },
    },
  },
};
</script>
