








import { CustomFieldFillable } from '@/common/components/support/support.types';
import Vue from 'vue';
import PaymentCustomFields from '../components/customer-payment/PaymentCustomFields.vue';

export default Vue.extend({
  components: { PaymentCustomFields },
  props: {
    value: { type: Array as () => CustomFieldFillable[], required: true },
  },
});
