




import Vue from 'vue';
import ReceiptSettings from '@/common/components/support/ReceiptSettings.vue';

export default Vue.extend({
  components: {
    ReceiptSettings,
  },
});
