























import Vue from 'vue';
import ProcessorSelector from '@/merchant/views/components/hosted-payments/ProcessorSelector.vue';
import { InvoiceInformationInterface } from './invoices.types';

export default Vue.extend({
  data() {
    return {
      openIssueDatePicker: false,
      openDueDatePicker: false,
    };
  },
  props: {
    invoiceInformation: {
      type: Object as () => InvoiceInformationInterface,
      required: true,
    },
  },
  components: {
    ProcessorSelector,
  },
});

