export enum FieldName {
  BILLING_COMPANY = 'billingCompany',
  BILLING_FIRST_NAME = 'billingFirstName',
  BILLING_LAST_NAME = 'billingLastName',
  BILLING_EMAIL = 'billingEmail',
  BILLING_ADDRESS_1 = 'billingAddress1',
  BILLING_ADDRESS_2 = 'billingAddress2',
  BILLING_CITY = 'billingCity',
  BILLING_STATE = 'billingState',
  BILLING_ZIP = 'billingZip',
  BILLING_COUNTRY = 'billingCountry',
  BILLING_PHONE = 'billingPhone',
  BILLING_FAX = 'billingFax',

  SHIPPING_COMPANY = 'shippingCompany',
  SHIPPING_FIRST_NAME = 'shippingFirstName',
  SHIPPING_LAST_NAME = 'shippingLastName',
  SHIPPING_EMAIL = 'shippingEmail',
  SHIPPING_ADDRESS_1 = 'shippingAddress1',
  SHIPPING_ADDRESS_2 = 'shippingAddress2',
  SHIPPING_CITY = 'shippingCity',
  SHIPPING_STATE = 'shippingState',
  SHIPPING_ZIP = 'shippingZip',
  SHIPPING_COUNTRY = 'shippingCountry',
  SHIPPING_PHONE = 'shippingPhone',

  DESCRIPTION = 'description',
}

export enum FieldDisplayName {
  BILLING_COMPANY = 'Billing Company',
  BILLING_FIRST_NAME = 'Billing First Name',
  BILLING_LAST_NAME = 'Billing Last Name',
  BILLING_EMAIL = 'Billing Email',
  BILLING_ADDRESS_1 = 'Billing Address 1',
  BILLING_ADDRESS_2 = 'Billing Address 2',
  BILLING_CITY = 'Billing City',
  BILLING_STATE = 'Billing State',
  BILLING_ZIP = 'Billing Zip',
  BILLING_COUNTRY = 'Billing Country',
  BILLING_PHONE = 'Billing Phone',
  BILLING_FAX = 'Billing Fax',

  SHIPPING_COMPANY = 'Shipping Company',
  SHIPPING_FIRST_NAME = 'Shipping First Name',
  SHIPPING_LAST_NAME = 'Shipping Last Name',
  SHIPPING_EMAIL = 'Shipping Email',
  SHIPPING_ADDRESS_1 = 'Shipping Address 1',
  SHIPPING_ADDRESS_2 = 'Shipping Address 2',
  SHIPPING_CITY = 'Shipping City',
  SHIPPING_STATE = 'Shipping State',
  SHIPPING_ZIP = 'Shipping Zip',
  SHIPPING_COUNTRY = 'Shipping Country',
  SHIPPING_PHONE = 'Shipping Phone',

  DESCRIPTION = 'Description',
}

export enum FieldStatus {
  OPTIONAL = 'optional',
  HIDDEN = 'hidden',
  REQUIRED = 'required',
}

export interface FieldToggle {
  name: FieldName | string,
  status: FieldStatus | string;
  displayName: FieldDisplayName | string;
}

export interface AddressToggles {
  show: boolean;
  toggles: FieldToggle[];
}
