<template>
  <ul>
    <tab-item :to="{ name: 'admin.reports.commission' }">
      Commissions Report
    </tab-item>
    <tab-item :to="{ name: 'admin.reports.transaction' }">
      Merchant Transaction Detail Report
    </tab-item>
  </ul>
</template>

<script>
import TabItem from '@/merchant/components/tab-item.vue';

export default {
  components: { TabItem },
};
</script>

<style scoped>
ul {
  display: flex;
  gap: 0.25rem;
}
</style>
