










































































import Vue from 'vue';
import api from '@/common/api';

import LoadingModal from '@/common/components/LoadingModal.vue';
import { CustomField, CustomFieldType, initCustomField } from './support.types';

export default Vue.extend({
  props: {
    customFieldToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CustomFieldType,
      newCustomField: initCustomField() as CustomField,
      newOptions: '' as string,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      fieldTypes: Object.values(CustomFieldType) as string[],
    };
  },
  watch: {
    customFieldToEdit() {
      this.newCustomField = this?.customFieldToEdit;
    },
    newCustomField() {
      this.loadCustomFieldData();
    },
  },
  methods: {
    getCustomOptionValues(options: any[]) {
      return options.map((option) => option.value).join('\n');
    },
    loadCustomFieldData() {
      if (this?.newCustomField.name === null
          || Object.keys(this?.newCustomField).length === 0) return;

      this.newOptions = this.getCustomOptionValues(this.newCustomField?.options);
      this.showModal = true;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      api
        .put(`/custom-fields/${this?.newCustomField?.id}`, {
          name: this.newCustomField.name,
          type: this.newCustomField.type,
          inVirtualTerminal: this.newCustomField.inVirtualTerminal,
          inHPP: this.newCustomField.inHPP,
          inInvoices: this.newCustomField.inInvoices,
          required: this.newCustomField.required,
          options:
            this.newOptions !== ''
            && (this.newCustomField.type === CustomFieldType.RADIO
              || this.newCustomField.type === CustomFieldType.SELECT
            )
              ? this.newOptions.split('\n').filter((option) => option !== '')
              : [],
          isSearchable: this.newCustomField.isSearchable,
        })
        .then(() => {
          this.close();
          this.newCustomField = initCustomField();
          this.newOptions = '';
          this.$emit('created');
          (this.$refs.form as any).reset();
        })
        .catch(({ response }: { response: any }) => {
          this.close();
          this.$toasted.error(
            `There was a problem updating custom field. (${response.status})`,
          );
        });
    },
    show() {
      this.showModal = false;
    },
    close() {
      this.submitActions(false);
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    LoadingModal,
  },
});
