import fetch from 'sync-fetch';

class EnvironmentSetup {
  private env: string;

  constructor() {
    this.env = fetch('/my-environment.json').json()?.environment;
  }

  environment(): string {
    return this.env;
  }
}

const setup: EnvironmentSetup = new EnvironmentSetup();

// eslint-disable-line no-param-reassign
export default function env(key: string) : string { // eslint-disable-line no-param-reassign
  const newKey = `${key}_${setup.environment().toUpperCase()}`;
  return process.env[newKey]!;
}
