














import Vue from 'vue';
import api from '@/common/api';
import { DocumentInfo, DocumentName } from '@/common/components/support/support.types';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import DocumentsContainer from '@/common/components/DocumentsContainer.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import { IsoResponse } from '../types/affiliate';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      DocumentName,
      iso: {} as any,
      uploading: '',
    };
  },
  created() {
    document.title = 'RiseOS-Affiliate Documents';
  },
  mounted() {
    this.loadIso();
  },
  methods: {
    loadIso() {
      api
        .get(`isos/${this.id}`)
        .then(this.setIso)
        .catch((this.$refs.loader as any).setError);
    },
    setIso({ data }: { data: JsonApiSingleResponse<IsoResponse> }) {
      const iso: IsoResponse = data.data!;
      this.iso = {
        ...iso,
        documents: iso.documents.map((document: DocumentInfo) => ({
          ...document,
          reupload: true,
          template: document.name === DocumentName.W9 ? '/docs/w9.pdf' : '',
        })),
      };
      (this.$refs.loader as any).setReady();
    },
    upload(document: string) {
      this.uploading = document;
      (this.$refs.fileInput as any).click();
    },
    saveFile() {
      if ((this.$refs.fileInput as any).length < 1) {
        return;
      }

      const upload = new FormData();
      upload.append('name', this.uploading);
      upload.append('document', (this.$refs.fileInput as any).files[0]);

      api
        .post(`isos/${this.iso.id}/documents`, upload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(this.uploadSuccess)
        .catch(this.uploadError)
        .finally(this.resetFileInput);
    },
    uploadSuccess(response: any) {
      this.setIso(response);
      this.$toasted.success('Document uploaded!');
    },
    uploadError({ response }: { response: any }) {
      if (response.status === 422) {
        const errors = response?.data?.errors;
        let msg: any = '';
        Object.keys(errors).forEach((key: any, idx: any) => {
          if (idx > 0) msg += '<br />';
          msg += `${errors[key]}`;
        });
        this.$toasted.error(msg);
      } else {
        this.$toasted.error(this.$errorMessages.get('documents.default_load'));
      }
    },
    resetFileInput() {
      (this.$refs.fileInput as any).value = null;
    },
  },
  components: { PageHeader, PageLoader, DocumentsContainer },
});
