import { MerchantCardType, MerchantCardTypeName } from '../types/cardTypes.types';

// eslint-disable-next-line import/prefer-default-export
export function initMerchantCardTypes(): MerchantCardType[] {
  return [
    {
      name: MerchantCardTypeName.VISA,
      enabled: true,
    },
    {
      name: MerchantCardTypeName.AMEX,
      enabled: true,
    },
    {
      name: MerchantCardTypeName.MASTERCARD,
      enabled: true,
    },
    {
      name: MerchantCardTypeName.DISCOVER,
      enabled: true,
    },
    {
      name: MerchantCardTypeName.DINER_CLUB,
      enabled: true,
    },
    {
      name: MerchantCardTypeName.JCB,
      enabled: true,
    },
  ];
}
