<template>
  <li class="card">
    <component :is="icons[insight.icon]" class="icon" />

    <div>
      <strong :aria-describedby="`insightTitle${uuid}`">
        <template v-if="insight.icon === 'bill'">
          ${{ insight.value | dollarsAndCents }}
        </template>
        <template v-else>
          {{ insight.value }}
        </template>
      </strong>
      <div :id="`insightTitle${uuid}`">
        {{ insight.title }}
      </div>
    </div>
  </li>
</template>

<script>
let uuid = 0;

export default {
  props: {
    insight: { type: Object, required: true },
  },
  data() {
    return {
      icons: {
        bill: () => import('@/components/icons/insights/i-bill.vue'),
        wallet: () => import('@/components/icons/insights/i-wallet.vue'),
        basket: () => import('@/components/icons/insights/i-basket.vue'),
        store: () => import('@/components/icons/insights/i-store.vue'),
      },
    };
  },
  created() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>

<style scoped>
li {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.icon {
  width: 3rem;
  height: 3rem;
}

strong {
  font-size: 1.125rem;
}
</style>
