























































import Vue from 'vue';
import api from '@/common/api';

export default Vue.extend({
  props: {
    addModal: { required: true, type: Boolean },
    addOrEditModal: { required: true, type: String },
    modalType: { required: true, type: String },
    discountPercentProp: { required: false, type: [Number, String] },
    taxPercentProp: { required: false, type: [Number, String] },
    shippingDollarAmountProp: { required: false, type: [Number, String] },
  },
  data() {
    return {
      discountPercent: 0 as number,
      taxPercent: 0 as number,
      shippingDollarAmount: 0 as number,
      loadingTaxRates: false as boolean,
      taxRates: [] as any[],
      taxRate: null as any,
    };
  },
  mounted() {
    this.propsToData();
  },
  methods: {
    async fetchTaxRates() {
      this.loadingTaxRates = true;

      try {
        const { data } = await api.get('tax-rates?invoice=true&isDefault=true');
        this.taxRates = data.data.sort((a: any, b: any) => (b.isDefault ? 1 : -1));
        const selectedTax = this.taxRates.find((x: any) => x.taxRate === this.taxPercentProp);
        if (selectedTax) {
          this.taxRate = selectedTax.uuid;
          return;
        }

        const defaultTaxRate = this.taxRates.find((x) => x.isDefault);
        if (defaultTaxRate) {
          this.taxRate = defaultTaxRate.uuid;
        }
      } catch (e) {
        this.$toasted.error('There was an error while loading tax rates.');
      } finally {
        this.loadingTaxRates = false;
      }
    },
    save() {
      if (!this.isValid()) {
        return;
      }
      switch (this.modalType) {
        case 'Discount':
          this.$emit('saveValue', this.discountPercent, this.modalType);
          break;
        case 'Tax':
          this.$emit('saveValue', this.taxPercent, this.modalType);
          break;
        default:
          this.$emit('saveValue', this.shippingDollarAmount, this.modalType);
          break;
      }
    },
    propsToData() {
      if (this.discountPercentProp) {
        this.discountPercent = this.discountPercentProp as any;
      }
      if (this.shippingDollarAmountProp) {
        this.shippingDollarAmount = this.shippingDollarAmountProp as any;
      }
      if (this.taxPercentProp) {
        this.taxPercent = this.taxPercentProp as any;
      }
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  computed: {
    value: {
      get(): any {
        switch (this.modalType) {
          case 'Discount':
            return this.discountPercent;
          case 'Tax':
            return this.taxPercent;
          default:
            return this.shippingDollarAmount;
        }
      },
      set(value: any) {
        switch (this.modalType) {
          case 'Discount':
            this.discountPercent = value;
            break;
          case 'Tax':
            this.taxPercent = value;
            break;
          default:
            this.shippingDollarAmount = value;
            break;
        }
      },
    },
  },
  watch: {
    modalType: {
      immediate: true,
      handler(value) {
        if (value === 'Tax') this.fetchTaxRates();
      },
    },
    taxRate(value) {
      if (!value) return;

      const taxRate = this.taxRates.find((x) => x.uuid === value);
      this.value = taxRate.taxRate;
    },
  },
});
