var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"feature-flipping",rawName:"v-feature-flipping",value:(("" + (_vm.Feature.INFO_FIELDS))),expression:"`${Feature.INFO_FIELDS}`"}]},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.infoFields,"options":_vm.options,"multi-sort":true,"server-items-length":_vm.meta.total,"header-props":{
      sortIcon: 'mdi-arrow-down',
    },"loading":_vm.loading,"footer-props":{ 'items-per-page-options': _vm.footerItemsPerPage }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pr-2"},[_vm._v("Customer Information Fields")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.name))+" ")]}},{key:"item.required",fn:function(ref){
    var item = ref.item;
return [(item.required)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.inHPP",fn:function(ref){
    var item = ref.item;
return [(item.inHPP)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.inVirtualTerminal",fn:function(ref){
    var item = ref.item;
return [(item.inVirtualTerminal)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editInfoField(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Info Field")])])]}}])}),_c('LoadingModal',{attrs:{"loadingText":_vm.loadingText,"showModal":_vm.isSubmitting}}),_c('InfoFieldEdit',{ref:"editModal",attrs:{"infoFieldToEdit":_vm.infoFieldToEdit},on:{"created":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }