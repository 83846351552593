

















































import Vue from 'vue';

import api, { setHeader } from '@/common/api';
import env from '@/environmentSetup';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import Spinner from '@/common/components/LoadingSpinner.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { PublicKeyFingerprint } from '@/merchant/views/customers/batch-uploads/customerBatchUploads.types';
import fileDownload from 'js-file-download';

export default Vue.extend({
  props: {
    closeFunction: {
      type: Function,
      required: false,
      default() {},
    },
  },
  data() {
    return {
      gpgPath: env('VUE_APP_GPG_PUBLIC_KEY_PATH'),
      publicPath: process.env.BASE_URL,
      validationErrors: initValidationErrors() as ValidationErrors,
      isSubmitting: false,
      selectedFiles: undefined as File[] | undefined,
      fingerprint: null as PublicKeyFingerprint | null,
    };
  },
  mounted() {
    this.loadFingerprint();
  },
  computed: {
    publicKeyFingerprint(): string {
      return this.fingerprint?.fingerprint ?? '';
    },
  },
  methods: {
    loadFingerprint() {
      if (this.$refs.encryptionLoader) {
        (this.$refs.encryptionLoader as any).setLoading();
      }
      api
        .get('/customer-uploads/public-key-fingerprint')
        .then((response) => {
          this.fingerprint = response.data.data;
          (this.$refs.encryptionLoader as any).setReady();
        })
        .catch(() => {
          this.$toasted.error('Problem loading public key fingerprint');
          (this.$refs.encryptionLoader as any).setError();
        });
    },
    resetFileInput() {
      if (this.$refs.fileInput) {
        (this.$refs.fileInput as any).value = null;
      }
    },
    submitActions(isSubmitting: boolean, resetForms: boolean = false) {
      this.isSubmitting = isSubmitting;
      if (!isSubmitting && resetForms) {
        this.resetFileInput();
      }
    },
    showSuccess() {
      this.submitActions(false, true);
      this.$toasted.success('Customer batch import was successfull!');
      this.closeFunction();
    },
    handleError({ response }: { response: any }) {
      this.submitActions(false, true);
      const message = this.$errorMessages.get('batches.upload');
      this.$toasted.error(
        `${message} (${response.status})`,
      );
      this.validationErrors = response.data.errors || {};
      // TODO: go to view page for upload to show error details
      this.$router.push({ name: 'merchant.customers.batch.uploads.index' });
    },
    selectFile() {
      this.selectedFiles = (this.$refs.fileInput as any).files;
    },
    uploadFile() {
      if (!this.selectedFiles || this.selectedFiles === undefined) {
        return;
      }
      this.submitActions(true);
      const currentFile: File = (this.selectedFiles as any).item(0);

      const upload = new FormData();
      upload.append('file', currentFile);

      api
        .post('customer-uploads', upload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(this.showSuccess)
        .catch(this.handleError);
    },
    downloadCsvTemplate() {
      const params: any = {
        role: '',
      };
      setHeader('Accept', 'text/csv');
      api
        .get('/customer-uploads/template', {
          params,
          responseType: 'blob',
        })
        .then((response) => {
          fileDownload(response.data, 'import_customers_template.csv');
        })
        .catch(({ response }: { response: any }) => {
          this.$toasted.error(
            `There was a problem downloading the customer import csv template file. (${response.status})`,
          );
        });
      setHeader('Accept', 'application/json');
    },
    downloadExcelTemplate() {
      const params: any = {
        role: '',
      };
      setHeader('Accept', 'application/vnd.ms-excel');
      api
        .get('/customer-uploads/template', {
          params,
          responseType: 'blob',
        })
        .then((response) => {
          fileDownload(response.data, 'import_customers_template.xls');
        })
        .catch(({ response }: { response: any }) => {
          this.$toasted.error(
            `There was a problem downloading the customer import excel template file. (${response.status})`,
          );
        });
      setHeader('Accept', 'application/json');
    },
  },
  components: {
    Spinner,
    PageLoader,
  },
});
