<template>
  <v-container class="mb-6 level-3-container" :class="{ 'dark-mode': darkMode }">
    <h2>Level 3 Settings</h2>
    <PageLoader ref="loader">

    <v-form @submit.prevent="saveData" ref="form" lazy-validation>
      <v-row>
        <v-col cols="6">

          <div class="input-wrapper">
            <span class="tw-pb-2">Order ID - </span>
            <v-switch
              v-model="newDefaultLevel3Setting.orderIdUseTransactionTd"
              class="d-inline-block mt-0 pt-0 ht-20"
            >
              <template v-slot:label>
                <span class="input__label blue--text">Use Transaction Id</span>
              </template>
            </v-switch>
            <v-text-field
              v-model="newDefaultLevel3Setting.orderId"
              label="Order ID"
              outlined
              dense
              single-line
              :disabled="newDefaultLevel3Setting.orderIdUseTransactionTd ? true : false"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">PO Number - </span>
            <v-switch
              v-model="newDefaultLevel3Setting.poNumberUseTransactionId"
              class="d-inline-block mt-0 pt-0 ht-20"
            >
              <template v-slot:label>
                <span class="input__label blue--text">Use Transaction Id</span>
              </template>
            </v-switch>
            <v-text-field
              v-model="newDefaultLevel3Setting.poNumber"
              label="PO Number"
              outlined
              dense
              single-line
              :disabled="newDefaultLevel3Setting.poNumberUseTransactionId ? true : false"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Order Description - </span>
            <v-switch
              v-model="newDefaultLevel3Setting.orderDescriptionUseTransactionId"
              class="d-inline-block mt-0 pt-0 ht-20"
            >
              <template v-slot:label>
                <span class="input__label blue--text">Use Transaction Id</span>
              </template>
            </v-switch>

            <v-text-field
              v-model="newDefaultLevel3Setting.orderDescription"
              label="Order Description"
              outlined
              dense
              single-line
              :disabled="newDefaultLevel3Setting.orderDescriptionUseTransactionId ? true : false"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Shipping Amount</span>
            <v-text-field
              v-model="shippingAmount"
              label="Shipping Amount"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Sales Tax Amount</span>
            <v-text-field
              v-model="salesTaxAmount"
              label="Sales Tax Amount"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Sales Tax Rate</span>
            <v-text-field
              v-model="salesTaxRate"
              label="Sales Tax Rate"
              outlined
              dense
              single-line
              type="number"
              suffix="%"
              step="0.01"
              min="0"
              max="99.99"
              :rules="[
                (v) => v >= 0 || 'Sales Tax Rate cannot be negative.',
                (v) => v < 100 || 'Sales Tax Rate cannot go over 100%.',
                (v) => v.toString().split('.')[1] === undefined || v.toString().split('.')[1].length <= 2 || 'Sales Tax Rate max decimals is 2.'
              ]"
              @focus="focuses.salesTaxRate = true"
              @blur="focuses.salesTaxRate = false"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper tw--mt-1">
            <span class="tw-pb-2">
              <v-checkbox
                v-model="newDefaultLevel3Setting.taxExempt"
                label="Tax Exempt"
                class="mt-0"
              />
            </span>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Discount Amount</span>
            <v-text-field
              v-model="discountAmount"
              label="Discount Amount"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">National Tax Amount</span>
            <v-text-field
              v-model="nationalTaxAmount"
              label="National Tax Amount"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">National Tax Rate</span>
            <v-text-field
              v-model="nationalTaxRate"
              label="National Tax Rate"
              outlined
              dense
              single-line
              type="number"
              suffix="%"
              step="0.01"
              min="0"
              max="99.99"
              :rules="[
                (v) => v >= 0 || 'National Tax Rate cannot be negative.',
                (v) => v < 100 || 'National Tax Rate cannot go over 100%.',
                (v) => v.toString().split('.')[1] === undefined || v.toString().split('.')[1].length <= 2 || 'National Tax Rate max decimals is 2.'
              ]"
              @focus="focuses.nationalTaxRate = true"
              @blur="focuses.nationalTaxRate = false"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Duty</span>
            <v-text-field
              v-model="duty"
              label="Duty"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Merchant VAT</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.merchantVat"
              label="Merchant VAT"
              outlined
              dense
              single-line
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Customer VAT</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.customerVat"
              label="Customer VAT"
              outlined
              dense
              single-line
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Ship FROM zip code</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.shipFromZipCode"
              label="Ship FROM zip code"
              outlined
              dense
              single-line
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Unique VAT Invoice</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.uniqueVatInvoice"
              label="Unique VAT Invoice"
              outlined
              dense
              single-line
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">VAT Tax Amount</span>
            <v-text-field
              v-model="vatTaxAmount"
              label="VAT Tax Amount"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">VAT Tax Rate</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.vatTaxRate"
              label="VAT Tax Rate"
              outlined
              dense
              single-line
              type="number"
              suffix="%"
              step="0.01"
              min="0"
              max="99.99"
              :rules="[
                (v) => v >= 0 || 'VAT Tax Rate cannot be negative.',
                (v) => v < 100 || 'VAT Tax Rate cannot go over 100%.',
                (v) => v.toString().split('.')[1] === undefined || v.toString().split('.')[1].length <= 2 || 'VAT Tax Rate max decimals is 2.'
              ]"
              @focus="focuses.vatTaxRate = true"
              @blur="focuses.vatTaxRate = false"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Summary Commodity Code</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.summaryCommodityCode"
              label="Summary Commodity Code"
              outlined
              dense
              single-line
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Original Order Date - </span>
            <v-switch
              v-model="newDefaultLevel3Setting.orderDateUseTransactionDate"
              class="d-inline-block mt-0 pt-0 ht-20"
            >
              <template v-slot:label>
                <span class="input__label blue--text">Use Transaction Id</span>
              </template>
            </v-switch>

            <v-dialog
              ref="dialog"
              v-model="orderDateModal"
              :return-value.sync="newDefaultLevel3Setting.orderDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newDefaultLevel3Setting.orderDate"
                  label="Original Order Date"
                  outlined
                  dense
                  single-line
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="newDefaultLevel3Setting.orderDateUseTransactionDate ? true : false"
                />
              </template>
              <v-date-picker
                v-model="newDefaultLevel3Setting.orderDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="orderDateModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(newDefaultLevel3Setting.orderDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </v-col>
        <v-col cols="6">
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Product SKU</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.productSku"
              label="Product SKU"
              outlined
              dense
              single-line
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Product Description</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.productDescription"
              label="Product Description"
              outlined
              dense
              single-line
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Product Cost</span>
            <v-text-field
              v-model="productCost"
              label="Product Cost"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Product Quantity</span>
            <v-text-field
              v-model="newDefaultLevel3Setting.productQuantity"
              label="Product Quantity"
              outlined
              dense
              single-line
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Product Tax Amount</span>
            <v-text-field
              v-model="productTaxAmount"
              label="Product Tax Amount"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Product Tax Rate</span>
            <v-text-field
              v-model="productTaxRate"
              label="Product Tax Rate"
              outlined
              dense
              single-line
              type="number"
              suffix="%"
              step="0.01"
              min="0"
              max="99.99"
              :rules="[
                (v) => v >= 0 || 'Product Tax Rate cannot be negative.',
                (v) => v < 100 || 'Product Tax Rate cannot go over 100%.',
                (v) => v.toString().split('.')[1] === undefined || v.toString().split('.')[1].length <= 2 || 'Product Tax Rate max decimals is 2.'
              ]"
              @focus="focuses.productTaxRate = true"
              @blur="focuses.productTaxRate = false"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="input-wrapper">
            <span class="tw-pb-2">Product Discount Amount</span>
            <v-text-field
              v-model="productDiscountAmount"
              label="Product Discount Amount"
              outlined
              dense
              single-line
              prefix="$"
              step="0.01"
              type="number"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
          </div>
        </v-col>
        <v-col cols="6">
          <div class="input-wrapper">
            <v-btn
              color="primary"
              class="tw-float-right"
              type="submit"
            >
              Save
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <LoadingModal loadingText="Saving Default level 3 settings..." :showModal="isSubmitting" />
    </PageLoader>
  </v-container>
</template>

<script>
import api from '@/common/api';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import {
  initDefaultLevel3Setting,
} from '@/common/components/support/support.types';
import { initValidationErrors } from '@/common/validation/validation.types';

export default {
  data() {
    return {
      isSubmitting: false,
      newDefaultLevel3Setting: initDefaultLevel3Setting(),
      orderDateModal: false,
      validationErrors: initValidationErrors(),
      focuses: {
        salesTaxRate: false,
        nationalTaxRate: false,
        vatTaxRate: false,
        productTaxRate: false,
      },
    };
  },
  computed: {
    ...mapState(['darkMode']),
    ...mapGetters(['isEnhancedDataEnabled']),
    salesTaxAmount: {
      get() {
        return this.newDefaultLevel3Setting.salesTaxAmount.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.salesTaxAmount = +value;
      },
    },
    salesTaxRate: {
      get() {
        return this.focuses.salesTaxRate
          ? this.newDefaultLevel3Setting.salesTaxRate
          : this.newDefaultLevel3Setting.salesTaxRate.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.salesTaxRate = +value;
      },
    },
    nationalTaxAmount: {
      get() {
        return this.newDefaultLevel3Setting.nationalTaxAmount.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.nationalTaxAmount = +value;
      },
    },
    nationalTaxRate: {
      get() {
        return this.focuses.nationalTaxRate
          ? this.newDefaultLevel3Setting.nationalTaxRate
          : this.newDefaultLevel3Setting.nationalTaxRate.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.nationalTaxRate = +value;
      },
    },
    vatTaxAmount: {
      get() {
        return this.newDefaultLevel3Setting.vatTaxAmount.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.vatTaxAmount = +value;
      },
    },
    vatTaxRate: {
      get() {
        return this.focuses.vatTaxRate
          ? this.newDefaultLevel3Setting.vatTaxRate
          : this.newDefaultLevel3Setting.vatTaxRate.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.vatTaxRate = +value;
      },
    },
    productTaxAmount: {
      get() {
        return this.newDefaultLevel3Setting.productTaxAmount.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.productTaxAmount = +value;
      },
    },
    productTaxRate: {
      get() {
        return this.focuses.productTaxRate
          ? this.newDefaultLevel3Setting.productTaxRate
          : this.newDefaultLevel3Setting.productTaxRate.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.productTaxRate = +value;
      },
    },
    shippingAmount: {
      get() {
        return this.newDefaultLevel3Setting.shippingAmount.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.shippingAmount = +value;
      },
    },
    discountAmount: {
      get() {
        return this.newDefaultLevel3Setting.discountAmount.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.discountAmount = +value;
      },
    },
    duty: {
      get() {
        return this.newDefaultLevel3Setting.duty.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.duty = +value;
      },
    },
    productCost: {
      get() {
        return this.newDefaultLevel3Setting.productCost.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.productCost = +value;
      },
    },
    productDiscountAmount: {
      get() {
        return this.newDefaultLevel3Setting.productDiscountAmount.toFixed(2);
      },
      set(value) {
        this.newDefaultLevel3Setting.productDiscountAmount = +value;
      },
    },
  },
  mounted() {
    if (typeof this.isEnhancedDataEnabled === 'undefined') {
      this.$store.dispatch('loadFeatures').then(() => {
        if (this.isEnhancedDataEnabled) {
          this.getData();
        } else {
          this.$router.push({ name: 'settings' });
        }
      });
    } else if (this.isEnhancedDataEnabled) {
      this.getData();
    } else {
      this.$router.push({ name: 'settings' });
    }
  },
  methods: {
    isValid() {
      return (this.$refs.form).validate();
    },
    dollarFormattedValueOrZero(value) {
      return value ? value / 100 : 0;
    },
    init_values() {
      if (this.newDefaultLevel3Setting.orderDate !== '') {
        this.newDefaultLevel3Setting.orderDate = this.dateShortFormattedValue(
          this.newDefaultLevel3Setting.orderDate,
        );
      }

      this.newDefaultLevel3Setting.shippingAmount = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.shippingAmount,
      );
      this.newDefaultLevel3Setting.salesTaxAmount = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.salesTaxAmount,
      );
      this.newDefaultLevel3Setting.salesTaxRate = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.salesTaxRate,
      );
      this.newDefaultLevel3Setting.discountAmount = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.discountAmount,
      );
      this.newDefaultLevel3Setting.nationalTaxAmount = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.nationalTaxAmount,
      );
      this.newDefaultLevel3Setting.nationalTaxRate = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.nationalTaxRate,
      );
      this.newDefaultLevel3Setting.duty = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.duty,
      );
      this.newDefaultLevel3Setting.vatTaxAmount = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.vatTaxAmount,
      );
      this.newDefaultLevel3Setting.productCost = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.productCost,
      );
      this.newDefaultLevel3Setting.productTaxAmount = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.productTaxAmount,
      );
      this.newDefaultLevel3Setting.productTaxRate = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.productTaxRate,
      );
      this.newDefaultLevel3Setting.productDiscountAmount = this.dollarFormattedValueOrZero(
        this.newDefaultLevel3Setting.productDiscountAmount,
      );
    },
    getData() {
      api
        .get('/level-3-settings')
        .then((response) => {
          if (typeof response.data.data !== 'undefined') {
            this.newDefaultLevel3Setting = response.data.data;
            this.init_values();
          }
          (this.$refs.loader).setReady();
        })
        .catch(() => {
          (this.$refs.loader).setReady();
        });
    },
    saveData() {
      if (!this.isValid()) {
        return;
      }

      this.newDefaultLevel3Setting.shippingAmount = Math.round(
        this.newDefaultLevel3Setting.shippingAmount * 100,
      );

      this.newDefaultLevel3Setting.salesTaxAmount = Math.round(
        this.newDefaultLevel3Setting.salesTaxAmount * 100,
      );

      this.newDefaultLevel3Setting.salesTaxRate = Math.round(
        this.newDefaultLevel3Setting.salesTaxRate * 100,
      );

      this.newDefaultLevel3Setting.discountAmount = Math.round(
        this.newDefaultLevel3Setting.discountAmount * 100,
      );

      this.newDefaultLevel3Setting.nationalTaxAmount = Math.round(
        this.newDefaultLevel3Setting.nationalTaxAmount * 100,
      );

      this.newDefaultLevel3Setting.nationalTaxRate = Math.round(
        this.newDefaultLevel3Setting.nationalTaxRate * 100,
      );

      this.newDefaultLevel3Setting.duty = Math.round(
        this.newDefaultLevel3Setting.duty * 100,
      );

      this.newDefaultLevel3Setting.vatTaxAmount = Math.round(
        this.newDefaultLevel3Setting.vatTaxAmount * 100,
      );

      this.newDefaultLevel3Setting.productCost = Math.round(
        this.newDefaultLevel3Setting.productCost * 100,
      );

      this.newDefaultLevel3Setting.productTaxAmount = Math.round(
        this.newDefaultLevel3Setting.productTaxAmount * 100,
      );

      this.newDefaultLevel3Setting.productTaxRate = Math.round(
        this.newDefaultLevel3Setting.productTaxRate * 100,
      );

      this.newDefaultLevel3Setting.productDiscountAmount = Math.round(
        this.newDefaultLevel3Setting.productDiscountAmount * 100,
      );

      if (this.newDefaultLevel3Setting.orderIdUseTransactionTd) this.newDefaultLevel3Setting.orderId = '';
      if (this.newDefaultLevel3Setting.poNumberUseTransactionId) this.newDefaultLevel3Setting.poNumber = '';
      if (this.newDefaultLevel3Setting.orderDescriptionUseTransactionId) this.newDefaultLevel3Setting.orderDescription = '';
      if (this.newDefaultLevel3Setting.orderDateUseTransactionDate) this.newDefaultLevel3Setting.orderDate = '';

      this.isSubmitting = true;
      api
        .post('/level-3-settings', this.newDefaultLevel3Setting)
        .then(() => {
          this.init_values();
          this.isSubmitting = false;
          this.$toasted.success('Default level 3 settings are saved successfully');
          (this.$refs.form).resetValidation();
        })
        .catch(({ response }) => {
          this.init_values();
          this.isSubmitting = false;

          if (response.status === 422) {
            const errors = response?.data?.errors;
            let msg = '';
            Object.keys(errors).forEach((key, idx) => {
              if (idx > 0) msg += '<br />';
              msg += `${errors[key]}`;
            });
            this.$toasted.error(msg);
          } else {
            this.$toasted.error(`There was a problem saving Default level 3 settings. (${response.status})`);
          }

          this.validationErrors = response.data.errors || {};
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    dateShortFormattedValue(value) {
      return DateTime.fromISO(value.toLocaleString()).toFormat('yyyy-MM-dd');
    },
  },
  components: {
    PageLoader,
    LoadingModal,
  },
};
</script>

<style scoped>
.level-3-container {
  background: #fafafa;
}

.level-3-container.dark-mode {
  background: #000;
}
</style>
