





































import Vue from 'vue';

import api from '@/common/api';
import { JsonApiSingleResponse, JsonApiArrayResponse } from '@/jsonApi.types';
import { initValidationErrors } from '@/common/validation/validation.types';
import { cloneDeep } from 'lodash';
import PageHeader from '@/common/components/PageHeader.vue';
import { CreditMethod, PaymentType } from '@/common/types/payments.types';
import { initPaymentMethod, initRecurringPayment } from '@/common/util/payments.util';
import { Customer } from '@/common/types/customers.types';
import { initCustomer } from '@/common/util/customers.util';
import { initVirtualTerminalTransaction, initInfoFields } from '@/common/types/virtualTerminal.types';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import { mapGetters } from 'vuex';
import getSubdomain from '@/common/context';
import { VirtualTerminalMerchant } from '@/iso/views/merchants/merchants.types';
import {
  InfoField,
} from '@/common/components/support/support.types';
import CustomerPaymentForm from '../components/customer-payment/CustomerPaymentForm.vue';
import {
  CustomerPaymentConfig,
  CustomerPaymentAutomation,
} from '../components/customer-payment/customerPayments.types';


export default Vue.extend({
  data() {
    return {
      config: {
        processors: [],
        transaction: initVirtualTerminalTransaction(),
        customer: initCustomer(true),
        paymentMethod: initPaymentMethod(PaymentType.CREDIT, CreditMethod.AUTH_AND_CAPTURE),
        recurrence: initRecurringPayment(),
        showAddToVault: false,
        validationErrors: initValidationErrors(),
        isSubmitting: false,
        isRecurring: false,
        paymentValidationPrefix: 'paymentMethod.',
        creditCardValidationPrefix: 'paymentMethod.creditCard.',
        achValidationPrefix: 'paymentMethod.ach.',
        customerBillingValidationPrefix: 'billingAddress.',
        customerShippingValidationPrefix: 'shippingAddress.',
        infoFields: initInfoFields(),
      } as CustomerPaymentConfig,
      loadingText: 'Creating Customer...' as string,
      CustomerPaymentAutomation,
      virtualTerminalMerchant: {} as VirtualTerminalMerchant,
    };
  },
  created() {
    document.title = 'RiseOS-Create Customer';
  },
  mounted() {
    this.loadVirtualTerminalMerchant();
    this.getCustomerInfoFields();
  },
  computed: {
    ...mapGetters(['isCustomerVaultEnabled']),
    isValid(): boolean {
      return (this.$refs.customerPaymentForm as any).isValid;
    },
  },
  methods: {
    loadVirtualTerminalMerchant() {
      api.get(`merchants/${getSubdomain()}/virtual-terminal`).then(this.setVirtualTerminalMerchant);
    },
    setVirtualTerminalMerchant({ data }: { data: JsonApiSingleResponse<VirtualTerminalMerchant> }) {
      const virtualTerminalMerchant: VirtualTerminalMerchant = data.data!;
      this.virtualTerminalMerchant = virtualTerminalMerchant;
    },
    submitActions(isSubmitting: boolean, resetForms: boolean = false) {
      this.config.isSubmitting = isSubmitting;
      if (!isSubmitting && resetForms) {
        this.resetForms();
      }
    },
    resetForms() {
      this.config.customer = initCustomer(true);
      this.config.paymentMethod = initPaymentMethod(
        PaymentType.CREDIT,
        CreditMethod.AUTH_AND_CAPTURE,
      );
      this.config.validationErrors = initValidationErrors();
    },
    submitCustomer() {
      if (!this.isValid) {
        return;
      }
      const customer: Customer = cloneDeep(this.config.customer);
      customer.paymentMethod = cloneDeep(this.config.paymentMethod);
      this.submitActions(true);
      api.post('customers', customer).then(this.showSuccess).catch(this.handleSaveError);
    },
    showSuccess({ data }: { data: JsonApiSingleResponse<Customer> }) {
      this.submitActions(false, true);
      this.resetForms();
      this.$toasted.success('Customer created successfully!');
      this.$router.push({
        name: 'merchant.customers.view',
        params: { id: data.data!.id! },
      });
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      this.$toasted.error(`There was a problem creating the customer. (${response.status})`);
      this.config.validationErrors = response.data.errors || {};
    },
    getCustomerInfoFields() {
      api
        .get('/info-fields', {
          params: {
            virtualTerminal: true,
          },
        })
        .then(this.assignCustomerInfoFields);
    },
    assignCustomerInfoFields({ data }: { data: JsonApiArrayResponse<InfoField> }) {
      this.config.infoFields = data.data!.map((infoField) => ({
        ...infoField,
        value: '',
      }));
    },
  },
  components: {
    PageHeader,
    CustomerPaymentForm,
    PaidFeatureNotEnabled,
  },
});
