






















































































import Vue from 'vue';
import { Feature } from '@/features.types';
import Modal from '@/common/components/Modal.vue';
import Icon from '@/common/icon';
import { MerchantFeatureName } from '@/common/types/features.types';
import { RateGroup, RateGroupName } from './sellRates.types';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => RateGroup,
      required: true,
    },
    surchargeAndNonCashRateGroups: {
      type: Array as () => RateGroup[],
      required: true,
    },
    rateGroupName: {
      type: String,
      required: true,
    },
    surchargeAmount: {
      type: Number,
      required: false,
    },
    isThereOneEnhancedDataProcessor: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    'value.feature': {
      handler() {
        if (this.value.feature!.name === MerchantFeatureName.NON_CASH_ADJUSTMENT) {
          this.disabledFeature = RateGroupName.SURCHARGING;
        } else if (this.value.feature!.name === MerchantFeatureName.SURCHARGING) {
          this.disabledFeature = RateGroupName.NON_CASH_ADJUSTMENT;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      Feature,
      disabledFeature: '',
    };
  },
  computed: {
    editedRateGroupName(): string {
      return this.rateGroupName === 'Enhanced Data (Level III)' ? 'Enhanced Data (Level II, III)' : this.rateGroupName;
    },
    getCheckBoxName(): string {
      const feature = this.value?.feature;
      if (!feature) {
        return '';
      }
      const featureName = feature.name.replace('_', '-');
      return `toggle-${featureName}`;
    },
    tabletScreenSizeAndBelow(): boolean {
      return this.$vuetify.breakpoint.name === 'md'
      || this.$vuetify.breakpoint.name === 'sm'
      || this.$vuetify.breakpoint.name === 'xs';
    },
    disableOtherFeatureText(): any {
      const filteredFeatures = this.surchargeAndNonCashRateGroups
        .filter((feature:any) => feature.name === this.disabledFeature);
      return filteredFeatures.length ?? false;
    },
  },
  methods: {
    handleCheckBoxClick(value: any) {
      if (value) {
        (this.$refs.toggleFeatureModal as any).show();
      }
    },
    updateEnabled(enabled: boolean) {
      const event: RateGroup = { ...this.value };
      event.feature!.enabled = enabled;
      this.updateDisabled(enabled);
      this.$emit('input', event);
      (this.$refs.toggleFeatureModal as any).hide();
    },
    updateDisabled(featureWasEnabled: boolean) {
      if (featureWasEnabled) {
        this.$emit('disabledFeature', this.disabledFeature);
      }
    },
    isShoppingCart(editedRateGroupName: string) {
      return RateGroupName.SHOPPING_CART === editedRateGroupName;
    },
    failedValidation(rate: any) {
      return `${rate.name} sell rate must be greater than the buy rate.`;
    },
    getPrefix(rate: any) {
      return rate.key === 'electronic_check_discount_rate' || rate.key === 'surcharge-percent' ? '' : '$';
    },
    getSuffix(rate: any) {
      return rate.key === 'electronic_check_discount_rate' || rate.key === 'surcharge-percent' ? '%' : '';
    },
    hasSellRateBeenEnabled(): boolean {
      if (
        (this.value.feature!.enabled === true)
      ) {
        return true;
      }
      return false;
    },
  },
  components: {
    Modal,
    Icon,
  },
});
