import { PaymentTypeFilterOption, StatusFilterOption } from '@/common/types/transactionFilters.types';
import dateFormat from 'dateformat';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { PaymentType } from '@/common/types/payments.types';
import { TransactionStatus } from '@/common/types/transactions.types';

const TRANSACTION_REPORT_TABLE_FILTERS = [
  {
    name: 'paymentType',
    display: 'Payment Type',
    value: '',
    type: EntityFilterType.SELECT,
    items: [
      { display: 'All', value: '' },
      { display: 'Credit / Debit Card', value: PaymentType.CREDIT },
      { display: 'Electronic Check', value: PaymentType.ACH },
      { display: 'Cash', value: PaymentType.CASH },
    ] as PaymentTypeFilterOption[],
  },
  {
    name: 'status',
    display: 'Status',
    value: '',
    type: EntityFilterType.SELECT,
    items: [
      { display: 'All', value: '' },
      { display: 'Pending', value: TransactionStatus.PENDING },
      { display: 'Captured', value: TransactionStatus.CAPTURED },
      { display: 'Voided', value: TransactionStatus.VOIDED },
      { display: 'Settled', value: TransactionStatus.SETTLED },
      { display: 'Authorized', value: TransactionStatus.AUTHORIZED },
      { display: 'Refunded', value: TransactionStatus.REFUNDED },
      { display: 'Declined', value: TransactionStatus.DECLINED },
    ] as StatusFilterOption[],
  },
  {
    name: 'dateStartCreatedAt',
    display: 'Start Date',
    type: EntityFilterType.DATE,
    value: dateFormat(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      'yyyy-mm-dd',
    ),
    open: false,
  },
  {
    name: 'dateEndCreatedAt',
    display: 'End Date',
    type: EntityFilterType.DATE,
    value: dateFormat(new Date(), 'yyyy-mm-dd'),
    open: false,
  },
  {
    name: 'merchant',
    display: 'Merchant',
    value: '',
    type: EntityFilterType.SELECT,
    items: [],
  },
  {
    name: 'company',
    display: 'Company',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'firstName',
    display: 'First Name',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'lastName',
    display: 'Last Name',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'ccLastFour',
    display: 'CC Last 4',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'zip',
    display: 'Zip',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'minAmount',
    display: '$ Minimum Amount',
    value: '',
    type: EntityFilterType.TEXT,
  },
  {
    name: 'maxAmount',
    display: '$ Maximum Amount',
    value: '',
    type: EntityFilterType.TEXT,
  },
];

export default TRANSACTION_REPORT_TABLE_FILTERS;
