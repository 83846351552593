











































import Vue from 'vue';
import slugify from 'slugify';
import api from '@/common/api';
import PCard from '@/components/presentations/p-card.vue';
import { Merchant } from '../merchants.types';

export default Vue.extend({
  components: { PCard },
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    details: {
      type: Object as () => Merchant,
      required: true,
    },
  },
  data() {
    return {
      shouldSluggify: this.details.subdomain.length === 0,
      countries: [],
    };
  },
  created() {
    api.get('countries').then(({ data: { data: countries } }) => {
      this.countries = countries;
    });
  },
  methods: {
    sluggifyForSubdomain(value: string): any {
      if (!this.shouldSluggify) {
        return;
      }
      this.details.subdomain = slugify(value, {
        lower: true,
        strict: true,
      });
    },
  },
  computed: {
    isMobileDevice(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    domainSuffix() {
      const { hostname } = window.location;
      const firstDot = hostname.indexOf('.');

      return hostname.substring(firstDot);
    },
  },
});
