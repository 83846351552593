import { DocumentInfo } from '@/common/components/support/support.types';
import { JsonApiData } from '@/jsonApi.types';
import { AchAccountHolderType, AchAccountType } from '@/common/types/payments.types';

export interface Contact {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface Branding {
  [key: string]: string | null;
  fullLogo: string | null;
  lightLogo: string | null;
  darkLogo: string | null;
  mainColor: string;
  mainTextColor: string;
  secondColor: string;
  secondTextColor: string;
  thirdColor: string;
  thirdTextColor: string;
  loginFormBackgroundColor: string;
  loginTextColor: string;
  loginBodyBackgroundColor: string;
}

export enum IsoStatus {
  CREATED = 'created',
  SIGNED = 'signed',
  SETUP = 'setup',
  QUALIFIED = 'qualified',
  ACTIVATED ='activated',
  HOLD = 'on hold',
}

export interface IsoRequest {
  name: string
  subdomain: string
  taxId: string
  routingNumber?: string
  accountNumber?: string
  contact: Contact,
  branding: Branding;
  buyRates: any[];
}

export interface IsoResponse extends IsoRequest, JsonApiData {
  accountHolderType: AchAccountHolderType;
  accountType: AchAccountType;
  status: IsoStatus
  siteUrl: string
  documents: DocumentInfo[];
}
