import { WeekDay } from '@/common/types/dateTime.types';
import RRule, { Frequency, Weekday } from 'rrule';

export interface FrequencyModel {
  frequency: Frequency;
}

export const DEFAULT_RRULE_FREQUENCY: Frequency = Frequency.MONTHLY;

export function createWeekdayMap(): Map<WeekDay, Weekday> {
  return new Map([
    [WeekDay.Monday, RRule.MO],
    [WeekDay.Tuesday, RRule.TU],
    [WeekDay.Wednesday, RRule.WE],
    [WeekDay.Thursday, RRule.TH],
    [WeekDay.Friday, RRule.FR],
    [WeekDay.Saturday, RRule.SA],
    [WeekDay.Sunday, RRule.SU],
  ]) as Map<WeekDay, Weekday>;
}
