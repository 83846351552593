<template>
  <a-button
    :loading="loading"
    :small="small"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <i-save />

    <slot />
    <template v-if="!$slots.default">Save Changes</template>
  </a-button>
</template>

<script>
import ISave from '../icons/i-save.vue';
import AButton from '../atoms/a-button.vue';

export default {
  components: { ISave, AButton },
  props: {
    loading: { type: Boolean },
    small: { type: Boolean },
  },
};
</script>

<style scoped>
button {
  background: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 1em 2em;
}
</style>
