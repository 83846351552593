






























































import Vue from 'vue';
import api from '@/common/api';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import IpBlockerNew from './IpBlockerNew.vue';
import IpBlockerEdit from './IpBlockerEdit.vue';
import { IpBlocker } from './support.types';

export default Vue.extend({
  data() {
    return {
      blockedIps: [] as IpBlocker[],
      meta: {} as any,
      loading: true,
      options: {} as any,
      showDeleteModal: false as boolean,
      blockedIpToDelete: null as IpBlocker | null,
      blockedIpToEdit: {} as object,
      isSubmitting: false as boolean,
      loadingText: '' as string,
      headers: [
        {
          text: 'id',
          value: 'id',
          sortable: false,
          align: ' d-none',
        },
        {
          text: 'Blocked IP',
          value: 'blockedIp',
          sortable: false,
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'entity-table-header subtitle-2 font-weight-black',
        },
      ],
      errors: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    showDeleteModal() {
      if (!this.showDeleteModal) {
        this.blockedIpToDelete = null;
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.options;
      const params: any = {
        page,
        perPage: itemsPerPage === -1 ? this.meta.total : itemsPerPage,
        role: '',
        sortBy,
        sortDesc,
      };
      this.loading = true;
      api
        .get('/ip-blocker', { params })
        .then(this.assignBlockedIps)

        .catch(() => {
          this.blockedIps = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    promptForDelete(blockedIp: IpBlocker) {
      this.showDeleteModal = true;
      this.blockedIpToDelete = blockedIp;
    },
    submitActions(isSubmitting: boolean, loadingText: string = 'Deleting...') {
      this.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    deleteBlockedIp() {
      this.showDeleteModal = false;
      this.submitActions(true);
      api
        .delete(`/ip-blocker/${this.blockedIpToDelete?.id}`)
        .then(() => {
          this.blockedIpToDelete = null;
          this.getData();
        })
        .catch(({ response }: { response: any }) => {
          this.$toasted.error(`There was a problem deleting the Blocked IP. (${response.status})`);
        })
        .finally(() => {
          this.submitActions(false);
        });
    },
    assignBlockedIps({ data }: { data: JsonApiArrayResponse<IpBlocker> }) {
      this.meta = data.meta;
      this.blockedIps = data.data!;
    },
    openNewBlockIpModal() {
      (this.$refs.newModal as any).show();
    },
    editBlockedIp(blockedIp: IpBlocker) {
      this.blockedIpToEdit = blockedIp;
      (this.$refs.editModal as any).show();
    },
  },
  components: {
    LoadingModal,
    IpBlockerNew,
    IpBlockerEdit,
  },
});
