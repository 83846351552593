














import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters(['isIsoContext', 'isMerchantContext', 'brandName']),
    company(): string {
      return (this.isIsoContext || this.isMerchantContext) ? this.brandName : 'Aurora Payments, LLC';
    },
    year(): number {
      const now = new Date();
      return now.getFullYear();
    },
  },
  components: {
  },
});
