import { RouteConfig } from 'vue-router';
import Permissions from '../../permissions';

const routes: RouteConfig[] = [
  {
    path: '/isos',
    name: 'isos.index',
    component: () => import('./pages/AffiliatesIndex.vue'),
  },
  {
    path: '/isos/new',
    name: 'isos.new',
    component: () => import('./pages/AffiliatesNew.vue'),
    meta: {
      permission: Permissions.MANAGE_ISOS,
    },
  },
  {
    path: '/isos/:id',
    name: 'isos.edit',
    props: true,
    component: () => import('./pages/AffiliatesEdit.vue'),
    meta: {
      permission: Permissions.MANAGE_ISOS,
    },
  },
  {
    path: '/isos/:id/docs',
    name: 'isos.docs',
    component: () => import('./pages/AffiliatesDocs.vue'),
    props: true,
  },
];

export default routes;
