

























import api from '@/common/api';
import vue from 'vue';
import { toFloat, toInt } from '@/common/mappers/buy-rate-value-mapper';
import PContainer from '@/components/presentations/p-container.vue';
import PPageTitle from '@/components/presentations/p-page-title.vue';
import ASaveButton from '@/components/atoms/a-save-button.vue';
import ABreadcrumbs from '@/components/atoms/a-breadcrumbs.vue';
import BuyRates from '../components/BuyRates.vue';
import BuyRatesSkeletons from '../components/BuyRatesSkeletons.vue';
import { BuyRate } from '../types/buyRate';

export default vue.extend({
  components: {
    PContainer,
    PPageTitle,
    ASaveButton,
    ABreadcrumbs,
    BuyRates,
    BuyRatesSkeletons,
  },
  data() {
    return {
      defaultBuyRates: [] as BuyRate[],
      loading: true,
      submitting: false,
    };
  },
  created() {
    document.title = 'Default Buy Rates';
    this.loadDefaultBuyRates();
  },
  methods: {
    async loadDefaultBuyRates() {
      this.loading = true;

      try {
        const { data } = await api.get<{ data: BuyRate[] }>('default-buy-rates');
        this.defaultBuyRates = toFloat(data.data);
      } catch (e) {
        this.$toasted.error('There was an error while loading the default buy rates');
      } finally {
        this.loading = false;
      }
    },
    async saveDefaultBuyRates() {
      this.submitting = true;

      try {
        await api.put('default-buy-rates', { rates: toInt(this.defaultBuyRates) });
        this.$toasted.success('Default buy rates successfully saved');
      } catch (e) {
        this.$toasted.error('There was an error while saving the default buy rates');
      } finally {
        this.submitting = false;
      }
    },
  },
});
