


















import Vue, { PropType } from 'vue';
import Spinner from './LoadingSpinner.vue';
import { PageLoaderStatus } from './pageLoader.types';

/**
 * This component should receive via its props the "loaderState" and defaulting to
 * LOADING in case none is passed.
 * The fact that we must create a ref to this component and alter it by calling its internal
 * methods is a really bad idea.
 */
export default Vue.extend({
  props: {
    status: {
      type: String as PropType<'loading' | 'ready' | 'error'>,
      default: 'loading',
    },
  },
  data() {
    return {
      loaderState: PageLoaderStatus.LOADING as PageLoaderStatus,
    };
  },
  computed: {
    loading(): boolean {
      return this.loaderState === PageLoaderStatus.LOADING;
    },
    ready(): boolean {
      return this.loaderState === PageLoaderStatus.READY;
    },
    error(): boolean {
      return this.loaderState === PageLoaderStatus.ERROR;
    },
  },
  methods: {
    setLoading() {
      this.loaderState = PageLoaderStatus.LOADING;
    },
    setReady() {
      this.loaderState = PageLoaderStatus.READY;
    },
    setError() {
      this.loaderState = PageLoaderStatus.ERROR;
    },
  },
  components: { Spinner },
});
