




















































































































































import { PaymentType } from '@/common/types/payments.types';
import { Subscription } from '@/common/types/subscriptions.types';
import { Transaction, TransactionStatus } from '@/common/types/transactions.types';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import TransactionAddressDetails from './TransactionAddressDetails.vue';

export default Vue.extend({
  props: {
    response: {
      type: Object as () => JsonApiSingleResponse<any>,
      required: false,
      default: null,
    },
    subscription: {
      type: Object as () => Subscription,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      activeClass: 'active',
      errorClass: 'text-danger',
    };
  },
  computed: {
    ...mapState(['darkMode']),
    ...mapGetters(['isSurchargingEnabled', 'isNonCashAdjustmentEnabled']),
    transaction(): Transaction {
      return this.response.data as Transaction;
    },
    subscriptionPlan(): Subscription {
      return this.subscription as Subscription;
    },
    transactionTypeIcon(): string {
      if (this.transaction.paymentMethod!.type === PaymentType.CASH) {
        return '$money-bill-alt';
      }
      if (this.transaction.paymentMethod!.type === PaymentType.ACH) {
        return '$money-check';
      }
      if (this.transaction!.cardType! !== '') {
        return this.transaction!.cardType!;
      }
      return '$credit-card';
    },
    transactionType(): string {
      if (this.transaction!.paymentMethod!.type === PaymentType.CASH) {
        return 'Cash';
      }
      if (this.transaction!.paymentMethod!.type === PaymentType.ACH) {
        return 'ACH';
      }
      return 'Credit';
    },
    expirationDate(): string {
      if (this.transaction.paymentMethod!.type !== PaymentType.CREDIT) {
        return '';
      }

      const { expMonth: month, expYear: year } = this.transaction.paymentMethod!.creditCard!;
      return `Exp: ${month}/${year}`;
    },
    isCustomerInVault(): boolean {
      const { customerDetails } = this.transaction;
      return customerDetails !== undefined && customerDetails !== null && customerDetails.inVault;
    },
    vaultId(): string {
      return this.isCustomerInVault ? this.transaction.customerDetails!.id! : 'Not in vault';
    },
    createdBy(): string {
      return this.transaction.createdBy ?? 'User information unavailable';
    },
    createdAt(): string {
      return (this.$options.filters as any).dateTimeShortWithAt(this.transaction.createdAt);
    },
    isSurchargeTransaction(): boolean {
      return this.isSurchargingEnabled && this.transactionType.toLowerCase() === PaymentType.CREDIT;
    },
    showNonCashAdjustment(): boolean {
      if (this.transaction.transactionSource === 'default' && this.transaction.nonCashAdjustmentFee!.inVirtualTerminal
      && this.isNonCashAdjustmentEnabled) {
        return true;
      }
      if (this.transaction.transactionSource === 'hpp' && this.transaction.nonCashAdjustmentFee!.inHpp
      && this.isNonCashAdjustmentEnabled) {
        return true;
      }
      return false;
    },
    transactionFontColor(): string {
      switch (this.transaction.status as TransactionStatus) {
        case 'captured' || 'settled':
          return 'green--text';
        case 'authorized':
          return 'blue--text';
        case 'pending' || 'refunded':
          return 'yellow--text';
        case 'voided':
          return 'red--text';
        default:
          return '';
      }
    },
    totalInvoiceAmountPaid(): number {
      if (this.isSurchargeTransaction) {
        return this.transaction.amount - this.transaction.surchargeAmount!;
      }
      if (this.transaction.amountPaid) {
        return this.transaction.amount + this.transaction.amountPaid;
      } return this.transaction.amount;
    },
    remainingBalance(): number {
      if (this.isSurchargeTransaction) {
        return this.transaction.dueAmount!
        - this.transaction.amount + this.transaction.surchargeAmount!;
      }
      return this.transaction.dueAmount! - this.transaction.amount;
    },
  },
  components: {
    TransactionAddressDetails,
  },
});
