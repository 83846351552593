<template>
  <div class="checkbox-container">
    <input v-model="theValue" type="checkbox" :id="`checkbox${uuid}`">

    <label :for="`checkbox${uuid}`">
      <slot />
    </label>
  </div>
</template>

<script>
let uuid = 0;

export default {
  props: {
    value: { type: Boolean, required: true },
  },
  computed: {
    theValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
};
</script>

<style scoped>
.checkbox-container {
  display: flex;
  align-items: center;
}

input,
label {
  cursor: pointer;
}

input {
  accent-color: var(--primary-color);
  margin: 0;
}

label {
  display: flex;
  align-items: center;
  gap: var(--checkbox-gap, 0.5rem);
  font-size: 1rem;
  font-weight: 600;
  padding-inline-start: var(--checkbox-gap, 0.5rem);
}
</style>
