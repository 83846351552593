











import Vue from 'vue';
import ApiTokens from '@/common/components/settings/ApiTokens.vue';

export default Vue.extend({
  components: {
    ApiTokens,
  },
});
