<template>
  <div class="flash" :class="[color]">
    <div class="flash__message">
      <slot />
    </div>

    <button class="flash__close-button" @click="$emit('close')">
      <v-icon dark>mdi-close-circle</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    color: { type: String },
  },
};
</script>

<style scoped>
.flash {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-block: 1.25rem;
  box-shadow: inset 0px 4px 0px rgba(255, 255, 255, 0.25);
}

.flash.error {
  color: #fff;
}

.flash__close-button {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
</style>
