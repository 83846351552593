









































import Vue from 'vue';
import { mapState } from 'vuex';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import Permissions from '@/tenants/admin/permissions';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { IsoStatus } from '../types/affiliate';
import IsoStatuses from '../components/iso-statuses.vue';

export default Vue.extend({
  components: {
    EntitiesTable,
    IsoStatuses,
  },
  data() {
    return {
      EntityFilterType,
      IsoStatus,
      title: 'Affiliates' as string,
      perPage: 10 as number,
      search: '' as string,
      meta: {} as any,
      config: {
        pageTitle: 'Manage Affiliates',
        header: 'Manage Affiliates',
        createButtonText: '+ ADD',
        routerBase: 'admin.isos',
        apiPath: 'isos',
        entityName: 'iso',
        identifierProperty: 'id',
        reportFileName: 'affiliates_report',
        apiFilterSearchPath: 'mcc-codes',
        filters: [],
        headers: [
          {
            text: 'ID',
            value: 'id',
            align: ' d-none',
          },
          {
            text: 'Site URL',
            value: 'siteUrl',
            align: ' d-none',
          },
          {
            text: 'Name',
            value: 'name',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Contact',
            value: 'contact',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Created',
            value: 'createdAt',
            sortable: true,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Active Merchants',
            value: 'activeMerchants',
            sortable: true,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
            sortable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
        ],
      },
      actionButtonAttrs: {
        color: 'primary',
        dense: true,
        depressed: true,
        'x-small': true,
      },
    };
  },
  computed: {
    ...mapState(['accessToken', 'currentUser']),
    userCanManageAffiliates() {
      return this.currentUser?.permissions.includes(Permissions.MANAGE_ISOS);
    },
  },
});
