





















import Vue from 'vue';
import api from '@/common/api';
import { MccCode } from '@/iso/views/merchants/components/mcc-codes.types';
import { MerchantSettings } from '@/iso/views/merchants/merchants.types';

export default Vue.extend({
  props: {
    settings: {
      type: Object as () => MerchantSettings,
      required: true,
    },
  },
  data() {
    return {
      autoCompleteSearch: '' as string,
      autoCompleteSearchLoading: false as boolean,
      autoCompleteSearchResults: [] as any[],
    };
  },
  mounted() {
    this.autoCompleteSearch = this.settings.mccCode;
  },
  watch: {
    autoCompleteSearch() {
      if (this.autoCompleteSearch === '' || !this.autoCompleteSearch) return;
      if (this.autoCompleteSearchLoading) return;
      this.autoCompleteSearchLoading = true;
      const splMcc = this.autoCompleteSearch.split(' - ');
      const mccCode = splMcc[0];
      api
        .get('mcc-codes', {
          params: {
            q: mccCode,
          },
        })
        .then(({ data: { data: searchResult } }) => {
          this.autoCompleteSearchResults = searchResult.map((result: MccCode) => ({
            text: `${result.mcc} - ${result.combined_description}`,
            value: result.mcc,
          }));
        })
        .finally(() => {
          this.autoCompleteSearchLoading = false;
          this.setSelected(this.settings.mccCode);
        });
    },
  },
  methods: {
    setSelected(selected: string) {
      this.$emit('input', selected);
    },
  },
});
