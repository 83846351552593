





















import Vue from 'vue';
import { isEnabled } from 'vue-feature-flipping';
import { Feature } from '@/features.types';
import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import { initValidationErrors } from '@/common/validation/validation.types';
import { CreditMethod, PaymentType } from '@/common/types/payments.types';
import {
  reformatCustomFields,
  initPaymentMethod,
  initRecurringPayment,
} from '@/common/util/payments.util';
import { initCustomer } from '@/common/util/customers.util';
import { Subscription } from '@/common/types/subscriptions.types';
import { CustomField } from '@/common/components/support/support.types';
import {
  initCustomFields,
  initVirtualTerminalTransaction,
} from '@/common/types/virtualTerminal.types';
import CustomerPaymentForm from '../components/customer-payment/CustomerPaymentForm.vue';
import {
  CustomerPaymentAutomation,
  CustomerPaymentConfigWithCustomFields,
} from '../components/customer-payment/customerPayments.types';

export default Vue.extend({
  data() {
    return {
      Feature,
      config: {
        processors: [],
        enhancedFields: [],
        transaction: initVirtualTerminalTransaction(),
        customFields: initCustomFields(),
        customer: initCustomer(),
        paymentMethod: initPaymentMethod(PaymentType.CREDIT, CreditMethod.AUTH_AND_CAPTURE),
        recurrence: initRecurringPayment(),
        showAddToVault: false,
        validationErrors: initValidationErrors(),
        isSubmitting: false,
        isRecurring: true,
        paymentValidationPrefix: 'paymentMethod.',
        creditCardValidationPrefix: 'paymentMethod.creditCard.',
        achValidationPrefix: 'paymentMethod.ach.',
        customerBillingValidationPrefix: 'customer.billingAddress.',
        customerShippingValidationPrefix: 'customer.shippingAddress.',
        infoFields: [],
      } as CustomerPaymentConfigWithCustomFields,
      CustomerPaymentAutomation,
    };
  },
  created() {
    document.title = 'RiseOS-Create Subscription';
  },
  mounted() {
    this.getCustomFields();
  },
  computed: {
    isValid(): boolean {
      return (this.$refs.customerPaymentForm as any).isValid;
    },
    showCustomFields(): boolean {
      return isEnabled(Feature.CUSTOM_FIELDS);
    },
  },
  methods: {
    getCustomFields() {
      api
        .get('/custom-fields', {
          params: {
            virtualTerminal: true,
          },
        })
        .then(this.assignCustomFields);
    },
    assignCustomFields({ data }: { data: JsonApiArrayResponse<CustomField> }) {
      this.config.customFields = data.data!.map((customField) => ({
        ...customField,
        value: '',
      }));
    },
    resetForms() {
      this.config.customer = initCustomer();
      this.config.paymentMethod = initPaymentMethod(
        PaymentType.CREDIT,
        CreditMethod.AUTH_AND_CAPTURE,
      );
      this.config.recurrence = initRecurringPayment();
      this.config.validationErrors = {};
    },
    submitActions(isSubmitting: boolean, resetForms: boolean = false) {
      this.config.isSubmitting = isSubmitting;
      if (!isSubmitting && resetForms) {
        this.resetForms();
      }
    },
    submit() {
      if (!this.isValid) {
        return;
      }
      this.submitActions(true);
      const subscription: Subscription = {
        planId: this.config.recurrence.plan?.id,
        name: this.config.recurrence.name,
        description: this.config.recurrence.description,
        recurrenceFrequency: this.config.recurrence.billingRecurrence.recurrence
          .recurrenceFrequency,
        amount: this.config.recurrence.billingRecurrence.amount,
        recurrenceText: this.config.recurrence.billingRecurrence.recurrence.recurrenceText,
        rruleText: this.config.recurrence.billingRecurrence.recurrence.rruleText,
        paymentMethod: this.config.paymentMethod,
        customer: this.config.customer,
        customFields: reformatCustomFields(this.config.customFields),
      };
      api.post('subscriptions', subscription).then(this.showSuccess).catch(this.handleSaveError);
    },
    showSuccess({ data }: { data: JsonApiSingleResponse<Subscription> }) {
      this.submitActions(true, true);
      this.$toasted.success('Subscription created successfully!');
      this.$router.push({
        name: 'merchant.subscriptions.view',
        params: { id: data.data!.id! },
      });
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      this.$toasted.error(`There was a problem creating the subscription. (${response.status})`);
      this.config.validationErrors = response.data.errors || {};
    },
  },
  components: {
    PageHeader,
    CustomerPaymentForm,
  },
});
