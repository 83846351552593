




























import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import Icon from '@/common/icon';

export default Vue.extend({
  props: {
    html: {
      type: String,
      required: true,
    },
    curtain: {
      type: Boolean,
      default: true,
    },
    closeOnCurtainClick: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    showHtml: {
      type: Boolean,
      default: true,
    },
    showImmediately: {
      type: Boolean,
      default: false,
    },
    supportPage: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      shown: false as boolean,
    };
  },
  mounted() {
    this.shown = this.showImmediately;
  },
  methods: {
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
    },
    setVisibility(show: boolean) {
      this.shown = show;
    },
  },
  validations: {
    email: {
      required,
    },
  },
  components: {
    Icon,
  },
});
