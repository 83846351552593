


























import Vue from 'vue';
import { RecurrenceDurationModel } from '@/common/types/billingRecurrences.types';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => RecurrenceDurationModel,
      required: true,
    },
  },
  computed: {
    infinitySymbolIfEmpty(): string {
      if (!this.value.count) {
        return '∞';
      }
      return '';
    },
  },
});
