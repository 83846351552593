import { nanoid } from 'nanoid';

import {
  HostedPaymentPageBase,
  HostedPaymentPageConfig,
  HostedPaymentPageResponse,
  HostedPaymentPageStatus,
  HostedPaymentPageStatusDisplay,
  HostedPaymentPageUpdate,
} from '@/common/types/hostedPaymentPages.types';
import { PaymentType, AchAccountHolderType, AchAccountType } from '../types/payments.types';

const states = [
  {
    text: 'Alabama',
    value: 'AL',
  },
  {
    text: 'Alaska',
    value: 'AK',
  },
  {
    text: 'Arizona',
    value: 'AZ',
  },
  {
    text: 'Arkansas',
    value: 'AR',
  },
  {
    text: 'California',
    value: 'CA',
  },
  {
    text: 'Colorado',
    value: 'CO',
  },
  {
    text: 'Connecticut',
    value: 'CT',
  },
  {
    text: 'Delaware',
    value: 'DE',
  },
  {
    text: 'District Of Columbia',
    value: 'DC',
  },
  {
    text: 'Florida',
    value: 'FL',
  },
  {
    text: 'Georgia',
    value: 'GA',
  },
  {
    text: 'Hawaii',
    value: 'HI',
  },
  {
    text: 'Idaho',
    value: 'ID',
  },
  {
    text: 'Illinois',
    value: 'IL',
  },
  {
    text: 'Indiana',
    value: 'IN',
  },
  {
    text: 'Iowa',
    value: 'IA',
  },
  {
    text: 'Kansas',
    value: 'KS',
  },
  {
    text: 'Kentucky',
    value: 'KY',
  },
  {
    text: 'Louisiana',
    value: 'LA',
  },
  {
    text: 'Maine',
    value: 'ME',
  },
  {
    text: 'Maryland',
    value: 'MD',
  },
  {
    text: 'Massachusetts',
    value: 'MA',
  },
  {
    text: 'Michigan',
    value: 'MI',
  },
  {
    text: 'Minnesota',
    value: 'MN',
  },
  {
    text: 'Mississippi',
    value: 'MS',
  },
  {
    text: 'Missouri',
    value: 'MO',
  },
  {
    text: 'Montana',
    value: 'MT',
  },
  {
    text: 'Nebraska',
    value: 'NE',
  },
  {
    text: 'Nevada',
    value: 'NV',
  },
  {
    text: 'New Hampshire',
    value: 'NH',
  },
  {
    text: 'New Jersey',
    value: 'NJ',
  },
  {
    text: 'New Mexico',
    value: 'NM',
  },
  {
    text: 'New York',
    value: 'NY',
  },
  {
    text: 'North Carolina',
    value: 'NC',
  },
  {
    text: 'North Dakota',
    value: 'ND',
  },
  {
    text: 'Ohio',
    value: 'OH',
  },
  {
    text: 'Oklahoma',
    value: 'OK',
  },
  {
    text: 'Oregon',
    value: 'OR',
  },
  {
    text: 'Pennsylvania',
    value: 'PA',
  },
  {
    text: 'Rhode Island',
    value: 'RI',
  },
  {
    text: 'South Carolina',
    value: 'SC',
  },
  {
    text: 'South Dakota',
    value: 'SD',
  },
  {
    text: 'Tennessee',
    value: 'TN',
  },
  {
    text: 'Texas',
    value: 'TX',
  },
  {
    text: 'Utah',
    value: 'UT',
  },
  {
    text: 'Vermont',
    value: 'VT',
  },
  {
    text: 'Virginia',
    value: 'VA',
  },
  {
    text: 'Washington',
    value: 'WA',
  },
  {
    text: 'West Virginia',
    value: 'WV',
  },
  {
    text: 'Wisconsin',
    value: 'WI',
  },
  {
    text: 'Wyoming',
    value: 'WY',
  },
];

export interface HppState {
  text: string,
  value: string,
}

export function initHppStates(): HppState[] {
  return states;
}

interface HppPaymentFieldAttributeValue {
  amount: number;
  type: PaymentType;
  creditCard: {
    method: string;
    cardNumber: string;
    expMonth: string;
    expYear: string;
    cvv: string;
  }
  ach: {
    routingNumber: string;
    accountNumber: string;
    accountHolderType: AchAccountHolderType;
    accountType: AchAccountType;
  }
}
interface HppPaymentFieldAttribute {
  values: HppPaymentFieldAttributeValue
}
export interface HppFieldAttribute {
  label: string;
  items?: string[]
}

export interface HppSelectFieldAttribute {
  label: string;
  items?: HppState[],
}

export interface HppField {
  id: string;
  type: string;
  attributes: HppFieldAttribute | HppPaymentFieldAttribute | HppSelectFieldAttribute;
  name: string;
  value: any | string;
  required: boolean;
  customField: boolean;
  hidden: boolean;
  style: string;
}
export interface HppColumn {
  key: string;
  type: string;
  name: string;
  fields: HppField[]
}

export interface HppRow {
  key: string;
  type: string;
  columns: HppColumn[]
}

export function initDefaultHppConfig(): HppRow[] {
  return [
    {
      key: nanoid(),
      type: 'Row',
      columns: [
        {
          key: nanoid(),
          type: 'Column',
          name: 'billingAddress',
          fields: [
            {
              id: nanoid(),
              type: 'HppHeading',
              attributes: {
                label: '',
              },
              name: 'billingHeading',
              value: 'Billing Information',
              required: false,
              customField: false,
              hidden: false,
              style: 'grid-column-start: 1; grid-column-end: 3',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Company Name',
              },
              name: 'company',
              value: '',
              required: false,
              customField: false,
              hidden: false,
              style: 'grid-column-start: 1; grid-column-end: 3',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'First Name',
              },
              name: 'firstName',
              value: '',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Last Name',
              },
              name: 'lastName',
              value: '',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Address 1',
              },
              name: 'address1',
              value: '',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Address 2',
              },
              value: '',
              name: 'address2',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'City',
              },
              value: '',
              name: 'city',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VSelect',
              attributes: {
                label: 'State',
                items: states,
              },
              value: '',
              name: 'state',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Zip Code',
              },
              value: '',
              name: 'zip',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VSelect',
              attributes: {
                label: 'Country',
                items: ['United States'],
              },
              value: 'United States',
              name: 'country',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Email Address',
              },
              value: '',
              name: 'email',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Phone Number',
              },
              value: '',
              name: 'phone',
              required: false,
              customField: false,
              hidden: false,
              style: '',
            },
          ],
        },
        {
          key: nanoid(),
          type: 'Column',
          name: 'shippingAddress',
          fields: [
            {
              id: nanoid(),
              type: 'HppEnableButton',
              attributes: {
                label: 'Use different shipping information',
              },
              name: 'shippingEnabled',
              value: '',
              required: false,
              customField: false,
              hidden: false,
              style: 'grid-column-start: 1; grid-column-end: 3',
            },
            {
              id: nanoid(),
              type: 'HppHeading',
              attributes: {
                label: '',
              },
              name: 'shippingHeading',
              value: 'Shipping Information',
              required: false,
              customField: false,
              hidden: true,
              style: 'grid-column-start: 1; grid-column-end: 3',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Company Name',
              },
              name: 'company',
              value: '',
              required: false,
              customField: false,
              hidden: true,
              style: 'grid-column-start: 1; grid-column-end: 3',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'First Name',
              },
              name: 'firstName',
              value: '',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Last Name',
              },
              name: 'lastName',
              value: '',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Address 1',
              },
              name: 'address1',
              value: '',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Address 2',
              },
              value: '',
              name: 'address2',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'City',
              },
              value: '',
              name: 'city',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VSelect',
              attributes: {
                label: 'State',
                items: states,
              },
              value: '',
              name: 'state',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Zip Code',
              },
              value: '',
              name: 'zip',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VSelect',
              attributes: {
                label: 'Country',
                items: ['United States'],
              },
              value: '',
              name: 'country',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Email Address',
              },
              value: '',
              name: 'email',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
            {
              id: nanoid(),
              type: 'VTextField',
              attributes: {
                label: 'Phone Number',
              },
              value: '',
              name: 'phone',
              required: false,
              customField: false,
              hidden: true,
              style: '',
            },
          ],
        },
        {
          key: nanoid(),
          type: 'Column',
          name: 'paymentMethod',
          fields: [
            {
              id: nanoid(),
              type: 'HppCardForm',
              style: 'grid-column-start: 1; grid-column-end: 3',
              attributes: {
                values: {
                  amount: 0,
                  type: PaymentType.CREDIT,
                  creditCard: {
                    method: '',
                    cardNumber: '',
                    expMonth: '',
                    expYear: '',
                    cvv: '',
                  },
                  ach: {
                    routingNumber: '',
                    accountNumber: '',
                    accountHolderType: AchAccountHolderType.PERSONAL,
                    accountType: AchAccountType.CHECKING,
                  },
                },
              },
              name: 'payment',
              value: '',
              required: false,
              customField: false,
              hidden: false,
            },
          ],
        },
      ],
    },
  ];
}

export function toDisplayText(pageStatus: HostedPaymentPageStatus): HostedPaymentPageStatusDisplay {
  switch (pageStatus) {
    case HostedPaymentPageStatus.ACTIVE:
      return HostedPaymentPageStatusDisplay.ACTIVE;
    case HostedPaymentPageStatus.ACTIVE_PENDING:
      return HostedPaymentPageStatusDisplay.ACTIVE_PENDING;
    case HostedPaymentPageStatus.NOT_ACTIVE:
    default:
      return HostedPaymentPageStatusDisplay.NOT_ACTIVE;
  }
}

export function initHostedPaymentPageConfig(): HostedPaymentPageConfig {
  return {
    html: '',
    css: '',
    styles: '',
    components: '',
    assets: '',
  };
}

export function initHostedPaymentPage(): HostedPaymentPageUpdate {
  return {
    name: '',
    description: null,
    config: [],
    creditCardProcessorId: null,
    achProcessorId: null,
    isFixedAmount: true,
    amount: 0,
    redirect: '',
    pageStatus: HostedPaymentPageStatus.NOT_ACTIVE,
    surchargePercent: 0,
    surchargeEnabled: true,
    nonCashAdjustmentEnabled: false,
    adjustmentName: '',
    adjustmentPercent: 0,
    adjustmentDollarAmount: 0,
    adjustmentInHpp: false,
    merchantContact: {},
    merchantCompanyName: '',
    isRecurring: false,
    plan: {},
  };
}

export function createUpdateModel(readModel: HostedPaymentPageResponse): HostedPaymentPageUpdate {
  return {
    id: readModel.id,
    name: readModel.name,
    description: readModel.description,
    config: readModel.config,
    creditCardProcessorId: readModel.processors.credit?.id ?? null,
    achProcessorId: readModel.processors.ach?.id ?? null,
    isFixedAmount: readModel.isFixedAmount,
    amount: readModel.amount || 0,
    redirect: readModel.redirect,
    pageStatus: readModel.pageStatus,
    surchargePercent: readModel.surchargePercent,
    surchargeEnabled: readModel.surchargeEnabled,
    nonCashAdjustmentEnabled: readModel.nonCashAdjustmentEnabled,
    adjustmentName: readModel.adjustmentName,
    adjustmentPercent: readModel.adjustmentPercent,
    adjustmentDollarAmount: readModel.adjustmentDollarAmount,
    adjustmentInHpp: readModel.adjustmentInHpp,
    merchantContact: readModel.merchantContact,
    merchantCompanyName: readModel.merchantCompanyName,
    isRecurring: readModel.isRecurring,
    plan: readModel.plan,
    taxRate: readModel.taxRate,
  };
}

export function displayAmount(filters: any, hpp: HostedPaymentPageBase | null | undefined): string {
  if (!hpp) {
    return '';
  }
  return hpp.isFixedAmount ? `Fixed: $${filters.dollarsAndCents(hpp.amount)}` : 'User provided';
}

export const toggleShippingInformation = (row: HppRow) => ({
  ...row,
  columns: row.columns.map((column) => ({
    ...column,
    fields:
      column.name === 'shippingAddress'
        ? column.fields.map((field) => ({
          ...field,
          attributes: {
            ...field.attributes,
            label:
                // eslint-disable-next-line no-nested-ternary
                field.name === 'shippingEnabled'
                  ? (field.attributes as HppFieldAttribute).label
                    === 'Use different shipping information'
                    ? 'Hide shipping information'
                    : 'Use different shipping information'
                  : (field.attributes as HppFieldAttribute).label,
          },
          hidden: field.name === 'shippingHeading' ? !field.hidden : field.hidden,
        }))
        : column.fields,
  })),
});
