



































































































import Vue from 'vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import DeleteModal from '@/common/components/modals/DeleteModal.vue';
import api from '@/common/api';
import LoadingModal from '@/common/components/LoadingModal.vue';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      deleteModal: false,
      reLoadData: false,
      loadingText: '',
      isSubmitting: false,
      invoiceToDelete: {} as any,
      config: {
        pageTitle: 'Manage Invoices',
        header: 'Invoices',
        routerBase: 'merchant.invoices',
        apiPath: 'merchant/invoice',
        entityName: 'invoice',
        identifierProperty: 'invoiceNumber',
        reLoadData: false,
        reportFileName: 'invoice_report',
        createButtonText: 'Create New',
        filters: [
          {
            name: 'status',
            display: 'Status',
            value: '',
            type: EntityFilterType.SELECT,
            items: [
              { display: 'All', value: -1 },
              { display: 'Draft', value: 1 },
              { display: 'Sent', value: 2 },
              { display: 'Partially Paid', value: 3 },
              { display: 'Paid', value: 4 },
              { display: 'Closed', value: 5 },
            ] as any[],
          },
        ],
        headers: [
          {
            text: 'ID',
            value: 'id',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
            align: ' d-none',
          },
          {
            text: 'Invoice #',
            value: 'invoiceNumber',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'invoiceCustomer',
            value: 'invoiceCustomer',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            align: ' d-none',
          },
          {
            text: 'lineItems',
            value: 'lineItems',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            align: ' d-none',
          },
          {
            text: 'Status',
            value: 'status',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Billed To',
            value: 'billedTo',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Issue Date',
            value: 'invoiceInformation',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Due Date',
            value: 'invoiceInformation.dueDateFormatted',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Amount Paid',
            value: 'amountPaid',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Amount Due',
            value: 'amountDue',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '15rem',
          },
        ],
      },
    };
  },
  methods: {
    promptForDelete(invoice: any) {
      this.deleteModal = true;
      this.invoiceToDelete = invoice;
    },
    resend(invoice: any) {
      this.loadingText = 'Resending invoice...';
      this.isSubmitting = true;
      api
        .post(`/merchant/invoice/${invoice.id}/send`)
        .then(this.showSuccess)
        .catch(this.handleSaveError)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    downloadPDF(invoice: any) {
      if (invoice.invoiceInformation.pdfUrl) {
        window.location.href = invoice.invoiceInformation.pdfUrl;
      }
    },
    deleteEntity() {
      this.loadingText = 'Deleting invoice...';
      this.isSubmitting = true;
      this.deleteModal = false;
      this.reLoadData = false;
      api
        .delete(`${this.config.apiPath}/${this.invoiceToDelete.id}`)
        .then(() => {
          this.invoiceToDelete = null;
          this.reLoadData = true;
          this.isSubmitting = false;
        })
        .catch(({ response }) => {
          const message = response?.data?.message || 'Failed to delete invoice. Try again.';
          this.$toasted.error(`${message} (${response.status})`);
        });
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    showSuccess() {
      this.isSubmitting = false;
      this.$toasted.success('Invoice resent successfully!');
    },
    handleSaveError({ response }: { response: any }) {
      if (response.status === 422) {
        let msg: any = '';
        if (response?.data?.errors) {
          const errors = response?.data?.errors;
          Object.keys(errors).forEach((key: any, idx: any) => {
            if (idx > 0) msg += '<br />';
            msg += `${errors[key]}`;
          });
        } else if (response?.data?.message) {
          msg += response?.data?.message;
        }

        if (msg) this.$toasted.error(msg);
        else this.$toasted.error(`There was a problem resending the invoice. (${response.status})`);
      } else {
        this.$toasted.error(`There was a problem resending the invoice. (${response.status})`);
      }
    },
  },
  computed: {
    ...mapGetters(['isInvoicingEnabled']),
    currentDate() {
      const yourDate = new Date();
      return yourDate.toISOString().split('T')[0]; // returns date in yyyy-mm-dd format
    },
  },
  components: {
    EntitiesTable,
    DeleteModal,
    LoadingModal,
    PaidFeatureNotEnabled,
  },
});
