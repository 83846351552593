import { MerchantFeature, MerchantFeatureName } from '@/common/types/features.types';
import { isEnabled } from 'vue-feature-flipping';
import { Feature } from '@/features.types';
import {
  Merchant, SellRate,
} from '../merchants.types';
import { RateGroup, RateGroupName } from './sellRates.types';

export function rateGroupings(): RateGroupName[][] {
  const row1 = [
    RateGroupName.GATEWAY,
    RateGroupName.ELECTRONIC_CHECK,
  ];
  const row2 = [
    RateGroupName.CASH,
    RateGroupName.CUSTOMER_VAULT,
  ];

  if (isEnabled(Feature.LEVEL23)) {
    row1.push(RateGroupName.ENHANCED_DATA_LEVEL_3);
  }

  if (isEnabled(Feature.SHOPPING_CARTS)) {
    row1.push(RateGroupName.SHOPPING_CART);
  }

  if (isEnabled(Feature.SURCHARGING)) {
    row2.push(RateGroupName.SURCHARGING);
  }

  if (isEnabled(Feature.NON_CASH_ADJUSTMENT)) {
    row2.push(RateGroupName.NON_CASH_ADJUSTMENT);
  }

  if (isEnabled(Feature.INVOICING)) {
    row1.push(RateGroupName.INVOICING);
  }

  return [
    row1,
    row2,
  ];
}

export function initGroupedRates(): RateGroup[] {
  return [
    {
      name: RateGroupName.GATEWAY,
      rates: [],
      feature: null,
    },
    {
      name: RateGroupName.AUTOMATIC_CARD_UPDATER,
      rates: [],
      feature: {
        name: MerchantFeatureName.AUTOMATIC_CARD_UPDATER,
        enabled: false,
      },
    },
    {
      name: RateGroupName.CASH,
      rates: [],
      feature: {
        name: MerchantFeatureName.CASH,
        enabled: false,
      },
    },
    {
      name: RateGroupName.CUSTOMER_VAULT,
      rates: [],
      feature: {
        name: MerchantFeatureName.CUSTOMER_VAULT,
        enabled: false,
      },
    },

    {
      name: RateGroupName.INVOICING,
      rates: [],
      feature: {
        name: MerchantFeatureName.INVOICING,
        enabled: false,
      },
    },
    {
      name: RateGroupName.ENHANCED_DATA_LEVEL_3,
      rates: [],
      feature: {
        name: MerchantFeatureName.ENHANCED_DATA_LEVEL_3,
        enabled: false,
      },
    },
    {
      name: RateGroupName.ELECTRONIC_CHECK,
      rates: [],
      feature: {
        name: MerchantFeatureName.ELECTRONIC_CHECK,
        enabled: false,
      },
    },
    {
      name: RateGroupName.SURCHARGING,
      rates: [],
      feature: {
        name: MerchantFeatureName.SURCHARGING,
        enabled: false,
      },
    },
    {
      name: RateGroupName.SHOPPING_CART,
      rates: [],
      feature: {
        name: MerchantFeatureName.SHOPPING_CART,
        enabled: false,
      },
    },
    {
      name: RateGroupName.NON_CASH_ADJUSTMENT,
      rates: [],
      feature: {
        name: MerchantFeatureName.NON_CASH_ADJUSTMENT,
        enabled: false,
      },
    },
  ];
}

export function createRateGroups(sellRate: SellRate[], features: MerchantFeature[]): RateGroup[] {
  const rateGroups: RateGroup[] = initGroupedRates();
  sellRate.forEach((rate) => {
    const rateGroup = rateGroups.find((group) => group.name === rate.group);
    if (rateGroup) {
      rateGroup.rates.push(rate);
    }
  });
  features.forEach((feature) => {
    const rateFeature = rateGroups.find((group) => group.feature?.name === feature.name);
    if (rateFeature) {
      rateFeature.feature!.enabled = feature.enabled;
    }
  });
  return rateGroups;
}

export function fillMerchantWithSellRates(merchant: Merchant, rateGroups: RateGroup[]): Merchant {
  const updatedMerchant = { ...merchant };
  updatedMerchant.sellRates = rateGroups.flatMap((group) => group.rates);

  const features: MerchantFeature[] = rateGroups
    .filter((group) => group.feature)
    .map((group) => group.feature!);

  features.forEach((feature) => {
    const merchantFeature = updatedMerchant.features.find((mf) => mf.name === feature.name);
    if (typeof merchantFeature !== 'undefined') {
      merchantFeature!.enabled = feature.enabled;
    }
  });

  return updatedMerchant;
}
