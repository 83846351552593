export default [
  {
    text: 'ID',
    value: 'id',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '6rem',
  },
  {
    text: 'Affiliate',
    value: 'affiliate',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '6rem',
  },
  {
    text: 'Merchant',
    value: 'company',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '6rem',
  },
  {
    text: 'Contact',
    value: 'contact',
    sortable: false,
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '6rem',
  },
  {
    text: 'Created',
    value: 'createdAt',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '8rem',
  },
  {
    text: 'Last Transaction',
    value: 'lastTransactionAt',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '6rem',
  },
  {
    text: 'Gateway URL',
    value: 'domain',
    sortable: false,
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '6rem',
  },
  {
    text: 'Stage',
    value: 'status',
    align: 'center',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '6rem',
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
    align: 'center',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '8rem',
  },
];
