














import Vue from 'vue';
import { initValidationErrors } from '@/common/validation/validation.types';
import { BillingAmountModel } from '@/common/types/billingRecurrences.types';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => BillingAmountModel,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => initValidationErrors(),
    },
  },
  data() {
    return {};
  },
  methods: {
    setAmount(value: number) {
      this.value.amount = value;
    },
  },
});
