








































import api from '@/common/api';
import Vue from 'vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { initCategory } from '@/merchant/views/categories/categories.types';


export default Vue.extend({
  watch: {
    editModal() {
      this.loadCategory();
    },
  },
  data() {
    return {
      name: '',
      category: initCategory(),
    };
  },
  props: {
    editModal: {
      type: Boolean,
    },
    id: {
      required: true,
      type: String,
    },
  },
  methods: {
    save() {
      if (!this.isValid()) {
        return;
      }
      this.$emit('updateCategory', this.category);
    },
    loadCategory() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api.get(`merchant/category/${this.id}`).then(this.setCategory)
        .catch(this.handleLoadingError);
    },
    setCategory({ data }: { data: any }) {
      this.category = data;
      (this.$refs.loader as any).setReady();
    },
    handleLoadingError(msg: string) {
      if (this.$refs.alertLoader) {
        (this.$refs.alertLoader as any).setReady();
      }
      this.$toasted.error(msg);
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    PageLoader,
  },
});
