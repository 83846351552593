













































































































import Vue from 'vue';

import api from '@/common/api';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import { Transaction, TransactionStatus } from '@/common/types/transactions.types';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { PaymentType } from '@/common/types/payments.types';
import { generatePaymentMethodTableData } from '@/common/util/payments.util';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { Batch } from '../components/batches/batches.types';
import TransactionDetails from '../transactions/components/TransactionDetails.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      closeBatchDialog: false,
      selectedTransactionId: '' as string,
      drawer: false,
      generatePaymentMethodTableData,
      EntityFilterType,
      PaymentType,
      TransactionStatus,
      batch: null as Batch | null,
      loadingTransactions: true as boolean,
      transactions: [] as Transaction[],
      options: {} as any,
      meta: {} as any,
      config: {
        pageTitle: null,
        routerBase: 'merchant.transactions',
        apiPath: `batches/${this.id}/transactions`,
        entityName: 'transaction',
        identifierProperty: 'id',
        header: 'Transactions Detail Report',
        reportFileName: 'batch_transactions_report',
        filters: [],
        headers: [
          {
            text: 'Amount',
            value: 'refundedAmount',
            align: ' d-none',
            sortable: false,
          },
          {
            text: 'Date',
            value: 'createdAt',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'ID',
            value: 'id',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Status',
            value: 'status',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Payment method',
            value: 'paymentMethod',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Customer',
            value: 'customer',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Subscription',
            value: 'subscriptionId',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Amount',
            value: 'amount',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
        ],
      },
    };
  },
  created() {
    document.title = 'RiseOS-View Batch';
  },
  mounted() {
    this.loadBatch();
  },
  watch: {
    drawer() {
      if (!this.drawer) {
        this.selectedTransactionId = '';
      }
    },
    options: {
      handler() {
        this.loadBatchTransactions();
      },
      deep: true,
    },
  },
  methods: {
    loadBatch() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api
        .get(`/batches/${this.id}`)
        .then(this.setBatch)
        .catch(() => {
          const message = this.$errorMessages.get('batches.load');
          this.$toasted.error(message);
          (this.$refs.loader as any).setError();
        });
    },
    setBatch({ data }: { data: JsonApiSingleResponse<Batch> }) {
      if (data.data === undefined || data.data.id === undefined) {
        return;
      }
      this.batch = data.data;

      this.loadBatchTransactions();
      (this.$refs.loader as any).setReady();
    },
    loadBatchTransactions() {
      this.loadingTransactions = true;
      const { page, itemsPerPage } = this.options;
      const params: any = {
        page,
        perPage: itemsPerPage,
        role: '',
      };
      api
        .get(`/batches/${this.id}/transactions`, { params })
        .then(this.setBatchTransactions)
        .catch(() => {
          const message = this.$errorMessages.get('batches.transactions_load');
          this.$toasted.error(message);
          (this.$refs.loader as any).setError();
        });
    },
    getAmount(transaction: Transaction) {
      if (transaction.refundedAmount && transaction.refundedAmount !== 0) {
        return `($${(this.$options.filters as any).dollarsAndCents(transaction.refundedAmount)})`;
      }

      return `$${(this.$options.filters as any).dollarsAndCents(transaction.amount)}`;
    },
    setBatchTransactions({ data }: { data: JsonApiArrayResponse<Transaction> }) {
      if (data.data === undefined) {
        this.loadingTransactions = false;
        return;
      }
      this.meta = data.meta;
      this.transactions = data.data;
      this.loadingTransactions = false;
    },
    viewTransaction(transaction: Transaction) {
      this.selectedTransactionId = transaction.id!;
      this.drawer = !this.drawer;
    },
    refundClass(transaction: Transaction) {
      return (transaction.refundedAmount && transaction.refundedAmount !== 0) ? 'red--text' : '';
    },
    closeBatch() {
      (this.$refs.loader as any).setLoading();

      api.post(`/batches/${this.id}/close`)
        .then(() => {
          (this.$refs.loader as any).setReady();
          this.$router.push({ name: 'merchant.reports.batch.settlements' });
        })
        .catch(() => {
          const message = this.$errorMessages.get('batches.close_batch');
          this.$toasted.error(message);
          (this.$refs.loader as any).setError();
        });
    },
  },
  components: {
    PageHeader,
    PageLoader,
    EntitiesTable,
    TransactionDetails,
  },
});
