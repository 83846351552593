













import Vue from 'vue';
import { WeekDay } from '@/common/types/dateTime.types';
import { WeekDayModel } from '@/common/types/billingRecurrences.types';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => WeekDayModel,
      required: true,
    },
  },
  data() {
    return {
      WeekDay,
    };
  },
  computed: {
    weekDays(): any[] {
      return [
        {
          value: WeekDay.Monday,
          display: WeekDay[WeekDay.Monday],
        },
        {
          value: WeekDay.Tuesday,
          display: WeekDay[WeekDay.Tuesday],
        },
        {
          value: WeekDay.Wednesday,
          display: WeekDay[WeekDay.Wednesday],
        },
        {
          value: WeekDay.Thursday,
          display: WeekDay[WeekDay.Thursday],
        },
        {
          value: WeekDay.Friday,
          display: WeekDay[WeekDay.Friday],
        },
        {
          value: WeekDay.Saturday,
          display: WeekDay[WeekDay.Saturday],
        },
        {
          value: WeekDay.Sunday,
          display: WeekDay[WeekDay.Sunday],
        },
      ];
    },
  },
});
