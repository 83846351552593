var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"feature-flipping",rawName:"v-feature-flipping",value:(("" + (_vm.Feature.SHOPPING_CARTS))),expression:"`${Feature.SHOPPING_CARTS}`"}],staticClass:"tw-px-4"},[_c('PageHeader',{staticClass:"tw-mx-auto tw-ml-4"},[_vm._v("Add New Shopping Cart")]),(_vm.showSuccess)?_c('div',[_c('Alert',{attrs:{"alertType":_vm.AlertType.Success,"header":"Shopping Cart","message":_vm.alertMessage,"showToggle":false},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}})],1):_c('div',{staticClass:"card bolded-labels"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('div',{staticClass:"tw-w-full"},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("First and Last Name")]),_c('v-text-field',{attrs:{"label":"First and Last Name","outlined":"","dense":"","single-line":"","rules":[
                      function (v) { return !!v || 'Name is required.'; } ]},model:{value:(_vm.newShoppingCart.name),callback:function ($$v) {_vm.$set(_vm.newShoppingCart, "name", $$v)},expression:"newShoppingCart.name"}})],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("DBA Company Name")]),_c('v-text-field',{attrs:{"label":"DBA Company Name","outlined":"","dense":"","single-line":"","rules":[
                      function (v) { return !!v || 'Company name is required.'; } ]},model:{value:(_vm.newShoppingCart.dbaName),callback:function ($$v) {_vm.$set(_vm.newShoppingCart, "dbaName", $$v)},expression:"newShoppingCart.dbaName"}})],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"tw-py-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("Email Address")]),_c('v-text-field',{attrs:{"label":"Email Address","outlined":"","dense":"","single-line":"","rules":[
                        function (v) { return !!v || 'Email is required.'; },
                        function (v) { return !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)
                          || 'Email must be valid'; } ]},model:{value:(_vm.newShoppingCart.email),callback:function ($$v) {_vm.$set(_vm.newShoppingCart, "email", $$v)},expression:"newShoppingCart.email"}})],1)]),_c('v-col',{staticClass:"tw-py-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("Phone Number")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({
                      mask: '(###) ###-####',
                      tokens: { '#': { pattern: /\d/ } },
                    }),expression:"{\n                      mask: '(###) ###-####',\n                      tokens: { '#': { pattern: /\\d/ } },\n                    }"}],attrs:{"label":"Phone Number","outlined":"","dense":"","single-line":"","rules":[function (v) { return !!v || 'Phone number is required.'; }],"id":"phoneNumber"},model:{value:(_vm.newShoppingCart.phone),callback:function ($$v) {_vm.$set(_vm.newShoppingCart, "phone", $$v)},expression:"newShoppingCart.phone"}})],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("Your Store Name")]),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"Your Store Name","outlined":"","dense":"","single-line":"","readonly":_vm.shouldDisable,"rules":[
                      function (v) { return !!v || 'Store name is required.'; } ]},model:{value:(_vm.newShoppingCart.storeName),callback:function ($$v) {_vm.$set(_vm.newShoppingCart, "storeName", $$v)},expression:"newShoppingCart.storeName"}}),_c('span',{staticClass:"tw-text-xs tw-italic tw--mt-1 tw-block"},[_vm._v(" Your Store Nickname will be autogenerated and used as the temporary domain name for your new store. You can add your own custom domain name to the shopping cart if you wish once you are inside the platform. ")])],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("Choose Password")]),_c('v-text-field',{attrs:{"label":"Choose Password","type":"password","outlined":"","dense":"","single-line":"","rules":[
                      function (v) { return !!v || 'Password is required.'; },
                      function (v) { return !v ||
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(v)
                          || 'Invalid Password'; } ]},model:{value:(_vm.newShoppingCart.mypassword),callback:function ($$v) {_vm.$set(_vm.newShoppingCart, "mypassword", $$v)},expression:"newShoppingCart.mypassword"}}),_c('span',{staticClass:"tw-text-xs tw-italic tw--mt-3 tw-block"},[_vm._v(" Minimum 8 characters long. We also recommend it contains at least one number, one alphabet and one symbol. ")])],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('label',[_vm._v("Industry Type")]),_c('v-select',{attrs:{"items":_vm.industry,"item-text":"display","item-value":"value","outlined":"","dense":"","label":"Store Category","single-line":"","rules":[
                      function (v) { return !!v || 'Store Category is required.'; } ]},model:{value:(_vm.newShoppingCart.storeCategory),callback:function ($$v) {_vm.$set(_vm.newShoppingCart, "storeCategory", $$v)},expression:"newShoppingCart.storeCategory"}})],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"tw-pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('v-checkbox',{attrs:{"rules":[
                      function (v) { return !!v || 'Please accept Terms and Conditions.'; } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to the "),_c('a',{attrs:{"target":"_blank","href":"https://www.corecommerce.com/terms-and-conditions/"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" terms and conditions ")])])]},proxy:true}]),model:{value:(_vm.newShoppingCart.myterms),callback:function ($$v) {_vm.$set(_vm.newShoppingCart, "myterms", $$v)},expression:"newShoppingCart.myterms"}})],1)]),_c('v-col',{staticClass:"tw-pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Save")])],1)])],1)],1)]),_c('LoadingModal',{attrs:{"loadingText":"Saving Store Information...","showModal":_vm.isSubmitting}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }