



















































































































































import Vue from 'vue';
import HppCardForm from '@/merchant/views/hosted-payments/design-components/HppCardForm.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import getDomain from '@/common/domain';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import { initHppStates } from '@/common/util/hostedPaymentPages.util';
import api from '@/common/api';
import axios from 'axios';
import getSubdomain from '@/common/context';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { Transaction, TransactionStatus } from '@/common/types/transactions.types';
import { CustomFieldFillable } from '@/common/components/support/support.types';
import PaymentDetailsModal from '@/common/components/payments/PaymentDetailsModal.vue';
import { InvoiceCustomer, InvoiceInformationInterface } from './invoices.types';
import PaymentCustomFields from '../components/customer-payment/PaymentCustomFields.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
    calledFrom: {
      type: String,
      default: '',
      required: false,
    },
  },
  mounted() {
    axios
      .get(`${getDomain('api')}/countries`).then(({ data: { data: countries } }) => {
        this.countries = countries;
      });
    this.getInvoice();

    document.documentElement.classList.toggle('tw-dark', false);
  },
  data() {
    return {
      values: {
        amount: '' as string,
        type: '',
        creditCard: {
          cardNumber: '',
          expMonth: '',
          expYear: '',
          cvv: '',
        },
        ach: {
          accountHolderType: 'personal',
          accountType: 'checking',
          accountNumber: '',
          routingNumber: '',
        },
      } as any,
      invoiceCustomer: {} as InvoiceCustomer,
      states: initHppStates(),
      dueAmount: 0 as number,
      amountPaid: 0 as number,
      invoiceNumber: '' as string,
      issueDate: '' as string,
      dueDate: '' as string,
      invoiceInformation: {} as InvoiceInformationInterface,
      customFields: [] as CustomFieldFillable[],
      creditCardProcessor: '' as string,
      achProcessor: '' as string,
      countries: [],
      surchargePercent: 0 as number,
      surchargeEnabled: false as boolean,
      invoiceId: '' as string,
      token: '' as string,
      isSubmitting: false as boolean,
      txnResponse: null as JsonApiSingleResponse<any> | null,
      reCaptcha: false as boolean,
    };
  },
  methods: {
    submit() {
      if (!this.isValid) {
        return;
      }

      this.isSubmitting = true;
      const payload: any = {
        amount: this.values.amount,
        description: 'Invoice payment',
        paymentMethod: {
          type: this.values.type,
          creditCard: this.values.creditCard,
          ach: this.values.ach,
        },
      };
      if (this.values.type === 'credit') {
        payload.paymentMethod.creditCard.method = 'capture';
        payload.paymentMethod.processorId = this.creditCardProcessor;
      } else {
        payload.paymentMethod.processorId = this.achProcessor;
      }
      payload.customer = {
        billingAddress: this.invoiceCustomer,
      };
      payload.customFields = this.customFields;
      payload.transactionType = 'moto';
      payload.internalNote = '';
      payload.isLevel3Transaction = false;
      payload.invoiceId = this.invoiceId;
      payload.dueAmount = this.dueAmount;
      payload.amountPaid = this.amountPaid;
      api.post(`${getDomain('api')}/transactions`, payload, { skipAuthentication: true, headers: { common: { Authorization: `Bearer ${this.token}`, 'x-merchant-proxy': getSubdomain() } } } as any)
        .then(({ data }: { data: JsonApiSingleResponse<Transaction> }) => {
          this.isSubmitting = false;
          this.txnResponse = data;
          (this.$refs.paymentDetailsModal as any).setVisibility(true);
          this.$toasted.success('Transaction processed!');
        }).catch((this as any).handleTransactionError);
    },
    handleTransactionError({ response }: any) {
      this.submitActions(false);
      this.txnResponse = response;
      (this.$refs.paymentDetailsModal as any).setVisibility(true);
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    getInvoice() {
      axios
        .get(`${getDomain('api')}/invoice/${this.id}/pay`, {
          params: {
            subdomain: getSubdomain(),
          },
        }).then(this.setInvoice)
        .catch(() => {
          if (this.$refs.loader) {
            (this.$refs.loader as any).setError();
          }
        });
    },
    setInvoice({ data }: { data: JsonApiSingleResponse<any> }) {
      this.values!.amount = data.data.amountDue;
      this.values.type = 'credit';
      this.invoiceCustomer = data.data.invoiceCustomer;
      this.dueAmount = data.data.amountDue;
      this.amountPaid = data.data.amountPaid;
      this.invoiceNumber = data.data.invoiceNumber;
      this.issueDate = data.data.invoiceInformation.issueDate;
      this.dueDate = data.data.invoiceInformation.dueDateFormatted;
      this.customFields = data.data.customFields;
      this.creditCardProcessor = data.data
        .invoiceInformation.processorInformation.creditCardProcessorId;
      this.achProcessor = data.data.invoiceInformation.processorInformation.achProcessorId;
      this.invoiceInformation = data.data.invoiceInformation;
      this.surchargePercent = data.data.surchargePercent;
      this.surchargeEnabled = data.data.surchargeEnabled;
      this.token = data.data.token;
      this.invoiceId = data.data.invoiceId;
      this.$store.commit('setHppTaxRate', data.data.invoiceInformation.tax || 0);
      (this.$refs.loader as any).setReady();
    },
    resetResponse() {
      if ([
        TransactionStatus.CAPTURED,
        TransactionStatus.AUTHORIZED,
      ].includes(this.txnResponse?.data.status)) {
        if (this.calledFrom === 'web') {
          window.close();
        } else {
          this.$router.go(0);
        }
      }
      this.txnResponse = null;
    },
    robotCheck(robot: boolean) {
      this.reCaptcha = robot;
    },
    closeMe() {
      window.close();
    },
  },
  computed: {
    mobileView(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    isValid(): boolean {
      return ((this as any).$refs.form as any).validate();
    },
  },
  components: {
    HppCardForm,
    PageLoader,
    LoadingModal,
    PaymentDetailsModal,
    PaymentCustomFields,
  },
});
