import { DefaultLevel3Setting } from '@/common/components/support/support.types';

import {
  CustomerPaymentConfigWithCustomFields,
} from '@/merchant/views/components/customer-payment/customerPayments.types';


export function getlLevel1Progress(value: CustomerPaymentConfigWithCustomFields) : boolean {
  let validLevel1Fields = true;

  let creditCardNumber = value?.paymentMethod?.creditCard?.cardNumber;
  creditCardNumber = creditCardNumber !== undefined ? creditCardNumber.replaceAll(/\s/g, '') : '';
  const amount = value.isRecurring
    ? value.recurrence.billingRecurrence.amount
    : value.transaction.amount;
  if (creditCardNumber === '' || amount === 0 || Number.isNaN(Number(amount))) {
    validLevel1Fields = false;
  }

  return validLevel1Fields;
}

export function getEnhancedFieldProgress(
  enhancedFields: any,
  defaultLevel3Setting: DefaultLevel3Setting,
) : any {
  let validEnhancedFields = true;

  const enhancedFieldVals = enhancedFields;

  const eFields = [];

  Object.keys(enhancedFieldVals).forEach((key: any) => {
    // For Level 2 'Sales Tax' field we are using value of Level 3 'salesTaxAmount'
    if (enhancedFieldVals[key].name === '11.Sales_Tax' && typeof defaultLevel3Setting !== 'undefined') {
      enhancedFieldVals[key].value = defaultLevel3Setting.salesTaxAmount;
    }

    if (enhancedFieldVals[key].value === '') {
      validEnhancedFields = false;
    } else {
      eFields.push(enhancedFieldVals[key].name);
    }
  });

  const enhancedElements = document.getElementsByClassName('enhanced_class error--text') as HTMLCollectionOf<Element>;
  if (enhancedElements.length > 0) {
    validEnhancedFields = false;
  } else if (eFields.length !== Object.keys(enhancedFieldVals).length) {
    validEnhancedFields = false;
  }

  return {
    enhancedFieldVals,
    validEnhancedFields,
  };
}

export function getLevel3FieldProgress(defaultLevel3Setting: DefaultLevel3Setting): boolean {
  const level3Fields:any[] = [];
  const level3Vals = defaultLevel3Setting;
  let validLevel3Fields = true;

  Object.keys(level3Vals).forEach((key: any) => {
    const value = level3Vals[key as keyof DefaultLevel3Setting];
    if (value === '') {
      if (key === 'orderId') {
        if (!defaultLevel3Setting?.orderIdUseTransactionTd) {
          validLevel3Fields = false;
        } else {
          level3Fields.push(key);
        }
      } else if (key === 'poNumber') {
        if (!defaultLevel3Setting?.poNumberUseTransactionId) {
          validLevel3Fields = false;
        } else {
          level3Fields.push(key);
        }
      } else if (key === 'orderDescription') {
        if (!defaultLevel3Setting?.orderDescriptionUseTransactionId) {
          validLevel3Fields = false;
        } else {
          level3Fields.push(key);
        }
      } else if (key === 'orderDate') {
        if (!defaultLevel3Setting?.orderDateUseTransactionDate) {
          validLevel3Fields = false;
        } else {
          level3Fields.push(key);
        }
      } else if (!['orderId',
        'poNumber',
        'orderDescription',
        'orderIdUseTransactionTd',
        'poNumberUseTransactionId',
        'orderDescriptionUseTransactionId',
        'orderDateUseTransactionDate',
      ].includes(key)
      ) {
        validLevel3Fields = false;
      }
    } else {
      level3Fields.push(key);
    }
  });

  if (level3Fields.length !== Object.keys(defaultLevel3Setting).length) {
    validLevel3Fields = false;
  }

  return validLevel3Fields;
}

export function getLevelProgress(
  level1Progress: boolean,
  level2Progress: boolean,
  level3Progress: boolean,
) : any {
  let level1progressColor; let level2progressColor; let
    level3progressColor = '';
  let progressCount = 0;

  if (level1Progress) {
    level1progressColor = '';
    progressCount = 39.3333;
  } else {
    level1progressColor = '#BBDEFB';
  }

  if (level1Progress && level2Progress) {
    level2progressColor = '';
    progressCount = 71.6666;
  } else {
    level2progressColor = '#BBDEFB';
  }

  if (level1Progress && level2Progress && level3Progress) {
    level3progressColor = '';
    progressCount = 100;
  } else {
    level3progressColor = '#BBDEFB';
  }

  return {
    level1progressColor,
    level2progressColor,
    level3progressColor,
    progressCount,
  };
}
