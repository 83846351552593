export function isValidNineRoutingNumber(routingNumber: string): boolean {
  return (
    /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/.test(routingNumber)
  );
}

export function isCheckDigitRoutingNumberValid(routingNumber: string): boolean {
  let checkSum: number = 0;

  for (let i = 0; i < routingNumber.length; i += 3) {
    checkSum += parseInt(routingNumber[i], 10) * 3;
    checkSum += parseInt(routingNumber[i + 1], 10) * 7;
    checkSum += parseInt(routingNumber[i + 2], 10);
  }

  return checkSum !== 0 && (checkSum % 10) === 0;
}
