<template>
  <p-container>
    <a-back-link to="merchant.settings.tax-rates">
      Back to Tax Rates
    </a-back-link>
    <p-card style="max-width: 54rem">
      <template #header>
        <h2>
          {{ editMode ? 'Tax Rate Edit Page' : 'Tax Rate Creation Page' }}
        </h2>
      </template>

      <v-form v-model="validForm" @submit.prevent="saveTaxRate" style="max-width: 32rem">
        <v-text-field
          v-model="taxRateForm.name"
          label="Tax Name"
          :rules="[
            (v) => !!v || 'Name is required',
            (v) => v.length <= 30 || 'Name can only go up to 30 characters'
          ]"
          :disabled="loading || saving"
          :loading="loading"
          outlined
          dense
        />

        <v-text-field
          v-model.number="taxRateForm.taxRate"
          label="Tax Rate %"
          :rules="[
            (v) => !!v || 'Tax Rate is required',
            (v) => v >= 0 || 'Tax Rate cannot be negative',
            (v) => v <= 100 || 'Tax Rate cannot be greater than 100'
          ]"
          :disabled="loading || saving"
          :loading="loading"
          type="number"
          step="0.001"
          min="0"
          max="99.999"
          suffix="%"
          outlined
          dense
          v-blur-decimals="3"
          style="max-width: 17rem"
        />

        <v-checkbox
          v-model="taxRateForm.isDefault"
          label="Use as Default"
          :disabled="loading || saving"
          hide-details
        />

        <v-checkbox
          v-model="taxRateForm.includeInHpp"
          label="Include in Hosted Payment Pages"
          :disabled="loading || saving"
          hide-details
        />

        <v-checkbox
          v-model="taxRateForm.includeInInvoices"
          label="Include in Invoices"
          :disabled="loading || saving"
          hide-details
        />

        <v-btn
          type="submit"
          color="primary"
          :disabled="!validForm || saving || loading"
          :loading="saving"
          class="mt-5"
        >
          <v-icon left>mdi-content-save-outline</v-icon>
          Save
        </v-btn>
      </v-form>
    </p-card>

    <v-dialog v-model="showConfirmDialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Are you sure you want to override your current default Tax Rate?
        </v-card-title>
        <v-card-actions>
          <v-btn @click="showConfirmDialog = false; saving = false">
            No
          </v-btn>
          <v-btn color="primary" @click="finishSaveTaxRate">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </p-container>
</template>

<script>
import api from '@/common/api';
import PContainer from '@/components/presentations/p-container.vue';
import PCard from '@/components/presentations/p-card.vue';
import ABackLink from '@/components/atoms/a-back-link.vue';

export default {
  components: { PContainer, PCard, ABackLink },
  props: {
    id: String,
  },
  data() {
    return {
      validForm: true,
      saving: false,
      loading: false,
      showConfirmDialog: false,
      taxRateForm: {
        name: '',
        taxRate: 0,
        isDefault: false,
        includeInHpp: false,
        includeInInvoices: false,
      },
    };
  },
  computed: {
    editMode() {
      return !!this.id;
    },
  },
  created() {
    if (this.editMode) this.fetchTaxRate(this.id);
  },
  methods: {
    async fetchTaxRate(id) {
      this.loading = true;

      try {
        const { data } = await api.get(`/tax-rates/${id}`);
        this.taxRateForm = {
          name: data.data.name,
          taxRate: data.data.taxRate,
          isDefault: data.data.isDefault,
          includeInHpp: data.data.includeInHpp,
          includeInInvoices: data.data.includeInInvoices,
        };
      } catch (e) {
        this.$toasted.error('There was an error while loading the tax rate. Please reload the page.');
      } finally {
        this.loading = false;
      }
    },
    async getDefaultTaxRate() {
      try {
        const { data } = await api.get('/tax-rates?isDefault=true');
        const taxRates = data.data;

        if (taxRates.length) return taxRates[0];
      } catch (e) {
        this.$toasted.error('There was an error while checking the default tax rate.');
      }

      return null;
    },
    async saveTaxRate() {
      this.saving = true;

      const defaultTaxRate = await this.getDefaultTaxRate();
      if (!defaultTaxRate || defaultTaxRate.uuid === this.id || !this.taxRateForm.isDefault) {
        this.finishSaveTaxRate();
      } else {
        this.showConfirmDialog = true;
      }
    },
    async finishSaveTaxRate() {
      this.showConfirmDialog = false;

      try {
        if (this.editMode) {
          await api.put(`/tax-rates/${this.id}`, this.taxRateForm);
        } else {
          await api.post('/tax-rates', this.taxRateForm);
        }
        this.$toasted.success('Tax Rate successfully saved.');

        await this.$router.push({ name: 'merchant.settings.tax-rates' });
      } catch (e) {
        this.$toasted.error('There was an error while saving the tax rate');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
