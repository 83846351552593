



















































import Vue from 'vue';

import PageHeader from '@/common/components/PageHeader.vue';
import { AlertType } from '@/common/components/alerts.types';
import Alert from '@/common/components/Alert.vue';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import { mapGetters } from 'vuex';
import { RateGroupName } from '@/iso/views/merchants/components/sellRates.types';
import api from '../api';
import { initSupportInfo, SupportInfo } from './support/support.types';
import PageLoader from './PageLoader.vue';

export default Vue.extend({
  props: {
    header: {
      type: String,
      required: true,
    },
    feature: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      AlertType,
      showAlert: true as boolean,
      supportInfo: initSupportInfo() as SupportInfo,
      dialog: false, // show dialog variable
    };
  },
  mounted() {
    this.loadSupportInfo();
  },
  methods: {
    loadSupportInfo(): void {
      api
        .get('support-info')
        .then(this.setSupportInfo)
        .catch(() => this.handleLoadingError('Support info not found'));
    },
    setSupportInfo({ data }: { data: JsonApiSingleResponse<SupportInfo> }) {
      this.supportInfo = data.data!;
      if (this.$refs.alertLoader) {
        (this.$refs.alertLoader as any).setReady();
      }
      if (this.isShoppingCartFeature()) this.dialog = true;
      else this.dialog = false;
    },
    handleLoadingError(msg: string) {
      if (this.$refs.alertLoader) {
        (this.$refs.alertLoader as any).setReady();
      }
      this.$toasted.error(msg);
    },
    isShoppingCartFeature() {
      return RateGroupName.SHOPPING_CART === this.feature;
    },
  },
  computed: {
    ...mapGetters(['brandName', 'branding']),
    alertMessage(): string {
      return RateGroupName.SHOPPING_CART === this.feature
        ? `Your online enterprise is now being created!
            In addition to the setup tour in your dashboard,
            you will receive informative emails to assist you during your Free Trial.
            If you need assistance, please contact
              ${this.brandName} at ${this.supportInfo.email} or ${this.supportInfo.phone}.`
        : `The paid feature ${this.feature} has not been enabled.
              To enable please contact ${this.brandName} at ${this.supportInfo.email} or ${this.supportInfo.phone}.`;
    },
  },
  components: {
    PageHeader,
    Alert,
    PageLoader,
  },
});
