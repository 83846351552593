






























































import Vue from 'vue';

import api from '@/common/api';
import { JsonApiArrayResponse, JsonApiSingleResponse } from '@/jsonApi.types';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import Icon from '@/common/icon';
import { PaymentMethod } from '@/common/types/payments.types';
import { Customer } from '@/common/types/customers.types';
import { isCustomerAddressEmpty } from '@/common/util/customers.util';
import { mapGetters } from 'vuex';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import EditCustomerCard from '../components/customers/EditCustomerCard.vue';
import PaymentMethodCard from '../components/payments/PaymentMethodCard.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      customer: {} as Customer,
      paymentMethods: null as PaymentMethod[] | null,
    };
  },
  created() {
    document.title = 'RiseOS-Edit Customer';
  },
  mounted() {
    if (!this.isCustomerVaultEnabled || this.isCustomerVaultEnabled === undefined) {
      this.$store.dispatch('loadFeatures').then(() => {
        if (this.isCustomerVaultEnabled) {
          this.loadCustomer();
        }
      });
    } else if (this.isCustomerVaultEnabled) {
      this.loadCustomer();
    }
  },
  computed: {
    ...mapGetters(['isCustomerVaultEnabled']),
    mobileButtons(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    mobileView(): boolean {
      return this.$vuetify.breakpoint.name !== 'xs';
    },
  },
  methods: {
    loadCustomer() {
      (this.$refs.loader as any).setLoading();
      api
        .get(`/customers/${this.id}`)
        .then(this.setCustomer)
        .catch(() => {
          this.$toasted.error('Problem loading customer');
          if (this.$refs.loader) {
            (this.$refs.loader as any).setError();
          }
        });
    },
    loadPaymentMethods() {
      if (this.$refs.paymentMethodsLoader) {
        (this.$refs.paymentMethodsLoader as any).setLoading();
      }
      api
        .get(`/customers/${this.id}/payment-methods`)
        .then(this.setPaymentMethods)
        .catch(() => {
          this.$toasted.error('Problem loading customer payment methods');
          (this.$refs.paymentMethodsLoader as any).setError();
        });
    },
    setCustomer({ data }: { data: JsonApiSingleResponse<Customer> }) {
      if (data.data === undefined || data.data.id === undefined) {
        return;
      }
      this.customer = data.data;
      this.customer.separateShipping = !isCustomerAddressEmpty(
        this.customer.shippingAddress,
        false,
      );
      (this.$refs.loader as any).setReady();
      this.loadPaymentMethods();
    },
    setPaymentMethods({ data }: { data: JsonApiArrayResponse<PaymentMethod> }) {
      this.paymentMethods = data.data ?? [];
      (this.$refs.paymentMethodsLoader as any).setReady();
    },
    removeCustomerFromVault() {
      api
        .put(`customers/${this.customer!.id}`, {
          ...this.customer,
          inVault: false,
        })
        .then(this.onCustomerRemovalFromVault)
        .catch(this.handleError);
    },
    onCustomerRemovalFromVault() {
      this.$router.push({
        name: 'merchant.customers.index',
      });
    },
    onCustomerUpdated() {
      this.$router.push({
        name: 'merchant.customers.view',
        params: { id: this.customer.id! },
      });
    },
    hasPaymentMethods(): boolean {
      return this.paymentMethods !== null && this.paymentMethods.length > 0;
    },
    handleError({ response }: { response: any }) {
      this.$toasted.error(
        `There was a problem remove the customer from vault. (${response.status})`,
      );
    },
  },
  components: {
    PageHeader,
    PageLoader,
    EditCustomerCard,
    Icon,
    PaymentMethodCard,
    PaidFeatureNotEnabled,
  },
});
