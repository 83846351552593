































import Vue from 'vue';

import { JsonApiSingleResponse } from '@/jsonApi.types';
import { Subscription } from '@/common/types/subscriptions.types';
import FailedTransactionDetails from './FailedTransactionDetails.vue';
import TransactionSuccessDetails from './TransactionSuccessDetails.vue';

export default Vue.extend({
  props: {
    response: {
      type: Object as () => JsonApiSingleResponse<any> | null,
      required: false,
      default: null,
    },
    subscription: {
      type: Object as () => Subscription,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      show: false as boolean,
    };
  },
  methods: {
    setVisibility(show: boolean) {
      this.show = show;
    },
  },
  watch: {
    show() {
      if (!this.show) {
        this.$emit('closed');
      }
    },
  },
  computed: {
    isError(): boolean {
      if (!this.response) {
        return false;
      }
      return (this.response as JsonApiSingleResponse<any>).status! >= 300;
    },
  },
  components: {
    FailedTransactionDetails,
    TransactionSuccessDetails,
  },
});
