






















import Vue from 'vue';
import api from '@/common/api';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import { Plan } from '../plans/plans.types';

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      autoCompleteSearch: '' as string,
      autoCompleteSearchLoading: false as boolean,
      perPage: 20 as number,
      search: '' as string,
      plans: [] as Plan[],
      selectedPlanName: null as String | null | undefined,
      selectedPlan: null as Plan | null | undefined,
    };
  },
  watch: {
    selectedPlanName() {
      if (this.selectedPlanName && this.selectedPlanName !== '') {
        this.selectedPlan = this.plans.find((p) => p.name === this.selectedPlanName);
      } else {
        this.selectedPlan = null;
      }
      this.$emit('selected-plan', this.selectedPlan);
    },
    autoCompleteSearch() {
      if (this.autoCompleteSearch === '' || !this.autoCompleteSearch) return;
      if (this.autoCompleteSearchLoading) return;
      this.autoCompleteSearchLoading = true;
      api
        .get('plans', {
          params: {
            page: 1,
            perPage: this.perPage,
            q: this.search,
            role: '',
          },
        })
        .then(this.setPlans)
        .catch(this.handleLoadingError)
        .finally(() => {
          this.autoCompleteSearchLoading = false;
        });
    },
  },
  methods: {
    setPlans({ data }: { data: JsonApiArrayResponse<Plan> }) {
      this.plans = data.data ?? [];
      Object.keys(this.plans).forEach((key: any) => {
        const planAmount = this.plans[key].amount * 100;
        this.plans[key].amount = planAmount;
      });
    },
    handleLoadingError() {
      const message = this.$errorMessages.get('plans.load_all');
      this.$toasted.error(message);
    },
  },
});
