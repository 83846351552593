import { RouteConfig } from 'vue-router';
import { Feature } from '@/features.types';
import reportsRoutes from './modules/reports/routes';

const routes: RouteConfig[] = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../../iso/views/Dashboard.vue'),
  },
  ...reportsRoutes,
  {
    path: '/onboarding/proposal/:id',
    name: 'proposal',
    component: () => import('../../iso/views/Proposal.vue'),
    props: true,
  },
  {
    path: '/onboarding/setup',
    name: 'setup',
    component: () => import('../../iso/views/onboarding/SetupIsoInfo.vue'),
  },
  {
    name: 'merchants.index',
    path: '/merchants',
    component: () => import('../../iso/views/merchants/MerchantIndex.vue'),
  },
  {
    name: 'merchants.new',
    path: '/merchants/new',
    component: () => import('../../iso/views/merchants/MerchantNew.vue'),
  },
  {
    name: 'merchants.edit',
    path: '/merchants/:id',
    component: () => import('../../iso/views/merchants/MerchantEdit.vue'),
    props: true,
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('../../iso/views/users/IsoUsers.vue'),
  },
  {
    path: '/users/new',
    name: 'users.new',
    component: () => import('../../iso/views/users/IsoUserNew.vue'),
    props: true,
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: () => import('../../iso/views/users/IsoUserEdit.vue'),
    props: true,
  },
  {
    path: '/shopping-carts',
    name: 'shopping-carts',
    component: () => import('../../iso/views/shopping-carts/ShoppingCarts.vue'),
    meta: {
      featureFlipping: { key: Feature.SHOPPING_CARTS, defaut: false },
    },
  },
];

export default routes;
