




import Vue from 'vue';
import TransactionLimits from '../../../common/components/support/TransactionLimits.vue';

export default Vue.extend({
  components: {
    TransactionLimits,
  },
});
