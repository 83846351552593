<template>
  <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.0985 23.9254C24.0118 24.012 24.1346 19.2598 24.0551 16.3854C24.019 15.0132 23.4485 13.7204 22.4662 12.7598C22.0257 12.3337 21.7801 11.7487 21.7801 11.1348V8.7876L18.429
      8.84538V11.1926C18.429 11.8065 18.1835 12.3915 17.7429 12.8176C16.7607 13.7709 16.1901 15.0709 16.154 16.4432C16.0746 19.3176 16.1757 23.7665 16.089 23.6798L14.4062 16.1109C13.9079
      14.7459 12.3985 14.0454 11.0335 14.5437L6.8879 16.0532C5.5229 16.5515 4.82234 18.0609 5.32068 19.4259L7.85568 24.0409L34.0362 24.1637V18.7904C34.0362 18.0537 33.8629 17.3315 33.5235
      16.6815L32.1729 14.067L25.6873 14.1248L24.0046 17.7865"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M37.9944 25.6804V25.0015C37.9944 24.4093 37.5177 23.9326 36.9255 23.9326H4.05717C3.46495 23.9326 2.98828 24.4093 2.98828 25.0015V25.6804C2.98828 26.2726 3.46495 26.7493 4.05717
      26.7493H36.9327C37.5177 26.7493 37.9944 26.2726 37.9944 25.6804Z"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M5.93555 25.9298L8.15277 36.5537C8.43444 37.8537 9.58999 38.7782 10.9189 38.7782H30.4983C31.8272 38.7782 32.9828 37.8537 33.2644 36.5537L35.5972 25.8721"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M21.1153 8.85264H19.0281C18.4286 8.85264 17.9375 8.36875 17.9375 7.76208V3.93431C17.9375 3.33486 18.4214 2.84375 19.0281 2.84375H21.1153C21.7147 2.84375 22.2058 3.32764 22.2058
      3.93431V7.76208C22.2058 8.36875 21.7147 8.85264 21.1153 8.85264Z"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M33.25 13.5469V12.4419C33.25 12.1169 32.99 11.8569 32.665 11.8569H25.3272C25.0022 11.8569 24.7422 12.1169 24.7422 12.4419V13.5469C24.7422 13.8719 25.0022 14.1319 25.3272
      14.1319H32.665C32.99 14.1392 33.25 13.8719 33.25 13.5469Z"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path d="M9.89361 10.1167C9.89361 10.1167 8.99083 12.1606 9.40972 14.7967L9.89361 10.1167Z" :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path d="M8.39783 15.1579C6.71505 11.4384 4.13672 10.5356 4.13672 10.5356L8.39783 15.1579Z" :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M19.209 6.81602H17.1218C16.5224 6.81602 16.0312 6.33213 16.0312 5.72546V1.89768C16.0312 1.29824 16.5151 0.807129 17.1218 0.807129H19.209C19.8085 0.807129 20.2996 1.29102 20.2996
      1.89768V5.72546C20.2996 6.33213 19.8157 6.81602 19.209 6.81602Z"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M16.5149 6.81592V9.16314C16.5149 9.77703 16.2694 10.362 15.8288 10.7881C14.8466 11.7415 14.2761 13.0415 14.2399 14.4137C14.1605 17.2881 14.2616 21.737 14.1749 21.6504L12.4922
      14.0815C11.9938 12.7165 10.4844 12.0159 9.11939 12.5143L4.97384 14.0237C3.60884 14.522 2.90828 16.0315 3.40661 17.3965L5.94161 22.0115"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path
      d="M20.5603 10.7305C21.5425 11.6838 22.1131 12.9838 22.1492 14.356C22.2286 17.2305 22.1059 21.9755 22.1925 21.896"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path d="M19.875 6.7583V9.10552C19.875 9.71941 20.1206 10.3044 20.5611 10.7305" :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10" />
    <path d="M21.983 15.8223H18.5091C17.8086 15.8223 17.238 16.3928 17.238 17.0934V21.7084" :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10" />
    <path
      d="M30.7587 12.1025H23.4209C23.0959 12.1025 22.8359 11.8425 22.8359 11.5175V10.4053C22.8359 10.0803 23.0959 9.82031 23.4209 9.82031H30.7587C31.0837 9.82031 31.3437 10.0803 31.3437
      10.4053V11.5103C31.3509 11.8353 31.0837 12.1025 30.7587 12.1025Z"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path d="M23.7824 12.1025L22.0996 15.7642" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path d="M30.2676 12.0376L31.6181 14.652C31.9576 15.302 32.1309 16.0243 32.1309 16.7609V22.1343" :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10" />
    <path
      d="M24.2656 21.5854V18.0104C24.2656 17.4616 24.7062 17.021 25.2551 17.021H29.2201C29.769 17.021 30.2095 17.4616 30.2095 18.0104V21.5854"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" />
    <path d="M8.83115 17.4473L4.44727 19.0073" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M9.13509 20.2061L5.76953 21.3472" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M4.57031 6.27441L7.03309 12.9983" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M7.99322 8.08008C7.99322 8.08008 7.09044 10.124 7.50933 12.7601" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10"
      stroke-linecap="round" />
    <path d="M2.23047 8.49902C2.23047 8.49902 4.8088 9.4018 6.49158 13.1212" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10"
      stroke-linecap="round" />
    <path
      d="M35.0265 24.7128H2.15092C1.5587 24.7128 1.08203 24.2361 1.08203 23.6439V22.9722C1.08203 22.38 1.5587 21.9033 2.15092 21.9033H35.0265C35.6187 21.9033 36.0954 22.38 36.0954
      22.9722V23.6511C36.0954 24.2361 35.6115 24.7128 35.0265 24.7128Z"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
    <path
      d="M4.0293 24.8932L6.24652 35.5171C6.52819 36.8171 7.68374 37.7416 9.01263 37.7416H28.5921C29.921 37.7416 31.0765 36.8171 31.3582 35.5171L33.691 24.8354"
      :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M15.7363 27.1104L16.4586 34.6792" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M22.3436 27.0527L21.7441 34.6794" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M27.6894 27.0527L26.3027 34.795" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M9.85742 27.2334L11.3524 34.7373" :stroke="darkMode ? 'white' : 'black'" stroke-miterlimit="10" stroke-linecap="round" />
  </svg>

</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['darkMode']),
  },
};
</script>
