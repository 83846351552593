








































































import Vue from 'vue';
import slugify from 'slugify';
import AchPaymentMethod from '@/common/components/AchPaymentMethod.vue';

export default Vue.extend({
  props: {
    iso: { type: Object, required: true },
    editableSubdomain: { type: Boolean, default: true },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    fromPage: {
      type: String,
      required: false,
      default: 'add',
    },
  },
  data() {
    return {
      shouldSluggify: this.iso.subdomain.length === 0,
    };
  },
  methods: {
    sluggifyForSubdomain(value: string) {
      if (!this.shouldSluggify) {
        return;
      }

      this.iso.subdomain = slugify(value, {
        lower: true,
        strict: true,
      });
    },
  },
  computed: {
    isoTaxId: {
      get() {
        return this.iso.taxId;
      },
      set(value: string) {
        if (value.length === 2) {
          this.iso.taxId = `${value}-`;
        } else if (value.length === 3) {
          const thirdCharacter = value[2];
          if (thirdCharacter === '-') {
            this.iso.taxId = value.substring(0, 2);
          } else {
            this.iso.taxId = `${value.substring(0, 2)}-${thirdCharacter}`;
          }
        } else {
          this.iso.taxId = value;
        }
      },
    },
    affiliateCategories() {
      return [
        { value: 'web_developers', display: 'Web Developers' },
        { value: 'isv', display: 'ISV' },
        { value: 'iso', display: 'ISO' },
        { value: 'health_organization', display: 'Health Organization' },
      ];
    },
    domainSuffix() {
      const { hostname } = window.location;
      const firstDot = hostname.indexOf('.');

      if (hostname.substring(firstDot + 1).indexOf('.') === -1) {
        return `.${hostname}`;
      }

      return hostname.substring(firstDot);
    },
  },
  components: { AchPaymentMethod },
});
