var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageLoader',{ref:"loader"},[(_vm.isVisible('company'))?_c('v-text-field',{attrs:{"id":_vm.uniqueId('company'),"name":"customer-address-company","label":"Company","outlined":"","dense":"","rules":[(_vm.isBusinessAch || _vm.isRequired('company')) ?
            function (v) { return !!v || 'Company is required.'; } : true]},model:{value:(_vm.value.company),callback:function ($$v) {_vm.$set(_vm.value, "company", $$v)},expression:"value.company"}}):_vm._e(),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.isVisible('firstName'))?_c('v-text-field',{attrs:{"id":_vm.uniqueId('firstName'),"name":"customer-address-first-name","label":"First Name","outlined":"","dense":"","rules":[(_vm.isBusinessAch || _vm.isRequired('firstName')) ?
              function (v) { return !!v || 'First name is required.'; } : true]},model:{value:(_vm.value.firstName),callback:function ($$v) {_vm.$set(_vm.value, "firstName", $$v)},expression:"value.firstName"}}):_vm._e()],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.isVisible('lastName'))?_c('v-text-field',{attrs:{"id":_vm.uniqueId('lastName'),"name":"customer-address-last-name","label":"Last Name","outlined":"","dense":"","rules":[(_vm.isBusinessAch || _vm.isRequired('lastName')) ?
              function (v) { return !!v || 'Last name is required.'; } : true]},model:{value:(_vm.value.lastName),callback:function ($$v) {_vm.$set(_vm.value, "lastName", $$v)},expression:"value.lastName"}}):_vm._e()],1)],1),(_vm.isVisible('email'))?_c('v-text-field',{attrs:{"id":_vm.uniqueId('email'),"name":"customer-address-email","label":"Email","rules":[_vm.isRequired('email') ?
            function (v) { return !!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)
                  || 'Email must be valid'; }
      : true ],"outlined":"","dense":""},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", $$v)},expression:"value.email"}}):_vm._e(),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.isVisible('address1'))?_c('v-text-field',{staticClass:"tw-flex-1 tw-pr-2",attrs:{"id":_vm.uniqueId('address1'),"name":"customer-address-address1","label":"Address 1","outlined":"","dense":"","rules":[_vm.isRequired('address1') ?
                function (v) { return !!v || 'Address1 is required.'; } : true]},model:{value:(_vm.value.address1),callback:function ($$v) {_vm.$set(_vm.value, "address1", $$v)},expression:"value.address1"}}):_vm._e()],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.isVisible('address2'))?_c('v-text-field',{staticClass:"tw-flex-1 tw-pl-2",attrs:{"id":_vm.uniqueId('address2'),"name":"customer-address-address2","label":"Address 2","outlined":"","dense":"","rules":[_vm.isRequired('address2') ?
                function (v) { return !!v || 'Address2 is required.'; } : true]},model:{value:(_vm.value.address2),callback:function ($$v) {_vm.$set(_vm.value, "address2", $$v)},expression:"value.address2"}}):_vm._e()],1)],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.isVisible('city'))?_c('v-text-field',{staticClass:"tw-flex-1 tw-pr-2",attrs:{"id":_vm.uniqueId('city'),"name":"customer-address-city","label":"City","outlined":"","dense":"","rules":[_vm.isRequired('city') ?
                function (v) { return !!v || 'Address2 is required.'; } : true]},model:{value:(_vm.value.city),callback:function ($$v) {_vm.$set(_vm.value, "city", $$v)},expression:"value.city"}}):_vm._e()],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.isVisible('state'))?_c('v-autocomplete',{attrs:{"id":_vm.uniqueId('state'),"name":"customer-address-state","items":_vm.states,"item-text":"name","item-value":"abbreviation","label":"State","outlined":"","dense":"","required":"[isRequired('state') ?\n                (v) => !!v || 'Address2 is required.' : true]"},model:{value:(_vm.value.state),callback:function ($$v) {_vm.$set(_vm.value, "state", $$v)},expression:"value.state"}}):_vm._e()],1)],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.isVisible('zip'))?_c('v-text-field',{attrs:{"id":_vm.uniqueId('zip'),"name":"customer-address-zip","label":"Zip","outlined":"","dense":"","rules":[_vm.isRequired('zip') ?
                function (v) { return !!v || 'Zip is required.'; } : true]},model:{value:(_vm.value.zip),callback:function ($$v) {_vm.$set(_vm.value, "zip", $$v)},expression:"value.zip"}}):_vm._e()],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6"}},[(_vm.isVisible('country'))?_c('v-autocomplete',{attrs:{"id":_vm.uniqueId('country'),"name":"customer-address-country","items":_vm.countries,"item-text":"name","item-value":"alpha2Code","label":"Country","outlined":"","dense":"","rules":[_vm.isRequired('country') ?
                function (v) { return !!v || 'Country is required.'; } : true]},model:{value:(_vm.value.country),callback:function ($$v) {_vm.$set(_vm.value, "country", $$v)},expression:"value.country"}}):_vm._e()],1)],1),(_vm.isVisible('phone'))?_c('a-phone-number-form',{attrs:{"name":"customer-address-phone-number","label":"Phone","disabled":!_vm.isVisible('phone'),"outlined":"","dense":"","fieldRequired":_vm.isRequired('phone')},model:{value:(_vm.value.phone),callback:function ($$v) {_vm.$set(_vm.value, "phone", $$v)},expression:"value.phone"}}):_vm._e(),(_vm.isVisible('fax'))?_c('a-phone-number-form',{attrs:{"label":"Fax","disabled":_vm.isVisible('fax'),"outlined":"","dense":""},model:{value:(_vm.value.fax),callback:function ($$v) {_vm.$set(_vm.value, "fax", $$v)},expression:"value.fax"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }