






























































































































import Vue from 'vue';
import api from '@/common/api';
import draggable from 'vuedraggable';
import { Feature } from '@/features.types';
import { nanoid } from 'nanoid';
import {
  HppColumn,
  HppField,
  HppRow,
  toggleShippingInformation,
} from '@/common/util/hostedPaymentPages.util';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { HostedPaymentPageStatus } from '@/common/types/hostedPaymentPages.types';
import { CustomField, CustomFieldType } from '@/common/components/support/support.types';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import HppFieldRow from './design-components/HppRow.vue';

const SAVING_LOADING_TEXT: string = 'Saving...';
const ACTIVATING: string = 'Activating..';
const DEACTIVATING: string = 'Deactivating...';

interface NewField {
  title: string;
  icon: string;
  field: HppField;
}

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    hppData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.loadCustomFields();
  },
  data() {
    return {
      Feature,
      isSubmitting: false,
      loadingText: SAVING_LOADING_TEXT as string,
      alertText: '' as string,
      alertType: 'error' as string,
      saving: false as boolean,
      outputHTML: '<div></div>' as string,
      hppConfig: this.hppData.config as HppRow[],
      customFields: [] as NewField[],
      showShipingFields: false as boolean,
    };
  },
  created() {
    document.title = 'RiseOS-HPP Edit';
  },
  methods: {

    savePage(display: boolean) {
      this.submitActions(true);
      const data = {
        config: this.hppConfig,
        html: this.outputHTML,
        isFixedAmount: this.hppData.isFixedAmount,
        amount: this.hppData.amount || 0,
        taxRate: this.$store.state.hppTaxRate,
        products: this.hppData.products || [],
      };

      api
        .patch(`/hosted-payments/${this.id}`, data)
        .then(() => {
          if (display) {
            api.post(`/hosted-payments/${this.id}/activation`).then(() => {
              this.$router.push({ name: 'pay.hpp', params: { id: this.id } });
            });
          } else {
            this.submitActions(false);
          }
        })
        .catch(() => {
          this.submitActions(false);
          const message = this.$errorMessages.get('hosted_payment_pages.save');
          this.$toasted.error(message);
        });
    },
    cloneElement(e: NewField) {
      /* eslint-disable consistent-return */
      const el: HppField = e.field;
      return el;
    },
    removeField(fieldId: string) {
      this.hppConfig = this.hppConfig.map((row) => ({
        ...row,
        columns: row.columns.map((column) => ({
          ...column,
          fields: column.fields.filter((field) => fieldId !== field.id),
        })),
      }));
    },
    toggleShipping(fieldLabel: string) {
      this.hppConfig = this.hppConfig.map(toggleShippingInformation);
      this.showShipingFields = fieldLabel === 'Use different shipping information';
    },
    activateHostedPaymentPage() {
      this.submitActions(true, ACTIVATING);
      api
        .post(`/hosted-payments/${this.id}/activation`)
        .then(() => {
          this.$toasted.success('Hosted payment page activated successfully');
          this.hppData.pageStatus = HostedPaymentPageStatus.ACTIVE;
          this.submitActions(false);
        })
        .catch(() => {
          this.submitActions(false);
          const message = this.$errorMessages.get('hosted_payment_pages.activate');
          this.$toasted.error(message);
        });
    },
    deactivateHostedPaymentPage() {
      this.submitActions(true, DEACTIVATING);
      api
        .delete(`/hosted-payments/${this.id}/activation`)
        .then(() => {
          this.$toasted.success('Hosted payment page deactivated successfully');
          this.hppData.pageStatus = HostedPaymentPageStatus.NOT_ACTIVE;
          this.submitActions(false);
        })
        .catch(() => {
          this.submitActions(false);
          const message = this.$errorMessages.get('hosted_payment_pages.deactivate');
          this.$toasted.error(message);
        });
    },
    loadCustomFields() {
      api
        .get('/custom-fields', {
          params: {
            hpp: true,
          },
        })
        .then(this.assignCustomFields);
    },
    assignCustomFields({ data }: { data: JsonApiArrayResponse<CustomField> }) {
      if (data.data!.length > 0) {
        const fields: CustomField[] = data.data!;
        this.customFields = fields.map((field: CustomField) => ({
          title: field.name,
          icon: 'mdi-form-textbox',
          field: {
            id: field.id,
            type: this.getCustomFieldType(field.type),
            display: field.type,
            name: field.name,
            value: this.getCustomFieldType(field.type) === 'VCheckbox' ? false : '',
            required: field.required,
            attributes: {
              label: (this.$options.filters as any).capitalize(field.name),
              items: field.options.map((option) => option.value),
            },
            customField: true,
            hidden: false,
            style: '',
          },
        }));

        let hasAdditionalField = false;
        const cols = this.hppConfig[0].columns;

        Object.keys(cols).forEach((key: any) => {
          const colArr = cols[key];
          if (colArr.name === 'additionalFields' && hasAdditionalField === false) {
            hasAdditionalField = true;
          }
        });
        if (hasAdditionalField === false) {
          const hppConfigLastArr = this.hppConfig[0].columns[this.hppConfig[0].columns.length - 1];
          this.hppConfig[0].columns.pop();
          this.hppConfig[0].columns.push({
            key: nanoid(),
            type: 'Column',
            name: 'additionalFields',
            fields: [{
              id: nanoid(),
              type: 'HppHeading',
              attributes: {
                label: 'Additional Information',
              },
              name: 'additionalFieldsHeading',
              value: 'Additional Information',
              required: false,
              customField: false,
              hidden: false,
              style: 'grid-column-start: 1; grid-column-end: 3',
            }],
          });
          this.hppConfig[0].columns.push(hppConfigLastArr);
        }
      }
    },
    fieldExists(field: NewField): boolean {
      const hppColumns: HppColumn[] = this.hppConfig.reduce(
        (columns: HppColumn[], row: HppRow) => columns.concat(row.columns),
        [],
      );
      const customFieldsData = hppColumns.find(
        (columns: HppColumn) => columns.name === 'additionalFields',
      );
      if (customFieldsData) {
        const existingFieldIndex = customFieldsData.fields.findIndex(
          (currentField) => currentField.id === field.field.id,
        );
        return existingFieldIndex !== -1;
      }

      return false;
    },
    getCustomFieldType(customFieldType: CustomFieldType) {
      if (customFieldType === CustomFieldType.TEXT) {
        return 'VTextField';
      }
      if (customFieldType === CustomFieldType.CHECK) {
        return 'VCheckbox';
      }

      return 'VSelect';
    },
    previewResolution(deviceType: string) {
      const pageURL = `/hosted-payments/preview/${this.id}`;

      switch (deviceType) {
        case 'mob':
          this.savePage(false);
          window.open(
            pageURL,
            'hppPreview',
            'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=600,width=400',
          );
          break;
        case 'tab':
          this.savePage(false);
          window.open(
            pageURL,
            'hppPreview',
            'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=600,width=768',
          );
          break;
        default:
          this.savePage(false);
          window.open(
            pageURL,
            'hppPreview',
            'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=600,width=1024',
          );
      }
    },
    submitActions(isSubmitting: boolean, loadingText: string = SAVING_LOADING_TEXT) {
      this.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    checkMoveFields(evt: any) {
      if ((evt.relatedContext.element.customField || evt.relatedContext.element.name === 'additionalFieldsHeading')
      && evt.willInsertAfter) {
        return true;
      }
      return false;
    },
  },
  computed: {
    existingHpp(): boolean {
      return this.$route.name === 'merchant.hosted-payments.edit';
    },
    hasCreditCardProcessor(): boolean {
      return this.hppData.creditCardProcessorId !== null;
    },
    hasAchProcessor(): boolean {
      return this.hppData.achProcessorId !== null;
    },
    canActivate(): boolean {
      return this.hppData.pageStatus !== HostedPaymentPageStatus.ACTIVE;
    },
    canDeactivate(): boolean {
      return this.hppData.pageStatus === HostedPaymentPageStatus.ACTIVE;
    },
    formFields(): NewField[] {
      return [
        {
          title: 'Logo',
          icon: 'mdi-image-text',
          field: {
            id: nanoid(),
            type: 'LogoPicker',
            attributes: {},
            value: '',
            name: 'logo',
            style: 'grid-column-start: 1; grid-column-end: 3',
          } as HppField,
        },
        {
          title: 'Paragraph',
          icon: 'mdi-format-paragraph',
          field: {
            id: nanoid(),
            type: 'HppParagraph',
            attributes: {},
            value: 'Your text',
            name: 'newTextArea',
            style: 'grid-column-start: 1; grid-column-end: 3',
          } as HppField,
        },
        {
          title: 'Heading',
          icon: 'mdi-format-header-1',
          field: {
            id: nanoid(),
            type: 'HppHeading',
            attributes: {},
            value: 'Your text',
            name: 'newTextArea',
            style: 'grid-column-start: 1; grid-column-end: 3',
          } as HppField,
        },
        {
          title: 'My Address',
          icon: 'mdi-home-map-marker',
          field: {
            id: nanoid(),
            type: 'HppParagraph',
            attributes: {},
            value: this.homeAddress,
            name: 'newTextArea',
            style:
              'grid-column-start: 1; grid-column-end: 3; text-align: center; font-weight: 500;',
          } as HppField,
        },
      ];
    },
    homeAddress(): string {
      return `${this.hppData.merchantCompanyName}
        ${this.hppData.merchantContact.address1} ${
  this.hppData.merchantContact.address2 === ''
    ? ''
    : `\n${this.hppData.merchantContact.address2}`
}
        ${this.hppData.merchantContact.city}, ${this.hppData.merchantContact.state} ${
  this.hppData.merchantContact.zip
}`;
    },
  },
  components: {
    HppFieldRow,
    draggable,
    LoadingModal,
  },
});
