export interface NonCashAdjustmentFee {
  id: string;
  name: string;
  adjustmentPercent?: number;
  // setting dollarAmount to number type cuts off zero at the end ($2.50 would display as $2.5)
  dollarAmount?: any;
  inVirtualTerminal: boolean;
  inInvoice: boolean;
  inHpp: boolean,
  adjustmentPercentAch?: number;
  dollarAmountAch?: any;
}

export function initNonCashAdjustmentFields(): NonCashAdjustmentFee {
  return {
    id: '',
    name: '',
    adjustmentPercent: 0,
    dollarAmount: 0,
    inVirtualTerminal: false,
    inInvoice: false,
    inHpp: false,
    adjustmentPercentAch: 0,
    dollarAmountAch: 0,
  };
}

export enum NonCashAdjustmentType {
  DOLLAR_AMOUNT = 'Dollar Amount',
  PERCENT = 'Percent',
}
