import { DirectiveOptions } from 'vue';

const showDecimals = (input: HTMLInputElement, decimals?: any) => {
  if (input === document.activeElement) return;

  const value = Number(input.value);
  const decimalsAmount = Number(decimals) || 2;
  if (!Number.isNaN(value) && !Number.isNaN(decimalsAmount)) {
    input.value = value.toFixed(decimalsAmount); // eslint-disable-line no-param-reassign
    const event = new Event('input');
    input.dispatchEvent(event);
  }
};

/**
 * This directive shows a number with two decimals when
 * a given input does not have focus.
 * This is specially useful for inputs related to money
 * and percentages.
 */
const BlurDecimals: DirectiveOptions = {
  bind: (el, binding) => {
    const input = el.querySelector('input');
    if (!input) return;

    input.addEventListener('blur', () => showDecimals(input, binding.value));
    showDecimals(input);
  },
};

export default BlurDecimals;
