<template>
  <div>
    <slot v-if="!loading && !error" />

    <div v-else class="centerer">
      <a-spinner v-if="loading" big />
      <slot v-else name="error" />
    </div>
  </div>
</template>

<script>
import ASpinner from '../atoms/a-spinner.vue';

export default {
  components: { ASpinner },
  props: {
    loading: { type: Boolean, required: true },
    error: { type: Boolean },
  },
};
</script>

<style scoped>
.centerer {
  display: grid;
  place-items: center;
  margin-block: 20vh;
}
</style>
