<template>
  <div class="card">
    <h3>Branding</h3>

    <div class="tw-flex tw-items-center">
      <h4 class="tw-mr-2">Logo upload</h4>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <i-question-circle />
          </div>
        </template>
        Logo must be in .jpg, .png, .gif format
      </v-tooltip>
    </div>

    <div class="logos">
      <a-logo-picker v-model="branding.fullLogo">Upload logo full color</a-logo-picker>
      <a-logo-picker v-model="branding.lightLogo">Upload logo white</a-logo-picker>
      <a-logo-picker  v-model="branding.darkLogo">Upload logo black</a-logo-picker>
    </div>

    <h4 class="title-3">Backgrounds & Texts</h4>
    <a-color-picker v-model="mainColor">Main Color</a-color-picker>
    <a-color-picker v-model="mainTextColor">Text Color</a-color-picker>
    <a-color-picker v-model="secondColor">Second Color</a-color-picker>
    <a-color-picker v-model="thirdColor">Third Color</a-color-picker>
  </div>
</template>

<script>
import AColorPicker from '@/components/atoms/a-color-picker.vue';
import IQuestionCircle from '@/components/icons/i-question-circle.vue';
import ALogoPicker from '@/components/atoms/a-logo-picker.vue';

export default {
  props: {
    branding: { type: Object, required: true },
  },
  computed: {
    mainColor: {
      get() {
        return this.branding.mainColor;
      },
      set(value) {
        this.$emit('updateColor', 'mainColor', value);
      },
    },
    mainTextColor: {
      get() {
        return this.branding.mainTextColor;
      },
      set(value) {
        this.$emit('updateColor', 'mainTextColor', value);
      },
    },
    secondColor: {
      get() {
        return this.branding.secondColor;
      },
      set(value) {
        this.$emit('updateColor', 'secondColor', value);
      },
    },
    thirdColor: {
      get() {
        return this.branding.thirdColor;
      },
      set(value) {
        this.$emit('updateColor', 'thirdColor', value);
      },
    },
  },
  components: { AColorPicker, ALogoPicker, IQuestionCircle },
};
</script>

<style scoped>
.card {
  --card-padding: 1.5rem;
}

h3 {
  margin-block-end: 1.25rem;
}

h4 {
  font-weight: 600;
}

.logos {
  margin-block: 1.25rem;
  display: grid;
  gap: 1rem;
}

.title-3 {
  margin-block-end: 0.5rem;
}
</style>
