




















































import Vue from 'vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import { mapGetters } from 'vuex';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import CustomerBatchUploadPrompt from '../components/customers/batch-uploads/CustomerBatchUploadPrompt.vue';

export default Vue.extend({
  data() {
    return {
      EntityFilterType,
      config: {
        pageTitle: 'Customers',
        header: 'Manage Customers',
        routerBase: 'merchant.customers',
        apiPath: 'customers',
        entityName: 'customer',
        identifierProperty: 'id',
        reportFileName: 'customers_vault_report',
        headers: [
          { text: 'ID', value: 'id', align: ' d-none' },
          {
            text: 'Expiring payment methods',
            value: 'hasExpiringPaymentMethods',
            align: ' d-none',
          },
          { text: 'Expired payment methods', value: 'hasExpiredPaymentMethods', align: ' d-none' },
          {
            text: 'Customer ID',
            value: 'externalId',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Name',
            value: 'billingAddress',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Transactions',
            value: 'transactionsCount',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
            align: 'center',
          },
          {
            text: 'Payment Methods',
            value: 'paymentMethodsCount',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
            align: 'center',
          },
          {
            text: 'Entry Time',
            value: 'createdAt',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
        ],
        filters: [
          {
            name: 'externalId',
            display: 'Customer ID',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'company',
            display: 'Company',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'firstName',
            display: 'First Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'lastName',
            display: 'Last Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'dateStartCreatedAt',
            display: 'Start Date Added',
            type: EntityFilterType.DATE,
            value: '',
            open: false,
          },
          {
            name: 'dateEndCreatedAt',
            display: 'End Date Added',
            type: EntityFilterType.DATE,
            value: '',
            open: false,
          },
          {
            name: 'email',
            display: 'Email',
            value: '',
            type: EntityFilterType.TEXT,
          },
        ],
      },
    };
  },
  methods: {
    getPaymentMethodClass(item: any): string {
      if (item.hasExpiredPaymentMethods) {
        return 'tw-bg-red-200 tw-text-red-700';
      }
      if (item.hasExpiringPaymentMethods) {
        return 'tw-bg-orange-200 tw-text-orange-700';
      }
      return '';
    },
  },
  computed: {
    ...mapGetters(['isCustomerVaultEnabled']),
  },
  components: {
    CustomerBatchUploadPrompt,
    EntitiesTable,
    PaidFeatureNotEnabled,
  },
});
