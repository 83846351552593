const RESIDUAL_REPORT_TABLE_HEADERS = [
  {
    text: 'Date',
    value: 'date',
    sortable: false,
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '9rem',
  },
  {
    text: 'Merchant',
    value: 'merchantName',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '9rem',
  },
  {
    text: 'Processor',
    value: 'processorName',
    sortable: false,
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '9rem',
  },
  {
    text: 'Fee type',
    value: 'rateKey',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '9rem',
  },
  {
    text: 'Quantity',
    value: 'quantity',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '9rem',
  },
  {
    text: 'Buy Rate',
    value: 'buyRate',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '9rem',
  },
  {
    text: 'Sell Rate',
    value: 'sellRate',
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '9rem',
  },
  {
    text: 'Residuals',
    value: 'residual',
    sortable: false,
    class: 'entity-table-header subtitle-2 font-weight-bold black--text',
    width: '9rem',
  },
];

export default RESIDUAL_REPORT_TABLE_HEADERS;
