































import Vue from 'vue';
import api from '@/common/api';
import ALogoPicker from '@/components/atoms/a-logo-picker.vue';
import MSaveButton from '@/components/molecules/m-save-button.vue';
import IQuestionCircle from '@/components/icons/i-question-circle.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isSubmitting: false,
      logo: '',
    };
  },
  computed: {
    ...mapState(['branding']),
  },
  watch: {
    branding: {
      handler(value) {
        this.logo = value.logo;
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      this.isSubmitting = true;

      api
        .put(`/merchant/logo/${this.branding.merchantId}`, { logo: this.logo })
        .then(this.showSuccess)
        .catch(this.handleSaveError)
        .finally(() => {
          this.isSubmitting = false;
          this.$store.dispatch('fetchBranding');
        });
    },
    showSuccess() {
      this.$toasted.success('Merchant Logo updated successfully!');
    },
    handleSaveError({ response }: { response: any }) {
      this.$toasted.error(`There was a problem saving the merchant logo. (${response.status})`);
    },
  },
  components: { ALogoPicker, MSaveButton, IQuestionCircle },
});
