var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',[_c('p-card',{staticClass:"password-confirm",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v("Password Reset Confirmation")])]},proxy:true}])},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Username","error-messages":_vm.validationErrors.username,"rules":[function (v) { return !!v || 'Username is required'; }],"readonly":"","outlined":"","dense":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('v-text-field',{attrs:{"label":"Password","error-messages":_vm.validationErrors.password,"type":"password","rules":[
          function (v) { return !!v || 'Password is required'; },
          function (v) { return v.length >= 8 || 'Password must be at least 8 characters'; }
        ],"outlined":"","dense":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('v-text-field',{attrs:{"label":"Password Confirmation","error-messages":_vm.validationErrors.confirm,"type":"password","rules":[
          function (v) { return !!v || 'Password confirmation is required'; },
          function (v) { return v.length >= 8 || 'Password confirmation must be at least 8 characters'; },
          function (v) { return v === _vm.user.password || 'Passwords do not match'; }
        ],"outlined":"","dense":""},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":!_vm.valid || _vm.submitting,"loading":_vm.submitting}},[_vm._v(" Set Password ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }