<template>
  <v-dialog :value="true" max-width="333">
    <p-card class="timeout-modal">
      <to-timeout-svg class="illustration" />
      <p>Your session is about to expire in {{ secondUntilLogOut }} seconds</p>
    </p-card>
  </v-dialog>
</template>

<script>
import PCard from '@/components/presentations/p-card.vue';
import ToTimeoutSvg from './idle-modal/to-timeout-svg.vue';

export default {
  components: { ToTimeoutSvg, PCard },
  data() {
    return {
      secondUntilLogOut: 60,
      timer: null,
    };
  },
  created() {
    this.timer = setInterval(() => {
      this.secondUntilLogOut -= 1;

      if (this.secondUntilLogOut < 1) {
        clearInterval(this.timer);
        this.$router.push('/logout');
      }
    }, 1000);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>


<style scoped>
.v-dialog__content >>> .v-dialog {
  border-radius: 0;
}

.timeout-modal {
  --card-border-radius: 0.5rem 0.5rem 0 0;

  border-top: 0.375rem solid var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeout-modal .illustration {
  color: var(--primary-color);
}

.timeout-modal p {
  font-size: 1.125rem;
  font-weight: 600;
  font-family: 'Montserrat';
  line-height: 1.222;
  text-align: center;
  max-width: 20ch;
  margin-block: 2rem 0;
}
</style>
