<template>
  <p-container class="faq-form">
    <p-page-title>
      {{ id ? 'Edit' : 'Add' }} Frequently Asked Question
    </p-page-title>

    <v-form v-model="isFormValid" @submit.prevent="saveFaq">
      <div>
        <label for="faqQuestion">Question</label>

        <v-text-field
          v-model="faq.question"
          id="faqQuestion"
          :disabled="loading || submitting"
          placeholder="frequently asked question"
          outlined
          dense
          :rules="[(v) => !!v || 'Question is required']"
        />
      </div>

      <div>
        <label for="faqAnswer">Answer</label>

        <mavon-editor
          v-model="faq.answer"
          id="faqAnswer"
          language="en"
          :disabled="loading || submitting"
          :boxShadow="false"
          placeholder="What is the answer to this question..."
          class="markdown-editor"
          :class="{ 'dark-mode': darkMode }"
        />

        <small v-if="!faq.answer" class="error--text" >
          Answer is required
        </small>
      </div>

      <v-btn
        color="primary"
        type="submit"
        :disabled="submitting || loading || !isFormValid"
        :loading="submitting"
        class="mt-10"
      >
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-form>
  </p-container>
</template>

<script>
import Vue from 'vue';
import api from '@/common/api';
import mavonEditor from 'mavon-editor';
import PContainer from '@/components/presentations/p-container.vue';
import PPageTitle from '@/components/presentations/p-page-title.vue';
import 'mavon-editor/dist/css/index.css';
import { mapState } from 'vuex';

Vue.use(mavonEditor);

export default {
  components: {
    PContainer,
    PPageTitle,
  },
  props: {
    id: { type: String },
  },
  data() {
    return {
      faq: {
        question: '',
        answer: '',
      },

      isFormValid: false,
      loading: false,
      submitting: false,
    };
  },
  computed: {
    ...mapState(['darkMode']),
    editMode() {
      return !!this.id;
    },
  },
  created() {
    if (this.editMode) this.loadFaq();
  },
  methods: {
    async loadFaq() {
      this.loading = true;

      try {
        const { data } = await api.get(`faq/${this.id}`);

        this.faq = { question: data.data.question, answer: data.data.answer };
      } catch (e) {
        this.$toasted.error('There was an error while loading the FAQ');
      } finally {
        this.loading = false;
      }
    },
    async saveFaq() {
      this.submitting = true;

      try {
        if (this.editMode) {
          await api.patch(`faq/${this.id}`, this.faq);
        } else {
          await api.post('faq', this.faq);
        }

        this.$toasted.success('FAQ successfully saved');
        await this.$router.push('/faqs');
      } catch (e) {
        this.$toasted.error('There was an error while saving the FAQ');
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped>
.faq-form label {
  font-weight: 500;
  font-family: 'Montserrat';
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  line-height: 1.71;
  opacity: 0.75;
  margin-block-end: 0.6em;
}

.markdown-editor {
  background: transparent;
}

.markdown-editor >>> .v-note-op {
  margin-block-end: 0.5rem;
}

.markdown-editor >>> .v-note-edit,
.markdown-editor >>> .v-note-show > .v-show-content {
  background: #fff !important;
}

.markdown-editor.dark-mode >>> .v-note-edit,
.markdown-editor.dark-mode >>> .v-note-edit > .content-input-wrapper,
.markdown-editor.dark-mode >>> .v-note-edit > .content-input-wrapper > .content-input,
.markdown-editor.dark-mode >>> .v-note-edit > .content-input-wrapper > .content-input > .auto-textarea-input,
.markdown-editor.dark-mode >>> .v-note-show > .v-show-content {
  background: #000 !important;
  color: #fff;
}
</style>
