<template>
  <v-form @submit.prevent="submit" v-model="isValidForm">
    <div v-if="!active" class="auth-main">
      <div>
        <p>
          Open your authentication app and add
          your account by scanning the QR
          code to the right or by manually entering
          the Base32 encoded secret
          <strong>{{ secret.secretBase32Encoded }}</strong>
        </p>

        <p>
          Once you have completed the first step,
          enter the code from your
          authenticator app in the verification code
          field below.
        </p>
      </div>

      <img
        :src="qrUrl"
        width="100"
        height="100"
        alt="QR Code"
        class="auth-main__qr"
      >
    </div>

    <div v-if="!active" class="two-factor__submit">
      <v-text-field
        v-model="code"
        label="Verification Code *"
        placeholder="Enter the one-time code"
        outlined
        dense
        hide-details
        :rules="[
          (v) => !!v || 'Verification Code is required.'
        ]"
      />

      <v-btn
        color="secondary"
        :disabled="submitting || !isValidForm"
        :loading="submitting"
        type="submit"
      >
        {{ active ? 'Deactivate' : 'Activate' }}
      </v-btn>
    </div>

    <support-link v-else />
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import SupportLink from './utils/support-link.vue';

export default {
  components: { SupportLink },
  props: {
    submitting: { type: Boolean, required: true },
    active: { type: Boolean, required: true },
    methodId: { type: String },
    secret: { type: Object },
    username: { type: String },
  },
  data() {
    return {
      isValidForm: false,
      code: '',
    };
  },
  computed: {
    ...mapState(['currentUser']),
    qrUrl() {
      const url = 'https://api.qrserver.com/v1/create-qr-code';
      const size = 'size=100x100';
      const name = `RiseOS (${this.username || this.currentUser?.username})`;
      const data = `data=otpauth://totp/${name}?secret=${this.secret.secretBase32Encoded}`;

      return `${url}?${size}&${data}`;
    },
  },
  methods: {
    sendCode() {
      const data = this.active
        ? { methodId: this.methodId }
        : { method: 'sms', mobilePhone: this.phone };

      this.$emit('sendCode', data);
    },
    activate() {
      const data = {
        method: 'authenticator',
        code: this.code,
        secret: this.secret.secret,
      };

      this.$emit('activate', data);
    },
    deactivate() {
      const data = {
        methodId: this.methodId,
        code: this.code,
      };

      this.$emit('deactivate', data);
    },
    submit() {
      if (this.active) {
        this.deactivate();
      } else {
        this.activate();
      }
    },
  },
};
</script>

<style scoped>
.auth-main {
  display: flex;
  gap: 2rem;
}

.auth-main__qr {
  object-fit: contain;
  height: fit-content;
}
</style>
