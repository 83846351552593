<template>
  <div>
    <h1>Logging in...</h1>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['context']),
  },
  created() {
    const { token } = this.$route.query;
    if (!token) {
      this.$router.push('/login');
      return;
    }

    this.saveToken(token);
  },
  methods: {
    async saveToken(token) {
      this.$store.dispatch('clearAuthPromise');
      this.$store.commit('setAccessToken', token);
      await this.$store.dispatch('authenticate');

      this.$router.push({ name: `${this.context}.dashboard` });
    },
  },
};
</script>
