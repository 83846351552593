
















import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';
import Navbar from '@/common/components/Navbar.vue';
import Spinner from '@/common/components/LoadingSpinner.vue';
import IdleModal from '@/common/components/modals/IdleModal.vue';
import { HPP_PAYMENT_PAGES_NEW_ROUTE_NAME, HPP_PAYMENT_PREVIEW_ROUTE_NAME } from '@/tenants/merchant/routes';
import { PAY_HPP_ROUTE_NAME, PAY_INVOICE_ROUTE_NAME } from './payments/routes';
import BrandTheme from './common/components/BrandTheme.vue';
import Header from './common/components/Header.vue';
import Footer from './common/components/Footer.vue';
import {
  LOGIN_JWT_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  OAUTH_PATH,
} from './common/routes';

export default Vue.extend({
  computed: {
    ...mapState(['branding']),
    ...mapGetters(['authenticated', 'currentUser', 'context', 'isAdminContext']),
    isHppOrInvoice(): boolean {
      return this.$route.name === PAY_HPP_ROUTE_NAME
        || this.$route.name === HPP_PAYMENT_PREVIEW_ROUTE_NAME
        || this.$route.name === HPP_PAYMENT_PAGES_NEW_ROUTE_NAME
        || this.$route.name === PAY_INVOICE_ROUTE_NAME;
    },
    isLoginRoute(): boolean {
      return [LOGIN_PATH, LOGIN_JWT_PATH, OAUTH_PATH, LOGOUT_PATH].includes(this.$route.path);
    },
    showBars(): boolean {
      return this.authenticated && !this.isLoginRoute && !this.isHppOrInvoice && !!this.branding;
    },
    isIdle(): boolean {
      return this.authenticated
      && this.$store.state.idleVue !== undefined
      && this.$store.state.idleVue.isIdle
      && !this.isAdminContext;
    },
  },
  components: {
    Navbar,
    Spinner,
    IdleModal,
    Footer,
    Header,
    BrandTheme,
  },
});
