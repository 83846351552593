<template>
  <p-card>
    <template #header>
      <h2>Allowed Card Types</h2>
    </template>

    <ul>
      <li v-for="item in cardTypes" :key="item.name">
        <a-checkbox v-model="item.enabled">
          <img :src="`/images/cards/${item.name}.png`">
          {{ getCardLabel(item.name) }}
        </a-checkbox>
      </li>
    </ul>
  </p-card>
</template>

<script>
import ACheckbox from '@/components/atoms/a-checkbox.vue';
import PCard from '@/components/presentations/p-card.vue';

export default {
  components: { ACheckbox, PCard },
  props: {
    cardTypes: { type: Array, required: true },
  },
  methods: {
    getCardLabel(name) {
      if (name === 'mastercard') return 'MasterCard';
      if (name === 'jcb') return 'JCB';

      return name.replace(/_/g, ' ');
    },
  },
};
</script>

<style scoped>
ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(13rem, calc(50% - 1.5rem)), 1fr));
  gap: 1.5rem;
}

li {
  --checkbox-gap: 1.25rem;
  text-transform: capitalize;
}

li img {
  width: 2.5rem;
  object-fit: scale-down;
}
</style>
