




import Vue from 'vue';
import UserCreateForm from '@/common/components/users/UserCreateForm.vue';
import { UserType } from '@/common/components/users/users.types';

export default Vue.extend({
  props: {
    userPermissions: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      UserType,
    };
  },
  created() {
    document.title = 'RiseOS-Create User';
  },
  components: {
    UserCreateForm,
  },
});
