























































import Vue from 'vue';
import { mapGetters } from 'vuex';
import api from '@/common/api';
import FormInput from '@/common/components/forms/Input.vue';
import Permissions from '@/common/permissions';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import PopoverButton from '@/common/components/popovers/PopoverButton.vue';
import { PaymentType } from '@/common/types/payments.types';
import { Transaction, TransactionStatus } from '@/common/types/transactions.types';
import Address from './components/Address.vue';
import TransactionDetailsCard from './components/TransactionDetailsCard.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      transaction: null as Transaction | null,
      refundAmount: 0 as number,
      isSubmitting: false as boolean,
    };
  },
  created() {
    document.title = 'RiseOS-Transaction';
  },
  mounted() {
    this.loadTransaction();
  },
  computed: {
    ...mapGetters(['currentUserCan', 'isCustomerVaultEnabled']),
    hasBillingAddress(): boolean {
      return (
        this.transaction !== null
        && this.transaction.customerDetails !== null
        && this.transaction.customerDetails?.billingAddress !== null
        && this.transaction.customerDetails?.billingAddress !== undefined
      );
    },
    hasShippingAddress(): boolean {
      return (
        this.transaction !== null
        && this.transaction.customerDetails !== null
        && this.transaction.customerDetails?.shippingAddress !== null
        && this.transaction.customerDetails?.shippingAddress !== undefined
      );
    },
    customer(): string {
      if (!this.transaction) {
        return 'Loading...';
      }

      if (this.transaction.customer) {
        return this.transaction.customer;
      }

      switch (this.transaction.paymentMethod!.type) {
        case PaymentType.CREDIT:
          return this.transaction.account;
        case PaymentType.ACH:
          return 'ACH';
        case PaymentType.CASH:
        default:
          return (this.$options.filters as any).capitalize(this.transaction.type);
      }
    },
    readyToCapture(): boolean {
      return this.transaction?.status === TransactionStatus.AUTHORIZED;
    },
    readyToVoid(): boolean {
      return (
        (this.transaction?.status === TransactionStatus.CAPTURED
        && this.currentUserCanVoidOrRefund
        && this.transaction?.paymentMethod?.key !== 'omaha')
        || (this.transaction?.status === TransactionStatus.AUTHORIZED && this.transaction?.paymentMethod?.key === 'omaha')
      );
    },
    readyToRefund(): boolean {
      return (
        this.transaction?.status === TransactionStatus.SETTLED
        && (this.transaction?.refundedAmount === undefined
          || this.transaction?.refundedAmount === 0)
        && this.currentUserCanVoidOrRefund
      );
    },
    currentUserCanVoidOrRefund(): boolean {
      return (
        (this.transaction?.paymentMethod!.type === PaymentType.CREDIT
          && this.currentUserCan(Permissions.RUN_CC_VOID))
        || (this.transaction!.paymentMethod!.type === PaymentType.ACH
          && this.currentUserCan(Permissions.RUN_ACH_VOID))
        || (this.transaction!.paymentMethod!.type === PaymentType.CASH
          && this.currentUserCan(Permissions.RUN_CASH_VOID))
      );
    },
    isCustomerInVault(): boolean {
      const customerId = this.transaction?.customerDetails?.id;
      return customerId !== undefined && customerId !== null && customerId !== '';
    },
    isDeclined(): boolean {
      return this.transaction!.status === TransactionStatus.DECLINED;
    },
  },
  methods: {
    loadTransaction() {
      (this.$refs.loader as any).setLoading();
      api
        .get(`/transactions/${this.id}`)
        .then(({ data: { data } }) => {
          this.transaction = data;
          this.refundAmount = (this.$options.filters as any).dollarsAndCents(
            this.transaction!.amount,
          );
          (this.$refs.loader as any).setReady();
        })
        .catch(() => {
          this.$toasted.error('Problem loading transaction');
          (this.$refs.loader as any).setError();
        });
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    captureTransaction() {
      this.submitActions(true);
      api
        .patch(`/transactions/${this.transaction!.id}`, { capture: true })
        .then(({ data: { data: transaction } }) => {
          this.submitActions(false);
          this.$toasted.success('Success');
          this.transaction = transaction;
        })
        .catch(({ response }) => {
          this.submitActions(false);
          this.$toasted.error(response.data.message);
        });
    },
    refundTransaction() {
      const amount = Math.round(100 * this.refundAmount);
      this.deleteTransaction({ amount });
    },
    voidTransaction() {
      this.deleteTransaction();
    },
    addToVault() {
      this.submitActions(true);
      api
        .post('customers', { transactionId: this.transaction!.id })
        .then(() => {
          this.submitActions(false);
          this.$toasted.success('Success');
          this.transaction = null;
          this.loadTransaction();
        })
        .catch(({ response }) => {
          this.submitActions(false);
          this.$toasted.error(response.data.message);
        });
    },
    addToRecurring() {
      this.$router.push({
        name: 'merchant.transactions.subscriptions.new',
        params: { id: this.transaction!.id! },
      });
    },
    deleteTransaction(args = {}) {
      this.submitActions(true);
      api
        .delete(`/transactions/${this.transaction!.id}`, { data: args })
        .then(({ data: { data: transaction } }) => {
          this.submitActions(false);
          this.$toasted.success('Success');
          this.transaction = transaction;
        })
        .catch(({ response }) => {
          this.submitActions(false);
          this.$toasted.error(response.data.message);
        });
    },
  },
  components: {
    Address,
    FormInput,
    TransactionDetailsCard,
    PageHeader,
    PageLoader,
    PopoverButton,
    LoadingModal,
  },
});
