<template>
  <div>
    <ul v-if="type == 'ul'">
      <li v-for="(item, index) in items" :key="index">{{ item }}</li>
    </ul>
    <ol v-else>
      <li v-for="(item, index) in items" :key="index">{{ item }}</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'HppList',
  props: {
    type: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
  ol, ul { margin-bottom: 1em; }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
</style>
