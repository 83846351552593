

























import Vue from 'vue';
import { Processor, ProcessorType } from '@/common/types/processors.types';
import { initProcessor } from '@/common/util/processors.util';
import LoadingModal from '@/common/components/LoadingModal.vue';
import api from '@/common/api';

export default Vue.extend({
  props: {
    merchantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: true as boolean,
      isSubmitting: false as boolean,
      processor: initProcessor(ProcessorType.CASH) as Processor,
    };
  },
  methods: {
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    readyToSubmit() {
      this.$emit('readyToSubmit');
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.processor = initProcessor();
    },
    submit() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      api
        .post(`merchants/${this.merchantId}/processors`, {
          key: this.processor.key,
          name: this.processor.name,
          config: this.processor.config,
        })
        .then(() => {
          this.$toasted.success('Success');
          this.reset();
          this.$emit('created');
        })
        .catch(({ response }) => {
          this.$toasted.error(response.data.message);
        })
        .finally(() => this.submitActions(false));
    },
  },
  components: { LoadingModal },
});
