import { DataTimestamps, JsonApiData } from '@/jsonApi.types';
import { AchAccountHolderType, AchAccountType } from '@/common/types/payments.types';
import { MerchantFeature } from '@/common/types/features.types';
import { MerchantCardType } from '@/common/types/cardTypes.types';
import { initMerchantFeatures } from '@/common/util/features.util';
import { initMerchantCardTypes } from '@/common/util/cardTypes.util';
import { NonCashAdjustmentFee } from '@/common/types/nonCashAdjustment.types';

export enum MerchantStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  RESTRICTED = 'restricted',
  CLOSED = 'closed',
  CREATED = 'created',
}
export interface MerchantSettings {
  externalIdentifier: string | null;
  timezone: string;
  mccCode: string;
  restrictTransactionLimits: boolean
  maxTicketAmount: number;
  maxMonthlyVolume: number;
  maxTransactionsInTimeframe: {
    amount: number;
    period: 'days' | 'hours',
    time: number
  };
  maxTransactionsPerIp: {
    amount: number;
    period: 'days' | 'hours',
    time: number
  };
}

export interface MerchantBanking {
  routingNumber: string;
  accountNumber: string;
  accountType: AchAccountType;
  accountHolderType: AchAccountHolderType;
}

export interface SellRate {
  group: string;
  name: string;
  key: string;
  value: number;
  buyRate: number | string;
}

export interface EmailInfo {
  username: string;
}

interface MerchantApiKeys {
  enabled: boolean;
  apiKey: string;
  signatureKey: string;
  transactionKey: string;
  generated: boolean;
}

export interface Merchant extends JsonApiData, DataTimestamps {
  status: MerchantStatus;
  company: string;
  subdomain: string;
  username?: string;
  siteUrl: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  settings: MerchantSettings;
  banking: MerchantBanking;
  sellRates: SellRate[];
  features: MerchantFeature[];
  emailInfo: EmailInfo;
  surchargePercent: number;
  processorCount: number;
  cardTypes: MerchantCardType[];
  apiKeys: MerchantApiKeys;
}
export interface VirtualTerminalMerchant extends JsonApiData, DataTimestamps {
  surchargePercent: number;
  isoName: string;
  isoEmail: string;
  isoPhone: string;
  status: string;
  nonCashAdjustmentFee: NonCashAdjustmentFee,
  confirmed: boolean
}

export function initMerchant(): Merchant {
  return {
    status: MerchantStatus.PENDING,
    company: '',
    subdomain: '',
    username: '',
    siteUrl: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    settings: {
      externalIdentifier: '',
      timezone: 'America/Chicago',
      mccCode: '',
      restrictTransactionLimits: false,
      maxMonthlyVolume: 0,
      maxTicketAmount: 0,
      maxTransactionsInTimeframe: {
        amount: 0,
        period: 'hours',
        time: 1,
      },
      maxTransactionsPerIp: {
        amount: 0,
        period: 'hours',
        time: 1,
      },
    },
    banking: {
      routingNumber: '',
      accountNumber: '',
      accountType: AchAccountType.CHECKING,
      accountHolderType: AchAccountHolderType.BUSINESS,
    },
    sellRates: [],
    features: initMerchantFeatures(),
    emailInfo: {
      username: '',
    },
    surchargePercent: 0,
    processorCount: 0,
    cardTypes: initMerchantCardTypes(),
    apiKeys: {
      enabled: true,
      apiKey: '',
      signatureKey: '',
      transactionKey: '',
      generated: false,
    },
  };
}
