


















































































































import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import { mapGetters } from 'vuex';
import api from '@/common/api';
import PageLoader from '@/common/components/PageLoader.vue';
import ALogoPicker from '@/components/atoms/a-logo-picker.vue';
import Vue from 'vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { Product, initProduct, measurementUnits } from './products.types';

export default Vue.extend({
  data() {
    return {
      product: initProduct() as Product,
      measurementUnits,
      isSubmitting: false as boolean,
      categories: null,
    };
  },
  mounted() {
    api
      .get('merchant/category').then(({ data }) => {
        this.categories = data.data;
        (this.$refs.loader as any).setReady();
      })
      .catch(() => {
        if (this.$refs.loader) {
          (this.$refs.loader as any).setError();
        }
      });
  },
  computed: {
    ...mapGetters(['isInvoicingEnabled']),
    logo: {
      get(): string {
        return this.product.image;
      },
      set(value: any) {
        this.product.image = value;
      },
    },
  },
  methods: {
    save() {
      if (!this.isValid()) {
        return;
      }

      this.product.cost = this.product.cost || 0;
      const cost = Math.round(this.product.cost * 100);
      this.submitActions(true);

      api
        .post('/merchant/products/create', {
          ...this.product, cost,
        })
        .then(this.showSuccess)
        .catch(this.handleSaveError);
    },
    showSuccess() {
      this.submitActions(false);
      this.$toasted.success('Product created successfully!');
      this.$router.replace({ name: 'merchant.products.index' });
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      this.$toasted.error(`There was a problem creating the product. (${response.status})`);
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    ALogoPicker,
    PageLoader,
    LoadingModal,
    PaidFeatureNotEnabled,
  },
});

