<template>
  <div class="support" :class="{ 'dark-mode': darkMode }">
    <div class="support-header">
      <i-support />
      <h1>Support</h1>
    </div>

    <main>
      <div class="main-header">
        <h2>Contact Support</h2>
        <a-spinner v-if="submitting" />
      </div>

      <div v-if="submitted" class="thanking-message">
        <h3>Thank you,</h3>
        <p>we will be in touch soon.</p>
      </div>

      <form v-else @submit.prevent="onSubmit">
        <p>
          Please check out our <router-link :to="{ name: 'merchant.faqs' }">FAQ</router-link>. Below you can submit a ticket
        </p>
        <v-select
          v-model="reason"
          :items="reasons"
          :disabled="submitting"
          outlined
          dense
          hide-details
        />
        <textarea
          v-model="details"
          :disabled="submitting"
          placeholder="Add Comments"
          maxlength="1000"
        >
        </textarea>
        <v-btn color="primary" type="submit" :disabled="!details || submitting">
          Submit
        </v-btn>
      </form>

      <div class="support-footer">
        <a href="mailto:gatewaysupport@risewithaurora.com">
          <i-email />
          gatewaysupport@risewithaurora.com
        </a>

        <a href="tel:+18332876722">
          <i-telephone />
          +1 (833) 287-6722
        </a>
      </div>
    </main>

    <div class="contact">
      <div>{{ branding.company }}</div>
      <div>{{ branding.first_name}} {{ branding.last_name }}</div>
      <a :href="`mailto:${branding.email}`">{{ branding.email }}</a>
      <div>{{ branding.isoPhone }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/common/api';
import ASpinner from '@/components/atoms/a-spinner.vue';
import ISupport from './icons/i-support.vue';
import IEmail from './icons/i-email.vue';
import ITelephone from './icons/i-telephone.vue';

export default {
  components: {
    ISupport,
    IEmail,
    ITelephone,
    ASpinner,
  },
  data() {
    return {
      reason: 'troubleshooting',
      reasons: [
        { text: 'Troubleshooting', value: 'troubleshooting' },
        { text: 'New Equipment/Supplies', value: 'new-equipment-supplies' },
        { text: 'Training', value: 'training' },
        { text: 'Software Support', value: 'software-support' },
        { text: 'Company Update', value: 'company-update' },
        { text: 'Contact Info Update', value: 'contact-info-update' },
        { text: 'Address Update', value: 'address-update' },
        { text: 'Bank Update (TLS)', value: 'bank-update-tls' },
        { text: 'Reprocess Transaction', value: 'reprocess-transaction' },
        { text: 'Funding Inquiries', value: 'funding-inquiries' },
        { text: 'Other', value: 'other' },
      ],
      details: '',
      submitting: false,
      submitted: false,
    };
  },
  computed: {
    ...mapState(['darkMode', ['branding']]),
  },
  created() {
    document.title = 'RiseOs-Support';
  },
  methods: {
    onSubmit() {
      this.submitting = true;

      api.post('/merchant/create-help-ticket', { reason: this.reason, details: this.details })
        .then(() => {
          this.submitted = true;
        })
        .catch(() => {
          this.$toasted.error('There was an error while submitting the ticket');
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped>
.support-header {
  display: flex;
  align-items: center;
  padding-block-end: 0.7em;
  margin-block-end: 6vh;
  border-bottom: 1px solid #d1d9e2;
  font-size: 2rem;
  gap: 1rem;
}

main {
  background: var(--card-background, #fff);
  box-shadow: 0 10px 30px rgba(126 135 140 / 10%);
  border-radius: 0.25rem;
  padding: 1.75rem;
  max-width: 40rem;
  margin-inline: auto;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
}

.contact {
  max-width: 40rem;
  margin-inline: auto;
  font-size: 0.875rem;
  margin-block-start: 1.25rem;
}

.main-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

h2 {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
}

.thanking-message {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thanking-message h3 {
  font-size: 3rem;
  font-weight: 400;
  color: var(--primary-color);
  text-transform: none;
  line-height: 1.1;
}

.thanking-message p {
  font-size: 1.5rem;
  color: var(--text-color, #666666);
  line-height: 1.41;
}

form {
  margin-block: 1.75rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

form p {
  font-size: 0.875rem;
  color: var(--text-color, #666666);
  line-height: 1.42;
}

form .v-select {
  flex: 0;
}

form .v-select >>> fieldset {
  border-color: var(--border-color, #cccccc);
}

form textarea {
  border: 1px solid var(--border-color, #cccccc);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  padding: 1em 2em 2em 1em;
  flex: 1;
  margin-block: 1.25rem 2rem;
}

form button {
  width: fit-content;
  align-self: center;
}

.support-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-footer a {
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.support.dark-mode {
  --text-color: #eeeeee;
  --border-color: #555;
}
</style>
