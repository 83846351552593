<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        name="amount"
        label="Amount"
        :rules="[(v) => v > 0 || 'Amount has to be greater than 0']"
        :value="resetAmount ? 0 : value.amount"
        @input="debouncedAmount"
        :disabled="disabled"
        prefix="$"
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    validationName: {
      type: String,
      required: false,
      default: 'amount',
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetAmount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    debouncedAmount: debounce(function debounced(value) {
      const amount = parseFloat(value) || 0;
      this.$emit('setTotal', Math.round(amount * 100));
    }, 100),
  },
};
</script>
