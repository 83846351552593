export enum MerchantCardTypeName {
  VISA = 'visa',
  AMEX = 'american_express',
  MASTERCARD = 'mastercard',
  DISCOVER = 'discover',
  DINER_CLUB = 'diner_club',
  JCB = 'jcb',
}

export interface MerchantCardType {
  name: MerchantCardTypeName;
  enabled: boolean;
}
