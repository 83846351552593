<template>

  <svg width="45" height="33" viewBox="0 0 45 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.815 21.1709L39.4777 4.25651C39.1961 3.36817 38.2355 2.86984 37.3472 3.15151L24.1088 7.33317H5.09273C4.16106 7.33317 3.39551 8.09873 3.39551 9.03039V26.7682C3.39551 27.6998 4.16106
      28.4654 5.09273 28.4654H11.015L11.9827 31.5348C12.2644 32.4232 13.225 32.9215 14.1133 32.6398L27.3155 28.4726L27.0772 28.4582H36.1122C37.0438 28.4582 37.8094 27.6926 37.8094
      26.761V25.1576L43.6955 23.3015C44.5983 23.0198 45.0966 22.0593 44.815 21.1709Z"
      :fill="darkMode ? '#004A55' : '#99DCE6'" />
    <path
      d="M33.9961 26.3925H2.96944C2.03777 26.3925 1.27222 25.6269 1.27222 24.6953V6.95748C1.27222 6.02581 2.03777 5.26025 2.96944 5.26025H33.9961C34.9278 5.26025 35.6933 6.02581 35.6933
      6.95748V24.6953C35.6933 25.6269 34.9278 26.3925 33.9961 26.3925Z"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path d="M25.1998 26.4072L11.9903 30.5744C11.102 30.8561 10.1414 30.3578 9.85977 29.4694L8.88477 26.3711"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path
      d="M21.675 5.35431L35.2167 1.07875C36.105 0.797085 37.0656 1.29542 37.3473 2.18375L42.6845 19.0982C42.9662 19.9865 42.4678 20.9471 41.5795 21.2288L35.6501 23.0993"
      :stroke="darkMode ? 'white' : 'black'"
      stroke-miterlimit="10"
    />
    <path
      d="M18.9595 20.0732V21.4454H18.3673V20.0876C17.1973 20.0299 16.1067 19.5965 15.5217 19.0043L15.8684 18.3254C16.4173 18.8599 17.3706 19.2643 18.3673 19.3221V16.1949C17.0601 15.8699
      15.7239 15.4654 15.7239 13.8982C15.7239 12.7571 16.5473 11.7676 18.3673 11.6232V10.251H18.9595V11.6088C19.8551 11.6449 20.7434 11.9049 21.3789 12.3454L21.0828 13.046C20.4184 12.6199
      19.6528 12.3888 18.9667 12.3526V15.5088C20.3101 15.8338 21.7256 16.2093 21.7256 17.8054C21.7256 18.9682 20.8517 19.9649 18.9595 20.0732ZM18.3673 15.3571V12.3815C17.1395 12.4899 16.5906
      13.1182 16.5906 13.8693C16.5906 14.7721 17.3923 15.1115 18.3673 15.3571ZM20.8517 17.856C20.8517 16.9171 19.9851 16.5921 18.9595 16.3465V19.3221C20.274 19.2282 20.8517 18.6215 20.8517 17.856Z"
      :fill="darkMode ? 'white' : 'black'"
    />
  </svg>

</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['darkMode']),
  },
};
</script>
