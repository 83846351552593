import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faChartLine,
  faFunnelDollar,
  faTachometerAlt,
  faUsers,
  faCoins,
  faChevronLeft,
  faBars,
  faStoreAlt,
  faCreditCard,
  faReceipt,
  faCalendarAlt,
  faUserShield,
  faChevronDown,
  faCog,
  faLifeRing,
  faSignOutAlt,
  faCheckCircle,
  faTimesCircle,
  faWindowMaximize,
  faMoneyBillAlt,
  faMoneyCheck,
  faSortUp,
  faCaretDown,
  faChevronRight,
  faFilter,
  faPlusCircle,
  faExclamationCircle,
  faRocket,
  faFileUpload,
  faCloudUploadAlt,
  faCheck,
  faCheckSquare,
  faTimes,
  faCopy,
  faClock,
  faBan,
  faShoppingCart,
  faInfoCircle,
  faBox,
  faFileInvoiceDollar,
  faShareSquare,
  faFileDownload,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';

import {
  faSquare as farSquare,
  faDotCircle as farDotCircle,
  faCircle as farCircle,
} from '@fortawesome/free-regular-svg-icons';


Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(
  faChartLine,
  faCoins,
  faFunnelDollar,
  faTachometerAlt,
  faUsers,
  faStoreAlt,
  faCreditCard,
  faReceipt,
  faCalendarAlt,
  faUserShield,
  faSignOutAlt,
  faCheckCircle,
  faCheckSquare,
  faTimesCircle,
  faChevronLeft,
  faChevronDown,
  faBars,
  faCog,
  faLifeRing,
  faWindowMaximize,
  faMoneyBillAlt,
  faMoneyCheck,
  faSortUp,
  faCaretDown,
  faChevronRight,
  faFilter,
  faPlusCircle,
  faExclamationCircle,
  faRocket,
  faFileUpload,
  faCloudUploadAlt,
  faCheck,
  faTimes,
  faCopy,
  faClock,
  faBan,
  faShoppingCart,
  farCircle as any,
  farSquare as any,
  farDotCircle as any,
  faInfoCircle,
  faBox,
  faFileInvoiceDollar,
  faShareSquare,
  faFileDownload,
  faEdit,
);

const CUSTOM_ICONS = {
  square: {
    component: FontAwesomeIcon,
    props: {
      icon: ['far', 'square'],
    },
  },
  filter: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'filter'],
    },
  },
  ban: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'ban'],
    },
  },
  'file-upload': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'file-upload'],
    },
  },
  'cloud-upload-alt': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'cloud-upload-alt'],
    },
  },
  clock: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'clock'],
    },
  },
  rocket: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'rocket'],
    },
  },
  'chevron-right': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'chevron-right'],
    },
  },
  'exclamation-circle': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'exclamation-circle'],
    },
  },
  'caret-down': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'caret-down'],
    },
  },
  'sort-up': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'sort-up'],
    },
  },
  'money-check': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'money-check'],
    },
  },
  check: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'check'],
    },
  },
  'money-bill-alt': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'money-bill-alt'],
    },
  },
  'times-circle': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'times-circle'],
    },
  },
  times: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'times'],
    },
  },
  'plus-circle': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'plus-circle'],
    },
  },
  'check-circle': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'check-circle'],
    },
  },
  copy: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'copy'],
    },
  },
  'question-circle': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'question-circle'],
    },
  },
  'info-circle': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'info-circle'],
    },
  },
  key: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'key'],
    },
  },
  'window-maximize': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'window-maximize'],
    },
  },
  'sign-out-alt': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'sign-out-alt'],
    },
  },
  'life-ring': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'life-ring'],
    },
  },
  cog: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'cog'],
    },
  },
  'user-shield': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'user-shield'],
    },
  },
  box: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'box'],
    },
  },
  invoice: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'file-invoice-dollar'],
    },
  },
  'calendar-alt': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'calendar-alt'],
    },
  },
  receipt: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'receipt'],
    },
  },
  'credit-card': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'credit-card'],
    },
  },
  'store-alt': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'store-alt'],
    },
  },
  'shopping-cart': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'shopping-cart'],
    },
  },
  coins: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'coins'],
    },
  },
  users: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'users'],
    },
  },
  'tachometer-alt': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'tachometer-alt'],
    },
  },
  'funnel-dollar': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'funnel-dollar'],
    },
  },
  'chart-line': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'chart-line'],
    },
  },
  'chevron-down': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'chevron-down'],
    },
  },
  'chevron-left': {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'chevron-left'],
    },
  },
  bars: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'bars'],
    },
  },
  send: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'share-square'],
    },
  },
  download: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fa', 'file-download'],
    },
  },
};

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: CUSTOM_ICONS,
  },
});
