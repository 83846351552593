<template>
  <div>
    <div class="label" :id="`copyField${uuid}`">
      {{ label }}
    </div>

    <div class="content" :aria-describedby="`copyField${uuid}`">
      <button
        @click="copyContentToClipboard"
        type="button"
        aria-label="copy content"
        title="copy content"
        :class="{ copied }"
        :disabled="disabled || secret"
      >
        <i-copy />
      </button>

      <span class="secret" v-if="secret">********</span>
      <span v-else>{{ content }}</span>
    </div>
  </div>
</template>

<script>
import ICopy from '../icons/i-copy.vue';

let uuid = 0;

export default {
  components: { ICopy },
  props: {
    label: { type: String, required: true },
    content: { type: String, required: true },
    secret: { type: Boolean },
    disabled: { type: Boolean },
  },
  data() {
    return {
      copied: false,
    };
  },
  created() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
  methods: {
    copyContentToClipboard() {
      navigator.clipboard.writeText(this.content);

      this.copied = true;
      setTimeout(() => { this.copied = false; }, 1000);
    },
  },
};
</script>

<style scoped>

.label {
  color: #fff;
  opacity: 0.9;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  margin-block-end: 0.5em;
}

.content {
  background: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.5em 0.75em;
  border-radius: 0.5rem;
  max-width: 50ch;
}

.content span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.content .secret {
  font-family: monospace;
  user-select: none;
  letter-spacing: -0.05em;
}

button {
  position: relative;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button::before {
  content: 'copied!';
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 0.75rem;
  line-height: 1;
  transform: translateY(-50%);
  background: #fff;
  color: #000;
  padding: 0.35em 0.6em;
  border-radius: 0.35em;
  pointer-events: none;
  opacity: 0;
  transition: 250ms ease-in opacity;
}

button.copied::before {
  opacity: 1;
  transition: 100ms ease-out opacity;
}
</style>
