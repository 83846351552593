export interface LineItemInterface {
  uuid: string;
  name: string;
  description: string;
  rate: any;
  quantity: any;
  measurementUnit: string;
  lineTotal: any;
}

export interface LineItemsInterface extends LineItemInterface {
}

export function initLineItem(): LineItemInterface {
  return {
    uuid: Math.random().toString(36),
    name: '',
    description: '',
    rate: '',
    quantity: '',
    measurementUnit: '',
    lineTotal: 0,
  };
}

export interface LineItemProduct {
  uuid: string;
  name: string;
  rate: any;
  description: string;
  measurementUnit: string;
}
