































import Vue from 'vue';
import api from '@/common/api';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { IpBlocker, initIpBlocker } from './support.types';

export default Vue.extend({
  data() {
    return {
      newBlockedIp: initIpBlocker() as IpBlocker,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      validationErrors: initValidationErrors() as ValidationErrors,
    };
  },
  methods: {
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      api
        .post('/ip-blocker', {
          blockedIp: this.newBlockedIp.blockedIp,
        })
        .then(() => {
          this.newBlockedIp = initIpBlocker();
          this.$emit('created');
          (this.$refs.form as any).resetValidation();
          this.close();
        })
        .catch(this.handleSaveError);
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      if (response.status === 422) {
        const errors = response?.data?.errors;
        let msg: any = '';
        Object.keys(errors).forEach((key: any, idx: any) => {
          if (idx > 0) msg += '<br />';
          msg += `${errors[key]}`;
        });
        this.$toasted.error(msg);
      } else {
        this.$toasted.error(`There was a problem creating the Blocked IP. (${response.status})`);
      }

      this.validationErrors = response.data.errors || {};
    },
    show() {
      this.showModal = true;
    },
    close() {
      this.submitActions(false);
      this.newBlockedIp = initIpBlocker();
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    LoadingModal,
  },
});
