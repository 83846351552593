

































import Vue from 'vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import ProductsPagesTabs from '@/merchant/components/products-pages-tabs.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      showDeleteModal: false,
      reLoadData: false,
      componentKey: 0,
      config: {
        pageTitle: 'Categories',
        routerBase: 'merchant.categories',
        apiPath: 'merchant/category',
        entityName: 'category',
        identifierProperty: 'name',
        reportFileName: '',
        filters: [],
        headers: [
          {
            text: 'ID',
            value: 'id',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '6rem',
          },
          {
            text: 'Category Name',
            value: 'name',
            class:
              'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Date Created',
            value: 'createdAt',
            class:
              'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class:
              'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '10rem',
          },
        ],
      },
    };
  },
  methods: {
    updateComponentKey() {
      this.componentKey += 1;
    },
  },
  computed: {
    ...mapGetters(['isInvoicingEnabled']),
  },
  components: {
    EntitiesTable,
    PaidFeatureNotEnabled,
    ProductsPagesTabs,
  },
});
