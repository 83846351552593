var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EntitiesTable',{attrs:{"config":_vm.config,"showLoginButton":"","showActions":"","showEditButton":"","showAddButton":"","showSearch":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateShort")(item.createdAt))+" ")]}},{key:"item.lastTransactionAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateShort")(item.lastTransactionAt))+" ")]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"link-text",attrs:{"href":("mailto:" + (item.contact.email))}},[_vm._v(_vm._s(item.contact.name))])]}},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"link-text",attrs:{"href":("" + (item.domain.url))}},[_vm._v(_vm._s(item.domain.subdomain))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uuidLast6")(item.id))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.MerchantStatus.PENDING || item.status === _vm.MerchantStatus.CREATED)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-4 tw-text-gray-400",attrs:{"color":"#ffcb3a","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" $plus-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status === _vm.MerchantStatus.PENDING ? 'Pending' : 'Created'))])])],1):(item.status === _vm.MerchantStatus.ACTIVE)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-4 tw-text-gray-400",attrs:{"color":"#399261","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" $check-circle ")])]}}],null,true)},[_c('span',[_vm._v("Active")])])],1):(item.status === _vm.MerchantStatus.RESTRICTED)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-4 tw-text-gray-400",attrs:{"color":"red","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" $ban ")])]}}],null,true)},[_c('span',[_vm._v("Restricted")])])],1):(item.status === _vm.MerchantStatus.CLOSED)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-4 tw-text-gray-400",attrs:{"color":"red","size":"25"}},'v-icon',attrs,false),on),[_vm._v(" $times-circle ")])]}}],null,true)},[_c('span',[_vm._v("Closed")])])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }