<template>
  <div class="input-wrapper" :class="disabled ? 'tw-opacity-25' : ''">
    <label v-if="hasLabel" :for="name">{{ label }}</label>
    <textarea
      :id="inputId"
      :name="name"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :autofocus="autofocus"
      :disabled="disabled"
      :readonly="readonly"
      :rows="rows"
      :style="getStyle"
    ></textarea>
    <div class="tw-text-xs tw-text-gray-600">
      <slot name="footer"></slot>
    </div>
    <ul class="errors" v-if="errors && validationKey in errors">
      <li v-for="(error, i) in errors[validationKey]" :key="i">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      required: false,
      default: '',
    },
    rows: {
      type: [Number, String],
      required: false,
      default: '3',
    },
    errors: {
      type: Object,
      required: false,
      default: () => {},
    },
    validationName: {
      required: false,
      default: '',
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    textStyle: {
      type: Object,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inputId() {
      return this.id || this.name;
    },
    validationKey() {
      return this.validationName || this.name;
    },
    hasLabel() {
      return this.label !== undefined && this.label !== null && this.label !== '';
    },
    getStyle() {
      return this.textStyle !== undefined && this.textStyle !== null && this.textStyle !== ''
        ? this.textStyle
        : { backgroundColor: '#FFFFFF', outline: '0.5px solid black' };
    },
  },
};
</script>
