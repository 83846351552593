import { AchAccountHolderType, PaymentType } from '@/common/types/payments.types';

export type ElavonConfig = {
  bin: string;
  tid: string;
  mid: string;
  type?: AchAccountHolderType;
}

export type TsysConfig = {
  uidTsys: string;
  midTsys: string;
  password: string;
}

export type FirstDataConfig = {
  gid: string;
  mid: string;
  tid: string;
  type?: AchAccountHolderType;
}

export type OmahaConfig = {
  gid: string;
  mid: string;
  tid: string;
  password: string;
}

export type PayaConfig = {
  username: string;
  password: string;
  terminalNumber: string;
  type: AchAccountHolderType;
}

export enum ProcessorType {
  ELAVON = 'elavon',
  TSYS = 'tsys',
  FIRSTDATA = 'firstdata',
  OMAHA = 'omaha',
  PAYA = 'paya',
  CASH = 'cash',
}

export interface BasicProcessor {
  id: string;
  key: ProcessorType | string | null;
  name: string;
}

export interface Processor extends BasicProcessor {
  config: ElavonConfig | PayaConfig | FirstDataConfig | TsysConfig | TsysConfig | {};
  supports?: PaymentType;
  settlementTimeUtc?: string;
  generateTransactionKey?: boolean;
}

export interface NullableProcessor {
  id: string | null;
  name: string;
  supports: PaymentType | null;
}

export const NO_PROCESSOR: NullableProcessor = {
  id: null,
  name: 'NONE',
  supports: null,
};

export interface PayaProcessorTypeSelectOption {
  display: string;
  value: AchAccountHolderType;
}

export function initProcessors(): Processor {
  return {
    id: Math.random().toString(36),
    key: '',
    name: '',
    settlementTimeUtc: '',
    config: {
    },
  };
}
