



































































import Vue from 'vue';
import { PayaProcessorTypeSelectOption, Processor } from '@/common/types/processors.types';
import { AchAccountHolderType } from '@/common/types/payments.types';
import { initPayaProcessor } from '@/common/util/processors.util';
import LoadingModal from '@/common/components/LoadingModal.vue';
import api from '@/common/api';

const CREATING_BUSINESS_PROCESS_TEXT = 'Creating business processor...';
const CREATING_PERSONAL_PROCESS_TEXT = 'Creating personal processor...';

export default Vue.extend({
  props: {
    merchantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false as boolean,
      loadingText: '' as string,
      processorName: '' as string,
      businessProcessor: initPayaProcessor(AchAccountHolderType.BUSINESS) as Processor,
      personalProcessor: initPayaProcessor(AchAccountHolderType.PERSONAL) as Processor,
      payaTypes: [
        { display: '', value: null },
        { display: 'Business', value: AchAccountHolderType.BUSINESS },
        { display: 'Personal', value: AchAccountHolderType.PERSONAL },
      ] as PayaProcessorTypeSelectOption[],
    };
  },
  methods: {
    isValid(): boolean {
      return (this.$refs.payaNewForm as any).validate();
    },
    submitActions(isSubmitting: boolean, loadingText: string) {
      this.loadingText = loadingText;
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.businessProcessor = initPayaProcessor(AchAccountHolderType.BUSINESS);
      this.personalProcessor = initPayaProcessor(AchAccountHolderType.PERSONAL);
    },
    async submit() {
      if (!this.isValid()) {
        return;
      }

      this.submitActions(true, CREATING_BUSINESS_PROCESS_TEXT);
      try {
        await api.post(`merchants/${this.merchantId}/processors`, {
          key: this.businessProcessor.key,
          name: this.processorName,
          config: this.businessProcessor.config,
        });
        this.$toasted.success('Successfully created business processor');
      } catch (error) {
        this.$toasted.error(error);
        this.submitActions(false, '');
        return;
      }

      this.submitActions(true, CREATING_PERSONAL_PROCESS_TEXT);
      try {
        await api.post(`merchants/${this.merchantId}/processors`, {
          key: this.personalProcessor.key,
          name: this.processorName,
          config: this.personalProcessor.config,
        });
        this.$toasted.success('Successfully created personal processor');
      } catch (error) {
        this.$toasted.error(error);
        this.submitActions(false, '');
        return;
      }

      this.reset();
      this.$emit('created');
      this.submitActions(false, '');
    },
  },
  components: { LoadingModal },
});
