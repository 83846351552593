import { JsonApiData } from '@/jsonApi.types';

export enum ApiTokenScope {
  DEFAULT = 'default',
  HPP_SUBMIT_PAYMENT_SCOPE = 'hpp-submit-payment',
}

export interface ApiToken extends JsonApiData {
  name: string;
  scopes: ApiTokenScope[] | string[];
  hidden?: boolean;
}

export enum CustomFieldType {
  TEXT = 'text',
  CHECK = 'check',
  RADIO = 'single select',
  SELECT = 'multi select'
}

export interface CustomField extends JsonApiData {
  id: string;
  name: string;
  type: CustomFieldType;
  display?: string;
  options: {value: string}[];
  inVirtualTerminal: boolean;
  inHPP: boolean;
  inInvoices: boolean;
  required: boolean;
  label?: string;
  rules?: Array<any>;
  change?: string;
  value?: any;
  isSearchable?: boolean;
}

export interface CustomFieldFillable extends CustomField {
  value: string | boolean | CustomField | CustomField[] | string[];
}

export function initCustomField(): CustomField {
  return {
    id: Math.random().toString(36),
    name: '',
    type: CustomFieldType.TEXT,
    display: '',
    options: [],
    inVirtualTerminal: false,
    inHPP: false,
    inInvoices: false,
    required: false,
    isSearchable: false,
  };
}

export interface CustomFieldOption {
  value: string;
}

export interface SupportInfo extends JsonApiData {
  email: string;
  phone: string;
}

export function initSupportInfo() {
  return {
    email: '',
    phone: '',
  };
}

type DocumentStatus = 'uploaded' | 'missing';

export enum DocumentName {
  W9 = 'W-9',
  ACH = 'ACH Approval',
  VOIDEDCHECK = 'Voided Check',
  APA = 'Affiliate Partner Agreement',
  AGREEMENT = 'Signed Agreement'
}

export interface DocumentInfo extends JsonApiData {
  name: DocumentName;
  url: string;
  status: DocumentStatus;
  reupload: boolean;
  template?: string;
}

export enum InfoFieldCategory {
  SHIPPING = 'Shipping',
  BILLING = 'Billing',
  PAYMENT = 'Payment',
}

export interface InfoField extends JsonApiData {
  id: string;
  name: string;
  inVirtualTerminal: boolean;
  inHPP: boolean;
  required: boolean;
  category: InfoFieldCategory;
}

export function initInfoField(): InfoField {
  return {
    id: Math.random().toString(36),
    name: '',
    inVirtualTerminal: false,
    inHPP: false,
    required: false,
    category: InfoFieldCategory.SHIPPING,
  };
}
export interface IpBlocker extends JsonApiData {
  id: string;
  blockedIp: string;
}

export function initIpBlocker(): IpBlocker {
  return {
    id: Math.random().toString(36),
    blockedIp: '',
  };
}

export interface InfoFieldFillable extends InfoField {
  value: string | boolean | InfoField | InfoField[] | string[];
}


export enum ReceiptSettingCategory {
  LOGO = 'Logo',
}

export interface ReceiptSetting extends JsonApiData {
  uuid: string;
  name: string;
  value: string;
  enabled: boolean;
  category: ReceiptSettingCategory;
}

export function initReceiptSetting(): ReceiptSetting {
  return {
    uuid: Math.random().toString(36),
    name: '',
    value: '',
    enabled: false,
    category: ReceiptSettingCategory.LOGO,
  };
}

export interface ShoppingCart extends JsonApiData {
  name: string;
  dbaName: string;
  email: string;
  phone: string;
  storeName: string,
  storeCategory: string;
  myterms: string;
  mypassword: string;
  affiliateId: string;
  affiliateLogo: string;
  merchantId: string;
  gateway: string;
}

export function initShoppingCart(): ShoppingCart {
  return {
    name: '',
    dbaName: '',
    email: '',
    phone: '',
    storeName: '',
    storeCategory: '',
    myterms: '',
    mypassword: '',
    affiliateId: '',
    affiliateLogo: '',
    merchantId: '',
    gateway: '',
  };
}


export interface DefaultLevel3Setting extends JsonApiData {
  orderId: string;
  orderIdUseTransactionTd: boolean;
  poNumber: string;
  poNumberUseTransactionId: boolean;
  orderDescription: string;
  orderDescriptionUseTransactionId: boolean;
  shippingAmount: number;
  salesTaxAmount: number;
  salesTaxRate: number;
  taxExempt: boolean;
  discountAmount: number;
  nationalTaxAmount: number;
  nationalTaxRate: number;
  duty: number;
  merchantVat: string;
  customerVat: string;
  shipFromZipCode: string;
  uniqueVatInvoice: string;
  vatTaxAmount: number;
  vatTaxRate: string;
  summaryCommodityCode: string;
  orderDate: string;
  orderDateUseTransactionDate: boolean;
  productSku: string;
  productDescription: string;
  productCost: number;
  productQuantity: number;
  productTaxAmount: number;
  productTaxRate: number;
  productDiscountAmount: number;
}


export function initDefaultLevel3Setting(): DefaultLevel3Setting {
  return {
    orderId: '',
    orderIdUseTransactionTd: false,
    poNumber: '',
    poNumberUseTransactionId: false,
    orderDescription: '',
    orderDescriptionUseTransactionId: false,
    shippingAmount: 0,
    salesTaxAmount: 0,
    salesTaxRate: 0,
    taxExempt: false,
    discountAmount: 0,
    nationalTaxAmount: 0,
    nationalTaxRate: 0,
    duty: 0,
    merchantVat: '',
    customerVat: '',
    shipFromZipCode: '',
    uniqueVatInvoice: '',
    vatTaxAmount: 0,
    vatTaxRate: '',
    summaryCommodityCode: '',
    orderDate: '',
    orderDateUseTransactionDate: false,
    productSku: '',
    productDescription: '',
    productCost: 0,
    productQuantity: 0,
    productTaxAmount: 0,
    productTaxRate: 0,
    productDiscountAmount: 0,
  };
}

export enum AvsSettingCategory {
  GENERAL = 'general',
  ADDRESS = 'address',
}

export enum AvsSettingResponse {
  ALLOW = 'allow',
  ALLOW_REPORT = 'allow_report',
  AUTH_AND_HOLD_FOR_REVIEW = 'auth_and_hold_for_review',
  DECLINE = 'decline',
}

export interface AvsSetting extends JsonApiData {
  merchantId: number;
  avsResponseId: number;
  avsCategory: AvsSettingCategory;
  avsSetting: AvsSettingResponse;
  avsCode: string;
  avsDescription: string;
  avsStreetAddress: string;
  avsZipCode: string;
  avsExtendedZip: string;
}

export interface TransactionLimit extends JsonApiData {
  restrictTransactionLimits: boolean;
  maxTicketAmount: number;
  maxMonthlyVolume: number;
}

export function initTransactionLimit(): TransactionLimit {
  return {
    restrictTransactionLimits: false,
    maxTicketAmount: 0,
    maxMonthlyVolume: 0,
  };
}
export interface IpWhitelist extends JsonApiData {
  id: string;
  name: string;
  ipAddress: string;
}

export function initIpWhitelist(): IpWhitelist {
  return {
    id: Math.random().toString(36),
    name: '',
    ipAddress: '',
  };
}
