

















































import Vue from 'vue';
import api from '@/common/api';
import { mapGetters } from 'vuex';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { Processor, initProcessors } from '@/common/types/processors.types';

export default Vue.extend({
  props: {
    processorToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTimePicker: false,
      newProcessor: initProcessors() as Processor,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      logo: '' as string,
      times: [],
    };
  },
  watch: {
    processorToEdit() {
      this.newProcessor = this?.processorToEdit;
    },
    newProcessor() {
      this.loadProcessorData();
    },
  },
  computed: {
    ...mapGetters(['branding']),
  },
  mounted() {
    this.getTimeDropdownValues();
  },
  methods: {
    getTimeDropdownValues() {
      const timesObj:any = [];
      for (let i:number = 0; i < 24; i += 1) {
        const tval = `${i.toString().padStart(2, '0')}:00:00`;
        let tlabel = i < 12 ? `${i} AM` : `${i - 12} PM`;

        if (i === 0) tlabel = '12 AM';
        else if (i === 12) tlabel = '12 PM';

        timesObj[i] = { label: tlabel, val: tval };
      }
      this.times = timesObj;
    },
    loadProcessorData() {
      if (this?.newProcessor.name === null
          || this?.newProcessor.name === ''
          || Object.keys(this?.newProcessor).length === 0) return;

      this.showModal = true;
    },
    updateLogo(logo: string) {
      this.logo = logo;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }

      this.submitActions(true);

      api
        .patch(`/processors/${this?.newProcessor?.id}`, {
          settlementTimeUtc: this.newProcessor.settlementTimeUtc,
        })
        .then(() => {
          this.close();
          this.newProcessor = initProcessors();
          this.$emit('created');
          (this.$refs.form as any).reset();
        })
        .catch(({ response }: { response: any }) => {
          this.submitActions(false);
          if (response.status === 422) {
            const errors = response?.data?.errors;
            let msg: any = '';
            Object.keys(errors).forEach((key: any, idx: any) => {
              if (idx > 0) msg += '<br />';
              msg += `${errors[key]}`;
            });
            this.$toasted.error(msg);
          } else {
            // const message = this.$errorMessages.get('default_delete');
            this.$toasted.error(`There was a problem creating the processor. (${response.status})`);
            this.close();
          }
        });
    },
    show() {
      this.newProcessor = this?.processorToEdit;
      this.showModal = false;
    },
    close() {
      this.newProcessor = initProcessors();
      this.submitActions(false);
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    LoadingModal,
  },
});
