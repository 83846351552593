var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EntitiesTable',{attrs:{"config":_vm.config,"showDownloadCsvButton":false,"reLoadData":_vm.reLoadData},on:{"dataLoaded":_vm.updateReloadData},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pr-2"},[_vm._v("Receipt Settings")]),_c('v-btn',{attrs:{"x-small":"","color":"secondary"},on:{"click":_vm.openNewReceiptSettingModal}},[_vm._v("Add")])],1)]},proxy:true},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [(item.enabled)?_c('v-icon',{attrs:{"color":"#23b912"}},[_vm._v("$check")]):_c('v-icon',{attrs:{"color":"#FF0000"}},[_vm._v("$times")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editReceiptSetting(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Receipt Setting")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteEntity(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Receipt Setting")])])]}}])}),_c('ReceiptSettingNew',{ref:"newModal",on:{"created":_vm.getData}}),_c('ReceiptSettingEdit',{ref:"editModal",attrs:{"receiptSettingToEdit":_vm.receiptSettingToEdit},on:{"created":_vm.getData}}),_c('DeleteModal',{attrs:{"deleteModal":_vm.deleteModal,"entitySelectedForDeletion":_vm.entitySelectedForDeletion,"config":_vm.config,"modalAction":"delete"},on:{"deleteEntity":_vm.deleteEntity,"closeDeleteModal":_vm.closeDeleteModal}}),_c('LoadingModal',{attrs:{"loadingText":"Deleting...","showModal":_vm.isDeleting}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }