var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.status === _vm.IsoStatus.ACTIVATED)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('i-check-circle')],1)]}}],null,false,631358570)},[_vm._v(" Live ")]):(_vm.item.status === _vm.IsoStatus.HOLD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('i-do-not')],1)]}}])},[_vm._v(" On Hold - No Commissions Paid ")]):(_vm.item.status === _vm.IsoStatus.QUALIFIED)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({on:{"click":_vm.activateIso}},'div',attrs,false),on),[_c('i-rocket')],1)]}}])},[_vm._v(" Launch ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('i-onboarding')],1)]}}])},[_vm._v(" Boarding ")])}
var staticRenderFns = []

export { render, staticRenderFns }