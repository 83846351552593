









































































import Vue from 'vue';

import { Processor, ProcessorType } from '@/common/types/processors.types';
import { AchAccountHolderType } from '@/common/types/payments.types';
import api from '@/common/api';
import { JsonApiArrayResponse } from '@/jsonApi.types';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { availableProcessorsDisplayTest } from '@/common/util/processors.util';
import MerchantProcessorEdit from './MerchantProcessorEdit.vue';
import MerchantProcessorNew from './MerchantProcessorNew.vue';

export default Vue.extend({
  props: {
    merchantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      availableProcessorsDisplayTest,
      processors: [] as Processor[],
      meta: {} as any,
      loading: true,
      options: {} as any,
      showDeleteModal: false as boolean,
      processorToDelete: null as Processor | null,
      processorToEdit: {} as Processor,
      isSubmitting: false as boolean,
      loadingText: '' as string,
      showAddButton: true as boolean,
      headers: [
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'entity-table-header subtitle-1 font-weight-black',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          class: 'entity-table-header subtitle-1 font-weight-black',
        },
        {
          text: 'Supports',
          value: 'supports',
          sortable: false,
          class: 'entity-table-header subtitle-1 font-weight-black',
        },
        {
          text: 'Key',
          value: 'key',
          sortable: false,
          class: 'entity-table-header subtitle-1 font-weight-black',
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    showDeleteModal() {
      if (!this.showDeleteModal) {
        this.processorToDelete = null;
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.merchantId === '') {
        this.showAddButton = false;
        this.loading = false;
        return;
      }

      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.options;
      this.loading = true;
      const params: any = {
        page,
        perPage: itemsPerPage === -1 ? this.meta.total : itemsPerPage,
        role: '',
        sortBy,
        sortDesc,
      };
      api
        .get(`merchants/${this.merchantId}/processors`, { params })
        .then(this.setProcessors)
        .catch(() => {
          this.processors = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setProcessors({ data }: { data: JsonApiArrayResponse<Processor> }) {
      this.meta = data.meta;
      const tempProcessors = data.data!.filter((item:Processor) => this.isNotPersonalPaya(item));
      this.processors = tempProcessors;
      this.$emit('updateProcessors', this.processors);
    },
    submitActions(isSubmitting: boolean, loadingText: string = 'Deleting...') {
      this.isSubmitting = isSubmitting;
      this.loadingText = loadingText;
    },
    promptForDelete(processor: Processor) {
      this.showDeleteModal = true;
      this.processorToDelete = processor;
    },
    deleteProcessor() {
      this.showDeleteModal = false;
      this.submitActions(true);
      api
        .delete(`merchants/${this.merchantId}/processors/${this.processorToDelete!.id}`)
        .then(() => {
          this.processorToDelete = null;
          this.getData();
        })
        .catch(({ response }) => {
          const message = response?.data?.message || 'Failed to delete. Try again.';
          this.$toasted.error(`${message} (${response.status})`);
        })
        .finally(() => {
          this.submitActions(false);
        });
    },
    editProcessor(processor: Processor) {
      this.processorToEdit = processor;
      (this.$refs.editModal as any).show();
    },
    newProcessor() {
      (this.$refs.newModal as any).show();
    },
    showAddBtn() {
      return this.merchantId !== '';
    },
    isNotPersonalPaya(processor: any) {
      return !(processor.key === ProcessorType.PAYA
        && processor.config?.type === AchAccountHolderType.PERSONAL);
    },
  },
  components: {
    LoadingModal,
    MerchantProcessorEdit,
    MerchantProcessorNew,
  },
});
