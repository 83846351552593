







































import Vue from 'vue';
import { SubscriptionStatus } from '@/common/types/subscriptions.types';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { EntityFilterType } from '@/common/components/entities/entities.types';
import { RecurrenceFrequency } from '@/common/types/billingRecurrences.types';
import ReportsPagesTabs from '@/merchant/components/reports-pages-tabs.vue';

export default Vue.extend({
  data() {
    return {
      EntityFilterType,
      RecurrenceFrequency,
      SubscriptionStatus,
      config: {
        pageTitle: 'Subscription Report',
        routerBase: 'merchant.subscriptions',
        apiPath: 'subscriptions',
        entityName: 'subscription',
        identifierProperty: 'name',
        reportFileName: 'recurring_report',
        filters: [
          {
            name: 'status',
            display: 'Status',
            value: '',
            type: EntityFilterType.SELECT,
            item: [
              { display: 'Active', value: SubscriptionStatus.ACTIVE },
              { display: 'Completed', value: SubscriptionStatus.COMPLETED },
              { display: 'Canceled', value: SubscriptionStatus.CANCELED },
              { display: 'Delinquent', value: SubscriptionStatus.DELINQUENT },
            ],
          },
          {
            name: 'frequency',
            display: 'Frequency',
            value: '',
            type: EntityFilterType.SELECT,
            items: [
              { display: 'All', value: '' },
              { display: 'Daily', value: RecurrenceFrequency.DAILY },
              { display: 'Weekly', value: RecurrenceFrequency.WEEKLY },
              { display: 'Monthly', value: RecurrenceFrequency.MONTHLY },
              { display: 'Yearly', value: RecurrenceFrequency.YEARLY },
            ],
          },
          {
            name: 'name',
            display: 'Name',
            value: '',
            type: EntityFilterType.TEXT,
          },
          {
            name: 'description',
            display: 'Description',
            value: '',
            type: EntityFilterType.TEXT,
          },
        ],
        headers: [
          { text: 'ID', value: 'id', align: ' d-none' },
          { text: 'Name', value: 'name', align: ' d-none' },
          {
            text: 'Status',
            value: 'status',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Customer',
            value: 'customer',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Amount',
            value: 'amount',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Payments',
            value: 'payments',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Frequency',
            value: 'recurrenceFrequency',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Type',
            value: 'paymentMethod',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Last Date',
            value: 'lastRun',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Next Date',
            value: 'nextRun',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
        ],
      },
    };
  },
  components: {
    EntitiesTable,
    ReportsPagesTabs,
  },
});
