var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"entities-table-container"},[_c('v-data-table',{attrs:{"headers":_vm.config.headers,"items":_vm.items,"options":_vm.options,"multi-sort":true,"server-items-length":_vm.meta.total,"header-props":{
      sortIcon: 'mdi-arrow-down',
    },"loading":_vm.loading,"footer-props":{ 'items-per-page-options': _vm.footerItemsPerPage },"item-class":"color"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.showToolbar)?_c('v-toolbar',{attrs:{"flat":""}},[(_vm.showHeaderTitle && _vm.$vuetify.breakpoint.name !== 'xs')?_c('v-toolbar-title',{staticClass:"toolbar-title"},[_vm._v(" "+_vm._s(_vm.config.header)+" ")]):_vm._e(),(_vm.allowAdd && !_vm.openModal)?_c('router-link',{staticClass:"add-button-link",class:{ 'no-title': !_vm.showHeaderTitle },attrs:{"to":{ name: ((_vm.config.routerBase) + ".new") }}},[_vm._v(" "+_vm._s(_vm.config.createButtonText || 'Add +')+" ")]):_vm._e(),(_vm.allowAdd && _vm.openModal)?_c('v-btn',{attrs:{"x-small":"","color":"secondary"},on:{"click":function($event){_vm.addModal = true}}},[_vm._v(" Add ")]):_vm._e(),(_vm.showFilters && _vm.config.filters)?_c('v-dialog',{attrs:{"max-width":"680px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('button',_vm._g({staticClass:"filter-button"},on),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("$filter")]),_vm._v(" Filters ")],1)]}}],null,false,411709307),model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-card',{staticClass:"filters-dialog"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Filter by")])]),_c('v-card-text',[_c('v-container',[_c('v-row',_vm._l((_vm.config.filters),function(filter){return _c('v-col',{key:filter.name,class:_vm.config.reportFileName === 'transaction_details_report' && filter.name === 'minAmount'
                          ? 'tw-mr-px'
                          : _vm.config.reportFileName === 'transaction_details_report' && filter.name === 'maxAmount'
                          ? 'tw--ml-px'
                          : '',attrs:{"cols":"12","sm":"6","md":"6"}},[(filter.type === _vm.EntityFilterType.TYPE_AHEAD)?_c('v-autocomplete',{attrs:{"items":_vm.autoCompleteSearchResults,"loading":_vm.autoCompleteSearchLoading,"search-input":_vm.autoCompleteSearch,"color":"white","hide-no-data":"","hide-selected":"","label":filter.value || filter.display,"placeholder":"Start typing to Search"},on:{"update:searchInput":function($event){_vm.autoCompleteSearch=$event},"update:search-input":function($event){_vm.autoCompleteSearch=$event}},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.type === _vm.EntityFilterType.TEXT)?_c('v-text-field',{attrs:{"label":filter.display},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.type === _vm.EntityFilterType.SELECT)?_c('v-select',{attrs:{"items":filter.items,"item-text":"display","label":filter.display},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                        filter.type === _vm.EntityFilterType.DATE ||
                        filter.type === _vm.EntityFilterType.DATE_MONTH
                      )?_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":filter.value,"label":filter.display,"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(filter.open),callback:function ($$v) {_vm.$set(filter, "open", $$v)},expression:"filter.open"}},[_c('v-date-picker',{staticClass:"white--text",attrs:{"type":filter.type === _vm.EntityFilterType.DATE
                            ? 'date'
                            : 'month',"max":new Date().toISOString(),"no-title":"","scrollable":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"secondary"},on:{"click":function($event){filter.open = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"small":"","text":"","color":"secondary"},on:{"click":function($event){filter.open = false}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey"},on:{"click":_vm.resetFilters}},[_vm._v(" Reset ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.applyFilter}},[_vm._v(" Apply ")])],1)],1)],1):_vm._e(),_c('v-spacer'),(_vm.showSearch)?_c('v-text-field',{staticClass:"pr-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(_vm.showDownloadCsvButton)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.downloadCsv}},[_vm._v(" Export as CSV ")]):_vm._e(),(_vm.showCustomButton)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"secondary","dark":"","small":""},on:{"click":function($event){_vm.customModal = true}}},[_vm._v(" "+_vm._s(_vm.customButtonText)+" ")]):_vm._e()],1):_vm._e()]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),(_vm.showActions)?{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[(_vm.allowView(item))?_c('v-btn',{attrs:{"to":{ name: ((_vm.config.routerBase) + ".view"), params: { id: item.id } },"color":"secondary","dense":"","x-small":""}},[_vm._v(" View ")]):_vm._e(),(_vm.showLoginButton)?_c('v-btn',{attrs:{"href":item.domain.url + "/login-jwt?token=" + _vm.accessToken,"target":"_blank","color":"secondary","dense":"","x-small":""}},[_vm._v(" Login ")]):_vm._e(),(_vm.showEditButton && !_vm.openModal)?_c('v-btn',{attrs:{"disabled":!_vm.allowEdit(item),"color":"secondary","dense":"","x-small":"","to":{ name: ((_vm.config.routerBase) + ".edit"), params: { id: item.id } }}},[_vm._v(" Edit ")]):_vm._e(),(_vm.showEditButton && _vm.openModal)?_c('v-btn',{attrs:{"disabled":!_vm.allowEdit(item),"color":"secondary","dense":"","x-small":""},on:{"click":function($event){return _vm.editModalFunction(item.id)}}},[_vm._v(" Edit ")]):_vm._e(),(_vm.showDeleteButton)?_c('v-btn',{attrs:{"disabled":!_vm.allowDelete(item),"color":"secondary","dense":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteEntity(item)}}},[_vm._v(" Delete ")]):_vm._e()],1)]}}:null,(_vm.config.footer)?{key:"body.append",fn:function(ref){
                            var headers = ref.headers;
return [(_vm.isMobile())?_c('tr',[_c('td',{staticClass:"text-start",attrs:{"colspan":headers.length}},[_c('span',{staticStyle:{"font-size":"0.875rem"}},[_vm._v(_vm._s(_vm.config.footer.header))]),_vm._l((headers.slice(_vm.showActions ? 2 : 1)),function(header,i){return _c('span',{key:i,staticClass:"tw-float-right"},[(
                _vm.config.reportFileName === 'batch_settlements_report' &&
                _vm.config.footer.displayHeaders.includes(header.value)
              )?_c('span',{staticClass:"tw-mr-2 tw-text-sm"},[_vm._v(" "+_vm._s(header.text)+": "+_vm._s(_vm.config.footer.displayFunction( _vm.$options.filters, _vm.items, header.value ))+" ")]):(_vm.config.footer.displayHeaders.includes(header.value))?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.config.footer.displayFunction( _vm.$options.filters, _vm.items, header.value ))+" ")]):_c('span')])})],2)]):_c('tr',[_c('th',{staticStyle:{"font-size":"0.875rem"}},[_vm._v(_vm._s(_vm.config.footer.header))]),_vm._l((headers.slice(_vm.showActions ? 2 : 1)),function(header,i){return _c('td',{key:i,class:_vm.config.reportFileName === 'transaction_details_report'
              ? headers[i].align
              : ''},[(_vm.config.footer.displayHeaders.includes(header.value))?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.config.footer.displayFunction( _vm.$options.filters, _vm.items, header.value ))+" ")]):_c('div')])})],2)]}}:null],null,true)}),_c('AddModal',{attrs:{"addModal":_vm.addModal},on:{"closeAddModal":_vm.closeAddModal,"saveCategory":_vm.saveCategory}}),_c('EditModal',{attrs:{"editModal":_vm.editModal,"id":_vm.categoryId},on:{"closeEditModal":_vm.closeEditModal,"updateCategory":_vm.updateCategory}}),_c('DeleteModal',{attrs:{"deleteModal":_vm.deleteModal,"entitySelectedForDeletion":_vm.entitySelectedForDeletion,"config":_vm.config,"modalAction":"close"},on:{"deleteEntity":_vm.deleteEntity,"closeDeleteModal":_vm.closeDeleteModal}}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.customModal),callback:function ($$v) {_vm.customModal=$$v},expression:"customModal"}},[[_vm._t("customModal",null,{"closeFunction":_vm.closeCustomModal})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }