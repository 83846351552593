




















import Vue from 'vue';

export default Vue.extend({
  props: {
    config: {
      type: Object,
      required: true,
    },
    deleteModal: {
      type: Boolean,
    },
    entitySelectedForDeletion: {
      type: Object,
    },
    modalAction: {
      type: String,
    },
    eventToBeTriggered: {
      type: String,
      required: false,
    },
  },
  computed: {
    identifierProperty(): string {
      if (this.entitySelectedForDeletion) {
        if (this.entitySelectedForDeletion[this.config.identifierProperty]) {
          return this.entitySelectedForDeletion[this.config.identifierProperty];
        } return this.config.name;
      }
      return '';
    },
  },
});
