<template>
  <div class="card">
    <div class="card-header">
      <h2>Auth.net Emulator</h2>

      <a-checkbox v-model="apiKeys.enabled">
        Enable
      </a-checkbox>
    </div>

    <div class="card-content" :class="{ disabled: !apiKeys.enabled }">
      <ul v-if="apiKeys.apiKey || apiKeys.generated">
        <li>
          <a-copy-field
            label="API Key"
            :content="apiKeys.apiKey"
            :secret="apiKeys.generated"
          />
        </li>
        <li>
          <a-copy-field
            label="Transaction Key"
            :content="apiKeys.transactionKey"
            :secret="apiKeys.generated"
          />
        </li>
        <li>
          <a-copy-field
            label="Signature Key"
            :content="apiKeys.signatureKey"
            :secret="apiKeys.generated"
          />
        </li>
      </ul>

      <a-button
        v-else
        @click="generateKey"
        :loading="generatingKeys"
        :disabled="!apiKeys.enabled"
        class="generate-button"
        full-width
      >
        <i-reload />
        Generate API KEY
      </a-button>
    </div>
  </div>
</template>

<script>
import api from '@/common/api';
import ACopyField from '@/components/atoms/a-copy-field.vue';
import ACheckbox from '@/components/atoms/a-checkbox.vue';
import AButton from '@/components/atoms/a-button.vue';
import IReload from '@/components/icons/i-reload.vue';

export default {
  components: {
    ACopyField,
    AButton,
    IReload,
    ACheckbox,
  },
  props: {
    apiKeys: { type: Object, required: true },
  },
  data() {
    return {
      generatingKeys: false,
    };
  },
  watch: {
    /**
     * Removes the generated keys when the enable checkbox is
     * set to false. This forces the flow to restart back to
     * "click to generate".
     *
     * Also, the generated value is set to false so that backend
     * knows that the keys are new.
     */
    // eslint-disable-next-line object-shorthand
    'apiKeys.enabled'(value) {
      if (value) return;

      if (this.apiKeys.apiKey || this.apiKeys.generated) {
        this.apiKeys.apiKey = '';
        this.apiKeys.signatureKey = '';
        this.apiKeys.transactionKey = '';
        this.apiKeys.generated = false;
      }
    },
  },
  methods: {
    generateKey() {
      this.generatingKeys = true;

      api.get('/emulatorkeys')
        .then(({ data }) => {
          this.apiKeys.apiKey = data.apiKey;
          this.apiKeys.signatureKey = data.signatureKey;
          this.apiKeys.transactionKey = data.transactionKey;
        })
        .catch(() => {
          const message = this.$errorMessages.get('merchants.authnet_emulator_key_generation');
          this.$toasted.error(message);
        })
        .finally(() => {
          this.generatingKeys = false;
        });
    },
  },
};
</script>

<style scoped>
.card {
  background: transparent;
  color: #fff;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--primary-color);
  filter: brightness(0.7);
  border-radius: 0.5rem;
  z-index: -1;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h2 {
  margin: 0;
  font-weight: 700;
  font-size: 1.125rem;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-content.disabled {
  filter: blur(3px);
  cursor: not-allowed;
}

.card-content.disabled > * {
  pointer-events: none;
}

input {
  accent-color: var(--primary-color);
}

label {
  font-weight: 600;
  font-size: 1rem;
}

li {
  margin-block: 2vh;
}

.generate-button {
  margin-block: auto;
}
</style>
