











































import Vue from 'vue';

import api from '@/common/api';
import PageHeader from '@/common/components/PageHeader.vue';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { Processor } from '@/common/types/processors.types';
import { mapGetters } from 'vuex';
import { JsonApiSingleResponse } from '@/jsonApi.types';
import { CreditMethod, PaymentMethod, PaymentType } from '@/common/types/payments.types';
import { initPaymentMethod, readablePaymentMethod } from '@/common/util/payments.util';
import getSubdomain from '@/common/context';
import { VirtualTerminalMerchant } from '@/iso/views/merchants/merchants.types';
import PaidFeatureNotEnabled from '@/common/components/PaidFeatureNotEnabled.vue';
import PaymentMethodForm from '../../components/payments/PaymentMethodForm.vue';

export default Vue.extend({
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      paymentMethod: initPaymentMethod(
        PaymentType.CREDIT,
        CreditMethod.AUTH_AND_CAPTURE,
      ) as PaymentMethod,
      validationErrors: initValidationErrors() as ValidationErrors,
      isSubmitting: false as boolean,
      virtualTerminalMerchant: {} as VirtualTerminalMerchant,
    };
  },
  created() {
    document.title = 'RiseOS-Create Payment Method';
  },
  mounted() {
    if (!this.isCustomerVaultEnabled || this.isCustomerVaultEnabled === undefined) {
      this.$store.dispatch('loadFeatures').then(() => {
        if (this.isCustomerVaultEnabled) {
          this.loadCustomerProcessors();
          this.loadVirtualTerminalMerchant();
        } else {
          this.$router.push({ name: 'merchant.customers.index' });
        }
      });
    } else if (this.isCustomerVaultEnabled) {
      this.loadCustomerProcessors();
      this.loadVirtualTerminalMerchant();
    }
  },
  computed: {
    ...mapGetters(['brandName', 'processors', 'context', 'isCustomerVaultEnabled']),
    supportedProcessors(): Processor[] {
      return (this.processors as Processor[]).filter((p) => p.supports === this.paymentMethod.type);
    },
    readablePaymentMethod(): string {
      return readablePaymentMethod(this.paymentMethod.type);
    },
    isPaymentTypeAvailable(): boolean {
      return this.supportedProcessors.length > 0;
    },
  },
  methods: {
    loadVirtualTerminalMerchant() {
      api.get(`merchants/${getSubdomain()}/virtual-terminal`).then(this.setVirtualTerminalMerchant);
    },
    setVirtualTerminalMerchant({ data }: { data: JsonApiSingleResponse<VirtualTerminalMerchant> }) {
      const virtualTerminalMerchant: VirtualTerminalMerchant = data.data!;
      this.virtualTerminalMerchant = virtualTerminalMerchant;
    },
    loadCustomerProcessors() {
      this.$store
        .dispatch('loadMerchantProcessors', this.context)
        .then(() => {
          const selected = this.processors[0];
          this.paymentMethod.processorId = selected.id;
          this.paymentMethod.type = selected.supports;

          (this.$refs.processorLoader as any).setReady();
        })
        .catch(() => {
          this.$toasted.error('Failed to load processors! Please refresh the page to try again.');
        });
    },
    submitActions(isSubmitting: boolean, resetForms: boolean = false) {
      this.isSubmitting = isSubmitting;
      if (!isSubmitting && resetForms) {
        this.resetForms();
      }
    },
    resetForms() {
      this.paymentMethod = initPaymentMethod(PaymentType.CREDIT, CreditMethod.AUTH_AND_CAPTURE);
      this.validationErrors = initValidationErrors();
    },
    submit() {
      this.submitActions(true);
      api
        .post(`/customers/${this.customerId}/payment-methods`, this.paymentMethod)
        .then(this.showSuccess)
        .catch(this.handleSaveError);
    },
    showSuccess({ data }: { data: JsonApiSingleResponse<PaymentMethod> }) {
      this.submitActions(false, true);
      this.resetForms();
      this.$toasted.success('Payment method added successfully!');
      this.$router.push({
        name: 'merchant.customers.view',
        params: { id: data.data!.customerId! },
      });
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      this.$toasted.error(`There was a problem adding the payment method. (${response.status})`);
      this.validationErrors = response.data.errors || {};
    },
  },
  components: {
    PageHeader,
    PaymentMethodForm,
    PageLoader,
    LoadingModal,
    PaidFeatureNotEnabled,
  },
});
