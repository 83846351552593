<template>
  <p-container>
    <p-page-title>
      {{ id ? 'Edit' : 'Add' }} Merchant Address
    </p-page-title>

    <v-form v-model="isFormValid" @submit.prevent="saveAddress">
      <v-text-field
        v-model="address.address1"
        label="Address 1"
        :rules="[(v) => !!v || 'Address 1 is required']"
        :disabled="loading || submitting"
        dense
        outlined
        prefix="*"
      />

      <v-text-field
        v-model="address.address2"
        label="Address 2"
        :disabled="loading || submitting"
        hint="Optional"
        dense
        outlined
      />

      <v-autocomplete
        v-model="address.country"
        label="Country"
        :items="countries"
        item-text="name"
        item-value="alpha2Code"
        :loading="loadingCountries"
        :disabled="loading || submitting"
        dense
        outlined
        prefix="*"
      />

      <v-autocomplete
        v-model="address.state"
        label="State"
        :items="states"
        item-text="name"
        item-value="abbreviation"
        :loading="loadingStates"
        :disabled="loading || submitting"
        :rules="[(v) => !!v || 'State is required']"
        dense
        outlined
        prefix="*"
      />

      <v-text-field
        v-model="address.city"
        label="City"
        :rules="[(v) => !!v || 'City is required']"
        :disabled="loading || submitting"
        dense
        outlined
        prefix="*"
      />

      <v-text-field
        v-model="address.zip"
        label="Zip"
        :disabled="loading || submitting"
        :rules="[(v) => !!v || 'Zip is required']"
        dense
        outlined
        prefix="*"
      />

      <v-btn
        color="primary"
        type="submit"
        :disabled="loading || submitting || !isFormValid"
        :loading="submitting"
      >
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-form>
  </p-container>
</template>

<script>
import api from '@/common/api';
import PContainer from '@/components/presentations/p-container.vue';
import PPageTitle from '@/components/presentations/p-page-title.vue';

export default {
  components: { PContainer, PPageTitle },
  props: {
    id: String,
  },
  data() {
    return {
      address: this.initAddress(),
      countries: [],
      states: [],

      isFormValid: true,
      loading: false,
      loadingCountries: false,
      loadingStates: false,
      submitting: false,
    };
  },
  computed: {
    isEditMode() {
      return !!this.id;
    },
  },
  created() {
    if (this.isEditMode) this.fetchMerchantAddress();

    this.fetchCountries();
    this.fetchStates();
  },
  methods: {
    async fetchMerchantAddress() {
      this.loading = true;

      try {
        const { data } = await api.get(`address/${this.id}`);
        this.address = this.initAddress(data.data);
      } catch (e) {
        this.$toasted.error('There was an error while loading the address');
      } finally {
        this.loading = false;
      }
    },
    async saveAddress() {
      this.submitting = true;

      try {
        if (this.isEditMode) {
          await api.put(`address/${this.id}`, this.address);
        } else {
          await api.post('address', this.address);
        }

        this.$toasted.success('Merchant address successfully saved');
        await this.$router.push({ name: 'merchant.settings.addresses' });
      } catch (e) {
        this.$toasted.error('There was an error while saving the address');
      } finally {
        this.submitting = false;
      }
    },
    async fetchCountries() {
      this.loadingCountries = true;

      try {
        const { data } = await api.get('countries');
        this.countries = data.data;
      } catch (e) {
        this.$toasted.error('There was an error while loading the list of countries');
      } finally {
        this.loadingCountries = false;
      }
    },
    async fetchStates() {
      this.loadingStates = true;

      try {
        const { data } = await api.get('states');
        this.states = data.data;
      } catch (e) {
        this.$toasted.error('There was an error while loading the list of states');
      } finally {
        this.loadingStates = false;
      }
    },
    initAddress(data) {
      return {
        address1: data?.address1 || '',
        address2: data?.address2 || '',
        country: data?.country || '',
        state: data?.state || '',
        city: data?.city || '',
        zip: data?.zip || '',
      };
    },
  },
};
</script>
