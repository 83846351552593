




import Vue from 'vue';
import Processors from '@/common/components/support/Processors.vue';

export default Vue.extend({
  components: {
    Processors,
  },
});
