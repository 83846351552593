import env from '@/environmentSetup';
import Permissions from './permissions';

export default [
  {
    route: 'admin.dashboard',
    icon: () => import('@/components/icons/i-tachometer.vue'),
    text: 'Dashboard',
  },
  {
    route: 'admin.isos.index',
    icon: () => import('@/components/icons/i-store.vue'),
    text: 'Affiliates',
  },
  {
    route: 'admin.merchants.index',
    icon: () => import('@/components/icons/i-list.vue'),
    text: 'Merchants',
  },
  {
    route: 'admin.buy-rates',
    icon: () => import('@/components/icons/i-buy-rates.vue'),
    text: 'Buy Rates',
    permission: Permissions.EDIT_DEFAULT_BUY_RATES,
  },
  {
    route: 'admin.reports.commission',
    icon: () => import('@/components/icons/i-chart.vue'),
    text: 'Reports',
  },
  {
    href: env('VUE_APP_API_DOCS_BASE_URL'),
    icon: () => import('@/components/icons/i-api.vue'),
    text: 'API Docs',
  },
];
