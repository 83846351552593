












































































































































































































import Vue from 'vue';
import api from '@/common/api';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import {
  AvsSetting,
  AvsSettingCategory,
  AvsSettingResponse,
} from '@/common/components/support/support.types';

export default Vue.extend({
  data() {
    return {
      AvsSettingCategory,
      AvsSettingResponse,
      isSubmitting: false as boolean,
      avsSettings: [] as AvsSetting[],
    };
  },
  mounted() {
    this.loadAvsSettings();
  },
  methods: {
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    loadAvsSettings() {
      if (this.$refs.loader) {
        (this.$refs.loader as any).setLoading();
      }
      api.get('avs-settings').then((response) => {
        this.avsSettings = response.data.data;
        if (this.$refs.loader) {
          (this.$refs.loader as any).setReady();
        }
      }).catch(this.handleLoadingError);
    },
    handleLoadingError() {
      this.$toasted.error('AVS Settings not found');
    },
    displayedSettings(avsSettings: AvsSetting[], category:AvsSettingCategory) {
      return avsSettings.filter((field) => field.avsCategory === category);
    },
    save() {
      this.submitActions(true);
      api
        .put('/avs-settings', { avsSettings: this.avsSettings })
        .then(() => {
          this.showSuccess();
        })
        .catch(this.handleSaveError);
    },
    showSuccess() {
      this.submitActions(false);
      this.$toasted.success('AVS Settings updated successfully!');
    },
    handleSaveError({ response }: { response: any }) {
      this.submitActions(false);
      if (response.status === 422) {
        let msg: any = '';
        if (response?.data?.errors) {
          const errors = response?.data?.errors;
          Object.keys(errors).forEach((key: any, idx: any) => {
            if (idx > 0) msg += '<br />';
            msg += `${errors[key]}`;
          });
        } else if (response?.data?.message) {
          msg += response?.data?.message;
        }
        if (msg) this.$toasted.error(msg);
        else this.$toasted.error(`There was a problem saving the AVS Settings. (${response.status})`);
      } else {
        this.$toasted.error(`There was a problem saving the AVS Settings. (${response.status})`);
      }
    },
  },
  components: {
    PageLoader,
    LoadingModal,
  },
});
