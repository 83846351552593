var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"report-page-title"},[_vm._v("Reports")]),_c('reports-pages-tabs'),_c('EntitiesTable',{attrs:{"config":_vm.config,"showActions":"","showViewButton":"","showSearch":""},scopedSlots:_vm._u([{key:"item.lastRun",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (item.lastRun ? _vm.$options.filters.dateShort(item.lastRun) : 'N/A')))+" ")]}},{key:"item.nextRun",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (item.nextRun ? _vm.$options.filters.dateShort(item.nextRun) : 'N/A')))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.status == 'canceled' ? 'suspended' : item.status))+" ")]}},{key:"item.lastRunStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.lastRunStatus))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm._f("dollarsAndCents")(item.amount))+" ")]}},{key:"item.paymentMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentMethod ? item.paymentMethod.type.toString().toUpperCase() : 'Payment method was deleted')+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.billingAddress.company || ("" + (((item.customer.billingAddress.firstName || '') + " " + (item.customer.billingAddress.lastName || '')).trim())))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }