export enum Feature {
  HPP = 'hpp',
  CUSTOM_FIELDS = 'custom_fields',
  SURCHARGING = 'surcharging',
  AUTO_LOGOUT = 'auto_logout',
  INFO_FIELDS = 'info_fields',
  SHOPPING_CARTS = 'shopping_carts',
  FIRSTDATA = 'firstdata',
  LEVEL23 = 'level23',
  TSYS = 'tsys',
  INVOICING = 'invoicing',
  OMAHA = 'omaha',
  NON_CASH_ADJUSTMENT = 'non_cash_adjustment',
}

export interface FeatureToggle {
  feature: Feature;
  enabled: boolean;
}
