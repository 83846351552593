



















































import Vue from 'vue';
import { mapState } from 'vuex';
import api, { setHeader } from '@/common/api';
import { generatePaymentMethodTableData } from '@/common/util/payments.util';
import { Transaction } from '@/common/types/transactions.types';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import TransactionDetails from '@/merchant/views/transactions/components/TransactionDetails.vue';
import AdminReportsPagesTabs from '@/admin/components/AdminReportsPagesTabs.vue';
import ReportsTabs from '../../components/reports-tabs.vue';
import TRANSACTION_REPORT_TABLE_FILTERS from './table-filters';
import TRANSACTION_REPORT_TABLE_HEADERS from './table-headers';

export default Vue.extend({
  components: {
    EntitiesTable,
    TransactionDetails,
    ReportsTabs,
    AdminReportsPagesTabs,
  },
  data() {
    return {
      selectedTransactionId: '' as string,
      selectedTransactionMerchant: '' as string,
      drawer: false,
      generatePaymentMethodTableData,
      config: {
        pageTitle: 'Transaction Detail Report',
        routerBase: 'merchant.transactions',
        apiPath: 'transactions',
        entityName: 'transaction',
        identifierProperty: 'id',
        reportFileName: 'transaction_details_report',
        filters: TRANSACTION_REPORT_TABLE_FILTERS,
        headers: TRANSACTION_REPORT_TABLE_HEADERS,
        footer: {
          header: 'Totals',
          displayHeaders: ['amount'],
          displayFunction(filters: any, entities: any[], header: string) {
            if (header === 'amount') {
              let totalAmount = 0;
              entities.forEach((item) => {
                if (item.refundedAmount !== 'undefined' && typeof item.refundedAmount !== 'undefined') {
                  totalAmount -= item.refundedAmount;
                } else if (item.status !== 'declined' && item.status !== 'voided') {
                  totalAmount += item.amount;
                }
              });

              const formatted = filters.dollarsAndCents(
                totalAmount,
              );
              return `$${formatted}`;
            }
            return '';
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['context']),
  },
  created() {
    if (this.context === 'admin') {
      setHeader('x-iso-proxy', 'aurora');
    }

    this.fetchMerchantsList();
  },
  destroyed() {
    if (this.context === 'admin') {
      setHeader('x-iso-proxy', undefined);
    }
  },
  methods: {
    fetchMerchantsList() {
      api.get('/merchants').then(({ data }) => {
        this.config.filters[4].items = data.data.map((item: any) => ({ display: item.company, value: item.id }));
      });
    },
    viewTransaction(transaction: Transaction) {
      this.selectedTransactionId = transaction.id!;
      this.selectedTransactionMerchant = transaction.merchant_subdomain!;
      this.drawer = !this.drawer;
    },
    getAmount(transaction: Transaction) {
      if (transaction.refundedAmount && transaction.refundedAmount !== 0) {
        return `($${(this.$options.filters as any).dollarsAndCents(transaction.refundedAmount)})`;
      }

      return `$${(this.$options.filters as any).dollarsAndCents(transaction.amount)}`;
    },
    refundClass(transaction: Transaction) {
      return (transaction.refundedAmount && transaction.refundedAmount !== 0) ? 'red--text' : '';
    },
  },
  watch: {
    drawer() {
      if (!this.drawer) {
        this.selectedTransactionId = '';
        this.selectedTransactionMerchant = '';
      }
    },
  },
});
