


























import Vue from 'vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import { Feature } from '@/features.types';

export default Vue.extend({
  data() {
    return {
      Feature,
      meta: {} as any,
      config: {
        pageTitle: 'Shopping Carts',
        apiPath: 'iso/shopping-carts',
        header: 'Shopping Carts',
        reportFileName: 'shopping_carts_report',
        headers: [
          {
            text: 'Store Name',
            value: 'storeName',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black black--text',
          },
          {
            text: 'Domain',
            value: 'domain',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black black--text',
          },
          {
            text: 'Merchant',
            value: 'merchantCompany',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black black--text',
          },
          {
            text: 'Monthly Fee',
            value: 'monthlyFee',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black black--text',
            align: 'center',
          },
          {
            text: 'Next Billing Date',
            value: 'nextBillingDate',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black black--text',
            align: 'center',
          },
          {
            text: 'Last Month Volume',
            value: 'lastMonthVolume',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black black--text',
            align: 'center',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-black black--text',
          },
        ],
        filters: [],
      },
    };
  },
  components: {
    EntitiesTable,
  },
});

