












































































import Vue from 'vue';
import api from '@/common/api';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import DeleteModal from '@/common/components/modals/DeleteModal.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import IpWhitelistNew from './IpWhitelistNew.vue';
import IpWhitelistEdit from './IpWhitelistEdit.vue';
import { IpWhitelist } from './support.types';

export default Vue.extend({
  data() {
    return {
      restrictIpSetting: false as boolean,
      restrictIpSettingLoaded: false as boolean,
      meta: {} as any,
      ipWhitelistToEdit: {} as object,
      reLoadData: false,
      entitySelectedForDeletion: {} as any,
      deleteModal: false,
      isDeleting: false,
      config: {
        pageTitle: 'Ip Whitelists',
        apiPath: 'ip-whitelists',
        header: 'Ip Whitelists',
        reportFileName: 'ip_whitelists',
        identifierProperty: 'name',
        entityName: 'Ip Whitelist',
        headers: [
          {
            text: 'id',
            value: 'id',
            sortable: false,
            align: ' d-none',
          },
          {
            text: 'Name',
            value: 'name',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black',
          },
          {
            text: 'Ip Address',
            value: 'ipAddress',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-black',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-black',
          },
        ],
        filters: [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      api
        .get('/ip-settings')
        .then(({ data }) => {
          this.restrictIpSetting = !!data.data.restrictIpSetting;
          this.restrictIpSettingLoaded = true;
        })
        .catch(() => {
          this.restrictIpSetting = false;
        });
    },
    changeIpRestrictionSetting() {
      api
        .put('/ip-settings', { restrictIpSetting: this.restrictIpSetting })
        .then(() => {
          this.$toasted.success('IP Restriction Setting updated successfully!');
        })
        .catch(({ response }) => {
          this.$toasted.error(response.data.message);
        });
    },
    openNewIpWhitelistModal() {
      (this.$refs.newModal as any).show();
    },
    getIpWhitelistData() {
      this.reLoadData = true;
      this.ipWhitelistToEdit = {};
    },
    editIpWhitelistSetting(ipWhitelist: IpWhitelist) {
      this.ipWhitelistToEdit = ipWhitelist;
      (this.$refs.editModal as any).show();
    },
    confirmDeleteEntity(entity: any) {
      this.entitySelectedForDeletion = entity;
      this.deleteModal = true;
    },
    deleteEntity() {
      this.isDeleting = true;
      this.deleteModal = false;
      api
        .delete(`${this.config.apiPath}/${this.entitySelectedForDeletion.id}`)
        .then(() => {
          this.getIpWhitelistData();
        })
        .catch(({ response }) => {
          const message = response?.data?.message || 'Failed to delete. Try again.';
          this.$toasted.error(`${message} (${response.status})`);
        })
        .finally(() => {
          this.deleteModal = false;
          this.isDeleting = false;
        });
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    updateReloadData() {
      this.reLoadData = false;
    },
  },
  components: {
    EntitiesTable,
    IpWhitelistNew,
    IpWhitelistEdit,
    DeleteModal,
    LoadingModal,
  },
});

