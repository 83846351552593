import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/common/routes';
import store from '@/common/store';
import { PAY_HPP_ROUTE_NAME, PAY_INVOICE_ROUTE_NAME } from '@/payments/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const publicRoutes = [
  'login', 'login-jwt', 'oauth-redirect', 'password-reset', 'confirm-password-reset',
];

const unauthenticatedRoutes = [
  PAY_HPP_ROUTE_NAME,
  PAY_INVOICE_ROUTE_NAME,
];

router.beforeEach((to, from, next) => {
  const promisesBeforePageRender = [
    store.dispatch('getContext'),
    store.dispatch('authenticate'),
    // Some components require data from branding
    store.dispatch('fetchBranding'),
  ];

  const isUnauthenticatedRoute = unauthenticatedRoutes.includes(to.name);
  if (isUnauthenticatedRoute) {
    Promise.all(promisesBeforePageRender).finally(() => {
      next();
    });
    return;
  }

  const requiresAuth = !publicRoutes.includes(to.name);

  Promise.all(promisesBeforePageRender)
    .then(async ([context, authenticated]) => {
      const routeContext = to.name?.split('.')[0];
      const isValidContext = ['admin', 'iso', 'merchant'].includes(routeContext);
      const contextsMatch = routeContext === context;
      const wrongContext = isValidContext && !contextsMatch;

      if (wrongContext) {
        const redirect = { name: to.name.replace(routeContext, context) };
        if (router.resolve(redirect).route.matched.length === 0) {
          redirect.name = `${context}.dashboard`;
        }
        next(redirect);
        return;
      }

      const redirectGuest = !authenticated.status && requiresAuth;
      const redirectUser = authenticated.status && !requiresAuth;

      if (to.name === 'logout') {
        /**
         * Although logout should be only for authenticated users. There's a case
         * in which users are authenticated against FusionAuth but no RiseOS and they
         * need to be logged out.
         */
        next();
      } else if (redirectGuest) {
        store.commit('setRedirect', to.path);
        next({ name: 'login' });
      } else if (redirectUser) {
        next({ name: `${context}.dashboard` });
      } else if (to.meta?.permission) {
        try {
          if (!store.state.currentUser) await store.dispatch('loadCurrentUser');

          next(store.getters.currentUserCan(to.meta.permission));
        } catch (e) {
          next(false);
        }
      } else {
        next();
      }
    }).catch(console.error);
});

export default router;
