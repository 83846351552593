<template>
  <div>
    <a href @click.prevent="triggerInput" class="tw-flex tw-justify-left">
      <div class="tw-flex tw-flex-col tw-items-center tw-text-gray-600">
        <Icon icon="file-upload" size="3x" />
        <p class="tw-text-center">Receipt Logo</p>
      </div>
      <img :src="value" v-if="value" class="tw-max-w-sm tw-mx-auto" />
    </a>
    <input type="file" accept="image/*" ref="fileInput" style="display: none" @change="readLogo" />
    <div v-if="error != ''" class="tw-text-red-400">
      {{error}}
    </div>
  </div>
</template>

<script>
import Icon from '@/common/icon';

export default {
  props: {
    value: {
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      error: '',
    };
  },
  methods: {
    triggerInput() {
      if (this.isEditing) {
        this.$refs.fileInput.click();
      }
    },
    readLogo(evt) {
      this.error = '';
      const input = evt.target;
      if (input.files && input.files[0]) {
        if (!['image/gif', 'image/jpeg', 'image/png', 'image/webp'].includes(input.files[0].type)) {
          this.error = 'Invalid image. Only GIF, JPG, PNG, and WEBP image types are allowed';
          return;
        }

        if (Math.round(input.files[0].size / 1024) > 500) {
          this.error = 'Maximum image size allowed is 500kb';
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.$emit('updateLogo', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
  },
  components: {
    Icon,
  },
};
</script>
