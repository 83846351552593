















































































































































import Vue from 'vue';
import draggable from 'vuedraggable';
import {
  VBtn, VChip, VDatePicker, VImg, VSelect, VTextField, VCheckbox,
} from 'vuetify/lib';
import { HppColumn, HppField, initHppStates } from '@/common/util/hostedPaymentPages.util';
import { CustomFieldType } from '@/common/components/support/support.types';
import HppBlockquote from './HppBlockquote.vue';
import HppCardForm from './HppCardForm.vue';
import HppEnableButton from './HppEnableButton.vue';
import HppHeading from './HppHeading.vue';
import HppLineItems from './HppLineItems.vue';
import HppList from './HppList.vue';
import HppParagraph from './HppParagraph.vue';
import LogoPicker from '../../../../components/atoms/a-logo-picker.vue';
import { Plan } from '../../plans/plans.types';

export default Vue.extend({
  data() {
    return {
      CustomFieldType,
      hppStatesData: initHppStates(),
    };
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixedAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    hasCreditCardProcessor: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasAchProcessor: {
      type: Boolean,
      required: false,
      default: false,
    },
    surchargePercent: {
      type: Number,
      required: false,
    },
    surchargeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    adjustmentPercent: {
      type: Number,
      required: false,
    },
    adjustmentDollarAmount: {
      type: Number,
      required: false,
    },
    adjustmentName: {
      type: String,
      required: false,
    },
    nonCashAdjustmentEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAdjustment: {
      type: Boolean,
      required: false,
      default: false,
    },
    plan: {
      type: Object as () => Plan,
      required: false,
    },
    isRecurring: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasProducts: {
      type: Boolean,
      required: false,
      default: false,
    },
    showShipingFields: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    removeField(fieldId: string) {
      this.$emit('removeField', fieldId);
    },
    displayedFields(column: HppColumn): HppField[] {
      return column.fields.filter((field) => !field.hidden);
    },
    toggle(fieldName: string, fieldLabel: string) {
      if (fieldName === 'shippingEnabled') {
        this.toggleShipping(fieldLabel);
      }
    },
    toggleShipping(fieldLabel: string) {
      this.$emit('toggleShipping', fieldLabel);
    },
    robotCheck(robot: boolean) {
      this.$emit('robotCheck', robot);
    },
    getClass(columnName: string, fieldName: string) {
      if (columnName === 'shippingAddress'
          && !['shippingHeading', 'shippingEnabled'].includes(fieldName)
          && !this.showShipingFields
      ) {
        return 'd-none';
      }
      return '';
    },
    getFieldAttributes(field: any) {
      const itemField = field;
      if (itemField.name === 'state') itemField.attributes.items = this.hppStatesData;

      return itemField.attributes;
    },
    checkMove(evt: any) {
      if (evt.draggedContext.element.customField === evt.relatedContext.element.customField) {
        return true;
      }
      return false;
    },
  },
  components: {
    draggable,
    HppBlockquote,
    HppCardForm,
    HppLineItems,
    HppList,
    HppHeading,
    HppParagraph,
    HppEnableButton,
    VBtn,
    VChip,
    VDatePicker,
    VImg,
    VSelect,
    VTextField,
    VCheckbox,
    LogoPicker,
  },
});
