import { PaymentPage, PaymentPageStatus } from './paymentPages.types';

// eslint-disable-next-line import/prefer-default-export
export function isActive(paymentPage: PaymentPage | null): boolean {
  switch (paymentPage?.pageStatus) {
    case PaymentPageStatus.ACTIVE:
      return true;
    case PaymentPageStatus.ACTIVE_PENDING:
      return true;
    default:
      return false;
  }
}
