<template>
  <v-select
    name="state"
    label="State"
    v-model="contact.state"
    :items="states"
    outlined
    item-text="name"
    item-value="abbreviation"
    @change="changeState"
    dense
    :rules="[isoEditOrMerchant ? (v) => !!v || 'State is required.' : true]"
    :prefix="isoEditOrMerchant ? '*' : ''"
  />
</template>

<script>
import api from '@/common/api';

export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    isoEditOrMerchant: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      state: this.value,
      states: [],
    };
  },
  computed: {
    inputId() {
      return this.id || this.name;
    },
  },
  mounted() {
    api.get('states').then(({ data: { data: states } }) => {
      this.states = states;
    });
  },
  methods: {
    changeState() {
      this.$emit('changeState', this.contact.state);
    },
  },
};
</script>
