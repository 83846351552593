

































































import Vue from 'vue';
import { Processor, ProcessorType } from '@/common/types/processors.types';
import { initProcessor } from '@/common/util/processors.util';
import LoadingModal from '@/common/components/LoadingModal.vue';
import api from '@/common/api';

export default Vue.extend({
  props: {
    merchantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: true as boolean,
      isSubmitting: false as boolean,
      processor: initProcessor(ProcessorType.TSYS) as Processor,
      uidTsysRules: [
        (v: string) => !!v || 'User ID is required to generate the Transaction Key',
        (v: string) => /^[a-zA-Z0-9]{3,128}$/.test(v) || 'User ID has Alphanumeric with Min Length = 3 and Max Length = 128',
      ],
      didTsysRules: [
        (v: string) => !!v || 'Device ID is required',
        (v: string) => /^[a-zA-Z0-9]{14,15}$/.test(v) || 'Device ID has to be Numeric with Min Length = 14 and Max Length = 15',
      ],
      midTsysRules: [
        (v: string) => !!v || 'Merchant ID is required',
        (v: string) => /^[a-zA-Z0-9]{12,12}$/.test(v) || 'Merchant ID has to be Numeric with Length = 15',
      ],
      pwTsysRules: [
        (v: string) => !!v || 'Tsys Password is required to generate the Transaction Key',
      ],
    };
  },
  methods: {
    isValid(): boolean {
      return (this.$refs.tsysNewForm as any).validate();
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.processor = initProcessor();
    },
    submit() {
      if (!this.isValid()) {
        return;
      }
      this.submitActions(true);
      api
        .post(`merchants/${this.merchantId}/processors`, {
          key: this.processor.key,
          name: this.processor.name,
          config: {
            ...this.processor.config,
          },
        })
        .then(() => {
          this.$toasted.success('Success');
          this.reset();
          this.$emit('created');
        })
        .catch(({ response }) => {
          const errorMessages: string[] = [];
          if (typeof response.data.message !== 'undefined') {
            errorMessages.push(response.data.message);
          } else if (response.data?.errors && typeof (response.data.errors === 'object')) {
            Object.keys(response.data.errors)
              .forEach((key) => errorMessages.push(response.data.errors[key]));
          }
          if (errorMessages.length) {
            this.$toasted.error(errorMessages.join(', '));
          } else {
            this.$toasted.error(response.data.message);
          }
        })
        .finally(() => this.submitActions(false));
    },
  },
  components: { LoadingModal },
});
