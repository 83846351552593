




































































import Vue from 'vue';
import ProductCombobox from '../products/ProductCombobox.vue';
import { LineItemInterface, LineItemProduct } from './lineItems.types';
import { measurementUnits } from '../products/products.types';

export default Vue.extend({
  data() {
    return {
      measurementUnits,
    };
  },
  props: {
    id: {
      required: true,
    },
    lineItem: {
      type: Object as () => LineItemInterface,
      required: true,
    },
  },
  watch: {
    lineTotal: {
      immediate: true,
      handler() {
        this.lineItem.lineTotal = this.lineTotal * 100;
        this.$emit('addFilledLineItem', this.lineItem, this.id);
      },
    },
  },
  computed: {
    lineTotal(): any {
      return ((this.lineItem.rate * this.lineItem.quantity)).toFixed(2);
    },
  },
  components: {
    ProductCombobox,
  },
  methods: {
    handleProduct(product: LineItemProduct) {
      this.lineItem.name = product.name;

      if (product.rate) {
        const formattedLineItemRate = ((product.rate) / 100);
        this.lineItem.rate = formattedLineItemRate.toFixed(2);
        this.lineItem.description = product.description;
        this.lineItem.measurementUnit = product.measurementUnit;
      }

      this.$emit('addFilledLineItem', this.lineItem, this.id);
    },
  },
});

