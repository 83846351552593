import { IsoStatus } from '@/tenants/admin/modules/affiliates/types/affiliate';
import {
  MerchantStatus,
} from '@/iso/views/merchants/merchants.types';
import api, {
  setHeader,
} from '@/common/api';
import auth from '@/common/auth';
import fetchBranding from '@/common/branding';
import getSubdomain from '@/common/context';

let authPromise = null;
const startAuth = ({
  commit,
  dispatch,
}) => {
  authPromise = new Promise((resolve) => {
    auth
      .get('/auth')
      .then(() => {
        commit('completeAuthFlow', true);
        dispatch('loadCurrentUser');
        dispatch('loadFeatures');
        dispatch('loadAvailableProcessors');
        resolve({ status: true });
      })
      .catch((e) => {
        commit('clearAccessToken');
        resolve({ status: false, error: e });
      });
  });

  return authPromise;
};

const setProxyHeader = (context, role) => {
  const allowedContext = role === 'admin' ? ['iso', 'merchant'] : ['merchant'];

  if (allowedContext.includes(context)) {
    setHeader(`x-${context}-proxy`, getSubdomain());
  }
};

const redirectDisallowedContext = (context, user) => {
  const redirect = `${user.subdomain}${window.location.pathname}`;
  if (user.role === 'iso' && context === 'admin') {
    window.location.href = redirect;
  } else if (user.role === 'merchant' && context !== 'merchant') {
    window.location.href = redirect;
  }
};

const redirectOnboardingIso = async (user) => {
  if (user.role !== 'iso') return;

  try {
    const { data } = await api.get('iso');
    const iso = data.data;
    const currentPath = window.location.pathname;

    if (iso.status === IsoStatus.CREATED && !currentPath.includes('/onboarding/proposal')) {
      window.location = `/onboarding/proposal/${iso.proposals[0].uuid}`;
    } else if (iso.status === IsoStatus.SIGNED && currentPath !== '/onboarding/setup') {
      window.location = '/onboarding/setup';
    }
  } catch (e) {
    console.error('Information for ISO could not be obtained.');
  }
};

const redirectPendingMerchant = (context, user) => {
  let redirect = user.subdomain;
  const currentPath = window.location.pathname;
  if (context === 'merchant' && user.id && user.role === 'merchant') {
    api.get('merchant').then(({
      data,
    }) => {
      const merchant = data.data;
      const merchantStatus = merchant.status;
      if (
        merchantStatus === MerchantStatus.PENDING
        && currentPath !== '/onboarding/proposal'
      ) {
        redirect += '/onboarding/proposal';
        window.location.href = redirect;
      }
    });
  }
};

export default {
  async getContext({ state, commit }) {
    if (state.context) return state.context;
    if (getSubdomain() === 'admin') {
      commit('setContext', 'admin');
      return 'admin';
    }

    const response = await api.get('context', { skipAuthentication: true });
    commit('setContext', response.data);
    return response.data;
  },
  async fetchBranding({ state, commit }) {
    if (state.branding) return state.branding;
    const branding = await fetchBranding(getSubdomain());

    commit('setBranding', branding);
    commit('setBrandName', branding.name);
    commit('setBrandCompany', branding.company);
    return branding;
  },
  authenticate(context) {
    return authPromise === null ? startAuth(context) : authPromise;
  },
  clearAuthPromise({ commit }) {
    authPromise = null;
    commit('clearAccessToken');
  },
  loadAvailableProcessors({ commit }) {
    return api.get('available-processors').then(({
      data,
    }) => {
      commit('setAvailableProcessors', data.data);
    });
  },
  loadCurrentUser({ commit, dispatch }) {
    return Promise.all([
      dispatch('getContext'),
      api.get('user', { onlyToken: true }).then(({
        data: {
          data: user,
        },
      }) => user),
    ]).then(([context, user]) => {
      setProxyHeader(context, user.role);

      redirectDisallowedContext(context, user);
      if (context === 'iso') {
        redirectOnboardingIso(user);
      } else if (context === 'merchant') {
        redirectPendingMerchant(context, user);
      }
      commit('setCurrentUser', user);
    });
  },
  loadMerchantProcessors({ commit }, context) {
    if (context === 'merchant') {
      return api.get('processors').then(({
        data,
      }) => {
        commit('setProcessors', data.data);
      });
    }
    commit('setProcessors', []);
    return Promise.resolve([]);
  },
  loadFeatures({ commit }) {
    return api.get(`features/${getSubdomain()}`).then(({
      data,
    }) => {
      commit('setFeatures', data.data.features);
    });
  },
};
