export function toFloat(rates) {
  return rates.map((rate) => {
    const value = (rate.value / 100).toFixed(2);
    const buyRate = typeof rate.buyRate !== 'undefined' ? (rate.buyRate / 100).toFixed(2) : value;

    return { ...rate, buyRate, value };
  });
}

export function toInt(rates) {
  return rates.map(
    (rate) => ({ ...rate, value: Math.round(rate.value * 100) }),
  );
}
