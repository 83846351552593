<template>
  <div class="card">
    <h3>{{ title }}</h3>

    <ul>
      <li v-for="item in items" :key="item.label" :class="item.label">
        <div>
          <component :is="icons[item.label]" />
          <span>{{ item.label }}</span>
        </div>
        <strong>{{ item.value }}</strong>
      </li>
    </ul>
  </div>
</template>

<script>
import ICheckCircle from '@/components/icons/i-check-circle.vue';
import IconClosed from './icons/icon-closed.vue';
import IconPending from './icons/icon-pending.vue';
import IconRestricted from './icons/icon-restricted.vue';

export default {
  props: {
    title: { type: String, required: true },
    active: { type: Number, required: true },
    restricted: { type: Number, required: true },
    pending: { type: Number, required: true },
    closed: { type: Number, required: true },
  },
  data() {
    return {
      icons: {
        Active: ICheckCircle,
        Pending: IconPending,
        Restricted: IconRestricted,
        Closed: IconClosed,
      },
    };
  },
  computed: {
    items() {
      return [
        { label: 'Active', value: this.active },
        { label: 'Restricted', value: this.restricted },
        { label: 'Pending', value: this.pending },
        { label: 'Closed', value: this.closed },
      ];
    },
  },
};
</script>

<style scoped>
.card {
  --card-padding: 2rem 1.5rem 2.5rem 2.5rem;
}

h3 {
  font-size: 1.125rem;
  font-weight: 700;
  margin-block-end: 1.7em;
}

ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  column-gap: max(1rem, 3vmax);
  row-gap: 1rem;
}

li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

li>div {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

li.Active {
  color: #00B5A5;
}

li.Restricted {
  color: #F7935A;
}

li.Pending {
  color: var(--card-text-color);
}

li.Closed {
  color: #FF200E;
}
</style>
