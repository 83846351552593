





























































































































import Vue from 'vue';
import { InvoiceInformationInterface, MerchantInfo, formatDate } from './invoices.types';

export default Vue.extend({
  data() {
    return {
      openDueDatePicker: false,
      dueDate: '',
      dueDateFormatted: '',
    };
  },
  props: {
    merchantInfo: {
      type: Object as () => MerchantInfo,
      required: true,
    },
    address: Object,
    total: {
      type: Number,
      required: true,
    },
    invoiceInformation: {
      type: Object as () => InvoiceInformationInterface,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    dueDate() {
      this.invoiceInformation.dueDateFormatted = this.formatDate(this.dueDate);
      this.invoiceInformation.dueDate = this.dueDate;
    },
  },
  computed: {
    mobileSizeAndBelow(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    issueDateFormatted(): string {
      return this.edit ? this.invoiceInformation.issueDate!
        : this.newFormattedIssueDate;
    },
    newFormattedIssueDate(): string {
      const today = new Date();
      return `${`${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`}`;
    },
  },
  methods: {
    formatDate,
  },
});

