















































































































import Vue from 'vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import slugify from 'slugify';
import api from '@/common/api';
import { BasicProcessor, Processor } from '@/common/types/processors.types';
import { initValidationErrors, ValidationErrors } from '@/common/validation/validation.types';
import { PaymentType } from '@/common/types/payments.types';
import { initDefaultHppConfig } from '@/common/util/hostedPaymentPages.util';
import { HostedPaymentPageTypes } from '@/common/types/hostedPaymentPages.types';
import { InfoField } from '@/common/components/support/support.types';
import ProcessorSelector from '../components/hosted-payments/ProcessorSelector.vue';
import HostedPaymentAmount from '../components/hosted-payments/HostedPaymentAmount.vue';
import { Plan } from '../plans/plans.types';

export default Vue.extend({
  props: {
    hppItemToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PaymentType,
      HostedPaymentPageTypes,
      hppWriteModel: {
        name: '',
        description: '',
        redirect: null,
        achProcessorId: '',
        creditCardProcessorId: '',
        amount: 0,
        isFixedAmount: false,
        config: initDefaultHppConfig(),
        slug: '',
        isRecurring: false,
        hasProducts: false,
        planId: null,
        products: [] as string[],
      },
      hppItemId: '' as string,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      isLoading: false as boolean,
      availableProcessors: [] as BasicProcessor[],
      availablePlans: [] as Plan[],
      selectedProcessor: null as Processor | null,
      validationErrors: initValidationErrors() as ValidationErrors,
      chosenHppType: '' as string,
      productsSearch: '' as string,
      productsSearching: false as boolean,
      productsSearchList: [],
      productsList: [] as any[],
      hppPaymentTypes: [
        {
          text: HostedPaymentPageTypes.STANDARD,
          value: HostedPaymentPageTypes.STANDARD,
        },
        {
          text: HostedPaymentPageTypes.RECURRING,
          value: HostedPaymentPageTypes.RECURRING,
        },
        {
          text: HostedPaymentPageTypes.FIXED_AMOUNT,
          value: HostedPaymentPageTypes.FIXED_AMOUNT,
        },
        {
          text: HostedPaymentPageTypes.PRODUCTS,
          value: HostedPaymentPageTypes.PRODUCTS,
        },
      ],
      infoFields: [] as any[],
      billingFields: [] as any[],
      billingRequiredFields: [] as any[],
      shippingFields: [] as any[],
      shippingRequiredFields: [] as any[],
    };
  },
  watch: {
    productsSearch: 'searchProducts',
    chosenHppType(type: HostedPaymentPageTypes) {
      if (type !== HostedPaymentPageTypes.PRODUCTS) {
        this.productsList = [];
      }
    },
    hppItemToEdit() {
      this.hppItemId = this?.hppItemToEdit.id;
    },
    hppItemId() {
      this.loadHppData();
    },
  },
  mounted() {
    this.loadAvailableProcessors();
    this.loadAvailablePlans();
  },
  methods: {
    async searchProducts(query: string, omitCheck: boolean) {
      if (!query && !omitCheck) return;
      this.productsSearching = true;

      try {
        const { data } = await api.get('/merchant/products', { params: { q: query } });
        this.productsSearchList = data.data.map((x: any) => ({ text: x.name, value: x.id }));
      } catch (e) {
        this.$toasted.error('There was an error while searching for products');
      } finally {
        this.productsSearching = false;
      }
    },
    getFieldName(name: string, removeName: string) {
      const fieldName = name.replace(removeName, '').replace(/ /g, '');
      return (fieldName.charAt(0).toLowerCase() + fieldName.slice(1));
    },
    isVisible(fieldName: string, fieldType: string) {
      return ((fieldType === 'Billing' && this.billingFields.includes(fieldName))
        || (fieldType === 'Shipping' && this.shippingFields.includes(fieldName)));
    },
    isRequired(fieldName: string, fieldType: string) {
      return ((fieldType === 'Billing' && this.billingRequiredFields.includes(fieldName))
        || (fieldType === 'Shipping' && this.shippingRequiredFields.includes(fieldName)));
    },
    setCustomerInfoFields() {
      Object.keys(this.infoFields).forEach((key: any) => {
        const field = this.infoFields[key] as InfoField;
        const fieldName = this.getFieldName(field.name, field.category);
        if (field.category === 'Billing') {
          this.billingFields.push(fieldName);
          if (field.required) {
            this.billingRequiredFields.push(fieldName);
          }
        } else if (field.category === 'Shipping') {
          this.shippingFields.push(fieldName);
          if (field.required) {
            this.shippingRequiredFields.push(fieldName);
          }
        }
      });
    },
    loadHppData() {
      if (this?.hppItemId === undefined || this?.hppItemId === '') return;

      api
        .get(`/hosted-payments/${this.hppItemId}`)
        .then(({ data: { data } }) => {
          this.hppWriteModel.name = data.name;
          this.hppWriteModel.description = data.description;
          this.hppWriteModel.redirect = data.redirect;
          this.hppWriteModel.achProcessorId = 'ach' in data?.processors ? data?.processors?.ach.id : null;
          this.hppWriteModel.creditCardProcessorId = 'credit' in data?.processors ? data?.processors?.credit.id : null;
          this.hppWriteModel.amount = data?.amount;
          this.hppWriteModel.isFixedAmount = data?.isFixedAmount;
          this.hppWriteModel.slug = data?.slug;
          this.hppWriteModel.config = data?.config;
          this.hppWriteModel.isRecurring = data?.isRecurring;
          this.hppWriteModel.hasProducts = data?.hasProducts;
          this.hppWriteModel.products = data?.products;
          this.hppWriteModel.planId = data?.plan?.uuid;
          this.isLoading = false;
          this.showModal = true;
          this.setChosenHppTypeRead();
          this.infoFields = data.infoFields;
          this.setCustomerInfoFields();

          if (this.hppWriteModel.products) {
            this.productsList = this.hppWriteModel.products;

            // This will get all products regarding their name.
            // We need this to populate the v-autocomplete with the
            // product names
            this.searchProducts('', true);
          }
        })
        .catch(() => {
          const message = this.$errorMessages.get('hosted_payment_pages.load');
          this.$toasted.error(message);
        });
    },
    loadAvailableProcessors() {
      if (this.showModal) {
        this.submitActions(true);
      }
      api
        .get('processors')
        .then(this.setAvailableProcessors)
        .catch(this.handleError)
        .finally(() => this.submitActions(false));
    },
    loadAvailablePlans() {
      api
        .get('/plans')
        .then(this.setAvailablePlans);
    },
    sluggifyForSubdomain(value: string): any {
      this.hppWriteModel.slug = slugify(value, {
        lower: true,
        strict: true,
      });
    },
    handleError() {
      const message = this.$errorMessages.get('hosted_payment_pages.processors_load');
      this.$toasted.error(message);
    },
    setAvailableProcessors({ data: { data } }: { data: { data: BasicProcessor[] } }) {
      this.availableProcessors = data;
    },
    setAvailablePlans({ data: { data } }: { data: { data: any } }) {
      this.availablePlans = data;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    reset() {
      this.selectedProcessor = null;
      this.validationErrors = {};
    },
    show() {
      this.hppItemId = this?.hppItemToEdit.id;
      this.isLoading = true;
      this.showModal = false;
    },
    close() {
      this.showModal = false;
      this.submitActions(false);
      this.hppItemId = '';
      this.reset();
    },
    onCreated() {
      this.$emit('created');
      this.close();
    },
    saveHpp() {
      if (!this.isValid) {
        return;
      }
      this.submitActions(true);
      this.setChosenHppTypeWrite();

      const cols = this.hppWriteModel.config[0].columns;
      Object.keys(cols).forEach((key: any) => {
        const colArr = cols[key];
        if (colArr.name === 'billingAddress' || colArr.name === 'shippingAddress') {
          const fieldCat = colArr.name === 'billingAddress' ? 'Billing' : 'Shipping';
          Object.keys(colArr.fields).forEach((fieldKey: any) => {
            if (!['billingHeading', 'shippingHeading', 'shippingEnabled'].includes(colArr.fields[fieldKey].name)) {
              if (!this.isVisible(colArr.fields[fieldKey].name, fieldCat)) {
                this.hppWriteModel.config[0].columns[key].fields[fieldKey].hidden = false;
              } else {
                this.hppWriteModel.config[0].columns[key].fields[fieldKey].hidden = false;
              }

              if (this.isRequired(colArr.fields[fieldKey].name, fieldCat)) {
                this.hppWriteModel.config[0].columns[key].fields[fieldKey].required = true;
              } else {
                this.hppWriteModel.config[0].columns[key].fields[fieldKey].required = false;
              }
            }
          });
        }
      });

      this.hppWriteModel.products = this.productsList;

      api
        .put(`/hosted-payments/${this.hppItemId}`, this.hppWriteModel)
        .then(() => {
          this.close();
          this.$emit('created');
          (this.$refs.form as any).reset();
        })
        .catch(({ response }: { response: any }) => {
          this.submitActions(false);
          if (response.status === 422) {
            const errors = response?.data?.errors;
            let msg: any = '';
            Object.keys(errors).forEach((key: any, idx: any) => {
              if (idx > 0) msg += '<br />';
              msg += `${errors[key]}`;
            });
            this.$toasted.error(msg);
          } else {
            const message = this.$errorMessages.get('hosted_payment_pages.create');
            this.$toasted.error(`${message} (${response.status})`);
          }
          this.validationErrors = response.data.errors || {};
        });
    },
    setChosenHppTypeRead() {
      if (this.hppWriteModel.hasProducts) {
        this.chosenHppType = HostedPaymentPageTypes.PRODUCTS;
      } else if (this.hppWriteModel.isFixedAmount) {
        this.chosenHppType = HostedPaymentPageTypes.FIXED_AMOUNT;
      } else if (this.hppWriteModel.isRecurring) {
        this.chosenHppType = HostedPaymentPageTypes.RECURRING;
      } else if (!this.hppWriteModel.isRecurring && !this.hppWriteModel.isFixedAmount) {
        this.chosenHppType = HostedPaymentPageTypes.STANDARD;
      }
    },
    setChosenHppTypeWrite() {
      if (this.hppWriteModel.planId !== null) {
        this.hppWriteModel.isRecurring = true;
      }

      if (this.chosenHppType === HostedPaymentPageTypes.STANDARD) {
        this.hppWriteModel.isFixedAmount = false;
        this.hppWriteModel.isRecurring = false;
        this.hppWriteModel.hasProducts = false;
      } else if (this.chosenHppType === HostedPaymentPageTypes.FIXED_AMOUNT) {
        this.hppWriteModel.isRecurring = false;
        this.hppWriteModel.hasProducts = false;
        this.hppWriteModel.isFixedAmount = true;
      } else if (this.chosenHppType === HostedPaymentPageTypes.RECURRING) {
        this.hppWriteModel.isFixedAmount = false;
        this.hppWriteModel.hasProducts = false;
        this.hppWriteModel.isRecurring = true;
      } else if (this.chosenHppType === HostedPaymentPageTypes.PRODUCTS) {
        this.hppWriteModel.isFixedAmount = false;
        this.hppWriteModel.isRecurring = false;
        this.hppWriteModel.hasProducts = true;
      }
    },
    setFixedAmount(fixedAmount: number) {
      this.hppWriteModel.amount = fixedAmount;
    },
  },
  computed: {
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    hppUrl(): string {
      return `${window.location.origin}/pay/`;
    },
    planDescription(): string {
      if (this.hppWriteModel.planId) {
        const plan = this.availablePlans.find((p) => p.id === this.hppWriteModel.planId);
        return `$${plan?.amount! / 100} ${plan?.recurrenceText}`;
      }
      return '';
    },
  },
  components: {
    LoadingModal,
    ProcessorSelector,
    HostedPaymentAmount,
  },
});
