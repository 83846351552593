<template>
  <ul>
    <tab-item :to="{ name: 'merchant.products.index' }">
      Products
    </tab-item>
    <tab-item :to="{ name: 'merchant.categories.index' }">
      Categories
    </tab-item>
  </ul>
</template>

<script>
import TabItem from './tab-item.vue';

export default {
  components: { TabItem },
};
</script>

<style scoped>
ul {
  display: flex;
  gap: 0.25rem;
}
</style>
