












































































































































































import { mask } from 'vue-the-mask';
import Vue from 'vue';
import { CustomerAddress } from '@/common/types/customers.types';
import { FieldName } from '@/common/types/fields.types';
import api from '@/common/api';
import { InfoFieldFillable, InfoField } from '@/common/components/support/support.types';
import PageLoader from '@/common/components/PageLoader.vue';
import APhoneNumberForm from '@/components/atoms/a-phone-number-form.vue';

const fieldNameMap: Map<boolean, Map<string, string>> = new Map([
  [
    true,
    new Map([
      ['company', FieldName.BILLING_COMPANY],
      ['firstName', FieldName.BILLING_FIRST_NAME],
      ['lastName', FieldName.BILLING_LAST_NAME],
      ['email', FieldName.BILLING_EMAIL],
      ['address1', FieldName.BILLING_ADDRESS_1],
      ['address2', FieldName.BILLING_ADDRESS_2],
      ['city', FieldName.BILLING_CITY],
      ['state', FieldName.BILLING_STATE],
      ['zip', FieldName.BILLING_ZIP],
      ['country', FieldName.BILLING_COUNTRY],
      ['phone', FieldName.BILLING_PHONE],
      ['fax', FieldName.BILLING_FAX],
    ]),
  ],
  [
    false,
    new Map([
      ['company', FieldName.SHIPPING_COMPANY],
      ['firstName', FieldName.SHIPPING_FIRST_NAME],
      ['lastName', FieldName.SHIPPING_LAST_NAME],
      ['email', FieldName.SHIPPING_EMAIL],
      ['address1', FieldName.SHIPPING_ADDRESS_1],
      ['address2', FieldName.SHIPPING_ADDRESS_2],
      ['city', FieldName.SHIPPING_CITY],
      ['state', FieldName.SHIPPING_STATE],
      ['zip', FieldName.SHIPPING_ZIP],
      ['country', FieldName.SHIPPING_COUNTRY],
      ['phone', FieldName.SHIPPING_PHONE],
    ]),
  ],
]);

export default Vue.extend({
  props: {
    fax: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Object as () => CustomerAddress,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    validationName: {
      type: String,
      required: false,
      default: '',
    },
    billing: {
      type: Boolean,
      required: false,
      default: false,
    },
    fieldToggles: {
      type: Array,
      required: false,
      default: () => [],
    },
    isBusinessAch: {
      type: Boolean,
      required: false,
      default: false,
    },
    infoFields: {
      type: Array as () => InfoFieldFillable[],
      required: true,
    },
  },
  data() {
    return {
      states: [],
      countries: [],
      customerInfoFields: [] as InfoFieldFillable[],
      billingFields: [] as any[],
      shippingFields: [] as any[],
      billingRequiredFields: [] as any[],
      shippingRequiredFields: [] as any[],
    };
  },
  watch: {
    infoFields() {
      this.setCustomerInfoFields();
    },
  },
  mounted() {
    api.get('states').then(({ data: { data: states } }) => {
      this.states = states;
    });
    api.get('countries').then(({ data: { data: countries } }) => {
      this.countries = countries;
    });

    this.finishLoading();
  },
  methods: {
    uniqueId(suffix: string) {
      // eslint-disable-next-line no-underscore-dangle
      const uid: number = (this as any)._uid;
      return `${uid}-${suffix}`;
    },
    makeValidationName(name: string) {
      return this.validationName !== '' ? `${this.validationName}${name}` : name;
    },
    setCustomerInfoFields() {
      Object.keys(this.infoFields).forEach((key: any) => {
        const infoField = this.infoFields[key] as InfoField;
        const fieldName = this.getFieldName(infoField.name);
        if (infoField.category === 'Billing') {
          this.billingFields.push(fieldName);
          if (infoField.required) {
            this.billingRequiredFields.push(fieldName);
          }
        } else if (infoField.category === 'Shipping') {
          this.shippingFields.push(fieldName);
          if (infoField.required) {
            this.shippingRequiredFields.push(fieldName);
          }
        }
      });

      (this.$refs.loader as any).setReady();
    },
    getFieldName(name: string) {
      return (name.charAt(0).toLowerCase() + name.slice(1)).replace(/ /g, '');
    },
    isVisible(fieldName: string) {
      const field = fieldNameMap.get(this.billing)!.get(fieldName);
      return ((this.billing && this.billingFields.includes(field))
      || (!this.billing && this.shippingFields.includes(field)));
    },
    isRequired(fieldName: string) {
      const field = fieldNameMap.get(this.billing)!.get(fieldName);
      return ((this.billing && this.billingRequiredFields.includes(field))
      || (!this.billing && this.shippingRequiredFields.includes(field)));
    },
    finishLoading() {
      if (Object.keys(this.infoFields).length && this.infoFields[0].name !== '') {
        this.setCustomerInfoFields();
      }
    },
  },
  directives: {
    mask: {
      bind(el, binding, vnode, oldVnode) {
        if (binding.value) {
          mask(el, binding, vnode, oldVnode);
        }
      },
    },
  },
  components: {
    PageLoader,
    APhoneNumberForm,
  },
});
