





























































































import Vue from 'vue';
import api from '@/common/api';
import { initNonCashAdjustmentFields, NonCashAdjustmentFee, NonCashAdjustmentType } from '@/common/types/nonCashAdjustment.types';
import PageLoader from '@/common/components/PageLoader.vue';

export default Vue.extend({
  data() {
    return {
      SendEmailSetting: false as boolean,
      isSubmitting: false as boolean,
      nonCashAdjustment: initNonCashAdjustmentFields(),
      feeTypes: [NonCashAdjustmentType.PERCENT, NonCashAdjustmentType.DOLLAR_AMOUNT] as any,
      feeType: NonCashAdjustmentType.PERCENT,
      feeTypeAch: NonCashAdjustmentType.PERCENT,
      NonCashAdjustmentType,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      api
        .get('/cash-discount')
        .then(({ data }) => {
          this.setNonCashAdjustment(data.data.nonCashAdjustmentFee);
          (this.$refs.loader as any).setReady();
        })
        .catch(() => {
          (this.$refs.loader as any).setError();
        });
    },
    setNonCashAdjustment(data: NonCashAdjustmentFee) {
      if (data) {
        this.nonCashAdjustment = data;
        if (this.nonCashAdjustment.adjustmentPercent !== 0) {
          this.feeType = NonCashAdjustmentType.PERCENT;
          this.nonCashAdjustment.dollarAmount = 0;
        } else if (this.nonCashAdjustment.dollarAmount !== 0) {
          this.feeType = NonCashAdjustmentType.DOLLAR_AMOUNT;
          this.nonCashAdjustment.adjustmentPercent = 0;
        }
        if (this.nonCashAdjustment.adjustmentPercentAch !== 0) {
          this.feeTypeAch = NonCashAdjustmentType.PERCENT;
          this.nonCashAdjustment.dollarAmountAch = 0;
        } else if (this.nonCashAdjustment.dollarAmountAch !== 0) {
          this.feeTypeAch = NonCashAdjustmentType.DOLLAR_AMOUNT;
          this.nonCashAdjustment.adjustmentPercentAch = 0;
        }
      }
      const formattedDollarAmount = (this.nonCashAdjustment.dollarAmount! / 100).toFixed(2);
      this.nonCashAdjustment.dollarAmount = formattedDollarAmount;
      this.nonCashAdjustment.dollarAmount = Number((this.nonCashAdjustment.dollarAmount));
      this.nonCashAdjustment.dollarAmountAch = (this.nonCashAdjustment.dollarAmountAch! / 100).toFixed(2);
      this.nonCashAdjustment.dollarAmountAch = Number((this.nonCashAdjustment.dollarAmountAch));
    },
    save() {
      if (!this.isValid()) {
        return;
      }
      api
        .post('/cash-discount', {
          feeType: this.feeType,
          nonCashAdjustmentFeeId: this.nonCashAdjustment.id,
          name: this.nonCashAdjustment.name,
          inVirtualTerminal: this.nonCashAdjustment.inVirtualTerminal,
          inInvoice: this.nonCashAdjustment.inInvoice,
          inHPP: this.nonCashAdjustment.inHpp,
          nonCashAdjustmentPercent: this.nonCashAdjustment.adjustmentPercent && this.feeType === NonCashAdjustmentType.PERCENT
            ? Number(this.nonCashAdjustment.adjustmentPercent) : null,
          nonCashAdjustmentDollarAmount: this.nonCashAdjustment.dollarAmount && this.feeType === NonCashAdjustmentType.DOLLAR_AMOUNT
            ? (Number(this.nonCashAdjustment.dollarAmount!) * 100) : null,
          nonCashAdjustmentPercentAch: this.nonCashAdjustment.adjustmentPercentAch && this.feeTypeAch === NonCashAdjustmentType.PERCENT
            ? Number(this.nonCashAdjustment.adjustmentPercentAch) : null,
          nonCashAdjustmentDollarAmountAch: this.nonCashAdjustment.dollarAmountAch && this.feeTypeAch === NonCashAdjustmentType.DOLLAR_AMOUNT
            ? (Number(this.nonCashAdjustment.dollarAmountAch!) * 100) : null,
        })
        .then(() => {
          this.$emit('updateComponentKey');
          this.$toasted.success('Cash Discount saved!');
        })
        .catch(({ response }: { response: any }) => {
          if (response.data.errors.nonCashAdjustmentPercent) {
            this.$toasted.error(
              `${response.data.errors.nonCashAdjustmentPercent[0]}`,
            );
          } else if (response.data.errors.nonCashAdjustmentDollarAmount) {
            this.$toasted.error(
              `${response.data.errors.nonCashAdjustmentDollarAmount[0]}`,
            );
          }
        });
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
  },
  components: {
    PageLoader,
  },
});

