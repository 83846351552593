import { Month, WeekDay } from '@/common/types/dateTime.types';

export enum RecurrenceFrequency {
  SECONDLY = 'secondly',
  MINUTELY = 'minutely',
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface RecurrenceModel {
  recurrenceText: string;
  rruleText: string;
  recurrenceFrequency: RecurrenceFrequency;
}

export interface BillingRecurrenceModel {
  recurrence: RecurrenceModel;
  amount: number;
}

export interface RecurrenceDurationModel {
  interval: number;
  count: number | null;
}

export interface MonthRecurrenceModel {
  day: number;
  month: Month;
}

export interface WeekDayModel {
  day: WeekDay | null;
}

export interface BillingAmountModel {
  amount: any;
}
