
















































import Vue from 'vue';
import { DocumentInfo } from '@/common/components/support/support.types';
import { chunk } from 'lodash';

export default Vue.extend({
  props: {
    documents: {
      type: Array,
      required: true,
      default: [] as DocumentInfo[],
    },
    blockUpload: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      documentRows: [] as DocumentInfo[][],
      maxRowCount: 4 as number,
    };
  },
  mounted() {
    this.chunkDocuments();
  },
  watch: {
    documents: {
      handler() {
        this.chunkDocuments();
      },
      deep: true,
    },
  },
  methods: {
    chunkDocuments() {
      this.documentRows = chunk(this.documents as DocumentInfo[], this.maxRowCount);
    },
  },
});
