import { NonCashAdjustmentFee } from '@/common/types/nonCashAdjustment.types';
import { JsonApiData } from '@/jsonApi.types';

export enum FeeType {
  DISCOUNT = 'Discount',
  TAX = 'Tax',
  SHIPPING = 'Shipping',
}

export enum SaveOption {
  SAVE = 'save',
  SAVEANDSEND = 'saveAndSend',
  UPDATE = 'update',
  UPDATEANDSEND = 'updateAndSend',
}

export enum SaveText {
  SAVE = 'Saving invoice...',
  SAVEANDSEND = 'Saving and sending invoice...',
  UPDATE = 'Updating invoice...',
  UPDATEANDSEND = 'Updating and sending invoice...'
}

export interface MerchantContact {
  address1: string;
  address2: string;
  city: string;
  country: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
  state: string;
}
export interface MerchantInfo extends JsonApiData {
  companyName: string;
  contact: MerchantContact;
  logo: boolean;
  nonCashAdjustmentFee?: NonCashAdjustmentFee;
}

export interface InvoiceCustomer {
  company: string;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  phoneNumber?: string;
  zip: string;
  country: string;
}

export interface InvoiceInformationInterface {
  dueDate: string;
  dueDateFormatted?: string;
  issueDate?: string;
  invoiceNumber: string;
  comments: string;
  discount: number;
  shipping: number;
  tax: number;
  cashDiscount?: number | string;
  cashDiscountName?: string;
  processorInformation: {
    creditCardProcessorId: string,
    achProcessorId: string,
  }
}

export function initInvoiceInformation(): InvoiceInformationInterface {
  return {
    dueDate: '',
    invoiceNumber: String(Math.random()).slice(10),
    comments: '',
    discount: 0,
    shipping: 0,
    tax: 0,
    processorInformation: {
      creditCardProcessorId: '',
      achProcessorId: '',
    },
  };
}

export function formatDate(date: any): any {
  if (!date) return null;
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}
