




















































import Vue from 'vue';
import api from '@/common/api';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { ReceiptSetting, initReceiptSetting, ReceiptSettingCategory } from './support.types';
import ReceiptSettingLogoPicker from './ReceiptSettingLogoPicker.vue';

export default Vue.extend({
  props: {
    receiptSettingToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newReceiptSetting: initReceiptSetting() as ReceiptSetting,
      valid: true,
      isSubmitting: false as boolean,
      showModal: false as boolean,
      ReceiptSettingCategory,
      receiptCategories: Object.values(ReceiptSettingCategory) as string[],
      logo: '' as string,
    };
  },
  watch: {
    receiptSettingToEdit() {
      this.newReceiptSetting = this?.receiptSettingToEdit;
    },
    newReceiptSetting() {
      this.loadReceiptSettingData();
    },
  },
  methods: {
    loadReceiptSettingData() {
      if (this?.newReceiptSetting.name === null
          || this?.newReceiptSetting.name === ''
          || Object.keys(this?.newReceiptSetting).length === 0) return;

      this.logo = this.isLogo() ? this.newReceiptSetting.value : '';
      this.showModal = true;
    },
    updateLogo(logo: string) {
      this.logo = logo;
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    save() {
      if (!this.isValid()) {
        return;
      }

      this.submitActions(true);

      const val = this.isLogo() ? this.logo : this.newReceiptSetting.value;

      api
        .put(`/receipt-settings/${this?.newReceiptSetting?.uuid}`, {
          name: this.newReceiptSetting.name,
          value: val,
          enabled: this.newReceiptSetting.enabled,
          category: this.newReceiptSetting.category,
        })
        .then(() => {
          this.close();
          this.newReceiptSetting = initReceiptSetting();
          this.logo = '';
          this.$emit('created');
          (this.$refs.form as any).reset();
        })
        .catch(({ response }: { response: any }) => {
          this.close();
          this.logo = '';
          this.$toasted.error(`There was a problem editting receipt setting. (${response.status})`);
        });
    },
    show() {
      this.newReceiptSetting = this?.receiptSettingToEdit;
      this.showModal = false;
    },
    close() {
      this.newReceiptSetting = initReceiptSetting();
      this.submitActions(false);
      this.showModal = false;
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    isLogo(): boolean {
      return this.newReceiptSetting.category === ReceiptSettingCategory.LOGO;
    },
  },
  components: {
    LoadingModal,
    ReceiptSettingLogoPicker,
  },
});
