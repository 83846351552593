














import Vue from 'vue';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';

export default Vue.extend({
  data() {
    return {
      config: {
        pageTitle: 'Plans',
        header: 'Manage Plans',
        routerBase: 'merchant.plans',
        apiPath: 'plans',
        entityName: 'plan',
        identifierProperty: 'name',
        reportFileName: '',
        filters: [],
        headers: [
          { text: 'ID', value: 'id', align: ' d-none' },
          {
            text: 'Plan Name',
            value: 'name',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Description',
            value: 'description',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Amount',
            value: 'amount',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Frequency',
            value: 'recurrenceFrequency',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Recurrence',
            value: 'recurrenceText',
            sortable: false,
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
        ],
      },
    };
  },
  components: {
    EntitiesTable,
  },
});
