import env from '@/environmentSetup';

/**
 * Returns the domain to be used for API calls
 *
 * @param prefix
 * @param useBase
 * @param isAuth
 */
export default function getDomain(prefix: string, useBase: boolean = false, isAuth: boolean = false): string {
  const domains: any = {
    'cgatewaystaging.link': {
      clientId: 3,
    },
    'coregateway.local': {
      clientId: 1,
    },
  };
  const useprefix = prefix ? `${prefix}.` : '';
  const host = window.location.hostname;
  const domainParts = host.split('.').slice(1);
  const domain = domainParts.join('.');
  if (domains[domain] && !useBase) {
    return `https://${useprefix}${domain}`;
  }
  if (useBase && isAuth) {
    return env('VUE_APP_AUTH_API_BASE_URL');
  }
  return env('VUE_APP_API_BASE_URL');
}
