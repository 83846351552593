






import Vue from 'vue';
import PaymentPage from '@/payments/views/payment-pages/PaymentPage.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    PaymentPage,
  },
});
