























import Vue from 'vue';

export default Vue.extend({
  props: {
    loadingText: {
      type: String,
      required: false,
      default: 'Loading...',
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
});
