<template>
  <auth-layout>
    <p-card class="password-reset">
      <template #header>
        <h1>
          Password Reset
        </h1>
      </template>

      <v-form ref="form" v-model="valid" @submit.prevent="resetPassword">
        <v-text-field
          v-model="username"
          label="Username"
          :rules="[(v) => !!v || 'Username is required']"
          :error-messages="validationErrors.username"
          :disabled="submitting"
          outlined
          dense
          @input="validationErrors = {}"
        />

        <div class="password-reset__actions">
          <v-btn
            color="primary"
            type="submit"
            :loading="submitting"
            :disabled="!valid || submitting"
          >
            Send Password Reset Link
          </v-btn>
          <router-link :to="{ name: 'login' }">
            Back to Login
          </router-link>
        </div>
      </v-form>
    </p-card>
  </auth-layout>
</template>

<script>
import auth from '@/common/auth';
import PCard from '@/components/presentations/p-card.vue';
import AuthLayout from './AuthLayout.vue';

export default {
  title: 'Reset your password',
  components: { AuthLayout, PCard },
  data() {
    return {
      valid: true,
      username: '',
      validationErrors: {},
      submitting: false,
    };
  },
  methods: {
    async resetPassword() {
      this.submitting = true;

      try {
        await auth.post('/password/email', { username: this.username });
        this.$toasted.success('Password reset link successfully sent');
        this.$refs.form.reset();
      } catch (e) {
        this.$toasted.error('There was an error while sending the password reset link');
        this.validationErrors = e.response.data.errors;
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped>
.password-reset {
  --card-padding: 2rem;
  --card-header-margin-block-end: 2rem;

  border-top: 0.25rem solid var(--primary-color);
  max-width: 30rem;
}

.password-reset__actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
}
</style>
