import { DataTimestamps, JsonApiData } from '@/jsonApi.types';

interface Item extends JsonApiData, DataTimestamps {
  processorName: string
  rateKey: string;
  quantity: number;
  total: number;
  month: number;
  year: number;
}
export interface BillingItem extends Item {
  rate: number;
  raw: number;
}

export interface Residual extends Item {
  merchantName: string;
  sellRate: number;
  buyRate: number;
  residual: number;
  raw: number;
}

export interface Commissions extends Residual {
  isoName: string;
  commission: number;
  raw: number;
}

export enum ResidualFeeType {
  CASH_SERVICE_FEE= 'cash_service_fee',
  CASH_MONTHLY_FEE = 'cash_monthly_fee',
  CASH_TRANSACTION_FEE = 'cash_transaction_fee',
  GATEWAY_SERVICE_FEE = 'gateway_service_fee',
  GATEWAY_MONTHLY_FEE = 'gateway_monthly_fee',
  GATEWAY_TRANSACTION_FEE = 'gateway_transaction_fee',
  GATEWAY_BATCH_SETTLEMENT_FEE = 'gateway_batch_settlement_fee',
  ELECTRONIC_CHECK_SETUP_FEE = 'electronic_check_setup_fee',
  ELECTRONIC_CHECK_MONTHLY_FEE = 'electronic_check_monthly_fee',
  ELECTRONIC_CHECK_TRANSACTION_FEE = 'electronic_check_transaction_fee',
  ELECTRONIC_CHECK_BATCH_FEE = 'electronic_check_batch_fee',
  CUSTOMER_VAULT_SERVICE_FEE = 'customer_vault_service_fee',
  CUSTOMER_VAULT_MONTHLY_FEE = 'customer_vault_monthly_fee',
  CUSTOMER_VAULT_TRANSACTION_FEE = 'customer_vault_transaction_fee',
  NONCASH_TRANSACTION_FEE = 'cash_discount_transaction_fee',
  NONCASH_MONTHLY_FEE = 'cash_discount_monthly_fee',
  NONCASH_SERVICE_FEE = 'cash_discount_service_fee',
  SURCHARGING_MONTHLY_FEE = 'surcharging_monthly_fee',
  SURCHARGING_SERVICE_FEE = 'surcharging_service_fee',
  ENHANCED_DATA_MONTHLY_FEE = 'enhanced_data_monthly_fee',
  ENHANCED_DATA_SERVICE_FEE = 'enhanced_data_service_fee',
}

export interface ResidualFeeTypeFilterOption {
  value: ResidualFeeType|string;
  display: string;
}
