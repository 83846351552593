import { Feature } from '@/features.types';

export const PAY_HPP_ROUTE_NAME = 'pay.hpp';
export const PAY_INVOICE_ROUTE_NAME = 'invoices.pay';

export default [
  {
    path: '/pay/:id',
    name: PAY_HPP_ROUTE_NAME,
    component: () => import('../views/payment-pages/PaymentPage.vue'),
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
    },
  },
  {
    path: '/invoices/:id/pay/:calledFrom?',
    name: PAY_INVOICE_ROUTE_NAME,
    component: () => import('@/merchant/views/invoices/InvoicePaymentPage.vue'),
    props: true,
  },
];
