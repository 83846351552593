






























































































































































































































































































































































































































































































































































import Vue from 'vue';
import { Customer } from '@/common/types/customers.types';
import { initCustomerAddress } from '@/common/util/customers.util';
import CustomerAddressForm from '@/common/components/customers/CustomerAddressForm.vue';
import { mapGetters } from 'vuex';
import { Feature } from '@/features.types';
import { DefaultLevel3Setting, InfoFieldFillable, InfoField } from '@/common/components/support/support.types';
import EnhancedFields from '../customer-payment/EnhancedFields.vue';


export default Vue.extend({
  props: {
    value: {
      type: Object as () => Customer,
      required: true,
    },
    newDefaultLevel3Setting: {
      type: Object as () => DefaultLevel3Setting,
      required: false,
    },
    showAddToVault: {
      type: Boolean,
      required: false,
      default: true,
    },
    alwaysShowBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    isBusinessAch: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    billingValidationPrefix: {
      type: String,
      required: false,
      default: 'billing',
    },
    shippingValidationPrefix: {
      type: String,
      required: false,
      default: 'shipping',
    },
    enhancedData: {
      type: Boolean,
      required: false,
      default: false,
    },
    enhancedFields: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    validateEnhancedFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    infoFields: {
      type: Array as () => InfoFieldFillable[],
      required: true,
    },
  },
  data() {
    return {
      // localValueForTest is to make tests based on computed properties pass
      // without computed is not reactive in tests
      localValueForTest: this.value as Customer,
      orderDateModal: false as boolean,
      Feature,
      level3ValidationInitiated: false as boolean,
    };
  },
  computed: {
    ...mapGetters(['isCustomerVaultEnabled', 'isEnhancedDataEnabled']),
    shippingLinkText(): string {
      return this.value.separateShipping
        ? 'Use this billing information for shipping'
        : 'Use different shipping information';
    },
    isValid(): boolean {
      return true;
    },
  },
  methods: {
    toggleShipping() {
      this.value.separateShipping = !this.value.separateShipping;
      this.value.shippingAddress = initCustomerAddress();
      if (!this.value.separateShipping) {
        this.value.shippingAddress.country = '';
      }
      this.$emit('input', this.value);
    },
    setEnhancedFieldProgress() {
      this.$emit('setEnhancedFieldProgress');
    },
    debouncedLevel3Field() {
      this.level3ValidationInitiated = true;
      this.$emit('setLevel3FieldProgress');
    },
    getClass(fieldVal:any) {
      return (this.level3ValidationInitiated || this.validateEnhancedFields)
              && fieldVal === ''
        ? 'level-input-error'
        : '';
    },
    showShippingLink() {
      return typeof this.infoFields.find((x:InfoField) => x.category === 'Shipping') !== 'undefined';
    },
  },
  components: {
    CustomerAddressForm,
    EnhancedFields,
  },
});
