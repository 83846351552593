






























































import Vue from 'vue';
import api from '@/common/api';
import EntitiesTable from '@/common/components/entities/EntitiesTable.vue';
import Permissions from '@/common/permissions';
import { mapGetters } from 'vuex';
import { User } from '@/common/components/users/users.types';

export default Vue.extend({
  data() {
    return {
      showCopyUserBtn: false,
      showDeleteModal: false,
      reloads: 0,
      userToDelete: null as User | null,
      config: {
        pageTitle: 'Users',
        header: 'Users',
        routerBase: 'admin.users',
        apiPath: 'users',
        entityName: 'user',
        identifierProperty: 'username',
        reportFileName: '',
        filters: [],
        headers: [
          {
            text: 'ID',
            value: 'id',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            align: ' d-none',
          },
          {
            text: 'Username',
            value: 'username',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'First Name',
            value: 'firstName',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Last Name',
            value: 'lastName',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Email',
            value: 'email',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '8rem',
          },
          {
            text: 'Permissions',
            value: 'permissions',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            align: ' d-none',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'entity-table-header subtitle-2 font-weight-bold black--text',
            width: '9rem',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'currentUserCan']),
  },
  mounted() {
    this.currentUserCanAddUser();
  },
  methods: {
    currentUserCanAddUser() {
      this.showCopyUserBtn = this.currentUserCan(Permissions.ADD_USER);
      return this.showCopyUserBtn;
    },
    currentUserCanEditUser() {
      return this.currentUserCan(Permissions.EDIT_USER);
    },
    currentUserCanDeleteUser(user: User) {
      return this.notSelf(user) && this.currentUserCan(Permissions.DELETE_USER);
    },
    notSelf(user: User) {
      return !(this.currentUser && this.currentUser.id === user.id);
    },
    promptForDelete(user: User) {
      this.userToDelete = user;
      this.showDeleteModal = true;
    },
    async getData() {
      this.showDeleteModal = false;
      this.userToDelete = null;

      this.reloads += 1;
    },
    deleteUser() {
      if (this.userToDelete === null) {
        this.showDeleteModal = false;
        return;
      }
      api
        .delete(`users/${this.userToDelete.id}`)
        .then(() => {
          this.getData();
        })
        .catch(({ response }) => {
          this.showDeleteModal = false;
          this.userToDelete = null;
          const message = this.$errorMessages.get('users.delete');
          this.$toasted.error(`${message} (${response.status})`);
        })
        .finally(() => {
          this.showDeleteModal = false;
          this.userToDelete = null;
        });
    },
  },
  components: {
    EntitiesTable,
  },
});
