




import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'setBranding') {
        this.updateBranding();
      }
    });
  },
  beforeDestroy() {
    // eslint-disable-next-line no-unused-expressions
    this.unsubscribe && this.unsubscribe();
  },
  data() {
    return {
      unsubscribe: null as Function | null,
    };
  },
  computed: {
    ...mapGetters(['branding']),
  },
  methods: {
    updateBranding() {
      if (!this.branding) {
        return;
      }
      const brandingTheme: any = {
        primary: this.branding.primaryColor,
        accent: this.branding.tertiaryColor,
        secondary: this.branding.secondaryColor,
        // success: '#6dff74',
        // info: '#7365ff',
        // warning: '#2e8ac0',
        // error: '#ff5e3c',
      };
      Object.keys(brandingTheme).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = brandingTheme[i];
      });
    },
  },
  components: {
  },
});
