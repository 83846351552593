<template>
  <p-card class="two-factor">
    <template #header>
      <h2>Enable two-factor</h2>
    </template>

    <v-select
      v-model="authenticatorMethod"
      :items="authenticatorMethods"
      :disabled="loading || submitting"
      label="Select a method *"
      outlined
      dense
      hide-details
      class="two-factor__method-selector"
    />

    <div v-if="loading" class="centerer">
      <a-spinner big />
    </div>

    <template v-else>
      <auth-method
        v-if="authenticatorMethod === 'authenticator'"
        :secret="secret"
        :username="username"
        v-bind="methodBindings"
        v-on="methodEvents"
      />
      <email-method
        v-if="authenticatorMethod === 'email'"
        :sendingCode="sendingCode"
        v-bind="methodBindings"
        v-on="methodEvents"
      />
      <sms-method
        v-if="authenticatorMethod === 'sms'"
        :sendingCode="sendingCode"
        v-bind="methodBindings"
        v-on="methodEvents"
      />
    </template>

    <v-dialog v-model="showRecoveryCodes" persistent max-width="593">
      <recovery-codes-card :recoveryCodes="recoveryCodes" @done="showRecoveryCodes = false; $emit('done')" />
    </v-dialog>
  </p-card>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import env from '@/environmentSetup';
import ASpinner from '@/components/atoms/a-spinner.vue';
import PCard from '@/components/presentations/p-card.vue';
import EmailMethod from './2fa-methods/email-method.vue';
import SmsMethod from './2fa-methods/sms-method.vue';
import AuthMethod from './2fa-methods/auth-method.vue';
import RecoveryCodesCard from './recovery-codes-card.vue';

export default {
  props: {
    token: { type: String },
    username: { type: String },
  },
  components: {
    ASpinner,
    PCard,
    EmailMethod,
    SmsMethod,
    AuthMethod,
    RecoveryCodesCard,
  },
  data() {
    return {
      authenticatorMethod: 'authenticator',
      authenticatorMethods: [
        { value: 'authenticator', text: 'Authenticator app' },
        { value: 'email', text: 'Email' },
        { value: 'sms', text: 'SMS' },
      ],

      secret: {
        secret: '',
        secretBase32Encoded: '',
      },

      showRecoveryCodes: false,
      recoveryCodes: [],
      activeMethods: [],

      loading: true,
      submitting: false,
      sendingCode: false,
    };
  },
  computed: {
    ...mapState(['accessToken', 'currentUser']),
    fusionAuthDomain: () => env('VUE_APP_FUSION_AUTH_URL'),
    axiosHeaders() {
      return { Authorization: `Bearer ${this.token || this.accessToken}` };
    },
    isCurrentMethodActive() {
      return this.activeMethods.some((x) => x.method === this.authenticatorMethod);
    },
    activeMethodId() {
      return this.activeMethods.find((x) => x.method === this.authenticatorMethod)?.id;
    },
    methodBindings() {
      return {
        active: this.isCurrentMethodActive,
        methodId: this.activeMethodId,
        submitting: this.submitting,
      };
    },
    methodEvents() {
      return {
        sendCode: this.sendCode,
        activate: this.activate,
        deactivate: this.deactivate,
      };
    },
  },
  watch: {
    authenticatorMethod: {
      immediate: true,
      handler(val) {
        if (val === 'authenticator' && !this.activeMethodId) {
          this.fetchSecret();
        }
      },
    },
  },
  created() {
    this.checkMFA();
  },
  methods: {
    async checkMFA() {
      this.loading = true;

      try {
        const endpoint = `${this.fusionAuthDomain}/api/user/`;
        const response = await axios.get(endpoint, { headers: this.axiosHeaders });

        this.activeMethods = response.data.user.twoFactor.methods || [];
      } catch (e) {
        this.$toasted.error('There was an error while checking 2FA data for this user');
      } finally {
        this.loading = false;
      }
    },
    async deactivate(params) {
      this.submitting = true;

      try {
        const endpoint = `${this.fusionAuthDomain}/api/user/two-factor`;
        await axios.delete(endpoint, { headers: this.axiosHeaders, params });

        this.$toasted.success('2FA method successfully deactivated');
        this.checkMFA();
      } catch (e) {
        this.$toasted.error('There was a problem while deactivating this method');
      } finally {
        this.submitting = false;
      }
    },
    async activate(data) {
      this.submitting = true;

      try {
        const endpoint = `${this.fusionAuthDomain}/api/user/two-factor`;
        const response = await axios.post(endpoint, data, { headers: this.axiosHeaders });

        this.$toasted.success('2FA method successfully activated');

        if (response.data.recoveryCodes) {
          this.recoveryCodes = response.data.recoveryCodes;
          this.showRecoveryCodes = true;
        }

        this.checkMFA();
      } catch (e) {
        this.$toasted.error('There was an error while saving 2FA');
      } finally {
        this.submitting = false;
      }
    },
    async sendCode(data) {
      this.sendingCode = true;

      try {
        const endpoint = `${this.fusionAuthDomain}/api/two-factor/send`;
        await axios.post(endpoint, data, { headers: this.axiosHeaders });

        this.$toasted.success('One-time code successfully sent.');
        this.oneTimeCodeSent = true;
        setTimeout(() => { this.oneTimeCodeSent = false; }, 60000);
      } catch (e) {
        this.$toasted.error('There was an error while sending the one-time code');
      } finally {
        this.sendingCode = false;
      }
    },
    async fetchSecret() {
      this.loading = true;

      try {
        const endpoint = `${this.fusionAuthDomain}/api/two-factor/secret`;
        const response = await axios.get(endpoint, { headers: this.axiosHeaders });
        this.secret = response.data;
      } catch (e) {
        this.$toasted.error('There was an error while obtaining the secret');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.two-factor__method-selector {
  margin-block-end: 1.5rem;
}

.centerer {
  display: grid;
  place-items: center;
  margin-block: 3rem;
}

.two-factor >>> .two-factor__submit {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
</style>
