<template>
  <div>
    <router-link :to="{ name: 'merchant.settings.invoice-settings' }" class="back-link">
      <v-icon>mdi-chevron-left</v-icon>
      Back to Invoice Settings
    </router-link>

    <v-card class="reply-to-card">
      <v-card-title class="reply-to-title">
        Invoice Contact Info
        <a-spinner v-if="loading" />
      </v-card-title>

      <v-form v-model="valid" @submit.prevent="onSubmit">
        <p>Please submit contact info</p>

        <v-text-field
          v-model="invoiceContactInfo.emailAddress"
          label="New Email"
          placeholder="hello@risewithaurora.io"
          :disabled="loading"
          :rules="[
            (v) => !!v || 'Email is required',
            (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) || 'Email must be valid'
          ]"
          outlined
          dense
        />
        <a-phone-number-form
          v-model="invoiceContactInfo.phoneNumber"
          name="custoer-address-phone-number"
          label="Phone Number"
          :disabled="loading"
          outlined
          dense
        />
        <v-btn color="primary" type="submit" :disabled="!valid || loading">
          SAVE
        </v-btn>
      </v-form>

    </v-card>
  </div>
</template>

<script>
import api from '@/common/api';
import ASpinner from '@/components/atoms/a-spinner.vue';
import APhoneNumberForm from '@/components/atoms/a-phone-number-form.vue';
import { mapState } from 'vuex';

export default {
  components: { ASpinner, APhoneNumberForm },
  data() {
    return {
      invoiceContactInfo: {
        emailAddress: '',
        phoneNumber: '',
      },
      loading: true,
      valid: false,
    };
  },
  computed: {
    ...mapState(['branding']),
    endpoint() {
      return `/merchant/invoice-contact-info/${this.branding.merchantId}`;
    },
  },
  created() {
    api.get('/merchant')
      .then(({ data }) => {
        this.invoiceContactInfo = data.data.invoiceContactInfo;
      })
      .catch(() => {
        const message = this.$errorMessages.get('settings.reply_to_load');
        this.$toasted.error(message);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    onSubmit() {
      this.loading = true;

      api.put(this.endpoint, {
        invoice_contact_phone: this.invoiceContactInfo.phoneNumber,
        invoice_contact_email_address: this.invoiceContactInfo.emailAddress,
      })
        .then(() => {
          this.$toasted.success('Email successfully saved');
        })
        .catch(() => {
          const message = this.$errorMessages.get('settings.reply_to');
          this.$toasted.error(message);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.back-link {
  font-size: 1.375rem;
  font-weight: 600;
  font-family: 'Montserrat';
  margin-block: 1.2em 1em;
  display: block;
  color: unset;
}

.back-link:hover {
  text-decoration: none;
}

.v-card.reply-to-card {
  box-shadow: 0px 10px 30px rgba(126, 135, 140, 0.1);
  max-width: 34rem;
}

.v-card .reply-to-title {
  font-size: 1.125rem;
  font-weight: 600;
  padding-inline-start: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

form {
  padding: 1rem 1.5rem 2.5rem 1.5rem;
}
</style>
