
































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import api, { setHeader } from '@/common/api';
import Permissions from '@/common/permissions';
import PageLoader from '@/common/components/PageLoader.vue';
import LoadingModal from '@/common/components/LoadingModal.vue';
import { PaymentType } from '@/common/types/payments.types';
import { Transaction, TransactionStatus } from '@/common/types/transactions.types';
import VueHtmlToPaper from 'vue-html-to-paper';
import Address from './Address.vue';
import TransactionDetailsCard from './TransactionDetailsCard.vue';

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: ['https://unpkg.com/kidlat-css/css/kidlat.css'],
};

Vue.use(VueHtmlToPaper, options);

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
    merchant: { type: String },
  },
  data() {
    return {
      transaction: null as Transaction | null,
      refundAmount: 0 as number,
      isSubmitting: false as boolean,
      refundModal: false as boolean,
      emailModal: false as boolean,
      email: '' as string,
      showDeleteModal: false as boolean,
      isoProxyHeader: '' as string,
    };
  },
  created() {
    if (this.merchant) {
      this.isoProxyHeader = api.defaults.headers.common['x-iso-proxy'];
      setHeader('x-iso-proxy', undefined);
      setHeader('x-merchant-proxy', this.merchant);
    }
  },
  destroyed() {
    if (this.merchant) {
      setHeader('x-iso-proxy', this.isoProxyHeader);
      setHeader('x-merchant-proxy', undefined);
    }
  },
  mounted() {
    this.loadTransaction();
  },
  computed: {
    ...mapGetters(['currentUserCan', 'isCustomerVaultEnabled']),
    hasBillingAddress(): boolean {
      return (
        this.transaction !== null
        && this.transaction.customerDetails !== null
        && this.transaction.customerDetails?.billingAddress !== null
        && this.transaction.customerDetails?.billingAddress !== undefined
      );
    },
    hasShippingAddress(): boolean {
      return (
        this.transaction !== null
        && this.transaction.customerDetails !== null
        && this.transaction.customerDetails?.shippingAddress !== null
        && this.transaction.customerDetails?.shippingAddress !== undefined
      );
    },
    customer(): string {
      if (!this.transaction) {
        return 'Loading...';
      }

      if (this.transaction.customer) {
        return this.transaction.customer;
      }

      switch (this.transaction.paymentMethod!.type) {
        case PaymentType.CREDIT:
          return this.transaction.account;
        case PaymentType.ACH:
          return 'ACH';
        case PaymentType.CASH:
        default:
          return (this.$options.filters as any).capitalize(this.transaction.type);
      }
    },
    readyToCapture(): boolean {
      return this.transaction?.status === TransactionStatus.AUTHORIZED;
    },
    isCapture(): boolean {
      return this.transaction?.status === TransactionStatus.CAPTURED;
    },
    isSettled(): boolean {
      return this.transaction?.status === TransactionStatus.SETTLED;
    },
    readyToVoid(): boolean {
      return (
        (this.transaction?.status === TransactionStatus.CAPTURED
        && this.currentUserCanVoidOrRefund
        && this.transaction?.paymentMethod?.key !== 'omaha')
        || (this.transaction?.status === TransactionStatus.AUTHORIZED && this.transaction?.paymentMethod?.key === 'omaha')
      );
    },
    transactionIsFewDaysAgo(): boolean {
      let trCreated = this?.transaction?.createdAt;
      if (!trCreated) {
        return false;
      }
      trCreated = this.changeTimeZone(trCreated, 'MST');
      const yesterday = this.changeTimeZone(new Date(), 'MST');
      yesterday.setDate(yesterday.getDate() - 1);
      if (yesterday.toDateString() === trCreated.toDateString()) {
        return true;
      }
      return yesterday.getTime() > trCreated.getTime();
    },
    readyToReverse(): boolean {
      return (
        (this.isSettled || this.isCapture)
        && this.transaction?.paymentMethod?.key === 'paya'
        && this.transactionIsFewDaysAgo
      );
    },
    readyToRefund(): boolean {
      return (
        this.transaction?.status === TransactionStatus.SETTLED
        && (this.transaction?.refundedAmount === undefined
          || this.transaction?.refundedAmount === 0)
        && this.currentUserCanVoidOrRefund
      );
    },
    currentUserCanVoidOrRefund(): boolean {
      return (
        (this.transaction?.paymentMethod!.type === PaymentType.CREDIT
          && this.currentUserCan(Permissions.RUN_CC_VOID))
        || (this.transaction!.paymentMethod!.type === PaymentType.ACH
          && this.currentUserCan(Permissions.RUN_ACH_VOID))
        || (this.transaction!.paymentMethod!.type === PaymentType.CASH
          && this.currentUserCan(Permissions.RUN_CASH_VOID))
      );
    },
    isCustomerInVault(): boolean {
      const customerId = this.transaction?.customerDetails?.id;
      return customerId !== undefined && customerId !== null && customerId !== '';
    },
    isDeclined(): boolean {
      return this.transaction!.status === TransactionStatus.DECLINED;
    },
  },
  methods: {
    loadTransaction() {
      (this.$refs.loader as any).setLoading();
      api
        .get(`/transactions/${this.id}`)
        .then(({ data: { data } }) => {
          this.transaction = data;
          this.refundAmount = (this.$options.filters as any).dollarsAndCents(
            this.transaction!.amount,
          );
          (this.$refs.loader as any).setReady();
        })
        .catch(() => {
          this.$toasted.error('Problem loading transaction');
          (this.$refs.loader as any).setError();
        });
    },
    isValid(): boolean {
      return (this.$refs.form as any).validate();
    },
    submitActions(isSubmitting: boolean) {
      this.isSubmitting = isSubmitting;
    },
    captureTransaction() {
      this.submitActions(true);
      api
        .patch(`/transactions/${this.transaction!.id}`, { capture: true })
        .then(({ data: { data: transaction } }) => {
          this.submitActions(false);
          this.$toasted.success('Success');
          this.transaction = transaction;
        })
        .catch(({ response }) => {
          this.submitActions(false);
          this.$toasted.error(response.data.message);
        });
    },
    refundTransaction() {
      if (!this.isValid()) {
        return;
      }
      const formattedRefundAmount = Number(String(this.refundAmount).replace(',', ''));
      this.deleteTransaction({ industry: 'moto', amount: Math.round(100 * formattedRefundAmount) });
      this.refundModal = false;
    },
    emailTransaction() {
      if (!this.isValid()) {
        return;
      }
      api.post(`/transactions/${this.transaction!.id}/send`, { email: this.email }).then(() => {
        this.$toasted.success('Success');
        this.emailModal = false;
      });
    },
    voidTransaction() {
      this.showDeleteModal = false;
      this.deleteTransaction({ industry: 'moto' });
    },
    addToVault() {
      this.submitActions(true);
      api
        .post('customers', { transactionId: this.transaction!.id })
        .then(() => {
          this.submitActions(false);
          this.$toasted.success('Success');
          this.transaction = null;
          this.loadTransaction();
        })
        .catch(({ response }) => {
          this.submitActions(false);
          this.$toasted.error(response.data.message);
        });
    },
    addToRecurring() {
      this.$router.push({
        name: 'merchant.transactions.subscriptions.new',
        params: { id: this.transaction!.id! },
      });
    },
    deleteTransaction(args = {}) {
      this.submitActions(true);
      api
        .delete(`/transactions/${this.transaction!.id}`, { data: args })
        .then(({ data: { data: transaction } }) => {
          this.submitActions(false);
          this.$toasted.success('Success');
          this.transaction = transaction;
        })
        .catch(({ response }) => {
          this.submitActions(false);
          this.$toasted.error(response.data.message);
        });
    },
    readyToEmail() {
      this.emailModal = true;
      const email = this.transaction!.customerDetails!.billingAddress!.email!;
      if (email) {
        this.email = email;
      }
    },
    printSection() {
      this.$htmlToPaper('printSection', () => {});
    },
    promptForDelete() {
      this.showDeleteModal = true;
    },
    changeTimeZone(date: string | Date, zone: string) {
      if (typeof date === 'string') {
        return new Date(
          new Date(date).toLocaleString('en-US', {
            timeZone: zone,
          }),
        );
      }
      return new Date(
        date.toLocaleString('en-US', { timeZone: zone }),
      );
    },
  },
  components: {
    Address,
    TransactionDetailsCard,
    PageLoader,
    LoadingModal,
  },
});
