<template>
  <table class="borderless">
    <thead>
      <tr class="main-tr">
        <th v-for="head in headers" :key="head">
          {{ head }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <td v-for="(value, index) in Object.values(item)" :key="index">
          <component
            v-if="typeof value === 'object' && value !== null"
            :is="typeof value.link === 'string' ? 'a' : 'router-link'"
            v-bind="[typeof value.link === 'string' ? { href: value.link } : { to: value.link }]"
          >
            {{ value.text }}
          </component>

          <template v-else>
            {{ value }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// TODO: Dont forget to add the limit by 10 and see all button
export default {
  props: {
    data: { type: Array, required: true },
  },
  computed: {
    headers() {
      if (!this.data || this.data.length === 0) return [];

      return Object.keys(this.data[0]);
    },
  },
};
</script>

<style scoped>
table {
  box-shadow: none;
  background: var(--background-accent-color);
}

table td,
table th {
  padding: 0.5rem 1rem;
  text-align: left;
}

thead {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
}

tbody tr:nth-child(odd) {
  background: var(--background-color);
}
</style>
