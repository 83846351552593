export function toFloat(rates) {
  return rates.map(
    (rate) => ({ ...rate, value: (rate.value / 100).toFixed(2) }),
  );
}

export function toInt(rates) {
  return rates.map(
    (rate) => ({ ...rate, value: Math.round(rate.value * 100) }),
  );
}
