import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/reports',
    name: 'reports',
    redirect: { name: 'reports.merchant' },
  },
  {
    path: '/reports/residual',
    name: 'reports.residual',
    component: () => import('./pages/residual-report/ResidualReport.vue'),
  },
  {
    path: '/reports/transaction',
    name: 'reports.transaction',
    component: () => import('./pages/transaction-report/TransactionReport.vue'),
  },
];

export default routes;
